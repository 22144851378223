import { h, FunctionalComponent } from "preact";

import kbbLogo from "@assets/powered-by-kbb-logo.png";
import cbbLogo from "@assets/cbb-logo.png";
import styles from "./TradeToolHeader.module.scss";

export interface TradeToolHeaderProps {
    provider?: "cbb" | "kbb";
}

export const TradeToolHeader = ({ provider }: TradeToolHeaderProps) => {
    if (provider === "cbb") {
        return (
            <div className={styles.cbb}>
                <img src={cbbLogo} alt="trade-tool-header-logo" />
            </div>
        );
    }

    return (
        <div className={styles.kbb}>
            <img src={kbbLogo} alt="trade-tool-header-logo" style={{ width: "100%" }} />
        </div>
    );
};
