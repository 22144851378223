import { h, FunctionalComponent } from "preact";
import { Text } from "@components/Text/Text";

import styles from "./Header.module.scss";
import CloseIcon from "@assets/close-icon.svg";
import BackIcon from "@assets/back-icon.svg";

export interface IHeaderProps {
    backButton: boolean;
    closeButton: boolean;
    goToPrev: () => void;
    onClose?: () => void;
    // progress?: number;
}

const LeftPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelLeft}>{children}</div>
);
const RightPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelRight}>{children}</div>
);

export const Header: FunctionalComponent<IHeaderProps> = ({
    children,
    backButton,
    closeButton,
    onClose,
    goToPrev,
}) => {
    return (
        <div className={styles.header}>
            <LeftPanel>
                {backButton && (
                    <div className={styles.backIconContainer} onClick={goToPrev}>
                        <BackIcon />
                        <Text block className={styles.backIconText}>Back</Text>
                    </div>
                )}
            </LeftPanel>
            <RightPanel>
                {closeButton && (
                    <div className={styles.closeIcon} onClick={onClose}>
                        <CloseIcon />
                    </div>
                )}
            </RightPanel>
            {children}
        </div>
    );
};