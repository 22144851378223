import { getStateMapper } from "@util/payload";
import { transformFromPhone } from "@util/phone";
import {
    toFloat,
    rawPostalCode,
    dateToRaw,
} from "@util/transforms";

import type { ICombinedState } from "@msa-tool/state";
import type { LoanAppInput } from "@graphql/schema";

export const mapStateToMSAApp = getStateMapper<ICombinedState, LoanAppInput>()({
    stateKeys: [
        "widgetId",
        "leadId",
        "sourceUrl",
        "firstName",
        "lastName",
        "dateOfBirth",
        "phoneNumber",
        "emailAddress",
        "postalCode",
        "streetNumber",
        "street",
        "unit",
        "city",
        "province",
        "country",
        "incomeTime",
        "employer",
        "monthlyIncome",
        "residenceTime",
        "jobTitle",
        "monthlyHomePayment",
        "rentOrOwn",
        "campaign",
    ],
    keyMap: {
        employer: "companyName",
    },
    transformers: {
        monthlyIncome: toFloat,
        monthlyHomePayment: toFloat,
        phoneNumber: transformFromPhone,
        postalCode: rawPostalCode,
        dateOfBirth: dateToRaw,
    },
    skipFalsy: [
        "sourceUrl",
        "widgetId",
        "leadId",
        "campaign",
    ],
    defaultValues: {
        employmentStatus: "yes",
    },
});

export type MappedMSAAppPayload = ReturnType<typeof mapStateToMSAApp>;