import { h, FunctionalComponent, Fragment } from "preact";

import { Footer } from "../Footer";
import { SubText } from "@components/Text/SubText";
import { Expandable } from "@components/Expandable";

import equifaxLogo from "@assets/powered-by-equifax.png";

interface IDefaultFooterProps {
    showLogo?: boolean;
    learnMore?: boolean;
}

export const DefaultFooter: FunctionalComponent<IDefaultFooterProps> = ({
    showLogo = true,
    learnMore = true,
    children,
}) => (
    <Fragment>
        {showLogo && (
            <img src={equifaxLogo} />
        )}
        <Footer>
            {learnMore && (
                <Expandable
                    title="Learn more"
                    content={(
                        <SubText block>
                            The Free Credit Process is powered by Equifax  Canada Co. (“Equifax”).
                            The Equifax Credit Score (www.equifax.ca) is based on Equifax’s proprietary
                            model and may not be the same score used by third parties to assess your
                            creditworthiness.  The provision of this score to you is intended for your
                            own educational use.  Third parties will take into consideration other
                            information in addition to a credit score when evaluating your
                            creditworthiness.
                        </SubText>
                    )}
                />
            )}
            {children}
        </Footer>
    </Fragment>
);