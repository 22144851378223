export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  FullText: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};


export enum AdvancedStatus {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  NotRun = 'NOT_RUN'
}

export type Analytic = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
};

export type AnalyticAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Analytic` object types. */
export type AnalyticAggregatesFilter = {
  /** A filter that must pass for the relevant `Analytic` object to be included within the aggregate. */
  filter?: Maybe<AnalyticFilter>;
  /** Sum aggregate over matching `Analytic` objects. */
  sum?: Maybe<AnalyticSumAggregateFilter>;
  /** Distinct count aggregate over matching `Analytic` objects. */
  distinctCount?: Maybe<AnalyticDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Analytic` objects. */
  min?: Maybe<AnalyticMinAggregateFilter>;
  /** Maximum aggregate over matching `Analytic` objects. */
  max?: Maybe<AnalyticMaxAggregateFilter>;
  /** Mean average aggregate over matching `Analytic` objects. */
  average?: Maybe<AnalyticAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Analytic` objects. */
  stddevSample?: Maybe<AnalyticStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Analytic` objects. */
  stddevPopulation?: Maybe<AnalyticStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Analytic` objects. */
  varianceSample?: Maybe<AnalyticVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Analytic` objects. */
  variancePopulation?: Maybe<AnalyticVariancePopulationAggregateFilter>;
};

/** The fields on `analytic` to look up the row to connect. */
export type AnalyticAnalyticsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `analytic` to look up the row to delete. */
export type AnalyticAnalyticsPkeyDelete = {
  id: Scalars['Int'];
};

export type AnalyticAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Analytic` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AnalyticCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventCategory` field. */
  eventCategory?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventAction` field. */
  eventAction?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `referrerUrl` field. */
  referrerUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userAgent` field. */
  userAgent?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userIp` field. */
  userIp?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `context` field. */
  context?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  clientId?: Maybe<BigIntFilter>;
  requestId?: Maybe<BigIntFilter>;
  eventCategory?: Maybe<BigIntFilter>;
  eventAction?: Maybe<BigIntFilter>;
  sourceUrl?: Maybe<BigIntFilter>;
  referrerUrl?: Maybe<BigIntFilter>;
  userAgent?: Maybe<BigIntFilter>;
  userIp?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  context?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type AnalyticDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventCategory across the matching connection */
  eventCategory?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventAction across the matching connection */
  eventAction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sourceUrl across the matching connection */
  sourceUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of referrerUrl across the matching connection */
  referrerUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userAgent across the matching connection */
  userAgent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userIp across the matching connection */
  userIp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of context across the matching connection */
  context?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `requestId` field. */
  requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `eventCategory` field. */
  eventCategory?: Maybe<StringFilter>;
  /** Filter by the object’s `eventAction` field. */
  eventAction?: Maybe<StringFilter>;
  /** Filter by the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `referrerUrl` field. */
  referrerUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `userAgent` field. */
  userAgent?: Maybe<StringFilter>;
  /** Filter by the object’s `userIp` field. */
  userIp?: Maybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `context` field. */
  context?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticFilter>;
};

/** An input for mutations affecting `Analytic` */
export type AnalyticInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

export type AnalyticMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type AnalyticMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type AnalyticMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type AnalyticMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AnalyticNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `analytic` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AnalyticNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `analytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AnalyticOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `analytic` to look up the row to update. */
export type AnalyticOnAnalyticForAnalyticsCompanyIdFkeyUsingAnalyticsPkeyUpdate = {
  /** An object where the defined keys will be set on the `analytic` being updated. */
  patch: UpdateAnalyticOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Analytic`. Fields that are set will be updated. */
export type AnalyticPatch = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

export type AnalyticStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type AnalyticSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

export type AnalyticVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type AnalyticVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The `analytic` to be created by this mutation. */
export type AnalyticsCompanyIdFkeyAnalyticsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

/** The `company` to be created by this mutation. */
export type AnalyticsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `AnalyticInput` mutation. */
export type AnalyticsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<AnalyticOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<AnalyticsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `analytic` in the `CompanyInput` mutation. */
export type AnalyticsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `analytic` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  connectById?: Maybe<Array<AnalyticAnalyticsPkeyConnect>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AnalyticNodeIdConnect>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  deleteById?: Maybe<Array<AnalyticAnalyticsPkeyDelete>>;
  /** The primary key(s) for `analytic` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AnalyticNodeIdDelete>>;
  /** The primary key(s) and patch data for `analytic` for the far side of the relationship. */
  updateById?: Maybe<Array<AnalyticOnAnalyticForAnalyticsCompanyIdFkeyUsingAnalyticsPkeyUpdate>>;
  /** The primary key(s) and patch data for `analytic` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate>>;
  /** A `AnalyticInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AnalyticsCompanyIdFkeyAnalyticsCreateInput>>;
};

/** A connection to a list of `Analytic` values. */
export type AnalyticsConnection = {
  /** A list of `Analytic` objects. */
  nodes: Array<Analytic>;
  /** A list of edges which contains the `Analytic` and cursor to aid in pagination. */
  edges: Array<AnalyticsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Analytic` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticAggregates>>;
};


/** A connection to a list of `Analytic` values. */
export type AnalyticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsGroupBy>;
  having?: Maybe<AnalyticsHavingInput>;
};

export type AnalyticsContext = {
  timestamp?: Maybe<Scalars['String']>;
  /** Custom Dimensions */
  ctaId?: Maybe<Scalars['String']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  ctaVersion?: Maybe<Scalars['String']>;
  ctaTheme?: Maybe<Scalars['String']>;
  /** @deprecated - will be constructed on the backend */
  ctaDetail?: Maybe<Scalars['String']>;
  widgetVersion?: Maybe<Scalars['String']>;
  widgetProduct?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  widgetCtaProduct?: Maybe<Scalars['String']>;
  widgetCtaType?: Maybe<Scalars['String']>;
  widgetCtaVersion?: Maybe<Scalars['String']>;
  widgetCtaTheme?: Maybe<Scalars['String']>;
  /** @deprecated - will be constructed on the backend */
  widgetCtaDetail?: Maybe<Scalars['String']>;
  /** Custom Metrics */
  ctaLoad?: Maybe<Scalars['Int']>;
  ctaOpened?: Maybe<Scalars['Int']>;
  userEngaged?: Maybe<Scalars['Int']>;
};

export type AnalyticsCta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  eventId: Scalars['Int'];
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticsCtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticsCtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticsCtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticsCtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticsCtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticsCtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticsCtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticsCtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticsCtaVariancePopulationAggregates>;
};

export type AnalyticsCtaAverageAggregates = {
  /** Mean average of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AnalyticsCta` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AnalyticsCtaCondition = {
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaType` field. */
  ctaType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventAction` field. */
  eventAction?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaDistinctCountAggregates = {
  /** Distinct count of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaProduct across the matching connection */
  ctaProduct?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaType across the matching connection */
  ctaType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventAction across the matching connection */
  eventAction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AnalyticsCta` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticsCtaFilter = {
  /** Filter by the object’s `eventId` field. */
  eventId?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaType` field. */
  ctaType?: Maybe<StringFilter>;
  /** Filter by the object’s `eventAction` field. */
  eventAction?: Maybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticsCtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticsCtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticsCtaFilter>;
};

/** Grouping methods for `AnalyticsCta` for usage during aggregation. */
export enum AnalyticsCtaGroupBy {
  CtaProduct = 'CTA_PRODUCT',
  CtaType = 'CTA_TYPE',
  EventAction = 'EVENT_ACTION',
  CompanyId = 'COMPANY_ID',
  ClientId = 'CLIENT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY'
}

export type AnalyticsCtaHavingAverageInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingDistinctCountInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `AnalyticsCta` aggregates. */
export type AnalyticsCtaHavingInput = {
  AND?: Maybe<Array<AnalyticsCtaHavingInput>>;
  OR?: Maybe<Array<AnalyticsCtaHavingInput>>;
  sum?: Maybe<AnalyticsCtaHavingSumInput>;
  distinctCount?: Maybe<AnalyticsCtaHavingDistinctCountInput>;
  min?: Maybe<AnalyticsCtaHavingMinInput>;
  max?: Maybe<AnalyticsCtaHavingMaxInput>;
  average?: Maybe<AnalyticsCtaHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsCtaHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsCtaHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsCtaHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsCtaHavingVariancePopulationInput>;
};

export type AnalyticsCtaHavingMaxInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingMinInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingStddevPopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingStddevSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingSumInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingVariancePopulationInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsCtaHavingVarianceSampleInput = {
  eventId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AnalyticsCta` */
export type AnalyticsCtaInput = {
  eventId: Scalars['Int'];
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaMaxAggregates = {
  /** Maximum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type AnalyticsCtaMinAggregates = {
  /** Minimum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `AnalyticsCta`. Fields that are set will be updated. */
export type AnalyticsCtaPatch = {
  eventId?: Maybe<Scalars['Int']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type AnalyticsCtaStddevPopulationAggregates = {
  /** Population standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaStddevSampleAggregates = {
  /** Sample standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSumAggregates = {
  /** Sum of eventId across the matching connection */
  eventId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A connection to a list of `AnalyticsCtaSummary` values. */
export type AnalyticsCtaSummariesConnection = {
  /** A list of `AnalyticsCtaSummary` objects. */
  nodes: Array<AnalyticsCtaSummary>;
  /** A list of edges which contains the `AnalyticsCtaSummary` and cursor to aid in pagination. */
  edges: Array<AnalyticsCtaSummariesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalyticsCtaSummary` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticsCtaSummaryAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticsCtaSummaryAggregates>>;
};


/** A connection to a list of `AnalyticsCtaSummary` values. */
export type AnalyticsCtaSummariesConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsCtaSummaryGroupBy>;
  having?: Maybe<AnalyticsCtaSummaryHavingInput>;
};

/** A `AnalyticsCtaSummary` edge in the connection. */
export type AnalyticsCtaSummariesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AnalyticsCtaSummary` at the end of the edge. */
  node: AnalyticsCtaSummary;
};

/** Methods to use when ordering `AnalyticsCtaSummary`. */
export enum AnalyticsCtaSummariesOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CtaProductAsc = 'CTA_PRODUCT_ASC',
  CtaProductDesc = 'CTA_PRODUCT_DESC',
  CtaTypeAsc = 'CTA_TYPE_ASC',
  CtaTypeDesc = 'CTA_TYPE_DESC',
  HourAsc = 'HOUR_ASC',
  HourDesc = 'HOUR_DESC',
  CtaLoadedAsc = 'CTA_LOADED_ASC',
  CtaLoadedDesc = 'CTA_LOADED_DESC',
  CtaImpressionsAsc = 'CTA_IMPRESSIONS_ASC',
  CtaImpressionsDesc = 'CTA_IMPRESSIONS_DESC',
  WidgetOpenedAsc = 'WIDGET_OPENED_ASC',
  WidgetOpenedDesc = 'WIDGET_OPENED_DESC',
  WidgetClosedAsc = 'WIDGET_CLOSED_ASC',
  WidgetClosedDesc = 'WIDGET_CLOSED_DESC',
  UserEngagementAsc = 'USER_ENGAGEMENT_ASC',
  UserEngagementDesc = 'USER_ENGAGEMENT_DESC',
  PartialLeadsAsc = 'PARTIAL_LEADS_ASC',
  PartialLeadsDesc = 'PARTIAL_LEADS_DESC',
  CreditLeadsAsc = 'CREDIT_LEADS_ASC',
  CreditLeadsDesc = 'CREDIT_LEADS_DESC',
  CreditAppLeadsAsc = 'CREDIT_APP_LEADS_ASC',
  CreditAppLeadsDesc = 'CREDIT_APP_LEADS_DESC',
  CreditVerifiedLeadsAsc = 'CREDIT_VERIFIED_LEADS_ASC',
  CreditVerifiedLeadsDesc = 'CREDIT_VERIFIED_LEADS_DESC',
  TradeLeadsAsc = 'TRADE_LEADS_ASC',
  TradeLeadsDesc = 'TRADE_LEADS_DESC'
}

export type AnalyticsCtaSummary = {
  companyId?: Maybe<Scalars['Int']>;
  ctaProduct?: Maybe<Scalars['String']>;
  ctaType?: Maybe<Scalars['String']>;
  hour?: Maybe<Scalars['Datetime']>;
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  widgetOpened?: Maybe<Scalars['BigInt']>;
  widgetClosed?: Maybe<Scalars['BigInt']>;
  userEngagement?: Maybe<Scalars['BigInt']>;
  partialLeads?: Maybe<Scalars['BigInt']>;
  creditLeads?: Maybe<Scalars['BigInt']>;
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AnalyticsCtaSummarySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AnalyticsCtaSummaryDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AnalyticsCtaSummaryMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AnalyticsCtaSummaryMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AnalyticsCtaSummaryAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AnalyticsCtaSummaryStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AnalyticsCtaSummaryStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AnalyticsCtaSummaryVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AnalyticsCtaSummaryVariancePopulationAggregates>;
};

export type AnalyticsCtaSummaryAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Mean average of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Mean average of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Mean average of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Mean average of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AnalyticsCtaSummary` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AnalyticsCtaSummaryCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaType` field. */
  ctaType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `hour` field. */
  hour?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `ctaLoaded` field. */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `ctaImpressions` field. */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `widgetOpened` field. */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `widgetClosed` field. */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `userEngagement` field. */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `partialLeads` field. */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditLeads` field. */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditAppLeads` field. */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `creditVerifiedLeads` field. */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `tradeLeads` field. */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaProduct across the matching connection */
  ctaProduct?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaType across the matching connection */
  ctaType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hour across the matching connection */
  hour?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Distinct count of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AnalyticsCtaSummary` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticsCtaSummaryFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaProduct` field. */
  ctaProduct?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaType` field. */
  ctaType?: Maybe<StringFilter>;
  /** Filter by the object’s `hour` field. */
  hour?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `ctaLoaded` field. */
  ctaLoaded?: Maybe<BigIntFilter>;
  /** Filter by the object’s `ctaImpressions` field. */
  ctaImpressions?: Maybe<BigIntFilter>;
  /** Filter by the object’s `widgetOpened` field. */
  widgetOpened?: Maybe<BigIntFilter>;
  /** Filter by the object’s `widgetClosed` field. */
  widgetClosed?: Maybe<BigIntFilter>;
  /** Filter by the object’s `userEngagement` field. */
  userEngagement?: Maybe<BigIntFilter>;
  /** Filter by the object’s `partialLeads` field. */
  partialLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditLeads` field. */
  creditLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditAppLeads` field. */
  creditAppLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `creditVerifiedLeads` field. */
  creditVerifiedLeads?: Maybe<BigIntFilter>;
  /** Filter by the object’s `tradeLeads` field. */
  tradeLeads?: Maybe<BigIntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AnalyticsCtaSummaryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AnalyticsCtaSummaryFilter>>;
  /** Negates the expression. */
  not?: Maybe<AnalyticsCtaSummaryFilter>;
};

/** Grouping methods for `AnalyticsCtaSummary` for usage during aggregation. */
export enum AnalyticsCtaSummaryGroupBy {
  CompanyId = 'COMPANY_ID',
  CtaProduct = 'CTA_PRODUCT',
  CtaType = 'CTA_TYPE',
  Hour = 'HOUR',
  HourTruncatedToHour = 'HOUR_TRUNCATED_TO_HOUR',
  HourTruncatedToDay = 'HOUR_TRUNCATED_TO_DAY',
  CtaLoaded = 'CTA_LOADED',
  CtaImpressions = 'CTA_IMPRESSIONS',
  WidgetOpened = 'WIDGET_OPENED',
  WidgetClosed = 'WIDGET_CLOSED',
  UserEngagement = 'USER_ENGAGEMENT',
  PartialLeads = 'PARTIAL_LEADS',
  CreditLeads = 'CREDIT_LEADS',
  CreditAppLeads = 'CREDIT_APP_LEADS',
  CreditVerifiedLeads = 'CREDIT_VERIFIED_LEADS',
  TradeLeads = 'TRADE_LEADS'
}

export type AnalyticsCtaSummaryHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

/** Conditions for `AnalyticsCtaSummary` aggregates. */
export type AnalyticsCtaSummaryHavingInput = {
  AND?: Maybe<Array<AnalyticsCtaSummaryHavingInput>>;
  OR?: Maybe<Array<AnalyticsCtaSummaryHavingInput>>;
  sum?: Maybe<AnalyticsCtaSummaryHavingSumInput>;
  distinctCount?: Maybe<AnalyticsCtaSummaryHavingDistinctCountInput>;
  min?: Maybe<AnalyticsCtaSummaryHavingMinInput>;
  max?: Maybe<AnalyticsCtaSummaryHavingMaxInput>;
  average?: Maybe<AnalyticsCtaSummaryHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsCtaSummaryHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsCtaSummaryHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsCtaSummaryHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsCtaSummaryHavingVariancePopulationInput>;
};

export type AnalyticsCtaSummaryHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  hour?: Maybe<HavingDatetimeFilter>;
  ctaLoaded?: Maybe<HavingBigintFilter>;
  ctaImpressions?: Maybe<HavingBigintFilter>;
  widgetOpened?: Maybe<HavingBigintFilter>;
  widgetClosed?: Maybe<HavingBigintFilter>;
  userEngagement?: Maybe<HavingBigintFilter>;
  partialLeads?: Maybe<HavingBigintFilter>;
  creditLeads?: Maybe<HavingBigintFilter>;
  creditAppLeads?: Maybe<HavingBigintFilter>;
  creditVerifiedLeads?: Maybe<HavingBigintFilter>;
  tradeLeads?: Maybe<HavingBigintFilter>;
};

export type AnalyticsCtaSummaryMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Maximum of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Maximum of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Maximum of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Maximum of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Maximum of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Maximum of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigInt']>;
  /** Minimum of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigInt']>;
  /** Minimum of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigInt']>;
  /** Minimum of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigInt']>;
  /** Minimum of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigInt']>;
  /** Minimum of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigInt']>;
  /** Minimum of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigInt']>;
};

export type AnalyticsCtaSummaryStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummaryStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummarySumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of ctaLoaded across the matching connection */
  ctaLoaded: Scalars['BigFloat'];
  /** Sum of ctaImpressions across the matching connection */
  ctaImpressions: Scalars['BigFloat'];
  /** Sum of widgetOpened across the matching connection */
  widgetOpened: Scalars['BigFloat'];
  /** Sum of widgetClosed across the matching connection */
  widgetClosed: Scalars['BigFloat'];
  /** Sum of userEngagement across the matching connection */
  userEngagement: Scalars['BigFloat'];
  /** Sum of partialLeads across the matching connection */
  partialLeads: Scalars['BigFloat'];
  /** Sum of creditLeads across the matching connection */
  creditLeads: Scalars['BigFloat'];
  /** Sum of creditAppLeads across the matching connection */
  creditAppLeads: Scalars['BigFloat'];
  /** Sum of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads: Scalars['BigFloat'];
  /** Sum of tradeLeads across the matching connection */
  tradeLeads: Scalars['BigFloat'];
};

export type AnalyticsCtaSummaryVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Population variance of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Population variance of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Population variance of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Population variance of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaSummaryVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of ctaLoaded across the matching connection */
  ctaLoaded?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of ctaImpressions across the matching connection */
  ctaImpressions?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of widgetOpened across the matching connection */
  widgetOpened?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of widgetClosed across the matching connection */
  widgetClosed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userEngagement across the matching connection */
  userEngagement?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of partialLeads across the matching connection */
  partialLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditLeads across the matching connection */
  creditLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditAppLeads across the matching connection */
  creditAppLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditVerifiedLeads across the matching connection */
  creditVerifiedLeads?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tradeLeads across the matching connection */
  tradeLeads?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaVariancePopulationAggregates = {
  /** Population variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type AnalyticsCtaVarianceSampleAggregates = {
  /** Sample variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AnalyticsCta` values. */
export type AnalyticsCtasConnection = {
  /** A list of `AnalyticsCta` objects. */
  nodes: Array<AnalyticsCta>;
  /** A list of edges which contains the `AnalyticsCta` and cursor to aid in pagination. */
  edges: Array<AnalyticsCtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AnalyticsCta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AnalyticsCtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AnalyticsCtaAggregates>>;
};


/** A connection to a list of `AnalyticsCta` values. */
export type AnalyticsCtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<AnalyticsCtaGroupBy>;
  having?: Maybe<AnalyticsCtaHavingInput>;
};

/** A `AnalyticsCta` edge in the connection. */
export type AnalyticsCtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AnalyticsCta` at the end of the edge. */
  node: AnalyticsCta;
};

/** Methods to use when ordering `AnalyticsCta`. */
export enum AnalyticsCtasOrderBy {
  Natural = 'NATURAL',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  CtaProductAsc = 'CTA_PRODUCT_ASC',
  CtaProductDesc = 'CTA_PRODUCT_DESC',
  CtaTypeAsc = 'CTA_TYPE_ASC',
  CtaTypeDesc = 'CTA_TYPE_DESC',
  EventActionAsc = 'EVENT_ACTION_ASC',
  EventActionDesc = 'EVENT_ACTION_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A `Analytic` edge in the connection. */
export type AnalyticsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Analytic` at the end of the edge. */
  node: Analytic;
};

export enum AnalyticsEventAction {
  PageView = 'pageView',
  CtaLoaded = 'ctaLoaded',
  CtaImpression = 'ctaImpression',
  WidgetOpened = 'widgetOpened',
  WidgetClosed = 'widgetClosed',
  UserEngaged = 'userEngaged',
  CreditReportReceived = 'creditReportReceived',
  PartialLeadSubmit = 'partialLeadSubmit',
  CreditToolSubmit = 'creditToolSubmit',
  TradeToolSubmit = 'tradeToolSubmit',
  CreditAppSubmit = 'creditAppSubmit',
  LenderToolSubmit = 'lenderToolSubmit',
  LenderToolOfferSelect = 'lenderToolOfferSelect'
}

export type AnalyticsEventResponse = {
  success: Scalars['Boolean'];
  eventAction: Scalars['String'];
  eventCategory: Scalars['String'];
  /** @deprecated Goes into the itesm in the analyticsPayload array */
  eventType?: Maybe<Scalars['String']>;
  analyticsPayload?: Maybe<Array<Scalars['JSON']>>;
};

/** Grouping methods for `Analytic` for usage during aggregation. */
export enum AnalyticsGroupBy {
  ClientId = 'CLIENT_ID',
  RequestId = 'REQUEST_ID',
  EventCategory = 'EVENT_CATEGORY',
  EventAction = 'EVENT_ACTION',
  SourceUrl = 'SOURCE_URL',
  ReferrerUrl = 'REFERRER_URL',
  UserAgent = 'USER_AGENT',
  UserIp = 'USER_IP',
  CompanyId = 'COMPANY_ID',
  Context = 'CONTEXT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type AnalyticsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Analytic` aggregates. */
export type AnalyticsHavingInput = {
  AND?: Maybe<Array<AnalyticsHavingInput>>;
  OR?: Maybe<Array<AnalyticsHavingInput>>;
  sum?: Maybe<AnalyticsHavingSumInput>;
  distinctCount?: Maybe<AnalyticsHavingDistinctCountInput>;
  min?: Maybe<AnalyticsHavingMinInput>;
  max?: Maybe<AnalyticsHavingMaxInput>;
  average?: Maybe<AnalyticsHavingAverageInput>;
  stddevSample?: Maybe<AnalyticsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AnalyticsHavingStddevPopulationInput>;
  varianceSample?: Maybe<AnalyticsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AnalyticsHavingVariancePopulationInput>;
};

export type AnalyticsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AnalyticsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Analytic`. */
export enum AnalyticsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  RequestIdAsc = 'REQUEST_ID_ASC',
  RequestIdDesc = 'REQUEST_ID_DESC',
  EventCategoryAsc = 'EVENT_CATEGORY_ASC',
  EventCategoryDesc = 'EVENT_CATEGORY_DESC',
  EventActionAsc = 'EVENT_ACTION_ASC',
  EventActionDesc = 'EVENT_ACTION_DESC',
  SourceUrlAsc = 'SOURCE_URL_ASC',
  SourceUrlDesc = 'SOURCE_URL_DESC',
  ReferrerUrlAsc = 'REFERRER_URL_ASC',
  ReferrerUrlDesc = 'REFERRER_URL_DESC',
  UserAgentAsc = 'USER_AGENT_ASC',
  UserAgentDesc = 'USER_AGENT_DESC',
  UserIpAsc = 'USER_IP_ASC',
  UserIpDesc = 'USER_IP_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContextAsc = 'CONTEXT_ASC',
  ContextDesc = 'CONTEXT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AnalyticsParamsEvents = {
  eventAction: AnalyticsEventAction;
  context?: Maybe<AnalyticsContext>;
  internalCall?: Maybe<Scalars['Boolean']>;
  timeStamp?: Maybe<Scalars['Datetime']>;
};

/** @deprecated */
export type AnalyticsParamsInput = {
  eventAction: AnalyticsEventAction;
  widgetId: Scalars['String'];
  sourceUrl: Scalars['String'];
  referrer: Scalars['String'];
  internalCall?: Maybe<Scalars['Boolean']>;
  context?: Maybe<AnalyticsContext>;
  sessionId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
};

export type AnalyticsParamsInput2 = {
  widgetId: Scalars['String'];
  sourceUrl: Scalars['String'];
  referrer: Scalars['String'];
};

export type AnalyticsResponse = {
  success: Scalars['Boolean'];
  events: Array<AnalyticsEventResponse>;
};

export enum AnalyticsType {
  GoogleUa = 'GOOGLE_UA',
  GoogleA4 = 'GOOGLE_A4',
  MetaPixel = 'META_PIXEL'
}

/** A filter to be used against AnalyticsType fields. All fields are combined with a logical ‘and.’ */
export type AnalyticsTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<AnalyticsType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<AnalyticsType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<AnalyticsType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<AnalyticsType>;
  /** Included in the specified list. */
  in?: Maybe<Array<AnalyticsType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<AnalyticsType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<AnalyticsType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<AnalyticsType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<AnalyticsType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<AnalyticsType>;
};

export type Appointment = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt: Scalars['Datetime'];
  confirmedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
};

export type AppointmentAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AppointmentSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AppointmentDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AppointmentMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AppointmentMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AppointmentAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AppointmentStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AppointmentStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AppointmentVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AppointmentVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Appointment` object types. */
export type AppointmentAggregatesFilter = {
  /** A filter that must pass for the relevant `Appointment` object to be included within the aggregate. */
  filter?: Maybe<AppointmentFilter>;
  /** Sum aggregate over matching `Appointment` objects. */
  sum?: Maybe<AppointmentSumAggregateFilter>;
  /** Distinct count aggregate over matching `Appointment` objects. */
  distinctCount?: Maybe<AppointmentDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Appointment` objects. */
  min?: Maybe<AppointmentMinAggregateFilter>;
  /** Maximum aggregate over matching `Appointment` objects. */
  max?: Maybe<AppointmentMaxAggregateFilter>;
  /** Mean average aggregate over matching `Appointment` objects. */
  average?: Maybe<AppointmentAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Appointment` objects. */
  stddevSample?: Maybe<AppointmentStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Appointment` objects. */
  stddevPopulation?: Maybe<AppointmentStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Appointment` objects. */
  varianceSample?: Maybe<AppointmentVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Appointment` objects. */
  variancePopulation?: Maybe<AppointmentVariancePopulationAggregateFilter>;
};

/** The fields on `appointment` to look up the row to connect. */
export type AppointmentAppointmentsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `appointment` to look up the row to delete. */
export type AppointmentAppointmentsPkeyDelete = {
  id: Scalars['Int'];
};

export type AppointmentAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Appointment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppointmentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `appointmentAt` field. */
  appointmentAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `confirmedAt` field. */
  confirmedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type AppointmentDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
  additionalInformation?: Maybe<BigIntFilter>;
  appointmentAt?: Maybe<BigIntFilter>;
  confirmedAt?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type AppointmentDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of additionalInformation across the matching connection */
  additionalInformation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of appointmentAt across the matching connection */
  appointmentAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of confirmedAt across the matching connection */
  confirmedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type AppointmentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<IntFilter>;
  /** Filter by the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<StringFilter>;
  /** Filter by the object’s `appointmentAt` field. */
  appointmentAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `confirmedAt` field. */
  confirmedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `vehicleListing` relation. */
  vehicleListing?: Maybe<VehicleListingFilter>;
  /** A related `vehicleListing` exists. */
  vehicleListingExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AppointmentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AppointmentFilter>>;
  /** Negates the expression. */
  not?: Maybe<AppointmentFilter>;
};

/** Grouping methods for `Appointment` for usage during aggregation. */
export enum AppointmentGroupBy {
  LeadId = 'LEAD_ID',
  VehicleListingId = 'VEHICLE_LISTING_ID',
  AdditionalInformation = 'ADDITIONAL_INFORMATION',
  AppointmentAt = 'APPOINTMENT_AT',
  AppointmentAtTruncatedToHour = 'APPOINTMENT_AT_TRUNCATED_TO_HOUR',
  AppointmentAtTruncatedToDay = 'APPOINTMENT_AT_TRUNCATED_TO_DAY',
  ConfirmedAt = 'CONFIRMED_AT',
  ConfirmedAtTruncatedToHour = 'CONFIRMED_AT_TRUNCATED_TO_HOUR',
  ConfirmedAtTruncatedToDay = 'CONFIRMED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type AppointmentHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Appointment` aggregates. */
export type AppointmentHavingInput = {
  AND?: Maybe<Array<AppointmentHavingInput>>;
  OR?: Maybe<Array<AppointmentHavingInput>>;
  sum?: Maybe<AppointmentHavingSumInput>;
  distinctCount?: Maybe<AppointmentHavingDistinctCountInput>;
  min?: Maybe<AppointmentHavingMinInput>;
  max?: Maybe<AppointmentHavingMaxInput>;
  average?: Maybe<AppointmentHavingAverageInput>;
  stddevSample?: Maybe<AppointmentHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AppointmentHavingStddevPopulationInput>;
  varianceSample?: Maybe<AppointmentHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AppointmentHavingVariancePopulationInput>;
};

export type AppointmentHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AppointmentHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
  appointmentAt?: Maybe<HavingDatetimeFilter>;
  confirmedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Appointment` */
export type AppointmentInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

export type AppointmentMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type AppointmentMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

export type AppointmentMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type AppointmentMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AppointmentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AppointmentNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `appointment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppointmentOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `appointment` to look up the row to update. */
export type AppointmentOnAppointmentForAppointmentsLeadIdFkeyUsingAppointmentsPkeyUpdate = {
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: UpdateAppointmentOnAppointmentForAppointmentsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** The fields on `appointment` to look up the row to update. */
export type AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingAppointmentsPkeyUpdate = {
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: UpdateAppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Appointment`. Fields that are set will be updated. */
export type AppointmentPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

export type AppointmentStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
};

export type AppointmentSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of vehicleListingId across the matching connection */
  vehicleListingId: Scalars['BigInt'];
};

export type AppointmentVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type AppointmentVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type AppointmentVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Appointment` values. */
export type AppointmentsConnection = {
  /** A list of `Appointment` objects. */
  nodes: Array<Appointment>;
  /** A list of edges which contains the `Appointment` and cursor to aid in pagination. */
  edges: Array<AppointmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Appointment` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AppointmentAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AppointmentAggregates>>;
};


/** A connection to a list of `Appointment` values. */
export type AppointmentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AppointmentGroupBy>;
  having?: Maybe<AppointmentHavingInput>;
};

/** A `Appointment` edge in the connection. */
export type AppointmentsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Appointment` at the end of the edge. */
  node: Appointment;
};

/** The `appointment` to be created by this mutation. */
export type AppointmentsLeadIdFkeyAppointmentCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `AppointmentInput` mutation. */
export type AppointmentsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnAppointmentForAppointmentsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppointmentOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<AppointmentsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `appointment` in the `LeadInput` mutation. */
export type AppointmentsLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `appointment` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectById?: Maybe<Array<AppointmentAppointmentsPkeyConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppointmentNodeIdConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppointmentAppointmentsPkeyDelete>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppointmentNodeIdDelete>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateById?: Maybe<Array<AppointmentOnAppointmentForAppointmentsLeadIdFkeyUsingAppointmentsPkeyUpdate>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate>>;
  /** A `AppointmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppointmentsLeadIdFkeyAppointmentCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type AppointmentsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `Appointment`. */
export enum AppointmentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  VehicleListingIdAsc = 'VEHICLE_LISTING_ID_ASC',
  VehicleListingIdDesc = 'VEHICLE_LISTING_ID_DESC',
  AdditionalInformationAsc = 'ADDITIONAL_INFORMATION_ASC',
  AdditionalInformationDesc = 'ADDITIONAL_INFORMATION_DESC',
  AppointmentAtAsc = 'APPOINTMENT_AT_ASC',
  AppointmentAtDesc = 'APPOINTMENT_AT_DESC',
  ConfirmedAtAsc = 'CONFIRMED_AT_ASC',
  ConfirmedAtDesc = 'CONFIRMED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `appointment` to be created by this mutation. */
export type AppointmentsVehicleListingsIdFkeyAppointmentCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** Input for the nested mutation of `vehicleListing` in the `AppointmentInput` mutation. */
export type AppointmentsVehicleListingsIdFkeyInput = {
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectById?: Maybe<VehicleListingVehicleListingsPkeyConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<VehicleListingNodeIdConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteById?: Maybe<VehicleListingVehicleListingsPkeyDelete>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<VehicleListingNodeIdDelete>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateById?: Maybe<VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingVehicleListingsPkeyUpdate>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate>;
  /** A `VehicleListingInput` object that will be created and connected to this object. */
  create?: Maybe<AppointmentsVehicleListingsIdFkeyVehicleListingCreateInput>;
};

/** Input for the nested mutation of `appointment` in the `VehicleListingInput` mutation. */
export type AppointmentsVehicleListingsIdFkeyInverseInput = {
  /** Flag indicating whether all other `appointment` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectById?: Maybe<Array<AppointmentAppointmentsPkeyConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AppointmentNodeIdConnect>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteById?: Maybe<Array<AppointmentAppointmentsPkeyDelete>>;
  /** The primary key(s) for `appointment` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<AppointmentNodeIdDelete>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateById?: Maybe<Array<AppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingAppointmentsPkeyUpdate>>;
  /** The primary key(s) and patch data for `appointment` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate>>;
  /** A `AppointmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AppointmentsVehicleListingsIdFkeyAppointmentCreateInput>>;
};

/** The `vehicleListing` to be created by this mutation. */
export type AppointmentsVehicleListingsIdFkeyVehicleListingCreateInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

export type ArchiveCompanyInput = {
  companyId: Scalars['Int'];
  archive: Scalars['Boolean'];
};

export type ArchiveCompanyResponse = {
  companyId: Scalars['Int'];
  archived: Scalars['Boolean'];
  usersModified: Array<UserDetails>;
};

export type AssessmentComplete = {
  passed?: Maybe<Scalars['Boolean']>;
  reasons?: Maybe<Array<Maybe<AssessmentCompleteReasons>>>;
};

export type AssessmentCompleteReasons = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** The auth challenge details */
export type AuthChallenge = {
  session?: Maybe<Scalars['String']>;
  challengeName: AuthChallengeType;
  challengeParameters?: Maybe<Array<Maybe<AuthChallengeParameter>>>;
};

/** Auth challenge parameters to use in your challenge response */
export type AuthChallengeParameter = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AuthChallengeResponse = {
  NEW_PASSWORD_REQUIRED?: Maybe<AuthNewPasswordRequired>;
  SMS_MFA?: Maybe<AuthSmsMfa>;
  PASSWORD_VERIFIER?: Maybe<AuthPasswordVerifier>;
  SOFTWARE_TOKEN_MFA?: Maybe<AuthSoftwareTokenMfa>;
  DEVICE_SRP_AUTH?: Maybe<AuthDeviceSrpAuth>;
  DEVICE_PASSWORD_VERIFIER?: Maybe<AuthDevicePasswordVerifier>;
  MFA_SETUP?: Maybe<AuthMfaSetup>;
};

/** The name of the challenge which you are responding to with this call. */
export enum AuthChallengeType {
  SmaMfa = 'SMA_MFA',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
  SelectMfaType = 'SELECT_MFA_TYPE',
  AdminNoSrpAuth = 'ADMIN_NO_SRP_AUTH',
  PasswordVerifier = 'PASSWORD_VERIFIER',
  CustomChallenge = 'CUSTOM_CHALLENGE',
  DeviceSrpAuth = 'DEVICE_SRP_AUTH',
  DevicePasswordVerifier = 'DEVICE_PASSWORD_VERIFIER',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MfaSetup = 'MFA_SETUP',
  NotSupported = 'NOT_SUPPORTED'
}

export type AuthDevicePasswordVerifier = {
  USERNAME: Scalars['String'];
  PASSWORD_CLAIM_SIGNATURE: Scalars['String'];
  PASSWORD_CLAIM_SECRET_BLOCK: Scalars['String'];
  TIMESTAMP: Scalars['String'];
  DEVICE_KEY: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthDeviceSrpAuth = {
  USERNAME: Scalars['String'];
  SRP_A: Scalars['String'];
  DEVICE_KEY: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

/** Auth error */
export type AuthError = {
  code?: Maybe<AuthErrorCode>;
  statusCode?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export enum AuthErrorCode {
  InternalErrorException = 'InternalErrorException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidSmsRoleAccessPolicyException = 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException = 'InvalidSmsRoleTrustRelationshipException',
  InvalidUserPoolConfigurationException = 'InvalidUserPoolConfigurationException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException'
}

export type AuthMfaSetup = {
  USERNAME: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthNewDeviceMeta = {
  deviceKey?: Maybe<Scalars['String']>;
  deviceGroupKey?: Maybe<Scalars['String']>;
};

export type AuthNewPasswordRequired = {
  USERNAME: Scalars['String'];
  NEW_PASSWORD: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthPasswordVerifier = {
  USERNAME: Scalars['String'];
  PASSWORD_CLAIM_SIGNATURE: Scalars['String'];
  PASSWORD_CLAIM_SECRET_BLOCK: Scalars['String'];
  TIMESTAMP: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthPayload = AuthError | AuthResult;

/** Authentication results */
export type AuthResult = {
  /** Access token. */
  accessToken?: Maybe<Scalars['String']>;
  /** ID token. */
  idToken?: Maybe<Scalars['String']>;
  /** Token type. */
  tokenType?: Maybe<Scalars['String']>;
  /** Expiration. */
  expiresIn?: Maybe<Scalars['Int']>;
  /** The new device metadata from an authentication result */
  newDeviceMetadata?: Maybe<AuthNewDeviceMeta>;
  /** Challenge name type */
  challenge?: Maybe<AuthChallenge>;
};

export type AuthSignOutResult = {
  success: Scalars['Boolean'];
};

export type AuthSmsMfa = {
  USERNAME: Scalars['String'];
  SMS_MFA_CODE: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AuthSoftwareTokenMfa = {
  USERNAME: Scalars['String'];
  SOFTWARE_TOKEN_MFA_CODE: Scalars['String'];
  SECRET_HASH?: Maybe<Scalars['String']>;
};

export type AutoBasicInfo = {
  styleId?: Maybe<Scalars['String']>;
  msrp?: Maybe<Scalars['String']>;
  invoice?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  styleName?: Maybe<Scalars['String']>;
  trimName?: Maybe<Scalars['String']>;
  trimSubStyleName?: Maybe<Scalars['String']>;
  makeName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['String']>;
  marketClass?: Maybe<Scalars['String']>;
  bodyType?: Maybe<Scalars['String']>;
  driveTrain?: Maybe<Scalars['String']>;
};

/** Vehicle configuration information */
export type AutoConfiguration = {
  coreVehicleData?: Maybe<AutoBasicInfo>;
  standardFeatures?: Maybe<Array<Maybe<AutoStdEquipment>>>;
  optionalFeatures?: Maybe<Array<Maybe<AutoOptions>>>;
  genericFeatures?: Maybe<Array<Maybe<AutoFeatureData>>>;
  techSpecs?: Maybe<Array<Maybe<AutoFeatureData>>>;
  warranty?: Maybe<Array<Maybe<AutoFeatureData>>>;
  bodyTypeHints?: Maybe<BodyTypeHints>;
  numberOfSeats?: Maybe<Scalars['String']>;
  numberOfDoors?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  styleGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AutoFeatureData = {
  featureId?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  measurementUnit?: Maybe<Scalars['String']>;
};

export type AutoImageWithMetaData = {
  href?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  shotCode?: Maybe<Scalars['String']>;
};

export type AutoImages = {
  whiteBg?: Maybe<Array<Maybe<AutoImageWithMetaData>>>;
  transparentBg?: Maybe<Array<Maybe<AutoImageWithMetaData>>>;
};

export type AutoOptions = {
  headerId?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  selectionState?: Maybe<Scalars['String']>;
};

export type AutoStdEquipment = {
  headerId?: Maybe<Scalars['String']>;
  headerName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  upgrade?: Maybe<Scalars['String']>;
};

export enum AutocorpPermissions {
  None = 'NONE',
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN'
}

export type AvaApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  creditRange?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** Reads and enables pagination through a set of `CreditBureau`. */
  creditBureaus: CreditBureausConnection;
};


export type AvaAppCreditBureausArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
  condition?: Maybe<CreditBureauCondition>;
  filter?: Maybe<CreditBureauFilter>;
};

export type AvaAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AvaAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AvaAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AvaAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AvaAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AvaAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AvaAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AvaAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AvaAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AvaAppVariancePopulationAggregates>;
};

/** The fields on `avaApp` to look up the row to connect. */
export type AvaAppAvaAppLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to connect. */
export type AvaAppAvaAppPkeyConnect = {
  id: Scalars['Int'];
};

export type AvaAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `AvaApp` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AvaAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `creditVerified` field. */
  creditVerified?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `dealerCreditConsent` field. */
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `creditScore` field. */
  creditScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creditRange` field. */
  creditRange?: Maybe<Scalars['String']>;
};

export type AvaAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditVerified across the matching connection */
  creditVerified?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dealerCreditConsent across the matching connection */
  dealerCreditConsent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditRange across the matching connection */
  creditRange?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AvaApp` object types. All fields are combined with a logical ‘and.’ */
export type AvaAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `creditVerified` field. */
  creditVerified?: Maybe<BooleanFilter>;
  /** Filter by the object’s `dealerCreditConsent` field. */
  dealerCreditConsent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `creditScore` field. */
  creditScore?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `creditRange` field. */
  creditRange?: Maybe<StringFilter>;
  /** Filter by the object’s `creditBureaus` relation. */
  creditBureaus?: Maybe<AvaAppToManyCreditBureauFilter>;
  /** Some related `creditBureaus` exist. */
  creditBureausExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AvaAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AvaAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<AvaAppFilter>;
};

/** Grouping methods for `AvaApp` for usage during aggregation. */
export enum AvaAppGroupBy {
  CreditVerified = 'CREDIT_VERIFIED',
  DealerCreditConsent = 'DEALER_CREDIT_CONSENT',
  CreditScore = 'CREDIT_SCORE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CreditRange = 'CREDIT_RANGE'
}

export type AvaAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `AvaApp` aggregates. */
export type AvaAppHavingInput = {
  AND?: Maybe<Array<AvaAppHavingInput>>;
  OR?: Maybe<Array<AvaAppHavingInput>>;
  sum?: Maybe<AvaAppHavingSumInput>;
  distinctCount?: Maybe<AvaAppHavingDistinctCountInput>;
  min?: Maybe<AvaAppHavingMinInput>;
  max?: Maybe<AvaAppHavingMaxInput>;
  average?: Maybe<AvaAppHavingAverageInput>;
  stddevSample?: Maybe<AvaAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<AvaAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<AvaAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<AvaAppHavingVariancePopulationInput>;
};

export type AvaAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type AvaAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AvaApp` */
export type AvaAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** The `avaApp` to be created by this mutation. */
export type AvaAppLeadIdFkeyAvaAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lead` in the `AvaAppInput` mutation. */
export type AvaAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnAvaAppForAvaAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<AvaAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `avaApp` in the `LeadInput` mutation. */
export type AvaAppLeadIdFkeyInverseInput = {
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectById?: Maybe<AvaAppAvaAppPkeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByLeadId?: Maybe<AvaAppAvaAppLeadIdKeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AvaAppNodeIdConnect>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateById?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppPkeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByLeadId?: Maybe<AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate>;
  /** A `AvaAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AvaAppLeadIdFkeyAvaAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type AvaAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type AvaAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

export type AvaAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AvaAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnAvaAppForAvaAppLeadIdFkeyUsingAvaAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `creditBureau` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: CreditBureauPatch;
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `avaApp` to look up the row to update. */
export type AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `AvaApp`. Fields that are set will be updated. */
export type AvaAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

export type AvaAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of creditScore across the matching connection */
  creditScore: Scalars['BigInt'];
};

/** A filter to be used against many `CreditBureau` object types. All fields are combined with a logical ‘and.’ */
export type AvaAppToManyCreditBureauFilter = {
  /** Every related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CreditBureauFilter>;
  /** Some related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CreditBureauFilter>;
  /** No related `CreditBureau` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CreditBureauFilter>;
  /** Aggregates across related `CreditBureau` match the filter criteria. */
  aggregates?: Maybe<CreditBureauAggregatesFilter>;
};

export type AvaAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type AvaAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AvaApp` values. */
export type AvaAppsConnection = {
  /** A list of `AvaApp` objects. */
  nodes: Array<AvaApp>;
  /** A list of edges which contains the `AvaApp` and cursor to aid in pagination. */
  edges: Array<AvaAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AvaApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AvaAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AvaAppAggregates>>;
};


/** A connection to a list of `AvaApp` values. */
export type AvaAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AvaAppGroupBy>;
  having?: Maybe<AvaAppHavingInput>;
};

/** A `AvaApp` edge in the connection. */
export type AvaAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AvaApp` at the end of the edge. */
  node: AvaApp;
};

/** Methods to use when ordering `AvaApp`. */
export enum AvaAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  CreditVerifiedAsc = 'CREDIT_VERIFIED_ASC',
  CreditVerifiedDesc = 'CREDIT_VERIFIED_DESC',
  DealerCreditConsentAsc = 'DEALER_CREDIT_CONSENT_ASC',
  DealerCreditConsentDesc = 'DEALER_CREDIT_CONSENT_DESC',
  CreditScoreAsc = 'CREDIT_SCORE_ASC',
  CreditScoreDesc = 'CREDIT_SCORE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CreditRangeAsc = 'CREDIT_RANGE_ASC',
  CreditRangeDesc = 'CREDIT_RANGE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CreditBureausCountAsc = 'CREDIT_BUREAUS_COUNT_ASC',
  CreditBureausCountDesc = 'CREDIT_BUREAUS_COUNT_DESC',
  CreditBureausSumIdAsc = 'CREDIT_BUREAUS_SUM_ID_ASC',
  CreditBureausSumIdDesc = 'CREDIT_BUREAUS_SUM_ID_DESC',
  CreditBureausSumAvaAppIdAsc = 'CREDIT_BUREAUS_SUM_AVA_APP_ID_ASC',
  CreditBureausSumAvaAppIdDesc = 'CREDIT_BUREAUS_SUM_AVA_APP_ID_DESC',
  CreditBureausSumTypeAsc = 'CREDIT_BUREAUS_SUM_TYPE_ASC',
  CreditBureausSumTypeDesc = 'CREDIT_BUREAUS_SUM_TYPE_DESC',
  CreditBureausSumCreatedAtAsc = 'CREDIT_BUREAUS_SUM_CREATED_AT_ASC',
  CreditBureausSumCreatedAtDesc = 'CREDIT_BUREAUS_SUM_CREATED_AT_DESC',
  CreditBureausSumCreditScoreAsc = 'CREDIT_BUREAUS_SUM_CREDIT_SCORE_ASC',
  CreditBureausSumCreditScoreDesc = 'CREDIT_BUREAUS_SUM_CREDIT_SCORE_DESC',
  CreditBureausSumRawBureauAsc = 'CREDIT_BUREAUS_SUM_RAW_BUREAU_ASC',
  CreditBureausSumRawBureauDesc = 'CREDIT_BUREAUS_SUM_RAW_BUREAU_DESC',
  CreditBureausSumUpdatedAtAsc = 'CREDIT_BUREAUS_SUM_UPDATED_AT_ASC',
  CreditBureausSumUpdatedAtDesc = 'CREDIT_BUREAUS_SUM_UPDATED_AT_DESC',
  CreditBureausSumRawExpiredAsc = 'CREDIT_BUREAUS_SUM_RAW_EXPIRED_ASC',
  CreditBureausSumRawExpiredDesc = 'CREDIT_BUREAUS_SUM_RAW_EXPIRED_DESC',
  CreditBureausSumXmlAsc = 'CREDIT_BUREAUS_SUM_XML_ASC',
  CreditBureausSumXmlDesc = 'CREDIT_BUREAUS_SUM_XML_DESC',
  CreditBureausDistinctCountIdAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_ID_ASC',
  CreditBureausDistinctCountIdDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_ID_DESC',
  CreditBureausDistinctCountAvaAppIdAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_AVA_APP_ID_ASC',
  CreditBureausDistinctCountAvaAppIdDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_AVA_APP_ID_DESC',
  CreditBureausDistinctCountTypeAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_TYPE_ASC',
  CreditBureausDistinctCountTypeDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_TYPE_DESC',
  CreditBureausDistinctCountCreatedAtAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREATED_AT_ASC',
  CreditBureausDistinctCountCreatedAtDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREATED_AT_DESC',
  CreditBureausDistinctCountCreditScoreAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREDIT_SCORE_ASC',
  CreditBureausDistinctCountCreditScoreDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_CREDIT_SCORE_DESC',
  CreditBureausDistinctCountRawBureauAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_BUREAU_ASC',
  CreditBureausDistinctCountRawBureauDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_BUREAU_DESC',
  CreditBureausDistinctCountUpdatedAtAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CreditBureausDistinctCountUpdatedAtDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CreditBureausDistinctCountRawExpiredAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_EXPIRED_ASC',
  CreditBureausDistinctCountRawExpiredDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_RAW_EXPIRED_DESC',
  CreditBureausDistinctCountXmlAsc = 'CREDIT_BUREAUS_DISTINCT_COUNT_XML_ASC',
  CreditBureausDistinctCountXmlDesc = 'CREDIT_BUREAUS_DISTINCT_COUNT_XML_DESC',
  CreditBureausMinIdAsc = 'CREDIT_BUREAUS_MIN_ID_ASC',
  CreditBureausMinIdDesc = 'CREDIT_BUREAUS_MIN_ID_DESC',
  CreditBureausMinAvaAppIdAsc = 'CREDIT_BUREAUS_MIN_AVA_APP_ID_ASC',
  CreditBureausMinAvaAppIdDesc = 'CREDIT_BUREAUS_MIN_AVA_APP_ID_DESC',
  CreditBureausMinTypeAsc = 'CREDIT_BUREAUS_MIN_TYPE_ASC',
  CreditBureausMinTypeDesc = 'CREDIT_BUREAUS_MIN_TYPE_DESC',
  CreditBureausMinCreatedAtAsc = 'CREDIT_BUREAUS_MIN_CREATED_AT_ASC',
  CreditBureausMinCreatedAtDesc = 'CREDIT_BUREAUS_MIN_CREATED_AT_DESC',
  CreditBureausMinCreditScoreAsc = 'CREDIT_BUREAUS_MIN_CREDIT_SCORE_ASC',
  CreditBureausMinCreditScoreDesc = 'CREDIT_BUREAUS_MIN_CREDIT_SCORE_DESC',
  CreditBureausMinRawBureauAsc = 'CREDIT_BUREAUS_MIN_RAW_BUREAU_ASC',
  CreditBureausMinRawBureauDesc = 'CREDIT_BUREAUS_MIN_RAW_BUREAU_DESC',
  CreditBureausMinUpdatedAtAsc = 'CREDIT_BUREAUS_MIN_UPDATED_AT_ASC',
  CreditBureausMinUpdatedAtDesc = 'CREDIT_BUREAUS_MIN_UPDATED_AT_DESC',
  CreditBureausMinRawExpiredAsc = 'CREDIT_BUREAUS_MIN_RAW_EXPIRED_ASC',
  CreditBureausMinRawExpiredDesc = 'CREDIT_BUREAUS_MIN_RAW_EXPIRED_DESC',
  CreditBureausMinXmlAsc = 'CREDIT_BUREAUS_MIN_XML_ASC',
  CreditBureausMinXmlDesc = 'CREDIT_BUREAUS_MIN_XML_DESC',
  CreditBureausMaxIdAsc = 'CREDIT_BUREAUS_MAX_ID_ASC',
  CreditBureausMaxIdDesc = 'CREDIT_BUREAUS_MAX_ID_DESC',
  CreditBureausMaxAvaAppIdAsc = 'CREDIT_BUREAUS_MAX_AVA_APP_ID_ASC',
  CreditBureausMaxAvaAppIdDesc = 'CREDIT_BUREAUS_MAX_AVA_APP_ID_DESC',
  CreditBureausMaxTypeAsc = 'CREDIT_BUREAUS_MAX_TYPE_ASC',
  CreditBureausMaxTypeDesc = 'CREDIT_BUREAUS_MAX_TYPE_DESC',
  CreditBureausMaxCreatedAtAsc = 'CREDIT_BUREAUS_MAX_CREATED_AT_ASC',
  CreditBureausMaxCreatedAtDesc = 'CREDIT_BUREAUS_MAX_CREATED_AT_DESC',
  CreditBureausMaxCreditScoreAsc = 'CREDIT_BUREAUS_MAX_CREDIT_SCORE_ASC',
  CreditBureausMaxCreditScoreDesc = 'CREDIT_BUREAUS_MAX_CREDIT_SCORE_DESC',
  CreditBureausMaxRawBureauAsc = 'CREDIT_BUREAUS_MAX_RAW_BUREAU_ASC',
  CreditBureausMaxRawBureauDesc = 'CREDIT_BUREAUS_MAX_RAW_BUREAU_DESC',
  CreditBureausMaxUpdatedAtAsc = 'CREDIT_BUREAUS_MAX_UPDATED_AT_ASC',
  CreditBureausMaxUpdatedAtDesc = 'CREDIT_BUREAUS_MAX_UPDATED_AT_DESC',
  CreditBureausMaxRawExpiredAsc = 'CREDIT_BUREAUS_MAX_RAW_EXPIRED_ASC',
  CreditBureausMaxRawExpiredDesc = 'CREDIT_BUREAUS_MAX_RAW_EXPIRED_DESC',
  CreditBureausMaxXmlAsc = 'CREDIT_BUREAUS_MAX_XML_ASC',
  CreditBureausMaxXmlDesc = 'CREDIT_BUREAUS_MAX_XML_DESC',
  CreditBureausAverageIdAsc = 'CREDIT_BUREAUS_AVERAGE_ID_ASC',
  CreditBureausAverageIdDesc = 'CREDIT_BUREAUS_AVERAGE_ID_DESC',
  CreditBureausAverageAvaAppIdAsc = 'CREDIT_BUREAUS_AVERAGE_AVA_APP_ID_ASC',
  CreditBureausAverageAvaAppIdDesc = 'CREDIT_BUREAUS_AVERAGE_AVA_APP_ID_DESC',
  CreditBureausAverageTypeAsc = 'CREDIT_BUREAUS_AVERAGE_TYPE_ASC',
  CreditBureausAverageTypeDesc = 'CREDIT_BUREAUS_AVERAGE_TYPE_DESC',
  CreditBureausAverageCreatedAtAsc = 'CREDIT_BUREAUS_AVERAGE_CREATED_AT_ASC',
  CreditBureausAverageCreatedAtDesc = 'CREDIT_BUREAUS_AVERAGE_CREATED_AT_DESC',
  CreditBureausAverageCreditScoreAsc = 'CREDIT_BUREAUS_AVERAGE_CREDIT_SCORE_ASC',
  CreditBureausAverageCreditScoreDesc = 'CREDIT_BUREAUS_AVERAGE_CREDIT_SCORE_DESC',
  CreditBureausAverageRawBureauAsc = 'CREDIT_BUREAUS_AVERAGE_RAW_BUREAU_ASC',
  CreditBureausAverageRawBureauDesc = 'CREDIT_BUREAUS_AVERAGE_RAW_BUREAU_DESC',
  CreditBureausAverageUpdatedAtAsc = 'CREDIT_BUREAUS_AVERAGE_UPDATED_AT_ASC',
  CreditBureausAverageUpdatedAtDesc = 'CREDIT_BUREAUS_AVERAGE_UPDATED_AT_DESC',
  CreditBureausAverageRawExpiredAsc = 'CREDIT_BUREAUS_AVERAGE_RAW_EXPIRED_ASC',
  CreditBureausAverageRawExpiredDesc = 'CREDIT_BUREAUS_AVERAGE_RAW_EXPIRED_DESC',
  CreditBureausAverageXmlAsc = 'CREDIT_BUREAUS_AVERAGE_XML_ASC',
  CreditBureausAverageXmlDesc = 'CREDIT_BUREAUS_AVERAGE_XML_DESC',
  CreditBureausStddevSampleIdAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_ID_ASC',
  CreditBureausStddevSampleIdDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_ID_DESC',
  CreditBureausStddevSampleAvaAppIdAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_AVA_APP_ID_ASC',
  CreditBureausStddevSampleAvaAppIdDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_AVA_APP_ID_DESC',
  CreditBureausStddevSampleTypeAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_TYPE_ASC',
  CreditBureausStddevSampleTypeDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_TYPE_DESC',
  CreditBureausStddevSampleCreatedAtAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CreditBureausStddevSampleCreatedAtDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CreditBureausStddevSampleCreditScoreAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREDIT_SCORE_ASC',
  CreditBureausStddevSampleCreditScoreDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_CREDIT_SCORE_DESC',
  CreditBureausStddevSampleRawBureauAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_BUREAU_ASC',
  CreditBureausStddevSampleRawBureauDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_BUREAU_DESC',
  CreditBureausStddevSampleUpdatedAtAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CreditBureausStddevSampleUpdatedAtDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CreditBureausStddevSampleRawExpiredAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_EXPIRED_ASC',
  CreditBureausStddevSampleRawExpiredDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_RAW_EXPIRED_DESC',
  CreditBureausStddevSampleXmlAsc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_XML_ASC',
  CreditBureausStddevSampleXmlDesc = 'CREDIT_BUREAUS_STDDEV_SAMPLE_XML_DESC',
  CreditBureausStddevPopulationIdAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_ID_ASC',
  CreditBureausStddevPopulationIdDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_ID_DESC',
  CreditBureausStddevPopulationAvaAppIdAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_AVA_APP_ID_ASC',
  CreditBureausStddevPopulationAvaAppIdDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_AVA_APP_ID_DESC',
  CreditBureausStddevPopulationTypeAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_TYPE_ASC',
  CreditBureausStddevPopulationTypeDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_TYPE_DESC',
  CreditBureausStddevPopulationCreatedAtAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREATED_AT_ASC',
  CreditBureausStddevPopulationCreatedAtDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREATED_AT_DESC',
  CreditBureausStddevPopulationCreditScoreAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREDIT_SCORE_ASC',
  CreditBureausStddevPopulationCreditScoreDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_CREDIT_SCORE_DESC',
  CreditBureausStddevPopulationRawBureauAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_BUREAU_ASC',
  CreditBureausStddevPopulationRawBureauDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_BUREAU_DESC',
  CreditBureausStddevPopulationUpdatedAtAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CreditBureausStddevPopulationUpdatedAtDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CreditBureausStddevPopulationRawExpiredAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_EXPIRED_ASC',
  CreditBureausStddevPopulationRawExpiredDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_RAW_EXPIRED_DESC',
  CreditBureausStddevPopulationXmlAsc = 'CREDIT_BUREAUS_STDDEV_POPULATION_XML_ASC',
  CreditBureausStddevPopulationXmlDesc = 'CREDIT_BUREAUS_STDDEV_POPULATION_XML_DESC',
  CreditBureausVarianceSampleIdAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_ID_ASC',
  CreditBureausVarianceSampleIdDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_ID_DESC',
  CreditBureausVarianceSampleAvaAppIdAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_AVA_APP_ID_ASC',
  CreditBureausVarianceSampleAvaAppIdDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_AVA_APP_ID_DESC',
  CreditBureausVarianceSampleTypeAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_TYPE_ASC',
  CreditBureausVarianceSampleTypeDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_TYPE_DESC',
  CreditBureausVarianceSampleCreatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CreditBureausVarianceSampleCreatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CreditBureausVarianceSampleCreditScoreAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREDIT_SCORE_ASC',
  CreditBureausVarianceSampleCreditScoreDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_CREDIT_SCORE_DESC',
  CreditBureausVarianceSampleRawBureauAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_BUREAU_ASC',
  CreditBureausVarianceSampleRawBureauDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_BUREAU_DESC',
  CreditBureausVarianceSampleUpdatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CreditBureausVarianceSampleUpdatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreditBureausVarianceSampleRawExpiredAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_EXPIRED_ASC',
  CreditBureausVarianceSampleRawExpiredDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_RAW_EXPIRED_DESC',
  CreditBureausVarianceSampleXmlAsc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_XML_ASC',
  CreditBureausVarianceSampleXmlDesc = 'CREDIT_BUREAUS_VARIANCE_SAMPLE_XML_DESC',
  CreditBureausVariancePopulationIdAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_ID_ASC',
  CreditBureausVariancePopulationIdDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_ID_DESC',
  CreditBureausVariancePopulationAvaAppIdAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_AVA_APP_ID_ASC',
  CreditBureausVariancePopulationAvaAppIdDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_AVA_APP_ID_DESC',
  CreditBureausVariancePopulationTypeAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_TYPE_ASC',
  CreditBureausVariancePopulationTypeDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_TYPE_DESC',
  CreditBureausVariancePopulationCreatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CreditBureausVariancePopulationCreatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CreditBureausVariancePopulationCreditScoreAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREDIT_SCORE_ASC',
  CreditBureausVariancePopulationCreditScoreDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_CREDIT_SCORE_DESC',
  CreditBureausVariancePopulationRawBureauAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_BUREAU_ASC',
  CreditBureausVariancePopulationRawBureauDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_BUREAU_DESC',
  CreditBureausVariancePopulationUpdatedAtAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CreditBureausVariancePopulationUpdatedAtDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CreditBureausVariancePopulationRawExpiredAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_EXPIRED_ASC',
  CreditBureausVariancePopulationRawExpiredDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_RAW_EXPIRED_DESC',
  CreditBureausVariancePopulationXmlAsc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_XML_ASC',
  CreditBureausVariancePopulationXmlDesc = 'CREDIT_BUREAUS_VARIANCE_POPULATION_XML_DESC'
}

export type BasicCompanyDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

export type BasicDeliveryMethodDetails = {
  value?: Maybe<Scalars['String']>;
  type?: Maybe<DeliveryMethodType>;
};

export type BasicProductDetails = {
  displayName: Scalars['String'];
  name: Scalars['String'];
};


/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigFloat']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigFloat']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type BodyTypeHints = {
  marketClassId?: Maybe<Scalars['String']>;
  marketClassName?: Maybe<Scalars['String']>;
  explicitlyStatedBodyTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

export type ChangeUserPasswordResponse = {
  success?: Maybe<Scalars['Boolean']>;
  temporaryPassword?: Maybe<Scalars['String']>;
};

export type Channel = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  displayName: Scalars['String'];
  enabled: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyChannelChannelIdAndCompanyId: ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByFieldConfigChannelIdAndProductId: ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByFieldConfigChannelIdAndIntegrationId: ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyConnection;
};


export type ChannelCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type ChannelFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type ChannelProductsByFieldConfigChannelIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};

export type ChannelAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ChannelSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ChannelDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ChannelMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ChannelMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ChannelAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ChannelStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ChannelStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ChannelVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ChannelVariancePopulationAggregates>;
};

export type ChannelAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `channel` to look up the row to connect. */
export type ChannelChannelPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `channel` to look up the row to delete. */
export type ChannelChannelPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `Company` values, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyChannel`, and the cursor to aid in pagination. */
  edges: Array<ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyChannel`. */
export type ChannelCompaniesByCompanyChannelChannelIdAndCompanyIdManyToManyEdgeCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

/** A condition to be used against `Channel` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ChannelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ChannelDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Channel` object types. All fields are combined with a logical ‘and.’ */
export type ChannelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `companyChannels` relation. */
  companyChannels?: Maybe<ChannelToManyCompanyChannelFilter>;
  /** Some related `companyChannels` exist. */
  companyChannelsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldConfigs` relation. */
  fieldConfigs?: Maybe<ChannelToManyFieldConfigFilter>;
  /** Some related `fieldConfigs` exist. */
  fieldConfigsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ChannelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ChannelFilter>>;
  /** Negates the expression. */
  not?: Maybe<ChannelFilter>;
};

/** Grouping methods for `Channel` for usage during aggregation. */
export enum ChannelGroupBy {
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED'
}

export type ChannelHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Channel` aggregates. */
export type ChannelHavingInput = {
  AND?: Maybe<Array<ChannelHavingInput>>;
  OR?: Maybe<Array<ChannelHavingInput>>;
  sum?: Maybe<ChannelHavingSumInput>;
  distinctCount?: Maybe<ChannelHavingDistinctCountInput>;
  min?: Maybe<ChannelHavingMinInput>;
  max?: Maybe<ChannelHavingMaxInput>;
  average?: Maybe<ChannelHavingAverageInput>;
  stddevSample?: Maybe<ChannelHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ChannelHavingStddevPopulationInput>;
  varianceSample?: Maybe<ChannelHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ChannelHavingVariancePopulationInput>;
};

export type ChannelHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ChannelHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Channel` */
export type ChannelInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** A connection to a list of `Integration` values, with data from `FieldConfig`. */
export type ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `FieldConfig`. */
export type ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `FieldConfig`. */
export type ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Integration` edge in the connection, with data from `FieldConfig`. */
export type ChannelIntegrationsByFieldConfigChannelIdAndIntegrationIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type ChannelMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ChannelMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ChannelNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `channel` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ChannelNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `channel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `channel` to look up the row to update. */
export type ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: UpdateChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ChannelOnFieldConfigForFieldConfigChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `channel` to look up the row to update. */
export type ChannelOnFieldConfigForFieldConfigChannelIdFkeyUsingChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: UpdateChannelOnFieldConfigForFieldConfigChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Channel`. Fields that are set will be updated. */
export type ChannelPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** A connection to a list of `Product` values, with data from `FieldConfig`. */
export type ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `FieldConfig`. */
export type ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `FieldConfig`. */
export type ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Product` edge in the connection, with data from `FieldConfig`. */
export type ChannelProductsByFieldConfigChannelIdAndProductIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type ChannelStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type ChannelToManyCompanyChannelFilter = {
  /** Every related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelFilter>;
  /** Some related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelFilter>;
  /** No related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelFilter>;
  /** Aggregates across related `CompanyChannel` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelAggregatesFilter>;
};

/** A filter to be used against many `FieldConfig` object types. All fields are combined with a logical ‘and.’ */
export type ChannelToManyFieldConfigFilter = {
  /** Every related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldConfigFilter>;
  /** Some related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldConfigFilter>;
  /** No related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldConfigFilter>;
  /** Aggregates across related `FieldConfig` match the filter criteria. */
  aggregates?: Maybe<FieldConfigAggregatesFilter>;
};

export type ChannelVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ChannelVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values. */
export type ChannelsConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel` and cursor to aid in pagination. */
  edges: Array<ChannelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values. */
export type ChannelsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection. */
export type ChannelsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
};

/** Methods to use when ordering `Channel`. */
export enum ChannelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyChannelsCountAsc = 'COMPANY_CHANNELS_COUNT_ASC',
  CompanyChannelsCountDesc = 'COMPANY_CHANNELS_COUNT_DESC',
  CompanyChannelsSumIdAsc = 'COMPANY_CHANNELS_SUM_ID_ASC',
  CompanyChannelsSumIdDesc = 'COMPANY_CHANNELS_SUM_ID_DESC',
  CompanyChannelsSumWidgetIdAsc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_ASC',
  CompanyChannelsSumWidgetIdDesc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_DESC',
  CompanyChannelsSumChannelIdAsc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_ASC',
  CompanyChannelsSumChannelIdDesc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_DESC',
  CompanyChannelsSumCompanyIdAsc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_ASC',
  CompanyChannelsSumCompanyIdDesc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_DESC',
  CompanyChannelsSumEnabledAsc = 'COMPANY_CHANNELS_SUM_ENABLED_ASC',
  CompanyChannelsSumEnabledDesc = 'COMPANY_CHANNELS_SUM_ENABLED_DESC',
  CompanyChannelsSumCreatedAtAsc = 'COMPANY_CHANNELS_SUM_CREATED_AT_ASC',
  CompanyChannelsSumCreatedAtDesc = 'COMPANY_CHANNELS_SUM_CREATED_AT_DESC',
  CompanyChannelsSumUpdatedAtAsc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_ASC',
  CompanyChannelsSumUpdatedAtDesc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_DESC',
  CompanyChannelsDistinctCountIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelsDistinctCountIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelsDistinctCountWidgetIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyChannelsDistinctCountWidgetIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyChannelsDistinctCountChannelIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  CompanyChannelsDistinctCountChannelIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  CompanyChannelsDistinctCountCompanyIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyChannelsDistinctCountCompanyIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyChannelsDistinctCountEnabledAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyChannelsDistinctCountEnabledDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyChannelsDistinctCountCreatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelsDistinctCountCreatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelsDistinctCountUpdatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelsDistinctCountUpdatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelsMinIdAsc = 'COMPANY_CHANNELS_MIN_ID_ASC',
  CompanyChannelsMinIdDesc = 'COMPANY_CHANNELS_MIN_ID_DESC',
  CompanyChannelsMinWidgetIdAsc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_ASC',
  CompanyChannelsMinWidgetIdDesc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_DESC',
  CompanyChannelsMinChannelIdAsc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_ASC',
  CompanyChannelsMinChannelIdDesc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_DESC',
  CompanyChannelsMinCompanyIdAsc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_ASC',
  CompanyChannelsMinCompanyIdDesc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_DESC',
  CompanyChannelsMinEnabledAsc = 'COMPANY_CHANNELS_MIN_ENABLED_ASC',
  CompanyChannelsMinEnabledDesc = 'COMPANY_CHANNELS_MIN_ENABLED_DESC',
  CompanyChannelsMinCreatedAtAsc = 'COMPANY_CHANNELS_MIN_CREATED_AT_ASC',
  CompanyChannelsMinCreatedAtDesc = 'COMPANY_CHANNELS_MIN_CREATED_AT_DESC',
  CompanyChannelsMinUpdatedAtAsc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_ASC',
  CompanyChannelsMinUpdatedAtDesc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_DESC',
  CompanyChannelsMaxIdAsc = 'COMPANY_CHANNELS_MAX_ID_ASC',
  CompanyChannelsMaxIdDesc = 'COMPANY_CHANNELS_MAX_ID_DESC',
  CompanyChannelsMaxWidgetIdAsc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_ASC',
  CompanyChannelsMaxWidgetIdDesc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_DESC',
  CompanyChannelsMaxChannelIdAsc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_ASC',
  CompanyChannelsMaxChannelIdDesc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_DESC',
  CompanyChannelsMaxCompanyIdAsc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_ASC',
  CompanyChannelsMaxCompanyIdDesc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_DESC',
  CompanyChannelsMaxEnabledAsc = 'COMPANY_CHANNELS_MAX_ENABLED_ASC',
  CompanyChannelsMaxEnabledDesc = 'COMPANY_CHANNELS_MAX_ENABLED_DESC',
  CompanyChannelsMaxCreatedAtAsc = 'COMPANY_CHANNELS_MAX_CREATED_AT_ASC',
  CompanyChannelsMaxCreatedAtDesc = 'COMPANY_CHANNELS_MAX_CREATED_AT_DESC',
  CompanyChannelsMaxUpdatedAtAsc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_ASC',
  CompanyChannelsMaxUpdatedAtDesc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_DESC',
  CompanyChannelsAverageIdAsc = 'COMPANY_CHANNELS_AVERAGE_ID_ASC',
  CompanyChannelsAverageIdDesc = 'COMPANY_CHANNELS_AVERAGE_ID_DESC',
  CompanyChannelsAverageWidgetIdAsc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_ASC',
  CompanyChannelsAverageWidgetIdDesc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_DESC',
  CompanyChannelsAverageChannelIdAsc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_ASC',
  CompanyChannelsAverageChannelIdDesc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_DESC',
  CompanyChannelsAverageCompanyIdAsc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_ASC',
  CompanyChannelsAverageCompanyIdDesc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_DESC',
  CompanyChannelsAverageEnabledAsc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_ASC',
  CompanyChannelsAverageEnabledDesc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_DESC',
  CompanyChannelsAverageCreatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelsAverageCreatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelsAverageUpdatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelsAverageUpdatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelsStddevSampleIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelsStddevSampleIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelsStddevSampleWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsStddevSampleWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsStddevSampleChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsStddevSampleChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsStddevSampleCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsStddevSampleCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsStddevSampleEnabledAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyChannelsStddevSampleEnabledDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyChannelsStddevSampleCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsStddevSampleCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsStddevSampleUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsStddevSampleUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsStddevPopulationIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelsStddevPopulationIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelsStddevPopulationWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsStddevPopulationWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsStddevPopulationChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsStddevPopulationChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsStddevPopulationCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsStddevPopulationCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsStddevPopulationEnabledAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyChannelsStddevPopulationEnabledDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyChannelsStddevPopulationCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelsStddevPopulationCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelsStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsVarianceSampleIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelsVarianceSampleIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelsVarianceSampleWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsVarianceSampleWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsVarianceSampleChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsVarianceSampleChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsVarianceSampleCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsVarianceSampleCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsVarianceSampleEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyChannelsVarianceSampleEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyChannelsVarianceSampleCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsVarianceSampleCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsVariancePopulationIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelsVariancePopulationIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelsVariancePopulationWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsVariancePopulationWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsVariancePopulationChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsVariancePopulationChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsVariancePopulationCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsVariancePopulationCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsVariancePopulationEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyChannelsVariancePopulationEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyChannelsVariancePopulationCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelsVariancePopulationCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelsVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldConfigsCountAsc = 'FIELD_CONFIGS_COUNT_ASC',
  FieldConfigsCountDesc = 'FIELD_CONFIGS_COUNT_DESC',
  FieldConfigsSumIdAsc = 'FIELD_CONFIGS_SUM_ID_ASC',
  FieldConfigsSumIdDesc = 'FIELD_CONFIGS_SUM_ID_DESC',
  FieldConfigsSumProductIdAsc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_ASC',
  FieldConfigsSumProductIdDesc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_DESC',
  FieldConfigsSumChannelIdAsc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_ASC',
  FieldConfigsSumChannelIdDesc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_DESC',
  FieldConfigsSumIntegrationIdAsc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_ASC',
  FieldConfigsSumIntegrationIdDesc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_DESC',
  FieldConfigsSumTypeAsc = 'FIELD_CONFIGS_SUM_TYPE_ASC',
  FieldConfigsSumTypeDesc = 'FIELD_CONFIGS_SUM_TYPE_DESC',
  FieldConfigsSumLabelAsc = 'FIELD_CONFIGS_SUM_LABEL_ASC',
  FieldConfigsSumLabelDesc = 'FIELD_CONFIGS_SUM_LABEL_DESC',
  FieldConfigsSumHelperAsc = 'FIELD_CONFIGS_SUM_HELPER_ASC',
  FieldConfigsSumHelperDesc = 'FIELD_CONFIGS_SUM_HELPER_DESC',
  FieldConfigsSumKeyAsc = 'FIELD_CONFIGS_SUM_KEY_ASC',
  FieldConfigsSumKeyDesc = 'FIELD_CONFIGS_SUM_KEY_DESC',
  FieldConfigsSumPublicAsc = 'FIELD_CONFIGS_SUM_PUBLIC_ASC',
  FieldConfigsSumPublicDesc = 'FIELD_CONFIGS_SUM_PUBLIC_DESC',
  FieldConfigsSumCreatedAtAsc = 'FIELD_CONFIGS_SUM_CREATED_AT_ASC',
  FieldConfigsSumCreatedAtDesc = 'FIELD_CONFIGS_SUM_CREATED_AT_DESC',
  FieldConfigsSumUpdatedAtAsc = 'FIELD_CONFIGS_SUM_UPDATED_AT_ASC',
  FieldConfigsSumUpdatedAtDesc = 'FIELD_CONFIGS_SUM_UPDATED_AT_DESC',
  FieldConfigsDistinctCountIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_ASC',
  FieldConfigsDistinctCountIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_DESC',
  FieldConfigsDistinctCountProductIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  FieldConfigsDistinctCountProductIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  FieldConfigsDistinctCountChannelIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  FieldConfigsDistinctCountChannelIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  FieldConfigsDistinctCountIntegrationIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  FieldConfigsDistinctCountIntegrationIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  FieldConfigsDistinctCountTypeAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_ASC',
  FieldConfigsDistinctCountTypeDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_DESC',
  FieldConfigsDistinctCountLabelAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_ASC',
  FieldConfigsDistinctCountLabelDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_DESC',
  FieldConfigsDistinctCountHelperAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_ASC',
  FieldConfigsDistinctCountHelperDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_DESC',
  FieldConfigsDistinctCountKeyAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_ASC',
  FieldConfigsDistinctCountKeyDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_DESC',
  FieldConfigsDistinctCountPublicAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_ASC',
  FieldConfigsDistinctCountPublicDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_DESC',
  FieldConfigsDistinctCountCreatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldConfigsDistinctCountCreatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldConfigsDistinctCountUpdatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldConfigsDistinctCountUpdatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldConfigsMinIdAsc = 'FIELD_CONFIGS_MIN_ID_ASC',
  FieldConfigsMinIdDesc = 'FIELD_CONFIGS_MIN_ID_DESC',
  FieldConfigsMinProductIdAsc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_ASC',
  FieldConfigsMinProductIdDesc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_DESC',
  FieldConfigsMinChannelIdAsc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_ASC',
  FieldConfigsMinChannelIdDesc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_DESC',
  FieldConfigsMinIntegrationIdAsc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_ASC',
  FieldConfigsMinIntegrationIdDesc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_DESC',
  FieldConfigsMinTypeAsc = 'FIELD_CONFIGS_MIN_TYPE_ASC',
  FieldConfigsMinTypeDesc = 'FIELD_CONFIGS_MIN_TYPE_DESC',
  FieldConfigsMinLabelAsc = 'FIELD_CONFIGS_MIN_LABEL_ASC',
  FieldConfigsMinLabelDesc = 'FIELD_CONFIGS_MIN_LABEL_DESC',
  FieldConfigsMinHelperAsc = 'FIELD_CONFIGS_MIN_HELPER_ASC',
  FieldConfigsMinHelperDesc = 'FIELD_CONFIGS_MIN_HELPER_DESC',
  FieldConfigsMinKeyAsc = 'FIELD_CONFIGS_MIN_KEY_ASC',
  FieldConfigsMinKeyDesc = 'FIELD_CONFIGS_MIN_KEY_DESC',
  FieldConfigsMinPublicAsc = 'FIELD_CONFIGS_MIN_PUBLIC_ASC',
  FieldConfigsMinPublicDesc = 'FIELD_CONFIGS_MIN_PUBLIC_DESC',
  FieldConfigsMinCreatedAtAsc = 'FIELD_CONFIGS_MIN_CREATED_AT_ASC',
  FieldConfigsMinCreatedAtDesc = 'FIELD_CONFIGS_MIN_CREATED_AT_DESC',
  FieldConfigsMinUpdatedAtAsc = 'FIELD_CONFIGS_MIN_UPDATED_AT_ASC',
  FieldConfigsMinUpdatedAtDesc = 'FIELD_CONFIGS_MIN_UPDATED_AT_DESC',
  FieldConfigsMaxIdAsc = 'FIELD_CONFIGS_MAX_ID_ASC',
  FieldConfigsMaxIdDesc = 'FIELD_CONFIGS_MAX_ID_DESC',
  FieldConfigsMaxProductIdAsc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_ASC',
  FieldConfigsMaxProductIdDesc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_DESC',
  FieldConfigsMaxChannelIdAsc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_ASC',
  FieldConfigsMaxChannelIdDesc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_DESC',
  FieldConfigsMaxIntegrationIdAsc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_ASC',
  FieldConfigsMaxIntegrationIdDesc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_DESC',
  FieldConfigsMaxTypeAsc = 'FIELD_CONFIGS_MAX_TYPE_ASC',
  FieldConfigsMaxTypeDesc = 'FIELD_CONFIGS_MAX_TYPE_DESC',
  FieldConfigsMaxLabelAsc = 'FIELD_CONFIGS_MAX_LABEL_ASC',
  FieldConfigsMaxLabelDesc = 'FIELD_CONFIGS_MAX_LABEL_DESC',
  FieldConfigsMaxHelperAsc = 'FIELD_CONFIGS_MAX_HELPER_ASC',
  FieldConfigsMaxHelperDesc = 'FIELD_CONFIGS_MAX_HELPER_DESC',
  FieldConfigsMaxKeyAsc = 'FIELD_CONFIGS_MAX_KEY_ASC',
  FieldConfigsMaxKeyDesc = 'FIELD_CONFIGS_MAX_KEY_DESC',
  FieldConfigsMaxPublicAsc = 'FIELD_CONFIGS_MAX_PUBLIC_ASC',
  FieldConfigsMaxPublicDesc = 'FIELD_CONFIGS_MAX_PUBLIC_DESC',
  FieldConfigsMaxCreatedAtAsc = 'FIELD_CONFIGS_MAX_CREATED_AT_ASC',
  FieldConfigsMaxCreatedAtDesc = 'FIELD_CONFIGS_MAX_CREATED_AT_DESC',
  FieldConfigsMaxUpdatedAtAsc = 'FIELD_CONFIGS_MAX_UPDATED_AT_ASC',
  FieldConfigsMaxUpdatedAtDesc = 'FIELD_CONFIGS_MAX_UPDATED_AT_DESC',
  FieldConfigsAverageIdAsc = 'FIELD_CONFIGS_AVERAGE_ID_ASC',
  FieldConfigsAverageIdDesc = 'FIELD_CONFIGS_AVERAGE_ID_DESC',
  FieldConfigsAverageProductIdAsc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_ASC',
  FieldConfigsAverageProductIdDesc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_DESC',
  FieldConfigsAverageChannelIdAsc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_ASC',
  FieldConfigsAverageChannelIdDesc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_DESC',
  FieldConfigsAverageIntegrationIdAsc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_ASC',
  FieldConfigsAverageIntegrationIdDesc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_DESC',
  FieldConfigsAverageTypeAsc = 'FIELD_CONFIGS_AVERAGE_TYPE_ASC',
  FieldConfigsAverageTypeDesc = 'FIELD_CONFIGS_AVERAGE_TYPE_DESC',
  FieldConfigsAverageLabelAsc = 'FIELD_CONFIGS_AVERAGE_LABEL_ASC',
  FieldConfigsAverageLabelDesc = 'FIELD_CONFIGS_AVERAGE_LABEL_DESC',
  FieldConfigsAverageHelperAsc = 'FIELD_CONFIGS_AVERAGE_HELPER_ASC',
  FieldConfigsAverageHelperDesc = 'FIELD_CONFIGS_AVERAGE_HELPER_DESC',
  FieldConfigsAverageKeyAsc = 'FIELD_CONFIGS_AVERAGE_KEY_ASC',
  FieldConfigsAverageKeyDesc = 'FIELD_CONFIGS_AVERAGE_KEY_DESC',
  FieldConfigsAveragePublicAsc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_ASC',
  FieldConfigsAveragePublicDesc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_DESC',
  FieldConfigsAverageCreatedAtAsc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_ASC',
  FieldConfigsAverageCreatedAtDesc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_DESC',
  FieldConfigsAverageUpdatedAtAsc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_ASC',
  FieldConfigsAverageUpdatedAtDesc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_DESC',
  FieldConfigsStddevSampleIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_ASC',
  FieldConfigsStddevSampleIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_DESC',
  FieldConfigsStddevSampleProductIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsStddevSampleProductIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsStddevSampleChannelIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsStddevSampleChannelIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsStddevSampleIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsStddevSampleIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsStddevSampleTypeAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_ASC',
  FieldConfigsStddevSampleTypeDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_DESC',
  FieldConfigsStddevSampleLabelAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_ASC',
  FieldConfigsStddevSampleLabelDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_DESC',
  FieldConfigsStddevSampleHelperAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_ASC',
  FieldConfigsStddevSampleHelperDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_DESC',
  FieldConfigsStddevSampleKeyAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_ASC',
  FieldConfigsStddevSampleKeyDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_DESC',
  FieldConfigsStddevSamplePublicAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_ASC',
  FieldConfigsStddevSamplePublicDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_DESC',
  FieldConfigsStddevSampleCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldConfigsStddevSampleCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldConfigsStddevSampleUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsStddevSampleUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsStddevPopulationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_ASC',
  FieldConfigsStddevPopulationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_DESC',
  FieldConfigsStddevPopulationProductIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsStddevPopulationProductIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsStddevPopulationChannelIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsStddevPopulationChannelIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsStddevPopulationIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsStddevPopulationIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsStddevPopulationTypeAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_ASC',
  FieldConfigsStddevPopulationTypeDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_DESC',
  FieldConfigsStddevPopulationLabelAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_ASC',
  FieldConfigsStddevPopulationLabelDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_DESC',
  FieldConfigsStddevPopulationHelperAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_ASC',
  FieldConfigsStddevPopulationHelperDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_DESC',
  FieldConfigsStddevPopulationKeyAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_ASC',
  FieldConfigsStddevPopulationKeyDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_DESC',
  FieldConfigsStddevPopulationPublicAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_ASC',
  FieldConfigsStddevPopulationPublicDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_DESC',
  FieldConfigsStddevPopulationCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldConfigsStddevPopulationCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldConfigsStddevPopulationUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldConfigsStddevPopulationUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldConfigsVarianceSampleIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_ASC',
  FieldConfigsVarianceSampleIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_DESC',
  FieldConfigsVarianceSampleProductIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsVarianceSampleProductIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsVarianceSampleChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsVarianceSampleChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsVarianceSampleIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsVarianceSampleIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsVarianceSampleTypeAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_ASC',
  FieldConfigsVarianceSampleTypeDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_DESC',
  FieldConfigsVarianceSampleLabelAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_ASC',
  FieldConfigsVarianceSampleLabelDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_DESC',
  FieldConfigsVarianceSampleHelperAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_ASC',
  FieldConfigsVarianceSampleHelperDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_DESC',
  FieldConfigsVarianceSampleKeyAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_ASC',
  FieldConfigsVarianceSampleKeyDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_DESC',
  FieldConfigsVarianceSamplePublicAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_ASC',
  FieldConfigsVarianceSamplePublicDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_DESC',
  FieldConfigsVarianceSampleCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldConfigsVarianceSampleCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldConfigsVarianceSampleUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsVarianceSampleUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsVariancePopulationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_ASC',
  FieldConfigsVariancePopulationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_DESC',
  FieldConfigsVariancePopulationProductIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsVariancePopulationProductIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsVariancePopulationChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsVariancePopulationChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsVariancePopulationIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsVariancePopulationIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsVariancePopulationTypeAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_ASC',
  FieldConfigsVariancePopulationTypeDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_DESC',
  FieldConfigsVariancePopulationLabelAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_ASC',
  FieldConfigsVariancePopulationLabelDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_DESC',
  FieldConfigsVariancePopulationHelperAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_ASC',
  FieldConfigsVariancePopulationHelperDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_DESC',
  FieldConfigsVariancePopulationKeyAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_ASC',
  FieldConfigsVariancePopulationKeyDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_DESC',
  FieldConfigsVariancePopulationPublicAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_ASC',
  FieldConfigsVariancePopulationPublicDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_DESC',
  FieldConfigsVariancePopulationCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldConfigsVariancePopulationCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldConfigsVariancePopulationUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldConfigsVariancePopulationUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

/** A connection to a list of `Company` values. */
export type CompaniesConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company` and cursor to aid in pagination. */
  edges: Array<CompaniesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values. */
export type CompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection. */
export type CompaniesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
};

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EquifaxCustomerNumberAsc = 'EQUIFAX_CUSTOMER_NUMBER_ASC',
  EquifaxCustomerNumberDesc = 'EQUIFAX_CUSTOMER_NUMBER_DESC',
  SecurityCodeAsc = 'SECURITY_CODE_ASC',
  SecurityCodeDesc = 'SECURITY_CODE_DESC',
  ExternalIdAsc = 'EXTERNAL_ID_ASC',
  ExternalIdDesc = 'EXTERNAL_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ArchivedAsc = 'ARCHIVED_ASC',
  ArchivedDesc = 'ARCHIVED_DESC',
  ZohoIdAsc = 'ZOHO_ID_ASC',
  ZohoIdDesc = 'ZOHO_ID_DESC',
  DeliverToAsc = 'DELIVER_TO_ASC',
  DeliverToDesc = 'DELIVER_TO_DESC',
  PrimaryGroupAsc = 'PRIMARY_GROUP_ASC',
  PrimaryGroupDesc = 'PRIMARY_GROUP_DESC',
  RawMetaAsc = 'RAW_META_ASC',
  RawMetaDesc = 'RAW_META_DESC',
  BillableCompanyIdAsc = 'BILLABLE_COMPANY_ID_ASC',
  BillableCompanyIdDesc = 'BILLABLE_COMPANY_ID_DESC',
  RawRequestedAccessAsc = 'RAW_REQUESTED_ACCESS_ASC',
  RawRequestedAccessDesc = 'RAW_REQUESTED_ACCESS_DESC',
  LenderDealerCodeAsc = 'LENDER_DEALER_CODE_ASC',
  LenderDealerCodeDesc = 'LENDER_DEALER_CODE_DESC',
  GooglePlacesIdAsc = 'GOOGLE_PLACES_ID_ASC',
  GooglePlacesIdDesc = 'GOOGLE_PLACES_ID_DESC',
  LegalConsentAsc = 'LEGAL_CONSENT_ASC',
  LegalConsentDesc = 'LEGAL_CONSENT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LeadsCountAsc = 'LEADS_COUNT_ASC',
  LeadsCountDesc = 'LEADS_COUNT_DESC',
  LeadsSumIdAsc = 'LEADS_SUM_ID_ASC',
  LeadsSumIdDesc = 'LEADS_SUM_ID_DESC',
  LeadsSumCompanyIdAsc = 'LEADS_SUM_COMPANY_ID_ASC',
  LeadsSumCompanyIdDesc = 'LEADS_SUM_COMPANY_ID_DESC',
  LeadsSumCreatedAtAsc = 'LEADS_SUM_CREATED_AT_ASC',
  LeadsSumCreatedAtDesc = 'LEADS_SUM_CREATED_AT_DESC',
  LeadsSumFirstNameAsc = 'LEADS_SUM_FIRST_NAME_ASC',
  LeadsSumFirstNameDesc = 'LEADS_SUM_FIRST_NAME_DESC',
  LeadsSumLastNameAsc = 'LEADS_SUM_LAST_NAME_ASC',
  LeadsSumLastNameDesc = 'LEADS_SUM_LAST_NAME_DESC',
  LeadsSumDateOfBirthAsc = 'LEADS_SUM_DATE_OF_BIRTH_ASC',
  LeadsSumDateOfBirthDesc = 'LEADS_SUM_DATE_OF_BIRTH_DESC',
  LeadsSumStreetAsc = 'LEADS_SUM_STREET_ASC',
  LeadsSumStreetDesc = 'LEADS_SUM_STREET_DESC',
  LeadsSumStreetNumberAsc = 'LEADS_SUM_STREET_NUMBER_ASC',
  LeadsSumStreetNumberDesc = 'LEADS_SUM_STREET_NUMBER_DESC',
  LeadsSumUnitAsc = 'LEADS_SUM_UNIT_ASC',
  LeadsSumUnitDesc = 'LEADS_SUM_UNIT_DESC',
  LeadsSumCityAsc = 'LEADS_SUM_CITY_ASC',
  LeadsSumCityDesc = 'LEADS_SUM_CITY_DESC',
  LeadsSumCountryAsc = 'LEADS_SUM_COUNTRY_ASC',
  LeadsSumCountryDesc = 'LEADS_SUM_COUNTRY_DESC',
  LeadsSumPostalCodeAsc = 'LEADS_SUM_POSTAL_CODE_ASC',
  LeadsSumPostalCodeDesc = 'LEADS_SUM_POSTAL_CODE_DESC',
  LeadsSumProvinceCodeAsc = 'LEADS_SUM_PROVINCE_CODE_ASC',
  LeadsSumProvinceCodeDesc = 'LEADS_SUM_PROVINCE_CODE_DESC',
  LeadsSumEmailAddressAsc = 'LEADS_SUM_EMAIL_ADDRESS_ASC',
  LeadsSumEmailAddressDesc = 'LEADS_SUM_EMAIL_ADDRESS_DESC',
  LeadsSumPhoneNumberAsc = 'LEADS_SUM_PHONE_NUMBER_ASC',
  LeadsSumPhoneNumberDesc = 'LEADS_SUM_PHONE_NUMBER_DESC',
  LeadsSumSourceUrlAsc = 'LEADS_SUM_SOURCE_URL_ASC',
  LeadsSumSourceUrlDesc = 'LEADS_SUM_SOURCE_URL_DESC',
  LeadsSumTransactionKeyAsc = 'LEADS_SUM_TRANSACTION_KEY_ASC',
  LeadsSumTransactionKeyDesc = 'LEADS_SUM_TRANSACTION_KEY_DESC',
  LeadsSumLeadspediaIdAsc = 'LEADS_SUM_LEADSPEDIA_ID_ASC',
  LeadsSumLeadspediaIdDesc = 'LEADS_SUM_LEADSPEDIA_ID_DESC',
  LeadsSumUpdatedAtAsc = 'LEADS_SUM_UPDATED_AT_ASC',
  LeadsSumUpdatedAtDesc = 'LEADS_SUM_UPDATED_AT_DESC',
  LeadsSumSearchTextAsc = 'LEADS_SUM_SEARCH_TEXT_ASC',
  LeadsSumSearchTextDesc = 'LEADS_SUM_SEARCH_TEXT_DESC',
  LeadsSumCommentAsc = 'LEADS_SUM_COMMENT_ASC',
  LeadsSumCommentDesc = 'LEADS_SUM_COMMENT_DESC',
  LeadsSumStateAsc = 'LEADS_SUM_STATE_ASC',
  LeadsSumStateDesc = 'LEADS_SUM_STATE_DESC',
  LeadsSumReadAsc = 'LEADS_SUM_READ_ASC',
  LeadsSumReadDesc = 'LEADS_SUM_READ_DESC',
  LeadsSumExternalIdsAsc = 'LEADS_SUM_EXTERNAL_IDS_ASC',
  LeadsSumExternalIdsDesc = 'LEADS_SUM_EXTERNAL_IDS_DESC',
  LeadsSumMetaAsc = 'LEADS_SUM_META_ASC',
  LeadsSumMetaDesc = 'LEADS_SUM_META_DESC',
  LeadsSumAdditionalInformationAsc = 'LEADS_SUM_ADDITIONAL_INFORMATION_ASC',
  LeadsSumAdditionalInformationDesc = 'LEADS_SUM_ADDITIONAL_INFORMATION_DESC',
  LeadsSumPhoneVerifiedAtAsc = 'LEADS_SUM_PHONE_VERIFIED_AT_ASC',
  LeadsSumPhoneVerifiedAtDesc = 'LEADS_SUM_PHONE_VERIFIED_AT_DESC',
  LeadsSumIpAddressAsc = 'LEADS_SUM_IP_ADDRESS_ASC',
  LeadsSumIpAddressDesc = 'LEADS_SUM_IP_ADDRESS_DESC',
  LeadsSumClientIdAsc = 'LEADS_SUM_CLIENT_ID_ASC',
  LeadsSumClientIdDesc = 'LEADS_SUM_CLIENT_ID_DESC',
  LeadsSumDeletedAsc = 'LEADS_SUM_DELETED_ASC',
  LeadsSumDeletedDesc = 'LEADS_SUM_DELETED_DESC',
  LeadsSumPriorityAsc = 'LEADS_SUM_PRIORITY_ASC',
  LeadsSumPriorityDesc = 'LEADS_SUM_PRIORITY_DESC',
  LeadsSumAssigneeAsc = 'LEADS_SUM_ASSIGNEE_ASC',
  LeadsSumAssigneeDesc = 'LEADS_SUM_ASSIGNEE_DESC',
  LeadsSumShortIdAsc = 'LEADS_SUM_SHORT_ID_ASC',
  LeadsSumShortIdDesc = 'LEADS_SUM_SHORT_ID_DESC',
  LeadsDistinctCountIdAsc = 'LEADS_DISTINCT_COUNT_ID_ASC',
  LeadsDistinctCountIdDesc = 'LEADS_DISTINCT_COUNT_ID_DESC',
  LeadsDistinctCountCompanyIdAsc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_ASC',
  LeadsDistinctCountCompanyIdDesc = 'LEADS_DISTINCT_COUNT_COMPANY_ID_DESC',
  LeadsDistinctCountCreatedAtAsc = 'LEADS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadsDistinctCountCreatedAtDesc = 'LEADS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadsDistinctCountFirstNameAsc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_ASC',
  LeadsDistinctCountFirstNameDesc = 'LEADS_DISTINCT_COUNT_FIRST_NAME_DESC',
  LeadsDistinctCountLastNameAsc = 'LEADS_DISTINCT_COUNT_LAST_NAME_ASC',
  LeadsDistinctCountLastNameDesc = 'LEADS_DISTINCT_COUNT_LAST_NAME_DESC',
  LeadsDistinctCountDateOfBirthAsc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_ASC',
  LeadsDistinctCountDateOfBirthDesc = 'LEADS_DISTINCT_COUNT_DATE_OF_BIRTH_DESC',
  LeadsDistinctCountStreetAsc = 'LEADS_DISTINCT_COUNT_STREET_ASC',
  LeadsDistinctCountStreetDesc = 'LEADS_DISTINCT_COUNT_STREET_DESC',
  LeadsDistinctCountStreetNumberAsc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_ASC',
  LeadsDistinctCountStreetNumberDesc = 'LEADS_DISTINCT_COUNT_STREET_NUMBER_DESC',
  LeadsDistinctCountUnitAsc = 'LEADS_DISTINCT_COUNT_UNIT_ASC',
  LeadsDistinctCountUnitDesc = 'LEADS_DISTINCT_COUNT_UNIT_DESC',
  LeadsDistinctCountCityAsc = 'LEADS_DISTINCT_COUNT_CITY_ASC',
  LeadsDistinctCountCityDesc = 'LEADS_DISTINCT_COUNT_CITY_DESC',
  LeadsDistinctCountCountryAsc = 'LEADS_DISTINCT_COUNT_COUNTRY_ASC',
  LeadsDistinctCountCountryDesc = 'LEADS_DISTINCT_COUNT_COUNTRY_DESC',
  LeadsDistinctCountPostalCodeAsc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LeadsDistinctCountPostalCodeDesc = 'LEADS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LeadsDistinctCountProvinceCodeAsc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_ASC',
  LeadsDistinctCountProvinceCodeDesc = 'LEADS_DISTINCT_COUNT_PROVINCE_CODE_DESC',
  LeadsDistinctCountEmailAddressAsc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_ASC',
  LeadsDistinctCountEmailAddressDesc = 'LEADS_DISTINCT_COUNT_EMAIL_ADDRESS_DESC',
  LeadsDistinctCountPhoneNumberAsc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  LeadsDistinctCountPhoneNumberDesc = 'LEADS_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  LeadsDistinctCountSourceUrlAsc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_ASC',
  LeadsDistinctCountSourceUrlDesc = 'LEADS_DISTINCT_COUNT_SOURCE_URL_DESC',
  LeadsDistinctCountTransactionKeyAsc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_ASC',
  LeadsDistinctCountTransactionKeyDesc = 'LEADS_DISTINCT_COUNT_TRANSACTION_KEY_DESC',
  LeadsDistinctCountLeadspediaIdAsc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_ASC',
  LeadsDistinctCountLeadspediaIdDesc = 'LEADS_DISTINCT_COUNT_LEADSPEDIA_ID_DESC',
  LeadsDistinctCountUpdatedAtAsc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadsDistinctCountUpdatedAtDesc = 'LEADS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadsDistinctCountSearchTextAsc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  LeadsDistinctCountSearchTextDesc = 'LEADS_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  LeadsDistinctCountCommentAsc = 'LEADS_DISTINCT_COUNT_COMMENT_ASC',
  LeadsDistinctCountCommentDesc = 'LEADS_DISTINCT_COUNT_COMMENT_DESC',
  LeadsDistinctCountStateAsc = 'LEADS_DISTINCT_COUNT_STATE_ASC',
  LeadsDistinctCountStateDesc = 'LEADS_DISTINCT_COUNT_STATE_DESC',
  LeadsDistinctCountReadAsc = 'LEADS_DISTINCT_COUNT_READ_ASC',
  LeadsDistinctCountReadDesc = 'LEADS_DISTINCT_COUNT_READ_DESC',
  LeadsDistinctCountExternalIdsAsc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_ASC',
  LeadsDistinctCountExternalIdsDesc = 'LEADS_DISTINCT_COUNT_EXTERNAL_IDS_DESC',
  LeadsDistinctCountMetaAsc = 'LEADS_DISTINCT_COUNT_META_ASC',
  LeadsDistinctCountMetaDesc = 'LEADS_DISTINCT_COUNT_META_DESC',
  LeadsDistinctCountAdditionalInformationAsc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  LeadsDistinctCountAdditionalInformationDesc = 'LEADS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  LeadsDistinctCountPhoneVerifiedAtAsc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_ASC',
  LeadsDistinctCountPhoneVerifiedAtDesc = 'LEADS_DISTINCT_COUNT_PHONE_VERIFIED_AT_DESC',
  LeadsDistinctCountIpAddressAsc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  LeadsDistinctCountIpAddressDesc = 'LEADS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  LeadsDistinctCountClientIdAsc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_ASC',
  LeadsDistinctCountClientIdDesc = 'LEADS_DISTINCT_COUNT_CLIENT_ID_DESC',
  LeadsDistinctCountDeletedAsc = 'LEADS_DISTINCT_COUNT_DELETED_ASC',
  LeadsDistinctCountDeletedDesc = 'LEADS_DISTINCT_COUNT_DELETED_DESC',
  LeadsDistinctCountPriorityAsc = 'LEADS_DISTINCT_COUNT_PRIORITY_ASC',
  LeadsDistinctCountPriorityDesc = 'LEADS_DISTINCT_COUNT_PRIORITY_DESC',
  LeadsDistinctCountAssigneeAsc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_ASC',
  LeadsDistinctCountAssigneeDesc = 'LEADS_DISTINCT_COUNT_ASSIGNEE_DESC',
  LeadsDistinctCountShortIdAsc = 'LEADS_DISTINCT_COUNT_SHORT_ID_ASC',
  LeadsDistinctCountShortIdDesc = 'LEADS_DISTINCT_COUNT_SHORT_ID_DESC',
  LeadsMinIdAsc = 'LEADS_MIN_ID_ASC',
  LeadsMinIdDesc = 'LEADS_MIN_ID_DESC',
  LeadsMinCompanyIdAsc = 'LEADS_MIN_COMPANY_ID_ASC',
  LeadsMinCompanyIdDesc = 'LEADS_MIN_COMPANY_ID_DESC',
  LeadsMinCreatedAtAsc = 'LEADS_MIN_CREATED_AT_ASC',
  LeadsMinCreatedAtDesc = 'LEADS_MIN_CREATED_AT_DESC',
  LeadsMinFirstNameAsc = 'LEADS_MIN_FIRST_NAME_ASC',
  LeadsMinFirstNameDesc = 'LEADS_MIN_FIRST_NAME_DESC',
  LeadsMinLastNameAsc = 'LEADS_MIN_LAST_NAME_ASC',
  LeadsMinLastNameDesc = 'LEADS_MIN_LAST_NAME_DESC',
  LeadsMinDateOfBirthAsc = 'LEADS_MIN_DATE_OF_BIRTH_ASC',
  LeadsMinDateOfBirthDesc = 'LEADS_MIN_DATE_OF_BIRTH_DESC',
  LeadsMinStreetAsc = 'LEADS_MIN_STREET_ASC',
  LeadsMinStreetDesc = 'LEADS_MIN_STREET_DESC',
  LeadsMinStreetNumberAsc = 'LEADS_MIN_STREET_NUMBER_ASC',
  LeadsMinStreetNumberDesc = 'LEADS_MIN_STREET_NUMBER_DESC',
  LeadsMinUnitAsc = 'LEADS_MIN_UNIT_ASC',
  LeadsMinUnitDesc = 'LEADS_MIN_UNIT_DESC',
  LeadsMinCityAsc = 'LEADS_MIN_CITY_ASC',
  LeadsMinCityDesc = 'LEADS_MIN_CITY_DESC',
  LeadsMinCountryAsc = 'LEADS_MIN_COUNTRY_ASC',
  LeadsMinCountryDesc = 'LEADS_MIN_COUNTRY_DESC',
  LeadsMinPostalCodeAsc = 'LEADS_MIN_POSTAL_CODE_ASC',
  LeadsMinPostalCodeDesc = 'LEADS_MIN_POSTAL_CODE_DESC',
  LeadsMinProvinceCodeAsc = 'LEADS_MIN_PROVINCE_CODE_ASC',
  LeadsMinProvinceCodeDesc = 'LEADS_MIN_PROVINCE_CODE_DESC',
  LeadsMinEmailAddressAsc = 'LEADS_MIN_EMAIL_ADDRESS_ASC',
  LeadsMinEmailAddressDesc = 'LEADS_MIN_EMAIL_ADDRESS_DESC',
  LeadsMinPhoneNumberAsc = 'LEADS_MIN_PHONE_NUMBER_ASC',
  LeadsMinPhoneNumberDesc = 'LEADS_MIN_PHONE_NUMBER_DESC',
  LeadsMinSourceUrlAsc = 'LEADS_MIN_SOURCE_URL_ASC',
  LeadsMinSourceUrlDesc = 'LEADS_MIN_SOURCE_URL_DESC',
  LeadsMinTransactionKeyAsc = 'LEADS_MIN_TRANSACTION_KEY_ASC',
  LeadsMinTransactionKeyDesc = 'LEADS_MIN_TRANSACTION_KEY_DESC',
  LeadsMinLeadspediaIdAsc = 'LEADS_MIN_LEADSPEDIA_ID_ASC',
  LeadsMinLeadspediaIdDesc = 'LEADS_MIN_LEADSPEDIA_ID_DESC',
  LeadsMinUpdatedAtAsc = 'LEADS_MIN_UPDATED_AT_ASC',
  LeadsMinUpdatedAtDesc = 'LEADS_MIN_UPDATED_AT_DESC',
  LeadsMinSearchTextAsc = 'LEADS_MIN_SEARCH_TEXT_ASC',
  LeadsMinSearchTextDesc = 'LEADS_MIN_SEARCH_TEXT_DESC',
  LeadsMinCommentAsc = 'LEADS_MIN_COMMENT_ASC',
  LeadsMinCommentDesc = 'LEADS_MIN_COMMENT_DESC',
  LeadsMinStateAsc = 'LEADS_MIN_STATE_ASC',
  LeadsMinStateDesc = 'LEADS_MIN_STATE_DESC',
  LeadsMinReadAsc = 'LEADS_MIN_READ_ASC',
  LeadsMinReadDesc = 'LEADS_MIN_READ_DESC',
  LeadsMinExternalIdsAsc = 'LEADS_MIN_EXTERNAL_IDS_ASC',
  LeadsMinExternalIdsDesc = 'LEADS_MIN_EXTERNAL_IDS_DESC',
  LeadsMinMetaAsc = 'LEADS_MIN_META_ASC',
  LeadsMinMetaDesc = 'LEADS_MIN_META_DESC',
  LeadsMinAdditionalInformationAsc = 'LEADS_MIN_ADDITIONAL_INFORMATION_ASC',
  LeadsMinAdditionalInformationDesc = 'LEADS_MIN_ADDITIONAL_INFORMATION_DESC',
  LeadsMinPhoneVerifiedAtAsc = 'LEADS_MIN_PHONE_VERIFIED_AT_ASC',
  LeadsMinPhoneVerifiedAtDesc = 'LEADS_MIN_PHONE_VERIFIED_AT_DESC',
  LeadsMinIpAddressAsc = 'LEADS_MIN_IP_ADDRESS_ASC',
  LeadsMinIpAddressDesc = 'LEADS_MIN_IP_ADDRESS_DESC',
  LeadsMinClientIdAsc = 'LEADS_MIN_CLIENT_ID_ASC',
  LeadsMinClientIdDesc = 'LEADS_MIN_CLIENT_ID_DESC',
  LeadsMinDeletedAsc = 'LEADS_MIN_DELETED_ASC',
  LeadsMinDeletedDesc = 'LEADS_MIN_DELETED_DESC',
  LeadsMinPriorityAsc = 'LEADS_MIN_PRIORITY_ASC',
  LeadsMinPriorityDesc = 'LEADS_MIN_PRIORITY_DESC',
  LeadsMinAssigneeAsc = 'LEADS_MIN_ASSIGNEE_ASC',
  LeadsMinAssigneeDesc = 'LEADS_MIN_ASSIGNEE_DESC',
  LeadsMinShortIdAsc = 'LEADS_MIN_SHORT_ID_ASC',
  LeadsMinShortIdDesc = 'LEADS_MIN_SHORT_ID_DESC',
  LeadsMaxIdAsc = 'LEADS_MAX_ID_ASC',
  LeadsMaxIdDesc = 'LEADS_MAX_ID_DESC',
  LeadsMaxCompanyIdAsc = 'LEADS_MAX_COMPANY_ID_ASC',
  LeadsMaxCompanyIdDesc = 'LEADS_MAX_COMPANY_ID_DESC',
  LeadsMaxCreatedAtAsc = 'LEADS_MAX_CREATED_AT_ASC',
  LeadsMaxCreatedAtDesc = 'LEADS_MAX_CREATED_AT_DESC',
  LeadsMaxFirstNameAsc = 'LEADS_MAX_FIRST_NAME_ASC',
  LeadsMaxFirstNameDesc = 'LEADS_MAX_FIRST_NAME_DESC',
  LeadsMaxLastNameAsc = 'LEADS_MAX_LAST_NAME_ASC',
  LeadsMaxLastNameDesc = 'LEADS_MAX_LAST_NAME_DESC',
  LeadsMaxDateOfBirthAsc = 'LEADS_MAX_DATE_OF_BIRTH_ASC',
  LeadsMaxDateOfBirthDesc = 'LEADS_MAX_DATE_OF_BIRTH_DESC',
  LeadsMaxStreetAsc = 'LEADS_MAX_STREET_ASC',
  LeadsMaxStreetDesc = 'LEADS_MAX_STREET_DESC',
  LeadsMaxStreetNumberAsc = 'LEADS_MAX_STREET_NUMBER_ASC',
  LeadsMaxStreetNumberDesc = 'LEADS_MAX_STREET_NUMBER_DESC',
  LeadsMaxUnitAsc = 'LEADS_MAX_UNIT_ASC',
  LeadsMaxUnitDesc = 'LEADS_MAX_UNIT_DESC',
  LeadsMaxCityAsc = 'LEADS_MAX_CITY_ASC',
  LeadsMaxCityDesc = 'LEADS_MAX_CITY_DESC',
  LeadsMaxCountryAsc = 'LEADS_MAX_COUNTRY_ASC',
  LeadsMaxCountryDesc = 'LEADS_MAX_COUNTRY_DESC',
  LeadsMaxPostalCodeAsc = 'LEADS_MAX_POSTAL_CODE_ASC',
  LeadsMaxPostalCodeDesc = 'LEADS_MAX_POSTAL_CODE_DESC',
  LeadsMaxProvinceCodeAsc = 'LEADS_MAX_PROVINCE_CODE_ASC',
  LeadsMaxProvinceCodeDesc = 'LEADS_MAX_PROVINCE_CODE_DESC',
  LeadsMaxEmailAddressAsc = 'LEADS_MAX_EMAIL_ADDRESS_ASC',
  LeadsMaxEmailAddressDesc = 'LEADS_MAX_EMAIL_ADDRESS_DESC',
  LeadsMaxPhoneNumberAsc = 'LEADS_MAX_PHONE_NUMBER_ASC',
  LeadsMaxPhoneNumberDesc = 'LEADS_MAX_PHONE_NUMBER_DESC',
  LeadsMaxSourceUrlAsc = 'LEADS_MAX_SOURCE_URL_ASC',
  LeadsMaxSourceUrlDesc = 'LEADS_MAX_SOURCE_URL_DESC',
  LeadsMaxTransactionKeyAsc = 'LEADS_MAX_TRANSACTION_KEY_ASC',
  LeadsMaxTransactionKeyDesc = 'LEADS_MAX_TRANSACTION_KEY_DESC',
  LeadsMaxLeadspediaIdAsc = 'LEADS_MAX_LEADSPEDIA_ID_ASC',
  LeadsMaxLeadspediaIdDesc = 'LEADS_MAX_LEADSPEDIA_ID_DESC',
  LeadsMaxUpdatedAtAsc = 'LEADS_MAX_UPDATED_AT_ASC',
  LeadsMaxUpdatedAtDesc = 'LEADS_MAX_UPDATED_AT_DESC',
  LeadsMaxSearchTextAsc = 'LEADS_MAX_SEARCH_TEXT_ASC',
  LeadsMaxSearchTextDesc = 'LEADS_MAX_SEARCH_TEXT_DESC',
  LeadsMaxCommentAsc = 'LEADS_MAX_COMMENT_ASC',
  LeadsMaxCommentDesc = 'LEADS_MAX_COMMENT_DESC',
  LeadsMaxStateAsc = 'LEADS_MAX_STATE_ASC',
  LeadsMaxStateDesc = 'LEADS_MAX_STATE_DESC',
  LeadsMaxReadAsc = 'LEADS_MAX_READ_ASC',
  LeadsMaxReadDesc = 'LEADS_MAX_READ_DESC',
  LeadsMaxExternalIdsAsc = 'LEADS_MAX_EXTERNAL_IDS_ASC',
  LeadsMaxExternalIdsDesc = 'LEADS_MAX_EXTERNAL_IDS_DESC',
  LeadsMaxMetaAsc = 'LEADS_MAX_META_ASC',
  LeadsMaxMetaDesc = 'LEADS_MAX_META_DESC',
  LeadsMaxAdditionalInformationAsc = 'LEADS_MAX_ADDITIONAL_INFORMATION_ASC',
  LeadsMaxAdditionalInformationDesc = 'LEADS_MAX_ADDITIONAL_INFORMATION_DESC',
  LeadsMaxPhoneVerifiedAtAsc = 'LEADS_MAX_PHONE_VERIFIED_AT_ASC',
  LeadsMaxPhoneVerifiedAtDesc = 'LEADS_MAX_PHONE_VERIFIED_AT_DESC',
  LeadsMaxIpAddressAsc = 'LEADS_MAX_IP_ADDRESS_ASC',
  LeadsMaxIpAddressDesc = 'LEADS_MAX_IP_ADDRESS_DESC',
  LeadsMaxClientIdAsc = 'LEADS_MAX_CLIENT_ID_ASC',
  LeadsMaxClientIdDesc = 'LEADS_MAX_CLIENT_ID_DESC',
  LeadsMaxDeletedAsc = 'LEADS_MAX_DELETED_ASC',
  LeadsMaxDeletedDesc = 'LEADS_MAX_DELETED_DESC',
  LeadsMaxPriorityAsc = 'LEADS_MAX_PRIORITY_ASC',
  LeadsMaxPriorityDesc = 'LEADS_MAX_PRIORITY_DESC',
  LeadsMaxAssigneeAsc = 'LEADS_MAX_ASSIGNEE_ASC',
  LeadsMaxAssigneeDesc = 'LEADS_MAX_ASSIGNEE_DESC',
  LeadsMaxShortIdAsc = 'LEADS_MAX_SHORT_ID_ASC',
  LeadsMaxShortIdDesc = 'LEADS_MAX_SHORT_ID_DESC',
  LeadsAverageIdAsc = 'LEADS_AVERAGE_ID_ASC',
  LeadsAverageIdDesc = 'LEADS_AVERAGE_ID_DESC',
  LeadsAverageCompanyIdAsc = 'LEADS_AVERAGE_COMPANY_ID_ASC',
  LeadsAverageCompanyIdDesc = 'LEADS_AVERAGE_COMPANY_ID_DESC',
  LeadsAverageCreatedAtAsc = 'LEADS_AVERAGE_CREATED_AT_ASC',
  LeadsAverageCreatedAtDesc = 'LEADS_AVERAGE_CREATED_AT_DESC',
  LeadsAverageFirstNameAsc = 'LEADS_AVERAGE_FIRST_NAME_ASC',
  LeadsAverageFirstNameDesc = 'LEADS_AVERAGE_FIRST_NAME_DESC',
  LeadsAverageLastNameAsc = 'LEADS_AVERAGE_LAST_NAME_ASC',
  LeadsAverageLastNameDesc = 'LEADS_AVERAGE_LAST_NAME_DESC',
  LeadsAverageDateOfBirthAsc = 'LEADS_AVERAGE_DATE_OF_BIRTH_ASC',
  LeadsAverageDateOfBirthDesc = 'LEADS_AVERAGE_DATE_OF_BIRTH_DESC',
  LeadsAverageStreetAsc = 'LEADS_AVERAGE_STREET_ASC',
  LeadsAverageStreetDesc = 'LEADS_AVERAGE_STREET_DESC',
  LeadsAverageStreetNumberAsc = 'LEADS_AVERAGE_STREET_NUMBER_ASC',
  LeadsAverageStreetNumberDesc = 'LEADS_AVERAGE_STREET_NUMBER_DESC',
  LeadsAverageUnitAsc = 'LEADS_AVERAGE_UNIT_ASC',
  LeadsAverageUnitDesc = 'LEADS_AVERAGE_UNIT_DESC',
  LeadsAverageCityAsc = 'LEADS_AVERAGE_CITY_ASC',
  LeadsAverageCityDesc = 'LEADS_AVERAGE_CITY_DESC',
  LeadsAverageCountryAsc = 'LEADS_AVERAGE_COUNTRY_ASC',
  LeadsAverageCountryDesc = 'LEADS_AVERAGE_COUNTRY_DESC',
  LeadsAveragePostalCodeAsc = 'LEADS_AVERAGE_POSTAL_CODE_ASC',
  LeadsAveragePostalCodeDesc = 'LEADS_AVERAGE_POSTAL_CODE_DESC',
  LeadsAverageProvinceCodeAsc = 'LEADS_AVERAGE_PROVINCE_CODE_ASC',
  LeadsAverageProvinceCodeDesc = 'LEADS_AVERAGE_PROVINCE_CODE_DESC',
  LeadsAverageEmailAddressAsc = 'LEADS_AVERAGE_EMAIL_ADDRESS_ASC',
  LeadsAverageEmailAddressDesc = 'LEADS_AVERAGE_EMAIL_ADDRESS_DESC',
  LeadsAveragePhoneNumberAsc = 'LEADS_AVERAGE_PHONE_NUMBER_ASC',
  LeadsAveragePhoneNumberDesc = 'LEADS_AVERAGE_PHONE_NUMBER_DESC',
  LeadsAverageSourceUrlAsc = 'LEADS_AVERAGE_SOURCE_URL_ASC',
  LeadsAverageSourceUrlDesc = 'LEADS_AVERAGE_SOURCE_URL_DESC',
  LeadsAverageTransactionKeyAsc = 'LEADS_AVERAGE_TRANSACTION_KEY_ASC',
  LeadsAverageTransactionKeyDesc = 'LEADS_AVERAGE_TRANSACTION_KEY_DESC',
  LeadsAverageLeadspediaIdAsc = 'LEADS_AVERAGE_LEADSPEDIA_ID_ASC',
  LeadsAverageLeadspediaIdDesc = 'LEADS_AVERAGE_LEADSPEDIA_ID_DESC',
  LeadsAverageUpdatedAtAsc = 'LEADS_AVERAGE_UPDATED_AT_ASC',
  LeadsAverageUpdatedAtDesc = 'LEADS_AVERAGE_UPDATED_AT_DESC',
  LeadsAverageSearchTextAsc = 'LEADS_AVERAGE_SEARCH_TEXT_ASC',
  LeadsAverageSearchTextDesc = 'LEADS_AVERAGE_SEARCH_TEXT_DESC',
  LeadsAverageCommentAsc = 'LEADS_AVERAGE_COMMENT_ASC',
  LeadsAverageCommentDesc = 'LEADS_AVERAGE_COMMENT_DESC',
  LeadsAverageStateAsc = 'LEADS_AVERAGE_STATE_ASC',
  LeadsAverageStateDesc = 'LEADS_AVERAGE_STATE_DESC',
  LeadsAverageReadAsc = 'LEADS_AVERAGE_READ_ASC',
  LeadsAverageReadDesc = 'LEADS_AVERAGE_READ_DESC',
  LeadsAverageExternalIdsAsc = 'LEADS_AVERAGE_EXTERNAL_IDS_ASC',
  LeadsAverageExternalIdsDesc = 'LEADS_AVERAGE_EXTERNAL_IDS_DESC',
  LeadsAverageMetaAsc = 'LEADS_AVERAGE_META_ASC',
  LeadsAverageMetaDesc = 'LEADS_AVERAGE_META_DESC',
  LeadsAverageAdditionalInformationAsc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  LeadsAverageAdditionalInformationDesc = 'LEADS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  LeadsAveragePhoneVerifiedAtAsc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_ASC',
  LeadsAveragePhoneVerifiedAtDesc = 'LEADS_AVERAGE_PHONE_VERIFIED_AT_DESC',
  LeadsAverageIpAddressAsc = 'LEADS_AVERAGE_IP_ADDRESS_ASC',
  LeadsAverageIpAddressDesc = 'LEADS_AVERAGE_IP_ADDRESS_DESC',
  LeadsAverageClientIdAsc = 'LEADS_AVERAGE_CLIENT_ID_ASC',
  LeadsAverageClientIdDesc = 'LEADS_AVERAGE_CLIENT_ID_DESC',
  LeadsAverageDeletedAsc = 'LEADS_AVERAGE_DELETED_ASC',
  LeadsAverageDeletedDesc = 'LEADS_AVERAGE_DELETED_DESC',
  LeadsAveragePriorityAsc = 'LEADS_AVERAGE_PRIORITY_ASC',
  LeadsAveragePriorityDesc = 'LEADS_AVERAGE_PRIORITY_DESC',
  LeadsAverageAssigneeAsc = 'LEADS_AVERAGE_ASSIGNEE_ASC',
  LeadsAverageAssigneeDesc = 'LEADS_AVERAGE_ASSIGNEE_DESC',
  LeadsAverageShortIdAsc = 'LEADS_AVERAGE_SHORT_ID_ASC',
  LeadsAverageShortIdDesc = 'LEADS_AVERAGE_SHORT_ID_DESC',
  LeadsStddevSampleIdAsc = 'LEADS_STDDEV_SAMPLE_ID_ASC',
  LeadsStddevSampleIdDesc = 'LEADS_STDDEV_SAMPLE_ID_DESC',
  LeadsStddevSampleCompanyIdAsc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LeadsStddevSampleCompanyIdDesc = 'LEADS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LeadsStddevSampleCreatedAtAsc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadsStddevSampleCreatedAtDesc = 'LEADS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadsStddevSampleFirstNameAsc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_ASC',
  LeadsStddevSampleFirstNameDesc = 'LEADS_STDDEV_SAMPLE_FIRST_NAME_DESC',
  LeadsStddevSampleLastNameAsc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_ASC',
  LeadsStddevSampleLastNameDesc = 'LEADS_STDDEV_SAMPLE_LAST_NAME_DESC',
  LeadsStddevSampleDateOfBirthAsc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsStddevSampleDateOfBirthDesc = 'LEADS_STDDEV_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsStddevSampleStreetAsc = 'LEADS_STDDEV_SAMPLE_STREET_ASC',
  LeadsStddevSampleStreetDesc = 'LEADS_STDDEV_SAMPLE_STREET_DESC',
  LeadsStddevSampleStreetNumberAsc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_ASC',
  LeadsStddevSampleStreetNumberDesc = 'LEADS_STDDEV_SAMPLE_STREET_NUMBER_DESC',
  LeadsStddevSampleUnitAsc = 'LEADS_STDDEV_SAMPLE_UNIT_ASC',
  LeadsStddevSampleUnitDesc = 'LEADS_STDDEV_SAMPLE_UNIT_DESC',
  LeadsStddevSampleCityAsc = 'LEADS_STDDEV_SAMPLE_CITY_ASC',
  LeadsStddevSampleCityDesc = 'LEADS_STDDEV_SAMPLE_CITY_DESC',
  LeadsStddevSampleCountryAsc = 'LEADS_STDDEV_SAMPLE_COUNTRY_ASC',
  LeadsStddevSampleCountryDesc = 'LEADS_STDDEV_SAMPLE_COUNTRY_DESC',
  LeadsStddevSamplePostalCodeAsc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LeadsStddevSamplePostalCodeDesc = 'LEADS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LeadsStddevSampleProvinceCodeAsc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_ASC',
  LeadsStddevSampleProvinceCodeDesc = 'LEADS_STDDEV_SAMPLE_PROVINCE_CODE_DESC',
  LeadsStddevSampleEmailAddressAsc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsStddevSampleEmailAddressDesc = 'LEADS_STDDEV_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsStddevSamplePhoneNumberAsc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  LeadsStddevSamplePhoneNumberDesc = 'LEADS_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  LeadsStddevSampleSourceUrlAsc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  LeadsStddevSampleSourceUrlDesc = 'LEADS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  LeadsStddevSampleTransactionKeyAsc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsStddevSampleTransactionKeyDesc = 'LEADS_STDDEV_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsStddevSampleLeadspediaIdAsc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsStddevSampleLeadspediaIdDesc = 'LEADS_STDDEV_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsStddevSampleUpdatedAtAsc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadsStddevSampleUpdatedAtDesc = 'LEADS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadsStddevSampleSearchTextAsc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  LeadsStddevSampleSearchTextDesc = 'LEADS_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  LeadsStddevSampleCommentAsc = 'LEADS_STDDEV_SAMPLE_COMMENT_ASC',
  LeadsStddevSampleCommentDesc = 'LEADS_STDDEV_SAMPLE_COMMENT_DESC',
  LeadsStddevSampleStateAsc = 'LEADS_STDDEV_SAMPLE_STATE_ASC',
  LeadsStddevSampleStateDesc = 'LEADS_STDDEV_SAMPLE_STATE_DESC',
  LeadsStddevSampleReadAsc = 'LEADS_STDDEV_SAMPLE_READ_ASC',
  LeadsStddevSampleReadDesc = 'LEADS_STDDEV_SAMPLE_READ_DESC',
  LeadsStddevSampleExternalIdsAsc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsStddevSampleExternalIdsDesc = 'LEADS_STDDEV_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsStddevSampleMetaAsc = 'LEADS_STDDEV_SAMPLE_META_ASC',
  LeadsStddevSampleMetaDesc = 'LEADS_STDDEV_SAMPLE_META_DESC',
  LeadsStddevSampleAdditionalInformationAsc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevSampleAdditionalInformationDesc = 'LEADS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevSamplePhoneVerifiedAtAsc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsStddevSamplePhoneVerifiedAtDesc = 'LEADS_STDDEV_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsStddevSampleIpAddressAsc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  LeadsStddevSampleIpAddressDesc = 'LEADS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  LeadsStddevSampleClientIdAsc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  LeadsStddevSampleClientIdDesc = 'LEADS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  LeadsStddevSampleDeletedAsc = 'LEADS_STDDEV_SAMPLE_DELETED_ASC',
  LeadsStddevSampleDeletedDesc = 'LEADS_STDDEV_SAMPLE_DELETED_DESC',
  LeadsStddevSamplePriorityAsc = 'LEADS_STDDEV_SAMPLE_PRIORITY_ASC',
  LeadsStddevSamplePriorityDesc = 'LEADS_STDDEV_SAMPLE_PRIORITY_DESC',
  LeadsStddevSampleAssigneeAsc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_ASC',
  LeadsStddevSampleAssigneeDesc = 'LEADS_STDDEV_SAMPLE_ASSIGNEE_DESC',
  LeadsStddevSampleShortIdAsc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_ASC',
  LeadsStddevSampleShortIdDesc = 'LEADS_STDDEV_SAMPLE_SHORT_ID_DESC',
  LeadsStddevPopulationIdAsc = 'LEADS_STDDEV_POPULATION_ID_ASC',
  LeadsStddevPopulationIdDesc = 'LEADS_STDDEV_POPULATION_ID_DESC',
  LeadsStddevPopulationCompanyIdAsc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_ASC',
  LeadsStddevPopulationCompanyIdDesc = 'LEADS_STDDEV_POPULATION_COMPANY_ID_DESC',
  LeadsStddevPopulationCreatedAtAsc = 'LEADS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadsStddevPopulationCreatedAtDesc = 'LEADS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadsStddevPopulationFirstNameAsc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_ASC',
  LeadsStddevPopulationFirstNameDesc = 'LEADS_STDDEV_POPULATION_FIRST_NAME_DESC',
  LeadsStddevPopulationLastNameAsc = 'LEADS_STDDEV_POPULATION_LAST_NAME_ASC',
  LeadsStddevPopulationLastNameDesc = 'LEADS_STDDEV_POPULATION_LAST_NAME_DESC',
  LeadsStddevPopulationDateOfBirthAsc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsStddevPopulationDateOfBirthDesc = 'LEADS_STDDEV_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsStddevPopulationStreetAsc = 'LEADS_STDDEV_POPULATION_STREET_ASC',
  LeadsStddevPopulationStreetDesc = 'LEADS_STDDEV_POPULATION_STREET_DESC',
  LeadsStddevPopulationStreetNumberAsc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_ASC',
  LeadsStddevPopulationStreetNumberDesc = 'LEADS_STDDEV_POPULATION_STREET_NUMBER_DESC',
  LeadsStddevPopulationUnitAsc = 'LEADS_STDDEV_POPULATION_UNIT_ASC',
  LeadsStddevPopulationUnitDesc = 'LEADS_STDDEV_POPULATION_UNIT_DESC',
  LeadsStddevPopulationCityAsc = 'LEADS_STDDEV_POPULATION_CITY_ASC',
  LeadsStddevPopulationCityDesc = 'LEADS_STDDEV_POPULATION_CITY_DESC',
  LeadsStddevPopulationCountryAsc = 'LEADS_STDDEV_POPULATION_COUNTRY_ASC',
  LeadsStddevPopulationCountryDesc = 'LEADS_STDDEV_POPULATION_COUNTRY_DESC',
  LeadsStddevPopulationPostalCodeAsc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LeadsStddevPopulationPostalCodeDesc = 'LEADS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LeadsStddevPopulationProvinceCodeAsc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_ASC',
  LeadsStddevPopulationProvinceCodeDesc = 'LEADS_STDDEV_POPULATION_PROVINCE_CODE_DESC',
  LeadsStddevPopulationEmailAddressAsc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsStddevPopulationEmailAddressDesc = 'LEADS_STDDEV_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsStddevPopulationPhoneNumberAsc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  LeadsStddevPopulationPhoneNumberDesc = 'LEADS_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  LeadsStddevPopulationSourceUrlAsc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_ASC',
  LeadsStddevPopulationSourceUrlDesc = 'LEADS_STDDEV_POPULATION_SOURCE_URL_DESC',
  LeadsStddevPopulationTransactionKeyAsc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_ASC',
  LeadsStddevPopulationTransactionKeyDesc = 'LEADS_STDDEV_POPULATION_TRANSACTION_KEY_DESC',
  LeadsStddevPopulationLeadspediaIdAsc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsStddevPopulationLeadspediaIdDesc = 'LEADS_STDDEV_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsStddevPopulationUpdatedAtAsc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadsStddevPopulationUpdatedAtDesc = 'LEADS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadsStddevPopulationSearchTextAsc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  LeadsStddevPopulationSearchTextDesc = 'LEADS_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  LeadsStddevPopulationCommentAsc = 'LEADS_STDDEV_POPULATION_COMMENT_ASC',
  LeadsStddevPopulationCommentDesc = 'LEADS_STDDEV_POPULATION_COMMENT_DESC',
  LeadsStddevPopulationStateAsc = 'LEADS_STDDEV_POPULATION_STATE_ASC',
  LeadsStddevPopulationStateDesc = 'LEADS_STDDEV_POPULATION_STATE_DESC',
  LeadsStddevPopulationReadAsc = 'LEADS_STDDEV_POPULATION_READ_ASC',
  LeadsStddevPopulationReadDesc = 'LEADS_STDDEV_POPULATION_READ_DESC',
  LeadsStddevPopulationExternalIdsAsc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_ASC',
  LeadsStddevPopulationExternalIdsDesc = 'LEADS_STDDEV_POPULATION_EXTERNAL_IDS_DESC',
  LeadsStddevPopulationMetaAsc = 'LEADS_STDDEV_POPULATION_META_ASC',
  LeadsStddevPopulationMetaDesc = 'LEADS_STDDEV_POPULATION_META_DESC',
  LeadsStddevPopulationAdditionalInformationAsc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsStddevPopulationAdditionalInformationDesc = 'LEADS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsStddevPopulationPhoneVerifiedAtAsc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsStddevPopulationPhoneVerifiedAtDesc = 'LEADS_STDDEV_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsStddevPopulationIpAddressAsc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  LeadsStddevPopulationIpAddressDesc = 'LEADS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  LeadsStddevPopulationClientIdAsc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_ASC',
  LeadsStddevPopulationClientIdDesc = 'LEADS_STDDEV_POPULATION_CLIENT_ID_DESC',
  LeadsStddevPopulationDeletedAsc = 'LEADS_STDDEV_POPULATION_DELETED_ASC',
  LeadsStddevPopulationDeletedDesc = 'LEADS_STDDEV_POPULATION_DELETED_DESC',
  LeadsStddevPopulationPriorityAsc = 'LEADS_STDDEV_POPULATION_PRIORITY_ASC',
  LeadsStddevPopulationPriorityDesc = 'LEADS_STDDEV_POPULATION_PRIORITY_DESC',
  LeadsStddevPopulationAssigneeAsc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_ASC',
  LeadsStddevPopulationAssigneeDesc = 'LEADS_STDDEV_POPULATION_ASSIGNEE_DESC',
  LeadsStddevPopulationShortIdAsc = 'LEADS_STDDEV_POPULATION_SHORT_ID_ASC',
  LeadsStddevPopulationShortIdDesc = 'LEADS_STDDEV_POPULATION_SHORT_ID_DESC',
  LeadsVarianceSampleIdAsc = 'LEADS_VARIANCE_SAMPLE_ID_ASC',
  LeadsVarianceSampleIdDesc = 'LEADS_VARIANCE_SAMPLE_ID_DESC',
  LeadsVarianceSampleCompanyIdAsc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LeadsVarianceSampleCompanyIdDesc = 'LEADS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LeadsVarianceSampleCreatedAtAsc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadsVarianceSampleCreatedAtDesc = 'LEADS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadsVarianceSampleFirstNameAsc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  LeadsVarianceSampleFirstNameDesc = 'LEADS_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  LeadsVarianceSampleLastNameAsc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_ASC',
  LeadsVarianceSampleLastNameDesc = 'LEADS_VARIANCE_SAMPLE_LAST_NAME_DESC',
  LeadsVarianceSampleDateOfBirthAsc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_ASC',
  LeadsVarianceSampleDateOfBirthDesc = 'LEADS_VARIANCE_SAMPLE_DATE_OF_BIRTH_DESC',
  LeadsVarianceSampleStreetAsc = 'LEADS_VARIANCE_SAMPLE_STREET_ASC',
  LeadsVarianceSampleStreetDesc = 'LEADS_VARIANCE_SAMPLE_STREET_DESC',
  LeadsVarianceSampleStreetNumberAsc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_ASC',
  LeadsVarianceSampleStreetNumberDesc = 'LEADS_VARIANCE_SAMPLE_STREET_NUMBER_DESC',
  LeadsVarianceSampleUnitAsc = 'LEADS_VARIANCE_SAMPLE_UNIT_ASC',
  LeadsVarianceSampleUnitDesc = 'LEADS_VARIANCE_SAMPLE_UNIT_DESC',
  LeadsVarianceSampleCityAsc = 'LEADS_VARIANCE_SAMPLE_CITY_ASC',
  LeadsVarianceSampleCityDesc = 'LEADS_VARIANCE_SAMPLE_CITY_DESC',
  LeadsVarianceSampleCountryAsc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_ASC',
  LeadsVarianceSampleCountryDesc = 'LEADS_VARIANCE_SAMPLE_COUNTRY_DESC',
  LeadsVarianceSamplePostalCodeAsc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LeadsVarianceSamplePostalCodeDesc = 'LEADS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LeadsVarianceSampleProvinceCodeAsc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_ASC',
  LeadsVarianceSampleProvinceCodeDesc = 'LEADS_VARIANCE_SAMPLE_PROVINCE_CODE_DESC',
  LeadsVarianceSampleEmailAddressAsc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ASC',
  LeadsVarianceSampleEmailAddressDesc = 'LEADS_VARIANCE_SAMPLE_EMAIL_ADDRESS_DESC',
  LeadsVarianceSamplePhoneNumberAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  LeadsVarianceSamplePhoneNumberDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  LeadsVarianceSampleSourceUrlAsc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  LeadsVarianceSampleSourceUrlDesc = 'LEADS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  LeadsVarianceSampleTransactionKeyAsc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_ASC',
  LeadsVarianceSampleTransactionKeyDesc = 'LEADS_VARIANCE_SAMPLE_TRANSACTION_KEY_DESC',
  LeadsVarianceSampleLeadspediaIdAsc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_ASC',
  LeadsVarianceSampleLeadspediaIdDesc = 'LEADS_VARIANCE_SAMPLE_LEADSPEDIA_ID_DESC',
  LeadsVarianceSampleUpdatedAtAsc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadsVarianceSampleUpdatedAtDesc = 'LEADS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadsVarianceSampleSearchTextAsc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  LeadsVarianceSampleSearchTextDesc = 'LEADS_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  LeadsVarianceSampleCommentAsc = 'LEADS_VARIANCE_SAMPLE_COMMENT_ASC',
  LeadsVarianceSampleCommentDesc = 'LEADS_VARIANCE_SAMPLE_COMMENT_DESC',
  LeadsVarianceSampleStateAsc = 'LEADS_VARIANCE_SAMPLE_STATE_ASC',
  LeadsVarianceSampleStateDesc = 'LEADS_VARIANCE_SAMPLE_STATE_DESC',
  LeadsVarianceSampleReadAsc = 'LEADS_VARIANCE_SAMPLE_READ_ASC',
  LeadsVarianceSampleReadDesc = 'LEADS_VARIANCE_SAMPLE_READ_DESC',
  LeadsVarianceSampleExternalIdsAsc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_ASC',
  LeadsVarianceSampleExternalIdsDesc = 'LEADS_VARIANCE_SAMPLE_EXTERNAL_IDS_DESC',
  LeadsVarianceSampleMetaAsc = 'LEADS_VARIANCE_SAMPLE_META_ASC',
  LeadsVarianceSampleMetaDesc = 'LEADS_VARIANCE_SAMPLE_META_DESC',
  LeadsVarianceSampleAdditionalInformationAsc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  LeadsVarianceSampleAdditionalInformationDesc = 'LEADS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  LeadsVarianceSamplePhoneVerifiedAtAsc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_ASC',
  LeadsVarianceSamplePhoneVerifiedAtDesc = 'LEADS_VARIANCE_SAMPLE_PHONE_VERIFIED_AT_DESC',
  LeadsVarianceSampleIpAddressAsc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  LeadsVarianceSampleIpAddressDesc = 'LEADS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  LeadsVarianceSampleClientIdAsc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  LeadsVarianceSampleClientIdDesc = 'LEADS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  LeadsVarianceSampleDeletedAsc = 'LEADS_VARIANCE_SAMPLE_DELETED_ASC',
  LeadsVarianceSampleDeletedDesc = 'LEADS_VARIANCE_SAMPLE_DELETED_DESC',
  LeadsVarianceSamplePriorityAsc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_ASC',
  LeadsVarianceSamplePriorityDesc = 'LEADS_VARIANCE_SAMPLE_PRIORITY_DESC',
  LeadsVarianceSampleAssigneeAsc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_ASC',
  LeadsVarianceSampleAssigneeDesc = 'LEADS_VARIANCE_SAMPLE_ASSIGNEE_DESC',
  LeadsVarianceSampleShortIdAsc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_ASC',
  LeadsVarianceSampleShortIdDesc = 'LEADS_VARIANCE_SAMPLE_SHORT_ID_DESC',
  LeadsVariancePopulationIdAsc = 'LEADS_VARIANCE_POPULATION_ID_ASC',
  LeadsVariancePopulationIdDesc = 'LEADS_VARIANCE_POPULATION_ID_DESC',
  LeadsVariancePopulationCompanyIdAsc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LeadsVariancePopulationCompanyIdDesc = 'LEADS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LeadsVariancePopulationCreatedAtAsc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadsVariancePopulationCreatedAtDesc = 'LEADS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadsVariancePopulationFirstNameAsc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_ASC',
  LeadsVariancePopulationFirstNameDesc = 'LEADS_VARIANCE_POPULATION_FIRST_NAME_DESC',
  LeadsVariancePopulationLastNameAsc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_ASC',
  LeadsVariancePopulationLastNameDesc = 'LEADS_VARIANCE_POPULATION_LAST_NAME_DESC',
  LeadsVariancePopulationDateOfBirthAsc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_ASC',
  LeadsVariancePopulationDateOfBirthDesc = 'LEADS_VARIANCE_POPULATION_DATE_OF_BIRTH_DESC',
  LeadsVariancePopulationStreetAsc = 'LEADS_VARIANCE_POPULATION_STREET_ASC',
  LeadsVariancePopulationStreetDesc = 'LEADS_VARIANCE_POPULATION_STREET_DESC',
  LeadsVariancePopulationStreetNumberAsc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_ASC',
  LeadsVariancePopulationStreetNumberDesc = 'LEADS_VARIANCE_POPULATION_STREET_NUMBER_DESC',
  LeadsVariancePopulationUnitAsc = 'LEADS_VARIANCE_POPULATION_UNIT_ASC',
  LeadsVariancePopulationUnitDesc = 'LEADS_VARIANCE_POPULATION_UNIT_DESC',
  LeadsVariancePopulationCityAsc = 'LEADS_VARIANCE_POPULATION_CITY_ASC',
  LeadsVariancePopulationCityDesc = 'LEADS_VARIANCE_POPULATION_CITY_DESC',
  LeadsVariancePopulationCountryAsc = 'LEADS_VARIANCE_POPULATION_COUNTRY_ASC',
  LeadsVariancePopulationCountryDesc = 'LEADS_VARIANCE_POPULATION_COUNTRY_DESC',
  LeadsVariancePopulationPostalCodeAsc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LeadsVariancePopulationPostalCodeDesc = 'LEADS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LeadsVariancePopulationProvinceCodeAsc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_ASC',
  LeadsVariancePopulationProvinceCodeDesc = 'LEADS_VARIANCE_POPULATION_PROVINCE_CODE_DESC',
  LeadsVariancePopulationEmailAddressAsc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_ASC',
  LeadsVariancePopulationEmailAddressDesc = 'LEADS_VARIANCE_POPULATION_EMAIL_ADDRESS_DESC',
  LeadsVariancePopulationPhoneNumberAsc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  LeadsVariancePopulationPhoneNumberDesc = 'LEADS_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  LeadsVariancePopulationSourceUrlAsc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  LeadsVariancePopulationSourceUrlDesc = 'LEADS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  LeadsVariancePopulationTransactionKeyAsc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_ASC',
  LeadsVariancePopulationTransactionKeyDesc = 'LEADS_VARIANCE_POPULATION_TRANSACTION_KEY_DESC',
  LeadsVariancePopulationLeadspediaIdAsc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_ASC',
  LeadsVariancePopulationLeadspediaIdDesc = 'LEADS_VARIANCE_POPULATION_LEADSPEDIA_ID_DESC',
  LeadsVariancePopulationUpdatedAtAsc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadsVariancePopulationUpdatedAtDesc = 'LEADS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadsVariancePopulationSearchTextAsc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  LeadsVariancePopulationSearchTextDesc = 'LEADS_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  LeadsVariancePopulationCommentAsc = 'LEADS_VARIANCE_POPULATION_COMMENT_ASC',
  LeadsVariancePopulationCommentDesc = 'LEADS_VARIANCE_POPULATION_COMMENT_DESC',
  LeadsVariancePopulationStateAsc = 'LEADS_VARIANCE_POPULATION_STATE_ASC',
  LeadsVariancePopulationStateDesc = 'LEADS_VARIANCE_POPULATION_STATE_DESC',
  LeadsVariancePopulationReadAsc = 'LEADS_VARIANCE_POPULATION_READ_ASC',
  LeadsVariancePopulationReadDesc = 'LEADS_VARIANCE_POPULATION_READ_DESC',
  LeadsVariancePopulationExternalIdsAsc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_ASC',
  LeadsVariancePopulationExternalIdsDesc = 'LEADS_VARIANCE_POPULATION_EXTERNAL_IDS_DESC',
  LeadsVariancePopulationMetaAsc = 'LEADS_VARIANCE_POPULATION_META_ASC',
  LeadsVariancePopulationMetaDesc = 'LEADS_VARIANCE_POPULATION_META_DESC',
  LeadsVariancePopulationAdditionalInformationAsc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  LeadsVariancePopulationAdditionalInformationDesc = 'LEADS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  LeadsVariancePopulationPhoneVerifiedAtAsc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_ASC',
  LeadsVariancePopulationPhoneVerifiedAtDesc = 'LEADS_VARIANCE_POPULATION_PHONE_VERIFIED_AT_DESC',
  LeadsVariancePopulationIpAddressAsc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  LeadsVariancePopulationIpAddressDesc = 'LEADS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  LeadsVariancePopulationClientIdAsc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  LeadsVariancePopulationClientIdDesc = 'LEADS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  LeadsVariancePopulationDeletedAsc = 'LEADS_VARIANCE_POPULATION_DELETED_ASC',
  LeadsVariancePopulationDeletedDesc = 'LEADS_VARIANCE_POPULATION_DELETED_DESC',
  LeadsVariancePopulationPriorityAsc = 'LEADS_VARIANCE_POPULATION_PRIORITY_ASC',
  LeadsVariancePopulationPriorityDesc = 'LEADS_VARIANCE_POPULATION_PRIORITY_DESC',
  LeadsVariancePopulationAssigneeAsc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_ASC',
  LeadsVariancePopulationAssigneeDesc = 'LEADS_VARIANCE_POPULATION_ASSIGNEE_DESC',
  LeadsVariancePopulationShortIdAsc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_ASC',
  LeadsVariancePopulationShortIdDesc = 'LEADS_VARIANCE_POPULATION_SHORT_ID_DESC',
  CompanyGroupsCountAsc = 'COMPANY_GROUPS_COUNT_ASC',
  CompanyGroupsCountDesc = 'COMPANY_GROUPS_COUNT_DESC',
  CompanyGroupsSumCompanyIdAsc = 'COMPANY_GROUPS_SUM_COMPANY_ID_ASC',
  CompanyGroupsSumCompanyIdDesc = 'COMPANY_GROUPS_SUM_COMPANY_ID_DESC',
  CompanyGroupsSumGroupIdAsc = 'COMPANY_GROUPS_SUM_GROUP_ID_ASC',
  CompanyGroupsSumGroupIdDesc = 'COMPANY_GROUPS_SUM_GROUP_ID_DESC',
  CompanyGroupsSumCreatedAtAsc = 'COMPANY_GROUPS_SUM_CREATED_AT_ASC',
  CompanyGroupsSumCreatedAtDesc = 'COMPANY_GROUPS_SUM_CREATED_AT_DESC',
  CompanyGroupsSumUpdatedAtAsc = 'COMPANY_GROUPS_SUM_UPDATED_AT_ASC',
  CompanyGroupsSumUpdatedAtDesc = 'COMPANY_GROUPS_SUM_UPDATED_AT_DESC',
  CompanyGroupsDistinctCountCompanyIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyGroupsDistinctCountCompanyIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyGroupsDistinctCountGroupIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyGroupsDistinctCountGroupIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyGroupsDistinctCountCreatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyGroupsDistinctCountCreatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyGroupsDistinctCountUpdatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyGroupsDistinctCountUpdatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyGroupsMinCompanyIdAsc = 'COMPANY_GROUPS_MIN_COMPANY_ID_ASC',
  CompanyGroupsMinCompanyIdDesc = 'COMPANY_GROUPS_MIN_COMPANY_ID_DESC',
  CompanyGroupsMinGroupIdAsc = 'COMPANY_GROUPS_MIN_GROUP_ID_ASC',
  CompanyGroupsMinGroupIdDesc = 'COMPANY_GROUPS_MIN_GROUP_ID_DESC',
  CompanyGroupsMinCreatedAtAsc = 'COMPANY_GROUPS_MIN_CREATED_AT_ASC',
  CompanyGroupsMinCreatedAtDesc = 'COMPANY_GROUPS_MIN_CREATED_AT_DESC',
  CompanyGroupsMinUpdatedAtAsc = 'COMPANY_GROUPS_MIN_UPDATED_AT_ASC',
  CompanyGroupsMinUpdatedAtDesc = 'COMPANY_GROUPS_MIN_UPDATED_AT_DESC',
  CompanyGroupsMaxCompanyIdAsc = 'COMPANY_GROUPS_MAX_COMPANY_ID_ASC',
  CompanyGroupsMaxCompanyIdDesc = 'COMPANY_GROUPS_MAX_COMPANY_ID_DESC',
  CompanyGroupsMaxGroupIdAsc = 'COMPANY_GROUPS_MAX_GROUP_ID_ASC',
  CompanyGroupsMaxGroupIdDesc = 'COMPANY_GROUPS_MAX_GROUP_ID_DESC',
  CompanyGroupsMaxCreatedAtAsc = 'COMPANY_GROUPS_MAX_CREATED_AT_ASC',
  CompanyGroupsMaxCreatedAtDesc = 'COMPANY_GROUPS_MAX_CREATED_AT_DESC',
  CompanyGroupsMaxUpdatedAtAsc = 'COMPANY_GROUPS_MAX_UPDATED_AT_ASC',
  CompanyGroupsMaxUpdatedAtDesc = 'COMPANY_GROUPS_MAX_UPDATED_AT_DESC',
  CompanyGroupsAverageCompanyIdAsc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_ASC',
  CompanyGroupsAverageCompanyIdDesc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_DESC',
  CompanyGroupsAverageGroupIdAsc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_ASC',
  CompanyGroupsAverageGroupIdDesc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_DESC',
  CompanyGroupsAverageCreatedAtAsc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_ASC',
  CompanyGroupsAverageCreatedAtDesc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_DESC',
  CompanyGroupsAverageUpdatedAtAsc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_ASC',
  CompanyGroupsAverageUpdatedAtDesc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_DESC',
  CompanyGroupsStddevSampleCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsStddevSampleCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsStddevSampleGroupIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsStddevSampleGroupIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsStddevSampleCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsStddevSampleCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsStddevSampleUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsStddevSampleUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsStddevPopulationCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsStddevPopulationCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsStddevPopulationGroupIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyGroupsStddevPopulationGroupIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyGroupsStddevPopulationCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyGroupsStddevPopulationCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyGroupsStddevPopulationUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsStddevPopulationUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyGroupsVarianceSampleCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsVarianceSampleCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsVarianceSampleGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsVarianceSampleGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsVarianceSampleCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsVarianceSampleCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsVarianceSampleUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsVarianceSampleUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsVariancePopulationCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsVariancePopulationCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsVariancePopulationGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyGroupsVariancePopulationGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyGroupsVariancePopulationCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyGroupsVariancePopulationCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyGroupsVariancePopulationUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsVariancePopulationUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsCountAsc = 'COMPANY_PRODUCTS_COUNT_ASC',
  CompanyProductsCountDesc = 'COMPANY_PRODUCTS_COUNT_DESC',
  CompanyProductsSumCompanyIdAsc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_ASC',
  CompanyProductsSumCompanyIdDesc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_DESC',
  CompanyProductsSumProductIdAsc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_ASC',
  CompanyProductsSumProductIdDesc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_DESC',
  CompanyProductsSumCreatedAtAsc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_ASC',
  CompanyProductsSumCreatedAtDesc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_DESC',
  CompanyProductsSumUpdatedAtAsc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_ASC',
  CompanyProductsSumUpdatedAtDesc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_DESC',
  CompanyProductsSumIdAsc = 'COMPANY_PRODUCTS_SUM_ID_ASC',
  CompanyProductsSumIdDesc = 'COMPANY_PRODUCTS_SUM_ID_DESC',
  CompanyProductsSumEnabledAsc = 'COMPANY_PRODUCTS_SUM_ENABLED_ASC',
  CompanyProductsSumEnabledDesc = 'COMPANY_PRODUCTS_SUM_ENABLED_DESC',
  CompanyProductsDistinctCountCompanyIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyProductsDistinctCountCompanyIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyProductsDistinctCountProductIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  CompanyProductsDistinctCountProductIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  CompanyProductsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductsDistinctCountIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_ASC',
  CompanyProductsDistinctCountIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_DESC',
  CompanyProductsDistinctCountEnabledAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductsDistinctCountEnabledDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductsMinCompanyIdAsc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_ASC',
  CompanyProductsMinCompanyIdDesc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_DESC',
  CompanyProductsMinProductIdAsc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_ASC',
  CompanyProductsMinProductIdDesc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_DESC',
  CompanyProductsMinCreatedAtAsc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_ASC',
  CompanyProductsMinCreatedAtDesc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_DESC',
  CompanyProductsMinUpdatedAtAsc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_ASC',
  CompanyProductsMinUpdatedAtDesc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_DESC',
  CompanyProductsMinIdAsc = 'COMPANY_PRODUCTS_MIN_ID_ASC',
  CompanyProductsMinIdDesc = 'COMPANY_PRODUCTS_MIN_ID_DESC',
  CompanyProductsMinEnabledAsc = 'COMPANY_PRODUCTS_MIN_ENABLED_ASC',
  CompanyProductsMinEnabledDesc = 'COMPANY_PRODUCTS_MIN_ENABLED_DESC',
  CompanyProductsMaxCompanyIdAsc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_ASC',
  CompanyProductsMaxCompanyIdDesc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_DESC',
  CompanyProductsMaxProductIdAsc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_ASC',
  CompanyProductsMaxProductIdDesc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_DESC',
  CompanyProductsMaxCreatedAtAsc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_ASC',
  CompanyProductsMaxCreatedAtDesc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_DESC',
  CompanyProductsMaxUpdatedAtAsc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_ASC',
  CompanyProductsMaxUpdatedAtDesc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_DESC',
  CompanyProductsMaxIdAsc = 'COMPANY_PRODUCTS_MAX_ID_ASC',
  CompanyProductsMaxIdDesc = 'COMPANY_PRODUCTS_MAX_ID_DESC',
  CompanyProductsMaxEnabledAsc = 'COMPANY_PRODUCTS_MAX_ENABLED_ASC',
  CompanyProductsMaxEnabledDesc = 'COMPANY_PRODUCTS_MAX_ENABLED_DESC',
  CompanyProductsAverageCompanyIdAsc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_ASC',
  CompanyProductsAverageCompanyIdDesc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_DESC',
  CompanyProductsAverageProductIdAsc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_ASC',
  CompanyProductsAverageProductIdDesc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_DESC',
  CompanyProductsAverageCreatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_ASC',
  CompanyProductsAverageCreatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_DESC',
  CompanyProductsAverageUpdatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductsAverageUpdatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductsAverageIdAsc = 'COMPANY_PRODUCTS_AVERAGE_ID_ASC',
  CompanyProductsAverageIdDesc = 'COMPANY_PRODUCTS_AVERAGE_ID_DESC',
  CompanyProductsAverageEnabledAsc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_ASC',
  CompanyProductsAverageEnabledDesc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_DESC',
  CompanyProductsStddevSampleCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsStddevSampleCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsStddevSampleProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsStddevSampleProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsStddevSampleIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductsStddevSampleIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductsStddevSampleEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductsStddevSampleEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductsStddevPopulationCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyProductsStddevPopulationCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyProductsStddevPopulationProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsStddevPopulationProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductsStddevPopulationIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_ASC',
  CompanyProductsStddevPopulationIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_DESC',
  CompanyProductsStddevPopulationEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductsStddevPopulationEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductsVarianceSampleCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsVarianceSampleCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsVarianceSampleProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsVarianceSampleProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsVarianceSampleIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductsVarianceSampleIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductsVarianceSampleEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductsVarianceSampleEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductsVariancePopulationCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyProductsVariancePopulationCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyProductsVariancePopulationProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsVariancePopulationProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsVariancePopulationIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductsVariancePopulationIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductsVariancePopulationEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductsVariancePopulationEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyLocationsCountAsc = 'COMPANY_LOCATIONS_COUNT_ASC',
  CompanyLocationsCountDesc = 'COMPANY_LOCATIONS_COUNT_DESC',
  CompanyLocationsSumIdAsc = 'COMPANY_LOCATIONS_SUM_ID_ASC',
  CompanyLocationsSumIdDesc = 'COMPANY_LOCATIONS_SUM_ID_DESC',
  CompanyLocationsSumCompanyIdAsc = 'COMPANY_LOCATIONS_SUM_COMPANY_ID_ASC',
  CompanyLocationsSumCompanyIdDesc = 'COMPANY_LOCATIONS_SUM_COMPANY_ID_DESC',
  CompanyLocationsSumAddress1Asc = 'COMPANY_LOCATIONS_SUM_ADDRESS1_ASC',
  CompanyLocationsSumAddress1Desc = 'COMPANY_LOCATIONS_SUM_ADDRESS1_DESC',
  CompanyLocationsSumAddress2Asc = 'COMPANY_LOCATIONS_SUM_ADDRESS2_ASC',
  CompanyLocationsSumAddress2Desc = 'COMPANY_LOCATIONS_SUM_ADDRESS2_DESC',
  CompanyLocationsSumCityAsc = 'COMPANY_LOCATIONS_SUM_CITY_ASC',
  CompanyLocationsSumCityDesc = 'COMPANY_LOCATIONS_SUM_CITY_DESC',
  CompanyLocationsSumPostalCodeAsc = 'COMPANY_LOCATIONS_SUM_POSTAL_CODE_ASC',
  CompanyLocationsSumPostalCodeDesc = 'COMPANY_LOCATIONS_SUM_POSTAL_CODE_DESC',
  CompanyLocationsSumProvinceAsc = 'COMPANY_LOCATIONS_SUM_PROVINCE_ASC',
  CompanyLocationsSumProvinceDesc = 'COMPANY_LOCATIONS_SUM_PROVINCE_DESC',
  CompanyLocationsSumLatAsc = 'COMPANY_LOCATIONS_SUM_LAT_ASC',
  CompanyLocationsSumLatDesc = 'COMPANY_LOCATIONS_SUM_LAT_DESC',
  CompanyLocationsSumLongAsc = 'COMPANY_LOCATIONS_SUM_LONG_ASC',
  CompanyLocationsSumLongDesc = 'COMPANY_LOCATIONS_SUM_LONG_DESC',
  CompanyLocationsSumCreatedAtAsc = 'COMPANY_LOCATIONS_SUM_CREATED_AT_ASC',
  CompanyLocationsSumCreatedAtDesc = 'COMPANY_LOCATIONS_SUM_CREATED_AT_DESC',
  CompanyLocationsSumUpdatedAtAsc = 'COMPANY_LOCATIONS_SUM_UPDATED_AT_ASC',
  CompanyLocationsSumUpdatedAtDesc = 'COMPANY_LOCATIONS_SUM_UPDATED_AT_DESC',
  CompanyLocationsDistinctCountIdAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyLocationsDistinctCountIdDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyLocationsDistinctCountCompanyIdAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyLocationsDistinctCountCompanyIdDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyLocationsDistinctCountAddress1Asc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS1_ASC',
  CompanyLocationsDistinctCountAddress1Desc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS1_DESC',
  CompanyLocationsDistinctCountAddress2Asc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS2_ASC',
  CompanyLocationsDistinctCountAddress2Desc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_ADDRESS2_DESC',
  CompanyLocationsDistinctCountCityAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CITY_ASC',
  CompanyLocationsDistinctCountCityDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CITY_DESC',
  CompanyLocationsDistinctCountPostalCodeAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_POSTAL_CODE_ASC',
  CompanyLocationsDistinctCountPostalCodeDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_POSTAL_CODE_DESC',
  CompanyLocationsDistinctCountProvinceAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_PROVINCE_ASC',
  CompanyLocationsDistinctCountProvinceDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_PROVINCE_DESC',
  CompanyLocationsDistinctCountLatAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LAT_ASC',
  CompanyLocationsDistinctCountLatDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LAT_DESC',
  CompanyLocationsDistinctCountLongAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LONG_ASC',
  CompanyLocationsDistinctCountLongDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_LONG_DESC',
  CompanyLocationsDistinctCountCreatedAtAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyLocationsDistinctCountCreatedAtDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyLocationsDistinctCountUpdatedAtAsc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyLocationsDistinctCountUpdatedAtDesc = 'COMPANY_LOCATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyLocationsMinIdAsc = 'COMPANY_LOCATIONS_MIN_ID_ASC',
  CompanyLocationsMinIdDesc = 'COMPANY_LOCATIONS_MIN_ID_DESC',
  CompanyLocationsMinCompanyIdAsc = 'COMPANY_LOCATIONS_MIN_COMPANY_ID_ASC',
  CompanyLocationsMinCompanyIdDesc = 'COMPANY_LOCATIONS_MIN_COMPANY_ID_DESC',
  CompanyLocationsMinAddress1Asc = 'COMPANY_LOCATIONS_MIN_ADDRESS1_ASC',
  CompanyLocationsMinAddress1Desc = 'COMPANY_LOCATIONS_MIN_ADDRESS1_DESC',
  CompanyLocationsMinAddress2Asc = 'COMPANY_LOCATIONS_MIN_ADDRESS2_ASC',
  CompanyLocationsMinAddress2Desc = 'COMPANY_LOCATIONS_MIN_ADDRESS2_DESC',
  CompanyLocationsMinCityAsc = 'COMPANY_LOCATIONS_MIN_CITY_ASC',
  CompanyLocationsMinCityDesc = 'COMPANY_LOCATIONS_MIN_CITY_DESC',
  CompanyLocationsMinPostalCodeAsc = 'COMPANY_LOCATIONS_MIN_POSTAL_CODE_ASC',
  CompanyLocationsMinPostalCodeDesc = 'COMPANY_LOCATIONS_MIN_POSTAL_CODE_DESC',
  CompanyLocationsMinProvinceAsc = 'COMPANY_LOCATIONS_MIN_PROVINCE_ASC',
  CompanyLocationsMinProvinceDesc = 'COMPANY_LOCATIONS_MIN_PROVINCE_DESC',
  CompanyLocationsMinLatAsc = 'COMPANY_LOCATIONS_MIN_LAT_ASC',
  CompanyLocationsMinLatDesc = 'COMPANY_LOCATIONS_MIN_LAT_DESC',
  CompanyLocationsMinLongAsc = 'COMPANY_LOCATIONS_MIN_LONG_ASC',
  CompanyLocationsMinLongDesc = 'COMPANY_LOCATIONS_MIN_LONG_DESC',
  CompanyLocationsMinCreatedAtAsc = 'COMPANY_LOCATIONS_MIN_CREATED_AT_ASC',
  CompanyLocationsMinCreatedAtDesc = 'COMPANY_LOCATIONS_MIN_CREATED_AT_DESC',
  CompanyLocationsMinUpdatedAtAsc = 'COMPANY_LOCATIONS_MIN_UPDATED_AT_ASC',
  CompanyLocationsMinUpdatedAtDesc = 'COMPANY_LOCATIONS_MIN_UPDATED_AT_DESC',
  CompanyLocationsMaxIdAsc = 'COMPANY_LOCATIONS_MAX_ID_ASC',
  CompanyLocationsMaxIdDesc = 'COMPANY_LOCATIONS_MAX_ID_DESC',
  CompanyLocationsMaxCompanyIdAsc = 'COMPANY_LOCATIONS_MAX_COMPANY_ID_ASC',
  CompanyLocationsMaxCompanyIdDesc = 'COMPANY_LOCATIONS_MAX_COMPANY_ID_DESC',
  CompanyLocationsMaxAddress1Asc = 'COMPANY_LOCATIONS_MAX_ADDRESS1_ASC',
  CompanyLocationsMaxAddress1Desc = 'COMPANY_LOCATIONS_MAX_ADDRESS1_DESC',
  CompanyLocationsMaxAddress2Asc = 'COMPANY_LOCATIONS_MAX_ADDRESS2_ASC',
  CompanyLocationsMaxAddress2Desc = 'COMPANY_LOCATIONS_MAX_ADDRESS2_DESC',
  CompanyLocationsMaxCityAsc = 'COMPANY_LOCATIONS_MAX_CITY_ASC',
  CompanyLocationsMaxCityDesc = 'COMPANY_LOCATIONS_MAX_CITY_DESC',
  CompanyLocationsMaxPostalCodeAsc = 'COMPANY_LOCATIONS_MAX_POSTAL_CODE_ASC',
  CompanyLocationsMaxPostalCodeDesc = 'COMPANY_LOCATIONS_MAX_POSTAL_CODE_DESC',
  CompanyLocationsMaxProvinceAsc = 'COMPANY_LOCATIONS_MAX_PROVINCE_ASC',
  CompanyLocationsMaxProvinceDesc = 'COMPANY_LOCATIONS_MAX_PROVINCE_DESC',
  CompanyLocationsMaxLatAsc = 'COMPANY_LOCATIONS_MAX_LAT_ASC',
  CompanyLocationsMaxLatDesc = 'COMPANY_LOCATIONS_MAX_LAT_DESC',
  CompanyLocationsMaxLongAsc = 'COMPANY_LOCATIONS_MAX_LONG_ASC',
  CompanyLocationsMaxLongDesc = 'COMPANY_LOCATIONS_MAX_LONG_DESC',
  CompanyLocationsMaxCreatedAtAsc = 'COMPANY_LOCATIONS_MAX_CREATED_AT_ASC',
  CompanyLocationsMaxCreatedAtDesc = 'COMPANY_LOCATIONS_MAX_CREATED_AT_DESC',
  CompanyLocationsMaxUpdatedAtAsc = 'COMPANY_LOCATIONS_MAX_UPDATED_AT_ASC',
  CompanyLocationsMaxUpdatedAtDesc = 'COMPANY_LOCATIONS_MAX_UPDATED_AT_DESC',
  CompanyLocationsAverageIdAsc = 'COMPANY_LOCATIONS_AVERAGE_ID_ASC',
  CompanyLocationsAverageIdDesc = 'COMPANY_LOCATIONS_AVERAGE_ID_DESC',
  CompanyLocationsAverageCompanyIdAsc = 'COMPANY_LOCATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyLocationsAverageCompanyIdDesc = 'COMPANY_LOCATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyLocationsAverageAddress1Asc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS1_ASC',
  CompanyLocationsAverageAddress1Desc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS1_DESC',
  CompanyLocationsAverageAddress2Asc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS2_ASC',
  CompanyLocationsAverageAddress2Desc = 'COMPANY_LOCATIONS_AVERAGE_ADDRESS2_DESC',
  CompanyLocationsAverageCityAsc = 'COMPANY_LOCATIONS_AVERAGE_CITY_ASC',
  CompanyLocationsAverageCityDesc = 'COMPANY_LOCATIONS_AVERAGE_CITY_DESC',
  CompanyLocationsAveragePostalCodeAsc = 'COMPANY_LOCATIONS_AVERAGE_POSTAL_CODE_ASC',
  CompanyLocationsAveragePostalCodeDesc = 'COMPANY_LOCATIONS_AVERAGE_POSTAL_CODE_DESC',
  CompanyLocationsAverageProvinceAsc = 'COMPANY_LOCATIONS_AVERAGE_PROVINCE_ASC',
  CompanyLocationsAverageProvinceDesc = 'COMPANY_LOCATIONS_AVERAGE_PROVINCE_DESC',
  CompanyLocationsAverageLatAsc = 'COMPANY_LOCATIONS_AVERAGE_LAT_ASC',
  CompanyLocationsAverageLatDesc = 'COMPANY_LOCATIONS_AVERAGE_LAT_DESC',
  CompanyLocationsAverageLongAsc = 'COMPANY_LOCATIONS_AVERAGE_LONG_ASC',
  CompanyLocationsAverageLongDesc = 'COMPANY_LOCATIONS_AVERAGE_LONG_DESC',
  CompanyLocationsAverageCreatedAtAsc = 'COMPANY_LOCATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyLocationsAverageCreatedAtDesc = 'COMPANY_LOCATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyLocationsAverageUpdatedAtAsc = 'COMPANY_LOCATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyLocationsAverageUpdatedAtDesc = 'COMPANY_LOCATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyLocationsStddevSampleIdAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyLocationsStddevSampleIdDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyLocationsStddevSampleCompanyIdAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyLocationsStddevSampleCompanyIdDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyLocationsStddevSampleAddress1Asc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS1_ASC',
  CompanyLocationsStddevSampleAddress1Desc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS1_DESC',
  CompanyLocationsStddevSampleAddress2Asc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS2_ASC',
  CompanyLocationsStddevSampleAddress2Desc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_ADDRESS2_DESC',
  CompanyLocationsStddevSampleCityAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CITY_ASC',
  CompanyLocationsStddevSampleCityDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CITY_DESC',
  CompanyLocationsStddevSamplePostalCodeAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  CompanyLocationsStddevSamplePostalCodeDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  CompanyLocationsStddevSampleProvinceAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_PROVINCE_ASC',
  CompanyLocationsStddevSampleProvinceDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_PROVINCE_DESC',
  CompanyLocationsStddevSampleLatAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LAT_ASC',
  CompanyLocationsStddevSampleLatDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LAT_DESC',
  CompanyLocationsStddevSampleLongAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LONG_ASC',
  CompanyLocationsStddevSampleLongDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_LONG_DESC',
  CompanyLocationsStddevSampleCreatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyLocationsStddevSampleCreatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyLocationsStddevSampleUpdatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyLocationsStddevSampleUpdatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyLocationsStddevPopulationIdAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyLocationsStddevPopulationIdDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyLocationsStddevPopulationCompanyIdAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyLocationsStddevPopulationCompanyIdDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyLocationsStddevPopulationAddress1Asc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS1_ASC',
  CompanyLocationsStddevPopulationAddress1Desc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS1_DESC',
  CompanyLocationsStddevPopulationAddress2Asc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS2_ASC',
  CompanyLocationsStddevPopulationAddress2Desc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_ADDRESS2_DESC',
  CompanyLocationsStddevPopulationCityAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CITY_ASC',
  CompanyLocationsStddevPopulationCityDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CITY_DESC',
  CompanyLocationsStddevPopulationPostalCodeAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_POSTAL_CODE_ASC',
  CompanyLocationsStddevPopulationPostalCodeDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_POSTAL_CODE_DESC',
  CompanyLocationsStddevPopulationProvinceAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_PROVINCE_ASC',
  CompanyLocationsStddevPopulationProvinceDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_PROVINCE_DESC',
  CompanyLocationsStddevPopulationLatAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LAT_ASC',
  CompanyLocationsStddevPopulationLatDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LAT_DESC',
  CompanyLocationsStddevPopulationLongAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LONG_ASC',
  CompanyLocationsStddevPopulationLongDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_LONG_DESC',
  CompanyLocationsStddevPopulationCreatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyLocationsStddevPopulationCreatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyLocationsStddevPopulationUpdatedAtAsc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyLocationsStddevPopulationUpdatedAtDesc = 'COMPANY_LOCATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyLocationsVarianceSampleIdAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyLocationsVarianceSampleIdDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyLocationsVarianceSampleCompanyIdAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyLocationsVarianceSampleCompanyIdDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyLocationsVarianceSampleAddress1Asc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS1_ASC',
  CompanyLocationsVarianceSampleAddress1Desc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS1_DESC',
  CompanyLocationsVarianceSampleAddress2Asc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS2_ASC',
  CompanyLocationsVarianceSampleAddress2Desc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_ADDRESS2_DESC',
  CompanyLocationsVarianceSampleCityAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CITY_ASC',
  CompanyLocationsVarianceSampleCityDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CITY_DESC',
  CompanyLocationsVarianceSamplePostalCodeAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  CompanyLocationsVarianceSamplePostalCodeDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  CompanyLocationsVarianceSampleProvinceAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_PROVINCE_ASC',
  CompanyLocationsVarianceSampleProvinceDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_PROVINCE_DESC',
  CompanyLocationsVarianceSampleLatAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LAT_ASC',
  CompanyLocationsVarianceSampleLatDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LAT_DESC',
  CompanyLocationsVarianceSampleLongAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LONG_ASC',
  CompanyLocationsVarianceSampleLongDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_LONG_DESC',
  CompanyLocationsVarianceSampleCreatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyLocationsVarianceSampleCreatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyLocationsVarianceSampleUpdatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyLocationsVarianceSampleUpdatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyLocationsVariancePopulationIdAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyLocationsVariancePopulationIdDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyLocationsVariancePopulationCompanyIdAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyLocationsVariancePopulationCompanyIdDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyLocationsVariancePopulationAddress1Asc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS1_ASC',
  CompanyLocationsVariancePopulationAddress1Desc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS1_DESC',
  CompanyLocationsVariancePopulationAddress2Asc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS2_ASC',
  CompanyLocationsVariancePopulationAddress2Desc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_ADDRESS2_DESC',
  CompanyLocationsVariancePopulationCityAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CITY_ASC',
  CompanyLocationsVariancePopulationCityDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CITY_DESC',
  CompanyLocationsVariancePopulationPostalCodeAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  CompanyLocationsVariancePopulationPostalCodeDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  CompanyLocationsVariancePopulationProvinceAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_PROVINCE_ASC',
  CompanyLocationsVariancePopulationProvinceDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_PROVINCE_DESC',
  CompanyLocationsVariancePopulationLatAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LAT_ASC',
  CompanyLocationsVariancePopulationLatDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LAT_DESC',
  CompanyLocationsVariancePopulationLongAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LONG_ASC',
  CompanyLocationsVariancePopulationLongDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_LONG_DESC',
  CompanyLocationsVariancePopulationCreatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyLocationsVariancePopulationCreatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyLocationsVariancePopulationUpdatedAtAsc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyLocationsVariancePopulationUpdatedAtDesc = 'COMPANY_LOCATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyAnalyticsCountAsc = 'COMPANY_ANALYTICS_COUNT_ASC',
  CompanyAnalyticsCountDesc = 'COMPANY_ANALYTICS_COUNT_DESC',
  CompanyAnalyticsSumIdAsc = 'COMPANY_ANALYTICS_SUM_ID_ASC',
  CompanyAnalyticsSumIdDesc = 'COMPANY_ANALYTICS_SUM_ID_DESC',
  CompanyAnalyticsSumCompanyIdAsc = 'COMPANY_ANALYTICS_SUM_COMPANY_ID_ASC',
  CompanyAnalyticsSumCompanyIdDesc = 'COMPANY_ANALYTICS_SUM_COMPANY_ID_DESC',
  CompanyAnalyticsSumTypeAsc = 'COMPANY_ANALYTICS_SUM_TYPE_ASC',
  CompanyAnalyticsSumTypeDesc = 'COMPANY_ANALYTICS_SUM_TYPE_DESC',
  CompanyAnalyticsSumAnalyticsIdAsc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_ID_ASC',
  CompanyAnalyticsSumAnalyticsIdDesc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_ID_DESC',
  CompanyAnalyticsSumAnalyticsSecretAsc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsSumAnalyticsSecretDesc = 'COMPANY_ANALYTICS_SUM_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsSumDomainAsc = 'COMPANY_ANALYTICS_SUM_DOMAIN_ASC',
  CompanyAnalyticsSumDomainDesc = 'COMPANY_ANALYTICS_SUM_DOMAIN_DESC',
  CompanyAnalyticsSumCreatedAtAsc = 'COMPANY_ANALYTICS_SUM_CREATED_AT_ASC',
  CompanyAnalyticsSumCreatedAtDesc = 'COMPANY_ANALYTICS_SUM_CREATED_AT_DESC',
  CompanyAnalyticsSumUpdatedAtAsc = 'COMPANY_ANALYTICS_SUM_UPDATED_AT_ASC',
  CompanyAnalyticsSumUpdatedAtDesc = 'COMPANY_ANALYTICS_SUM_UPDATED_AT_DESC',
  CompanyAnalyticsDistinctCountIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ID_ASC',
  CompanyAnalyticsDistinctCountIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ID_DESC',
  CompanyAnalyticsDistinctCountCompanyIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyAnalyticsDistinctCountCompanyIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyAnalyticsDistinctCountTypeAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_TYPE_ASC',
  CompanyAnalyticsDistinctCountTypeDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_TYPE_DESC',
  CompanyAnalyticsDistinctCountAnalyticsIdAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_ID_ASC',
  CompanyAnalyticsDistinctCountAnalyticsIdDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_ID_DESC',
  CompanyAnalyticsDistinctCountAnalyticsSecretAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsDistinctCountAnalyticsSecretDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsDistinctCountDomainAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_DOMAIN_ASC',
  CompanyAnalyticsDistinctCountDomainDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_DOMAIN_DESC',
  CompanyAnalyticsDistinctCountCreatedAtAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyAnalyticsDistinctCountCreatedAtDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyAnalyticsDistinctCountUpdatedAtAsc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyAnalyticsDistinctCountUpdatedAtDesc = 'COMPANY_ANALYTICS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyAnalyticsMinIdAsc = 'COMPANY_ANALYTICS_MIN_ID_ASC',
  CompanyAnalyticsMinIdDesc = 'COMPANY_ANALYTICS_MIN_ID_DESC',
  CompanyAnalyticsMinCompanyIdAsc = 'COMPANY_ANALYTICS_MIN_COMPANY_ID_ASC',
  CompanyAnalyticsMinCompanyIdDesc = 'COMPANY_ANALYTICS_MIN_COMPANY_ID_DESC',
  CompanyAnalyticsMinTypeAsc = 'COMPANY_ANALYTICS_MIN_TYPE_ASC',
  CompanyAnalyticsMinTypeDesc = 'COMPANY_ANALYTICS_MIN_TYPE_DESC',
  CompanyAnalyticsMinAnalyticsIdAsc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_ID_ASC',
  CompanyAnalyticsMinAnalyticsIdDesc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_ID_DESC',
  CompanyAnalyticsMinAnalyticsSecretAsc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsMinAnalyticsSecretDesc = 'COMPANY_ANALYTICS_MIN_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsMinDomainAsc = 'COMPANY_ANALYTICS_MIN_DOMAIN_ASC',
  CompanyAnalyticsMinDomainDesc = 'COMPANY_ANALYTICS_MIN_DOMAIN_DESC',
  CompanyAnalyticsMinCreatedAtAsc = 'COMPANY_ANALYTICS_MIN_CREATED_AT_ASC',
  CompanyAnalyticsMinCreatedAtDesc = 'COMPANY_ANALYTICS_MIN_CREATED_AT_DESC',
  CompanyAnalyticsMinUpdatedAtAsc = 'COMPANY_ANALYTICS_MIN_UPDATED_AT_ASC',
  CompanyAnalyticsMinUpdatedAtDesc = 'COMPANY_ANALYTICS_MIN_UPDATED_AT_DESC',
  CompanyAnalyticsMaxIdAsc = 'COMPANY_ANALYTICS_MAX_ID_ASC',
  CompanyAnalyticsMaxIdDesc = 'COMPANY_ANALYTICS_MAX_ID_DESC',
  CompanyAnalyticsMaxCompanyIdAsc = 'COMPANY_ANALYTICS_MAX_COMPANY_ID_ASC',
  CompanyAnalyticsMaxCompanyIdDesc = 'COMPANY_ANALYTICS_MAX_COMPANY_ID_DESC',
  CompanyAnalyticsMaxTypeAsc = 'COMPANY_ANALYTICS_MAX_TYPE_ASC',
  CompanyAnalyticsMaxTypeDesc = 'COMPANY_ANALYTICS_MAX_TYPE_DESC',
  CompanyAnalyticsMaxAnalyticsIdAsc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_ID_ASC',
  CompanyAnalyticsMaxAnalyticsIdDesc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_ID_DESC',
  CompanyAnalyticsMaxAnalyticsSecretAsc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsMaxAnalyticsSecretDesc = 'COMPANY_ANALYTICS_MAX_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsMaxDomainAsc = 'COMPANY_ANALYTICS_MAX_DOMAIN_ASC',
  CompanyAnalyticsMaxDomainDesc = 'COMPANY_ANALYTICS_MAX_DOMAIN_DESC',
  CompanyAnalyticsMaxCreatedAtAsc = 'COMPANY_ANALYTICS_MAX_CREATED_AT_ASC',
  CompanyAnalyticsMaxCreatedAtDesc = 'COMPANY_ANALYTICS_MAX_CREATED_AT_DESC',
  CompanyAnalyticsMaxUpdatedAtAsc = 'COMPANY_ANALYTICS_MAX_UPDATED_AT_ASC',
  CompanyAnalyticsMaxUpdatedAtDesc = 'COMPANY_ANALYTICS_MAX_UPDATED_AT_DESC',
  CompanyAnalyticsAverageIdAsc = 'COMPANY_ANALYTICS_AVERAGE_ID_ASC',
  CompanyAnalyticsAverageIdDesc = 'COMPANY_ANALYTICS_AVERAGE_ID_DESC',
  CompanyAnalyticsAverageCompanyIdAsc = 'COMPANY_ANALYTICS_AVERAGE_COMPANY_ID_ASC',
  CompanyAnalyticsAverageCompanyIdDesc = 'COMPANY_ANALYTICS_AVERAGE_COMPANY_ID_DESC',
  CompanyAnalyticsAverageTypeAsc = 'COMPANY_ANALYTICS_AVERAGE_TYPE_ASC',
  CompanyAnalyticsAverageTypeDesc = 'COMPANY_ANALYTICS_AVERAGE_TYPE_DESC',
  CompanyAnalyticsAverageAnalyticsIdAsc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_ID_ASC',
  CompanyAnalyticsAverageAnalyticsIdDesc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_ID_DESC',
  CompanyAnalyticsAverageAnalyticsSecretAsc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsAverageAnalyticsSecretDesc = 'COMPANY_ANALYTICS_AVERAGE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsAverageDomainAsc = 'COMPANY_ANALYTICS_AVERAGE_DOMAIN_ASC',
  CompanyAnalyticsAverageDomainDesc = 'COMPANY_ANALYTICS_AVERAGE_DOMAIN_DESC',
  CompanyAnalyticsAverageCreatedAtAsc = 'COMPANY_ANALYTICS_AVERAGE_CREATED_AT_ASC',
  CompanyAnalyticsAverageCreatedAtDesc = 'COMPANY_ANALYTICS_AVERAGE_CREATED_AT_DESC',
  CompanyAnalyticsAverageUpdatedAtAsc = 'COMPANY_ANALYTICS_AVERAGE_UPDATED_AT_ASC',
  CompanyAnalyticsAverageUpdatedAtDesc = 'COMPANY_ANALYTICS_AVERAGE_UPDATED_AT_DESC',
  CompanyAnalyticsStddevSampleIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ID_ASC',
  CompanyAnalyticsStddevSampleIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ID_DESC',
  CompanyAnalyticsStddevSampleCompanyIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyAnalyticsStddevSampleCompanyIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyAnalyticsStddevSampleTypeAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_TYPE_ASC',
  CompanyAnalyticsStddevSampleTypeDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_TYPE_DESC',
  CompanyAnalyticsStddevSampleAnalyticsIdAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_ID_ASC',
  CompanyAnalyticsStddevSampleAnalyticsIdDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_ID_DESC',
  CompanyAnalyticsStddevSampleAnalyticsSecretAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsStddevSampleAnalyticsSecretDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsStddevSampleDomainAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_DOMAIN_ASC',
  CompanyAnalyticsStddevSampleDomainDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_DOMAIN_DESC',
  CompanyAnalyticsStddevSampleCreatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyAnalyticsStddevSampleCreatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyAnalyticsStddevSampleUpdatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyAnalyticsStddevSampleUpdatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyAnalyticsStddevPopulationIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ID_ASC',
  CompanyAnalyticsStddevPopulationIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ID_DESC',
  CompanyAnalyticsStddevPopulationCompanyIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyAnalyticsStddevPopulationCompanyIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyAnalyticsStddevPopulationTypeAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_TYPE_ASC',
  CompanyAnalyticsStddevPopulationTypeDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_TYPE_DESC',
  CompanyAnalyticsStddevPopulationAnalyticsIdAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_ID_ASC',
  CompanyAnalyticsStddevPopulationAnalyticsIdDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_ID_DESC',
  CompanyAnalyticsStddevPopulationAnalyticsSecretAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsStddevPopulationAnalyticsSecretDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsStddevPopulationDomainAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_DOMAIN_ASC',
  CompanyAnalyticsStddevPopulationDomainDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_DOMAIN_DESC',
  CompanyAnalyticsStddevPopulationCreatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyAnalyticsStddevPopulationCreatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyAnalyticsStddevPopulationUpdatedAtAsc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyAnalyticsStddevPopulationUpdatedAtDesc = 'COMPANY_ANALYTICS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyAnalyticsVarianceSampleIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ID_ASC',
  CompanyAnalyticsVarianceSampleIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ID_DESC',
  CompanyAnalyticsVarianceSampleCompanyIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyAnalyticsVarianceSampleCompanyIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyAnalyticsVarianceSampleTypeAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_TYPE_ASC',
  CompanyAnalyticsVarianceSampleTypeDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_TYPE_DESC',
  CompanyAnalyticsVarianceSampleAnalyticsIdAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_ID_ASC',
  CompanyAnalyticsVarianceSampleAnalyticsIdDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_ID_DESC',
  CompanyAnalyticsVarianceSampleAnalyticsSecretAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsVarianceSampleAnalyticsSecretDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsVarianceSampleDomainAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_DOMAIN_ASC',
  CompanyAnalyticsVarianceSampleDomainDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_DOMAIN_DESC',
  CompanyAnalyticsVarianceSampleCreatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyAnalyticsVarianceSampleCreatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyAnalyticsVarianceSampleUpdatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyAnalyticsVarianceSampleUpdatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyAnalyticsVariancePopulationIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ID_ASC',
  CompanyAnalyticsVariancePopulationIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ID_DESC',
  CompanyAnalyticsVariancePopulationCompanyIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyAnalyticsVariancePopulationCompanyIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyAnalyticsVariancePopulationTypeAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_TYPE_ASC',
  CompanyAnalyticsVariancePopulationTypeDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_TYPE_DESC',
  CompanyAnalyticsVariancePopulationAnalyticsIdAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_ID_ASC',
  CompanyAnalyticsVariancePopulationAnalyticsIdDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_ID_DESC',
  CompanyAnalyticsVariancePopulationAnalyticsSecretAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_SECRET_ASC',
  CompanyAnalyticsVariancePopulationAnalyticsSecretDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_ANALYTICS_SECRET_DESC',
  CompanyAnalyticsVariancePopulationDomainAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_DOMAIN_ASC',
  CompanyAnalyticsVariancePopulationDomainDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_DOMAIN_DESC',
  CompanyAnalyticsVariancePopulationCreatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyAnalyticsVariancePopulationCreatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyAnalyticsVariancePopulationUpdatedAtAsc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyAnalyticsVariancePopulationUpdatedAtDesc = 'COMPANY_ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsCountAsc = 'COMPANY_DOMAINS_COUNT_ASC',
  CompanyDomainsCountDesc = 'COMPANY_DOMAINS_COUNT_DESC',
  CompanyDomainsSumIdAsc = 'COMPANY_DOMAINS_SUM_ID_ASC',
  CompanyDomainsSumIdDesc = 'COMPANY_DOMAINS_SUM_ID_DESC',
  CompanyDomainsSumCompanyIdAsc = 'COMPANY_DOMAINS_SUM_COMPANY_ID_ASC',
  CompanyDomainsSumCompanyIdDesc = 'COMPANY_DOMAINS_SUM_COMPANY_ID_DESC',
  CompanyDomainsSumDomainAsc = 'COMPANY_DOMAINS_SUM_DOMAIN_ASC',
  CompanyDomainsSumDomainDesc = 'COMPANY_DOMAINS_SUM_DOMAIN_DESC',
  CompanyDomainsSumCreatedAtAsc = 'COMPANY_DOMAINS_SUM_CREATED_AT_ASC',
  CompanyDomainsSumCreatedAtDesc = 'COMPANY_DOMAINS_SUM_CREATED_AT_DESC',
  CompanyDomainsSumUpdatedAtAsc = 'COMPANY_DOMAINS_SUM_UPDATED_AT_ASC',
  CompanyDomainsSumUpdatedAtDesc = 'COMPANY_DOMAINS_SUM_UPDATED_AT_DESC',
  CompanyDomainsDistinctCountIdAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsDistinctCountIdDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsDistinctCountDomainAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_DOMAIN_ASC',
  CompanyDomainsDistinctCountDomainDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_DOMAIN_DESC',
  CompanyDomainsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsMinIdAsc = 'COMPANY_DOMAINS_MIN_ID_ASC',
  CompanyDomainsMinIdDesc = 'COMPANY_DOMAINS_MIN_ID_DESC',
  CompanyDomainsMinCompanyIdAsc = 'COMPANY_DOMAINS_MIN_COMPANY_ID_ASC',
  CompanyDomainsMinCompanyIdDesc = 'COMPANY_DOMAINS_MIN_COMPANY_ID_DESC',
  CompanyDomainsMinDomainAsc = 'COMPANY_DOMAINS_MIN_DOMAIN_ASC',
  CompanyDomainsMinDomainDesc = 'COMPANY_DOMAINS_MIN_DOMAIN_DESC',
  CompanyDomainsMinCreatedAtAsc = 'COMPANY_DOMAINS_MIN_CREATED_AT_ASC',
  CompanyDomainsMinCreatedAtDesc = 'COMPANY_DOMAINS_MIN_CREATED_AT_DESC',
  CompanyDomainsMinUpdatedAtAsc = 'COMPANY_DOMAINS_MIN_UPDATED_AT_ASC',
  CompanyDomainsMinUpdatedAtDesc = 'COMPANY_DOMAINS_MIN_UPDATED_AT_DESC',
  CompanyDomainsMaxIdAsc = 'COMPANY_DOMAINS_MAX_ID_ASC',
  CompanyDomainsMaxIdDesc = 'COMPANY_DOMAINS_MAX_ID_DESC',
  CompanyDomainsMaxCompanyIdAsc = 'COMPANY_DOMAINS_MAX_COMPANY_ID_ASC',
  CompanyDomainsMaxCompanyIdDesc = 'COMPANY_DOMAINS_MAX_COMPANY_ID_DESC',
  CompanyDomainsMaxDomainAsc = 'COMPANY_DOMAINS_MAX_DOMAIN_ASC',
  CompanyDomainsMaxDomainDesc = 'COMPANY_DOMAINS_MAX_DOMAIN_DESC',
  CompanyDomainsMaxCreatedAtAsc = 'COMPANY_DOMAINS_MAX_CREATED_AT_ASC',
  CompanyDomainsMaxCreatedAtDesc = 'COMPANY_DOMAINS_MAX_CREATED_AT_DESC',
  CompanyDomainsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_MAX_UPDATED_AT_ASC',
  CompanyDomainsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_MAX_UPDATED_AT_DESC',
  CompanyDomainsAverageIdAsc = 'COMPANY_DOMAINS_AVERAGE_ID_ASC',
  CompanyDomainsAverageIdDesc = 'COMPANY_DOMAINS_AVERAGE_ID_DESC',
  CompanyDomainsAverageCompanyIdAsc = 'COMPANY_DOMAINS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsAverageCompanyIdDesc = 'COMPANY_DOMAINS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsAverageDomainAsc = 'COMPANY_DOMAINS_AVERAGE_DOMAIN_ASC',
  CompanyDomainsAverageDomainDesc = 'COMPANY_DOMAINS_AVERAGE_DOMAIN_DESC',
  CompanyDomainsAverageCreatedAtAsc = 'COMPANY_DOMAINS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsAverageCreatedAtDesc = 'COMPANY_DOMAINS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsStddevSampleIdAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsStddevSampleIdDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsStddevSampleDomainAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_DOMAIN_ASC',
  CompanyDomainsStddevSampleDomainDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_DOMAIN_DESC',
  CompanyDomainsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsStddevPopulationIdAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsStddevPopulationIdDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsStddevPopulationDomainAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_DOMAIN_ASC',
  CompanyDomainsStddevPopulationDomainDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_DOMAIN_DESC',
  CompanyDomainsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsVarianceSampleIdAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsVarianceSampleIdDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsVarianceSampleDomainAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_DOMAIN_ASC',
  CompanyDomainsVarianceSampleDomainDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_DOMAIN_DESC',
  CompanyDomainsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsVariancePopulationIdAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsVariancePopulationIdDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsVariancePopulationDomainAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_DOMAIN_ASC',
  CompanyDomainsVariancePopulationDomainDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_DOMAIN_DESC',
  CompanyDomainsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsCountAsc = 'COMPANY_DOMAINS_APPROVEDS_COUNT_ASC',
  CompanyDomainsApprovedsCountDesc = 'COMPANY_DOMAINS_APPROVEDS_COUNT_DESC',
  CompanyDomainsApprovedsSumCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_COMPANY_ID_ASC',
  CompanyDomainsApprovedsSumCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_COMPANY_ID_DESC',
  CompanyDomainsApprovedsSumDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsSumDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsSumCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_CREATED_AT_ASC',
  CompanyDomainsApprovedsSumCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_CREATED_AT_DESC',
  CompanyDomainsApprovedsSumUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_SUM_UPDATED_AT_ASC',
  CompanyDomainsApprovedsSumUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_SUM_UPDATED_AT_DESC',
  CompanyDomainsApprovedsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsApprovedsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsApprovedsDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsApprovedsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsApprovedsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsApprovedsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsApprovedsMinCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_COMPANY_ID_ASC',
  CompanyDomainsApprovedsMinCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_COMPANY_ID_DESC',
  CompanyDomainsApprovedsMinDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsMinDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsMinCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_CREATED_AT_ASC',
  CompanyDomainsApprovedsMinCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_CREATED_AT_DESC',
  CompanyDomainsApprovedsMinUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MIN_UPDATED_AT_ASC',
  CompanyDomainsApprovedsMinUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MIN_UPDATED_AT_DESC',
  CompanyDomainsApprovedsMaxCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_COMPANY_ID_ASC',
  CompanyDomainsApprovedsMaxCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_COMPANY_ID_DESC',
  CompanyDomainsApprovedsMaxDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsMaxDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsMaxCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_CREATED_AT_ASC',
  CompanyDomainsApprovedsMaxCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_CREATED_AT_DESC',
  CompanyDomainsApprovedsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_MAX_UPDATED_AT_ASC',
  CompanyDomainsApprovedsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_MAX_UPDATED_AT_DESC',
  CompanyDomainsApprovedsAverageCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsAverageCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsAverageDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsAverageDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsAverageCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsApprovedsAverageCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsApprovedsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsCountAsc = 'COMPANY_DOMAINS_PATHS_COUNT_ASC',
  CompanyDomainsPathsCountDesc = 'COMPANY_DOMAINS_PATHS_COUNT_DESC',
  CompanyDomainsPathsSumIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_ID_ASC',
  CompanyDomainsPathsSumIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_ID_DESC',
  CompanyDomainsPathsSumCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_COMPANY_ID_ASC',
  CompanyDomainsPathsSumCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_COMPANY_ID_DESC',
  CompanyDomainsPathsSumDomainIdAsc = 'COMPANY_DOMAINS_PATHS_SUM_DOMAIN_ID_ASC',
  CompanyDomainsPathsSumDomainIdDesc = 'COMPANY_DOMAINS_PATHS_SUM_DOMAIN_ID_DESC',
  CompanyDomainsPathsSumPathAsc = 'COMPANY_DOMAINS_PATHS_SUM_PATH_ASC',
  CompanyDomainsPathsSumPathDesc = 'COMPANY_DOMAINS_PATHS_SUM_PATH_DESC',
  CompanyDomainsPathsSumCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_SUM_CREATED_AT_ASC',
  CompanyDomainsPathsSumCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_SUM_CREATED_AT_DESC',
  CompanyDomainsPathsSumUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_SUM_UPDATED_AT_ASC',
  CompanyDomainsPathsSumUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_SUM_UPDATED_AT_DESC',
  CompanyDomainsPathsDistinctCountIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsPathsDistinctCountIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsPathsDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsPathsDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsPathsDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsPathsDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsPathsDistinctCountPathAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_PATH_ASC',
  CompanyDomainsPathsDistinctCountPathDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_PATH_DESC',
  CompanyDomainsPathsDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsPathsDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsPathsDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsPathsDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsPathsMinIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_ID_ASC',
  CompanyDomainsPathsMinIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_ID_DESC',
  CompanyDomainsPathsMinCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_COMPANY_ID_ASC',
  CompanyDomainsPathsMinCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_COMPANY_ID_DESC',
  CompanyDomainsPathsMinDomainIdAsc = 'COMPANY_DOMAINS_PATHS_MIN_DOMAIN_ID_ASC',
  CompanyDomainsPathsMinDomainIdDesc = 'COMPANY_DOMAINS_PATHS_MIN_DOMAIN_ID_DESC',
  CompanyDomainsPathsMinPathAsc = 'COMPANY_DOMAINS_PATHS_MIN_PATH_ASC',
  CompanyDomainsPathsMinPathDesc = 'COMPANY_DOMAINS_PATHS_MIN_PATH_DESC',
  CompanyDomainsPathsMinCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_MIN_CREATED_AT_ASC',
  CompanyDomainsPathsMinCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_MIN_CREATED_AT_DESC',
  CompanyDomainsPathsMinUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_MIN_UPDATED_AT_ASC',
  CompanyDomainsPathsMinUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_MIN_UPDATED_AT_DESC',
  CompanyDomainsPathsMaxIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_ID_ASC',
  CompanyDomainsPathsMaxIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_ID_DESC',
  CompanyDomainsPathsMaxCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_COMPANY_ID_ASC',
  CompanyDomainsPathsMaxCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_COMPANY_ID_DESC',
  CompanyDomainsPathsMaxDomainIdAsc = 'COMPANY_DOMAINS_PATHS_MAX_DOMAIN_ID_ASC',
  CompanyDomainsPathsMaxDomainIdDesc = 'COMPANY_DOMAINS_PATHS_MAX_DOMAIN_ID_DESC',
  CompanyDomainsPathsMaxPathAsc = 'COMPANY_DOMAINS_PATHS_MAX_PATH_ASC',
  CompanyDomainsPathsMaxPathDesc = 'COMPANY_DOMAINS_PATHS_MAX_PATH_DESC',
  CompanyDomainsPathsMaxCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_MAX_CREATED_AT_ASC',
  CompanyDomainsPathsMaxCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_MAX_CREATED_AT_DESC',
  CompanyDomainsPathsMaxUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_MAX_UPDATED_AT_ASC',
  CompanyDomainsPathsMaxUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_MAX_UPDATED_AT_DESC',
  CompanyDomainsPathsAverageIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_ID_ASC',
  CompanyDomainsPathsAverageIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_ID_DESC',
  CompanyDomainsPathsAverageCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsPathsAverageCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsPathsAverageDomainIdAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsPathsAverageDomainIdDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsPathsAveragePathAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_PATH_ASC',
  CompanyDomainsPathsAveragePathDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_PATH_DESC',
  CompanyDomainsPathsAverageCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsPathsAverageCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsPathsAverageUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsPathsAverageUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsPathsStddevSampleIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsPathsStddevSampleIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsPathsStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsStddevSamplePathAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_PATH_ASC',
  CompanyDomainsPathsStddevSamplePathDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_PATH_DESC',
  CompanyDomainsPathsStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsStddevPopulationIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsPathsStddevPopulationIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsPathsStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsStddevPopulationPathAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_PATH_ASC',
  CompanyDomainsPathsStddevPopulationPathDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_PATH_DESC',
  CompanyDomainsPathsStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsVarianceSampleIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsPathsVarianceSampleIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsPathsVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsVarianceSamplePathAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_PATH_ASC',
  CompanyDomainsPathsVarianceSamplePathDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_PATH_DESC',
  CompanyDomainsPathsVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsVariancePopulationIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsPathsVariancePopulationIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsPathsVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsVariancePopulationPathAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_PATH_ASC',
  CompanyDomainsPathsVariancePopulationPathDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_PATH_DESC',
  CompanyDomainsPathsVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ReviewsCountAsc = 'REVIEWS_COUNT_ASC',
  ReviewsCountDesc = 'REVIEWS_COUNT_DESC',
  ReviewsSumCompanyIdAsc = 'REVIEWS_SUM_COMPANY_ID_ASC',
  ReviewsSumCompanyIdDesc = 'REVIEWS_SUM_COMPANY_ID_DESC',
  ReviewsSumProviderAsc = 'REVIEWS_SUM_PROVIDER_ASC',
  ReviewsSumProviderDesc = 'REVIEWS_SUM_PROVIDER_DESC',
  ReviewsSumAuthorNameAsc = 'REVIEWS_SUM_AUTHOR_NAME_ASC',
  ReviewsSumAuthorNameDesc = 'REVIEWS_SUM_AUTHOR_NAME_DESC',
  ReviewsSumAuthorPhotoUrlAsc = 'REVIEWS_SUM_AUTHOR_PHOTO_URL_ASC',
  ReviewsSumAuthorPhotoUrlDesc = 'REVIEWS_SUM_AUTHOR_PHOTO_URL_DESC',
  ReviewsSumAuthorUrlAsc = 'REVIEWS_SUM_AUTHOR_URL_ASC',
  ReviewsSumAuthorUrlDesc = 'REVIEWS_SUM_AUTHOR_URL_DESC',
  ReviewsSumLanguageAsc = 'REVIEWS_SUM_LANGUAGE_ASC',
  ReviewsSumLanguageDesc = 'REVIEWS_SUM_LANGUAGE_DESC',
  ReviewsSumRatingAsc = 'REVIEWS_SUM_RATING_ASC',
  ReviewsSumRatingDesc = 'REVIEWS_SUM_RATING_DESC',
  ReviewsSumTextAsc = 'REVIEWS_SUM_TEXT_ASC',
  ReviewsSumTextDesc = 'REVIEWS_SUM_TEXT_DESC',
  ReviewsSumTimestampAsc = 'REVIEWS_SUM_TIMESTAMP_ASC',
  ReviewsSumTimestampDesc = 'REVIEWS_SUM_TIMESTAMP_DESC',
  ReviewsSumCreatedAtAsc = 'REVIEWS_SUM_CREATED_AT_ASC',
  ReviewsSumCreatedAtDesc = 'REVIEWS_SUM_CREATED_AT_DESC',
  ReviewsSumUpdatedAtAsc = 'REVIEWS_SUM_UPDATED_AT_ASC',
  ReviewsSumUpdatedAtDesc = 'REVIEWS_SUM_UPDATED_AT_DESC',
  ReviewsDistinctCountCompanyIdAsc = 'REVIEWS_DISTINCT_COUNT_COMPANY_ID_ASC',
  ReviewsDistinctCountCompanyIdDesc = 'REVIEWS_DISTINCT_COUNT_COMPANY_ID_DESC',
  ReviewsDistinctCountProviderAsc = 'REVIEWS_DISTINCT_COUNT_PROVIDER_ASC',
  ReviewsDistinctCountProviderDesc = 'REVIEWS_DISTINCT_COUNT_PROVIDER_DESC',
  ReviewsDistinctCountAuthorNameAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_NAME_ASC',
  ReviewsDistinctCountAuthorNameDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_NAME_DESC',
  ReviewsDistinctCountAuthorPhotoUrlAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_PHOTO_URL_ASC',
  ReviewsDistinctCountAuthorPhotoUrlDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_PHOTO_URL_DESC',
  ReviewsDistinctCountAuthorUrlAsc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_URL_ASC',
  ReviewsDistinctCountAuthorUrlDesc = 'REVIEWS_DISTINCT_COUNT_AUTHOR_URL_DESC',
  ReviewsDistinctCountLanguageAsc = 'REVIEWS_DISTINCT_COUNT_LANGUAGE_ASC',
  ReviewsDistinctCountLanguageDesc = 'REVIEWS_DISTINCT_COUNT_LANGUAGE_DESC',
  ReviewsDistinctCountRatingAsc = 'REVIEWS_DISTINCT_COUNT_RATING_ASC',
  ReviewsDistinctCountRatingDesc = 'REVIEWS_DISTINCT_COUNT_RATING_DESC',
  ReviewsDistinctCountTextAsc = 'REVIEWS_DISTINCT_COUNT_TEXT_ASC',
  ReviewsDistinctCountTextDesc = 'REVIEWS_DISTINCT_COUNT_TEXT_DESC',
  ReviewsDistinctCountTimestampAsc = 'REVIEWS_DISTINCT_COUNT_TIMESTAMP_ASC',
  ReviewsDistinctCountTimestampDesc = 'REVIEWS_DISTINCT_COUNT_TIMESTAMP_DESC',
  ReviewsDistinctCountCreatedAtAsc = 'REVIEWS_DISTINCT_COUNT_CREATED_AT_ASC',
  ReviewsDistinctCountCreatedAtDesc = 'REVIEWS_DISTINCT_COUNT_CREATED_AT_DESC',
  ReviewsDistinctCountUpdatedAtAsc = 'REVIEWS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ReviewsDistinctCountUpdatedAtDesc = 'REVIEWS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ReviewsMinCompanyIdAsc = 'REVIEWS_MIN_COMPANY_ID_ASC',
  ReviewsMinCompanyIdDesc = 'REVIEWS_MIN_COMPANY_ID_DESC',
  ReviewsMinProviderAsc = 'REVIEWS_MIN_PROVIDER_ASC',
  ReviewsMinProviderDesc = 'REVIEWS_MIN_PROVIDER_DESC',
  ReviewsMinAuthorNameAsc = 'REVIEWS_MIN_AUTHOR_NAME_ASC',
  ReviewsMinAuthorNameDesc = 'REVIEWS_MIN_AUTHOR_NAME_DESC',
  ReviewsMinAuthorPhotoUrlAsc = 'REVIEWS_MIN_AUTHOR_PHOTO_URL_ASC',
  ReviewsMinAuthorPhotoUrlDesc = 'REVIEWS_MIN_AUTHOR_PHOTO_URL_DESC',
  ReviewsMinAuthorUrlAsc = 'REVIEWS_MIN_AUTHOR_URL_ASC',
  ReviewsMinAuthorUrlDesc = 'REVIEWS_MIN_AUTHOR_URL_DESC',
  ReviewsMinLanguageAsc = 'REVIEWS_MIN_LANGUAGE_ASC',
  ReviewsMinLanguageDesc = 'REVIEWS_MIN_LANGUAGE_DESC',
  ReviewsMinRatingAsc = 'REVIEWS_MIN_RATING_ASC',
  ReviewsMinRatingDesc = 'REVIEWS_MIN_RATING_DESC',
  ReviewsMinTextAsc = 'REVIEWS_MIN_TEXT_ASC',
  ReviewsMinTextDesc = 'REVIEWS_MIN_TEXT_DESC',
  ReviewsMinTimestampAsc = 'REVIEWS_MIN_TIMESTAMP_ASC',
  ReviewsMinTimestampDesc = 'REVIEWS_MIN_TIMESTAMP_DESC',
  ReviewsMinCreatedAtAsc = 'REVIEWS_MIN_CREATED_AT_ASC',
  ReviewsMinCreatedAtDesc = 'REVIEWS_MIN_CREATED_AT_DESC',
  ReviewsMinUpdatedAtAsc = 'REVIEWS_MIN_UPDATED_AT_ASC',
  ReviewsMinUpdatedAtDesc = 'REVIEWS_MIN_UPDATED_AT_DESC',
  ReviewsMaxCompanyIdAsc = 'REVIEWS_MAX_COMPANY_ID_ASC',
  ReviewsMaxCompanyIdDesc = 'REVIEWS_MAX_COMPANY_ID_DESC',
  ReviewsMaxProviderAsc = 'REVIEWS_MAX_PROVIDER_ASC',
  ReviewsMaxProviderDesc = 'REVIEWS_MAX_PROVIDER_DESC',
  ReviewsMaxAuthorNameAsc = 'REVIEWS_MAX_AUTHOR_NAME_ASC',
  ReviewsMaxAuthorNameDesc = 'REVIEWS_MAX_AUTHOR_NAME_DESC',
  ReviewsMaxAuthorPhotoUrlAsc = 'REVIEWS_MAX_AUTHOR_PHOTO_URL_ASC',
  ReviewsMaxAuthorPhotoUrlDesc = 'REVIEWS_MAX_AUTHOR_PHOTO_URL_DESC',
  ReviewsMaxAuthorUrlAsc = 'REVIEWS_MAX_AUTHOR_URL_ASC',
  ReviewsMaxAuthorUrlDesc = 'REVIEWS_MAX_AUTHOR_URL_DESC',
  ReviewsMaxLanguageAsc = 'REVIEWS_MAX_LANGUAGE_ASC',
  ReviewsMaxLanguageDesc = 'REVIEWS_MAX_LANGUAGE_DESC',
  ReviewsMaxRatingAsc = 'REVIEWS_MAX_RATING_ASC',
  ReviewsMaxRatingDesc = 'REVIEWS_MAX_RATING_DESC',
  ReviewsMaxTextAsc = 'REVIEWS_MAX_TEXT_ASC',
  ReviewsMaxTextDesc = 'REVIEWS_MAX_TEXT_DESC',
  ReviewsMaxTimestampAsc = 'REVIEWS_MAX_TIMESTAMP_ASC',
  ReviewsMaxTimestampDesc = 'REVIEWS_MAX_TIMESTAMP_DESC',
  ReviewsMaxCreatedAtAsc = 'REVIEWS_MAX_CREATED_AT_ASC',
  ReviewsMaxCreatedAtDesc = 'REVIEWS_MAX_CREATED_AT_DESC',
  ReviewsMaxUpdatedAtAsc = 'REVIEWS_MAX_UPDATED_AT_ASC',
  ReviewsMaxUpdatedAtDesc = 'REVIEWS_MAX_UPDATED_AT_DESC',
  ReviewsAverageCompanyIdAsc = 'REVIEWS_AVERAGE_COMPANY_ID_ASC',
  ReviewsAverageCompanyIdDesc = 'REVIEWS_AVERAGE_COMPANY_ID_DESC',
  ReviewsAverageProviderAsc = 'REVIEWS_AVERAGE_PROVIDER_ASC',
  ReviewsAverageProviderDesc = 'REVIEWS_AVERAGE_PROVIDER_DESC',
  ReviewsAverageAuthorNameAsc = 'REVIEWS_AVERAGE_AUTHOR_NAME_ASC',
  ReviewsAverageAuthorNameDesc = 'REVIEWS_AVERAGE_AUTHOR_NAME_DESC',
  ReviewsAverageAuthorPhotoUrlAsc = 'REVIEWS_AVERAGE_AUTHOR_PHOTO_URL_ASC',
  ReviewsAverageAuthorPhotoUrlDesc = 'REVIEWS_AVERAGE_AUTHOR_PHOTO_URL_DESC',
  ReviewsAverageAuthorUrlAsc = 'REVIEWS_AVERAGE_AUTHOR_URL_ASC',
  ReviewsAverageAuthorUrlDesc = 'REVIEWS_AVERAGE_AUTHOR_URL_DESC',
  ReviewsAverageLanguageAsc = 'REVIEWS_AVERAGE_LANGUAGE_ASC',
  ReviewsAverageLanguageDesc = 'REVIEWS_AVERAGE_LANGUAGE_DESC',
  ReviewsAverageRatingAsc = 'REVIEWS_AVERAGE_RATING_ASC',
  ReviewsAverageRatingDesc = 'REVIEWS_AVERAGE_RATING_DESC',
  ReviewsAverageTextAsc = 'REVIEWS_AVERAGE_TEXT_ASC',
  ReviewsAverageTextDesc = 'REVIEWS_AVERAGE_TEXT_DESC',
  ReviewsAverageTimestampAsc = 'REVIEWS_AVERAGE_TIMESTAMP_ASC',
  ReviewsAverageTimestampDesc = 'REVIEWS_AVERAGE_TIMESTAMP_DESC',
  ReviewsAverageCreatedAtAsc = 'REVIEWS_AVERAGE_CREATED_AT_ASC',
  ReviewsAverageCreatedAtDesc = 'REVIEWS_AVERAGE_CREATED_AT_DESC',
  ReviewsAverageUpdatedAtAsc = 'REVIEWS_AVERAGE_UPDATED_AT_ASC',
  ReviewsAverageUpdatedAtDesc = 'REVIEWS_AVERAGE_UPDATED_AT_DESC',
  ReviewsStddevSampleCompanyIdAsc = 'REVIEWS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ReviewsStddevSampleCompanyIdDesc = 'REVIEWS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ReviewsStddevSampleProviderAsc = 'REVIEWS_STDDEV_SAMPLE_PROVIDER_ASC',
  ReviewsStddevSampleProviderDesc = 'REVIEWS_STDDEV_SAMPLE_PROVIDER_DESC',
  ReviewsStddevSampleAuthorNameAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_NAME_ASC',
  ReviewsStddevSampleAuthorNameDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_NAME_DESC',
  ReviewsStddevSampleAuthorPhotoUrlAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_PHOTO_URL_ASC',
  ReviewsStddevSampleAuthorPhotoUrlDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_PHOTO_URL_DESC',
  ReviewsStddevSampleAuthorUrlAsc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_URL_ASC',
  ReviewsStddevSampleAuthorUrlDesc = 'REVIEWS_STDDEV_SAMPLE_AUTHOR_URL_DESC',
  ReviewsStddevSampleLanguageAsc = 'REVIEWS_STDDEV_SAMPLE_LANGUAGE_ASC',
  ReviewsStddevSampleLanguageDesc = 'REVIEWS_STDDEV_SAMPLE_LANGUAGE_DESC',
  ReviewsStddevSampleRatingAsc = 'REVIEWS_STDDEV_SAMPLE_RATING_ASC',
  ReviewsStddevSampleRatingDesc = 'REVIEWS_STDDEV_SAMPLE_RATING_DESC',
  ReviewsStddevSampleTextAsc = 'REVIEWS_STDDEV_SAMPLE_TEXT_ASC',
  ReviewsStddevSampleTextDesc = 'REVIEWS_STDDEV_SAMPLE_TEXT_DESC',
  ReviewsStddevSampleTimestampAsc = 'REVIEWS_STDDEV_SAMPLE_TIMESTAMP_ASC',
  ReviewsStddevSampleTimestampDesc = 'REVIEWS_STDDEV_SAMPLE_TIMESTAMP_DESC',
  ReviewsStddevSampleCreatedAtAsc = 'REVIEWS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ReviewsStddevSampleCreatedAtDesc = 'REVIEWS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ReviewsStddevSampleUpdatedAtAsc = 'REVIEWS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ReviewsStddevSampleUpdatedAtDesc = 'REVIEWS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ReviewsStddevPopulationCompanyIdAsc = 'REVIEWS_STDDEV_POPULATION_COMPANY_ID_ASC',
  ReviewsStddevPopulationCompanyIdDesc = 'REVIEWS_STDDEV_POPULATION_COMPANY_ID_DESC',
  ReviewsStddevPopulationProviderAsc = 'REVIEWS_STDDEV_POPULATION_PROVIDER_ASC',
  ReviewsStddevPopulationProviderDesc = 'REVIEWS_STDDEV_POPULATION_PROVIDER_DESC',
  ReviewsStddevPopulationAuthorNameAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_NAME_ASC',
  ReviewsStddevPopulationAuthorNameDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_NAME_DESC',
  ReviewsStddevPopulationAuthorPhotoUrlAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_PHOTO_URL_ASC',
  ReviewsStddevPopulationAuthorPhotoUrlDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_PHOTO_URL_DESC',
  ReviewsStddevPopulationAuthorUrlAsc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_URL_ASC',
  ReviewsStddevPopulationAuthorUrlDesc = 'REVIEWS_STDDEV_POPULATION_AUTHOR_URL_DESC',
  ReviewsStddevPopulationLanguageAsc = 'REVIEWS_STDDEV_POPULATION_LANGUAGE_ASC',
  ReviewsStddevPopulationLanguageDesc = 'REVIEWS_STDDEV_POPULATION_LANGUAGE_DESC',
  ReviewsStddevPopulationRatingAsc = 'REVIEWS_STDDEV_POPULATION_RATING_ASC',
  ReviewsStddevPopulationRatingDesc = 'REVIEWS_STDDEV_POPULATION_RATING_DESC',
  ReviewsStddevPopulationTextAsc = 'REVIEWS_STDDEV_POPULATION_TEXT_ASC',
  ReviewsStddevPopulationTextDesc = 'REVIEWS_STDDEV_POPULATION_TEXT_DESC',
  ReviewsStddevPopulationTimestampAsc = 'REVIEWS_STDDEV_POPULATION_TIMESTAMP_ASC',
  ReviewsStddevPopulationTimestampDesc = 'REVIEWS_STDDEV_POPULATION_TIMESTAMP_DESC',
  ReviewsStddevPopulationCreatedAtAsc = 'REVIEWS_STDDEV_POPULATION_CREATED_AT_ASC',
  ReviewsStddevPopulationCreatedAtDesc = 'REVIEWS_STDDEV_POPULATION_CREATED_AT_DESC',
  ReviewsStddevPopulationUpdatedAtAsc = 'REVIEWS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ReviewsStddevPopulationUpdatedAtDesc = 'REVIEWS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ReviewsVarianceSampleCompanyIdAsc = 'REVIEWS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ReviewsVarianceSampleCompanyIdDesc = 'REVIEWS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ReviewsVarianceSampleProviderAsc = 'REVIEWS_VARIANCE_SAMPLE_PROVIDER_ASC',
  ReviewsVarianceSampleProviderDesc = 'REVIEWS_VARIANCE_SAMPLE_PROVIDER_DESC',
  ReviewsVarianceSampleAuthorNameAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_NAME_ASC',
  ReviewsVarianceSampleAuthorNameDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_NAME_DESC',
  ReviewsVarianceSampleAuthorPhotoUrlAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_PHOTO_URL_ASC',
  ReviewsVarianceSampleAuthorPhotoUrlDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_PHOTO_URL_DESC',
  ReviewsVarianceSampleAuthorUrlAsc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_URL_ASC',
  ReviewsVarianceSampleAuthorUrlDesc = 'REVIEWS_VARIANCE_SAMPLE_AUTHOR_URL_DESC',
  ReviewsVarianceSampleLanguageAsc = 'REVIEWS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  ReviewsVarianceSampleLanguageDesc = 'REVIEWS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  ReviewsVarianceSampleRatingAsc = 'REVIEWS_VARIANCE_SAMPLE_RATING_ASC',
  ReviewsVarianceSampleRatingDesc = 'REVIEWS_VARIANCE_SAMPLE_RATING_DESC',
  ReviewsVarianceSampleTextAsc = 'REVIEWS_VARIANCE_SAMPLE_TEXT_ASC',
  ReviewsVarianceSampleTextDesc = 'REVIEWS_VARIANCE_SAMPLE_TEXT_DESC',
  ReviewsVarianceSampleTimestampAsc = 'REVIEWS_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  ReviewsVarianceSampleTimestampDesc = 'REVIEWS_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  ReviewsVarianceSampleCreatedAtAsc = 'REVIEWS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ReviewsVarianceSampleCreatedAtDesc = 'REVIEWS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ReviewsVarianceSampleUpdatedAtAsc = 'REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ReviewsVarianceSampleUpdatedAtDesc = 'REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ReviewsVariancePopulationCompanyIdAsc = 'REVIEWS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ReviewsVariancePopulationCompanyIdDesc = 'REVIEWS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ReviewsVariancePopulationProviderAsc = 'REVIEWS_VARIANCE_POPULATION_PROVIDER_ASC',
  ReviewsVariancePopulationProviderDesc = 'REVIEWS_VARIANCE_POPULATION_PROVIDER_DESC',
  ReviewsVariancePopulationAuthorNameAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_NAME_ASC',
  ReviewsVariancePopulationAuthorNameDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_NAME_DESC',
  ReviewsVariancePopulationAuthorPhotoUrlAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_PHOTO_URL_ASC',
  ReviewsVariancePopulationAuthorPhotoUrlDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_PHOTO_URL_DESC',
  ReviewsVariancePopulationAuthorUrlAsc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_URL_ASC',
  ReviewsVariancePopulationAuthorUrlDesc = 'REVIEWS_VARIANCE_POPULATION_AUTHOR_URL_DESC',
  ReviewsVariancePopulationLanguageAsc = 'REVIEWS_VARIANCE_POPULATION_LANGUAGE_ASC',
  ReviewsVariancePopulationLanguageDesc = 'REVIEWS_VARIANCE_POPULATION_LANGUAGE_DESC',
  ReviewsVariancePopulationRatingAsc = 'REVIEWS_VARIANCE_POPULATION_RATING_ASC',
  ReviewsVariancePopulationRatingDesc = 'REVIEWS_VARIANCE_POPULATION_RATING_DESC',
  ReviewsVariancePopulationTextAsc = 'REVIEWS_VARIANCE_POPULATION_TEXT_ASC',
  ReviewsVariancePopulationTextDesc = 'REVIEWS_VARIANCE_POPULATION_TEXT_DESC',
  ReviewsVariancePopulationTimestampAsc = 'REVIEWS_VARIANCE_POPULATION_TIMESTAMP_ASC',
  ReviewsVariancePopulationTimestampDesc = 'REVIEWS_VARIANCE_POPULATION_TIMESTAMP_DESC',
  ReviewsVariancePopulationCreatedAtAsc = 'REVIEWS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ReviewsVariancePopulationCreatedAtDesc = 'REVIEWS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ReviewsVariancePopulationUpdatedAtAsc = 'REVIEWS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ReviewsVariancePopulationUpdatedAtDesc = 'REVIEWS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnalyticsCountAsc = 'ANALYTICS_COUNT_ASC',
  AnalyticsCountDesc = 'ANALYTICS_COUNT_DESC',
  AnalyticsSumIdAsc = 'ANALYTICS_SUM_ID_ASC',
  AnalyticsSumIdDesc = 'ANALYTICS_SUM_ID_DESC',
  AnalyticsSumClientIdAsc = 'ANALYTICS_SUM_CLIENT_ID_ASC',
  AnalyticsSumClientIdDesc = 'ANALYTICS_SUM_CLIENT_ID_DESC',
  AnalyticsSumRequestIdAsc = 'ANALYTICS_SUM_REQUEST_ID_ASC',
  AnalyticsSumRequestIdDesc = 'ANALYTICS_SUM_REQUEST_ID_DESC',
  AnalyticsSumEventCategoryAsc = 'ANALYTICS_SUM_EVENT_CATEGORY_ASC',
  AnalyticsSumEventCategoryDesc = 'ANALYTICS_SUM_EVENT_CATEGORY_DESC',
  AnalyticsSumEventActionAsc = 'ANALYTICS_SUM_EVENT_ACTION_ASC',
  AnalyticsSumEventActionDesc = 'ANALYTICS_SUM_EVENT_ACTION_DESC',
  AnalyticsSumSourceUrlAsc = 'ANALYTICS_SUM_SOURCE_URL_ASC',
  AnalyticsSumSourceUrlDesc = 'ANALYTICS_SUM_SOURCE_URL_DESC',
  AnalyticsSumReferrerUrlAsc = 'ANALYTICS_SUM_REFERRER_URL_ASC',
  AnalyticsSumReferrerUrlDesc = 'ANALYTICS_SUM_REFERRER_URL_DESC',
  AnalyticsSumUserAgentAsc = 'ANALYTICS_SUM_USER_AGENT_ASC',
  AnalyticsSumUserAgentDesc = 'ANALYTICS_SUM_USER_AGENT_DESC',
  AnalyticsSumUserIpAsc = 'ANALYTICS_SUM_USER_IP_ASC',
  AnalyticsSumUserIpDesc = 'ANALYTICS_SUM_USER_IP_DESC',
  AnalyticsSumCompanyIdAsc = 'ANALYTICS_SUM_COMPANY_ID_ASC',
  AnalyticsSumCompanyIdDesc = 'ANALYTICS_SUM_COMPANY_ID_DESC',
  AnalyticsSumContextAsc = 'ANALYTICS_SUM_CONTEXT_ASC',
  AnalyticsSumContextDesc = 'ANALYTICS_SUM_CONTEXT_DESC',
  AnalyticsSumCreatedAtAsc = 'ANALYTICS_SUM_CREATED_AT_ASC',
  AnalyticsSumCreatedAtDesc = 'ANALYTICS_SUM_CREATED_AT_DESC',
  AnalyticsSumUpdatedAtAsc = 'ANALYTICS_SUM_UPDATED_AT_ASC',
  AnalyticsSumUpdatedAtDesc = 'ANALYTICS_SUM_UPDATED_AT_DESC',
  AnalyticsDistinctCountIdAsc = 'ANALYTICS_DISTINCT_COUNT_ID_ASC',
  AnalyticsDistinctCountIdDesc = 'ANALYTICS_DISTINCT_COUNT_ID_DESC',
  AnalyticsDistinctCountClientIdAsc = 'ANALYTICS_DISTINCT_COUNT_CLIENT_ID_ASC',
  AnalyticsDistinctCountClientIdDesc = 'ANALYTICS_DISTINCT_COUNT_CLIENT_ID_DESC',
  AnalyticsDistinctCountRequestIdAsc = 'ANALYTICS_DISTINCT_COUNT_REQUEST_ID_ASC',
  AnalyticsDistinctCountRequestIdDesc = 'ANALYTICS_DISTINCT_COUNT_REQUEST_ID_DESC',
  AnalyticsDistinctCountEventCategoryAsc = 'ANALYTICS_DISTINCT_COUNT_EVENT_CATEGORY_ASC',
  AnalyticsDistinctCountEventCategoryDesc = 'ANALYTICS_DISTINCT_COUNT_EVENT_CATEGORY_DESC',
  AnalyticsDistinctCountEventActionAsc = 'ANALYTICS_DISTINCT_COUNT_EVENT_ACTION_ASC',
  AnalyticsDistinctCountEventActionDesc = 'ANALYTICS_DISTINCT_COUNT_EVENT_ACTION_DESC',
  AnalyticsDistinctCountSourceUrlAsc = 'ANALYTICS_DISTINCT_COUNT_SOURCE_URL_ASC',
  AnalyticsDistinctCountSourceUrlDesc = 'ANALYTICS_DISTINCT_COUNT_SOURCE_URL_DESC',
  AnalyticsDistinctCountReferrerUrlAsc = 'ANALYTICS_DISTINCT_COUNT_REFERRER_URL_ASC',
  AnalyticsDistinctCountReferrerUrlDesc = 'ANALYTICS_DISTINCT_COUNT_REFERRER_URL_DESC',
  AnalyticsDistinctCountUserAgentAsc = 'ANALYTICS_DISTINCT_COUNT_USER_AGENT_ASC',
  AnalyticsDistinctCountUserAgentDesc = 'ANALYTICS_DISTINCT_COUNT_USER_AGENT_DESC',
  AnalyticsDistinctCountUserIpAsc = 'ANALYTICS_DISTINCT_COUNT_USER_IP_ASC',
  AnalyticsDistinctCountUserIpDesc = 'ANALYTICS_DISTINCT_COUNT_USER_IP_DESC',
  AnalyticsDistinctCountCompanyIdAsc = 'ANALYTICS_DISTINCT_COUNT_COMPANY_ID_ASC',
  AnalyticsDistinctCountCompanyIdDesc = 'ANALYTICS_DISTINCT_COUNT_COMPANY_ID_DESC',
  AnalyticsDistinctCountContextAsc = 'ANALYTICS_DISTINCT_COUNT_CONTEXT_ASC',
  AnalyticsDistinctCountContextDesc = 'ANALYTICS_DISTINCT_COUNT_CONTEXT_DESC',
  AnalyticsDistinctCountCreatedAtAsc = 'ANALYTICS_DISTINCT_COUNT_CREATED_AT_ASC',
  AnalyticsDistinctCountCreatedAtDesc = 'ANALYTICS_DISTINCT_COUNT_CREATED_AT_DESC',
  AnalyticsDistinctCountUpdatedAtAsc = 'ANALYTICS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnalyticsDistinctCountUpdatedAtDesc = 'ANALYTICS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnalyticsMinIdAsc = 'ANALYTICS_MIN_ID_ASC',
  AnalyticsMinIdDesc = 'ANALYTICS_MIN_ID_DESC',
  AnalyticsMinClientIdAsc = 'ANALYTICS_MIN_CLIENT_ID_ASC',
  AnalyticsMinClientIdDesc = 'ANALYTICS_MIN_CLIENT_ID_DESC',
  AnalyticsMinRequestIdAsc = 'ANALYTICS_MIN_REQUEST_ID_ASC',
  AnalyticsMinRequestIdDesc = 'ANALYTICS_MIN_REQUEST_ID_DESC',
  AnalyticsMinEventCategoryAsc = 'ANALYTICS_MIN_EVENT_CATEGORY_ASC',
  AnalyticsMinEventCategoryDesc = 'ANALYTICS_MIN_EVENT_CATEGORY_DESC',
  AnalyticsMinEventActionAsc = 'ANALYTICS_MIN_EVENT_ACTION_ASC',
  AnalyticsMinEventActionDesc = 'ANALYTICS_MIN_EVENT_ACTION_DESC',
  AnalyticsMinSourceUrlAsc = 'ANALYTICS_MIN_SOURCE_URL_ASC',
  AnalyticsMinSourceUrlDesc = 'ANALYTICS_MIN_SOURCE_URL_DESC',
  AnalyticsMinReferrerUrlAsc = 'ANALYTICS_MIN_REFERRER_URL_ASC',
  AnalyticsMinReferrerUrlDesc = 'ANALYTICS_MIN_REFERRER_URL_DESC',
  AnalyticsMinUserAgentAsc = 'ANALYTICS_MIN_USER_AGENT_ASC',
  AnalyticsMinUserAgentDesc = 'ANALYTICS_MIN_USER_AGENT_DESC',
  AnalyticsMinUserIpAsc = 'ANALYTICS_MIN_USER_IP_ASC',
  AnalyticsMinUserIpDesc = 'ANALYTICS_MIN_USER_IP_DESC',
  AnalyticsMinCompanyIdAsc = 'ANALYTICS_MIN_COMPANY_ID_ASC',
  AnalyticsMinCompanyIdDesc = 'ANALYTICS_MIN_COMPANY_ID_DESC',
  AnalyticsMinContextAsc = 'ANALYTICS_MIN_CONTEXT_ASC',
  AnalyticsMinContextDesc = 'ANALYTICS_MIN_CONTEXT_DESC',
  AnalyticsMinCreatedAtAsc = 'ANALYTICS_MIN_CREATED_AT_ASC',
  AnalyticsMinCreatedAtDesc = 'ANALYTICS_MIN_CREATED_AT_DESC',
  AnalyticsMinUpdatedAtAsc = 'ANALYTICS_MIN_UPDATED_AT_ASC',
  AnalyticsMinUpdatedAtDesc = 'ANALYTICS_MIN_UPDATED_AT_DESC',
  AnalyticsMaxIdAsc = 'ANALYTICS_MAX_ID_ASC',
  AnalyticsMaxIdDesc = 'ANALYTICS_MAX_ID_DESC',
  AnalyticsMaxClientIdAsc = 'ANALYTICS_MAX_CLIENT_ID_ASC',
  AnalyticsMaxClientIdDesc = 'ANALYTICS_MAX_CLIENT_ID_DESC',
  AnalyticsMaxRequestIdAsc = 'ANALYTICS_MAX_REQUEST_ID_ASC',
  AnalyticsMaxRequestIdDesc = 'ANALYTICS_MAX_REQUEST_ID_DESC',
  AnalyticsMaxEventCategoryAsc = 'ANALYTICS_MAX_EVENT_CATEGORY_ASC',
  AnalyticsMaxEventCategoryDesc = 'ANALYTICS_MAX_EVENT_CATEGORY_DESC',
  AnalyticsMaxEventActionAsc = 'ANALYTICS_MAX_EVENT_ACTION_ASC',
  AnalyticsMaxEventActionDesc = 'ANALYTICS_MAX_EVENT_ACTION_DESC',
  AnalyticsMaxSourceUrlAsc = 'ANALYTICS_MAX_SOURCE_URL_ASC',
  AnalyticsMaxSourceUrlDesc = 'ANALYTICS_MAX_SOURCE_URL_DESC',
  AnalyticsMaxReferrerUrlAsc = 'ANALYTICS_MAX_REFERRER_URL_ASC',
  AnalyticsMaxReferrerUrlDesc = 'ANALYTICS_MAX_REFERRER_URL_DESC',
  AnalyticsMaxUserAgentAsc = 'ANALYTICS_MAX_USER_AGENT_ASC',
  AnalyticsMaxUserAgentDesc = 'ANALYTICS_MAX_USER_AGENT_DESC',
  AnalyticsMaxUserIpAsc = 'ANALYTICS_MAX_USER_IP_ASC',
  AnalyticsMaxUserIpDesc = 'ANALYTICS_MAX_USER_IP_DESC',
  AnalyticsMaxCompanyIdAsc = 'ANALYTICS_MAX_COMPANY_ID_ASC',
  AnalyticsMaxCompanyIdDesc = 'ANALYTICS_MAX_COMPANY_ID_DESC',
  AnalyticsMaxContextAsc = 'ANALYTICS_MAX_CONTEXT_ASC',
  AnalyticsMaxContextDesc = 'ANALYTICS_MAX_CONTEXT_DESC',
  AnalyticsMaxCreatedAtAsc = 'ANALYTICS_MAX_CREATED_AT_ASC',
  AnalyticsMaxCreatedAtDesc = 'ANALYTICS_MAX_CREATED_AT_DESC',
  AnalyticsMaxUpdatedAtAsc = 'ANALYTICS_MAX_UPDATED_AT_ASC',
  AnalyticsMaxUpdatedAtDesc = 'ANALYTICS_MAX_UPDATED_AT_DESC',
  AnalyticsAverageIdAsc = 'ANALYTICS_AVERAGE_ID_ASC',
  AnalyticsAverageIdDesc = 'ANALYTICS_AVERAGE_ID_DESC',
  AnalyticsAverageClientIdAsc = 'ANALYTICS_AVERAGE_CLIENT_ID_ASC',
  AnalyticsAverageClientIdDesc = 'ANALYTICS_AVERAGE_CLIENT_ID_DESC',
  AnalyticsAverageRequestIdAsc = 'ANALYTICS_AVERAGE_REQUEST_ID_ASC',
  AnalyticsAverageRequestIdDesc = 'ANALYTICS_AVERAGE_REQUEST_ID_DESC',
  AnalyticsAverageEventCategoryAsc = 'ANALYTICS_AVERAGE_EVENT_CATEGORY_ASC',
  AnalyticsAverageEventCategoryDesc = 'ANALYTICS_AVERAGE_EVENT_CATEGORY_DESC',
  AnalyticsAverageEventActionAsc = 'ANALYTICS_AVERAGE_EVENT_ACTION_ASC',
  AnalyticsAverageEventActionDesc = 'ANALYTICS_AVERAGE_EVENT_ACTION_DESC',
  AnalyticsAverageSourceUrlAsc = 'ANALYTICS_AVERAGE_SOURCE_URL_ASC',
  AnalyticsAverageSourceUrlDesc = 'ANALYTICS_AVERAGE_SOURCE_URL_DESC',
  AnalyticsAverageReferrerUrlAsc = 'ANALYTICS_AVERAGE_REFERRER_URL_ASC',
  AnalyticsAverageReferrerUrlDesc = 'ANALYTICS_AVERAGE_REFERRER_URL_DESC',
  AnalyticsAverageUserAgentAsc = 'ANALYTICS_AVERAGE_USER_AGENT_ASC',
  AnalyticsAverageUserAgentDesc = 'ANALYTICS_AVERAGE_USER_AGENT_DESC',
  AnalyticsAverageUserIpAsc = 'ANALYTICS_AVERAGE_USER_IP_ASC',
  AnalyticsAverageUserIpDesc = 'ANALYTICS_AVERAGE_USER_IP_DESC',
  AnalyticsAverageCompanyIdAsc = 'ANALYTICS_AVERAGE_COMPANY_ID_ASC',
  AnalyticsAverageCompanyIdDesc = 'ANALYTICS_AVERAGE_COMPANY_ID_DESC',
  AnalyticsAverageContextAsc = 'ANALYTICS_AVERAGE_CONTEXT_ASC',
  AnalyticsAverageContextDesc = 'ANALYTICS_AVERAGE_CONTEXT_DESC',
  AnalyticsAverageCreatedAtAsc = 'ANALYTICS_AVERAGE_CREATED_AT_ASC',
  AnalyticsAverageCreatedAtDesc = 'ANALYTICS_AVERAGE_CREATED_AT_DESC',
  AnalyticsAverageUpdatedAtAsc = 'ANALYTICS_AVERAGE_UPDATED_AT_ASC',
  AnalyticsAverageUpdatedAtDesc = 'ANALYTICS_AVERAGE_UPDATED_AT_DESC',
  AnalyticsStddevSampleIdAsc = 'ANALYTICS_STDDEV_SAMPLE_ID_ASC',
  AnalyticsStddevSampleIdDesc = 'ANALYTICS_STDDEV_SAMPLE_ID_DESC',
  AnalyticsStddevSampleClientIdAsc = 'ANALYTICS_STDDEV_SAMPLE_CLIENT_ID_ASC',
  AnalyticsStddevSampleClientIdDesc = 'ANALYTICS_STDDEV_SAMPLE_CLIENT_ID_DESC',
  AnalyticsStddevSampleRequestIdAsc = 'ANALYTICS_STDDEV_SAMPLE_REQUEST_ID_ASC',
  AnalyticsStddevSampleRequestIdDesc = 'ANALYTICS_STDDEV_SAMPLE_REQUEST_ID_DESC',
  AnalyticsStddevSampleEventCategoryAsc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_CATEGORY_ASC',
  AnalyticsStddevSampleEventCategoryDesc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_CATEGORY_DESC',
  AnalyticsStddevSampleEventActionAsc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_ACTION_ASC',
  AnalyticsStddevSampleEventActionDesc = 'ANALYTICS_STDDEV_SAMPLE_EVENT_ACTION_DESC',
  AnalyticsStddevSampleSourceUrlAsc = 'ANALYTICS_STDDEV_SAMPLE_SOURCE_URL_ASC',
  AnalyticsStddevSampleSourceUrlDesc = 'ANALYTICS_STDDEV_SAMPLE_SOURCE_URL_DESC',
  AnalyticsStddevSampleReferrerUrlAsc = 'ANALYTICS_STDDEV_SAMPLE_REFERRER_URL_ASC',
  AnalyticsStddevSampleReferrerUrlDesc = 'ANALYTICS_STDDEV_SAMPLE_REFERRER_URL_DESC',
  AnalyticsStddevSampleUserAgentAsc = 'ANALYTICS_STDDEV_SAMPLE_USER_AGENT_ASC',
  AnalyticsStddevSampleUserAgentDesc = 'ANALYTICS_STDDEV_SAMPLE_USER_AGENT_DESC',
  AnalyticsStddevSampleUserIpAsc = 'ANALYTICS_STDDEV_SAMPLE_USER_IP_ASC',
  AnalyticsStddevSampleUserIpDesc = 'ANALYTICS_STDDEV_SAMPLE_USER_IP_DESC',
  AnalyticsStddevSampleCompanyIdAsc = 'ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  AnalyticsStddevSampleCompanyIdDesc = 'ANALYTICS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  AnalyticsStddevSampleContextAsc = 'ANALYTICS_STDDEV_SAMPLE_CONTEXT_ASC',
  AnalyticsStddevSampleContextDesc = 'ANALYTICS_STDDEV_SAMPLE_CONTEXT_DESC',
  AnalyticsStddevSampleCreatedAtAsc = 'ANALYTICS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnalyticsStddevSampleCreatedAtDesc = 'ANALYTICS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnalyticsStddevSampleUpdatedAtAsc = 'ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnalyticsStddevSampleUpdatedAtDesc = 'ANALYTICS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnalyticsStddevPopulationIdAsc = 'ANALYTICS_STDDEV_POPULATION_ID_ASC',
  AnalyticsStddevPopulationIdDesc = 'ANALYTICS_STDDEV_POPULATION_ID_DESC',
  AnalyticsStddevPopulationClientIdAsc = 'ANALYTICS_STDDEV_POPULATION_CLIENT_ID_ASC',
  AnalyticsStddevPopulationClientIdDesc = 'ANALYTICS_STDDEV_POPULATION_CLIENT_ID_DESC',
  AnalyticsStddevPopulationRequestIdAsc = 'ANALYTICS_STDDEV_POPULATION_REQUEST_ID_ASC',
  AnalyticsStddevPopulationRequestIdDesc = 'ANALYTICS_STDDEV_POPULATION_REQUEST_ID_DESC',
  AnalyticsStddevPopulationEventCategoryAsc = 'ANALYTICS_STDDEV_POPULATION_EVENT_CATEGORY_ASC',
  AnalyticsStddevPopulationEventCategoryDesc = 'ANALYTICS_STDDEV_POPULATION_EVENT_CATEGORY_DESC',
  AnalyticsStddevPopulationEventActionAsc = 'ANALYTICS_STDDEV_POPULATION_EVENT_ACTION_ASC',
  AnalyticsStddevPopulationEventActionDesc = 'ANALYTICS_STDDEV_POPULATION_EVENT_ACTION_DESC',
  AnalyticsStddevPopulationSourceUrlAsc = 'ANALYTICS_STDDEV_POPULATION_SOURCE_URL_ASC',
  AnalyticsStddevPopulationSourceUrlDesc = 'ANALYTICS_STDDEV_POPULATION_SOURCE_URL_DESC',
  AnalyticsStddevPopulationReferrerUrlAsc = 'ANALYTICS_STDDEV_POPULATION_REFERRER_URL_ASC',
  AnalyticsStddevPopulationReferrerUrlDesc = 'ANALYTICS_STDDEV_POPULATION_REFERRER_URL_DESC',
  AnalyticsStddevPopulationUserAgentAsc = 'ANALYTICS_STDDEV_POPULATION_USER_AGENT_ASC',
  AnalyticsStddevPopulationUserAgentDesc = 'ANALYTICS_STDDEV_POPULATION_USER_AGENT_DESC',
  AnalyticsStddevPopulationUserIpAsc = 'ANALYTICS_STDDEV_POPULATION_USER_IP_ASC',
  AnalyticsStddevPopulationUserIpDesc = 'ANALYTICS_STDDEV_POPULATION_USER_IP_DESC',
  AnalyticsStddevPopulationCompanyIdAsc = 'ANALYTICS_STDDEV_POPULATION_COMPANY_ID_ASC',
  AnalyticsStddevPopulationCompanyIdDesc = 'ANALYTICS_STDDEV_POPULATION_COMPANY_ID_DESC',
  AnalyticsStddevPopulationContextAsc = 'ANALYTICS_STDDEV_POPULATION_CONTEXT_ASC',
  AnalyticsStddevPopulationContextDesc = 'ANALYTICS_STDDEV_POPULATION_CONTEXT_DESC',
  AnalyticsStddevPopulationCreatedAtAsc = 'ANALYTICS_STDDEV_POPULATION_CREATED_AT_ASC',
  AnalyticsStddevPopulationCreatedAtDesc = 'ANALYTICS_STDDEV_POPULATION_CREATED_AT_DESC',
  AnalyticsStddevPopulationUpdatedAtAsc = 'ANALYTICS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnalyticsStddevPopulationUpdatedAtDesc = 'ANALYTICS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnalyticsVarianceSampleIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_ID_ASC',
  AnalyticsVarianceSampleIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_ID_DESC',
  AnalyticsVarianceSampleClientIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_CLIENT_ID_ASC',
  AnalyticsVarianceSampleClientIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_CLIENT_ID_DESC',
  AnalyticsVarianceSampleRequestIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_REQUEST_ID_ASC',
  AnalyticsVarianceSampleRequestIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_REQUEST_ID_DESC',
  AnalyticsVarianceSampleEventCategoryAsc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_CATEGORY_ASC',
  AnalyticsVarianceSampleEventCategoryDesc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_CATEGORY_DESC',
  AnalyticsVarianceSampleEventActionAsc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_ACTION_ASC',
  AnalyticsVarianceSampleEventActionDesc = 'ANALYTICS_VARIANCE_SAMPLE_EVENT_ACTION_DESC',
  AnalyticsVarianceSampleSourceUrlAsc = 'ANALYTICS_VARIANCE_SAMPLE_SOURCE_URL_ASC',
  AnalyticsVarianceSampleSourceUrlDesc = 'ANALYTICS_VARIANCE_SAMPLE_SOURCE_URL_DESC',
  AnalyticsVarianceSampleReferrerUrlAsc = 'ANALYTICS_VARIANCE_SAMPLE_REFERRER_URL_ASC',
  AnalyticsVarianceSampleReferrerUrlDesc = 'ANALYTICS_VARIANCE_SAMPLE_REFERRER_URL_DESC',
  AnalyticsVarianceSampleUserAgentAsc = 'ANALYTICS_VARIANCE_SAMPLE_USER_AGENT_ASC',
  AnalyticsVarianceSampleUserAgentDesc = 'ANALYTICS_VARIANCE_SAMPLE_USER_AGENT_DESC',
  AnalyticsVarianceSampleUserIpAsc = 'ANALYTICS_VARIANCE_SAMPLE_USER_IP_ASC',
  AnalyticsVarianceSampleUserIpDesc = 'ANALYTICS_VARIANCE_SAMPLE_USER_IP_DESC',
  AnalyticsVarianceSampleCompanyIdAsc = 'ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  AnalyticsVarianceSampleCompanyIdDesc = 'ANALYTICS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  AnalyticsVarianceSampleContextAsc = 'ANALYTICS_VARIANCE_SAMPLE_CONTEXT_ASC',
  AnalyticsVarianceSampleContextDesc = 'ANALYTICS_VARIANCE_SAMPLE_CONTEXT_DESC',
  AnalyticsVarianceSampleCreatedAtAsc = 'ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnalyticsVarianceSampleCreatedAtDesc = 'ANALYTICS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnalyticsVarianceSampleUpdatedAtAsc = 'ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnalyticsVarianceSampleUpdatedAtDesc = 'ANALYTICS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AnalyticsVariancePopulationIdAsc = 'ANALYTICS_VARIANCE_POPULATION_ID_ASC',
  AnalyticsVariancePopulationIdDesc = 'ANALYTICS_VARIANCE_POPULATION_ID_DESC',
  AnalyticsVariancePopulationClientIdAsc = 'ANALYTICS_VARIANCE_POPULATION_CLIENT_ID_ASC',
  AnalyticsVariancePopulationClientIdDesc = 'ANALYTICS_VARIANCE_POPULATION_CLIENT_ID_DESC',
  AnalyticsVariancePopulationRequestIdAsc = 'ANALYTICS_VARIANCE_POPULATION_REQUEST_ID_ASC',
  AnalyticsVariancePopulationRequestIdDesc = 'ANALYTICS_VARIANCE_POPULATION_REQUEST_ID_DESC',
  AnalyticsVariancePopulationEventCategoryAsc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_CATEGORY_ASC',
  AnalyticsVariancePopulationEventCategoryDesc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_CATEGORY_DESC',
  AnalyticsVariancePopulationEventActionAsc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_ACTION_ASC',
  AnalyticsVariancePopulationEventActionDesc = 'ANALYTICS_VARIANCE_POPULATION_EVENT_ACTION_DESC',
  AnalyticsVariancePopulationSourceUrlAsc = 'ANALYTICS_VARIANCE_POPULATION_SOURCE_URL_ASC',
  AnalyticsVariancePopulationSourceUrlDesc = 'ANALYTICS_VARIANCE_POPULATION_SOURCE_URL_DESC',
  AnalyticsVariancePopulationReferrerUrlAsc = 'ANALYTICS_VARIANCE_POPULATION_REFERRER_URL_ASC',
  AnalyticsVariancePopulationReferrerUrlDesc = 'ANALYTICS_VARIANCE_POPULATION_REFERRER_URL_DESC',
  AnalyticsVariancePopulationUserAgentAsc = 'ANALYTICS_VARIANCE_POPULATION_USER_AGENT_ASC',
  AnalyticsVariancePopulationUserAgentDesc = 'ANALYTICS_VARIANCE_POPULATION_USER_AGENT_DESC',
  AnalyticsVariancePopulationUserIpAsc = 'ANALYTICS_VARIANCE_POPULATION_USER_IP_ASC',
  AnalyticsVariancePopulationUserIpDesc = 'ANALYTICS_VARIANCE_POPULATION_USER_IP_DESC',
  AnalyticsVariancePopulationCompanyIdAsc = 'ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  AnalyticsVariancePopulationCompanyIdDesc = 'ANALYTICS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  AnalyticsVariancePopulationContextAsc = 'ANALYTICS_VARIANCE_POPULATION_CONTEXT_ASC',
  AnalyticsVariancePopulationContextDesc = 'ANALYTICS_VARIANCE_POPULATION_CONTEXT_DESC',
  AnalyticsVariancePopulationCreatedAtAsc = 'ANALYTICS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnalyticsVariancePopulationCreatedAtDesc = 'ANALYTICS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnalyticsVariancePopulationUpdatedAtAsc = 'ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnalyticsVariancePopulationUpdatedAtDesc = 'ANALYTICS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsCountAsc = 'COMPANY_INTEGRATIONS_COUNT_ASC',
  CompanyIntegrationsCountDesc = 'COMPANY_INTEGRATIONS_COUNT_DESC',
  CompanyIntegrationsSumIdAsc = 'COMPANY_INTEGRATIONS_SUM_ID_ASC',
  CompanyIntegrationsSumIdDesc = 'COMPANY_INTEGRATIONS_SUM_ID_DESC',
  CompanyIntegrationsSumIntegrationIdAsc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  CompanyIntegrationsSumIntegrationIdDesc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  CompanyIntegrationsSumCompanyIdAsc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_ASC',
  CompanyIntegrationsSumCompanyIdDesc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_DESC',
  CompanyIntegrationsSumEnabledAsc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyIntegrationsSumEnabledDesc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyIntegrationsSumCreatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyIntegrationsSumCreatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyIntegrationsSumUpdatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyIntegrationsSumUpdatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyIntegrationsDistinctCountIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyIntegrationsDistinctCountIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyIntegrationsDistinctCountIntegrationIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  CompanyIntegrationsDistinctCountIntegrationIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  CompanyIntegrationsDistinctCountCompanyIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyIntegrationsDistinctCountCompanyIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyIntegrationsDistinctCountEnabledAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyIntegrationsDistinctCountEnabledDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyIntegrationsMinIdAsc = 'COMPANY_INTEGRATIONS_MIN_ID_ASC',
  CompanyIntegrationsMinIdDesc = 'COMPANY_INTEGRATIONS_MIN_ID_DESC',
  CompanyIntegrationsMinIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  CompanyIntegrationsMinIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  CompanyIntegrationsMinCompanyIdAsc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_ASC',
  CompanyIntegrationsMinCompanyIdDesc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_DESC',
  CompanyIntegrationsMinEnabledAsc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyIntegrationsMinEnabledDesc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyIntegrationsMinCreatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyIntegrationsMinCreatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyIntegrationsMinUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyIntegrationsMinUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyIntegrationsMaxIdAsc = 'COMPANY_INTEGRATIONS_MAX_ID_ASC',
  CompanyIntegrationsMaxIdDesc = 'COMPANY_INTEGRATIONS_MAX_ID_DESC',
  CompanyIntegrationsMaxIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  CompanyIntegrationsMaxIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  CompanyIntegrationsMaxCompanyIdAsc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_ASC',
  CompanyIntegrationsMaxCompanyIdDesc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_DESC',
  CompanyIntegrationsMaxEnabledAsc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyIntegrationsMaxEnabledDesc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyIntegrationsMaxCreatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyIntegrationsMaxCreatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyIntegrationsMaxUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyIntegrationsMaxUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyIntegrationsAverageIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyIntegrationsAverageIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyIntegrationsAverageIntegrationIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  CompanyIntegrationsAverageIntegrationIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  CompanyIntegrationsAverageCompanyIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyIntegrationsAverageCompanyIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyIntegrationsAverageEnabledAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyIntegrationsAverageEnabledDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyIntegrationsAverageCreatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyIntegrationsAverageCreatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyIntegrationsAverageUpdatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyIntegrationsAverageUpdatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevSampleIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyIntegrationsStddevSampleIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyIntegrationsStddevSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsStddevSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsStddevSampleEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsStddevSampleEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevPopulationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyIntegrationsStddevPopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevPopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsStddevPopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsStddevPopulationEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyIntegrationsStddevPopulationEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsVarianceSampleIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyIntegrationsVarianceSampleIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyIntegrationsVarianceSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsVarianceSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsVarianceSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsVarianceSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsVarianceSampleEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsVarianceSampleEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsVariancePopulationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyIntegrationsVariancePopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsVariancePopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsVariancePopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsVariancePopulationEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyIntegrationsVariancePopulationEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsCountAsc = 'COMPANY_CHANNELS_COUNT_ASC',
  CompanyChannelsCountDesc = 'COMPANY_CHANNELS_COUNT_DESC',
  CompanyChannelsSumIdAsc = 'COMPANY_CHANNELS_SUM_ID_ASC',
  CompanyChannelsSumIdDesc = 'COMPANY_CHANNELS_SUM_ID_DESC',
  CompanyChannelsSumWidgetIdAsc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_ASC',
  CompanyChannelsSumWidgetIdDesc = 'COMPANY_CHANNELS_SUM_WIDGET_ID_DESC',
  CompanyChannelsSumChannelIdAsc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_ASC',
  CompanyChannelsSumChannelIdDesc = 'COMPANY_CHANNELS_SUM_CHANNEL_ID_DESC',
  CompanyChannelsSumCompanyIdAsc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_ASC',
  CompanyChannelsSumCompanyIdDesc = 'COMPANY_CHANNELS_SUM_COMPANY_ID_DESC',
  CompanyChannelsSumEnabledAsc = 'COMPANY_CHANNELS_SUM_ENABLED_ASC',
  CompanyChannelsSumEnabledDesc = 'COMPANY_CHANNELS_SUM_ENABLED_DESC',
  CompanyChannelsSumCreatedAtAsc = 'COMPANY_CHANNELS_SUM_CREATED_AT_ASC',
  CompanyChannelsSumCreatedAtDesc = 'COMPANY_CHANNELS_SUM_CREATED_AT_DESC',
  CompanyChannelsSumUpdatedAtAsc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_ASC',
  CompanyChannelsSumUpdatedAtDesc = 'COMPANY_CHANNELS_SUM_UPDATED_AT_DESC',
  CompanyChannelsDistinctCountIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_ASC',
  CompanyChannelsDistinctCountIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ID_DESC',
  CompanyChannelsDistinctCountWidgetIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyChannelsDistinctCountWidgetIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyChannelsDistinctCountChannelIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  CompanyChannelsDistinctCountChannelIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  CompanyChannelsDistinctCountCompanyIdAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyChannelsDistinctCountCompanyIdDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyChannelsDistinctCountEnabledAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyChannelsDistinctCountEnabledDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyChannelsDistinctCountCreatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyChannelsDistinctCountCreatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyChannelsDistinctCountUpdatedAtAsc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyChannelsDistinctCountUpdatedAtDesc = 'COMPANY_CHANNELS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyChannelsMinIdAsc = 'COMPANY_CHANNELS_MIN_ID_ASC',
  CompanyChannelsMinIdDesc = 'COMPANY_CHANNELS_MIN_ID_DESC',
  CompanyChannelsMinWidgetIdAsc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_ASC',
  CompanyChannelsMinWidgetIdDesc = 'COMPANY_CHANNELS_MIN_WIDGET_ID_DESC',
  CompanyChannelsMinChannelIdAsc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_ASC',
  CompanyChannelsMinChannelIdDesc = 'COMPANY_CHANNELS_MIN_CHANNEL_ID_DESC',
  CompanyChannelsMinCompanyIdAsc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_ASC',
  CompanyChannelsMinCompanyIdDesc = 'COMPANY_CHANNELS_MIN_COMPANY_ID_DESC',
  CompanyChannelsMinEnabledAsc = 'COMPANY_CHANNELS_MIN_ENABLED_ASC',
  CompanyChannelsMinEnabledDesc = 'COMPANY_CHANNELS_MIN_ENABLED_DESC',
  CompanyChannelsMinCreatedAtAsc = 'COMPANY_CHANNELS_MIN_CREATED_AT_ASC',
  CompanyChannelsMinCreatedAtDesc = 'COMPANY_CHANNELS_MIN_CREATED_AT_DESC',
  CompanyChannelsMinUpdatedAtAsc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_ASC',
  CompanyChannelsMinUpdatedAtDesc = 'COMPANY_CHANNELS_MIN_UPDATED_AT_DESC',
  CompanyChannelsMaxIdAsc = 'COMPANY_CHANNELS_MAX_ID_ASC',
  CompanyChannelsMaxIdDesc = 'COMPANY_CHANNELS_MAX_ID_DESC',
  CompanyChannelsMaxWidgetIdAsc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_ASC',
  CompanyChannelsMaxWidgetIdDesc = 'COMPANY_CHANNELS_MAX_WIDGET_ID_DESC',
  CompanyChannelsMaxChannelIdAsc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_ASC',
  CompanyChannelsMaxChannelIdDesc = 'COMPANY_CHANNELS_MAX_CHANNEL_ID_DESC',
  CompanyChannelsMaxCompanyIdAsc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_ASC',
  CompanyChannelsMaxCompanyIdDesc = 'COMPANY_CHANNELS_MAX_COMPANY_ID_DESC',
  CompanyChannelsMaxEnabledAsc = 'COMPANY_CHANNELS_MAX_ENABLED_ASC',
  CompanyChannelsMaxEnabledDesc = 'COMPANY_CHANNELS_MAX_ENABLED_DESC',
  CompanyChannelsMaxCreatedAtAsc = 'COMPANY_CHANNELS_MAX_CREATED_AT_ASC',
  CompanyChannelsMaxCreatedAtDesc = 'COMPANY_CHANNELS_MAX_CREATED_AT_DESC',
  CompanyChannelsMaxUpdatedAtAsc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_ASC',
  CompanyChannelsMaxUpdatedAtDesc = 'COMPANY_CHANNELS_MAX_UPDATED_AT_DESC',
  CompanyChannelsAverageIdAsc = 'COMPANY_CHANNELS_AVERAGE_ID_ASC',
  CompanyChannelsAverageIdDesc = 'COMPANY_CHANNELS_AVERAGE_ID_DESC',
  CompanyChannelsAverageWidgetIdAsc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_ASC',
  CompanyChannelsAverageWidgetIdDesc = 'COMPANY_CHANNELS_AVERAGE_WIDGET_ID_DESC',
  CompanyChannelsAverageChannelIdAsc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_ASC',
  CompanyChannelsAverageChannelIdDesc = 'COMPANY_CHANNELS_AVERAGE_CHANNEL_ID_DESC',
  CompanyChannelsAverageCompanyIdAsc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_ASC',
  CompanyChannelsAverageCompanyIdDesc = 'COMPANY_CHANNELS_AVERAGE_COMPANY_ID_DESC',
  CompanyChannelsAverageEnabledAsc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_ASC',
  CompanyChannelsAverageEnabledDesc = 'COMPANY_CHANNELS_AVERAGE_ENABLED_DESC',
  CompanyChannelsAverageCreatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_ASC',
  CompanyChannelsAverageCreatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_CREATED_AT_DESC',
  CompanyChannelsAverageUpdatedAtAsc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_ASC',
  CompanyChannelsAverageUpdatedAtDesc = 'COMPANY_CHANNELS_AVERAGE_UPDATED_AT_DESC',
  CompanyChannelsStddevSampleIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_ASC',
  CompanyChannelsStddevSampleIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ID_DESC',
  CompanyChannelsStddevSampleWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsStddevSampleWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsStddevSampleChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsStddevSampleChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsStddevSampleCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsStddevSampleCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsStddevSampleEnabledAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyChannelsStddevSampleEnabledDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyChannelsStddevSampleCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsStddevSampleCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsStddevSampleUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsStddevSampleUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsStddevPopulationIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_ASC',
  CompanyChannelsStddevPopulationIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ID_DESC',
  CompanyChannelsStddevPopulationWidgetIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsStddevPopulationWidgetIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsStddevPopulationChannelIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsStddevPopulationChannelIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsStddevPopulationCompanyIdAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsStddevPopulationCompanyIdDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsStddevPopulationEnabledAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyChannelsStddevPopulationEnabledDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyChannelsStddevPopulationCreatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyChannelsStddevPopulationCreatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyChannelsStddevPopulationUpdatedAtAsc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsStddevPopulationUpdatedAtDesc = 'COMPANY_CHANNELS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyChannelsVarianceSampleIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_ASC',
  CompanyChannelsVarianceSampleIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ID_DESC',
  CompanyChannelsVarianceSampleWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyChannelsVarianceSampleWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyChannelsVarianceSampleChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  CompanyChannelsVarianceSampleChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  CompanyChannelsVarianceSampleCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyChannelsVarianceSampleCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyChannelsVarianceSampleEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyChannelsVarianceSampleEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyChannelsVarianceSampleCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyChannelsVarianceSampleCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyChannelsVarianceSampleUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyChannelsVarianceSampleUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyChannelsVariancePopulationIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_ASC',
  CompanyChannelsVariancePopulationIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ID_DESC',
  CompanyChannelsVariancePopulationWidgetIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyChannelsVariancePopulationWidgetIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyChannelsVariancePopulationChannelIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  CompanyChannelsVariancePopulationChannelIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  CompanyChannelsVariancePopulationCompanyIdAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyChannelsVariancePopulationCompanyIdDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyChannelsVariancePopulationEnabledAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyChannelsVariancePopulationEnabledDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyChannelsVariancePopulationCreatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyChannelsVariancePopulationCreatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyChannelsVariancePopulationUpdatedAtAsc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyChannelsVariancePopulationUpdatedAtDesc = 'COMPANY_CHANNELS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type Company = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  archived: Scalars['Boolean'];
  zohoId: Scalars['String'];
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup: Scalars['Int'];
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** Reads and enables pagination through a set of `Lead`. */
  leads: LeadsConnection;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups: CompanyGroupsConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
  /** Reads and enables pagination through a set of `CompanyLocation`. */
  companyLocations: CompanyLocationsConnection;
  /** Reads and enables pagination through a set of `CompanyAnalytic`. */
  companyAnalytics: CompanyAnalyticsConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomains: CompanyDomainsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApproveds: CompanyDomainsApprovedsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths: CompanyDomainsPathsConnection;
  /** Reads and enables pagination through a set of `Review`. */
  reviews: ReviewsConnection;
  /** Reads and enables pagination through a set of `Analytic`. */
  analytics: AnalyticsConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets: WidgetsConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByCompanyProductCompanyIdAndProductId: CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomainsByCompanyDomainsApprovedCompanyIdAndDomainId: CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomainsByCompanyDomainsPathCompanyIdAndDomainId: CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByCompanyIntegrationCompanyIdAndIntegrationId: CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByCompanyChannelCompanyIdAndChannelId: CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyIdAndCompanyProductId: CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyIdAndCompanyChannelId: CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyIdAndCompanyIntegrationId: CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyIdAndConfig: CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnection;
  meta?: Maybe<CompanyMeta>;
  requestedAccess?: Maybe<Array<CompanyRequestedAccess>>;
};


export type CompanyLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type CompanyCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


export type CompanyCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyCompanyLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
  condition?: Maybe<CompanyLocationCondition>;
  filter?: Maybe<CompanyLocationFilter>;
};


export type CompanyCompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
  condition?: Maybe<CompanyAnalyticCondition>;
  filter?: Maybe<CompanyAnalyticFilter>;
};


export type CompanyCompanyDomainsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyCompanyDomainsApprovedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


export type CompanyCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


export type CompanyReviewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
  condition?: Maybe<ReviewCondition>;
  filter?: Maybe<ReviewFilter>;
};


export type CompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
  condition?: Maybe<AnalyticCondition>;
  filter?: Maybe<AnalyticFilter>;
};


export type CompanyCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


export type CompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<WidgetFilter>;
};


export type CompanyProductsByCompanyProductCompanyIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyFieldConfigsByFieldCompanyIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type CompanyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyVariancePopulationAggregates>;
};

export type CompanyAnalytic = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
};

export type CompanyAnalyticAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyAnalyticSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyAnalyticDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyAnalyticMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyAnalyticMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyAnalyticAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyAnalyticStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyAnalyticStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyAnalyticVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyAnalyticVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyAnalytic` object types. */
export type CompanyAnalyticAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyAnalytic` object to be included within the aggregate. */
  filter?: Maybe<CompanyAnalyticFilter>;
  /** Sum aggregate over matching `CompanyAnalytic` objects. */
  sum?: Maybe<CompanyAnalyticSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyAnalytic` objects. */
  distinctCount?: Maybe<CompanyAnalyticDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyAnalytic` objects. */
  min?: Maybe<CompanyAnalyticMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyAnalytic` objects. */
  max?: Maybe<CompanyAnalyticMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyAnalytic` objects. */
  average?: Maybe<CompanyAnalyticAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyAnalytic` objects. */
  stddevSample?: Maybe<CompanyAnalyticStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyAnalytic` objects. */
  stddevPopulation?: Maybe<CompanyAnalyticStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyAnalytic` objects. */
  varianceSample?: Maybe<CompanyAnalyticVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyAnalytic` objects. */
  variancePopulation?: Maybe<CompanyAnalyticVariancePopulationAggregateFilter>;
};

export type CompanyAnalyticAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyAnalytic` to look up the row to connect. */
export type CompanyAnalyticCompanyAnalyticsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyAnalytic` to look up the row to delete. */
export type CompanyAnalyticCompanyAnalyticsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyAnalytic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyAnalyticCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<AnalyticsType>;
  /** Checks for equality with the object’s `analyticsId` field. */
  analyticsId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `analyticsSecret` field. */
  analyticsSecret?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomains?: Maybe<Scalars['String']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomainOnly?: Maybe<Scalars['String']>;
};

export type CompanyAnalyticDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  analyticsId?: Maybe<BigIntFilter>;
  analyticsSecret?: Maybe<BigIntFilter>;
  domain?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyAnalyticDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of analyticsId across the matching connection */
  analyticsId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of analyticsSecret across the matching connection */
  analyticsSecret?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyAnalytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyAnalyticFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<AnalyticsTypeFilter>;
  /** Filter by the object’s `analyticsId` field. */
  analyticsId?: Maybe<StringFilter>;
  /** Filter by the object’s `analyticsSecret` field. */
  analyticsSecret?: Maybe<StringFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyAnalyticFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyAnalyticFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyAnalyticFilter>;
};

/** An input for mutations affecting `CompanyAnalytic` */
export type CompanyAnalyticInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

export type CompanyAnalyticMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyAnalyticMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyAnalyticMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyAnalyticMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyAnalyticNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyAnalyticNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyAnalytic` to look up the row to update. */
export type CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyAnalyticsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyAnalytic` being updated. */
  patch: UpdateCompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyAnalytic`. Fields that are set will be updated. */
export type CompanyAnalyticPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type?: Maybe<AnalyticsType>;
  analyticsId?: Maybe<Scalars['String']>;
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

export type CompanyAnalyticStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyAnalyticSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

export type CompanyAnalyticVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyAnalyticVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyAnalyticVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyAnalytic` values. */
export type CompanyAnalyticsConnection = {
  /** A list of `CompanyAnalytic` objects. */
  nodes: Array<CompanyAnalytic>;
  /** A list of edges which contains the `CompanyAnalytic` and cursor to aid in pagination. */
  edges: Array<CompanyAnalyticsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyAnalytic` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAnalyticAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAnalyticAggregates>>;
};


/** A connection to a list of `CompanyAnalytic` values. */
export type CompanyAnalyticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyAnalyticsGroupBy>;
  having?: Maybe<CompanyAnalyticsHavingInput>;
};

/** A `CompanyAnalytic` edge in the connection. */
export type CompanyAnalyticsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyAnalytic` at the end of the edge. */
  node: CompanyAnalytic;
};

/** Grouping methods for `CompanyAnalytic` for usage during aggregation. */
export enum CompanyAnalyticsGroupBy {
  CompanyId = 'COMPANY_ID',
  Type = 'TYPE',
  AnalyticsId = 'ANALYTICS_ID',
  AnalyticsSecret = 'ANALYTICS_SECRET',
  Domain = 'DOMAIN',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyAnalyticsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyAnalytic` aggregates. */
export type CompanyAnalyticsHavingInput = {
  AND?: Maybe<Array<CompanyAnalyticsHavingInput>>;
  OR?: Maybe<Array<CompanyAnalyticsHavingInput>>;
  sum?: Maybe<CompanyAnalyticsHavingSumInput>;
  distinctCount?: Maybe<CompanyAnalyticsHavingDistinctCountInput>;
  min?: Maybe<CompanyAnalyticsHavingMinInput>;
  max?: Maybe<CompanyAnalyticsHavingMaxInput>;
  average?: Maybe<CompanyAnalyticsHavingAverageInput>;
  stddevSample?: Maybe<CompanyAnalyticsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyAnalyticsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyAnalyticsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyAnalyticsHavingVariancePopulationInput>;
};

export type CompanyAnalyticsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyAnalyticsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyAnalytic`. */
export enum CompanyAnalyticsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  AnalyticsIdAsc = 'ANALYTICS_ID_ASC',
  AnalyticsIdDesc = 'ANALYTICS_ID_DESC',
  AnalyticsSecretAsc = 'ANALYTICS_SECRET_ASC',
  AnalyticsSecretDesc = 'ANALYTICS_SECRET_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Mean average of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannel = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyChannelIdAndCompanyId: CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyChannelIdAndCompanyProductId: CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationId: CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyChannelIdAndConfig: CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnection;
};


export type CompanyChannelFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type CompanyChannelAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyChannelSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyChannelDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyChannelMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyChannelMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyChannelAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyChannelStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyChannelStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyChannelVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyChannelVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyChannel` object types. */
export type CompanyChannelAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyChannel` object to be included within the aggregate. */
  filter?: Maybe<CompanyChannelFilter>;
  /** Sum aggregate over matching `CompanyChannel` objects. */
  sum?: Maybe<CompanyChannelSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyChannel` objects. */
  distinctCount?: Maybe<CompanyChannelDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyChannel` objects. */
  min?: Maybe<CompanyChannelMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyChannel` objects. */
  max?: Maybe<CompanyChannelMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyChannel` objects. */
  average?: Maybe<CompanyChannelAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyChannel` objects. */
  stddevSample?: Maybe<CompanyChannelStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyChannel` objects. */
  stddevPopulation?: Maybe<CompanyChannelStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyChannel` objects. */
  varianceSample?: Maybe<CompanyChannelVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyChannel` objects. */
  variancePopulation?: Maybe<CompanyChannelVariancePopulationAggregateFilter>;
};

export type CompanyChannelAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** The `channel` to be created by this mutation. */
export type CompanyChannelChannelIdFkeyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** The `companyChannel` to be created by this mutation. */
export type CompanyChannelChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** Input for the nested mutation of `channel` in the `CompanyChannelInput` mutation. */
export type CompanyChannelChannelIdFkeyInput = {
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectById?: Maybe<ChannelChannelPkeyConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectByNodeId?: Maybe<ChannelNodeIdConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteById?: Maybe<ChannelChannelPkeyDelete>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ChannelNodeIdDelete>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateById?: Maybe<ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate>;
  /** A `ChannelInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyChannelChannelIdFkeyChannelCreateInput>;
};

/** Input for the nested mutation of `companyChannel` in the `ChannelInput` mutation. */
export type CompanyChannelChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyChannel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelCompanyChannelPkeyConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelNodeIdConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyChannelCompanyChannelPkeyDelete>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyChannelNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingCompanyChannelPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelChannelIdFkeyCompanyChannelCreateInput>>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyChannelCompaniesByFieldCompanyChannelIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The fields on `companyChannel` to look up the row to connect. */
export type CompanyChannelCompanyChannelPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyChannel` to look up the row to delete. */
export type CompanyChannelCompanyChannelPkeyDelete = {
  id: Scalars['Int'];
};

/** The `companyChannel` to be created by this mutation. */
export type CompanyChannelCompanyIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** The `company` to be created by this mutation. */
export type CompanyChannelCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyChannelInput` mutation. */
export type CompanyChannelCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyChannelCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyChannel` in the `CompanyInput` mutation. */
export type CompanyChannelCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyChannel` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyChannelCompanyChannelPkeyConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyChannelNodeIdConnect>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyChannelCompanyChannelPkeyDelete>>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyChannelNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyChannelPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyChannelCompanyIdFkeyCompanyChannelCreateInput>>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyIntegrationsByFieldCompanyChannelIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyChannelCompanyProductsByFieldCompanyChannelIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/**
 * A condition to be used against `CompanyChannel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyChannelCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `channelId` field. */
  channelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyChannelDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyChannelDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyChannelFieldConfigsByFieldCompanyChannelIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `channelId` field. */
  channelId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyChannelToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `channel` relation. */
  channel?: Maybe<ChannelFilter>;
  /** A related `channel` exists. */
  channelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyChannelFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyChannelFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyChannelFilter>;
};

/** Grouping methods for `CompanyChannel` for usage during aggregation. */
export enum CompanyChannelGroupBy {
  ChannelId = 'CHANNEL_ID',
  CompanyId = 'COMPANY_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyChannelHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyChannel` aggregates. */
export type CompanyChannelHavingInput = {
  AND?: Maybe<Array<CompanyChannelHavingInput>>;
  OR?: Maybe<Array<CompanyChannelHavingInput>>;
  sum?: Maybe<CompanyChannelHavingSumInput>;
  distinctCount?: Maybe<CompanyChannelHavingDistinctCountInput>;
  min?: Maybe<CompanyChannelHavingMinInput>;
  max?: Maybe<CompanyChannelHavingMaxInput>;
  average?: Maybe<CompanyChannelHavingAverageInput>;
  stddevSample?: Maybe<CompanyChannelHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyChannelHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyChannelHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyChannelHavingVariancePopulationInput>;
};

export type CompanyChannelHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyChannelHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyChannel` */
export type CompanyChannelInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

export type CompanyChannelMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyChannelMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyChannelMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyChannelMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyChannelNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyChannelNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `channel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: ChannelPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyChannelOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyChannel` to look up the row to update. */
export type CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: UpdateCompanyChannelOnFieldForFieldCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyChannel`. Fields that are set will be updated. */
export type CompanyChannelPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

export type CompanyChannelStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyChannelSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of channelId across the matching connection */
  channelId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyChannelToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

export type CompanyChannelVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyChannelVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyChannelVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel`, info from the `CompanyChannel`, and the cursor to aid in pagination. */
  edges: Array<CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels: CompanyChannelsConnection;
};


/** A `Channel` edge in the connection, with data from `CompanyChannel`. */
export type CompanyChannelsByCompanyChannelCompanyIdAndChannelIdManyToManyEdgeCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};

/** A connection to a list of `CompanyChannel` values. */
export type CompanyChannelsConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel` and cursor to aid in pagination. */
  edges: Array<CompanyChannelsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values. */
export type CompanyChannelsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection. */
export type CompanyChannelsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
};

/** Methods to use when ordering `CompanyChannel`. */
export enum CompanyChannelsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  ChannelIdAsc = 'CHANNEL_ID_ASC',
  ChannelIdDesc = 'CHANNEL_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyCompanyChannelsByFieldCompanyIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain`, info from the `CompanyDomainsApproved`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsApproved`. */
export type CompanyCompanyDomainsByCompanyDomainsApprovedCompanyIdAndDomainIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain`, info from the `CompanyDomainsPath`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPathsByDomainId: CompanyDomainsPathsConnection;
};


/** A `CompanyDomain` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyCompanyDomainsByCompanyDomainsPathCompanyIdAndDomainIdManyToManyEdgeCompanyDomainsPathsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyExternalIdKeyConnect = {
  externalId: Scalars['String'];
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyCompanyIntegrationsByFieldCompanyIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyNameKeyConnect = {
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to connect. */
export type CompanyCompanyPrimaryGroupKeyConnect = {
  primaryGroup: Scalars['Int'];
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyCompanyProductsByFieldCompanyIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `equifaxCustomerNumber` field. */
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `securityCode` field. */
  securityCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `externalId` field. */
  externalId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `archived` field. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `zohoId` field. */
  zohoId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deliverTo` field. */
  deliverTo?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `primaryGroup` field. */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawMeta` field. */
  rawMeta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `billableCompanyId` field. */
  billableCompanyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawRequestedAccess` field. */
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `lenderDealerCode` field. */
  lenderDealerCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `googlePlacesId` field. */
  googlePlacesId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `legalConsent` field. */
  legalConsent?: Maybe<Scalars['Boolean']>;
};

export type CompanyCta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
};

export type CompanyCtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyCtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyCtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyCtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyCtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyCtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyCtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyCtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyCtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyCtaVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyCta` object types. */
export type CompanyCtaAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyCta` object to be included within the aggregate. */
  filter?: Maybe<CompanyCtaFilter>;
  /** Sum aggregate over matching `CompanyCta` objects. */
  sum?: Maybe<CompanyCtaSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyCta` objects. */
  distinctCount?: Maybe<CompanyCtaDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyCta` objects. */
  min?: Maybe<CompanyCtaMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyCta` objects. */
  max?: Maybe<CompanyCtaMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyCta` objects. */
  average?: Maybe<CompanyCtaAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyCta` objects. */
  stddevSample?: Maybe<CompanyCtaStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyCta` objects. */
  stddevPopulation?: Maybe<CompanyCtaStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyCta` objects. */
  varianceSample?: Maybe<CompanyCtaVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyCta` objects. */
  variancePopulation?: Maybe<CompanyCtaVariancePopulationAggregateFilter>;
};

export type CompanyCtaAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyCta` to look up the row to connect. */
export type CompanyCtaCompanyCtasPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyCta` to look up the row to delete. */
export type CompanyCtaCompanyCtasPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyCta` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CompanyCtaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaId` field. */
  ctaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `theme` field. */
  theme?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locked` field. */
  locked?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `default` field. */
  default?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `templateDef` field. */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeDef` field. */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaDetails` field. */
  ctaDetails?: Maybe<Scalars['JSON']>;
};

export type CompanyCtaDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  ctaId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  theme?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  locked?: Maybe<BigIntFilter>;
  default?: Maybe<BigIntFilter>;
  templateDef?: Maybe<BigIntFilter>;
  themeDef?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  compatibleMajor?: Maybe<BigIntFilter>;
  ctaDetails?: Maybe<BigIntFilter>;
};

export type CompanyCtaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaId across the matching connection */
  ctaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of theme across the matching connection */
  theme?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of locked across the matching connection */
  locked?: Maybe<Scalars['BigInt']>;
  /** Distinct count of default across the matching connection */
  default?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateDef across the matching connection */
  templateDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeDef across the matching connection */
  themeDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaDetails across the matching connection */
  ctaDetails?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type CompanyCtaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaId` field. */
  ctaId?: Maybe<StringFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `theme` field. */
  theme?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `locked` field. */
  locked?: Maybe<BooleanFilter>;
  /** Filter by the object’s `default` field. */
  default?: Maybe<BooleanFilter>;
  /** Filter by the object’s `templateDef` field. */
  templateDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeDef` field. */
  themeDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaDetails` field. */
  ctaDetails?: Maybe<JsonFilter>;
  /** Filter by the object’s `cta` relation. */
  cta?: Maybe<CtaFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyCtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyCtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyCtaFilter>;
};

/** An input for mutations affecting `CompanyCta` */
export type CompanyCtaInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

export type CompanyCtaMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  compatibleMajor?: Maybe<IntFilter>;
};

export type CompanyCtaMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Maximum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

export type CompanyCtaMeta = {
  theme?: Maybe<Scalars['String']>;
};

export type CompanyCtaMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
  compatibleMajor?: Maybe<IntFilter>;
};

export type CompanyCtaMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Minimum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyCtaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyCtaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: CtaPatch;
};

/** The fields on `companyCta` to look up the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCompanyCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: UpdateCompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyCta` to look up the row to update. */
export type CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyUsingCompanyCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: UpdateCompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  id: Scalars['Int'];
};

export type CompanyCtaOverrides = {
  theme: Scalars['String'];
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
};

/** Represents an update to a `CompanyCta`. Fields that are set will be updated. */
export type CompanyCtaPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

export type CompanyCtaStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  compatibleMajor?: Maybe<BigIntFilter>;
};

export type CompanyCtaSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
  /** Sum of compatibleMajor across the matching connection */
  compatibleMajor: Scalars['BigInt'];
};

export type CompanyCtaVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CompanyCtaVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
  compatibleMajor?: Maybe<BigFloatFilter>;
};

export type CompanyCtaVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyCta` values. */
export type CompanyCtasConnection = {
  /** A list of `CompanyCta` objects. */
  nodes: Array<CompanyCta>;
  /** A list of edges which contains the `CompanyCta` and cursor to aid in pagination. */
  edges: Array<CompanyCtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyCta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyCtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyCtaAggregates>>;
};


/** A connection to a list of `CompanyCta` values. */
export type CompanyCtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyCtasGroupBy>;
  having?: Maybe<CompanyCtasHavingInput>;
};

/** The `companyCta` to be created by this mutation. */
export type CompanyCtasCtaIdFkeyCompanyCtasCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** The `cta` to be created by this mutation. */
export type CompanyCtasCtaIdFkeyCtasCreateInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** Input for the nested mutation of `cta` in the `CompanyCtaInput` mutation. */
export type CompanyCtasCtaIdFkeyInput = {
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectById?: Maybe<CtaCtasPkeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByNodeId?: Maybe<CtaNodeIdConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteById?: Maybe<CtaCtasPkeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CtaNodeIdDelete>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateById?: Maybe<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasPkeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByProductAndTypeAndVersion?: Maybe<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasProductTypeVersionKeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate>;
  /** A `CtaInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyCtasCtaIdFkeyCtasCreateInput>;
};

/** Input for the nested mutation of `companyCta` in the `CtaInput` mutation. */
export type CompanyCtasCtaIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyCta` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCtaCompanyCtasPkeyConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyCtaNodeIdConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyCtaCompanyCtasPkeyDelete>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyCtaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCompanyCtasPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate>>;
  /** A `CompanyCtaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyCtasCtaIdFkeyCompanyCtasCreateInput>>;
};

/** A `CompanyCta` edge in the connection. */
export type CompanyCtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyCta` at the end of the edge. */
  node: CompanyCta;
};

/** Grouping methods for `CompanyCta` for usage during aggregation. */
export enum CompanyCtasGroupBy {
  CtaId = 'CTA_ID',
  GroupId = 'GROUP_ID',
  Theme = 'THEME',
  Name = 'NAME',
  Locked = 'LOCKED',
  Default = 'DEFAULT',
  TemplateDef = 'TEMPLATE_DEF',
  ThemeDef = 'THEME_DEF',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CompatibleMajor = 'COMPATIBLE_MAJOR',
  CtaDetails = 'CTA_DETAILS'
}

/** The `companyCta` to be created by this mutation. */
export type CompanyCtasGroupIdFkeyCompanyCtasCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyCtasGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyCtaInput` mutation. */
export type CompanyCtasGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyCtasGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `companyCta` in the `GroupInput` mutation. */
export type CompanyCtasGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyCta` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyCtaCompanyCtasPkeyConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyCtaNodeIdConnect>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyCtaCompanyCtasPkeyDelete>>;
  /** The primary key(s) for `companyCta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyCtaNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyUsingCompanyCtasPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyCta` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate>>;
  /** A `CompanyCtaInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyCtasGroupIdFkeyCompanyCtasCreateInput>>;
};

export type CompanyCtasHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Conditions for `CompanyCta` aggregates. */
export type CompanyCtasHavingInput = {
  AND?: Maybe<Array<CompanyCtasHavingInput>>;
  OR?: Maybe<Array<CompanyCtasHavingInput>>;
  sum?: Maybe<CompanyCtasHavingSumInput>;
  distinctCount?: Maybe<CompanyCtasHavingDistinctCountInput>;
  min?: Maybe<CompanyCtasHavingMinInput>;
  max?: Maybe<CompanyCtasHavingMaxInput>;
  average?: Maybe<CompanyCtasHavingAverageInput>;
  stddevSample?: Maybe<CompanyCtasHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyCtasHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyCtasHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyCtasHavingVariancePopulationInput>;
};

export type CompanyCtasHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CompanyCtasHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `CompanyCta`. */
export enum CompanyCtasOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CtaIdAsc = 'CTA_ID_ASC',
  CtaIdDesc = 'CTA_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  ThemeAsc = 'THEME_ASC',
  ThemeDesc = 'THEME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LockedAsc = 'LOCKED_ASC',
  LockedDesc = 'LOCKED_DESC',
  DefaultAsc = 'DEFAULT_ASC',
  DefaultDesc = 'DEFAULT_DESC',
  TemplateDefAsc = 'TEMPLATE_DEF_ASC',
  TemplateDefDesc = 'TEMPLATE_DEF_DESC',
  ThemeDefAsc = 'THEME_DEF_ASC',
  ThemeDefDesc = 'THEME_DEF_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CompatibleMajorAsc = 'COMPATIBLE_MAJOR_ASC',
  CompatibleMajorDesc = 'COMPATIBLE_MAJOR_DESC',
  CtaDetailsAsc = 'CTA_DETAILS_ASC',
  CtaDetailsDesc = 'CTA_DETAILS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxCustomerNumber across the matching connection */
  equifaxCustomerNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of securityCode across the matching connection */
  securityCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalId across the matching connection */
  externalId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of archived across the matching connection */
  archived?: Maybe<Scalars['BigInt']>;
  /** Distinct count of zohoId across the matching connection */
  zohoId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deliverTo across the matching connection */
  deliverTo?: Maybe<Scalars['BigInt']>;
  /** Distinct count of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawMeta across the matching connection */
  rawMeta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawRequestedAccess across the matching connection */
  rawRequestedAccess?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderDealerCode across the matching connection */
  lenderDealerCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of googlePlacesId across the matching connection */
  googlePlacesId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of legalConsent across the matching connection */
  legalConsent?: Maybe<Scalars['BigInt']>;
};

export type CompanyDomain = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApprovedsByDomainId: CompanyDomainsApprovedsConnection;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPathsByDomainId: CompanyDomainsPathsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyDomainsApprovedDomainIdAndCompanyId: CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyDomainsPathDomainIdAndCompanyId: CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnection;
};


export type CompanyDomainCompanyDomainsApprovedsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


export type CompanyDomainCompanyDomainsPathsByDomainIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};

export type CompanyDomainAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomain` object types. */
export type CompanyDomainAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomain` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainFilter>;
  /** Sum aggregate over matching `CompanyDomain` objects. */
  sum?: Maybe<CompanyDomainSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomain` objects. */
  distinctCount?: Maybe<CompanyDomainDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomain` objects. */
  min?: Maybe<CompanyDomainMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomain` objects. */
  max?: Maybe<CompanyDomainMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomain` objects. */
  average?: Maybe<CompanyDomainAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomain` objects. */
  stddevSample?: Maybe<CompanyDomainStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomain` objects. */
  stddevPopulation?: Maybe<CompanyDomainStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomain` objects. */
  varianceSample?: Maybe<CompanyDomainVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomain` objects. */
  variancePopulation?: Maybe<CompanyDomainVariancePopulationAggregateFilter>;
};

export type CompanyDomainAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyDomainsApproved`, and the cursor to aid in pagination. */
  edges: Array<CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyDomainsApproved`. */
export type CompanyDomainCompaniesByCompanyDomainsApprovedDomainIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `Company` values, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyDomainsPath`, and the cursor to aid in pagination. */
  edges: Array<CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths: CompanyDomainsPathsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyDomainsPath`. */
export type CompanyDomainCompaniesByCompanyDomainsPathDomainIdAndCompanyIdManyToManyEdgeCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};

/** The fields on `companyDomain` to look up the row to connect. */
export type CompanyDomainCompanyDomainsDomainKeyConnect = {
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to delete. */
export type CompanyDomainCompanyDomainsDomainKeyDelete = {
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to connect. */
export type CompanyDomainCompanyDomainsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyDomain` to look up the row to delete. */
export type CompanyDomainCompanyDomainsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyDomain` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomains?: Maybe<Scalars['String']>;
  /** The source url to find the most relevant domain match for */
  mostRelevantDomainOnly?: Maybe<Scalars['String']>;
};

export type CompanyDomainDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domain?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyDomain` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyDomainsApprovedsByDomainId` relation. */
  companyDomainsApprovedsByDomainId?: Maybe<CompanyDomainToManyCompanyDomainsApprovedFilter>;
  /** Some related `companyDomainsApprovedsByDomainId` exist. */
  companyDomainsApprovedsByDomainIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsPathsByDomainId` relation. */
  companyDomainsPathsByDomainId?: Maybe<CompanyDomainToManyCompanyDomainsPathFilter>;
  /** Some related `companyDomainsPathsByDomainId` exist. */
  companyDomainsPathsByDomainIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainFilter>;
};

/** An input for mutations affecting `CompanyDomain` */
export type CompanyDomainInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

export type CompanyDomainMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyDomainMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyDomainMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  domain: Scalars['String'];
};

/** The fields on `companyDomain` to look up the row to update. */
export type CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyDomain`. Fields that are set will be updated. */
export type CompanyDomainPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

export type CompanyDomainStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyDomainSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainToManyCompanyDomainsApprovedFilter = {
  /** Every related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsApprovedFilter>;
  /** Some related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsApprovedFilter>;
  /** No related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsApprovedFilter>;
  /** Aggregates across related `CompanyDomainsApproved` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsApprovedAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainToManyCompanyDomainsPathFilter = {
  /** Every related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsPathFilter>;
  /** Some related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsPathFilter>;
  /** No related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsPathFilter>;
  /** Aggregates across related `CompanyDomainsPath` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsPathAggregatesFilter>;
};

export type CompanyDomainVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApproved = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
};

export type CompanyDomainsApprovedAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainsApprovedSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainsApprovedDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainsApprovedMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainsApprovedMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainsApprovedAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainsApprovedStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainsApprovedStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainsApprovedVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainsApprovedVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomainsApproved` object types. */
export type CompanyDomainsApprovedAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomainsApproved` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainsApprovedFilter>;
  /** Sum aggregate over matching `CompanyDomainsApproved` objects. */
  sum?: Maybe<CompanyDomainsApprovedSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomainsApproved` objects. */
  distinctCount?: Maybe<CompanyDomainsApprovedDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomainsApproved` objects. */
  min?: Maybe<CompanyDomainsApprovedMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomainsApproved` objects. */
  max?: Maybe<CompanyDomainsApprovedMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomainsApproved` objects. */
  average?: Maybe<CompanyDomainsApprovedAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomainsApproved` objects. */
  stddevSample?: Maybe<CompanyDomainsApprovedStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomainsApproved` objects. */
  stddevPopulation?: Maybe<CompanyDomainsApprovedStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomainsApproved` objects. */
  varianceSample?: Maybe<CompanyDomainsApprovedVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomainsApproved` objects. */
  variancePopulation?: Maybe<CompanyDomainsApprovedVariancePopulationAggregateFilter>;
};

export type CompanyDomainsApprovedAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyDomainsApproved` to look up the row to connect. */
export type CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The fields on `companyDomainsApproved` to look up the row to delete. */
export type CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyDomainsApproved` to be created by this mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyCompanyDomainsApprovedCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainsApprovedInput` mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsApprovedCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomainsApproved` in the `CompanyInput` mutation. */
export type CompanyDomainsApprovedCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsApproved` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyDomainsApprovedPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsApprovedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsApprovedCompanyIdFkeyCompanyDomainsApprovedCreateInput>>;
};

/**
 * A condition to be used against `CompanyDomainsApproved` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainsApprovedCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domainId` field. */
  domainId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyDomainsApprovedDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainsApprovedDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `companyDomainsApproved` to be created by this mutation. */
export type CompanyDomainsApprovedDomainIdFkeyCompanyDomainsApprovedCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsApprovedDomainIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyDomainsApprovedInput` mutation. */
export type CompanyDomainsApprovedDomainIdFkeyInput = {
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<CompanyDomainCompanyDomainsPkeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyDomainNodeIdConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<CompanyDomainCompanyDomainsPkeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyDomainNodeIdDelete>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsPkeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsApprovedDomainIdFkeyCompanyDomainsCreateInput>;
};

/** Input for the nested mutation of `companyDomainsApproved` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsApprovedDomainIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsApproved` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedCompanyDomainsApprovedPkeyDelete>>;
  /** The primary key(s) for `companyDomainsApproved` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsApprovedNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByCompanyIdAndDomainId?: Maybe<Array<CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsApprovedPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsApproved` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsApprovedInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsApprovedDomainIdFkeyCompanyDomainsApprovedCreateInput>>;
};

/** A filter to be used against `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainsApprovedFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domainId` field. */
  domainId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `domain` relation. */
  domain?: Maybe<CompanyDomainFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainsApprovedFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainsApprovedFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainsApprovedFilter>;
};

/** Grouping methods for `CompanyDomainsApproved` for usage during aggregation. */
export enum CompanyDomainsApprovedGroupBy {
  CompanyId = 'COMPANY_ID',
  DomainId = 'DOMAIN_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsApprovedHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomainsApproved` aggregates. */
export type CompanyDomainsApprovedHavingInput = {
  AND?: Maybe<Array<CompanyDomainsApprovedHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsApprovedHavingInput>>;
  sum?: Maybe<CompanyDomainsApprovedHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsApprovedHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsApprovedHavingMinInput>;
  max?: Maybe<CompanyDomainsApprovedHavingMaxInput>;
  average?: Maybe<CompanyDomainsApprovedHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsApprovedHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsApprovedHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsApprovedHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsApprovedHavingVariancePopulationInput>;
};

export type CompanyDomainsApprovedHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsApprovedHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyDomainsApproved` */
export type CompanyDomainsApprovedInput = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

export type CompanyDomainsApprovedMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsApprovedMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainsApprovedMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsApprovedMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainsApprovedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainsApprovedNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomainsApproved` to look up the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyDomainsApprovedPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `companyDomainsApproved` to look up the row to update. */
export type CompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyUsingCompanyDomainsApprovedPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** Represents an update to a `CompanyDomainsApproved`. Fields that are set will be updated. */
export type CompanyDomainsApprovedPatch = {
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

export type CompanyDomainsApprovedStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
};

export type CompanyDomainsApprovedSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of domainId across the matching connection */
  domainId: Scalars['BigInt'];
};

export type CompanyDomainsApprovedVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsApprovedVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsApprovedVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyDomainsApproved` values. */
export type CompanyDomainsApprovedsConnection = {
  /** A list of `CompanyDomainsApproved` objects. */
  nodes: Array<CompanyDomainsApproved>;
  /** A list of edges which contains the `CompanyDomainsApproved` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsApprovedsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomainsApproved` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainsApprovedAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainsApprovedAggregates>>;
};


/** A connection to a list of `CompanyDomainsApproved` values. */
export type CompanyDomainsApprovedsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsApprovedGroupBy>;
  having?: Maybe<CompanyDomainsApprovedHavingInput>;
};

/** A `CompanyDomainsApproved` edge in the connection. */
export type CompanyDomainsApprovedsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomainsApproved` at the end of the edge. */
  node: CompanyDomainsApproved;
};

/** Methods to use when ordering `CompanyDomainsApproved`. */
export enum CompanyDomainsApprovedsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainIdAsc = 'DOMAIN_ID_ASC',
  DomainIdDesc = 'DOMAIN_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `company` to be created by this mutation. */
export type CompanyDomainsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsCompanyIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyInput` mutation. */
export type CompanyDomainsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomain` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainCompanyDomainsPkeyConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<Array<CompanyDomainCompanyDomainsDomainKeyConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainNodeIdConnect>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainCompanyDomainsPkeyDelete>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<Array<CompanyDomainCompanyDomainsDomainKeyDelete>>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<Array<CompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyDomainsDomainKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsCompanyIdFkeyCompanyDomainsCreateInput>>;
};

/** A connection to a list of `CompanyDomain` values. */
export type CompanyDomainsConnection = {
  /** A list of `CompanyDomain` objects. */
  nodes: Array<CompanyDomain>;
  /** A list of edges which contains the `CompanyDomain` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomain` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainAggregates>>;
};


/** A connection to a list of `CompanyDomain` values. */
export type CompanyDomainsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsGroupBy>;
  having?: Maybe<CompanyDomainsHavingInput>;
};

/** A `CompanyDomain` edge in the connection. */
export type CompanyDomainsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomain` at the end of the edge. */
  node: CompanyDomain;
};

/** Grouping methods for `CompanyDomain` for usage during aggregation. */
export enum CompanyDomainsGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomain` aggregates. */
export type CompanyDomainsHavingInput = {
  AND?: Maybe<Array<CompanyDomainsHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsHavingInput>>;
  sum?: Maybe<CompanyDomainsHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsHavingMinInput>;
  max?: Maybe<CompanyDomainsHavingMaxInput>;
  average?: Maybe<CompanyDomainsHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsHavingVariancePopulationInput>;
};

export type CompanyDomainsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyDomain`. */
export enum CompanyDomainsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyDomainsApprovedsByDomainIdCountAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_COUNT_ASC',
  CompanyDomainsApprovedsByDomainIdCountDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_COUNT_DESC',
  CompanyDomainsApprovedsByDomainIdSumCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdSumCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdSumDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdSumDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdSumCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdSumCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdSumUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdSumUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_SUM_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMinCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMinCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMinDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMinDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMinCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMinCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMinUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMinUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MIN_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMaxCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMaxCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMaxDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdMaxDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdMaxCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMaxCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdMaxUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdMaxUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_MAX_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdAverageCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdAverageCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdAverageDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdAverageDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdAverageCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdAverageCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdAverageUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdAverageUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsApprovedsByDomainIdVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_APPROVEDS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdCountAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_COUNT_ASC',
  CompanyDomainsPathsByDomainIdCountDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_COUNT_DESC',
  CompanyDomainsPathsByDomainIdSumIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_ID_ASC',
  CompanyDomainsPathsByDomainIdSumIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_ID_DESC',
  CompanyDomainsPathsByDomainIdSumCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdSumCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdSumDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdSumDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdSumPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_PATH_ASC',
  CompanyDomainsPathsByDomainIdSumPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_PATH_DESC',
  CompanyDomainsPathsByDomainIdSumCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdSumCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdSumUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdSumUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_SUM_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_PATH_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_PATH_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdDistinctCountUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdDistinctCountUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMinIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMinIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMinCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdMinCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdMinDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMinDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMinPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_PATH_ASC',
  CompanyDomainsPathsByDomainIdMinPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_PATH_DESC',
  CompanyDomainsPathsByDomainIdMinCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMinCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMinUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMinUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MIN_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMaxIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdMaxDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdMaxPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_PATH_ASC',
  CompanyDomainsPathsByDomainIdMaxPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_PATH_DESC',
  CompanyDomainsPathsByDomainIdMaxCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMaxCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdMaxUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdMaxUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_MAX_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdAverageIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_ID_DESC',
  CompanyDomainsPathsByDomainIdAverageCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdAverageDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdAverageDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdAveragePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_PATH_ASC',
  CompanyDomainsPathsByDomainIdAveragePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_PATH_DESC',
  CompanyDomainsPathsByDomainIdAverageCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdAverageCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdAverageUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdAverageUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_AVERAGE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevSamplePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_PATH_ASC',
  CompanyDomainsPathsByDomainIdStddevSamplePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_PATH_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_PATH_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_PATH_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdStddevPopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdStddevPopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdVarianceSamplePathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_PATH_ASC',
  CompanyDomainsPathsByDomainIdVarianceSamplePathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_PATH_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVarianceSampleUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVarianceSampleUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationCompanyIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationCompanyIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationDomainIdAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationDomainIdDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationPathAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_PATH_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationPathDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_PATH_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationCreatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationCreatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyDomainsPathsByDomainIdVariancePopulationUpdatedAtAsc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyDomainsPathsByDomainIdVariancePopulationUpdatedAtDesc = 'COMPANY_DOMAINS_PATHS_BY_DOMAIN_ID_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CompanyDomainsPath = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
};

export type CompanyDomainsPathAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyDomainsPathSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDomainsPathDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyDomainsPathMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyDomainsPathMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyDomainsPathAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyDomainsPathStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyDomainsPathStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyDomainsPathVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyDomainsPathVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyDomainsPath` object types. */
export type CompanyDomainsPathAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyDomainsPath` object to be included within the aggregate. */
  filter?: Maybe<CompanyDomainsPathFilter>;
  /** Sum aggregate over matching `CompanyDomainsPath` objects. */
  sum?: Maybe<CompanyDomainsPathSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyDomainsPath` objects. */
  distinctCount?: Maybe<CompanyDomainsPathDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyDomainsPath` objects. */
  min?: Maybe<CompanyDomainsPathMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyDomainsPath` objects. */
  max?: Maybe<CompanyDomainsPathMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyDomainsPath` objects. */
  average?: Maybe<CompanyDomainsPathAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyDomainsPath` objects. */
  stddevSample?: Maybe<CompanyDomainsPathStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyDomainsPath` objects. */
  stddevPopulation?: Maybe<CompanyDomainsPathStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyDomainsPath` objects. */
  varianceSample?: Maybe<CompanyDomainsPathVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyDomainsPath` objects. */
  variancePopulation?: Maybe<CompanyDomainsPathVariancePopulationAggregateFilter>;
};

export type CompanyDomainsPathAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyDomainsPath` to look up the row to connect. */
export type CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to delete. */
export type CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to connect. */
export type CompanyDomainsPathCompanyDomainsPathPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyDomainsPath` to look up the row to delete. */
export type CompanyDomainsPathCompanyDomainsPathPkeyDelete = {
  id: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyDomainsPathCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyDomainsPath` to be created by this mutation. */
export type CompanyDomainsPathCompanyIdFkeyCompanyDomainsPathCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyDomainsPathInput` mutation. */
export type CompanyDomainsPathCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsPathCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyDomainsPath` in the `CompanyInput` mutation. */
export type CompanyDomainsPathCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsPath` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsPathInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsPathCompanyIdFkeyCompanyDomainsPathCreateInput>>;
};

/**
 * A condition to be used against `CompanyDomainsPath` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyDomainsPathCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `domainId` field. */
  domainId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `path` field. */
  path?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyDomainsPathDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
  path?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyDomainsPathDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `companyDomain` to be created by this mutation. */
export type CompanyDomainsPathDomainIdFkeyCompanyDomainsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** The `companyDomainsPath` to be created by this mutation. */
export type CompanyDomainsPathDomainIdFkeyCompanyDomainsPathCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** Input for the nested mutation of `companyDomain` in the `CompanyDomainsPathInput` mutation. */
export type CompanyDomainsPathDomainIdFkeyInput = {
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectById?: Maybe<CompanyDomainCompanyDomainsPkeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyDomainNodeIdConnect>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteById?: Maybe<CompanyDomainCompanyDomainsPkeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByDomain?: Maybe<CompanyDomainCompanyDomainsDomainKeyDelete>;
  /** The primary key(s) for `companyDomain` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyDomainNodeIdDelete>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateById?: Maybe<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPkeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByDomain?: Maybe<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsDomainKeyUpdate>;
  /** The primary key(s) and patch data for `companyDomain` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate>;
  /** A `CompanyDomainInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyDomainsPathDomainIdFkeyCompanyDomainsCreateInput>;
};

/** Input for the nested mutation of `companyDomainsPath` in the `CompanyDomainInput` mutation. */
export type CompanyDomainsPathDomainIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyDomainsPath` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdConnect>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathPkeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathCompanyDomainsPathDomainIdPathKeyDelete>>;
  /** The primary key(s) for `companyDomainsPath` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyDomainsPathNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByDomainIdAndPath?: Maybe<Array<CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate>>;
  /** The primary key(s) and patch data for `companyDomainsPath` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate>>;
  /** A `CompanyDomainsPathInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyDomainsPathDomainIdFkeyCompanyDomainsPathCreateInput>>;
};

/** A filter to be used against `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyDomainsPathFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `domainId` field. */
  domainId?: Maybe<IntFilter>;
  /** Filter by the object’s `path` field. */
  path?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `domain` relation. */
  domain?: Maybe<CompanyDomainFilter>;
  /** A related `domain` exists. */
  domainExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyDomainsPathFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyDomainsPathFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyDomainsPathFilter>;
};

/** Grouping methods for `CompanyDomainsPath` for usage during aggregation. */
export enum CompanyDomainsPathGroupBy {
  CompanyId = 'COMPANY_ID',
  DomainId = 'DOMAIN_ID',
  Path = 'PATH',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyDomainsPathHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyDomainsPath` aggregates. */
export type CompanyDomainsPathHavingInput = {
  AND?: Maybe<Array<CompanyDomainsPathHavingInput>>;
  OR?: Maybe<Array<CompanyDomainsPathHavingInput>>;
  sum?: Maybe<CompanyDomainsPathHavingSumInput>;
  distinctCount?: Maybe<CompanyDomainsPathHavingDistinctCountInput>;
  min?: Maybe<CompanyDomainsPathHavingMinInput>;
  max?: Maybe<CompanyDomainsPathHavingMaxInput>;
  average?: Maybe<CompanyDomainsPathHavingAverageInput>;
  stddevSample?: Maybe<CompanyDomainsPathHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyDomainsPathHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyDomainsPathHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyDomainsPathHavingVariancePopulationInput>;
};

export type CompanyDomainsPathHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyDomainsPathHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  domainId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyDomainsPath` */
export type CompanyDomainsPathInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

export type CompanyDomainsPathMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsPathMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

export type CompanyDomainsPathMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  domainId?: Maybe<IntFilter>;
};

export type CompanyDomainsPathMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of domainId across the matching connection */
  domainId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyDomainsPathNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyDomainsPathNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyDomainsPathPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathDomainIdPathKeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** The fields on `companyDomainsPath` to look up the row to update. */
export type CompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyUsingCompanyDomainsPathPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyDomainsPath`. Fields that are set will be updated. */
export type CompanyDomainsPathPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

export type CompanyDomainsPathStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  domainId?: Maybe<BigIntFilter>;
};

export type CompanyDomainsPathSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of domainId across the matching connection */
  domainId: Scalars['BigInt'];
};

export type CompanyDomainsPathVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyDomainsPathVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  domainId?: Maybe<BigFloatFilter>;
};

export type CompanyDomainsPathVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of domainId across the matching connection */
  domainId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyDomainsPath` values. */
export type CompanyDomainsPathsConnection = {
  /** A list of `CompanyDomainsPath` objects. */
  nodes: Array<CompanyDomainsPath>;
  /** A list of edges which contains the `CompanyDomainsPath` and cursor to aid in pagination. */
  edges: Array<CompanyDomainsPathsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyDomainsPath` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyDomainsPathAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyDomainsPathAggregates>>;
};


/** A connection to a list of `CompanyDomainsPath` values. */
export type CompanyDomainsPathsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyDomainsPathGroupBy>;
  having?: Maybe<CompanyDomainsPathHavingInput>;
};

/** A `CompanyDomainsPath` edge in the connection. */
export type CompanyDomainsPathsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyDomainsPath` at the end of the edge. */
  node: CompanyDomainsPath;
};

/** Methods to use when ordering `CompanyDomainsPath`. */
export enum CompanyDomainsPathsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DomainIdAsc = 'DOMAIN_ID_ASC',
  DomainIdDesc = 'DOMAIN_ID_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  /** Reads and enables pagination through a set of `Field`. */
  fieldsByConfig: FieldsConnection;
};


/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyFieldConfigsByFieldCompanyIdAndConfigManyToManyEdgeFieldsByConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’ */
export type CompanyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `equifaxCustomerNumber` field. */
  equifaxCustomerNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `securityCode` field. */
  securityCode?: Maybe<StringFilter>;
  /** Filter by the object’s `externalId` field. */
  externalId?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `archived` field. */
  archived?: Maybe<BooleanFilter>;
  /** Filter by the object’s `zohoId` field. */
  zohoId?: Maybe<StringFilter>;
  /** Filter by the object’s `deliverTo` field. */
  deliverTo?: Maybe<BooleanFilter>;
  /** Filter by the object’s `primaryGroup` field. */
  primaryGroup?: Maybe<IntFilter>;
  /** Filter by the object’s `rawMeta` field. */
  rawMeta?: Maybe<JsonFilter>;
  /** Filter by the object’s `billableCompanyId` field. */
  billableCompanyId?: Maybe<IntFilter>;
  /** Filter by the object’s `rawRequestedAccess` field. */
  rawRequestedAccess?: Maybe<JsonFilter>;
  /** Filter by the object’s `lenderDealerCode` field. */
  lenderDealerCode?: Maybe<StringFilter>;
  /** Filter by the object’s `googlePlacesId` field. */
  googlePlacesId?: Maybe<StringFilter>;
  /** Filter by the object’s `legalConsent` field. */
  legalConsent?: Maybe<BooleanFilter>;
  /** Filter by the object’s `leads` relation. */
  leads?: Maybe<CompanyToManyLeadFilter>;
  /** Some related `leads` exist. */
  leadsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyGroups` relation. */
  companyGroups?: Maybe<CompanyToManyCompanyGroupFilter>;
  /** Some related `companyGroups` exist. */
  companyGroupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProducts` relation. */
  companyProducts?: Maybe<CompanyToManyCompanyProductFilter>;
  /** Some related `companyProducts` exist. */
  companyProductsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyLocations` relation. */
  companyLocations?: Maybe<CompanyToManyCompanyLocationFilter>;
  /** Some related `companyLocations` exist. */
  companyLocationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyAnalytics` relation. */
  companyAnalytics?: Maybe<CompanyToManyCompanyAnalyticFilter>;
  /** Some related `companyAnalytics` exist. */
  companyAnalyticsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomains` relation. */
  companyDomains?: Maybe<CompanyToManyCompanyDomainFilter>;
  /** Some related `companyDomains` exist. */
  companyDomainsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsApproveds` relation. */
  companyDomainsApproveds?: Maybe<CompanyToManyCompanyDomainsApprovedFilter>;
  /** Some related `companyDomainsApproveds` exist. */
  companyDomainsApprovedsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyDomainsPaths` relation. */
  companyDomainsPaths?: Maybe<CompanyToManyCompanyDomainsPathFilter>;
  /** Some related `companyDomainsPaths` exist. */
  companyDomainsPathsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `reviews` relation. */
  reviews?: Maybe<CompanyToManyReviewFilter>;
  /** Some related `reviews` exist. */
  reviewsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `analytics` relation. */
  analytics?: Maybe<CompanyToManyAnalyticFilter>;
  /** Some related `analytics` exist. */
  analyticsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegrations` relation. */
  companyIntegrations?: Maybe<CompanyToManyCompanyIntegrationFilter>;
  /** Some related `companyIntegrations` exist. */
  companyIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannels` relation. */
  companyChannels?: Maybe<CompanyToManyCompanyChannelFilter>;
  /** Some related `companyChannels` exist. */
  companyChannelsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `groupByPrimaryGroup` relation. */
  groupByPrimaryGroup?: Maybe<GroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyFilter>;
};

export type CompanyGroup = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
};

export type CompanyGroupAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyGroupSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyGroupDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyGroupMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyGroupMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyGroupAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyGroupStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyGroupStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyGroupVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyGroupVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyGroup` object types. */
export type CompanyGroupAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyGroup` object to be included within the aggregate. */
  filter?: Maybe<CompanyGroupFilter>;
  /** Sum aggregate over matching `CompanyGroup` objects. */
  sum?: Maybe<CompanyGroupSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyGroup` objects. */
  distinctCount?: Maybe<CompanyGroupDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyGroup` objects. */
  min?: Maybe<CompanyGroupMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyGroup` objects. */
  max?: Maybe<CompanyGroupMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyGroup` objects. */
  average?: Maybe<CompanyGroupAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyGroup` objects. */
  stddevSample?: Maybe<CompanyGroupStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyGroup` objects. */
  stddevPopulation?: Maybe<CompanyGroupStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyGroup` objects. */
  varianceSample?: Maybe<CompanyGroupVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyGroup` objects. */
  variancePopulation?: Maybe<CompanyGroupVariancePopulationAggregateFilter>;
};

export type CompanyGroupAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** Grouping methods for `Company` for usage during aggregation. */
export enum CompanyGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  EquifaxCustomerNumber = 'EQUIFAX_CUSTOMER_NUMBER',
  SecurityCode = 'SECURITY_CODE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Archived = 'ARCHIVED',
  ZohoId = 'ZOHO_ID',
  DeliverTo = 'DELIVER_TO',
  RawMeta = 'RAW_META',
  BillableCompanyId = 'BILLABLE_COMPANY_ID',
  RawRequestedAccess = 'RAW_REQUESTED_ACCESS',
  LenderDealerCode = 'LENDER_DEALER_CODE',
  GooglePlacesId = 'GOOGLE_PLACES_ID',
  LegalConsent = 'LEGAL_CONSENT'
}

/** The fields on `companyGroup` to look up the row to connect. */
export type CompanyGroupCompanyGroupPkeyConnect = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The fields on `companyGroup` to look up the row to delete. */
export type CompanyGroupCompanyGroupPkeyDelete = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The `company` to be created by this mutation. */
export type CompanyGroupCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyGroup` to be created by this mutation. */
export type CompanyGroupCompanyIdFkeyCompanyGroupCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyGroupInput` mutation. */
export type CompanyGroupCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyGroupCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyGroup` in the `CompanyInput` mutation. */
export type CompanyGroupCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyGroupNodeIdConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyGroupCompanyIdFkeyCompanyGroupCreateInput>>;
};

/**
 * A condition to be used against `CompanyGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyGroupCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyGroupDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyGroupDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type CompanyGroupFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyGroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyGroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyGroupFilter>;
};

/** Grouping methods for `CompanyGroup` for usage during aggregation. */
export enum CompanyGroupGroupBy {
  CompanyId = 'COMPANY_ID',
  GroupId = 'GROUP_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `companyGroup` to be created by this mutation. */
export type CompanyGroupGroupIdFkeyCompanyGroupCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyGroupGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyGroupInput` mutation. */
export type CompanyGroupGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyGroupGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `companyGroup` in the `GroupInput` mutation. */
export type CompanyGroupGroupIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyGroup` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyGroupNodeIdConnect>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupCompanyGroupPkeyDelete>>;
  /** The primary key(s) for `companyGroup` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyGroupNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByCompanyIdAndGroupId?: Maybe<Array<CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingCompanyGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyGroup` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate>>;
  /** A `CompanyGroupInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyGroupGroupIdFkeyCompanyGroupCreateInput>>;
};

export type CompanyGroupHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyGroup` aggregates. */
export type CompanyGroupHavingInput = {
  AND?: Maybe<Array<CompanyGroupHavingInput>>;
  OR?: Maybe<Array<CompanyGroupHavingInput>>;
  sum?: Maybe<CompanyGroupHavingSumInput>;
  distinctCount?: Maybe<CompanyGroupHavingDistinctCountInput>;
  min?: Maybe<CompanyGroupHavingMinInput>;
  max?: Maybe<CompanyGroupHavingMaxInput>;
  average?: Maybe<CompanyGroupHavingAverageInput>;
  stddevSample?: Maybe<CompanyGroupHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyGroupHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyGroupHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyGroupHavingVariancePopulationInput>;
};

export type CompanyGroupHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyGroupHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyGroup` */
export type CompanyGroupInput = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

export type CompanyGroupMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CompanyGroupMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CompanyGroupMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CompanyGroupMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyGroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyGroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyGroup` to look up the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyGroup` to look up the row to update. */
export type CompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingCompanyGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: UpdateCompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** Represents an update to a `CompanyGroup`. Fields that are set will be updated. */
export type CompanyGroupPatch = {
  companyId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

export type CompanyGroupStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
};

export type CompanyGroupSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CompanyGroupVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyGroupVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyGroupVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyGroup` values. */
export type CompanyGroupsConnection = {
  /** A list of `CompanyGroup` objects. */
  nodes: Array<CompanyGroup>;
  /** A list of edges which contains the `CompanyGroup` and cursor to aid in pagination. */
  edges: Array<CompanyGroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyGroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyGroupAggregates>>;
};


/** A connection to a list of `CompanyGroup` values. */
export type CompanyGroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupGroupBy>;
  having?: Maybe<CompanyGroupHavingInput>;
};

/** A `CompanyGroup` edge in the connection. */
export type CompanyGroupsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyGroup` at the end of the edge. */
  node: CompanyGroup;
};

/** Methods to use when ordering `CompanyGroup`. */
export enum CompanyGroupsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

/** Conditions for `Company` aggregates. */
export type CompanyHavingInput = {
  AND?: Maybe<Array<CompanyHavingInput>>;
  OR?: Maybe<Array<CompanyHavingInput>>;
  sum?: Maybe<CompanyHavingSumInput>;
  distinctCount?: Maybe<CompanyHavingDistinctCountInput>;
  min?: Maybe<CompanyHavingMinInput>;
  max?: Maybe<CompanyHavingMaxInput>;
  average?: Maybe<CompanyHavingAverageInput>;
  stddevSample?: Maybe<CompanyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyHavingVariancePopulationInput>;
};

export type CompanyHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  primaryGroup?: Maybe<HavingIntFilter>;
  billableCompanyId?: Maybe<HavingIntFilter>;
};

export type CompanyIdToolMeta = {
  autoSendSms?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<IdToolTemplateType>;
  idCountry?: Maybe<IdSupportedCountries>;
  autoSendSmsForTrade?: Maybe<Scalars['Boolean']>;
  idProvider?: Maybe<Scalars['String']>;
  stripeDocumentOptions?: Maybe<StripeIdentityDocumentOptions>;
};

/** An input for mutations affecting `Company` */
export type CompanyInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

export type CompanyInstallationMeta = {
  problemUrl?: Maybe<Scalars['String']>;
};

export type CompanyIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductId: CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyIntegrationIdAndCompanyId: CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldCompanyIntegrationIdAndCompanyProductId: CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyIntegrationIdAndCompanyChannelId: CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyIntegrationIdAndConfig: CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnection;
};


export type CompanyIntegrationCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


export type CompanyIntegrationFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type CompanyIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyIntegration` object types. */
export type CompanyIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyIntegration` object to be included within the aggregate. */
  filter?: Maybe<CompanyIntegrationFilter>;
  /** Sum aggregate over matching `CompanyIntegration` objects. */
  sum?: Maybe<CompanyIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyIntegration` objects. */
  distinctCount?: Maybe<CompanyIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyIntegration` objects. */
  min?: Maybe<CompanyIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyIntegration` objects. */
  max?: Maybe<CompanyIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyIntegration` objects. */
  average?: Maybe<CompanyIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyIntegration` objects. */
  stddevSample?: Maybe<CompanyIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyIntegration` objects. */
  stddevPopulation?: Maybe<CompanyIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyIntegration` objects. */
  varianceSample?: Maybe<CompanyIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyIntegration` objects. */
  variancePopulation?: Maybe<CompanyIntegrationVariancePopulationAggregateFilter>;
};

export type CompanyIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompaniesByFieldCompanyIntegrationIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyChannelsByFieldCompanyIntegrationIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The `company` to be created by this mutation. */
export type CompanyIntegrationCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyIntegrationCompanyIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyIntegrationInput` mutation. */
export type CompanyIntegrationCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyIntegrationCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `CompanyInput` mutation. */
export type CompanyIntegrationCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyIntegrationCompanyIdFkeyCompanyIntegrationCreateInput>>;
};

/** The fields on `companyIntegration` to look up the row to connect. */
export type CompanyIntegrationCompanyIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyIntegration` to look up the row to delete. */
export type CompanyIntegrationCompanyIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `CompanyProduct` values, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `CompanyProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyIntegrationCompanyProductsByCompanyProductIntegrationCompanyIntegrationIdAndCompanyProductIdManyToManyEdgeCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type CompanyIntegrationCompanyProductsByFieldCompanyIntegrationIdAndCompanyProductIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/**
 * A condition to be used against `CompanyIntegration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyIntegrationFieldConfigsByFieldCompanyIntegrationIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyProductIntegrations` relation. */
  companyProductIntegrations?: Maybe<CompanyIntegrationToManyCompanyProductIntegrationFilter>;
  /** Some related `companyProductIntegrations` exist. */
  companyProductIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyIntegrationToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyIntegrationFilter>;
};

/** Grouping methods for `CompanyIntegration` for usage during aggregation. */
export enum CompanyIntegrationGroupBy {
  IntegrationId = 'INTEGRATION_ID',
  CompanyId = 'COMPANY_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyIntegration` aggregates. */
export type CompanyIntegrationHavingInput = {
  AND?: Maybe<Array<CompanyIntegrationHavingInput>>;
  OR?: Maybe<Array<CompanyIntegrationHavingInput>>;
  sum?: Maybe<CompanyIntegrationHavingSumInput>;
  distinctCount?: Maybe<CompanyIntegrationHavingDistinctCountInput>;
  min?: Maybe<CompanyIntegrationHavingMinInput>;
  max?: Maybe<CompanyIntegrationHavingMaxInput>;
  average?: Maybe<CompanyIntegrationHavingAverageInput>;
  stddevSample?: Maybe<CompanyIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyIntegrationHavingVariancePopulationInput>;
};

export type CompanyIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyIntegration` */
export type CompanyIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyIntegrationIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `integration` in the `CompanyIntegrationInput` mutation. */
export type CompanyIntegrationIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyIntegrationIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type CompanyIntegrationIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `IntegrationInput` mutation. */
export type CompanyIntegrationIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyIntegrationCompanyIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate>>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyIntegrationIntegrationIdFkeyCompanyIntegrationCreateInput>>;
};

export type CompanyIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

export type CompanyIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
};

export type CompanyIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyIntegration` to look up the row to update. */
export type CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: UpdateCompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyIntegration`. Fields that are set will be updated. */
export type CompanyIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

export type CompanyIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
};

export type CompanyIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyCompanyProductIntegrationFilter = {
  /** Every related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductIntegrationFilter>;
  /** Some related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductIntegrationFilter>;
  /** No related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductIntegrationFilter>;
  /** Aggregates across related `CompanyProductIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyProductIntegrationAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyIntegrationToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

export type CompanyIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
};

export type CompanyIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `CompanyIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
};


/** A `Integration` edge in the connection, with data from `CompanyIntegration`. */
export type CompanyIntegrationsByCompanyIntegrationCompanyIdAndIntegrationIdManyToManyEdgeCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

/** A connection to a list of `CompanyIntegration` values. */
export type CompanyIntegrationsConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration` and cursor to aid in pagination. */
  edges: Array<CompanyIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values. */
export type CompanyIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection. */
export type CompanyIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
};

/** Methods to use when ordering `CompanyIntegration`. */
export enum CompanyIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyProductIntegrationsCountAsc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_ASC',
  CompanyProductIntegrationsCountDesc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_DESC',
  CompanyProductIntegrationsSumIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  CompanyProductIntegrationsSumIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  CompanyProductIntegrationsSumCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsSumCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsSumCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsSumCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsSumEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyProductIntegrationsSumEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyProductIntegrationsSumCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyProductIntegrationsSumCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyProductIntegrationsSumUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyProductIntegrationsSumUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyProductIntegrationsDistinctCountIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsDistinctCountEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductIntegrationsDistinctCountEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductIntegrationsMinIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  CompanyProductIntegrationsMinIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  CompanyProductIntegrationsMinCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMinCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMinCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMinCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMinEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyProductIntegrationsMinEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyProductIntegrationsMinCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyProductIntegrationsMinCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyProductIntegrationsMinUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyProductIntegrationsMinUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyProductIntegrationsMaxIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  CompanyProductIntegrationsMaxIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMaxCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMaxCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMaxEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyProductIntegrationsMaxEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyProductIntegrationsMaxCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyProductIntegrationsMaxCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyProductIntegrationsMaxUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyProductIntegrationsMaxUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyProductIntegrationsAverageIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyProductIntegrationsAverageIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsAverageCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsAverageCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsAverageEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyProductIntegrationsAverageEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyProductIntegrationsAverageCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyProductIntegrationsAverageCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyProductIntegrationsAverageUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductIntegrationsAverageUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductIntegrationsStddevSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsStddevSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevPopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsStddevPopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductIntegrationsVarianceSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVarianceSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsVarianceSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVariancePopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsVariancePopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CompanyLenderToolMeta = {
  creditApp?: Maybe<LenderProviderCreditAppConfig>;
  enabledProviders?: Maybe<Array<Scalars['String']>>;
};

export type CompanyLocation = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
};

export type CompanyLocationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyLocationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyLocationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyLocationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyLocationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyLocationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyLocationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyLocationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyLocationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyLocationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyLocation` object types. */
export type CompanyLocationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyLocation` object to be included within the aggregate. */
  filter?: Maybe<CompanyLocationFilter>;
  /** Sum aggregate over matching `CompanyLocation` objects. */
  sum?: Maybe<CompanyLocationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyLocation` objects. */
  distinctCount?: Maybe<CompanyLocationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyLocation` objects. */
  min?: Maybe<CompanyLocationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyLocation` objects. */
  max?: Maybe<CompanyLocationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyLocation` objects. */
  average?: Maybe<CompanyLocationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyLocation` objects. */
  stddevSample?: Maybe<CompanyLocationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyLocation` objects. */
  stddevPopulation?: Maybe<CompanyLocationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyLocation` objects. */
  varianceSample?: Maybe<CompanyLocationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyLocation` objects. */
  variancePopulation?: Maybe<CompanyLocationVariancePopulationAggregateFilter>;
};

export type CompanyLocationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Mean average of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** The `company` to be created by this mutation. */
export type CompanyLocationCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyLocation` to be created by this mutation. */
export type CompanyLocationCompanyIdFkeyCompanyLocationsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

/** Input for the nested mutation of `company` in the `CompanyLocationInput` mutation. */
export type CompanyLocationCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyLocationCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyLocation` in the `CompanyInput` mutation. */
export type CompanyLocationCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyLocation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyLocationCompanyLocationsPkeyConnect>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyLocationNodeIdConnect>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyLocationCompanyLocationsPkeyDelete>>;
  /** The primary key(s) for `companyLocation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyLocationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyLocation` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLocationsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyLocation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyLocationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyLocationCompanyIdFkeyCompanyLocationsCreateInput>>;
};

/** The fields on `companyLocation` to look up the row to connect. */
export type CompanyLocationCompanyLocationsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyLocation` to look up the row to delete. */
export type CompanyLocationCompanyLocationsPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyLocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyLocationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `address1` field. */
  address1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `address2` field. */
  address2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `province` field. */
  province?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lat` field. */
  lat?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `long` field. */
  long?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyLocationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  address1?: Maybe<BigIntFilter>;
  address2?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  province?: Maybe<BigIntFilter>;
  lat?: Maybe<BigIntFilter>;
  long?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyLocationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address1 across the matching connection */
  address1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of address2 across the matching connection */
  address2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of province across the matching connection */
  province?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lat across the matching connection */
  lat?: Maybe<Scalars['BigInt']>;
  /** Distinct count of long across the matching connection */
  long?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyLocation` object types. All fields are combined with a logical ‘and.’ */
export type CompanyLocationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `address1` field. */
  address1?: Maybe<StringFilter>;
  /** Filter by the object’s `address2` field. */
  address2?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `province` field. */
  province?: Maybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: Maybe<FloatFilter>;
  /** Filter by the object’s `long` field. */
  long?: Maybe<FloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyLocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyLocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyLocationFilter>;
};

/** An input for mutations affecting `CompanyLocation` */
export type CompanyLocationInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

export type CompanyLocationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Maximum of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Minimum of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyLocationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyLocationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyLocation` to look up the row to update. */
export type CompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyLocationsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyLocation` being updated. */
  patch: UpdateCompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyLocation`. Fields that are set will be updated. */
export type CompanyLocationPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

export type CompanyLocationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Population standard deviation of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of lat across the matching connection */
  lat: Scalars['Float'];
  /** Sum of long across the matching connection */
  long: Scalars['Float'];
};

export type CompanyLocationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Population variance of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

export type CompanyLocationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  lat?: Maybe<FloatFilter>;
  long?: Maybe<FloatFilter>;
};

export type CompanyLocationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lat across the matching connection */
  lat?: Maybe<Scalars['Float']>;
  /** Sample variance of long across the matching connection */
  long?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `CompanyLocation` values. */
export type CompanyLocationsConnection = {
  /** A list of `CompanyLocation` objects. */
  nodes: Array<CompanyLocation>;
  /** A list of edges which contains the `CompanyLocation` and cursor to aid in pagination. */
  edges: Array<CompanyLocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyLocation` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyLocationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyLocationAggregates>>;
};


/** A connection to a list of `CompanyLocation` values. */
export type CompanyLocationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyLocationsGroupBy>;
  having?: Maybe<CompanyLocationsHavingInput>;
};

/** A `CompanyLocation` edge in the connection. */
export type CompanyLocationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyLocation` at the end of the edge. */
  node: CompanyLocation;
};

/** Grouping methods for `CompanyLocation` for usage during aggregation. */
export enum CompanyLocationsGroupBy {
  CompanyId = 'COMPANY_ID',
  Address1 = 'ADDRESS1',
  Address2 = 'ADDRESS2',
  City = 'CITY',
  PostalCode = 'POSTAL_CODE',
  Province = 'PROVINCE',
  Lat = 'LAT',
  Long = 'LONG',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyLocationsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyLocation` aggregates. */
export type CompanyLocationsHavingInput = {
  AND?: Maybe<Array<CompanyLocationsHavingInput>>;
  OR?: Maybe<Array<CompanyLocationsHavingInput>>;
  sum?: Maybe<CompanyLocationsHavingSumInput>;
  distinctCount?: Maybe<CompanyLocationsHavingDistinctCountInput>;
  min?: Maybe<CompanyLocationsHavingMinInput>;
  max?: Maybe<CompanyLocationsHavingMaxInput>;
  average?: Maybe<CompanyLocationsHavingAverageInput>;
  stddevSample?: Maybe<CompanyLocationsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyLocationsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyLocationsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyLocationsHavingVariancePopulationInput>;
};

export type CompanyLocationsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyLocationsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  lat?: Maybe<HavingFloatFilter>;
  long?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyLocation`. */
export enum CompanyLocationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  Address1Asc = 'ADDRESS1_ASC',
  Address1Desc = 'ADDRESS1_DESC',
  Address2Asc = 'ADDRESS2_ASC',
  Address2Desc = 'ADDRESS2_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  ProvinceAsc = 'PROVINCE_ASC',
  ProvinceDesc = 'PROVINCE_DESC',
  LatAsc = 'LAT_ASC',
  LatDesc = 'LAT_DESC',
  LongAsc = 'LONG_ASC',
  LongDesc = 'LONG_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Maximum of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['Int']>;
};

export type CompanyMeta = {
  id: Scalars['String'];
  template?: Maybe<CompanyTemplateMeta>;
  ctas?: Maybe<CompanyCtaMeta>;
  tradeTool?: Maybe<CompanyTradeToolMeta>;
  installation?: Maybe<CompanyInstallationMeta>;
  idTool?: Maybe<CompanyIdToolMeta>;
  lenderTool?: Maybe<CompanyLenderToolMeta>;
  securityCheck?: Maybe<SecurityCheck>;
  forceAssignedFilter?: Maybe<Scalars['Boolean']>;
};

export type CompanyMetaResponse = {
  companyId: Scalars['Int'];
  success?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type CompanyMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['Int']>;
  /** Minimum of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
};

export type CompanyNotificationInput = {
  companyId: Scalars['Int'];
  type: CompanyNotificationType;
};

export type CompanyNotificationResponse = {
  success: Scalars['Boolean'];
};

export enum CompanyNotificationType {
  OfferAccepted = 'OfferAccepted',
  NewVehicleAdded = 'NewVehicleAdded'
}

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `analytic` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `analytic` being updated. */
  patch: AnalyticPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnAnalyticForAnalyticsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyAnalytic` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomain` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsApproved` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyDomainsPath` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyLocation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyLocation` being updated. */
  patch: CompanyLocationPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `review` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `review` being updated. */
  patch: ReviewPatch;
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  externalId: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyNameKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  name: Scalars['String'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPkeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `company` to look up the row to update. */
export type CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate = {
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch;
  primaryGroup: Scalars['Int'];
};

/** Represents an update to a `Company`. Fields that are set will be updated. */
export type CompanyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

export type CompanyPermissions = {
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permissions>;
};

/** The `company` to be created by this mutation. */
export type CompanyPrimaryGroupFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyPrimaryGroupFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyInput` mutation. */
export type CompanyPrimaryGroupFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyPrimaryGroupFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `company` in the `GroupInput` mutation. */
export type CompanyPrimaryGroupFkeyInverseInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyForCompanyPrimaryGroupFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<GroupOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyPrimaryGroupFkeyCompanyCreateInput>>;
};

export type CompanyProduct = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  id: Scalars['Int'];
  enabled: Scalars['Boolean'];
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationId: CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldCompanyProductIdAndCompanyId: CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldCompanyProductIdAndCompanyChannelId: CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationId: CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigsByFieldCompanyProductIdAndConfig: CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnection;
};


export type CompanyProductCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


export type CompanyProductFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

export type CompanyProductAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyProductSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyProductDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyProductMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyProductMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyProductAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyProductStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyProductStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyProductVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyProductVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyProduct` object types. */
export type CompanyProductAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyProduct` object to be included within the aggregate. */
  filter?: Maybe<CompanyProductFilter>;
  /** Sum aggregate over matching `CompanyProduct` objects. */
  sum?: Maybe<CompanyProductSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyProduct` objects. */
  distinctCount?: Maybe<CompanyProductDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyProduct` objects. */
  min?: Maybe<CompanyProductMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyProduct` objects. */
  max?: Maybe<CompanyProductMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyProduct` objects. */
  average?: Maybe<CompanyProductAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyProduct` objects. */
  stddevSample?: Maybe<CompanyProductStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyProduct` objects. */
  stddevPopulation?: Maybe<CompanyProductStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyProduct` objects. */
  varianceSample?: Maybe<CompanyProductVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyProduct` objects. */
  variancePopulation?: Maybe<CompanyProductVariancePopulationAggregateFilter>;
};

export type CompanyProductAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type CompanyProductCompaniesByFieldCompanyProductIdAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyChannelsByFieldCompanyProductIdAndCompanyChannelIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The `company` to be created by this mutation. */
export type CompanyProductCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductCompanyIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyProductInput` mutation. */
export type CompanyProductCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `companyProduct` in the `CompanyInput` mutation. */
export type CompanyProductCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProduct` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductCompanyProductPkeyConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductNodeIdConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductCompanyProductPkeyDelete>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyProductPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductCompanyIdFkeyCompanyProductCreateInput>>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `CompanyProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations: CompanyProductIntegrationsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `CompanyProductIntegration`. */
export type CompanyProductCompanyIntegrationsByCompanyProductIntegrationCompanyProductIdAndCompanyIntegrationIdManyToManyEdgeCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type CompanyProductCompanyIntegrationsByFieldCompanyProductIdAndCompanyIntegrationIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** The fields on `companyProduct` to look up the row to connect. */
export type CompanyProductCompanyProductPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyProduct` to look up the row to delete. */
export type CompanyProductCompanyProductPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyProduct` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyProductCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CompanyProductDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
};

export type CompanyProductDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection, with data from `Field`. */
export type CompanyProductFieldConfigsByFieldCompanyProductIdAndConfigManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A filter to be used against `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `companyProductIntegrations` relation. */
  companyProductIntegrations?: Maybe<CompanyProductToManyCompanyProductIntegrationFilter>;
  /** Some related `companyProductIntegrations` exist. */
  companyProductIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fields` relation. */
  fields?: Maybe<CompanyProductToManyFieldFilter>;
  /** Some related `fields` exist. */
  fieldsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyProductFilter>;
};

/** Grouping methods for `CompanyProduct` for usage during aggregation. */
export enum CompanyProductGroupBy {
  CompanyId = 'COMPANY_ID',
  ProductId = 'PRODUCT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Id = 'ID',
  Enabled = 'ENABLED'
}

export type CompanyProductHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** Conditions for `CompanyProduct` aggregates. */
export type CompanyProductHavingInput = {
  AND?: Maybe<Array<CompanyProductHavingInput>>;
  OR?: Maybe<Array<CompanyProductHavingInput>>;
  sum?: Maybe<CompanyProductHavingSumInput>;
  distinctCount?: Maybe<CompanyProductHavingDistinctCountInput>;
  min?: Maybe<CompanyProductHavingMinInput>;
  max?: Maybe<CompanyProductHavingMaxInput>;
  average?: Maybe<CompanyProductHavingAverageInput>;
  stddevSample?: Maybe<CompanyProductHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyProductHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyProductHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyProductHavingVariancePopulationInput>;
};

export type CompanyProductHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type CompanyProductHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `CompanyProduct` */
export type CompanyProductInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

export type CompanyProductIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
};

export type CompanyProductIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyProductIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyProductIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyProductIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyProductIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyProductIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyProductIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyProductIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyProductIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyProductIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyProductIntegration` object types. */
export type CompanyProductIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyProductIntegration` object to be included within the aggregate. */
  filter?: Maybe<CompanyProductIntegrationFilter>;
  /** Sum aggregate over matching `CompanyProductIntegration` objects. */
  sum?: Maybe<CompanyProductIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyProductIntegration` objects. */
  distinctCount?: Maybe<CompanyProductIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyProductIntegration` objects. */
  min?: Maybe<CompanyProductIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyProductIntegration` objects. */
  max?: Maybe<CompanyProductIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyProductIntegration` objects. */
  average?: Maybe<CompanyProductIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyProductIntegration` objects. */
  stddevSample?: Maybe<CompanyProductIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyProductIntegration` objects. */
  stddevPopulation?: Maybe<CompanyProductIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyProductIntegration` objects. */
  varianceSample?: Maybe<CompanyProductIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyProductIntegration` objects. */
  variancePopulation?: Maybe<CompanyProductIntegrationVariancePopulationAggregateFilter>;
};

export type CompanyProductIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

/** The `companyIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** The `companyProductIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `CompanyProductIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `companyProductIntegration` in the `CompanyIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProductIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductIntegrationCompanyIntegrationIdFkeyCompanyProductIntegrationCreateInput>>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `companyProductIntegration` to be created by this mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyCompanyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** Input for the nested mutation of `companyProduct` in the `CompanyProductIntegrationInput` mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyInput = {
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<CompanyProductCompanyProductPkeyConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyProductNodeIdConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<CompanyProductCompanyProductPkeyDelete>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyProductNodeIdDelete>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductPkeyUpdate>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyCompanyProductCreateInput>;
};

/** Input for the nested mutation of `companyProductIntegration` in the `CompanyProductInput` mutation. */
export type CompanyProductIntegrationCompanyProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProductIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductIntegrationCompanyProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `companyProductIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProductIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductIntegrationCompanyProductIdFkeyCompanyProductIntegrationCreateInput>>;
};

/** The fields on `companyProductIntegration` to look up the row to connect. */
export type CompanyProductIntegrationCompanyProductIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `companyProductIntegration` to look up the row to delete. */
export type CompanyProductIntegrationCompanyProductIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

/**
 * A condition to be used against `CompanyProductIntegration` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyProductIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyProductId` field. */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CompanyProductIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CompanyProductIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyProductId` field. */
  companyProductId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProduct` relation. */
  companyProduct?: Maybe<CompanyProductFilter>;
  /** A related `companyProduct` exists. */
  companyProductExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyProductIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyProductIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyProductIntegrationFilter>;
};

/** Grouping methods for `CompanyProductIntegration` for usage during aggregation. */
export enum CompanyProductIntegrationGroupBy {
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  CompanyProductId = 'COMPANY_PRODUCT_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CompanyProductIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyProductIntegration` aggregates. */
export type CompanyProductIntegrationHavingInput = {
  AND?: Maybe<Array<CompanyProductIntegrationHavingInput>>;
  OR?: Maybe<Array<CompanyProductIntegrationHavingInput>>;
  sum?: Maybe<CompanyProductIntegrationHavingSumInput>;
  distinctCount?: Maybe<CompanyProductIntegrationHavingDistinctCountInput>;
  min?: Maybe<CompanyProductIntegrationHavingMinInput>;
  max?: Maybe<CompanyProductIntegrationHavingMaxInput>;
  average?: Maybe<CompanyProductIntegrationHavingAverageInput>;
  stddevSample?: Maybe<CompanyProductIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyProductIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyProductIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyProductIntegrationHavingVariancePopulationInput>;
};

export type CompanyProductIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CompanyProductIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CompanyProductIntegration` */
export type CompanyProductIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

export type CompanyProductIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
};

export type CompanyProductIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
};

export type CompanyProductIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
};

export type CompanyProductIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyProductIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyProductIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `companyProductIntegration` to look up the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyUsingCompanyProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `companyProductIntegration` to look up the row to update. */
export type CompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyProductIntegration`. Fields that are set will be updated. */
export type CompanyProductIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

export type CompanyProductIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
};

export type CompanyProductIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of companyProductId across the matching connection */
  companyProductId: Scalars['BigInt'];
};

export type CompanyProductIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
};

export type CompanyProductIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyProductIntegration` values. */
export type CompanyProductIntegrationsConnection = {
  /** A list of `CompanyProductIntegration` objects. */
  nodes: Array<CompanyProductIntegration>;
  /** A list of edges which contains the `CompanyProductIntegration` and cursor to aid in pagination. */
  edges: Array<CompanyProductIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProductIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductIntegrationAggregates>>;
};


/** A connection to a list of `CompanyProductIntegration` values. */
export type CompanyProductIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductIntegrationGroupBy>;
  having?: Maybe<CompanyProductIntegrationHavingInput>;
};

/** A `CompanyProductIntegration` edge in the connection. */
export type CompanyProductIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProductIntegration` at the end of the edge. */
  node: CompanyProductIntegration;
};

/** Methods to use when ordering `CompanyProductIntegration`. */
export enum CompanyProductIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIdAsc = 'COMPANY_PRODUCT_ID_ASC',
  CompanyProductIdDesc = 'COMPANY_PRODUCT_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompanyProductMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type CompanyProductMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type CompanyProductMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type CompanyProductMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyProductNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyProductNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductForCompanyProductProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProductIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyProductOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `companyProduct` to look up the row to update. */
export type CompanyProductOnFieldForFieldCompanyProductIdFkeyUsingCompanyProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: UpdateCompanyProductOnFieldForFieldCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CompanyProduct`. Fields that are set will be updated. */
export type CompanyProductPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `companyProduct` to be created by this mutation. */
export type CompanyProductProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** Input for the nested mutation of `product` in the `CompanyProductInput` mutation. */
export type CompanyProductProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `companyProduct` in the `ProductInput` mutation. */
export type CompanyProductProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `companyProduct` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyProductCompanyProductPkeyConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyProductNodeIdConnect>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyProductCompanyProductPkeyDelete>>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyProductNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyProductOnCompanyProductForCompanyProductProductIdFkeyUsingCompanyProductPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate>>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyProductProductIdFkeyCompanyProductCreateInput>>;
};

export type CompanyProductStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
};

export type CompanyProductSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductToManyCompanyProductIntegrationFilter = {
  /** Every related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductIntegrationFilter>;
  /** Some related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductIntegrationFilter>;
  /** No related `CompanyProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductIntegrationFilter>;
  /** Aggregates across related `CompanyProductIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyProductIntegrationAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyProductToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

export type CompanyProductVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CompanyProductVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type CompanyProductVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Product` values, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `CompanyProduct`, and the cursor to aid in pagination. */
  edges: Array<CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
};


/** A `Product` edge in the connection, with data from `CompanyProduct`. */
export type CompanyProductsByCompanyProductCompanyIdAndProductIdManyToManyEdgeCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};

/** A connection to a list of `CompanyProduct` values. */
export type CompanyProductsConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct` and cursor to aid in pagination. */
  edges: Array<CompanyProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values. */
export type CompanyProductsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection. */
export type CompanyProductsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
};

/** Methods to use when ordering `CompanyProduct`. */
export enum CompanyProductsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyProductIntegrationsCountAsc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_ASC',
  CompanyProductIntegrationsCountDesc = 'COMPANY_PRODUCT_INTEGRATIONS_COUNT_DESC',
  CompanyProductIntegrationsSumIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  CompanyProductIntegrationsSumIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  CompanyProductIntegrationsSumCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsSumCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsSumCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsSumCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsSumEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyProductIntegrationsSumEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyProductIntegrationsSumCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyProductIntegrationsSumCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyProductIntegrationsSumUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyProductIntegrationsSumUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyProductIntegrationsDistinctCountIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsDistinctCountCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsDistinctCountEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductIntegrationsDistinctCountEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductIntegrationsMinIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  CompanyProductIntegrationsMinIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  CompanyProductIntegrationsMinCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMinCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMinCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMinCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMinEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyProductIntegrationsMinEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyProductIntegrationsMinCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyProductIntegrationsMinCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyProductIntegrationsMinUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyProductIntegrationsMinUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyProductIntegrationsMaxIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  CompanyProductIntegrationsMaxIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsMaxCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsMaxCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsMaxCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsMaxEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyProductIntegrationsMaxEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyProductIntegrationsMaxCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyProductIntegrationsMaxCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyProductIntegrationsMaxUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyProductIntegrationsMaxUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyProductIntegrationsAverageIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyProductIntegrationsAverageIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsAverageCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsAverageCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsAverageCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsAverageEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyProductIntegrationsAverageEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyProductIntegrationsAverageCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyProductIntegrationsAverageCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyProductIntegrationsAverageUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductIntegrationsAverageUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductIntegrationsStddevSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsStddevSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsStddevPopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsStddevPopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsStddevPopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductIntegrationsVarianceSampleIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVarianceSampleCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVarianceSampleEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductIntegrationsVarianceSampleEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyIntegrationIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  CompanyProductIntegrationsVariancePopulationCompanyProductIdDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  CompanyProductIntegrationsVariancePopulationEnabledAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductIntegrationsVariancePopulationEnabledDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyProductIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsCountAsc = 'FIELDS_COUNT_ASC',
  FieldsCountDesc = 'FIELDS_COUNT_DESC',
  FieldsSumIdAsc = 'FIELDS_SUM_ID_ASC',
  FieldsSumIdDesc = 'FIELDS_SUM_ID_DESC',
  FieldsSumCompanyIdAsc = 'FIELDS_SUM_COMPANY_ID_ASC',
  FieldsSumCompanyIdDesc = 'FIELDS_SUM_COMPANY_ID_DESC',
  FieldsSumCompanyProductIdAsc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsSumCompanyProductIdDesc = 'FIELDS_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsSumCompanyChannelIdAsc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsSumCompanyChannelIdDesc = 'FIELDS_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsSumCompanyIntegrationIdAsc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsSumCompanyIntegrationIdDesc = 'FIELDS_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsSumConfigAsc = 'FIELDS_SUM_CONFIG_ASC',
  FieldsSumConfigDesc = 'FIELDS_SUM_CONFIG_DESC',
  FieldsSumTopicAsc = 'FIELDS_SUM_TOPIC_ASC',
  FieldsSumTopicDesc = 'FIELDS_SUM_TOPIC_DESC',
  FieldsSumValueAsc = 'FIELDS_SUM_VALUE_ASC',
  FieldsSumValueDesc = 'FIELDS_SUM_VALUE_DESC',
  FieldsSumCreatedAtAsc = 'FIELDS_SUM_CREATED_AT_ASC',
  FieldsSumCreatedAtDesc = 'FIELDS_SUM_CREATED_AT_DESC',
  FieldsSumUpdatedAtAsc = 'FIELDS_SUM_UPDATED_AT_ASC',
  FieldsSumUpdatedAtDesc = 'FIELDS_SUM_UPDATED_AT_DESC',
  FieldsDistinctCountIdAsc = 'FIELDS_DISTINCT_COUNT_ID_ASC',
  FieldsDistinctCountIdDesc = 'FIELDS_DISTINCT_COUNT_ID_DESC',
  FieldsDistinctCountCompanyIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsDistinctCountCompanyIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsDistinctCountCompanyProductIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsDistinctCountCompanyProductIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsDistinctCountCompanyChannelIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsDistinctCountCompanyChannelIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsDistinctCountCompanyIntegrationIdAsc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsDistinctCountCompanyIntegrationIdDesc = 'FIELDS_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsDistinctCountConfigAsc = 'FIELDS_DISTINCT_COUNT_CONFIG_ASC',
  FieldsDistinctCountConfigDesc = 'FIELDS_DISTINCT_COUNT_CONFIG_DESC',
  FieldsDistinctCountTopicAsc = 'FIELDS_DISTINCT_COUNT_TOPIC_ASC',
  FieldsDistinctCountTopicDesc = 'FIELDS_DISTINCT_COUNT_TOPIC_DESC',
  FieldsDistinctCountValueAsc = 'FIELDS_DISTINCT_COUNT_VALUE_ASC',
  FieldsDistinctCountValueDesc = 'FIELDS_DISTINCT_COUNT_VALUE_DESC',
  FieldsDistinctCountCreatedAtAsc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsDistinctCountCreatedAtDesc = 'FIELDS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsDistinctCountUpdatedAtAsc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsDistinctCountUpdatedAtDesc = 'FIELDS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsMinIdAsc = 'FIELDS_MIN_ID_ASC',
  FieldsMinIdDesc = 'FIELDS_MIN_ID_DESC',
  FieldsMinCompanyIdAsc = 'FIELDS_MIN_COMPANY_ID_ASC',
  FieldsMinCompanyIdDesc = 'FIELDS_MIN_COMPANY_ID_DESC',
  FieldsMinCompanyProductIdAsc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsMinCompanyProductIdDesc = 'FIELDS_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsMinCompanyChannelIdAsc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsMinCompanyChannelIdDesc = 'FIELDS_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsMinCompanyIntegrationIdAsc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsMinCompanyIntegrationIdDesc = 'FIELDS_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsMinConfigAsc = 'FIELDS_MIN_CONFIG_ASC',
  FieldsMinConfigDesc = 'FIELDS_MIN_CONFIG_DESC',
  FieldsMinTopicAsc = 'FIELDS_MIN_TOPIC_ASC',
  FieldsMinTopicDesc = 'FIELDS_MIN_TOPIC_DESC',
  FieldsMinValueAsc = 'FIELDS_MIN_VALUE_ASC',
  FieldsMinValueDesc = 'FIELDS_MIN_VALUE_DESC',
  FieldsMinCreatedAtAsc = 'FIELDS_MIN_CREATED_AT_ASC',
  FieldsMinCreatedAtDesc = 'FIELDS_MIN_CREATED_AT_DESC',
  FieldsMinUpdatedAtAsc = 'FIELDS_MIN_UPDATED_AT_ASC',
  FieldsMinUpdatedAtDesc = 'FIELDS_MIN_UPDATED_AT_DESC',
  FieldsMaxIdAsc = 'FIELDS_MAX_ID_ASC',
  FieldsMaxIdDesc = 'FIELDS_MAX_ID_DESC',
  FieldsMaxCompanyIdAsc = 'FIELDS_MAX_COMPANY_ID_ASC',
  FieldsMaxCompanyIdDesc = 'FIELDS_MAX_COMPANY_ID_DESC',
  FieldsMaxCompanyProductIdAsc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsMaxCompanyProductIdDesc = 'FIELDS_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsMaxCompanyChannelIdAsc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsMaxCompanyChannelIdDesc = 'FIELDS_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsMaxCompanyIntegrationIdAsc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsMaxCompanyIntegrationIdDesc = 'FIELDS_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsMaxConfigAsc = 'FIELDS_MAX_CONFIG_ASC',
  FieldsMaxConfigDesc = 'FIELDS_MAX_CONFIG_DESC',
  FieldsMaxTopicAsc = 'FIELDS_MAX_TOPIC_ASC',
  FieldsMaxTopicDesc = 'FIELDS_MAX_TOPIC_DESC',
  FieldsMaxValueAsc = 'FIELDS_MAX_VALUE_ASC',
  FieldsMaxValueDesc = 'FIELDS_MAX_VALUE_DESC',
  FieldsMaxCreatedAtAsc = 'FIELDS_MAX_CREATED_AT_ASC',
  FieldsMaxCreatedAtDesc = 'FIELDS_MAX_CREATED_AT_DESC',
  FieldsMaxUpdatedAtAsc = 'FIELDS_MAX_UPDATED_AT_ASC',
  FieldsMaxUpdatedAtDesc = 'FIELDS_MAX_UPDATED_AT_DESC',
  FieldsAverageIdAsc = 'FIELDS_AVERAGE_ID_ASC',
  FieldsAverageIdDesc = 'FIELDS_AVERAGE_ID_DESC',
  FieldsAverageCompanyIdAsc = 'FIELDS_AVERAGE_COMPANY_ID_ASC',
  FieldsAverageCompanyIdDesc = 'FIELDS_AVERAGE_COMPANY_ID_DESC',
  FieldsAverageCompanyProductIdAsc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsAverageCompanyProductIdDesc = 'FIELDS_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsAverageCompanyChannelIdAsc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsAverageCompanyChannelIdDesc = 'FIELDS_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsAverageCompanyIntegrationIdAsc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsAverageCompanyIntegrationIdDesc = 'FIELDS_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsAverageConfigAsc = 'FIELDS_AVERAGE_CONFIG_ASC',
  FieldsAverageConfigDesc = 'FIELDS_AVERAGE_CONFIG_DESC',
  FieldsAverageTopicAsc = 'FIELDS_AVERAGE_TOPIC_ASC',
  FieldsAverageTopicDesc = 'FIELDS_AVERAGE_TOPIC_DESC',
  FieldsAverageValueAsc = 'FIELDS_AVERAGE_VALUE_ASC',
  FieldsAverageValueDesc = 'FIELDS_AVERAGE_VALUE_DESC',
  FieldsAverageCreatedAtAsc = 'FIELDS_AVERAGE_CREATED_AT_ASC',
  FieldsAverageCreatedAtDesc = 'FIELDS_AVERAGE_CREATED_AT_DESC',
  FieldsAverageUpdatedAtAsc = 'FIELDS_AVERAGE_UPDATED_AT_ASC',
  FieldsAverageUpdatedAtDesc = 'FIELDS_AVERAGE_UPDATED_AT_DESC',
  FieldsStddevSampleIdAsc = 'FIELDS_STDDEV_SAMPLE_ID_ASC',
  FieldsStddevSampleIdDesc = 'FIELDS_STDDEV_SAMPLE_ID_DESC',
  FieldsStddevSampleCompanyIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsStddevSampleCompanyIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsStddevSampleCompanyProductIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevSampleCompanyProductIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevSampleCompanyChannelIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevSampleCompanyChannelIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevSampleCompanyIntegrationIdAsc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevSampleCompanyIntegrationIdDesc = 'FIELDS_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevSampleConfigAsc = 'FIELDS_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsStddevSampleConfigDesc = 'FIELDS_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsStddevSampleTopicAsc = 'FIELDS_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsStddevSampleTopicDesc = 'FIELDS_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsStddevSampleValueAsc = 'FIELDS_STDDEV_SAMPLE_VALUE_ASC',
  FieldsStddevSampleValueDesc = 'FIELDS_STDDEV_SAMPLE_VALUE_DESC',
  FieldsStddevSampleCreatedAtAsc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsStddevSampleCreatedAtDesc = 'FIELDS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsStddevSampleUpdatedAtAsc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsStddevSampleUpdatedAtDesc = 'FIELDS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsStddevPopulationIdAsc = 'FIELDS_STDDEV_POPULATION_ID_ASC',
  FieldsStddevPopulationIdDesc = 'FIELDS_STDDEV_POPULATION_ID_DESC',
  FieldsStddevPopulationCompanyIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsStddevPopulationCompanyIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsStddevPopulationCompanyProductIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsStddevPopulationCompanyProductIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsStddevPopulationCompanyChannelIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsStddevPopulationCompanyChannelIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsStddevPopulationConfigAsc = 'FIELDS_STDDEV_POPULATION_CONFIG_ASC',
  FieldsStddevPopulationConfigDesc = 'FIELDS_STDDEV_POPULATION_CONFIG_DESC',
  FieldsStddevPopulationTopicAsc = 'FIELDS_STDDEV_POPULATION_TOPIC_ASC',
  FieldsStddevPopulationTopicDesc = 'FIELDS_STDDEV_POPULATION_TOPIC_DESC',
  FieldsStddevPopulationValueAsc = 'FIELDS_STDDEV_POPULATION_VALUE_ASC',
  FieldsStddevPopulationValueDesc = 'FIELDS_STDDEV_POPULATION_VALUE_DESC',
  FieldsStddevPopulationCreatedAtAsc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsStddevPopulationCreatedAtDesc = 'FIELDS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsStddevPopulationUpdatedAtAsc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsStddevPopulationUpdatedAtDesc = 'FIELDS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsVarianceSampleIdAsc = 'FIELDS_VARIANCE_SAMPLE_ID_ASC',
  FieldsVarianceSampleIdDesc = 'FIELDS_VARIANCE_SAMPLE_ID_DESC',
  FieldsVarianceSampleCompanyIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsVarianceSampleCompanyIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsVarianceSampleCompanyProductIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsVarianceSampleCompanyProductIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsVarianceSampleCompanyChannelIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsVarianceSampleCompanyChannelIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsVarianceSampleConfigAsc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsVarianceSampleConfigDesc = 'FIELDS_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsVarianceSampleTopicAsc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsVarianceSampleTopicDesc = 'FIELDS_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsVarianceSampleValueAsc = 'FIELDS_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsVarianceSampleValueDesc = 'FIELDS_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsVarianceSampleCreatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsVarianceSampleCreatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsVarianceSampleUpdatedAtAsc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsVarianceSampleUpdatedAtDesc = 'FIELDS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsVariancePopulationIdAsc = 'FIELDS_VARIANCE_POPULATION_ID_ASC',
  FieldsVariancePopulationIdDesc = 'FIELDS_VARIANCE_POPULATION_ID_DESC',
  FieldsVariancePopulationCompanyIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsVariancePopulationCompanyIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsVariancePopulationCompanyProductIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsVariancePopulationCompanyProductIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsVariancePopulationCompanyChannelIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsVariancePopulationCompanyChannelIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsVariancePopulationConfigAsc = 'FIELDS_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsVariancePopulationConfigDesc = 'FIELDS_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsVariancePopulationTopicAsc = 'FIELDS_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsVariancePopulationTopicDesc = 'FIELDS_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsVariancePopulationValueAsc = 'FIELDS_VARIANCE_POPULATION_VALUE_ASC',
  FieldsVariancePopulationValueDesc = 'FIELDS_VARIANCE_POPULATION_VALUE_DESC',
  FieldsVariancePopulationCreatedAtAsc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsVariancePopulationCreatedAtDesc = 'FIELDS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsVariancePopulationUpdatedAtAsc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsVariancePopulationUpdatedAtDesc = 'FIELDS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type CompanyRequestedAccess = {
  topic: Scalars['String'];
  timestamp: Scalars['Datetime'];
  userEmail: Scalars['String'];
};

export type CompanyReviewConfig = {
  googlePlacesId: Scalars['String'];
};

export type CompanyStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of primaryGroup across the matching connection */
  primaryGroup: Scalars['BigInt'];
  /** Sum of billableCompanyId across the matching connection */
  billableCompanyId: Scalars['BigInt'];
};

export type CompanyTemplateMeta = {
  branchCode?: Maybe<Scalars['String']>;
};

/** A filter to be used against many `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyAnalyticFilter = {
  /** Every related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AnalyticFilter>;
  /** Some related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AnalyticFilter>;
  /** No related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AnalyticFilter>;
  /** Aggregates across related `Analytic` match the filter criteria. */
  aggregates?: Maybe<AnalyticAggregatesFilter>;
};

/** A filter to be used against many `CompanyAnalytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyAnalyticFilter = {
  /** Every related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyAnalyticFilter>;
  /** Some related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyAnalyticFilter>;
  /** No related `CompanyAnalytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyAnalyticFilter>;
  /** Aggregates across related `CompanyAnalytic` match the filter criteria. */
  aggregates?: Maybe<CompanyAnalyticAggregatesFilter>;
};

/** A filter to be used against many `CompanyChannel` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyChannelFilter = {
  /** Every related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyChannelFilter>;
  /** Some related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyChannelFilter>;
  /** No related `CompanyChannel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyChannelFilter>;
  /** Aggregates across related `CompanyChannel` match the filter criteria. */
  aggregates?: Maybe<CompanyChannelAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomain` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainFilter = {
  /** Every related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainFilter>;
  /** Some related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainFilter>;
  /** No related `CompanyDomain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainFilter>;
  /** Aggregates across related `CompanyDomain` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsApproved` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainsApprovedFilter = {
  /** Every related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsApprovedFilter>;
  /** Some related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsApprovedFilter>;
  /** No related `CompanyDomainsApproved` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsApprovedFilter>;
  /** Aggregates across related `CompanyDomainsApproved` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsApprovedAggregatesFilter>;
};

/** A filter to be used against many `CompanyDomainsPath` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyDomainsPathFilter = {
  /** Every related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyDomainsPathFilter>;
  /** Some related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyDomainsPathFilter>;
  /** No related `CompanyDomainsPath` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyDomainsPathFilter>;
  /** Aggregates across related `CompanyDomainsPath` match the filter criteria. */
  aggregates?: Maybe<CompanyDomainsPathAggregatesFilter>;
};

/** A filter to be used against many `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyGroupFilter = {
  /** Every related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyGroupFilter>;
  /** Some related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyGroupFilter>;
  /** No related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyGroupFilter>;
  /** Aggregates across related `CompanyGroup` match the filter criteria. */
  aggregates?: Maybe<CompanyGroupAggregatesFilter>;
};

/** A filter to be used against many `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyIntegrationFilter = {
  /** Every related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyIntegrationFilter>;
  /** Some related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyIntegrationFilter>;
  /** No related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyIntegrationFilter>;
  /** Aggregates across related `CompanyIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyIntegrationAggregatesFilter>;
};

/** A filter to be used against many `CompanyLocation` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyLocationFilter = {
  /** Every related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyLocationFilter>;
  /** Some related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyLocationFilter>;
  /** No related `CompanyLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyLocationFilter>;
  /** Aggregates across related `CompanyLocation` match the filter criteria. */
  aggregates?: Maybe<CompanyLocationAggregatesFilter>;
};

/** A filter to be used against many `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyProductFilter = {
  /** Every related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductFilter>;
  /** Some related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductFilter>;
  /** No related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductFilter>;
  /** Aggregates across related `CompanyProduct` match the filter criteria. */
  aggregates?: Maybe<CompanyProductAggregatesFilter>;
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `Lead` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyLeadFilter = {
  /** Every related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadFilter>;
  /** Some related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadFilter>;
  /** No related `Lead` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadFilter>;
  /** Aggregates across related `Lead` match the filter criteria. */
  aggregates?: Maybe<LeadAggregatesFilter>;
};

/** A filter to be used against many `Review` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyReviewFilter = {
  /** Every related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReviewFilter>;
  /** Some related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReviewFilter>;
  /** No related `Review` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReviewFilter>;
  /** Aggregates across related `Review` match the filter criteria. */
  aggregates?: Maybe<ReviewAggregatesFilter>;
};

export type CompanyTradeToolMeta = {
  disableTradeValue: Scalars['Boolean'];
  /**  Example values: +10, -10  */
  modifyByPercentage?: Maybe<Scalars['Int']>;
  provider?: Maybe<TradeProvider>;
};

export type CompanyVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Population variance of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of primaryGroup across the matching connection */
  primaryGroup?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of billableCompanyId across the matching connection */
  billableCompanyId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
  specOverride?: Maybe<Scalars['JSON']>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
};

export type CompanyWidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyWidgetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyWidgetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyWidgetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyWidgetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyWidgetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyWidgetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyWidgetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyWidgetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyWidgetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CompanyWidget` object types. */
export type CompanyWidgetAggregatesFilter = {
  /** A filter that must pass for the relevant `CompanyWidget` object to be included within the aggregate. */
  filter?: Maybe<CompanyWidgetFilter>;
  /** Sum aggregate over matching `CompanyWidget` objects. */
  sum?: Maybe<CompanyWidgetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyWidget` objects. */
  distinctCount?: Maybe<CompanyWidgetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CompanyWidget` objects. */
  min?: Maybe<CompanyWidgetMinAggregateFilter>;
  /** Maximum aggregate over matching `CompanyWidget` objects. */
  max?: Maybe<CompanyWidgetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CompanyWidget` objects. */
  average?: Maybe<CompanyWidgetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyWidget` objects. */
  stddevSample?: Maybe<CompanyWidgetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyWidget` objects. */
  stddevPopulation?: Maybe<CompanyWidgetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyWidget` objects. */
  varianceSample?: Maybe<CompanyWidgetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CompanyWidget` objects. */
  variancePopulation?: Maybe<CompanyWidgetVariancePopulationAggregateFilter>;
};

export type CompanyWidgetAverageAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetAverageAggregates = {
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `companyWidget` to look up the row to connect. */
export type CompanyWidgetCompanyWidgetsPkeyConnect = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The fields on `companyWidget` to look up the row to delete. */
export type CompanyWidgetCompanyWidgetsPkeyDelete = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/**
 * A condition to be used against `CompanyWidget` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyWidgetCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `specOverride` field. */
  specOverride?: Maybe<Scalars['JSON']>;
};

export type CompanyWidgetDistinctCountAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  specOverride?: Maybe<BigIntFilter>;
};

export type CompanyWidgetDistinctCountAggregates = {
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of specOverride across the matching connection */
  specOverride?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type CompanyWidgetFilter = {
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CompanyWidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CompanyWidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CompanyWidgetFilter>;
};

/** An input for mutations affecting `CompanyWidget` */
export type CompanyWidgetInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

export type CompanyWidgetMaxAggregateFilter = {
  groupId?: Maybe<IntFilter>;
};

export type CompanyWidgetMaxAggregates = {
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CompanyWidgetMinAggregateFilter = {
  groupId?: Maybe<IntFilter>;
};

export type CompanyWidgetMinAggregates = {
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CompanyWidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CompanyWidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `companyWidget` to look up the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingCompanyWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `companyWidget` to look up the row to update. */
export type CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingCompanyWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** Represents an update to a `CompanyWidget`. Fields that are set will be updated. */
export type CompanyWidgetPatch = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

export type CompanyWidgetStddevPopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetStddevPopulationAggregates = {
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetStddevSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetStddevSampleAggregates = {
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetSumAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
};

export type CompanyWidgetSumAggregates = {
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CompanyWidgetVariancePopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetVariancePopulationAggregates = {
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CompanyWidgetVarianceSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
};

export type CompanyWidgetVarianceSampleAggregates = {
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CompanyWidget` values. */
export type CompanyWidgetsConnection = {
  /** A list of `CompanyWidget` objects. */
  nodes: Array<CompanyWidget>;
  /** A list of edges which contains the `CompanyWidget` and cursor to aid in pagination. */
  edges: Array<CompanyWidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyWidget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyWidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyWidgetAggregates>>;
};


/** A connection to a list of `CompanyWidget` values. */
export type CompanyWidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyWidgetsGroupBy>;
  having?: Maybe<CompanyWidgetsHavingInput>;
};

/** A `CompanyWidget` edge in the connection. */
export type CompanyWidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyWidget` at the end of the edge. */
  node: CompanyWidget;
};

/** Grouping methods for `CompanyWidget` for usage during aggregation. */
export enum CompanyWidgetsGroupBy {
  GroupId = 'GROUP_ID',
  WidgetId = 'WIDGET_ID',
  SpecOverride = 'SPEC_OVERRIDE'
}

/** The `companyWidget` to be created by this mutation. */
export type CompanyWidgetsGroupIdFkCompanyWidgetsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** The `group` to be created by this mutation. */
export type CompanyWidgetsGroupIdFkGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `CompanyWidgetInput` mutation. */
export type CompanyWidgetsGroupIdFkInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyWidgetsGroupIdFkGroupCreateInput>;
};

/** Input for the nested mutation of `companyWidget` in the `GroupInput` mutation. */
export type CompanyWidgetsGroupIdFkInverseInput = {
  /** Flag indicating whether all other `companyWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyWidgetNodeIdConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyDelete>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingCompanyWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate>>;
  /** A `CompanyWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyWidgetsGroupIdFkCompanyWidgetsCreateInput>>;
};

export type CompanyWidgetsHavingAverageInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingDistinctCountInput = {
  groupId?: Maybe<HavingIntFilter>;
};

/** Conditions for `CompanyWidget` aggregates. */
export type CompanyWidgetsHavingInput = {
  AND?: Maybe<Array<CompanyWidgetsHavingInput>>;
  OR?: Maybe<Array<CompanyWidgetsHavingInput>>;
  sum?: Maybe<CompanyWidgetsHavingSumInput>;
  distinctCount?: Maybe<CompanyWidgetsHavingDistinctCountInput>;
  min?: Maybe<CompanyWidgetsHavingMinInput>;
  max?: Maybe<CompanyWidgetsHavingMaxInput>;
  average?: Maybe<CompanyWidgetsHavingAverageInput>;
  stddevSample?: Maybe<CompanyWidgetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CompanyWidgetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CompanyWidgetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CompanyWidgetsHavingVariancePopulationInput>;
};

export type CompanyWidgetsHavingMaxInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingMinInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingStddevPopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingStddevSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingSumInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingVariancePopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
};

export type CompanyWidgetsHavingVarianceSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `CompanyWidget`. */
export enum CompanyWidgetsOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  SpecOverrideAsc = 'SPEC_OVERRIDE_ASC',
  SpecOverrideDesc = 'SPEC_OVERRIDE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `companyWidget` to be created by this mutation. */
export type CompanyWidgetsWidgetIdFkCompanyWidgetsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** Input for the nested mutation of `widget` in the `CompanyWidgetInput` mutation. */
export type CompanyWidgetsWidgetIdFkInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<CompanyWidgetsWidgetIdFkWidgetsCreateInput>;
};

/** Input for the nested mutation of `companyWidget` in the `WidgetInput` mutation. */
export type CompanyWidgetsWidgetIdFkInverseInput = {
  /** Flag indicating whether all other `companyWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyWidgetNodeIdConnect>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetCompanyWidgetsPkeyDelete>>;
  /** The primary key(s) for `companyWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByGroupIdAndWidgetId?: Maybe<Array<CompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingCompanyWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate>>;
  /** A `CompanyWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CompanyWidgetsWidgetIdFkCompanyWidgetsCreateInput>>;
};

/** The `widget` to be created by this mutation. */
export type CompanyWidgetsWidgetIdFkWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export type CopyPermissionsInput = {
  fromUsername: Scalars['String'];
  toUsername: Scalars['String'];
};

export type CopyPermissionsResponse = {
  success?: Maybe<Scalars['Boolean']>;
  removedFromGroups?: Maybe<Array<Scalars['String']>>;
  addedToGroups?: Maybe<Array<Scalars['String']>>;
};

/** All input for the create `Analytic` mutation. */
export type CreateAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` to be created by this mutation. */
  analytic: AnalyticInput;
};

/** The output of our create `Analytic` mutation. */
export type CreateAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was created by this mutation. */
  analytic?: Maybe<Analytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our create `Analytic` mutation. */
export type CreateAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` to be created by this mutation. */
  analyticsCta: AnalyticsCtaInput;
};

/** The output of our create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was created by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our create `AnalyticsCta` mutation. */
export type CreateAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the create `Appointment` mutation. */
export type CreateAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` to be created by this mutation. */
  appointment: AppointmentInput;
};

/** The output of our create `Appointment` mutation. */
export type CreateAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was created by this mutation. */
  appointment?: Maybe<Appointment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our create `Appointment` mutation. */
export type CreateAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the create `AvaApp` mutation. */
export type CreateAvaAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` to be created by this mutation. */
  avaApp: AvaAppInput;
};

/** The output of our create `AvaApp` mutation. */
export type CreateAvaAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` that was created by this mutation. */
  avaApp?: Maybe<AvaApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `AvaApp`. May be used by Relay 1. */
  avaAppEdge?: Maybe<AvaAppsEdge>;
};


/** The output of our create `AvaApp` mutation. */
export type CreateAvaAppPayloadAvaAppEdgeArgs = {
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
};

/** All input for the create `Channel` mutation. */
export type CreateChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` to be created by this mutation. */
  channel: ChannelInput;
};

/** The output of our create `Channel` mutation. */
export type CreateChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was created by this mutation. */
  channel?: Maybe<Channel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our create `Channel` mutation. */
export type CreateChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` to be created by this mutation. */
  companyAnalytic: CompanyAnalyticInput;
};

/** The output of our create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was created by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our create `CompanyAnalytic` mutation. */
export type CreateCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

/** All input for the create `CompanyChannel` mutation. */
export type CreateCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` to be created by this mutation. */
  companyChannel: CompanyChannelInput;
};

/** The output of our create `CompanyChannel` mutation. */
export type CreateCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` that was created by this mutation. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our create `CompanyChannel` mutation. */
export type CreateCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the create `CompanyCta` mutation. */
export type CreateCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` to be created by this mutation. */
  companyCta: CompanyCtaInput;
};

/** The output of our create `CompanyCta` mutation. */
export type CreateCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was created by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our create `CompanyCta` mutation. */
export type CreateCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the create `CompanyDomain` mutation. */
export type CreateCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` to be created by this mutation. */
  companyDomain: CompanyDomainInput;
};

/** The output of our create `CompanyDomain` mutation. */
export type CreateCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was created by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our create `CompanyDomain` mutation. */
export type CreateCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` to be created by this mutation. */
  companyDomainsApproved: CompanyDomainsApprovedInput;
};

/** The output of our create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was created by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our create `CompanyDomainsApproved` mutation. */
export type CreateCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` to be created by this mutation. */
  companyDomainsPath: CompanyDomainsPathInput;
};

/** The output of our create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was created by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our create `CompanyDomainsPath` mutation. */
export type CreateCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the create `CompanyGroup` mutation. */
export type CreateCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` to be created by this mutation. */
  companyGroup: CompanyGroupInput;
};

/** The output of our create `CompanyGroup` mutation. */
export type CreateCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was created by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our create `CompanyGroup` mutation. */
export type CreateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the create `Company` mutation. */
export type CreateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` to be created by this mutation. */
  company: CompanyInput;
};

/** All input for the create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` to be created by this mutation. */
  companyIntegration: CompanyIntegrationInput;
};

/** The output of our create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was created by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our create `CompanyIntegration` mutation. */
export type CreateCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the create `CompanyLocation` mutation. */
export type CreateCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` to be created by this mutation. */
  companyLocation: CompanyLocationInput;
};

/** The output of our create `CompanyLocation` mutation. */
export type CreateCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was created by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our create `CompanyLocation` mutation. */
export type CreateCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

/** The output of our create `Company` mutation. */
export type CreateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was created by this mutation. */
  company?: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
};


/** The output of our create `Company` mutation. */
export type CreateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
};

/** All input for the create `CompanyProduct` mutation. */
export type CreateCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` to be created by this mutation. */
  companyProduct: CompanyProductInput;
};

/** All input for the create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` to be created by this mutation. */
  companyProductIntegration: CompanyProductIntegrationInput;
};

/** The output of our create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was created by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our create `CompanyProductIntegration` mutation. */
export type CreateCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our create `CompanyProduct` mutation. */
export type CreateCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was created by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our create `CompanyProduct` mutation. */
export type CreateCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the create `CompanyWidget` mutation. */
export type CreateCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` to be created by this mutation. */
  companyWidget: CompanyWidgetInput;
};

/** The output of our create `CompanyWidget` mutation. */
export type CreateCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was created by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our create `CompanyWidget` mutation. */
export type CreateCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

export type CreateCompanyWithUserInput = {
  cloneFromCompanyId?: Maybe<Scalars['Int']>;
  company: CompanyInput;
  user?: Maybe<CreateUserInput>;
  groupDetails?: Maybe<GroupDetailsInput>;
  products?: Maybe<Array<ProductType>>;
  deliveryMethods?: Maybe<Array<DeliveryMethodInput>>;
};

export type CreateCompanyWithUserResponse = {
  company: BasicCompanyDetails;
  user: CreateUserResponse;
  companyProducts?: Maybe<Array<BasicProductDetails>>;
  deliveryMethods: Array<BasicDeliveryMethodDetails>;
};

export type CreateCreditAppApplicationResponse = {
  applicationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

/** All input for the create `CreditApp` mutation. */
export type CreateCreditAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditApp` to be created by this mutation. */
  creditApp: CreditAppInput;
};

/** The output of our create `CreditApp` mutation. */
export type CreateCreditAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditApp` that was created by this mutation. */
  creditApp?: Maybe<CreditApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `CreditApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `CreditApp`. May be used by Relay 1. */
  creditAppEdge?: Maybe<CreditAppsEdge>;
};


/** The output of our create `CreditApp` mutation. */
export type CreateCreditAppPayloadCreditAppEdgeArgs = {
  orderBy?: Maybe<Array<CreditAppsOrderBy>>;
};

/** All input for the create `CreditBureau` mutation. */
export type CreateCreditBureauInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` to be created by this mutation. */
  creditBureau: CreditBureauInput;
};

/** The output of our create `CreditBureau` mutation. */
export type CreateCreditBureauPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` that was created by this mutation. */
  creditBureau?: Maybe<CreditBureau>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  /** An edge for our `CreditBureau`. May be used by Relay 1. */
  creditBureauEdge?: Maybe<CreditBureausEdge>;
};


/** The output of our create `CreditBureau` mutation. */
export type CreateCreditBureauPayloadCreditBureauEdgeArgs = {
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
};

/** All input for the create `Cta` mutation. */
export type CreateCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` to be created by this mutation. */
  cta: CtaInput;
};

/** The output of our create `Cta` mutation. */
export type CreateCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was created by this mutation. */
  cta?: Maybe<Cta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our create `Cta` mutation. */
export type CreateCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type CreateCtaSpecsInput = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  /**
   * Use this field to generate ctaStyles, ctaStylesStatic, and ctaTree from
   * a raw html string
   */
  html?: Maybe<Scalars['String']>;
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Should be the themeSpec.spec value */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Should be the templateSpec.spec value */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Should be the theme definitions for themeDef.defs.default */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Should be the template definitions for templateDef.defs.default */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Should be the plugin value definitions, as needed. */
  pluginDef?: Maybe<Scalars['JSON']>;
};

/** All input for the create `CtaWidget` mutation. */
export type CreateCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` to be created by this mutation. */
  ctaWidget: CtaWidgetInput;
};

/** The output of our create `CtaWidget` mutation. */
export type CreateCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was created by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our create `CtaWidget` mutation. */
export type CreateCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` to be created by this mutation. */
  deliveryMethod: DeliveryMethodInput;
};

/** The output of our create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was created by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our create `DeliveryMethod` mutation. */
export type CreateDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the create `EmailTemplate` mutation. */
export type CreateEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` to be created by this mutation. */
  emailTemplate: EmailTemplateInput;
};

/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was created by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the create `EquifaxLender` mutation. */
export type CreateEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` to be created by this mutation. */
  equifaxLender: EquifaxLenderInput;
};

/** The output of our create `EquifaxLender` mutation. */
export type CreateEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was created by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our create `EquifaxLender` mutation. */
export type CreateEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the create `FieldConfig` mutation. */
export type CreateFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` to be created by this mutation. */
  fieldConfig: FieldConfigInput;
};

/** The output of our create `FieldConfig` mutation. */
export type CreateFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was created by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Product` that is related to this `FieldConfig`. */
  product?: Maybe<Product>;
  /** Reads a single `Channel` that is related to this `FieldConfig`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Integration` that is related to this `FieldConfig`. */
  integration?: Maybe<Integration>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our create `FieldConfig` mutation. */
export type CreateFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the create `Field` mutation. */
export type CreateFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` to be created by this mutation. */
  field: FieldInput;
};

/** All input for the create `FieldKey` mutation. */
export type CreateFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` to be created by this mutation. */
  fieldKey: FieldKeyInput;
};

/** The output of our create `FieldKey` mutation. */
export type CreateFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was created by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our create `FieldKey` mutation. */
export type CreateFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our create `Field` mutation. */
export type CreateFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was created by this mutation. */
  field?: Maybe<Field>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our create `Field` mutation. */
export type CreateFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the create `Group` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` to be created by this mutation. */
  group: GroupInput;
};

/** The output of our create `Group` mutation. */
export type CreateGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was created by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our create `Group` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the create `IdVerification` mutation. */
export type CreateIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` to be created by this mutation. */
  idVerification: IdVerificationInput;
};

/** The output of our create `IdVerification` mutation. */
export type CreateIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was created by this mutation. */
  idVerification?: Maybe<IdVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our create `IdVerification` mutation. */
export type CreateIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the create `IncomeVerification` mutation. */
export type CreateIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` to be created by this mutation. */
  incomeVerification: IncomeVerificationInput;
};

/** The output of our create `IncomeVerification` mutation. */
export type CreateIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was created by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our create `IncomeVerification` mutation. */
export type CreateIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the create `Integration` mutation. */
export type CreateIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` to be created by this mutation. */
  integration: IntegrationInput;
};

/** The output of our create `Integration` mutation. */
export type CreateIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was created by this mutation. */
  integration?: Maybe<Integration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our create `Integration` mutation. */
export type CreateIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the create `LeadCampaign` mutation. */
export type CreateLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` to be created by this mutation. */
  leadCampaign: LeadCampaignInput;
};

/** The output of our create `LeadCampaign` mutation. */
export type CreateLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was created by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our create `LeadCampaign` mutation. */
export type CreateLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the create `Lead` mutation. */
export type CreateLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` to be created by this mutation. */
  lead: LeadInput;
};

/** All input for the create `LeadJourney` mutation. */
export type CreateLeadJourneyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadJourney` to be created by this mutation. */
  leadJourney: LeadJourneyInput;
};

/** The output of our create `LeadJourney` mutation. */
export type CreateLeadJourneyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadJourney` that was created by this mutation. */
  leadJourney?: Maybe<LeadJourney>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadJourney`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Product` that is related to this `LeadJourney`. */
  product?: Maybe<Product>;
  /** An edge for our `LeadJourney`. May be used by Relay 1. */
  leadJourneyEdge?: Maybe<LeadJourneysEdge>;
};


/** The output of our create `LeadJourney` mutation. */
export type CreateLeadJourneyPayloadLeadJourneyEdgeArgs = {
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
};

/** The output of our create `Lead` mutation. */
export type CreateLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was created by this mutation. */
  lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our create `Lead` mutation. */
export type CreateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

/** All input for the create `LenderApp` mutation. */
export type CreateLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` to be created by this mutation. */
  lenderApp: LenderAppInput;
};

/** The output of our create `LenderApp` mutation. */
export type CreateLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was created by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our create `LenderApp` mutation. */
export type CreateLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the create `LenderQuote` mutation. */
export type CreateLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` to be created by this mutation. */
  lenderQuote: LenderQuoteInput;
};

/** The output of our create `LenderQuote` mutation. */
export type CreateLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was created by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our create `LenderQuote` mutation. */
export type CreateLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the create `Option` mutation. */
export type CreateOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` to be created by this mutation. */
  option: OptionInput;
};

/** The output of our create `Option` mutation. */
export type CreateOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was created by this mutation. */
  option?: Maybe<Option>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our create `Option` mutation. */
export type CreateOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the create `ProductIntegration` mutation. */
export type CreateProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` to be created by this mutation. */
  productIntegration: ProductIntegrationInput;
};

/** The output of our create `ProductIntegration` mutation. */
export type CreateProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was created by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our create `ProductIntegration` mutation. */
export type CreateProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the create `Review` mutation. */
export type CreateReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` to be created by this mutation. */
  review: ReviewInput;
};

/** The output of our create `Review` mutation. */
export type CreateReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was created by this mutation. */
  review?: Maybe<Review>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our create `Review` mutation. */
export type CreateReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the create `TradeApp` mutation. */
export type CreateTradeAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` to be created by this mutation. */
  tradeApp: TradeAppInput;
};

/** The output of our create `TradeApp` mutation. */
export type CreateTradeAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` that was created by this mutation. */
  tradeApp?: Maybe<TradeApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `TradeApp`. May be used by Relay 1. */
  tradeAppEdge?: Maybe<TradeAppsEdge>;
};


/** The output of our create `TradeApp` mutation. */
export type CreateTradeAppPayloadTradeAppEdgeArgs = {
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
};

/** All input for the create `UserActivity` mutation. */
export type CreateUserActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` to be created by this mutation. */
  userActivity: UserActivityInput;
};

/** The output of our create `UserActivity` mutation. */
export type CreateUserActivityPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` that was created by this mutation. */
  userActivity?: Maybe<UserActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserActivity`. May be used by Relay 1. */
  userActivityEdge?: Maybe<UserActivitiesEdge>;
};


/** The output of our create `UserActivity` mutation. */
export type CreateUserActivityPayloadUserActivityEdgeArgs = {
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
};

export type CreateUserInput = {
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  makeGroupAdmin?: Maybe<Scalars['Boolean']>;
};

export type CreateUserResponse = {
  success?: Maybe<Scalars['Boolean']>;
  temporaryPassword?: Maybe<Scalars['String']>;
  user?: Maybe<UserDetails>;
};

/** All input for the create `VehicleListing` mutation. */
export type CreateVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` to be created by this mutation. */
  vehicleListing: VehicleListingInput;
};

/** The output of our create `VehicleListing` mutation. */
export type CreateVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was created by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our create `VehicleListing` mutation. */
export type CreateVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the create `Widget` mutation. */
export type CreateWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` to be created by this mutation. */
  widget: WidgetInput;
};

/** The output of our create `Widget` mutation. */
export type CreateWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was created by this mutation. */
  widget?: Maybe<Widget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our create `Widget` mutation. */
export type CreateWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the create `WidgetSetting` mutation. */
export type CreateWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` to be created by this mutation. */
  widgetSetting: WidgetSettingInput;
};

/** The output of our create `WidgetSetting` mutation. */
export type CreateWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was created by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our create `WidgetSetting` mutation. */
export type CreateWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type CreditAnswer = {
  answerId: Scalars['String'];
  value: Scalars['String'];
};

export type CreditAnswerInput = {
  questionId: Scalars['String'];
  answerId: Scalars['String'];
};

export type CreditApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `CreditApp`. */
  lead?: Maybe<Lead>;
};

export type CreditAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CreditAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CreditAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CreditAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CreditAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CreditAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CreditAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CreditAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CreditAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CreditAppVariancePopulationAggregates>;
};

export type CreditAppApplicant = {
  applicantId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  sin?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  addressHistory?: Maybe<Scalars['JSON']>;
};

export type CreditAppApplication = {
  applicationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  hasCoApplicant?: Maybe<Scalars['Boolean']>;
  applicationType?: Maybe<Scalars['String']>;
  primaryApplicant?: Maybe<CreditAppApplicant>;
  coApplicant?: Maybe<CreditAppApplicant>;
};

export type CreditAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Mean average of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `CreditApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CreditAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `desiredVehicle` field. */
  desiredVehicle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `jobTitle` field. */
  jobTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `monthlyIncome` field. */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `preferredContactMethod` field. */
  preferredContactMethod?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `salaryHourly` field. */
  salaryHourly?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomeSource` field. */
  incomeSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `incomeTime` field. */
  incomeTime?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `creditConcern` field. */
  creditConcern?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `residenceTime` field. */
  residenceTime?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rentOrOwn` field. */
  rentOrOwn?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `creditApp` to look up the row to connect. */
export type CreditAppCreditAppLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `creditApp` to look up the row to connect. */
export type CreditAppCreditAppPkeyConnect = {
  id: Scalars['Int'];
};

export type CreditAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of desiredVehicle across the matching connection */
  desiredVehicle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of jobTitle across the matching connection */
  jobTitle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['BigInt']>;
  /** Distinct count of preferredContactMethod across the matching connection */
  preferredContactMethod?: Maybe<Scalars['BigInt']>;
  /** Distinct count of salaryHourly across the matching connection */
  salaryHourly?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeSource across the matching connection */
  incomeSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of incomeTime across the matching connection */
  incomeTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditConcern across the matching connection */
  creditConcern?: Maybe<Scalars['BigInt']>;
  /** Distinct count of residenceTime across the matching connection */
  residenceTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rentOrOwn across the matching connection */
  rentOrOwn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of employmentStatus across the matching connection */
  employmentStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CreditApp` object types. All fields are combined with a logical ‘and.’ */
export type CreditAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `desiredVehicle` field. */
  desiredVehicle?: Maybe<StringFilter>;
  /** Filter by the object’s `jobTitle` field. */
  jobTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `monthlyIncome` field. */
  monthlyIncome?: Maybe<FloatFilter>;
  /** Filter by the object’s `preferredContactMethod` field. */
  preferredContactMethod?: Maybe<StringFilter>;
  /** Filter by the object’s `salaryHourly` field. */
  salaryHourly?: Maybe<StringFilter>;
  /** Filter by the object’s `incomeSource` field. */
  incomeSource?: Maybe<StringFilter>;
  /** Filter by the object’s `incomeTime` field. */
  incomeTime?: Maybe<StringFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: Maybe<StringFilter>;
  /** Filter by the object’s `creditConcern` field. */
  creditConcern?: Maybe<StringFilter>;
  /** Filter by the object’s `residenceTime` field. */
  residenceTime?: Maybe<StringFilter>;
  /** Filter by the object’s `rentOrOwn` field. */
  rentOrOwn?: Maybe<StringFilter>;
  /** Filter by the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<FloatFilter>;
  /** Filter by the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CreditAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CreditAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<CreditAppFilter>;
};

/** Grouping methods for `CreditApp` for usage during aggregation. */
export enum CreditAppGroupBy {
  DesiredVehicle = 'DESIRED_VEHICLE',
  JobTitle = 'JOB_TITLE',
  MonthlyIncome = 'MONTHLY_INCOME',
  PreferredContactMethod = 'PREFERRED_CONTACT_METHOD',
  SalaryHourly = 'SALARY_HOURLY',
  IncomeSource = 'INCOME_SOURCE',
  IncomeTime = 'INCOME_TIME',
  CompanyName = 'COMPANY_NAME',
  CreditConcern = 'CREDIT_CONCERN',
  ResidenceTime = 'RESIDENCE_TIME',
  RentOrOwn = 'RENT_OR_OWN',
  MonthlyHomePayment = 'MONTHLY_HOME_PAYMENT',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CreditAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CreditApp` aggregates. */
export type CreditAppHavingInput = {
  AND?: Maybe<Array<CreditAppHavingInput>>;
  OR?: Maybe<Array<CreditAppHavingInput>>;
  sum?: Maybe<CreditAppHavingSumInput>;
  distinctCount?: Maybe<CreditAppHavingDistinctCountInput>;
  min?: Maybe<CreditAppHavingMinInput>;
  max?: Maybe<CreditAppHavingMaxInput>;
  average?: Maybe<CreditAppHavingAverageInput>;
  stddevSample?: Maybe<CreditAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CreditAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<CreditAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CreditAppHavingVariancePopulationInput>;
};

export type CreditAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  monthlyIncome?: Maybe<HavingFloatFilter>;
  monthlyHomePayment?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CreditApp` */
export type CreditAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<CreditAppLeadIdFkeyInput>;
};

/** The `creditApp` to be created by this mutation. */
export type CreditAppLeadIdFkeyCreditAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<CreditAppLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `CreditAppInput` mutation. */
export type CreditAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnCreditAppForCreditAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<CreditAppOnCreditAppForCreditAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<CreditAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `creditApp` in the `LeadInput` mutation. */
export type CreditAppLeadIdFkeyInverseInput = {
  /** The primary key(s) for `creditApp` for the far side of the relationship. */
  connectById?: Maybe<CreditAppCreditAppPkeyConnect>;
  /** The primary key(s) for `creditApp` for the far side of the relationship. */
  connectByLeadId?: Maybe<CreditAppCreditAppLeadIdKeyConnect>;
  /** The primary key(s) for `creditApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<CreditAppNodeIdConnect>;
  /** The primary key(s) and patch data for `creditApp` for the far side of the relationship. */
  updateById?: Maybe<CreditAppOnCreditAppForCreditAppLeadIdFkeyUsingCreditAppPkeyUpdate>;
  /** The primary key(s) and patch data for `creditApp` for the far side of the relationship. */
  updateByLeadId?: Maybe<CreditAppOnCreditAppForCreditAppLeadIdFkeyUsingCreditAppLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `creditApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnCreditAppForCreditAppLeadIdFkeyNodeIdUpdate>;
  /** A `CreditAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CreditAppLeadIdFkeyCreditAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type CreditAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type CreditAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Maximum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

export type CreditAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Minimum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CreditAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `creditApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CreditAppOnCreditAppForCreditAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `creditApp` to look up the row to update. */
export type CreditAppOnCreditAppForCreditAppLeadIdFkeyUsingCreditAppLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `creditApp` being updated. */
  patch: UpdateCreditAppOnCreditAppForCreditAppLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `creditApp` to look up the row to update. */
export type CreditAppOnCreditAppForCreditAppLeadIdFkeyUsingCreditAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `creditApp` being updated. */
  patch: UpdateCreditAppOnCreditAppForCreditAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CreditApp`. Fields that are set will be updated. */
export type CreditAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<CreditAppLeadIdFkeyInput>;
};

export type CreditAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Population standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

export type CreditAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

export type CreditAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of monthlyIncome across the matching connection */
  monthlyIncome: Scalars['Float'];
  /** Sum of monthlyHomePayment across the matching connection */
  monthlyHomePayment: Scalars['Float'];
};

export type CreditAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Population variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

export type CreditAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthlyIncome across the matching connection */
  monthlyIncome?: Maybe<Scalars['Float']>;
  /** Sample variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `CreditApp` values. */
export type CreditAppsConnection = {
  /** A list of `CreditApp` objects. */
  nodes: Array<CreditApp>;
  /** A list of edges which contains the `CreditApp` and cursor to aid in pagination. */
  edges: Array<CreditAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreditApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CreditAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CreditAppAggregates>>;
};


/** A connection to a list of `CreditApp` values. */
export type CreditAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CreditAppGroupBy>;
  having?: Maybe<CreditAppHavingInput>;
};

/** A `CreditApp` edge in the connection. */
export type CreditAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CreditApp` at the end of the edge. */
  node: CreditApp;
};

/** Methods to use when ordering `CreditApp`. */
export enum CreditAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  DesiredVehicleAsc = 'DESIRED_VEHICLE_ASC',
  DesiredVehicleDesc = 'DESIRED_VEHICLE_DESC',
  JobTitleAsc = 'JOB_TITLE_ASC',
  JobTitleDesc = 'JOB_TITLE_DESC',
  MonthlyIncomeAsc = 'MONTHLY_INCOME_ASC',
  MonthlyIncomeDesc = 'MONTHLY_INCOME_DESC',
  PreferredContactMethodAsc = 'PREFERRED_CONTACT_METHOD_ASC',
  PreferredContactMethodDesc = 'PREFERRED_CONTACT_METHOD_DESC',
  SalaryHourlyAsc = 'SALARY_HOURLY_ASC',
  SalaryHourlyDesc = 'SALARY_HOURLY_DESC',
  IncomeSourceAsc = 'INCOME_SOURCE_ASC',
  IncomeSourceDesc = 'INCOME_SOURCE_DESC',
  IncomeTimeAsc = 'INCOME_TIME_ASC',
  IncomeTimeDesc = 'INCOME_TIME_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  CreditConcernAsc = 'CREDIT_CONCERN_ASC',
  CreditConcernDesc = 'CREDIT_CONCERN_DESC',
  ResidenceTimeAsc = 'RESIDENCE_TIME_ASC',
  ResidenceTimeDesc = 'RESIDENCE_TIME_DESC',
  RentOrOwnAsc = 'RENT_OR_OWN_ASC',
  RentOrOwnDesc = 'RENT_OR_OWN_DESC',
  MonthlyHomePaymentAsc = 'MONTHLY_HOME_PAYMENT_ASC',
  MonthlyHomePaymentDesc = 'MONTHLY_HOME_PAYMENT_DESC',
  EmploymentStatusAsc = 'EMPLOYMENT_STATUS_ASC',
  EmploymentStatusDesc = 'EMPLOYMENT_STATUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CreditAuthInput = {
  id?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  dateOfBirth: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  streetNumber: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  dealerCreditConsent: Scalars['Boolean'];
  sourceUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<LeadCampaignInputType>;
  meta?: Maybe<Scalars['JSON']>;
  lenderApp?: Maybe<LenderAppInput>;
};

export type CreditBureau = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  avaAppId: Scalars['Int'];
  /** company | lead */
  type: Scalars['String'];
  createdAt: Scalars['Datetime'];
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  bureau?: Maybe<CreditReport>;
  expired: Scalars['Boolean'];
};

export type CreditBureauAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CreditBureauSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CreditBureauDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CreditBureauMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CreditBureauMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CreditBureauAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CreditBureauStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CreditBureauStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CreditBureauVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CreditBureauVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CreditBureau` object types. */
export type CreditBureauAggregatesFilter = {
  /** A filter that must pass for the relevant `CreditBureau` object to be included within the aggregate. */
  filter?: Maybe<CreditBureauFilter>;
  /** Sum aggregate over matching `CreditBureau` objects. */
  sum?: Maybe<CreditBureauSumAggregateFilter>;
  /** Distinct count aggregate over matching `CreditBureau` objects. */
  distinctCount?: Maybe<CreditBureauDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CreditBureau` objects. */
  min?: Maybe<CreditBureauMinAggregateFilter>;
  /** Maximum aggregate over matching `CreditBureau` objects. */
  max?: Maybe<CreditBureauMaxAggregateFilter>;
  /** Mean average aggregate over matching `CreditBureau` objects. */
  average?: Maybe<CreditBureauAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CreditBureau` objects. */
  stddevSample?: Maybe<CreditBureauStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CreditBureau` objects. */
  stddevPopulation?: Maybe<CreditBureauStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CreditBureau` objects. */
  varianceSample?: Maybe<CreditBureauVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CreditBureau` objects. */
  variancePopulation?: Maybe<CreditBureauVariancePopulationAggregateFilter>;
};

/** The `avaApp` to be created by this mutation. */
export type CreditBureauAvaAppIdFkeyAvaAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** The `creditBureau` to be created by this mutation. */
export type CreditBureauAvaAppIdFkeyCreditBureauCreateInput = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
};

/** Input for the nested mutation of `avaApp` in the `CreditBureauInput` mutation. */
export type CreditBureauAvaAppIdFkeyInput = {
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectById?: Maybe<AvaAppAvaAppPkeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByLeadId?: Maybe<AvaAppAvaAppLeadIdKeyConnect>;
  /** The primary key(s) for `avaApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<AvaAppNodeIdConnect>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateById?: Maybe<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppPkeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByLeadId?: Maybe<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyUsingAvaAppLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `avaApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate>;
  /** A `AvaAppInput` object that will be created and connected to this object. */
  create?: Maybe<CreditBureauAvaAppIdFkeyAvaAppCreateInput>;
};

/** Input for the nested mutation of `creditBureau` in the `AvaAppInput` mutation. */
export type CreditBureauAvaAppIdFkeyInverseInput = {
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectById?: Maybe<Array<CreditBureauCreditBureauPkeyConnect>>;
  /** The primary key(s) for `creditBureau` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CreditBureauNodeIdConnect>>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateById?: Maybe<Array<CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyUsingCreditBureauPkeyUpdate>>;
  /** The primary key(s) and patch data for `creditBureau` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<AvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate>>;
  /** A `CreditBureauInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CreditBureauAvaAppIdFkeyCreditBureauCreateInput>>;
};

export type CreditBureauAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CreditBureau` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CreditBureauCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `avaAppId` field. */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creditScore` field. */
  creditScore?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rawBureau` field. */
  rawBureau?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `rawExpired` field. */
  rawExpired?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `xml` field. */
  xml?: Maybe<Scalars['String']>;
};

/** The fields on `creditBureau` to look up the row to connect. */
export type CreditBureauCreditBureauPkeyConnect = {
  id: Scalars['Int'];
};

export type CreditBureauDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  avaAppId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  creditScore?: Maybe<BigIntFilter>;
  rawBureau?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  rawExpired?: Maybe<BigIntFilter>;
  xml?: Maybe<BigIntFilter>;
};

export type CreditBureauDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawBureau across the matching connection */
  rawBureau?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawExpired across the matching connection */
  rawExpired?: Maybe<Scalars['BigInt']>;
  /** Distinct count of xml across the matching connection */
  xml?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CreditBureau` object types. All fields are combined with a logical ‘and.’ */
export type CreditBureauFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `avaAppId` field. */
  avaAppId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `creditScore` field. */
  creditScore?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `rawExpired` field. */
  rawExpired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `xml` field. */
  xml?: Maybe<StringFilter>;
  /** Filter by the object’s `avaApp` relation. */
  avaApp?: Maybe<AvaAppFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CreditBureauFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CreditBureauFilter>>;
  /** Negates the expression. */
  not?: Maybe<CreditBureauFilter>;
};

/** Grouping methods for `CreditBureau` for usage during aggregation. */
export enum CreditBureauGroupBy {
  AvaAppId = 'AVA_APP_ID',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreditScore = 'CREDIT_SCORE',
  RawBureau = 'RAW_BUREAU',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  RawExpired = 'RAW_EXPIRED',
  Xml = 'XML'
}

export type CreditBureauHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CreditBureau` aggregates. */
export type CreditBureauHavingInput = {
  AND?: Maybe<Array<CreditBureauHavingInput>>;
  OR?: Maybe<Array<CreditBureauHavingInput>>;
  sum?: Maybe<CreditBureauHavingSumInput>;
  distinctCount?: Maybe<CreditBureauHavingDistinctCountInput>;
  min?: Maybe<CreditBureauHavingMinInput>;
  max?: Maybe<CreditBureauHavingMaxInput>;
  average?: Maybe<CreditBureauHavingAverageInput>;
  stddevSample?: Maybe<CreditBureauHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CreditBureauHavingStddevPopulationInput>;
  varianceSample?: Maybe<CreditBureauHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CreditBureauHavingVariancePopulationInput>;
};

export type CreditBureauHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CreditBureauHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  avaAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  creditScore?: Maybe<HavingIntFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CreditBureau` */
export type CreditBureauInput = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
};

export type CreditBureauMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  avaAppId?: Maybe<IntFilter>;
  creditScore?: Maybe<IntFilter>;
};

export type CreditBureauMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Maximum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

export type CreditBureauMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  avaAppId?: Maybe<IntFilter>;
  creditScore?: Maybe<IntFilter>;
};

export type CreditBureauMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['Int']>;
  /** Minimum of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CreditBureauNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `creditBureau` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: AvaAppPatch;
};

/** The fields on `creditBureau` to look up the row to update. */
export type CreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyUsingCreditBureauPkeyUpdate = {
  /** An object where the defined keys will be set on the `creditBureau` being updated. */
  patch: UpdateCreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CreditBureau`. Fields that are set will be updated. */
export type CreditBureauPatch = {
  id?: Maybe<Scalars['Int']>;
  avaAppId?: Maybe<Scalars['Int']>;
  /** company | lead */
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
};

export type CreditBureauStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  avaAppId?: Maybe<BigIntFilter>;
  creditScore?: Maybe<BigIntFilter>;
};

export type CreditBureauSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of avaAppId across the matching connection */
  avaAppId: Scalars['BigInt'];
  /** Sum of creditScore across the matching connection */
  creditScore: Scalars['BigInt'];
};

export type CreditBureauVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

export type CreditBureauVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  avaAppId?: Maybe<BigFloatFilter>;
  creditScore?: Maybe<BigFloatFilter>;
};

export type CreditBureauVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of avaAppId across the matching connection */
  avaAppId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of creditScore across the matching connection */
  creditScore?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CreditBureau` values. */
export type CreditBureausConnection = {
  /** A list of `CreditBureau` objects. */
  nodes: Array<CreditBureau>;
  /** A list of edges which contains the `CreditBureau` and cursor to aid in pagination. */
  edges: Array<CreditBureausEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreditBureau` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CreditBureauAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CreditBureauAggregates>>;
};


/** A connection to a list of `CreditBureau` values. */
export type CreditBureausConnectionGroupedAggregatesArgs = {
  groupBy: Array<CreditBureauGroupBy>;
  having?: Maybe<CreditBureauHavingInput>;
};

/** A `CreditBureau` edge in the connection. */
export type CreditBureausEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CreditBureau` at the end of the edge. */
  node: CreditBureau;
};

/** Methods to use when ordering `CreditBureau`. */
export enum CreditBureausOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  AvaAppIdAsc = 'AVA_APP_ID_ASC',
  AvaAppIdDesc = 'AVA_APP_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreditScoreAsc = 'CREDIT_SCORE_ASC',
  CreditScoreDesc = 'CREDIT_SCORE_DESC',
  RawBureauAsc = 'RAW_BUREAU_ASC',
  RawBureauDesc = 'RAW_BUREAU_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  RawExpiredAsc = 'RAW_EXPIRED_ASC',
  RawExpiredDesc = 'RAW_EXPIRED_DESC',
  XmlAsc = 'XML_ASC',
  XmlDesc = 'XML_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CreditConsentStatus = {
  leadId: Scalars['UUID'];
  consentAlreadyGiven: Scalars['Boolean'];
  companyDetails: BasicCompanyDetails;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreditQuery = {
  transactionKey?: Maybe<Scalars['String']>;
  interactiveQuery?: Maybe<InteractiveQuery>;
  assessmentComplete?: Maybe<AssessmentComplete>;
  fieldErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  equifaxServerError?: Maybe<Scalars['Boolean']>;
  equifaxErrorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  fraudCheckFailed?: Maybe<Scalars['Boolean']>;
  consumer: Scalars['ID'];
  report?: Maybe<CreditReport>;
  lenderOffers?: Maybe<LenderOffers>;
  lenderApp?: Maybe<LenderAppResult>;
};

export type CreditQueryInput = {
  transactionKey?: Maybe<Scalars['String']>;
  queryId?: Maybe<Scalars['String']>;
  answers: Array<CreditAnswerInput>;
  consumer: Scalars['ID'];
};

export type CreditQuestion = {
  questionId: Scalars['String'];
  questionText: Scalars['String'];
  answers?: Maybe<Array<CreditAnswer>>;
};

export type CreditReport = {
  score?: Maybe<Score>;
  trades: Array<Trade>;
  tradeLines: TradeLines;
  inquiries: Array<Inquiry>;
  expired?: Maybe<Scalars['Boolean']>;
};

export type Cta = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyOverride: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyCtaCtaIdAndGroupId: CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByCtaWidgetCtaIdAndWidgetId: CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnection;
};


export type CtaCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type CtaCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


export type CtaCompanyOverrideArgs = {
  inputGroup?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type CtaGroupsByCompanyCtaCtaIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};

export type CtaAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaVariancePopulationAggregates>;
};

export type CtaAnalyticsFilter = {
  hour?: Maybe<DatetimeFilter>;
};

export type CtaAnalyticsResponse = {
  nodes: Array<CtaAnalyticsSummary>;
};

export type CtaAnalyticsSummary = {
  id?: Maybe<Scalars['String']>;
  ctaId?: Maybe<Scalars['String']>;
  ctaVersion?: Maybe<Scalars['String']>;
  ctaOverride?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  companyGroup?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  companyProducts: Array<ProductType>;
  product?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ctaImpression?: Maybe<Scalars['Int']>;
  ctaLoaded?: Maybe<Scalars['Int']>;
  widgetOpened?: Maybe<Scalars['Int']>;
  widgetClosed?: Maybe<Scalars['Int']>;
  userEngagement?: Maybe<Scalars['Int']>;
  partialLead?: Maybe<Scalars['Int']>;
  creditLead?: Maybe<Scalars['Int']>;
  creditVerifiedLead?: Maybe<Scalars['Int']>;
  tradeLead?: Maybe<Scalars['Int']>;
};

export type CtaAsset = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Group` that is related to this `CtaAsset`. */
  group?: Maybe<Group>;
  href: Scalars['String'];
};

export type CtaAssetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaAssetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaAssetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaAssetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaAssetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaAssetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaAssetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaAssetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaAssetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaAssetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CtaAsset` object types. */
export type CtaAssetAggregatesFilter = {
  /** A filter that must pass for the relevant `CtaAsset` object to be included within the aggregate. */
  filter?: Maybe<CtaAssetFilter>;
  /** Sum aggregate over matching `CtaAsset` objects. */
  sum?: Maybe<CtaAssetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CtaAsset` objects. */
  distinctCount?: Maybe<CtaAssetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CtaAsset` objects. */
  min?: Maybe<CtaAssetMinAggregateFilter>;
  /** Maximum aggregate over matching `CtaAsset` objects. */
  max?: Maybe<CtaAssetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CtaAsset` objects. */
  average?: Maybe<CtaAssetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CtaAsset` objects. */
  stddevSample?: Maybe<CtaAssetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CtaAsset` objects. */
  stddevPopulation?: Maybe<CtaAssetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CtaAsset` objects. */
  varianceSample?: Maybe<CtaAssetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CtaAsset` objects. */
  variancePopulation?: Maybe<CtaAssetVariancePopulationAggregateFilter>;
};

export type CtaAssetAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CtaAsset` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CtaAssetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bucket` field. */
  bucket?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ipAddress` field. */
  ipAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `ctaAsset` to look up the row to connect. */
export type CtaAssetCtaAssetsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `ctaAsset` to look up the row to connect. */
export type CtaAssetCtaAssetsUqGroupIdNameConnect = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
};

export type CtaAssetDetails = {
  id: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
  href: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type CtaAssetDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
  name?: Maybe<BigIntFilter>;
  bucket?: Maybe<BigIntFilter>;
  key?: Maybe<BigIntFilter>;
  description?: Maybe<BigIntFilter>;
  email?: Maybe<BigIntFilter>;
  ipAddress?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CtaAssetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bucket across the matching connection */
  bucket?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ipAddress across the matching connection */
  ipAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CtaAsset` object types. All fields are combined with a logical ‘and.’ */
export type CtaAssetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `bucket` field. */
  bucket?: Maybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `ipAddress` field. */
  ipAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaAssetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaAssetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaAssetFilter>;
};

export type CtaAssetMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CtaAssetMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

export type CtaAssetMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  groupId?: Maybe<IntFilter>;
};

export type CtaAssetMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaAssetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `ctaAsset` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `ctaAsset` to look up the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `ctaAsset` to look up the row to update. */
export type CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsUqGroupIdNameUpdate = {
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  groupId: Scalars['Int'];
  name: Scalars['String'];
};

/** Represents an update to a `CtaAsset`. Fields that are set will be updated. */
export type CtaAssetPatch = {
  id?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CtaAssetsGroupIdFkeyInput>;
};

export type CtaAssetStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  groupId?: Maybe<BigIntFilter>;
};

export type CtaAssetSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
};

export type CtaAssetVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

export type CtaAssetVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  groupId?: Maybe<BigFloatFilter>;
};

export type CtaAssetVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CtaAsset` values. */
export type CtaAssetsConnection = {
  /** A list of `CtaAsset` objects. */
  nodes: Array<CtaAsset>;
  /** A list of edges which contains the `CtaAsset` and cursor to aid in pagination. */
  edges: Array<CtaAssetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CtaAsset` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAssetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAssetAggregates>>;
};


/** A connection to a list of `CtaAsset` values. */
export type CtaAssetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtaAssetsGroupBy>;
  having?: Maybe<CtaAssetsHavingInput>;
};

/** A `CtaAsset` edge in the connection. */
export type CtaAssetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CtaAsset` at the end of the edge. */
  node: CtaAsset;
};

/** Grouping methods for `CtaAsset` for usage during aggregation. */
export enum CtaAssetsGroupBy {
  GroupId = 'GROUP_ID',
  Name = 'NAME',
  Bucket = 'BUCKET',
  Key = 'KEY',
  Description = 'DESCRIPTION',
  Email = 'EMAIL',
  IpAddress = 'IP_ADDRESS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The `group` to be created by this mutation. */
export type CtaAssetsGroupIdFkeyGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `CtaAssetInput` mutation. */
export type CtaAssetsGroupIdFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<CtaAssetsGroupIdFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `ctaAsset` in the `GroupInput` mutation. */
export type CtaAssetsGroupIdFkeyInverseInput = {
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaAssetCtaAssetsPkeyConnect>>;
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectByGroupIdAndName?: Maybe<Array<CtaAssetCtaAssetsUqGroupIdNameConnect>>;
  /** The primary key(s) for `ctaAsset` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaAssetNodeIdConnect>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateByGroupIdAndName?: Maybe<Array<CtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyUsingCtaAssetsUqGroupIdNameUpdate>>;
  /** The primary key(s) and patch data for `ctaAsset` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate>>;
};

export type CtaAssetsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CtaAsset` aggregates. */
export type CtaAssetsHavingInput = {
  AND?: Maybe<Array<CtaAssetsHavingInput>>;
  OR?: Maybe<Array<CtaAssetsHavingInput>>;
  sum?: Maybe<CtaAssetsHavingSumInput>;
  distinctCount?: Maybe<CtaAssetsHavingDistinctCountInput>;
  min?: Maybe<CtaAssetsHavingMinInput>;
  max?: Maybe<CtaAssetsHavingMaxInput>;
  average?: Maybe<CtaAssetsHavingAverageInput>;
  stddevSample?: Maybe<CtaAssetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtaAssetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtaAssetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtaAssetsHavingVariancePopulationInput>;
};

export type CtaAssetsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaAssetsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CtaAsset`. */
export enum CtaAssetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  BucketAsc = 'BUCKET_ASC',
  BucketDesc = 'BUCKET_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CtaAverageAggregates = {
  /** Mean average of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaCatalogNode = {
  overrides: Array<CtaCatalogOverride>;
  themes: Array<Scalars['String']>;
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

export type CtaCatalogOverride = {
  id: Scalars['Int'];
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
};

export type CtaCatalogResults = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
  catalog: Array<CtaCatalogNode>;
};

/** A condition to be used against `Cta` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CtaCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `product` field. */
  product?: Maybe<ProductType>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<CtaType>;
  /** Checks for equality with the object’s `version` field. */
  version?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `published` field. */
  published?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `ctaStyles` field. */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaStylesStatic` field. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `ctaTree` field. */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `templateSpec` field. */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `templateDef` field. */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeSpec` field. */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `themeDef` field. */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `pluginDef` field. */
  pluginDef?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invalidate` field. */
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `themes` field. */
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The fields on `cta` to look up the row to connect. */
export type CtaCtasPkeyConnect = {
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to delete. */
export type CtaCtasPkeyDelete = {
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to connect. */
export type CtaCtasProductTypeVersionKeyConnect = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** The fields on `cta` to look up the row to delete. */
export type CtaCtasProductTypeVersionKeyDelete = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

export type CtaDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of product across the matching connection */
  product?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of version across the matching connection */
  version?: Maybe<Scalars['BigInt']>;
  /** Distinct count of published across the matching connection */
  published?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaStyles across the matching connection */
  ctaStyles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaStylesStatic across the matching connection */
  ctaStylesStatic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaTree across the matching connection */
  ctaTree?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateSpec across the matching connection */
  templateSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateDef across the matching connection */
  templateDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeSpec across the matching connection */
  themeSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themeDef across the matching connection */
  themeDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pluginDef across the matching connection */
  pluginDef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invalidate across the matching connection */
  invalidate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of themes across the matching connection */
  themes?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Cta` object types. All fields are combined with a logical ‘and.’ */
export type CtaFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `product` field. */
  product?: Maybe<ProductTypeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<CtaTypeFilter>;
  /** Filter by the object’s `version` field. */
  version?: Maybe<StringFilter>;
  /** Filter by the object’s `published` field. */
  published?: Maybe<BooleanFilter>;
  /** Filter by the object’s `ctaStyles` field. */
  ctaStyles?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaStylesStatic` field. */
  ctaStylesStatic?: Maybe<StringFilter>;
  /** Filter by the object’s `templateSpec` field. */
  templateSpec?: Maybe<JsonFilter>;
  /** Filter by the object’s `templateDef` field. */
  templateDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeSpec` field. */
  themeSpec?: Maybe<JsonFilter>;
  /** Filter by the object’s `themeDef` field. */
  themeDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `pluginDef` field. */
  pluginDef?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `compatibleMajor` field. */
  compatibleMajor?: Maybe<IntFilter>;
  /** Filter by the object’s `invalidate` field. */
  invalidate?: Maybe<StringListFilter>;
  /** Filter by the object’s `themes` field. */
  themes?: Maybe<StringListFilter>;
  /** Filter by the object’s `companyCtas` relation. */
  companyCtas?: Maybe<CtaToManyCompanyCtaFilter>;
  /** Some related `companyCtas` exist. */
  companyCtasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaWidgets` relation. */
  ctaWidgets?: Maybe<CtaToManyCtaWidgetFilter>;
  /** Some related `ctaWidgets` exist. */
  ctaWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaFilter>;
};

/** A connection to a list of `Group` values, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyCta`, and the cursor to aid in pagination. */
  edges: Array<CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
};


/** A `Group` edge in the connection, with data from `CompanyCta`. */
export type CtaGroupsByCompanyCtaCtaIdAndGroupIdManyToManyEdgeCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};

/** An input for mutations affecting `Cta` */
export type CtaInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

export type CtaMaxAggregates = {
  /** Maximum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

export type CtaMinAggregates = {
  /** Minimum of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CtaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `cta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCompanyCtaForCompanyCtasCtaIdFkeyUsingCtasProductTypeVersionKeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasPkeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `cta` to look up the row to update. */
export type CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasProductTypeVersionKeyUpdate = {
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** Represents an update to a `Cta`. Fields that are set will be updated. */
export type CtaPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

export type CtaSpecs = {
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree: Scalars['JSON'];
  templateSpec: Scalars['JSON'];
  templateDef: Scalars['JSON'];
  themeSpec: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  pluginDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export enum CtaSpecsErrors {
  ProductNotEnabled = 'ProductNotEnabled'
}

export type CtaSpecsInput = {
  widgetId: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
};

export type CtaSpecsResponse = {
  cta?: Maybe<CtaSpecs>;
  overrides?: Maybe<CompanyCtaOverrides>;
  ctaAssets?: Maybe<Array<CtaAssetDetails>>;
  error?: Maybe<CtaSpecsResponseError>;
};

export type CtaSpecsResponseError = {
  message: Scalars['String'];
  code?: Maybe<Scalars['Int']>;
  type: CtaSpecsErrors;
};

export type CtaStddevPopulationAggregates = {
  /** Population standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaStddevSampleAggregates = {
  /** Sample standard deviation of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaSumAggregates = {
  /** Sum of compatibleMajor across the matching connection */
  compatibleMajor: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type CtaToManyCompanyCtaFilter = {
  /** Every related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyCtaFilter>;
  /** Some related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyCtaFilter>;
  /** No related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyCtaFilter>;
  /** Aggregates across related `CompanyCta` match the filter criteria. */
  aggregates?: Maybe<CompanyCtaAggregatesFilter>;
};

/** A filter to be used against many `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type CtaToManyCtaWidgetFilter = {
  /** Every related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaWidgetFilter>;
  /** Some related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaWidgetFilter>;
  /** No related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaWidgetFilter>;
  /** Aggregates across related `CtaWidget` match the filter criteria. */
  aggregates?: Maybe<CtaWidgetAggregatesFilter>;
};

export enum CtaType {
  Floating = 'FLOATING',
  Banner = 'BANNER',
  Inline = 'INLINE',
  Button = 'BUTTON',
  Hero = 'HERO',
  BannerHero = 'BANNER_HERO',
  OemBanner = 'OEM_BANNER',
  OemInline = 'OEM_INLINE',
  InlineHero = 'INLINE_HERO'
}

/** A filter to be used against CtaType fields. All fields are combined with a logical ‘and.’ */
export type CtaTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<CtaType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<CtaType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<CtaType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<CtaType>;
  /** Included in the specified list. */
  in?: Maybe<Array<CtaType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<CtaType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<CtaType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<CtaType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<CtaType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<CtaType>;
};

export type CtaVariancePopulationAggregates = {
  /** Population variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaVarianceSampleAggregates = {
  /** Sample variance of compatibleMajor across the matching connection */
  compatibleMajor?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
};

export type CtaWidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CtaWidgetSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CtaWidgetDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CtaWidgetMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CtaWidgetMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CtaWidgetAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CtaWidgetStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CtaWidgetStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CtaWidgetVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CtaWidgetVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `CtaWidget` object types. */
export type CtaWidgetAggregatesFilter = {
  /** A filter that must pass for the relevant `CtaWidget` object to be included within the aggregate. */
  filter?: Maybe<CtaWidgetFilter>;
  /** Sum aggregate over matching `CtaWidget` objects. */
  sum?: Maybe<CtaWidgetSumAggregateFilter>;
  /** Distinct count aggregate over matching `CtaWidget` objects. */
  distinctCount?: Maybe<CtaWidgetDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `CtaWidget` objects. */
  min?: Maybe<CtaWidgetMinAggregateFilter>;
  /** Maximum aggregate over matching `CtaWidget` objects. */
  max?: Maybe<CtaWidgetMaxAggregateFilter>;
  /** Mean average aggregate over matching `CtaWidget` objects. */
  average?: Maybe<CtaWidgetAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CtaWidget` objects. */
  stddevSample?: Maybe<CtaWidgetStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `CtaWidget` objects. */
  stddevPopulation?: Maybe<CtaWidgetStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CtaWidget` objects. */
  varianceSample?: Maybe<CtaWidgetVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `CtaWidget` objects. */
  variancePopulation?: Maybe<CtaWidgetVariancePopulationAggregateFilter>;
};

export type CtaWidgetAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CtaWidget` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CtaWidgetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ctaId` field. */
  ctaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `staticId` field. */
  staticId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** The fields on `ctaWidget` to look up the row to connect. */
export type CtaWidgetCtaWidgetsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `ctaWidget` to look up the row to delete. */
export type CtaWidgetCtaWidgetsPkeyDelete = {
  id: Scalars['Int'];
};

export type CtaWidgetDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  ctaId?: Maybe<BigIntFilter>;
  staticId?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type CtaWidgetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaId across the matching connection */
  ctaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of staticId across the matching connection */
  staticId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type CtaWidgetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `ctaId` field. */
  ctaId?: Maybe<StringFilter>;
  /** Filter by the object’s `staticId` field. */
  staticId?: Maybe<StringFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `cta` relation. */
  cta?: Maybe<CtaFilter>;
  /** A related `cta` exists. */
  ctaExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CtaWidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CtaWidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<CtaWidgetFilter>;
};

/** An input for mutations affecting `CtaWidget` */
export type CtaWidgetInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

export type CtaWidgetMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type CtaWidgetMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type CtaWidgetMinAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type CtaWidgetMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CtaWidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CtaWidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `cta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `cta` being updated. */
  patch: CtaPatch;
};

/** The fields on `ctaWidget` to look up the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtaWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: UpdateCtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `ctaWidget` to look up the row to update. */
export type CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingCtaWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: UpdateCtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `CtaWidget`. Fields that are set will be updated. */
export type CtaWidgetPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

export type CtaWidgetStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
};

export type CtaWidgetSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type CtaWidgetVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type CtaWidgetVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type CtaWidgetVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `CtaWidget`, and the cursor to aid in pagination. */
  edges: Array<CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
};


/** A `Widget` edge in the connection, with data from `CtaWidget`. */
export type CtaWidgetsByCtaWidgetCtaIdAndWidgetIdManyToManyEdgeCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};

/** A connection to a list of `CtaWidget` values. */
export type CtaWidgetsConnection = {
  /** A list of `CtaWidget` objects. */
  nodes: Array<CtaWidget>;
  /** A list of edges which contains the `CtaWidget` and cursor to aid in pagination. */
  edges: Array<CtaWidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CtaWidget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaWidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaWidgetAggregates>>;
};


/** A connection to a list of `CtaWidget` values. */
export type CtaWidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtaWidgetsGroupBy>;
  having?: Maybe<CtaWidgetsHavingInput>;
};

/** The `ctaWidget` to be created by this mutation. */
export type CtaWidgetsCtaIdFkCtaWidgetsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** The `cta` to be created by this mutation. */
export type CtaWidgetsCtaIdFkCtasCreateInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree: Scalars['JSON'];
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec: Scalars['JSON'];
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef: Scalars['JSON'];
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec: Scalars['JSON'];
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef: Scalars['JSON'];
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** Input for the nested mutation of `cta` in the `CtaWidgetInput` mutation. */
export type CtaWidgetsCtaIdFkInput = {
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectById?: Maybe<CtaCtasPkeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  connectByNodeId?: Maybe<CtaNodeIdConnect>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteById?: Maybe<CtaCtasPkeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByProductAndTypeAndVersion?: Maybe<CtaCtasProductTypeVersionKeyDelete>;
  /** The primary key(s) for `cta` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CtaNodeIdDelete>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateById?: Maybe<CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasPkeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByProductAndTypeAndVersion?: Maybe<CtaOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtasProductTypeVersionKeyUpdate>;
  /** The primary key(s) and patch data for `cta` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate>;
  /** A `CtaInput` object that will be created and connected to this object. */
  create?: Maybe<CtaWidgetsCtaIdFkCtasCreateInput>;
};

/** Input for the nested mutation of `ctaWidget` in the `CtaInput` mutation. */
export type CtaWidgetsCtaIdFkInverseInput = {
  /** Flag indicating whether all other `ctaWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaWidgetNodeIdConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyDelete>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CtaWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkUsingCtaWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CtaOnCtaWidgetForCtaWidgetsCtaIdFkNodeIdUpdate>>;
  /** A `CtaWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CtaWidgetsCtaIdFkCtaWidgetsCreateInput>>;
};

/** A `CtaWidget` edge in the connection. */
export type CtaWidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CtaWidget` at the end of the edge. */
  node: CtaWidget;
};

/** Grouping methods for `CtaWidget` for usage during aggregation. */
export enum CtaWidgetsGroupBy {
  CtaId = 'CTA_ID',
  StaticId = 'STATIC_ID',
  WidgetId = 'WIDGET_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type CtaWidgetsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CtaWidget` aggregates. */
export type CtaWidgetsHavingInput = {
  AND?: Maybe<Array<CtaWidgetsHavingInput>>;
  OR?: Maybe<Array<CtaWidgetsHavingInput>>;
  sum?: Maybe<CtaWidgetsHavingSumInput>;
  distinctCount?: Maybe<CtaWidgetsHavingDistinctCountInput>;
  min?: Maybe<CtaWidgetsHavingMinInput>;
  max?: Maybe<CtaWidgetsHavingMaxInput>;
  average?: Maybe<CtaWidgetsHavingAverageInput>;
  stddevSample?: Maybe<CtaWidgetsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtaWidgetsHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtaWidgetsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtaWidgetsHavingVariancePopulationInput>;
};

export type CtaWidgetsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CtaWidgetsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CtaWidget`. */
export enum CtaWidgetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CtaIdAsc = 'CTA_ID_ASC',
  CtaIdDesc = 'CTA_ID_DESC',
  StaticIdAsc = 'STATIC_ID_ASC',
  StaticIdDesc = 'STATIC_ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `ctaWidget` to be created by this mutation. */
export type CtaWidgetsWidgetIdFkCtaWidgetsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** Input for the nested mutation of `widget` in the `CtaWidgetInput` mutation. */
export type CtaWidgetsWidgetIdFkInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<CtaWidgetsWidgetIdFkWidgetsCreateInput>;
};

/** Input for the nested mutation of `ctaWidget` in the `WidgetInput` mutation. */
export type CtaWidgetsWidgetIdFkInverseInput = {
  /** Flag indicating whether all other `ctaWidget` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CtaWidgetNodeIdConnect>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteById?: Maybe<Array<CtaWidgetCtaWidgetsPkeyDelete>>;
  /** The primary key(s) for `ctaWidget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CtaWidgetNodeIdDelete>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateById?: Maybe<Array<CtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingCtaWidgetsPkeyUpdate>>;
  /** The primary key(s) and patch data for `ctaWidget` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate>>;
  /** A `CtaWidgetInput` object that will be created and connected to this object. */
  create?: Maybe<Array<CtaWidgetsWidgetIdFkCtaWidgetsCreateInput>>;
};

/** The `widget` to be created by this mutation. */
export type CtaWidgetsWidgetIdFkWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** A connection to a list of `Cta` values. */
export type CtasConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta` and cursor to aid in pagination. */
  edges: Array<CtasEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values. */
export type CtasConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection. */
export type CtasEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
};

/** Grouping methods for `Cta` for usage during aggregation. */
export enum CtasGroupBy {
  Product = 'PRODUCT',
  Type = 'TYPE',
  Version = 'VERSION',
  Published = 'PUBLISHED',
  CtaStyles = 'CTA_STYLES',
  CtaStylesStatic = 'CTA_STYLES_STATIC',
  CtaTree = 'CTA_TREE',
  TemplateSpec = 'TEMPLATE_SPEC',
  TemplateDef = 'TEMPLATE_DEF',
  ThemeSpec = 'THEME_SPEC',
  ThemeDef = 'THEME_DEF',
  PluginDef = 'PLUGIN_DEF',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  CompatibleMajor = 'COMPATIBLE_MAJOR',
  Invalidate = 'INVALIDATE',
  Themes = 'THEMES'
}

export type CtasHavingAverageInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingDistinctCountInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Conditions for `Cta` aggregates. */
export type CtasHavingInput = {
  AND?: Maybe<Array<CtasHavingInput>>;
  OR?: Maybe<Array<CtasHavingInput>>;
  sum?: Maybe<CtasHavingSumInput>;
  distinctCount?: Maybe<CtasHavingDistinctCountInput>;
  min?: Maybe<CtasHavingMinInput>;
  max?: Maybe<CtasHavingMaxInput>;
  average?: Maybe<CtasHavingAverageInput>;
  stddevSample?: Maybe<CtasHavingStddevSampleInput>;
  stddevPopulation?: Maybe<CtasHavingStddevPopulationInput>;
  varianceSample?: Maybe<CtasHavingVarianceSampleInput>;
  variancePopulation?: Maybe<CtasHavingVariancePopulationInput>;
};

export type CtasHavingMaxInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingMinInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingStddevPopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingStddevSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingSumInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingVariancePopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

export type CtasHavingVarianceSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  compatibleMajor?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `Cta`. */
export enum CtasOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductAsc = 'PRODUCT_ASC',
  ProductDesc = 'PRODUCT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  PublishedAsc = 'PUBLISHED_ASC',
  PublishedDesc = 'PUBLISHED_DESC',
  CtaStylesAsc = 'CTA_STYLES_ASC',
  CtaStylesDesc = 'CTA_STYLES_DESC',
  CtaStylesStaticAsc = 'CTA_STYLES_STATIC_ASC',
  CtaStylesStaticDesc = 'CTA_STYLES_STATIC_DESC',
  CtaTreeAsc = 'CTA_TREE_ASC',
  CtaTreeDesc = 'CTA_TREE_DESC',
  TemplateSpecAsc = 'TEMPLATE_SPEC_ASC',
  TemplateSpecDesc = 'TEMPLATE_SPEC_DESC',
  TemplateDefAsc = 'TEMPLATE_DEF_ASC',
  TemplateDefDesc = 'TEMPLATE_DEF_DESC',
  ThemeSpecAsc = 'THEME_SPEC_ASC',
  ThemeSpecDesc = 'THEME_SPEC_DESC',
  ThemeDefAsc = 'THEME_DEF_ASC',
  ThemeDefDesc = 'THEME_DEF_DESC',
  PluginDefAsc = 'PLUGIN_DEF_ASC',
  PluginDefDesc = 'PLUGIN_DEF_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CompatibleMajorAsc = 'COMPATIBLE_MAJOR_ASC',
  CompatibleMajorDesc = 'COMPATIBLE_MAJOR_DESC',
  InvalidateAsc = 'INVALIDATE_ASC',
  InvalidateDesc = 'INVALIDATE_DESC',
  ThemesAsc = 'THEMES_ASC',
  ThemesDesc = 'THEMES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyCtasCountAsc = 'COMPANY_CTAS_COUNT_ASC',
  CompanyCtasCountDesc = 'COMPANY_CTAS_COUNT_DESC',
  CompanyCtasSumIdAsc = 'COMPANY_CTAS_SUM_ID_ASC',
  CompanyCtasSumIdDesc = 'COMPANY_CTAS_SUM_ID_DESC',
  CompanyCtasSumCtaIdAsc = 'COMPANY_CTAS_SUM_CTA_ID_ASC',
  CompanyCtasSumCtaIdDesc = 'COMPANY_CTAS_SUM_CTA_ID_DESC',
  CompanyCtasSumGroupIdAsc = 'COMPANY_CTAS_SUM_GROUP_ID_ASC',
  CompanyCtasSumGroupIdDesc = 'COMPANY_CTAS_SUM_GROUP_ID_DESC',
  CompanyCtasSumThemeAsc = 'COMPANY_CTAS_SUM_THEME_ASC',
  CompanyCtasSumThemeDesc = 'COMPANY_CTAS_SUM_THEME_DESC',
  CompanyCtasSumNameAsc = 'COMPANY_CTAS_SUM_NAME_ASC',
  CompanyCtasSumNameDesc = 'COMPANY_CTAS_SUM_NAME_DESC',
  CompanyCtasSumLockedAsc = 'COMPANY_CTAS_SUM_LOCKED_ASC',
  CompanyCtasSumLockedDesc = 'COMPANY_CTAS_SUM_LOCKED_DESC',
  CompanyCtasSumDefaultAsc = 'COMPANY_CTAS_SUM_DEFAULT_ASC',
  CompanyCtasSumDefaultDesc = 'COMPANY_CTAS_SUM_DEFAULT_DESC',
  CompanyCtasSumTemplateDefAsc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_ASC',
  CompanyCtasSumTemplateDefDesc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_DESC',
  CompanyCtasSumThemeDefAsc = 'COMPANY_CTAS_SUM_THEME_DEF_ASC',
  CompanyCtasSumThemeDefDesc = 'COMPANY_CTAS_SUM_THEME_DEF_DESC',
  CompanyCtasSumCreatedAtAsc = 'COMPANY_CTAS_SUM_CREATED_AT_ASC',
  CompanyCtasSumCreatedAtDesc = 'COMPANY_CTAS_SUM_CREATED_AT_DESC',
  CompanyCtasSumUpdatedAtAsc = 'COMPANY_CTAS_SUM_UPDATED_AT_ASC',
  CompanyCtasSumUpdatedAtDesc = 'COMPANY_CTAS_SUM_UPDATED_AT_DESC',
  CompanyCtasSumCompatibleMajorAsc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_ASC',
  CompanyCtasSumCompatibleMajorDesc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_DESC',
  CompanyCtasSumCtaDetailsAsc = 'COMPANY_CTAS_SUM_CTA_DETAILS_ASC',
  CompanyCtasSumCtaDetailsDesc = 'COMPANY_CTAS_SUM_CTA_DETAILS_DESC',
  CompanyCtasDistinctCountIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_ASC',
  CompanyCtasDistinctCountIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_DESC',
  CompanyCtasDistinctCountCtaIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_ASC',
  CompanyCtasDistinctCountCtaIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_DESC',
  CompanyCtasDistinctCountGroupIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyCtasDistinctCountGroupIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyCtasDistinctCountThemeAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_ASC',
  CompanyCtasDistinctCountThemeDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DESC',
  CompanyCtasDistinctCountNameAsc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_ASC',
  CompanyCtasDistinctCountNameDesc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_DESC',
  CompanyCtasDistinctCountLockedAsc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_ASC',
  CompanyCtasDistinctCountLockedDesc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_DESC',
  CompanyCtasDistinctCountDefaultAsc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_ASC',
  CompanyCtasDistinctCountDefaultDesc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_DESC',
  CompanyCtasDistinctCountTemplateDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_ASC',
  CompanyCtasDistinctCountTemplateDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_DESC',
  CompanyCtasDistinctCountThemeDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_ASC',
  CompanyCtasDistinctCountThemeDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_DESC',
  CompanyCtasDistinctCountCreatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyCtasDistinctCountCreatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyCtasDistinctCountUpdatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyCtasDistinctCountUpdatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyCtasDistinctCountCompatibleMajorAsc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_ASC',
  CompanyCtasDistinctCountCompatibleMajorDesc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_DESC',
  CompanyCtasDistinctCountCtaDetailsAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_ASC',
  CompanyCtasDistinctCountCtaDetailsDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_DESC',
  CompanyCtasMinIdAsc = 'COMPANY_CTAS_MIN_ID_ASC',
  CompanyCtasMinIdDesc = 'COMPANY_CTAS_MIN_ID_DESC',
  CompanyCtasMinCtaIdAsc = 'COMPANY_CTAS_MIN_CTA_ID_ASC',
  CompanyCtasMinCtaIdDesc = 'COMPANY_CTAS_MIN_CTA_ID_DESC',
  CompanyCtasMinGroupIdAsc = 'COMPANY_CTAS_MIN_GROUP_ID_ASC',
  CompanyCtasMinGroupIdDesc = 'COMPANY_CTAS_MIN_GROUP_ID_DESC',
  CompanyCtasMinThemeAsc = 'COMPANY_CTAS_MIN_THEME_ASC',
  CompanyCtasMinThemeDesc = 'COMPANY_CTAS_MIN_THEME_DESC',
  CompanyCtasMinNameAsc = 'COMPANY_CTAS_MIN_NAME_ASC',
  CompanyCtasMinNameDesc = 'COMPANY_CTAS_MIN_NAME_DESC',
  CompanyCtasMinLockedAsc = 'COMPANY_CTAS_MIN_LOCKED_ASC',
  CompanyCtasMinLockedDesc = 'COMPANY_CTAS_MIN_LOCKED_DESC',
  CompanyCtasMinDefaultAsc = 'COMPANY_CTAS_MIN_DEFAULT_ASC',
  CompanyCtasMinDefaultDesc = 'COMPANY_CTAS_MIN_DEFAULT_DESC',
  CompanyCtasMinTemplateDefAsc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_ASC',
  CompanyCtasMinTemplateDefDesc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_DESC',
  CompanyCtasMinThemeDefAsc = 'COMPANY_CTAS_MIN_THEME_DEF_ASC',
  CompanyCtasMinThemeDefDesc = 'COMPANY_CTAS_MIN_THEME_DEF_DESC',
  CompanyCtasMinCreatedAtAsc = 'COMPANY_CTAS_MIN_CREATED_AT_ASC',
  CompanyCtasMinCreatedAtDesc = 'COMPANY_CTAS_MIN_CREATED_AT_DESC',
  CompanyCtasMinUpdatedAtAsc = 'COMPANY_CTAS_MIN_UPDATED_AT_ASC',
  CompanyCtasMinUpdatedAtDesc = 'COMPANY_CTAS_MIN_UPDATED_AT_DESC',
  CompanyCtasMinCompatibleMajorAsc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMinCompatibleMajorDesc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMinCtaDetailsAsc = 'COMPANY_CTAS_MIN_CTA_DETAILS_ASC',
  CompanyCtasMinCtaDetailsDesc = 'COMPANY_CTAS_MIN_CTA_DETAILS_DESC',
  CompanyCtasMaxIdAsc = 'COMPANY_CTAS_MAX_ID_ASC',
  CompanyCtasMaxIdDesc = 'COMPANY_CTAS_MAX_ID_DESC',
  CompanyCtasMaxCtaIdAsc = 'COMPANY_CTAS_MAX_CTA_ID_ASC',
  CompanyCtasMaxCtaIdDesc = 'COMPANY_CTAS_MAX_CTA_ID_DESC',
  CompanyCtasMaxGroupIdAsc = 'COMPANY_CTAS_MAX_GROUP_ID_ASC',
  CompanyCtasMaxGroupIdDesc = 'COMPANY_CTAS_MAX_GROUP_ID_DESC',
  CompanyCtasMaxThemeAsc = 'COMPANY_CTAS_MAX_THEME_ASC',
  CompanyCtasMaxThemeDesc = 'COMPANY_CTAS_MAX_THEME_DESC',
  CompanyCtasMaxNameAsc = 'COMPANY_CTAS_MAX_NAME_ASC',
  CompanyCtasMaxNameDesc = 'COMPANY_CTAS_MAX_NAME_DESC',
  CompanyCtasMaxLockedAsc = 'COMPANY_CTAS_MAX_LOCKED_ASC',
  CompanyCtasMaxLockedDesc = 'COMPANY_CTAS_MAX_LOCKED_DESC',
  CompanyCtasMaxDefaultAsc = 'COMPANY_CTAS_MAX_DEFAULT_ASC',
  CompanyCtasMaxDefaultDesc = 'COMPANY_CTAS_MAX_DEFAULT_DESC',
  CompanyCtasMaxTemplateDefAsc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_ASC',
  CompanyCtasMaxTemplateDefDesc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_DESC',
  CompanyCtasMaxThemeDefAsc = 'COMPANY_CTAS_MAX_THEME_DEF_ASC',
  CompanyCtasMaxThemeDefDesc = 'COMPANY_CTAS_MAX_THEME_DEF_DESC',
  CompanyCtasMaxCreatedAtAsc = 'COMPANY_CTAS_MAX_CREATED_AT_ASC',
  CompanyCtasMaxCreatedAtDesc = 'COMPANY_CTAS_MAX_CREATED_AT_DESC',
  CompanyCtasMaxUpdatedAtAsc = 'COMPANY_CTAS_MAX_UPDATED_AT_ASC',
  CompanyCtasMaxUpdatedAtDesc = 'COMPANY_CTAS_MAX_UPDATED_AT_DESC',
  CompanyCtasMaxCompatibleMajorAsc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMaxCompatibleMajorDesc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMaxCtaDetailsAsc = 'COMPANY_CTAS_MAX_CTA_DETAILS_ASC',
  CompanyCtasMaxCtaDetailsDesc = 'COMPANY_CTAS_MAX_CTA_DETAILS_DESC',
  CompanyCtasAverageIdAsc = 'COMPANY_CTAS_AVERAGE_ID_ASC',
  CompanyCtasAverageIdDesc = 'COMPANY_CTAS_AVERAGE_ID_DESC',
  CompanyCtasAverageCtaIdAsc = 'COMPANY_CTAS_AVERAGE_CTA_ID_ASC',
  CompanyCtasAverageCtaIdDesc = 'COMPANY_CTAS_AVERAGE_CTA_ID_DESC',
  CompanyCtasAverageGroupIdAsc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_ASC',
  CompanyCtasAverageGroupIdDesc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_DESC',
  CompanyCtasAverageThemeAsc = 'COMPANY_CTAS_AVERAGE_THEME_ASC',
  CompanyCtasAverageThemeDesc = 'COMPANY_CTAS_AVERAGE_THEME_DESC',
  CompanyCtasAverageNameAsc = 'COMPANY_CTAS_AVERAGE_NAME_ASC',
  CompanyCtasAverageNameDesc = 'COMPANY_CTAS_AVERAGE_NAME_DESC',
  CompanyCtasAverageLockedAsc = 'COMPANY_CTAS_AVERAGE_LOCKED_ASC',
  CompanyCtasAverageLockedDesc = 'COMPANY_CTAS_AVERAGE_LOCKED_DESC',
  CompanyCtasAverageDefaultAsc = 'COMPANY_CTAS_AVERAGE_DEFAULT_ASC',
  CompanyCtasAverageDefaultDesc = 'COMPANY_CTAS_AVERAGE_DEFAULT_DESC',
  CompanyCtasAverageTemplateDefAsc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_ASC',
  CompanyCtasAverageTemplateDefDesc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_DESC',
  CompanyCtasAverageThemeDefAsc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_ASC',
  CompanyCtasAverageThemeDefDesc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_DESC',
  CompanyCtasAverageCreatedAtAsc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_ASC',
  CompanyCtasAverageCreatedAtDesc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_DESC',
  CompanyCtasAverageUpdatedAtAsc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_ASC',
  CompanyCtasAverageUpdatedAtDesc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_DESC',
  CompanyCtasAverageCompatibleMajorAsc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasAverageCompatibleMajorDesc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasAverageCtaDetailsAsc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_ASC',
  CompanyCtasAverageCtaDetailsDesc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_DESC',
  CompanyCtasStddevSampleIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_ASC',
  CompanyCtasStddevSampleIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_DESC',
  CompanyCtasStddevSampleCtaIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_ASC',
  CompanyCtasStddevSampleCtaIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_DESC',
  CompanyCtasStddevSampleGroupIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyCtasStddevSampleGroupIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyCtasStddevSampleThemeAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_ASC',
  CompanyCtasStddevSampleThemeDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DESC',
  CompanyCtasStddevSampleNameAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_ASC',
  CompanyCtasStddevSampleNameDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_DESC',
  CompanyCtasStddevSampleLockedAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_ASC',
  CompanyCtasStddevSampleLockedDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_DESC',
  CompanyCtasStddevSampleDefaultAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_ASC',
  CompanyCtasStddevSampleDefaultDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_DESC',
  CompanyCtasStddevSampleTemplateDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasStddevSampleTemplateDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasStddevSampleThemeDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_ASC',
  CompanyCtasStddevSampleThemeDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_DESC',
  CompanyCtasStddevSampleCreatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyCtasStddevSampleCreatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyCtasStddevSampleUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasStddevSampleUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasStddevSampleCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevSampleCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevSampleCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasStddevSampleCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasStddevPopulationIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_ASC',
  CompanyCtasStddevPopulationIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_DESC',
  CompanyCtasStddevPopulationCtaIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_ASC',
  CompanyCtasStddevPopulationCtaIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_DESC',
  CompanyCtasStddevPopulationGroupIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyCtasStddevPopulationGroupIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyCtasStddevPopulationThemeAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_ASC',
  CompanyCtasStddevPopulationThemeDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DESC',
  CompanyCtasStddevPopulationNameAsc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_ASC',
  CompanyCtasStddevPopulationNameDesc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_DESC',
  CompanyCtasStddevPopulationLockedAsc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_ASC',
  CompanyCtasStddevPopulationLockedDesc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_DESC',
  CompanyCtasStddevPopulationDefaultAsc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_ASC',
  CompanyCtasStddevPopulationDefaultDesc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_DESC',
  CompanyCtasStddevPopulationTemplateDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasStddevPopulationTemplateDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasStddevPopulationThemeDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_ASC',
  CompanyCtasStddevPopulationThemeDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_DESC',
  CompanyCtasStddevPopulationCreatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyCtasStddevPopulationCreatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyCtasStddevPopulationUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyCtasStddevPopulationUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyCtasStddevPopulationCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevPopulationCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevPopulationCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasStddevPopulationCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_DESC',
  CompanyCtasVarianceSampleIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyCtasVarianceSampleIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyCtasVarianceSampleCtaIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CompanyCtasVarianceSampleCtaIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CompanyCtasVarianceSampleGroupIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyCtasVarianceSampleGroupIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyCtasVarianceSampleThemeAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_ASC',
  CompanyCtasVarianceSampleThemeDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DESC',
  CompanyCtasVarianceSampleNameAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyCtasVarianceSampleNameDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyCtasVarianceSampleLockedAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_ASC',
  CompanyCtasVarianceSampleLockedDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_DESC',
  CompanyCtasVarianceSampleDefaultAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_ASC',
  CompanyCtasVarianceSampleDefaultDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_DESC',
  CompanyCtasVarianceSampleTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasVarianceSampleTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasVarianceSampleThemeDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_ASC',
  CompanyCtasVarianceSampleThemeDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_DESC',
  CompanyCtasVarianceSampleCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyCtasVarianceSampleCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyCtasVarianceSampleUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasVarianceSampleUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasVarianceSampleCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVarianceSampleCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVarianceSampleCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasVarianceSampleCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasVariancePopulationIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_ASC',
  CompanyCtasVariancePopulationIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_DESC',
  CompanyCtasVariancePopulationCtaIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_ASC',
  CompanyCtasVariancePopulationCtaIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_DESC',
  CompanyCtasVariancePopulationGroupIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyCtasVariancePopulationGroupIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyCtasVariancePopulationThemeAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_ASC',
  CompanyCtasVariancePopulationThemeDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DESC',
  CompanyCtasVariancePopulationNameAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_ASC',
  CompanyCtasVariancePopulationNameDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_DESC',
  CompanyCtasVariancePopulationLockedAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_ASC',
  CompanyCtasVariancePopulationLockedDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_DESC',
  CompanyCtasVariancePopulationDefaultAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_ASC',
  CompanyCtasVariancePopulationDefaultDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_DESC',
  CompanyCtasVariancePopulationTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasVariancePopulationTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasVariancePopulationThemeDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_ASC',
  CompanyCtasVariancePopulationThemeDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_DESC',
  CompanyCtasVariancePopulationCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyCtasVariancePopulationCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyCtasVariancePopulationUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyCtasVariancePopulationUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasVariancePopulationCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVariancePopulationCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVariancePopulationCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasVariancePopulationCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_DESC',
  CtaWidgetsCountAsc = 'CTA_WIDGETS_COUNT_ASC',
  CtaWidgetsCountDesc = 'CTA_WIDGETS_COUNT_DESC',
  CtaWidgetsSumIdAsc = 'CTA_WIDGETS_SUM_ID_ASC',
  CtaWidgetsSumIdDesc = 'CTA_WIDGETS_SUM_ID_DESC',
  CtaWidgetsSumCtaIdAsc = 'CTA_WIDGETS_SUM_CTA_ID_ASC',
  CtaWidgetsSumCtaIdDesc = 'CTA_WIDGETS_SUM_CTA_ID_DESC',
  CtaWidgetsSumStaticIdAsc = 'CTA_WIDGETS_SUM_STATIC_ID_ASC',
  CtaWidgetsSumStaticIdDesc = 'CTA_WIDGETS_SUM_STATIC_ID_DESC',
  CtaWidgetsSumWidgetIdAsc = 'CTA_WIDGETS_SUM_WIDGET_ID_ASC',
  CtaWidgetsSumWidgetIdDesc = 'CTA_WIDGETS_SUM_WIDGET_ID_DESC',
  CtaWidgetsSumCreatedAtAsc = 'CTA_WIDGETS_SUM_CREATED_AT_ASC',
  CtaWidgetsSumCreatedAtDesc = 'CTA_WIDGETS_SUM_CREATED_AT_DESC',
  CtaWidgetsSumUpdatedAtAsc = 'CTA_WIDGETS_SUM_UPDATED_AT_ASC',
  CtaWidgetsSumUpdatedAtDesc = 'CTA_WIDGETS_SUM_UPDATED_AT_DESC',
  CtaWidgetsDistinctCountIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_ASC',
  CtaWidgetsDistinctCountIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_DESC',
  CtaWidgetsDistinctCountCtaIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_ASC',
  CtaWidgetsDistinctCountCtaIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_DESC',
  CtaWidgetsDistinctCountStaticIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_ASC',
  CtaWidgetsDistinctCountStaticIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_DESC',
  CtaWidgetsDistinctCountWidgetIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CtaWidgetsDistinctCountWidgetIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CtaWidgetsDistinctCountCreatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaWidgetsDistinctCountCreatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaWidgetsDistinctCountUpdatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaWidgetsDistinctCountUpdatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaWidgetsMinIdAsc = 'CTA_WIDGETS_MIN_ID_ASC',
  CtaWidgetsMinIdDesc = 'CTA_WIDGETS_MIN_ID_DESC',
  CtaWidgetsMinCtaIdAsc = 'CTA_WIDGETS_MIN_CTA_ID_ASC',
  CtaWidgetsMinCtaIdDesc = 'CTA_WIDGETS_MIN_CTA_ID_DESC',
  CtaWidgetsMinStaticIdAsc = 'CTA_WIDGETS_MIN_STATIC_ID_ASC',
  CtaWidgetsMinStaticIdDesc = 'CTA_WIDGETS_MIN_STATIC_ID_DESC',
  CtaWidgetsMinWidgetIdAsc = 'CTA_WIDGETS_MIN_WIDGET_ID_ASC',
  CtaWidgetsMinWidgetIdDesc = 'CTA_WIDGETS_MIN_WIDGET_ID_DESC',
  CtaWidgetsMinCreatedAtAsc = 'CTA_WIDGETS_MIN_CREATED_AT_ASC',
  CtaWidgetsMinCreatedAtDesc = 'CTA_WIDGETS_MIN_CREATED_AT_DESC',
  CtaWidgetsMinUpdatedAtAsc = 'CTA_WIDGETS_MIN_UPDATED_AT_ASC',
  CtaWidgetsMinUpdatedAtDesc = 'CTA_WIDGETS_MIN_UPDATED_AT_DESC',
  CtaWidgetsMaxIdAsc = 'CTA_WIDGETS_MAX_ID_ASC',
  CtaWidgetsMaxIdDesc = 'CTA_WIDGETS_MAX_ID_DESC',
  CtaWidgetsMaxCtaIdAsc = 'CTA_WIDGETS_MAX_CTA_ID_ASC',
  CtaWidgetsMaxCtaIdDesc = 'CTA_WIDGETS_MAX_CTA_ID_DESC',
  CtaWidgetsMaxStaticIdAsc = 'CTA_WIDGETS_MAX_STATIC_ID_ASC',
  CtaWidgetsMaxStaticIdDesc = 'CTA_WIDGETS_MAX_STATIC_ID_DESC',
  CtaWidgetsMaxWidgetIdAsc = 'CTA_WIDGETS_MAX_WIDGET_ID_ASC',
  CtaWidgetsMaxWidgetIdDesc = 'CTA_WIDGETS_MAX_WIDGET_ID_DESC',
  CtaWidgetsMaxCreatedAtAsc = 'CTA_WIDGETS_MAX_CREATED_AT_ASC',
  CtaWidgetsMaxCreatedAtDesc = 'CTA_WIDGETS_MAX_CREATED_AT_DESC',
  CtaWidgetsMaxUpdatedAtAsc = 'CTA_WIDGETS_MAX_UPDATED_AT_ASC',
  CtaWidgetsMaxUpdatedAtDesc = 'CTA_WIDGETS_MAX_UPDATED_AT_DESC',
  CtaWidgetsAverageIdAsc = 'CTA_WIDGETS_AVERAGE_ID_ASC',
  CtaWidgetsAverageIdDesc = 'CTA_WIDGETS_AVERAGE_ID_DESC',
  CtaWidgetsAverageCtaIdAsc = 'CTA_WIDGETS_AVERAGE_CTA_ID_ASC',
  CtaWidgetsAverageCtaIdDesc = 'CTA_WIDGETS_AVERAGE_CTA_ID_DESC',
  CtaWidgetsAverageStaticIdAsc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_ASC',
  CtaWidgetsAverageStaticIdDesc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_DESC',
  CtaWidgetsAverageWidgetIdAsc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CtaWidgetsAverageWidgetIdDesc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CtaWidgetsAverageCreatedAtAsc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CtaWidgetsAverageCreatedAtDesc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CtaWidgetsAverageUpdatedAtAsc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CtaWidgetsAverageUpdatedAtDesc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CtaWidgetsStddevSampleIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_ASC',
  CtaWidgetsStddevSampleIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_DESC',
  CtaWidgetsStddevSampleCtaIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_ASC',
  CtaWidgetsStddevSampleCtaIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_DESC',
  CtaWidgetsStddevSampleStaticIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsStddevSampleStaticIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsStddevSampleWidgetIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsStddevSampleWidgetIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsStddevSampleCreatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsStddevSampleCreatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsStddevSampleUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsStddevSampleUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsStddevPopulationIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_ASC',
  CtaWidgetsStddevPopulationIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_DESC',
  CtaWidgetsStddevPopulationCtaIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_ASC',
  CtaWidgetsStddevPopulationCtaIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_DESC',
  CtaWidgetsStddevPopulationStaticIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_ASC',
  CtaWidgetsStddevPopulationStaticIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_DESC',
  CtaWidgetsStddevPopulationWidgetIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsStddevPopulationWidgetIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsStddevPopulationCreatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaWidgetsStddevPopulationCreatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaWidgetsStddevPopulationUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsStddevPopulationUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaWidgetsVarianceSampleIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_ASC',
  CtaWidgetsVarianceSampleIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_DESC',
  CtaWidgetsVarianceSampleCtaIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CtaWidgetsVarianceSampleCtaIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CtaWidgetsVarianceSampleStaticIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsVarianceSampleStaticIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsVarianceSampleWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsVarianceSampleWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsVarianceSampleCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsVarianceSampleCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsVarianceSampleUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsVarianceSampleUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsVariancePopulationIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_ASC',
  CtaWidgetsVariancePopulationIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_DESC',
  CtaWidgetsVariancePopulationCtaIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_ASC',
  CtaWidgetsVariancePopulationCtaIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_DESC',
  CtaWidgetsVariancePopulationStaticIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_ASC',
  CtaWidgetsVariancePopulationStaticIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_DESC',
  CtaWidgetsVariancePopulationWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsVariancePopulationWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsVariancePopulationCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaWidgetsVariancePopulationCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaWidgetsVariancePopulationUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsVariancePopulationUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}



/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Date']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Date']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Date']>;
};


/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteAnalyticByNodeId` mutation. */
export type DeleteAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Analytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAnalytic` mutation. */
export type DeleteAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Analytic` mutation. */
export type DeleteAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was deleted by this mutation. */
  analytic?: Maybe<Analytic>;
  deletedAnalyticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our delete `Analytic` mutation. */
export type DeleteAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the `deleteAnalyticsCtaByNodeId` mutation. */
export type DeleteAnalyticsCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AnalyticsCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAnalyticsCta` mutation. */
export type DeleteAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  eventId: Scalars['Int'];
};

/** The output of our delete `AnalyticsCta` mutation. */
export type DeleteAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was deleted by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  deletedAnalyticsCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our delete `AnalyticsCta` mutation. */
export type DeleteAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the `deleteAppointmentByNodeId` mutation. */
export type DeleteAppointmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Appointment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAppointment` mutation. */
export type DeleteAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Appointment` mutation. */
export type DeleteAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was deleted by this mutation. */
  appointment?: Maybe<Appointment>;
  deletedAppointmentNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our delete `Appointment` mutation. */
export type DeleteAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the `deleteChannelByNodeId` mutation. */
export type DeleteChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Channel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteChannel` mutation. */
export type DeleteChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Channel` mutation. */
export type DeleteChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was deleted by this mutation. */
  channel?: Maybe<Channel>;
  deletedChannelNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our delete `Channel` mutation. */
export type DeleteChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the `deleteCompanyAnalyticByNodeId` mutation. */
export type DeleteCompanyAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyAnalytic` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was deleted by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  deletedCompanyAnalyticNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our delete `CompanyAnalytic` mutation. */
export type DeleteCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

/** All input for the `deleteCompanyChannelByNodeId` mutation. */
export type DeleteCompanyChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyChannel` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyChannel` mutation. */
export type DeleteCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyChannel` mutation. */
export type DeleteCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` that was deleted by this mutation. */
  companyChannel?: Maybe<CompanyChannel>;
  deletedCompanyChannelNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our delete `CompanyChannel` mutation. */
export type DeleteCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the `deleteCompanyCtaByNodeId` mutation. */
export type DeleteCompanyCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyCta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyCta` mutation. */
export type DeleteCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyCta` mutation. */
export type DeleteCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was deleted by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  deletedCompanyCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our delete `CompanyCta` mutation. */
export type DeleteCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the `deleteCompanyDomainByDomain` mutation. */
export type DeleteCompanyDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
};

/** All input for the `deleteCompanyDomainByNodeId` mutation. */
export type DeleteCompanyDomainByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomain` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomain` mutation. */
export type DeleteCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyDomain` mutation. */
export type DeleteCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was deleted by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  deletedCompanyDomainNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our delete `CompanyDomain` mutation. */
export type DeleteCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the `deleteCompanyDomainsApprovedByNodeId` mutation. */
export type DeleteCompanyDomainsApprovedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsApproved` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The output of our delete `CompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was deleted by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  deletedCompanyDomainsApprovedNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our delete `CompanyDomainsApproved` mutation. */
export type DeleteCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the `deleteCompanyDomainsPathByDomainIdAndPath` mutation. */
export type DeleteCompanyDomainsPathByDomainIdAndPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** All input for the `deleteCompanyDomainsPathByNodeId` mutation. */
export type DeleteCompanyDomainsPathByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsPath` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was deleted by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  deletedCompanyDomainsPathNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our delete `CompanyDomainsPath` mutation. */
export type DeleteCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the `deleteCompanyGroupByNodeId` mutation. */
export type DeleteCompanyGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyGroup` mutation. */
export type DeleteCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The output of our delete `CompanyGroup` mutation. */
export type DeleteCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was deleted by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  deletedCompanyGroupNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our delete `CompanyGroup` mutation. */
export type DeleteCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the `deleteCompanyIntegrationByNodeId` mutation. */
export type DeleteCompanyIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyIntegration` mutation. */
export type DeleteCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyIntegration` mutation. */
export type DeleteCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was deleted by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  deletedCompanyIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our delete `CompanyIntegration` mutation. */
export type DeleteCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the `deleteCompanyLocationByNodeId` mutation. */
export type DeleteCompanyLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyLocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyLocation` mutation. */
export type DeleteCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyLocation` mutation. */
export type DeleteCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was deleted by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  deletedCompanyLocationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our delete `CompanyLocation` mutation. */
export type DeleteCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

/** All input for the `deleteCompanyProductByNodeId` mutation. */
export type DeleteCompanyProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProduct` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyProduct` mutation. */
export type DeleteCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteCompanyProductIntegrationByNodeId` mutation. */
export type DeleteCompanyProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was deleted by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  deletedCompanyProductIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our delete `CompanyProductIntegration` mutation. */
export type DeleteCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our delete `CompanyProduct` mutation. */
export type DeleteCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was deleted by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  deletedCompanyProductNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our delete `CompanyProduct` mutation. */
export type DeleteCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the `deleteCompanyWidgetByNodeId` mutation. */
export type DeleteCompanyWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyWidget` mutation. */
export type DeleteCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The output of our delete `CompanyWidget` mutation. */
export type DeleteCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was deleted by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  deletedCompanyWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our delete `CompanyWidget` mutation. */
export type DeleteCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

/** All input for the `deleteCtaByNodeId` mutation. */
export type DeleteCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCtaByProductAndTypeAndVersion` mutation. */
export type DeleteCtaByProductAndTypeAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** All input for the `deleteCta` mutation. */
export type DeleteCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `Cta` mutation. */
export type DeleteCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was deleted by this mutation. */
  cta?: Maybe<Cta>;
  deletedCtaNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our delete `Cta` mutation. */
export type DeleteCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type DeleteCtaSpecInput = {
  id: Scalars['String'];
};

/** All input for the `deleteCtaWidgetByNodeId` mutation. */
export type DeleteCtaWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CtaWidget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCtaWidget` mutation. */
export type DeleteCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CtaWidget` mutation. */
export type DeleteCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was deleted by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  deletedCtaWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our delete `CtaWidget` mutation. */
export type DeleteCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the `deleteDeliveryMethodByNodeId` mutation. */
export type DeleteDeliveryMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DeliveryMethod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDeliveryMethod` mutation. */
export type DeleteDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `DeliveryMethod` mutation. */
export type DeleteDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was deleted by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  deletedDeliveryMethodNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our delete `DeliveryMethod` mutation. */
export type DeleteDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the `deleteEmailTemplateByNodeId` mutation. */
export type DeleteEmailTemplateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmailTemplate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmailTemplate` mutation. */
export type DeleteEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was deleted by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  deletedEmailTemplateNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `deleteEquifaxLenderByEquifaxIdAndName` mutation. */
export type DeleteEquifaxLenderByEquifaxIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** All input for the `deleteEquifaxLenderByNodeId` mutation. */
export type DeleteEquifaxLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquifaxLender` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEquifaxLender` mutation. */
export type DeleteEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `EquifaxLender` mutation. */
export type DeleteEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was deleted by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  deletedEquifaxLenderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our delete `EquifaxLender` mutation. */
export type DeleteEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the `deleteEventByNodeId` mutation. */
export type DeleteEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEvent` mutation. */
export type DeleteEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Event` mutation. */
export type DeleteEventPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was deleted by this mutation. */
  event?: Maybe<Event>;
  deletedEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Event`. */
  lead?: Maybe<Lead>;
  /** An edge for our `Event`. May be used by Relay 1. */
  eventEdge?: Maybe<EventsEdge>;
};


/** The output of our delete `Event` mutation. */
export type DeleteEventPayloadEventEdgeArgs = {
  orderBy?: Maybe<Array<EventsOrderBy>>;
};

/** All input for the `deleteFieldByCompanyChannelIdAndConfig` mutation. */
export type DeleteFieldByCompanyChannelIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByCompanyIntegrationIdAndConfig` mutation. */
export type DeleteFieldByCompanyIntegrationIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByCompanyProductIdAndConfig` mutation. */
export type DeleteFieldByCompanyProductIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `deleteFieldByNodeId` mutation. */
export type DeleteFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Field` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldConfigByNodeId` mutation. */
export type DeleteFieldConfigByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldConfig` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldConfig` mutation. */
export type DeleteFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FieldConfig` mutation. */
export type DeleteFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was deleted by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  deletedFieldConfigNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Product` that is related to this `FieldConfig`. */
  product?: Maybe<Product>;
  /** Reads a single `Channel` that is related to this `FieldConfig`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Integration` that is related to this `FieldConfig`. */
  integration?: Maybe<Integration>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our delete `FieldConfig` mutation. */
export type DeleteFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the `deleteField` mutation. */
export type DeleteFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteFieldKeyByKey` mutation. */
export type DeleteFieldKeyByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

/** All input for the `deleteFieldKeyByNodeId` mutation. */
export type DeleteFieldKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFieldKey` mutation. */
export type DeleteFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FieldKey` mutation. */
export type DeleteFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was deleted by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  deletedFieldKeyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our delete `FieldKey` mutation. */
export type DeleteFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our delete `Field` mutation. */
export type DeleteFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was deleted by this mutation. */
  field?: Maybe<Field>;
  deletedFieldNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our delete `Field` mutation. */
export type DeleteFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the `deleteIdVerificationByLeadId` mutation. */
export type DeleteIdVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteIdVerificationByNodeId` mutation. */
export type DeleteIdVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIdVerification` mutation. */
export type DeleteIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IdVerification` mutation. */
export type DeleteIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was deleted by this mutation. */
  idVerification?: Maybe<IdVerification>;
  deletedIdVerificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our delete `IdVerification` mutation. */
export type DeleteIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the `deleteIncomeVerificationByLeadId` mutation. */
export type DeleteIncomeVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteIncomeVerificationByNodeId` mutation. */
export type DeleteIncomeVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IncomeVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIncomeVerification` mutation. */
export type DeleteIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `IncomeVerification` mutation. */
export type DeleteIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was deleted by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  deletedIncomeVerificationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our delete `IncomeVerification` mutation. */
export type DeleteIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the `deleteIntegrationByNodeId` mutation. */
export type DeleteIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Integration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIntegration` mutation. */
export type DeleteIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Integration` mutation. */
export type DeleteIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was deleted by this mutation. */
  integration?: Maybe<Integration>;
  deletedIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our delete `Integration` mutation. */
export type DeleteIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the `deleteLeadByNodeId` mutation. */
export type DeleteLeadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lead` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLeadCampaignByLeadId` mutation. */
export type DeleteLeadCampaignByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  leadId: Scalars['UUID'];
};

/** All input for the `deleteLeadCampaignByNodeId` mutation. */
export type DeleteLeadCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadCampaign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLeadCampaign` mutation. */
export type DeleteLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LeadCampaign` mutation. */
export type DeleteLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was deleted by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  deletedLeadCampaignNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our delete `LeadCampaign` mutation. */
export type DeleteLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the `deleteLead` mutation. */
export type DeleteLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was deleted by this mutation. */
  lead?: Maybe<Lead>;
  deletedLeadNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our delete `Lead` mutation. */
export type DeleteLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

export type DeleteLeadResponse = {
  success: Scalars['Boolean'];
  leadId: Scalars['ID'];
};

/** All input for the `deleteLenderAppByNodeId` mutation. */
export type DeleteLenderAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderApp` mutation. */
export type DeleteLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderApp` mutation. */
export type DeleteLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was deleted by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  deletedLenderAppNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our delete `LenderApp` mutation. */
export type DeleteLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the `deleteLenderQuoteByNodeId` mutation. */
export type DeleteLenderQuoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderQuote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLenderQuote` mutation. */
export type DeleteLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `LenderQuote` mutation. */
export type DeleteLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was deleted by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  deletedLenderQuoteNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our delete `LenderQuote` mutation. */
export type DeleteLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the `deleteOptionByNodeId` mutation. */
export type DeleteOptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Option` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOption` mutation. */
export type DeleteOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Option` mutation. */
export type DeleteOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was deleted by this mutation. */
  option?: Maybe<Option>;
  deletedOptionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our delete `Option` mutation. */
export type DeleteOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the `deleteProductIntegrationByNodeId` mutation. */
export type DeleteProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProductIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProductIntegration` mutation. */
export type DeleteProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ProductIntegration` mutation. */
export type DeleteProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was deleted by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  deletedProductIntegrationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our delete `ProductIntegration` mutation. */
export type DeleteProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the `deleteReviewByNodeId` mutation. */
export type DeleteReviewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Review` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReview` mutation. */
export type DeleteReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The output of our delete `Review` mutation. */
export type DeleteReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was deleted by this mutation. */
  review?: Maybe<Review>;
  deletedReviewNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our delete `Review` mutation. */
export type DeleteReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the `deleteUserActivityByNodeId` mutation. */
export type DeleteUserActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserActivity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserActivity` mutation. */
export type DeleteUserActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserActivity` mutation. */
export type DeleteUserActivityPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserActivity` that was deleted by this mutation. */
  userActivity?: Maybe<UserActivity>;
  deletedUserActivityNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserActivity`. May be used by Relay 1. */
  userActivityEdge?: Maybe<UserActivitiesEdge>;
};


/** The output of our delete `UserActivity` mutation. */
export type DeleteUserActivityPayloadUserActivityEdgeArgs = {
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
};

/** All input for the `deleteVehicleListingByNodeId` mutation. */
export type DeleteVehicleListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVehicleListing` mutation. */
export type DeleteVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `VehicleListing` mutation. */
export type DeleteVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was deleted by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  deletedVehicleListingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our delete `VehicleListing` mutation. */
export type DeleteVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the `deleteWidgetByNodeId` mutation. */
export type DeleteWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Widget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWidgetByVariant` mutation. */
export type DeleteWidgetByVariantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  variant: Scalars['String'];
};

/** All input for the `deleteWidget` mutation. */
export type DeleteWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `Widget` mutation. */
export type DeleteWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was deleted by this mutation. */
  widget?: Maybe<Widget>;
  deletedWidgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our delete `Widget` mutation. */
export type DeleteWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the `deleteWidgetSettingByNodeId` mutation. */
export type DeleteWidgetSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WidgetSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWidgetSetting` mutation. */
export type DeleteWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `WidgetSetting` mutation. */
export type DeleteWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was deleted by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  deletedWidgetSettingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our delete `WidgetSetting` mutation. */
export type DeleteWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type DeliveryMethod = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  rule?: Maybe<Scalars['JSON']>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  values: Array<Scalars['String']>;
};

export type DeliveryMethodAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DeliveryMethodSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DeliveryMethodDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DeliveryMethodMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DeliveryMethodMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DeliveryMethodAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DeliveryMethodStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DeliveryMethodStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DeliveryMethodVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DeliveryMethodVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `DeliveryMethod` object types. */
export type DeliveryMethodAggregatesFilter = {
  /** A filter that must pass for the relevant `DeliveryMethod` object to be included within the aggregate. */
  filter?: Maybe<DeliveryMethodFilter>;
  /** Sum aggregate over matching `DeliveryMethod` objects. */
  sum?: Maybe<DeliveryMethodSumAggregateFilter>;
  /** Distinct count aggregate over matching `DeliveryMethod` objects. */
  distinctCount?: Maybe<DeliveryMethodDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `DeliveryMethod` objects. */
  min?: Maybe<DeliveryMethodMinAggregateFilter>;
  /** Maximum aggregate over matching `DeliveryMethod` objects. */
  max?: Maybe<DeliveryMethodMaxAggregateFilter>;
  /** Mean average aggregate over matching `DeliveryMethod` objects. */
  average?: Maybe<DeliveryMethodAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DeliveryMethod` objects. */
  stddevSample?: Maybe<DeliveryMethodStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `DeliveryMethod` objects. */
  stddevPopulation?: Maybe<DeliveryMethodStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DeliveryMethod` objects. */
  varianceSample?: Maybe<DeliveryMethodVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `DeliveryMethod` objects. */
  variancePopulation?: Maybe<DeliveryMethodVariancePopulationAggregateFilter>;
};

export type DeliveryMethodAverageAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodAverageAggregates = {
  /** Mean average of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DeliveryMethod` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DeliveryMethodCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<DeliveryMethodType>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rule` field. */
  rule?: Maybe<Scalars['JSON']>;
};

/** The fields on `deliveryMethod` to look up the row to connect. */
export type DeliveryMethodDeliveryMethodsPkey1Connect = {
  id: Scalars['Int'];
};

/** The fields on `deliveryMethod` to look up the row to delete. */
export type DeliveryMethodDeliveryMethodsPkey1Delete = {
  id: Scalars['Int'];
};

export type DeliveryMethodDistinctCountAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
  rule?: Maybe<BigIntFilter>;
};

export type DeliveryMethodDistinctCountAggregates = {
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rule across the matching connection */
  rule?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `DeliveryMethod` object types. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodFilter = {
  /** Filter by the object’s `groupId` field. */
  groupId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<DeliveryMethodTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<JsonFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `rule` field. */
  rule?: Maybe<JsonFilter>;
  /** Filter by the object’s `group` relation. */
  group?: Maybe<GroupFilter>;
  /** A related `group` exists. */
  groupExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DeliveryMethodFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DeliveryMethodFilter>>;
  /** Negates the expression. */
  not?: Maybe<DeliveryMethodFilter>;
};

/** The `deliveryMethod` to be created by this mutation. */
export type DeliveryMethodGroupIdFkDeliveryMethodsCreateInput = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

/** The `group` to be created by this mutation. */
export type DeliveryMethodGroupIdFkGroupCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** Input for the nested mutation of `group` in the `DeliveryMethodInput` mutation. */
export type DeliveryMethodGroupIdFkInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByCognitoName?: Maybe<GroupGroupCognitoNameKeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByCognitoName?: Maybe<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupCognitoNameKeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<DeliveryMethodGroupIdFkGroupCreateInput>;
};

/** Input for the nested mutation of `deliveryMethod` in the `GroupInput` mutation. */
export type DeliveryMethodGroupIdFkInverseInput = {
  /** Flag indicating whether all other `deliveryMethod` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  connectById?: Maybe<Array<DeliveryMethodDeliveryMethodsPkey1Connect>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<DeliveryMethodNodeIdConnect>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  deleteById?: Maybe<Array<DeliveryMethodDeliveryMethodsPkey1Delete>>;
  /** The primary key(s) for `deliveryMethod` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<DeliveryMethodNodeIdDelete>>;
  /** The primary key(s) and patch data for `deliveryMethod` for the far side of the relationship. */
  updateById?: Maybe<Array<DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkUsingDeliveryMethodsPkey1Update>>;
  /** The primary key(s) and patch data for `deliveryMethod` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate>>;
  /** A `DeliveryMethodInput` object that will be created and connected to this object. */
  create?: Maybe<Array<DeliveryMethodGroupIdFkDeliveryMethodsCreateInput>>;
};

/** An input for mutations affecting `DeliveryMethod` */
export type DeliveryMethodInput = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

export type DeliveryMethodMaxAggregateFilter = {
  groupId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type DeliveryMethodMaxAggregates = {
  /** Maximum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type DeliveryMethodMinAggregateFilter = {
  groupId?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
};

export type DeliveryMethodMinAggregates = {
  /** Minimum of groupId across the matching connection */
  groupId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DeliveryMethodNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DeliveryMethodNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `deliveryMethod` to look up the row to update. */
export type DeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkUsingDeliveryMethodsPkey1Update = {
  /** An object where the defined keys will be set on the `deliveryMethod` being updated. */
  patch: UpdateDeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `DeliveryMethod`. Fields that are set will be updated. */
export type DeliveryMethodPatch = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

export type DeliveryMethodResponse = {
  success: Scalars['Boolean'];
  deliveryMethodId: Scalars['Int'];
  preferredSender: Scalars['String'];
};

export type DeliveryMethodStddevPopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodStddevPopulationAggregates = {
  /** Population standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodStddevSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodStddevSampleAggregates = {
  /** Sample standard deviation of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodSumAggregateFilter = {
  groupId?: Maybe<BigIntFilter>;
  id?: Maybe<BigIntFilter>;
};

export type DeliveryMethodSumAggregates = {
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export enum DeliveryMethodType {
  Email = 'EMAIL',
  Adf = 'ADF',
  Webhook = 'WEBHOOK',
  Leadspedia = 'LEADSPEDIA'
}

/** A filter to be used against DeliveryMethodType fields. All fields are combined with a logical ‘and.’ */
export type DeliveryMethodTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<DeliveryMethodType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<DeliveryMethodType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<DeliveryMethodType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<DeliveryMethodType>;
  /** Included in the specified list. */
  in?: Maybe<Array<DeliveryMethodType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<DeliveryMethodType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<DeliveryMethodType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<DeliveryMethodType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<DeliveryMethodType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<DeliveryMethodType>;
};

export type DeliveryMethodVariancePopulationAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodVariancePopulationAggregates = {
  /** Population variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DeliveryMethodVarianceSampleAggregateFilter = {
  groupId?: Maybe<BigFloatFilter>;
  id?: Maybe<BigFloatFilter>;
};

export type DeliveryMethodVarianceSampleAggregates = {
  /** Sample variance of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DeliveryMethod` values. */
export type DeliveryMethodsConnection = {
  /** A list of `DeliveryMethod` objects. */
  nodes: Array<DeliveryMethod>;
  /** A list of edges which contains the `DeliveryMethod` and cursor to aid in pagination. */
  edges: Array<DeliveryMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DeliveryMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DeliveryMethodAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DeliveryMethodAggregates>>;
};


/** A connection to a list of `DeliveryMethod` values. */
export type DeliveryMethodsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DeliveryMethodsGroupBy>;
  having?: Maybe<DeliveryMethodsHavingInput>;
};

/** A `DeliveryMethod` edge in the connection. */
export type DeliveryMethodsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DeliveryMethod` at the end of the edge. */
  node: DeliveryMethod;
};

/** Grouping methods for `DeliveryMethod` for usage during aggregation. */
export enum DeliveryMethodsGroupBy {
  GroupId = 'GROUP_ID',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Config = 'CONFIG',
  Value = 'VALUE',
  Rule = 'RULE'
}

export type DeliveryMethodsHavingAverageInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingDistinctCountInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** Conditions for `DeliveryMethod` aggregates. */
export type DeliveryMethodsHavingInput = {
  AND?: Maybe<Array<DeliveryMethodsHavingInput>>;
  OR?: Maybe<Array<DeliveryMethodsHavingInput>>;
  sum?: Maybe<DeliveryMethodsHavingSumInput>;
  distinctCount?: Maybe<DeliveryMethodsHavingDistinctCountInput>;
  min?: Maybe<DeliveryMethodsHavingMinInput>;
  max?: Maybe<DeliveryMethodsHavingMaxInput>;
  average?: Maybe<DeliveryMethodsHavingAverageInput>;
  stddevSample?: Maybe<DeliveryMethodsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<DeliveryMethodsHavingStddevPopulationInput>;
  varianceSample?: Maybe<DeliveryMethodsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<DeliveryMethodsHavingVariancePopulationInput>;
};

export type DeliveryMethodsHavingMaxInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingMinInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingStddevPopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingStddevSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingSumInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingVariancePopulationInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

export type DeliveryMethodsHavingVarianceSampleInput = {
  groupId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  id?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `DeliveryMethod`. */
export enum DeliveryMethodsOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  RuleAsc = 'RULE_ASC',
  RuleDesc = 'RULE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DerogatoryCounters = {
  past30: Scalars['Int'];
  past60: Scalars['Int'];
  past90: Scalars['Int'];
};

export type EmailTemplate = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  name: Scalars['String'];
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type EmailTemplateAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailTemplateDistinctCountAggregates>;
};

/**
 * A condition to be used against `EmailTemplate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailTemplateCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `parents` field. */
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `body` field. */
  body?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailTemplateDistinctCountAggregates = {
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parents across the matching connection */
  parents?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']>;
  /** Distinct count of body across the matching connection */
  body?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmailTemplate` object types. All fields are combined with a logical ‘and.’ */
export type EmailTemplateFilter = {
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `parents` field. */
  parents?: Maybe<StringListFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: Maybe<StringFilter>;
  /** Filter by the object’s `body` field. */
  body?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EmailTemplateFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EmailTemplateFilter>>;
  /** Negates the expression. */
  not?: Maybe<EmailTemplateFilter>;
};

/** An input for mutations affecting `EmailTemplate` */
export type EmailTemplateInput = {
  name: Scalars['String'];
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `EmailTemplate`. Fields that are set will be updated. */
export type EmailTemplatePatch = {
  name?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnection = {
  /** A list of `EmailTemplate` objects. */
  nodes: Array<EmailTemplate>;
  /** A list of edges which contains the `EmailTemplate` and cursor to aid in pagination. */
  edges: Array<EmailTemplatesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailTemplate` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailTemplateAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailTemplateAggregates>>;
};


/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailTemplatesGroupBy>;
  having?: Maybe<EmailTemplatesHavingInput>;
};

/** A `EmailTemplate` edge in the connection. */
export type EmailTemplatesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmailTemplate` at the end of the edge. */
  node: EmailTemplate;
};

/** Grouping methods for `EmailTemplate` for usage during aggregation. */
export enum EmailTemplatesGroupBy {
  Parents = 'PARENTS',
  Subject = 'SUBJECT',
  Body = 'BODY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type EmailTemplatesHavingAverageInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingDistinctCountInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailTemplate` aggregates. */
export type EmailTemplatesHavingInput = {
  AND?: Maybe<Array<EmailTemplatesHavingInput>>;
  OR?: Maybe<Array<EmailTemplatesHavingInput>>;
  sum?: Maybe<EmailTemplatesHavingSumInput>;
  distinctCount?: Maybe<EmailTemplatesHavingDistinctCountInput>;
  min?: Maybe<EmailTemplatesHavingMinInput>;
  max?: Maybe<EmailTemplatesHavingMaxInput>;
  average?: Maybe<EmailTemplatesHavingAverageInput>;
  stddevSample?: Maybe<EmailTemplatesHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EmailTemplatesHavingStddevPopulationInput>;
  varianceSample?: Maybe<EmailTemplatesHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EmailTemplatesHavingVariancePopulationInput>;
};

export type EmailTemplatesHavingMaxInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingMinInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevPopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingSumInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVariancePopulationInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVarianceSampleInput = {
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `EmailTemplate`. */
export enum EmailTemplatesOrderBy {
  Natural = 'NATURAL',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ParentsAsc = 'PARENTS_ASC',
  ParentsDesc = 'PARENTS_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type EquifaxEntity = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type EquifaxLender = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display: Scalars['Boolean'];
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type EquifaxLenderAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EquifaxLenderSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EquifaxLenderDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EquifaxLenderMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EquifaxLenderMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EquifaxLenderAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EquifaxLenderStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EquifaxLenderStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EquifaxLenderVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EquifaxLenderVariancePopulationAggregates>;
};

export type EquifaxLenderAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EquifaxLender` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EquifaxLenderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equifaxId` field. */
  equifaxId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `display` field. */
  display?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `reviewedAt` field. */
  reviewedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EquifaxLenderDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of equifaxId across the matching connection */
  equifaxId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of display across the matching connection */
  display?: Maybe<Scalars['BigInt']>;
  /** Distinct count of reviewedAt across the matching connection */
  reviewedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EquifaxLender` object types. All fields are combined with a logical ‘and.’ */
export type EquifaxLenderFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `equifaxId` field. */
  equifaxId?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `display` field. */
  display?: Maybe<BooleanFilter>;
  /** Filter by the object’s `reviewedAt` field. */
  reviewedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EquifaxLenderFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EquifaxLenderFilter>>;
  /** Negates the expression. */
  not?: Maybe<EquifaxLenderFilter>;
};

/** An input for mutations affecting `EquifaxLender` */
export type EquifaxLenderInput = {
  id?: Maybe<Scalars['Int']>;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EquifaxLenderMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type EquifaxLenderMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EquifaxLender`. Fields that are set will be updated. */
export type EquifaxLenderPatch = {
  id?: Maybe<Scalars['Int']>;
  equifaxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['Boolean']>;
  reviewedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EquifaxLenderStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type EquifaxLenderVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EquifaxLenderVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EquifaxLender` values. */
export type EquifaxLendersConnection = {
  /** A list of `EquifaxLender` objects. */
  nodes: Array<EquifaxLender>;
  /** A list of edges which contains the `EquifaxLender` and cursor to aid in pagination. */
  edges: Array<EquifaxLendersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquifaxLender` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EquifaxLenderAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EquifaxLenderAggregates>>;
};


/** A connection to a list of `EquifaxLender` values. */
export type EquifaxLendersConnectionGroupedAggregatesArgs = {
  groupBy: Array<EquifaxLendersGroupBy>;
  having?: Maybe<EquifaxLendersHavingInput>;
};

/** A `EquifaxLender` edge in the connection. */
export type EquifaxLendersEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquifaxLender` at the end of the edge. */
  node: EquifaxLender;
};

/** Grouping methods for `EquifaxLender` for usage during aggregation. */
export enum EquifaxLendersGroupBy {
  EquifaxId = 'EQUIFAX_ID',
  Name = 'NAME',
  Display = 'DISPLAY',
  ReviewedAt = 'REVIEWED_AT',
  ReviewedAtTruncatedToHour = 'REVIEWED_AT_TRUNCATED_TO_HOUR',
  ReviewedAtTruncatedToDay = 'REVIEWED_AT_TRUNCATED_TO_DAY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type EquifaxLendersHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EquifaxLender` aggregates. */
export type EquifaxLendersHavingInput = {
  AND?: Maybe<Array<EquifaxLendersHavingInput>>;
  OR?: Maybe<Array<EquifaxLendersHavingInput>>;
  sum?: Maybe<EquifaxLendersHavingSumInput>;
  distinctCount?: Maybe<EquifaxLendersHavingDistinctCountInput>;
  min?: Maybe<EquifaxLendersHavingMinInput>;
  max?: Maybe<EquifaxLendersHavingMaxInput>;
  average?: Maybe<EquifaxLendersHavingAverageInput>;
  stddevSample?: Maybe<EquifaxLendersHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EquifaxLendersHavingStddevPopulationInput>;
  varianceSample?: Maybe<EquifaxLendersHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EquifaxLendersHavingVariancePopulationInput>;
};

export type EquifaxLendersHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EquifaxLendersHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  reviewedAt?: Maybe<HavingDatetimeFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `EquifaxLender`. */
export enum EquifaxLendersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  EquifaxIdAsc = 'EQUIFAX_ID_ASC',
  EquifaxIdDesc = 'EQUIFAX_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayAsc = 'DISPLAY_ASC',
  DisplayDesc = 'DISPLAY_DESC',
  ReviewedAtAsc = 'REVIEWED_AT_ASC',
  ReviewedAtDesc = 'REVIEWED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Event = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  error?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `Event`. */
  lead?: Maybe<Lead>;
};

export type EventAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EventSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EventDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EventMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EventMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EventAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EventStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EventStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EventVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EventVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Event` object types. */
export type EventAggregatesFilter = {
  /** A filter that must pass for the relevant `Event` object to be included within the aggregate. */
  filter?: Maybe<EventFilter>;
  /** Sum aggregate over matching `Event` objects. */
  sum?: Maybe<EventSumAggregateFilter>;
  /** Distinct count aggregate over matching `Event` objects. */
  distinctCount?: Maybe<EventDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Event` objects. */
  min?: Maybe<EventMinAggregateFilter>;
  /** Maximum aggregate over matching `Event` objects. */
  max?: Maybe<EventMaxAggregateFilter>;
  /** Mean average aggregate over matching `Event` objects. */
  average?: Maybe<EventAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Event` objects. */
  stddevSample?: Maybe<EventStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Event` objects. */
  stddevPopulation?: Maybe<EventStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Event` objects. */
  varianceSample?: Maybe<EventVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Event` objects. */
  variancePopulation?: Maybe<EventVariancePopulationAggregateFilter>;
};

export type EventAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `error` field. */
  error?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EventDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  requestId?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  error?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type EventDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of error across the matching connection */
  error?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `event` to look up the row to connect. */
export type EventEventsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `event` to look up the row to delete. */
export type EventEventsPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `requestId` field. */
  requestId?: Maybe<StringFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `error` field. */
  error?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EventFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EventFilter>>;
  /** Negates the expression. */
  not?: Maybe<EventFilter>;
};

export type EventMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type EventMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type EventMinAggregateFilter = {
  id?: Maybe<IntFilter>;
};

export type EventMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type EventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type EventNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `event` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventOnEventForFakePublicEventsForeignKey0NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `event` to look up the row to update. */
export type EventOnEventForFakePublicEventsForeignKey0UsingEventsPkeyUpdate = {
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: UpdateEventOnEventForFakePublicEventsForeignKey0Patch;
  id: Scalars['Int'];
};

/** Represents an update to a `Event`. Fields that are set will be updated. */
export type EventPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<FakePublicEventsForeignKey0Input>;
};

export type EventStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
};

export type EventSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type EventVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
};

export type EventVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EventAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EventAggregates>>;
};


/** A connection to a list of `Event` values. */
export type EventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EventsGroupBy>;
  having?: Maybe<EventsHavingInput>;
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
};

/** Grouping methods for `Event` for usage during aggregation. */
export enum EventsGroupBy {
  LeadId = 'LEAD_ID',
  RequestId = 'REQUEST_ID',
  Meta = 'META',
  Type = 'TYPE',
  Error = 'ERROR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type EventsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Event` aggregates. */
export type EventsHavingInput = {
  AND?: Maybe<Array<EventsHavingInput>>;
  OR?: Maybe<Array<EventsHavingInput>>;
  sum?: Maybe<EventsHavingSumInput>;
  distinctCount?: Maybe<EventsHavingDistinctCountInput>;
  min?: Maybe<EventsHavingMinInput>;
  max?: Maybe<EventsHavingMaxInput>;
  average?: Maybe<EventsHavingAverageInput>;
  stddevSample?: Maybe<EventsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<EventsHavingStddevPopulationInput>;
  varianceSample?: Maybe<EventsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<EventsHavingVariancePopulationInput>;
};

export type EventsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EventsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  RequestIdAsc = 'REQUEST_ID_ASC',
  RequestIdDesc = 'REQUEST_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ErrorAsc = 'ERROR_ASC',
  ErrorDesc = 'ERROR_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `lead` in the `EventInput` mutation. */
export type FakePublicEventsForeignKey0Input = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnEventForFakePublicEventsForeignKey0UsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<EventOnEventForFakePublicEventsForeignKey0NodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<FakePublicEventsForeignKey0LeadCreateInput>;
};

/** Input for the nested mutation of `event` in the `LeadInput` mutation. */
export type FakePublicEventsForeignKey0InverseInput = {
  /** Flag indicating whether all other `event` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectById?: Maybe<Array<EventEventsPkeyConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<EventNodeIdConnect>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteById?: Maybe<Array<EventEventsPkeyDelete>>;
  /** The primary key(s) for `event` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<EventNodeIdDelete>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateById?: Maybe<Array<EventOnEventForFakePublicEventsForeignKey0UsingEventsPkeyUpdate>>;
  /** The primary key(s) and patch data for `event` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnEventForFakePublicEventsForeignKey0NodeIdUpdate>>;
};

/** The `lead` to be created by this mutation. */
export type FakePublicEventsForeignKey0LeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export enum FeedbackType {
  Feedback = 'Feedback',
  Complaint = 'Complaint'
}

export type FetchLenderQuotesInput = {
  leadId: Scalars['UUID'];
  updatedAsset?: Maybe<FixAssetInput>;
};

export type FetchLenderQuotesResult = {
  success: Scalars['Boolean'];
  quotes?: Maybe<Array<Maybe<LenderQuote>>>;
};

export type Field = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
};

export type FieldAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Field` object types. */
export type FieldAggregatesFilter = {
  /** A filter that must pass for the relevant `Field` object to be included within the aggregate. */
  filter?: Maybe<FieldFilter>;
  /** Sum aggregate over matching `Field` objects. */
  sum?: Maybe<FieldSumAggregateFilter>;
  /** Distinct count aggregate over matching `Field` objects. */
  distinctCount?: Maybe<FieldDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Field` objects. */
  min?: Maybe<FieldMinAggregateFilter>;
  /** Maximum aggregate over matching `Field` objects. */
  max?: Maybe<FieldMaxAggregateFilter>;
  /** Mean average aggregate over matching `Field` objects. */
  average?: Maybe<FieldAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Field` objects. */
  stddevSample?: Maybe<FieldStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Field` objects. */
  stddevPopulation?: Maybe<FieldStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Field` objects. */
  varianceSample?: Maybe<FieldVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Field` objects. */
  variancePopulation?: Maybe<FieldVariancePopulationAggregateFilter>;
};

export type FieldAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

/** The `companyChannel` to be created by this mutation. */
export type FieldCompanyChannelIdFkeyCompanyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId: Scalars['String'];
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyChannelIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyChannel` in the `FieldInput` mutation. */
export type FieldCompanyChannelIdFkeyInput = {
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectById?: Maybe<CompanyChannelCompanyChannelPkeyConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyChannelNodeIdConnect>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteById?: Maybe<CompanyChannelCompanyChannelPkeyDelete>;
  /** The primary key(s) for `companyChannel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyChannelNodeIdDelete>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateById?: Maybe<CompanyChannelOnFieldForFieldCompanyChannelIdFkeyUsingCompanyChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `companyChannel` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate>;
  /** A `CompanyChannelInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyChannelIdFkeyCompanyChannelCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyChannelInput` mutation. */
export type FieldCompanyChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyChannelOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyChannelIdFkeyFieldCreateInput>>;
};

/** The `company` to be created by this mutation. */
export type FieldCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `company` in the `FieldInput` mutation. */
export type FieldCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnFieldForFieldCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyInput` mutation. */
export type FieldCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnFieldForFieldCompanyIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyIdFkeyFieldCreateInput>>;
};

/** The `companyIntegration` to be created by this mutation. */
export type FieldCompanyIntegrationIdFkeyCompanyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyIntegrationIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyIntegration` in the `FieldInput` mutation. */
export type FieldCompanyIntegrationIdFkeyInput = {
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyIntegrationNodeIdConnect>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteById?: Maybe<CompanyIntegrationCompanyIntegrationPkeyDelete>;
  /** The primary key(s) for `companyIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyIntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateById?: Maybe<CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyUsingCompanyIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `companyIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate>;
  /** A `CompanyIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyIntegrationIdFkeyCompanyIntegrationCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyIntegrationInput` mutation. */
export type FieldCompanyIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyIntegrationIdFkeyFieldCreateInput>>;
};

/** The `companyProduct` to be created by this mutation. */
export type FieldCompanyProductIdFkeyCompanyProductCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldCompanyProductIdFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `companyProduct` in the `FieldInput` mutation. */
export type FieldCompanyProductIdFkeyInput = {
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectById?: Maybe<CompanyProductCompanyProductPkeyConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyProductNodeIdConnect>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteById?: Maybe<CompanyProductCompanyProductPkeyDelete>;
  /** The primary key(s) for `companyProduct` for the far side of the relationship. */
  deleteByNodeId?: Maybe<CompanyProductNodeIdDelete>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateById?: Maybe<CompanyProductOnFieldForFieldCompanyProductIdFkeyUsingCompanyProductPkeyUpdate>;
  /** The primary key(s) and patch data for `companyProduct` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate>;
  /** A `CompanyProductInput` object that will be created and connected to this object. */
  create?: Maybe<FieldCompanyProductIdFkeyCompanyProductCreateInput>;
};

/** Input for the nested mutation of `field` in the `CompanyProductInput` mutation. */
export type FieldCompanyProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyProductOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldCompanyProductIdFkeyFieldCreateInput>>;
};

/** A condition to be used against `Field` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FieldCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyProductId` field. */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `config` field. */
  config?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `topic` field. */
  topic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type FieldConfig = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type: FieldType;
  label: Scalars['String'];
  helper: Scalars['String'];
  key: Scalars['String'];
  public: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Product` that is related to this `FieldConfig`. */
  product?: Maybe<Product>;
  /** Reads a single `Channel` that is related to this `FieldConfig`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Integration` that is related to this `FieldConfig`. */
  integration?: Maybe<Integration>;
  /** Reads and enables pagination through a set of `Option`. */
  options: OptionsConnection;
  /** Reads and enables pagination through a set of `Field`. */
  fieldsByConfig: FieldsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFieldConfigAndCompanyId: FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProductsByFieldConfigAndCompanyProductId: FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannelsByFieldConfigAndCompanyChannelId: FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrationsByFieldConfigAndCompanyIntegrationId: FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnection;
};


export type FieldConfigOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<OptionsOrderBy>>;
  condition?: Maybe<OptionCondition>;
  filter?: Maybe<OptionFilter>;
};


export type FieldConfigFieldsByConfigArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


export type FieldConfigCompaniesByFieldConfigAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

export type FieldConfigAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldConfigSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldConfigDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldConfigMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldConfigMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldConfigAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldConfigStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldConfigStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldConfigVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldConfigVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `FieldConfig` object types. */
export type FieldConfigAggregatesFilter = {
  /** A filter that must pass for the relevant `FieldConfig` object to be included within the aggregate. */
  filter?: Maybe<FieldConfigFilter>;
  /** Sum aggregate over matching `FieldConfig` objects. */
  sum?: Maybe<FieldConfigSumAggregateFilter>;
  /** Distinct count aggregate over matching `FieldConfig` objects. */
  distinctCount?: Maybe<FieldConfigDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `FieldConfig` objects. */
  min?: Maybe<FieldConfigMinAggregateFilter>;
  /** Maximum aggregate over matching `FieldConfig` objects. */
  max?: Maybe<FieldConfigMaxAggregateFilter>;
  /** Mean average aggregate over matching `FieldConfig` objects. */
  average?: Maybe<FieldConfigAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FieldConfig` objects. */
  stddevSample?: Maybe<FieldConfigStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `FieldConfig` objects. */
  stddevPopulation?: Maybe<FieldConfigStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FieldConfig` objects. */
  varianceSample?: Maybe<FieldConfigVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `FieldConfig` objects. */
  variancePopulation?: Maybe<FieldConfigVariancePopulationAggregateFilter>;
};

export type FieldConfigAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type FieldConfigAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/** The `channel` to be created by this mutation. */
export type FieldConfigChannelIdFkeyChannelCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** The `fieldConfig` to be created by this mutation. */
export type FieldConfigChannelIdFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** Input for the nested mutation of `channel` in the `FieldConfigInput` mutation. */
export type FieldConfigChannelIdFkeyInput = {
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectById?: Maybe<ChannelChannelPkeyConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  connectByNodeId?: Maybe<ChannelNodeIdConnect>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteById?: Maybe<ChannelChannelPkeyDelete>;
  /** The primary key(s) for `channel` for the far side of the relationship. */
  deleteByNodeId?: Maybe<ChannelNodeIdDelete>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateById?: Maybe<ChannelOnFieldConfigForFieldConfigChannelIdFkeyUsingChannelPkeyUpdate>;
  /** The primary key(s) and patch data for `channel` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldConfigOnFieldConfigForFieldConfigChannelIdFkeyNodeIdUpdate>;
  /** A `ChannelInput` object that will be created and connected to this object. */
  create?: Maybe<FieldConfigChannelIdFkeyChannelCreateInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `ChannelInput` mutation. */
export type FieldConfigChannelIdFkeyInverseInput = {
  /** Flag indicating whether all other `fieldConfig` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldConfigFieldConfigPkeyConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldConfigNodeIdConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldConfigFieldConfigPkeyDelete>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldConfigNodeIdDelete>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldConfigOnFieldConfigForFieldConfigChannelIdFkeyUsingFieldConfigPkeyUpdate>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ChannelOnFieldConfigForFieldConfigChannelIdFkeyNodeIdUpdate>>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldConfigChannelIdFkeyFieldConfigCreateInput>>;
};

/** A connection to a list of `Company` values, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `Field`. */
  fields: FieldsConnection;
};


/** A `Company` edge in the connection, with data from `Field`. */
export type FieldConfigCompaniesByFieldConfigAndCompanyIdManyToManyEdgeFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};

/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnection = {
  /** A list of `CompanyChannel` objects. */
  nodes: Array<CompanyChannel>;
  /** A list of edges which contains the `CompanyChannel`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyChannel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyChannelAggregates>>;
};


/** A connection to a list of `CompanyChannel` values, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyChannelGroupBy>;
  having?: Maybe<CompanyChannelHavingInput>;
};

/** A `CompanyChannel` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyChannelsByFieldConfigAndCompanyChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyChannel` at the end of the edge. */
  node: CompanyChannel;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnection = {
  /** A list of `CompanyIntegration` objects. */
  nodes: Array<CompanyIntegration>;
  /** A list of edges which contains the `CompanyIntegration`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyIntegrationAggregates>>;
};


/** A connection to a list of `CompanyIntegration` values, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyIntegrationGroupBy>;
  having?: Maybe<CompanyIntegrationHavingInput>;
};

/** A `CompanyIntegration` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyIntegrationsByFieldConfigAndCompanyIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyIntegration` at the end of the edge. */
  node: CompanyIntegration;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnection = {
  /** A list of `CompanyProduct` objects. */
  nodes: Array<CompanyProduct>;
  /** A list of edges which contains the `CompanyProduct`, info from the `Field`, and the cursor to aid in pagination. */
  edges: Array<FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyProduct` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyProductAggregates>>;
};


/** A connection to a list of `CompanyProduct` values, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyProductGroupBy>;
  having?: Maybe<CompanyProductHavingInput>;
};

/** A `CompanyProduct` edge in the connection, with data from `Field`. */
export type FieldConfigCompanyProductsByFieldConfigAndCompanyProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyProduct` at the end of the edge. */
  node: CompanyProduct;
  id: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `FieldConfig` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FieldConfigCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `channelId` field. */
  channelId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<FieldType>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `helper` field. */
  helper?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `public` field. */
  public?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type FieldConfigDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  label?: Maybe<BigIntFilter>;
  helper?: Maybe<BigIntFilter>;
  key?: Maybe<BigIntFilter>;
  public?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type FieldConfigDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of helper across the matching connection */
  helper?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of public across the matching connection */
  public?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `fieldConfig` to look up the row to connect. */
export type FieldConfigFieldConfigPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `fieldConfig` to look up the row to delete. */
export type FieldConfigFieldConfigPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `FieldConfig` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `channelId` field. */
  channelId?: Maybe<IntFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<FieldTypeFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `helper` field. */
  helper?: Maybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `public` field. */
  public?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `options` relation. */
  options?: Maybe<FieldConfigToManyOptionFilter>;
  /** Some related `options` exist. */
  optionsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldsByConfig` relation. */
  fieldsByConfig?: Maybe<FieldConfigToManyFieldFilter>;
  /** Some related `fieldsByConfig` exist. */
  fieldsByConfigExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** A related `product` exists. */
  productExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `channel` relation. */
  channel?: Maybe<ChannelFilter>;
  /** A related `channel` exists. */
  channelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldConfigFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldConfigFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldConfigFilter>;
};

/** The `fieldConfig` to be created by this mutation. */
export type FieldConfigFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** The `field` to be created by this mutation. */
export type FieldConfigFkeyFieldCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `FieldInput` mutation. */
export type FieldConfigFkeyInput = {
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<FieldConfigFieldConfigPkeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldConfigNodeIdConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<FieldConfigFieldConfigPkeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldConfigNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<FieldConfigOnFieldForFieldConfigFkeyUsingFieldConfigPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldOnFieldForFieldConfigFkeyNodeIdUpdate>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<FieldConfigFkeyFieldConfigCreateInput>;
};

/** Input for the nested mutation of `field` in the `FieldConfigInput` mutation. */
export type FieldConfigFkeyInverseInput = {
  /** Flag indicating whether all other `field` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldFieldPkeyConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldNodeIdConnect>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldFieldPkeyDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyProductIdAndConfig?: Maybe<Array<FieldFieldUniqueProductDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyChannelIdAndConfig?: Maybe<Array<FieldFieldUniqueChannelDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldFieldUniqueIntegrationDelete>>;
  /** The primary key(s) for `field` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldNodeIdDelete>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldPkeyUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyProductIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueProductUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyChannelIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueChannelUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByCompanyIntegrationIdAndConfig?: Maybe<Array<FieldOnFieldForFieldConfigFkeyUsingFieldUniqueIntegrationUpdate>>;
  /** The primary key(s) and patch data for `field` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldConfigOnFieldForFieldConfigFkeyNodeIdUpdate>>;
  /** A `FieldInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldConfigFkeyFieldCreateInput>>;
};

/** Grouping methods for `FieldConfig` for usage during aggregation. */
export enum FieldConfigGroupBy {
  ProductId = 'PRODUCT_ID',
  ChannelId = 'CHANNEL_ID',
  IntegrationId = 'INTEGRATION_ID',
  Type = 'TYPE',
  Label = 'LABEL',
  Helper = 'HELPER',
  Key = 'KEY',
  Public = 'PUBLIC',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FieldConfigHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `FieldConfig` aggregates. */
export type FieldConfigHavingInput = {
  AND?: Maybe<Array<FieldConfigHavingInput>>;
  OR?: Maybe<Array<FieldConfigHavingInput>>;
  sum?: Maybe<FieldConfigHavingSumInput>;
  distinctCount?: Maybe<FieldConfigHavingDistinctCountInput>;
  min?: Maybe<FieldConfigHavingMinInput>;
  max?: Maybe<FieldConfigHavingMaxInput>;
  average?: Maybe<FieldConfigHavingAverageInput>;
  stddevSample?: Maybe<FieldConfigHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldConfigHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldConfigHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldConfigHavingVariancePopulationInput>;
};

export type FieldConfigHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldConfigHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  channelId?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `FieldConfig` */
export type FieldConfigInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** The `fieldConfig` to be created by this mutation. */
export type FieldConfigIntegrationIdFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** Input for the nested mutation of `integration` in the `FieldConfigInput` mutation. */
export type FieldConfigIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<FieldConfigIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type FieldConfigIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `IntegrationInput` mutation. */
export type FieldConfigIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `fieldConfig` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldConfigFieldConfigPkeyConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldConfigNodeIdConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldConfigFieldConfigPkeyDelete>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldConfigNodeIdDelete>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyUsingFieldConfigPkeyUpdate>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyNodeIdUpdate>>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldConfigIntegrationIdFkeyFieldConfigCreateInput>>;
};

export type FieldConfigMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
};

export type FieldConfigMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Maximum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type FieldConfigMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
  channelId?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
};

export type FieldConfigMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
  /** Minimum of channelId across the matching connection */
  channelId?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldConfigNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldConfigNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `channel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `channel` being updated. */
  patch: ChannelPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigChannelIdFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldConfigForFieldConfigChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldConfigForFieldConfigProductIdFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldConfigForFieldConfigProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnFieldForFieldConfigFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: FieldPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnFieldForFieldConfigFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnFieldForFieldConfigFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldConfigOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `option` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `option` being updated. */
  patch: OptionPatch;
};

/** The fields on `fieldConfig` to look up the row to update. */
export type FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingFieldConfigPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: UpdateFieldConfigOnOptionForOptionFieldConfigIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `FieldConfig`. Fields that are set will be updated. */
export type FieldConfigPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** The `fieldConfig` to be created by this mutation. */
export type FieldConfigProductIdFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** Input for the nested mutation of `product` in the `FieldConfigInput` mutation. */
export type FieldConfigProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnFieldConfigForFieldConfigProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnFieldConfigForFieldConfigProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<FieldConfigOnFieldConfigForFieldConfigProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `fieldConfig` in the `ProductInput` mutation. */
export type FieldConfigProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `fieldConfig` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<Array<FieldConfigFieldConfigPkeyConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FieldConfigNodeIdConnect>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<Array<FieldConfigFieldConfigPkeyDelete>>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FieldConfigNodeIdDelete>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<Array<FieldConfigOnFieldConfigForFieldConfigProductIdFkeyUsingFieldConfigPkeyUpdate>>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ProductOnFieldConfigForFieldConfigProductIdFkeyNodeIdUpdate>>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FieldConfigProductIdFkeyFieldConfigCreateInput>>;
};

export type FieldConfigStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type FieldConfigStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type FieldConfigStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  channelId?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
};

export type FieldConfigSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
  /** Sum of channelId across the matching connection */
  channelId: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
};

/** A filter to be used against many `Field` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigToManyFieldFilter = {
  /** Every related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldFilter>;
  /** Some related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldFilter>;
  /** No related `Field` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldFilter>;
  /** Aggregates across related `Field` match the filter criteria. */
  aggregates?: Maybe<FieldAggregatesFilter>;
};

/** A filter to be used against many `Option` object types. All fields are combined with a logical ‘and.’ */
export type FieldConfigToManyOptionFilter = {
  /** Every related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<OptionFilter>;
  /** Some related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<OptionFilter>;
  /** No related `Option` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<OptionFilter>;
  /** Aggregates across related `Option` match the filter criteria. */
  aggregates?: Maybe<OptionAggregatesFilter>;
};

export type FieldConfigVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type FieldConfigVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type FieldConfigVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
  channelId?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
};

export type FieldConfigVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of channelId across the matching connection */
  channelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FieldConfig` values. */
export type FieldConfigsConnection = {
  /** A list of `FieldConfig` objects. */
  nodes: Array<FieldConfig>;
  /** A list of edges which contains the `FieldConfig` and cursor to aid in pagination. */
  edges: Array<FieldConfigsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldConfigAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldConfigAggregates>>;
};


/** A connection to a list of `FieldConfig` values. */
export type FieldConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldConfigGroupBy>;
  having?: Maybe<FieldConfigHavingInput>;
};

/** A `FieldConfig` edge in the connection. */
export type FieldConfigsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldConfig` at the end of the edge. */
  node: FieldConfig;
};

/** Methods to use when ordering `FieldConfig`. */
export enum FieldConfigsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ChannelIdAsc = 'CHANNEL_ID_ASC',
  ChannelIdDesc = 'CHANNEL_ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  HelperAsc = 'HELPER_ASC',
  HelperDesc = 'HELPER_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  PublicAsc = 'PUBLIC_ASC',
  PublicDesc = 'PUBLIC_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  OptionsCountAsc = 'OPTIONS_COUNT_ASC',
  OptionsCountDesc = 'OPTIONS_COUNT_DESC',
  OptionsSumIdAsc = 'OPTIONS_SUM_ID_ASC',
  OptionsSumIdDesc = 'OPTIONS_SUM_ID_DESC',
  OptionsSumFieldConfigIdAsc = 'OPTIONS_SUM_FIELD_CONFIG_ID_ASC',
  OptionsSumFieldConfigIdDesc = 'OPTIONS_SUM_FIELD_CONFIG_ID_DESC',
  OptionsSumDisplayAsc = 'OPTIONS_SUM_DISPLAY_ASC',
  OptionsSumDisplayDesc = 'OPTIONS_SUM_DISPLAY_DESC',
  OptionsSumValueAsc = 'OPTIONS_SUM_VALUE_ASC',
  OptionsSumValueDesc = 'OPTIONS_SUM_VALUE_DESC',
  OptionsSumEnabledAsc = 'OPTIONS_SUM_ENABLED_ASC',
  OptionsSumEnabledDesc = 'OPTIONS_SUM_ENABLED_DESC',
  OptionsSumCreatedAtAsc = 'OPTIONS_SUM_CREATED_AT_ASC',
  OptionsSumCreatedAtDesc = 'OPTIONS_SUM_CREATED_AT_DESC',
  OptionsSumUpdatedAtAsc = 'OPTIONS_SUM_UPDATED_AT_ASC',
  OptionsSumUpdatedAtDesc = 'OPTIONS_SUM_UPDATED_AT_DESC',
  OptionsDistinctCountIdAsc = 'OPTIONS_DISTINCT_COUNT_ID_ASC',
  OptionsDistinctCountIdDesc = 'OPTIONS_DISTINCT_COUNT_ID_DESC',
  OptionsDistinctCountFieldConfigIdAsc = 'OPTIONS_DISTINCT_COUNT_FIELD_CONFIG_ID_ASC',
  OptionsDistinctCountFieldConfigIdDesc = 'OPTIONS_DISTINCT_COUNT_FIELD_CONFIG_ID_DESC',
  OptionsDistinctCountDisplayAsc = 'OPTIONS_DISTINCT_COUNT_DISPLAY_ASC',
  OptionsDistinctCountDisplayDesc = 'OPTIONS_DISTINCT_COUNT_DISPLAY_DESC',
  OptionsDistinctCountValueAsc = 'OPTIONS_DISTINCT_COUNT_VALUE_ASC',
  OptionsDistinctCountValueDesc = 'OPTIONS_DISTINCT_COUNT_VALUE_DESC',
  OptionsDistinctCountEnabledAsc = 'OPTIONS_DISTINCT_COUNT_ENABLED_ASC',
  OptionsDistinctCountEnabledDesc = 'OPTIONS_DISTINCT_COUNT_ENABLED_DESC',
  OptionsDistinctCountCreatedAtAsc = 'OPTIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  OptionsDistinctCountCreatedAtDesc = 'OPTIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  OptionsDistinctCountUpdatedAtAsc = 'OPTIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  OptionsDistinctCountUpdatedAtDesc = 'OPTIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  OptionsMinIdAsc = 'OPTIONS_MIN_ID_ASC',
  OptionsMinIdDesc = 'OPTIONS_MIN_ID_DESC',
  OptionsMinFieldConfigIdAsc = 'OPTIONS_MIN_FIELD_CONFIG_ID_ASC',
  OptionsMinFieldConfigIdDesc = 'OPTIONS_MIN_FIELD_CONFIG_ID_DESC',
  OptionsMinDisplayAsc = 'OPTIONS_MIN_DISPLAY_ASC',
  OptionsMinDisplayDesc = 'OPTIONS_MIN_DISPLAY_DESC',
  OptionsMinValueAsc = 'OPTIONS_MIN_VALUE_ASC',
  OptionsMinValueDesc = 'OPTIONS_MIN_VALUE_DESC',
  OptionsMinEnabledAsc = 'OPTIONS_MIN_ENABLED_ASC',
  OptionsMinEnabledDesc = 'OPTIONS_MIN_ENABLED_DESC',
  OptionsMinCreatedAtAsc = 'OPTIONS_MIN_CREATED_AT_ASC',
  OptionsMinCreatedAtDesc = 'OPTIONS_MIN_CREATED_AT_DESC',
  OptionsMinUpdatedAtAsc = 'OPTIONS_MIN_UPDATED_AT_ASC',
  OptionsMinUpdatedAtDesc = 'OPTIONS_MIN_UPDATED_AT_DESC',
  OptionsMaxIdAsc = 'OPTIONS_MAX_ID_ASC',
  OptionsMaxIdDesc = 'OPTIONS_MAX_ID_DESC',
  OptionsMaxFieldConfigIdAsc = 'OPTIONS_MAX_FIELD_CONFIG_ID_ASC',
  OptionsMaxFieldConfigIdDesc = 'OPTIONS_MAX_FIELD_CONFIG_ID_DESC',
  OptionsMaxDisplayAsc = 'OPTIONS_MAX_DISPLAY_ASC',
  OptionsMaxDisplayDesc = 'OPTIONS_MAX_DISPLAY_DESC',
  OptionsMaxValueAsc = 'OPTIONS_MAX_VALUE_ASC',
  OptionsMaxValueDesc = 'OPTIONS_MAX_VALUE_DESC',
  OptionsMaxEnabledAsc = 'OPTIONS_MAX_ENABLED_ASC',
  OptionsMaxEnabledDesc = 'OPTIONS_MAX_ENABLED_DESC',
  OptionsMaxCreatedAtAsc = 'OPTIONS_MAX_CREATED_AT_ASC',
  OptionsMaxCreatedAtDesc = 'OPTIONS_MAX_CREATED_AT_DESC',
  OptionsMaxUpdatedAtAsc = 'OPTIONS_MAX_UPDATED_AT_ASC',
  OptionsMaxUpdatedAtDesc = 'OPTIONS_MAX_UPDATED_AT_DESC',
  OptionsAverageIdAsc = 'OPTIONS_AVERAGE_ID_ASC',
  OptionsAverageIdDesc = 'OPTIONS_AVERAGE_ID_DESC',
  OptionsAverageFieldConfigIdAsc = 'OPTIONS_AVERAGE_FIELD_CONFIG_ID_ASC',
  OptionsAverageFieldConfigIdDesc = 'OPTIONS_AVERAGE_FIELD_CONFIG_ID_DESC',
  OptionsAverageDisplayAsc = 'OPTIONS_AVERAGE_DISPLAY_ASC',
  OptionsAverageDisplayDesc = 'OPTIONS_AVERAGE_DISPLAY_DESC',
  OptionsAverageValueAsc = 'OPTIONS_AVERAGE_VALUE_ASC',
  OptionsAverageValueDesc = 'OPTIONS_AVERAGE_VALUE_DESC',
  OptionsAverageEnabledAsc = 'OPTIONS_AVERAGE_ENABLED_ASC',
  OptionsAverageEnabledDesc = 'OPTIONS_AVERAGE_ENABLED_DESC',
  OptionsAverageCreatedAtAsc = 'OPTIONS_AVERAGE_CREATED_AT_ASC',
  OptionsAverageCreatedAtDesc = 'OPTIONS_AVERAGE_CREATED_AT_DESC',
  OptionsAverageUpdatedAtAsc = 'OPTIONS_AVERAGE_UPDATED_AT_ASC',
  OptionsAverageUpdatedAtDesc = 'OPTIONS_AVERAGE_UPDATED_AT_DESC',
  OptionsStddevSampleIdAsc = 'OPTIONS_STDDEV_SAMPLE_ID_ASC',
  OptionsStddevSampleIdDesc = 'OPTIONS_STDDEV_SAMPLE_ID_DESC',
  OptionsStddevSampleFieldConfigIdAsc = 'OPTIONS_STDDEV_SAMPLE_FIELD_CONFIG_ID_ASC',
  OptionsStddevSampleFieldConfigIdDesc = 'OPTIONS_STDDEV_SAMPLE_FIELD_CONFIG_ID_DESC',
  OptionsStddevSampleDisplayAsc = 'OPTIONS_STDDEV_SAMPLE_DISPLAY_ASC',
  OptionsStddevSampleDisplayDesc = 'OPTIONS_STDDEV_SAMPLE_DISPLAY_DESC',
  OptionsStddevSampleValueAsc = 'OPTIONS_STDDEV_SAMPLE_VALUE_ASC',
  OptionsStddevSampleValueDesc = 'OPTIONS_STDDEV_SAMPLE_VALUE_DESC',
  OptionsStddevSampleEnabledAsc = 'OPTIONS_STDDEV_SAMPLE_ENABLED_ASC',
  OptionsStddevSampleEnabledDesc = 'OPTIONS_STDDEV_SAMPLE_ENABLED_DESC',
  OptionsStddevSampleCreatedAtAsc = 'OPTIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  OptionsStddevSampleCreatedAtDesc = 'OPTIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  OptionsStddevSampleUpdatedAtAsc = 'OPTIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  OptionsStddevSampleUpdatedAtDesc = 'OPTIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  OptionsStddevPopulationIdAsc = 'OPTIONS_STDDEV_POPULATION_ID_ASC',
  OptionsStddevPopulationIdDesc = 'OPTIONS_STDDEV_POPULATION_ID_DESC',
  OptionsStddevPopulationFieldConfigIdAsc = 'OPTIONS_STDDEV_POPULATION_FIELD_CONFIG_ID_ASC',
  OptionsStddevPopulationFieldConfigIdDesc = 'OPTIONS_STDDEV_POPULATION_FIELD_CONFIG_ID_DESC',
  OptionsStddevPopulationDisplayAsc = 'OPTIONS_STDDEV_POPULATION_DISPLAY_ASC',
  OptionsStddevPopulationDisplayDesc = 'OPTIONS_STDDEV_POPULATION_DISPLAY_DESC',
  OptionsStddevPopulationValueAsc = 'OPTIONS_STDDEV_POPULATION_VALUE_ASC',
  OptionsStddevPopulationValueDesc = 'OPTIONS_STDDEV_POPULATION_VALUE_DESC',
  OptionsStddevPopulationEnabledAsc = 'OPTIONS_STDDEV_POPULATION_ENABLED_ASC',
  OptionsStddevPopulationEnabledDesc = 'OPTIONS_STDDEV_POPULATION_ENABLED_DESC',
  OptionsStddevPopulationCreatedAtAsc = 'OPTIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  OptionsStddevPopulationCreatedAtDesc = 'OPTIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  OptionsStddevPopulationUpdatedAtAsc = 'OPTIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  OptionsStddevPopulationUpdatedAtDesc = 'OPTIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  OptionsVarianceSampleIdAsc = 'OPTIONS_VARIANCE_SAMPLE_ID_ASC',
  OptionsVarianceSampleIdDesc = 'OPTIONS_VARIANCE_SAMPLE_ID_DESC',
  OptionsVarianceSampleFieldConfigIdAsc = 'OPTIONS_VARIANCE_SAMPLE_FIELD_CONFIG_ID_ASC',
  OptionsVarianceSampleFieldConfigIdDesc = 'OPTIONS_VARIANCE_SAMPLE_FIELD_CONFIG_ID_DESC',
  OptionsVarianceSampleDisplayAsc = 'OPTIONS_VARIANCE_SAMPLE_DISPLAY_ASC',
  OptionsVarianceSampleDisplayDesc = 'OPTIONS_VARIANCE_SAMPLE_DISPLAY_DESC',
  OptionsVarianceSampleValueAsc = 'OPTIONS_VARIANCE_SAMPLE_VALUE_ASC',
  OptionsVarianceSampleValueDesc = 'OPTIONS_VARIANCE_SAMPLE_VALUE_DESC',
  OptionsVarianceSampleEnabledAsc = 'OPTIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  OptionsVarianceSampleEnabledDesc = 'OPTIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  OptionsVarianceSampleCreatedAtAsc = 'OPTIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  OptionsVarianceSampleCreatedAtDesc = 'OPTIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  OptionsVarianceSampleUpdatedAtAsc = 'OPTIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  OptionsVarianceSampleUpdatedAtDesc = 'OPTIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  OptionsVariancePopulationIdAsc = 'OPTIONS_VARIANCE_POPULATION_ID_ASC',
  OptionsVariancePopulationIdDesc = 'OPTIONS_VARIANCE_POPULATION_ID_DESC',
  OptionsVariancePopulationFieldConfigIdAsc = 'OPTIONS_VARIANCE_POPULATION_FIELD_CONFIG_ID_ASC',
  OptionsVariancePopulationFieldConfigIdDesc = 'OPTIONS_VARIANCE_POPULATION_FIELD_CONFIG_ID_DESC',
  OptionsVariancePopulationDisplayAsc = 'OPTIONS_VARIANCE_POPULATION_DISPLAY_ASC',
  OptionsVariancePopulationDisplayDesc = 'OPTIONS_VARIANCE_POPULATION_DISPLAY_DESC',
  OptionsVariancePopulationValueAsc = 'OPTIONS_VARIANCE_POPULATION_VALUE_ASC',
  OptionsVariancePopulationValueDesc = 'OPTIONS_VARIANCE_POPULATION_VALUE_DESC',
  OptionsVariancePopulationEnabledAsc = 'OPTIONS_VARIANCE_POPULATION_ENABLED_ASC',
  OptionsVariancePopulationEnabledDesc = 'OPTIONS_VARIANCE_POPULATION_ENABLED_DESC',
  OptionsVariancePopulationCreatedAtAsc = 'OPTIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  OptionsVariancePopulationCreatedAtDesc = 'OPTIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  OptionsVariancePopulationUpdatedAtAsc = 'OPTIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  OptionsVariancePopulationUpdatedAtDesc = 'OPTIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldsByConfigCountAsc = 'FIELDS_BY_CONFIG_COUNT_ASC',
  FieldsByConfigCountDesc = 'FIELDS_BY_CONFIG_COUNT_DESC',
  FieldsByConfigSumIdAsc = 'FIELDS_BY_CONFIG_SUM_ID_ASC',
  FieldsByConfigSumIdDesc = 'FIELDS_BY_CONFIG_SUM_ID_DESC',
  FieldsByConfigSumCompanyIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_ID_ASC',
  FieldsByConfigSumCompanyIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_ID_DESC',
  FieldsByConfigSumCompanyProductIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigSumCompanyProductIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigSumCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigSumCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigSumCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_SUM_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigSumCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_SUM_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigSumConfigAsc = 'FIELDS_BY_CONFIG_SUM_CONFIG_ASC',
  FieldsByConfigSumConfigDesc = 'FIELDS_BY_CONFIG_SUM_CONFIG_DESC',
  FieldsByConfigSumTopicAsc = 'FIELDS_BY_CONFIG_SUM_TOPIC_ASC',
  FieldsByConfigSumTopicDesc = 'FIELDS_BY_CONFIG_SUM_TOPIC_DESC',
  FieldsByConfigSumValueAsc = 'FIELDS_BY_CONFIG_SUM_VALUE_ASC',
  FieldsByConfigSumValueDesc = 'FIELDS_BY_CONFIG_SUM_VALUE_DESC',
  FieldsByConfigSumCreatedAtAsc = 'FIELDS_BY_CONFIG_SUM_CREATED_AT_ASC',
  FieldsByConfigSumCreatedAtDesc = 'FIELDS_BY_CONFIG_SUM_CREATED_AT_DESC',
  FieldsByConfigSumUpdatedAtAsc = 'FIELDS_BY_CONFIG_SUM_UPDATED_AT_ASC',
  FieldsByConfigSumUpdatedAtDesc = 'FIELDS_BY_CONFIG_SUM_UPDATED_AT_DESC',
  FieldsByConfigDistinctCountIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_ID_ASC',
  FieldsByConfigDistinctCountIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_ID_DESC',
  FieldsByConfigDistinctCountCompanyIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_ID_ASC',
  FieldsByConfigDistinctCountCompanyIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_ID_DESC',
  FieldsByConfigDistinctCountCompanyProductIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigDistinctCountCompanyProductIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigDistinctCountCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigDistinctCountCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigDistinctCountCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigDistinctCountCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigDistinctCountConfigAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CONFIG_ASC',
  FieldsByConfigDistinctCountConfigDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CONFIG_DESC',
  FieldsByConfigDistinctCountTopicAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_TOPIC_ASC',
  FieldsByConfigDistinctCountTopicDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_TOPIC_DESC',
  FieldsByConfigDistinctCountValueAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_VALUE_ASC',
  FieldsByConfigDistinctCountValueDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_VALUE_DESC',
  FieldsByConfigDistinctCountCreatedAtAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldsByConfigDistinctCountCreatedAtDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldsByConfigDistinctCountUpdatedAtAsc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldsByConfigDistinctCountUpdatedAtDesc = 'FIELDS_BY_CONFIG_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldsByConfigMinIdAsc = 'FIELDS_BY_CONFIG_MIN_ID_ASC',
  FieldsByConfigMinIdDesc = 'FIELDS_BY_CONFIG_MIN_ID_DESC',
  FieldsByConfigMinCompanyIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_ID_ASC',
  FieldsByConfigMinCompanyIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_ID_DESC',
  FieldsByConfigMinCompanyProductIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigMinCompanyProductIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigMinCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigMinCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigMinCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_MIN_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigMinCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_MIN_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigMinConfigAsc = 'FIELDS_BY_CONFIG_MIN_CONFIG_ASC',
  FieldsByConfigMinConfigDesc = 'FIELDS_BY_CONFIG_MIN_CONFIG_DESC',
  FieldsByConfigMinTopicAsc = 'FIELDS_BY_CONFIG_MIN_TOPIC_ASC',
  FieldsByConfigMinTopicDesc = 'FIELDS_BY_CONFIG_MIN_TOPIC_DESC',
  FieldsByConfigMinValueAsc = 'FIELDS_BY_CONFIG_MIN_VALUE_ASC',
  FieldsByConfigMinValueDesc = 'FIELDS_BY_CONFIG_MIN_VALUE_DESC',
  FieldsByConfigMinCreatedAtAsc = 'FIELDS_BY_CONFIG_MIN_CREATED_AT_ASC',
  FieldsByConfigMinCreatedAtDesc = 'FIELDS_BY_CONFIG_MIN_CREATED_AT_DESC',
  FieldsByConfigMinUpdatedAtAsc = 'FIELDS_BY_CONFIG_MIN_UPDATED_AT_ASC',
  FieldsByConfigMinUpdatedAtDesc = 'FIELDS_BY_CONFIG_MIN_UPDATED_AT_DESC',
  FieldsByConfigMaxIdAsc = 'FIELDS_BY_CONFIG_MAX_ID_ASC',
  FieldsByConfigMaxIdDesc = 'FIELDS_BY_CONFIG_MAX_ID_DESC',
  FieldsByConfigMaxCompanyIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_ID_ASC',
  FieldsByConfigMaxCompanyIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_ID_DESC',
  FieldsByConfigMaxCompanyProductIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigMaxCompanyProductIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigMaxCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigMaxCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigMaxCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_MAX_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigMaxCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_MAX_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigMaxConfigAsc = 'FIELDS_BY_CONFIG_MAX_CONFIG_ASC',
  FieldsByConfigMaxConfigDesc = 'FIELDS_BY_CONFIG_MAX_CONFIG_DESC',
  FieldsByConfigMaxTopicAsc = 'FIELDS_BY_CONFIG_MAX_TOPIC_ASC',
  FieldsByConfigMaxTopicDesc = 'FIELDS_BY_CONFIG_MAX_TOPIC_DESC',
  FieldsByConfigMaxValueAsc = 'FIELDS_BY_CONFIG_MAX_VALUE_ASC',
  FieldsByConfigMaxValueDesc = 'FIELDS_BY_CONFIG_MAX_VALUE_DESC',
  FieldsByConfigMaxCreatedAtAsc = 'FIELDS_BY_CONFIG_MAX_CREATED_AT_ASC',
  FieldsByConfigMaxCreatedAtDesc = 'FIELDS_BY_CONFIG_MAX_CREATED_AT_DESC',
  FieldsByConfigMaxUpdatedAtAsc = 'FIELDS_BY_CONFIG_MAX_UPDATED_AT_ASC',
  FieldsByConfigMaxUpdatedAtDesc = 'FIELDS_BY_CONFIG_MAX_UPDATED_AT_DESC',
  FieldsByConfigAverageIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_ID_ASC',
  FieldsByConfigAverageIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_ID_DESC',
  FieldsByConfigAverageCompanyIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_ID_ASC',
  FieldsByConfigAverageCompanyIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_ID_DESC',
  FieldsByConfigAverageCompanyProductIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigAverageCompanyProductIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigAverageCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigAverageCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigAverageCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigAverageCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_AVERAGE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigAverageConfigAsc = 'FIELDS_BY_CONFIG_AVERAGE_CONFIG_ASC',
  FieldsByConfigAverageConfigDesc = 'FIELDS_BY_CONFIG_AVERAGE_CONFIG_DESC',
  FieldsByConfigAverageTopicAsc = 'FIELDS_BY_CONFIG_AVERAGE_TOPIC_ASC',
  FieldsByConfigAverageTopicDesc = 'FIELDS_BY_CONFIG_AVERAGE_TOPIC_DESC',
  FieldsByConfigAverageValueAsc = 'FIELDS_BY_CONFIG_AVERAGE_VALUE_ASC',
  FieldsByConfigAverageValueDesc = 'FIELDS_BY_CONFIG_AVERAGE_VALUE_DESC',
  FieldsByConfigAverageCreatedAtAsc = 'FIELDS_BY_CONFIG_AVERAGE_CREATED_AT_ASC',
  FieldsByConfigAverageCreatedAtDesc = 'FIELDS_BY_CONFIG_AVERAGE_CREATED_AT_DESC',
  FieldsByConfigAverageUpdatedAtAsc = 'FIELDS_BY_CONFIG_AVERAGE_UPDATED_AT_ASC',
  FieldsByConfigAverageUpdatedAtDesc = 'FIELDS_BY_CONFIG_AVERAGE_UPDATED_AT_DESC',
  FieldsByConfigStddevSampleIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_ID_ASC',
  FieldsByConfigStddevSampleIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_ID_DESC',
  FieldsByConfigStddevSampleCompanyIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FieldsByConfigStddevSampleCompanyIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FieldsByConfigStddevSampleCompanyProductIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigStddevSampleCompanyProductIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigStddevSampleCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigStddevSampleCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigStddevSampleCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigStddevSampleCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigStddevSampleConfigAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CONFIG_ASC',
  FieldsByConfigStddevSampleConfigDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CONFIG_DESC',
  FieldsByConfigStddevSampleTopicAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_TOPIC_ASC',
  FieldsByConfigStddevSampleTopicDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_TOPIC_DESC',
  FieldsByConfigStddevSampleValueAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_VALUE_ASC',
  FieldsByConfigStddevSampleValueDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_VALUE_DESC',
  FieldsByConfigStddevSampleCreatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldsByConfigStddevSampleCreatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldsByConfigStddevSampleUpdatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldsByConfigStddevSampleUpdatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldsByConfigStddevPopulationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_ID_ASC',
  FieldsByConfigStddevPopulationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_ID_DESC',
  FieldsByConfigStddevPopulationCompanyIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_ID_ASC',
  FieldsByConfigStddevPopulationCompanyIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_ID_DESC',
  FieldsByConfigStddevPopulationCompanyProductIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigStddevPopulationCompanyProductIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigStddevPopulationCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigStddevPopulationCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigStddevPopulationCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigStddevPopulationCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigStddevPopulationConfigAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CONFIG_ASC',
  FieldsByConfigStddevPopulationConfigDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CONFIG_DESC',
  FieldsByConfigStddevPopulationTopicAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_TOPIC_ASC',
  FieldsByConfigStddevPopulationTopicDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_TOPIC_DESC',
  FieldsByConfigStddevPopulationValueAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_VALUE_ASC',
  FieldsByConfigStddevPopulationValueDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_VALUE_DESC',
  FieldsByConfigStddevPopulationCreatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldsByConfigStddevPopulationCreatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldsByConfigStddevPopulationUpdatedAtAsc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldsByConfigStddevPopulationUpdatedAtDesc = 'FIELDS_BY_CONFIG_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldsByConfigVarianceSampleIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_ID_ASC',
  FieldsByConfigVarianceSampleIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_ID_DESC',
  FieldsByConfigVarianceSampleCompanyIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FieldsByConfigVarianceSampleCompanyIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FieldsByConfigVarianceSampleCompanyProductIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigVarianceSampleCompanyProductIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigVarianceSampleCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigVarianceSampleCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigVarianceSampleCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigVarianceSampleCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigVarianceSampleConfigAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CONFIG_ASC',
  FieldsByConfigVarianceSampleConfigDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CONFIG_DESC',
  FieldsByConfigVarianceSampleTopicAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_TOPIC_ASC',
  FieldsByConfigVarianceSampleTopicDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_TOPIC_DESC',
  FieldsByConfigVarianceSampleValueAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_VALUE_ASC',
  FieldsByConfigVarianceSampleValueDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_VALUE_DESC',
  FieldsByConfigVarianceSampleCreatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldsByConfigVarianceSampleCreatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldsByConfigVarianceSampleUpdatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldsByConfigVarianceSampleUpdatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldsByConfigVariancePopulationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_ID_ASC',
  FieldsByConfigVariancePopulationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_ID_DESC',
  FieldsByConfigVariancePopulationCompanyIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FieldsByConfigVariancePopulationCompanyIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FieldsByConfigVariancePopulationCompanyProductIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_ASC',
  FieldsByConfigVariancePopulationCompanyProductIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_PRODUCT_ID_DESC',
  FieldsByConfigVariancePopulationCompanyChannelIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_ASC',
  FieldsByConfigVariancePopulationCompanyChannelIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_CHANNEL_ID_DESC',
  FieldsByConfigVariancePopulationCompanyIntegrationIdAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_ASC',
  FieldsByConfigVariancePopulationCompanyIntegrationIdDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_COMPANY_INTEGRATION_ID_DESC',
  FieldsByConfigVariancePopulationConfigAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CONFIG_ASC',
  FieldsByConfigVariancePopulationConfigDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CONFIG_DESC',
  FieldsByConfigVariancePopulationTopicAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_TOPIC_ASC',
  FieldsByConfigVariancePopulationTopicDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_TOPIC_DESC',
  FieldsByConfigVariancePopulationValueAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_VALUE_ASC',
  FieldsByConfigVariancePopulationValueDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_VALUE_DESC',
  FieldsByConfigVariancePopulationCreatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldsByConfigVariancePopulationCreatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldsByConfigVariancePopulationUpdatedAtAsc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldsByConfigVariancePopulationUpdatedAtDesc = 'FIELDS_BY_CONFIG_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type FieldDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
  topic?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type FieldDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of topic across the matching connection */
  topic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueChannelConnect = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueChannelDelete = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueIntegrationConnect = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueIntegrationDelete = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to connect. */
export type FieldFieldUniqueProductConnect = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to delete. */
export type FieldFieldUniqueProductDelete = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** A filter to be used against `Field` object types. All fields are combined with a logical ‘and.’ */
export type FieldFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyProductId` field. */
  companyProductId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyChannelId` field. */
  companyChannelId?: Maybe<IntFilter>;
  /** Filter by the object’s `companyIntegrationId` field. */
  companyIntegrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `config` field. */
  config?: Maybe<IntFilter>;
  /** Filter by the object’s `topic` field. */
  topic?: Maybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyProduct` relation. */
  companyProduct?: Maybe<CompanyProductFilter>;
  /** A related `companyProduct` exists. */
  companyProductExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyChannel` relation. */
  companyChannel?: Maybe<CompanyChannelFilter>;
  /** A related `companyChannel` exists. */
  companyChannelExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyIntegration` relation. */
  companyIntegration?: Maybe<CompanyIntegrationFilter>;
  /** A related `companyIntegration` exists. */
  companyIntegrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldConfigByConfig` relation. */
  fieldConfigByConfig?: Maybe<FieldConfigFilter>;
  /** A related `fieldConfigByConfig` exists. */
  fieldConfigByConfigExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldFilter>;
};

/** Grouping methods for `Field` for usage during aggregation. */
export enum FieldGroupBy {
  CompanyId = 'COMPANY_ID',
  CompanyProductId = 'COMPANY_PRODUCT_ID',
  CompanyChannelId = 'COMPANY_CHANNEL_ID',
  CompanyIntegrationId = 'COMPANY_INTEGRATION_ID',
  Config = 'CONFIG',
  Topic = 'TOPIC',
  Value = 'VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FieldHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Field` aggregates. */
export type FieldHavingInput = {
  AND?: Maybe<Array<FieldHavingInput>>;
  OR?: Maybe<Array<FieldHavingInput>>;
  sum?: Maybe<FieldHavingSumInput>;
  distinctCount?: Maybe<FieldHavingDistinctCountInput>;
  min?: Maybe<FieldHavingMinInput>;
  max?: Maybe<FieldHavingMaxInput>;
  average?: Maybe<FieldHavingAverageInput>;
  stddevSample?: Maybe<FieldHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldHavingVariancePopulationInput>;
};

export type FieldHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  companyId?: Maybe<HavingIntFilter>;
  companyProductId?: Maybe<HavingIntFilter>;
  companyChannelId?: Maybe<HavingIntFilter>;
  companyIntegrationId?: Maybe<HavingIntFilter>;
  config?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Field` */
export type FieldInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

export type FieldKey = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  key: Scalars['String'];
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettingsByFieldKey: WidgetSettingsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByWidgetSettingFieldKeyAndWidgetId: FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnection;
};


export type FieldKeyWidgetSettingsByFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};

export type FieldKeyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FieldKeySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FieldKeyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FieldKeyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FieldKeyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FieldKeyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FieldKeyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FieldKeyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FieldKeyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FieldKeyVariancePopulationAggregates>;
};

export type FieldKeyAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FieldKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FieldKeyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `label` field. */
  label?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `public` field. */
  public?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<FieldType>;
  /** Checks for equality with the object’s `options` field. */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `key` field. */
  key?: Maybe<Scalars['String']>;
};

export type FieldKeyDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of public across the matching connection */
  public?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of options across the matching connection */
  options?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
};

/** The fields on `fieldKey` to look up the row to connect. */
export type FieldKeyFieldKeyKeyKeyConnect = {
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to delete. */
export type FieldKeyFieldKeyKeyKeyDelete = {
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to connect. */
export type FieldKeyFieldKeyPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `fieldKey` to look up the row to delete. */
export type FieldKeyFieldKeyPkeyDelete = {
  id: Scalars['Int'];
};

/** A filter to be used against `FieldKey` object types. All fields are combined with a logical ‘and.’ */
export type FieldKeyFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label?: Maybe<StringFilter>;
  /** Filter by the object’s `public` field. */
  public?: Maybe<BooleanFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<FieldTypeFilter>;
  /** Filter by the object’s `options` field. */
  options?: Maybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `key` field. */
  key?: Maybe<StringFilter>;
  /** Filter by the object’s `widgetSettingsByFieldKey` relation. */
  widgetSettingsByFieldKey?: Maybe<FieldKeyToManyWidgetSettingFilter>;
  /** Some related `widgetSettingsByFieldKey` exist. */
  widgetSettingsByFieldKeyExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<FieldKeyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<FieldKeyFilter>>;
  /** Negates the expression. */
  not?: Maybe<FieldKeyFilter>;
};

/** Grouping methods for `FieldKey` for usage during aggregation. */
export enum FieldKeyGroupBy {
  Label = 'LABEL',
  Public = 'PUBLIC',
  Type = 'TYPE',
  Options = 'OPTIONS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type FieldKeyHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `FieldKey` aggregates. */
export type FieldKeyHavingInput = {
  AND?: Maybe<Array<FieldKeyHavingInput>>;
  OR?: Maybe<Array<FieldKeyHavingInput>>;
  sum?: Maybe<FieldKeyHavingSumInput>;
  distinctCount?: Maybe<FieldKeyHavingDistinctCountInput>;
  min?: Maybe<FieldKeyHavingMinInput>;
  max?: Maybe<FieldKeyHavingMaxInput>;
  average?: Maybe<FieldKeyHavingAverageInput>;
  stddevSample?: Maybe<FieldKeyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<FieldKeyHavingStddevPopulationInput>;
  varianceSample?: Maybe<FieldKeyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<FieldKeyHavingVariancePopulationInput>;
};

export type FieldKeyHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type FieldKeyHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `FieldKey` */
export type FieldKeyInput = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key: Scalars['String'];
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

export type FieldKeyMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type FieldKeyMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldKeyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldKeyNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** The fields on `fieldKey` to look up the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyKeyKeyUpdate = {
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  key: Scalars['String'];
};

/** The fields on `fieldKey` to look up the row to update. */
export type FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `FieldKey`. Fields that are set will be updated. */
export type FieldKeyPatch = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type?: Maybe<FieldType>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

export type FieldKeyStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeyStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type FieldKeyToManyWidgetSettingFilter = {
  /** Every related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WidgetSettingFilter>;
  /** Some related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WidgetSettingFilter>;
  /** No related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WidgetSettingFilter>;
  /** Aggregates across related `WidgetSetting` match the filter criteria. */
  aggregates?: Maybe<WidgetSettingAggregatesFilter>;
};

export type FieldKeyType = {
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public: Scalars['Boolean'];
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type FieldKeyVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FieldKeyVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `WidgetSetting`, and the cursor to aid in pagination. */
  edges: Array<FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings: WidgetSettingsConnection;
};


/** A `Widget` edge in the connection, with data from `WidgetSetting`. */
export type FieldKeyWidgetsByWidgetSettingFieldKeyAndWidgetIdManyToManyEdgeWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};

/** A connection to a list of `FieldKey` values. */
export type FieldKeysConnection = {
  /** A list of `FieldKey` objects. */
  nodes: Array<FieldKey>;
  /** A list of edges which contains the `FieldKey` and cursor to aid in pagination. */
  edges: Array<FieldKeysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldKey` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldKeyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldKeyAggregates>>;
};


/** A connection to a list of `FieldKey` values. */
export type FieldKeysConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldKeyGroupBy>;
  having?: Maybe<FieldKeyHavingInput>;
};

/** A `FieldKey` edge in the connection. */
export type FieldKeysEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldKey` at the end of the edge. */
  node: FieldKey;
};

/** Methods to use when ordering `FieldKey`. */
export enum FieldKeysOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  PublicAsc = 'PUBLIC_ASC',
  PublicDesc = 'PUBLIC_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  OptionsAsc = 'OPTIONS_ASC',
  OptionsDesc = 'OPTIONS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WidgetSettingsByFieldKeyCountAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_COUNT_ASC',
  WidgetSettingsByFieldKeyCountDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_COUNT_DESC',
  WidgetSettingsByFieldKeySumIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_ID_ASC',
  WidgetSettingsByFieldKeySumIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_ID_DESC',
  WidgetSettingsByFieldKeySumWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeySumWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeySumTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_TOPIC_ASC',
  WidgetSettingsByFieldKeySumTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_TOPIC_DESC',
  WidgetSettingsByFieldKeySumFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeySumFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeySumValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_VALUE_ASC',
  WidgetSettingsByFieldKeySumValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_VALUE_DESC',
  WidgetSettingsByFieldKeySumCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_CREATED_AT_ASC',
  WidgetSettingsByFieldKeySumCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_CREATED_AT_DESC',
  WidgetSettingsByFieldKeySumUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeySumUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_SUM_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyDistinctCountIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_ID_ASC',
  WidgetSettingsByFieldKeyDistinctCountIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_ID_DESC',
  WidgetSettingsByFieldKeyDistinctCountWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyDistinctCountWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyDistinctCountTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_TOPIC_ASC',
  WidgetSettingsByFieldKeyDistinctCountTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_TOPIC_DESC',
  WidgetSettingsByFieldKeyDistinctCountFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyDistinctCountFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyDistinctCountValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_VALUE_ASC',
  WidgetSettingsByFieldKeyDistinctCountValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_VALUE_DESC',
  WidgetSettingsByFieldKeyDistinctCountCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyDistinctCountCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyDistinctCountUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyDistinctCountUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_DISTINCT_COUNT_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyMinIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_ID_ASC',
  WidgetSettingsByFieldKeyMinIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_ID_DESC',
  WidgetSettingsByFieldKeyMinWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyMinWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyMinTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_TOPIC_ASC',
  WidgetSettingsByFieldKeyMinTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_TOPIC_DESC',
  WidgetSettingsByFieldKeyMinFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyMinFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyMinValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_VALUE_ASC',
  WidgetSettingsByFieldKeyMinValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_VALUE_DESC',
  WidgetSettingsByFieldKeyMinCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyMinCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyMinUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyMinUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MIN_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyMaxIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_ID_ASC',
  WidgetSettingsByFieldKeyMaxIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_ID_DESC',
  WidgetSettingsByFieldKeyMaxWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyMaxWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyMaxTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_TOPIC_ASC',
  WidgetSettingsByFieldKeyMaxTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_TOPIC_DESC',
  WidgetSettingsByFieldKeyMaxFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyMaxFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyMaxValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_VALUE_ASC',
  WidgetSettingsByFieldKeyMaxValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_VALUE_DESC',
  WidgetSettingsByFieldKeyMaxCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyMaxCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyMaxUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyMaxUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_MAX_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyAverageIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_ID_ASC',
  WidgetSettingsByFieldKeyAverageIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_ID_DESC',
  WidgetSettingsByFieldKeyAverageWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyAverageWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyAverageTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_TOPIC_ASC',
  WidgetSettingsByFieldKeyAverageTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_TOPIC_DESC',
  WidgetSettingsByFieldKeyAverageFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyAverageFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyAverageValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_VALUE_ASC',
  WidgetSettingsByFieldKeyAverageValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_VALUE_DESC',
  WidgetSettingsByFieldKeyAverageCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyAverageCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyAverageUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyAverageUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_AVERAGE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevSampleIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_ID_ASC',
  WidgetSettingsByFieldKeyStddevSampleIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_ID_DESC',
  WidgetSettingsByFieldKeyStddevSampleWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyStddevSampleWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyStddevSampleTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_TOPIC_ASC',
  WidgetSettingsByFieldKeyStddevSampleTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_TOPIC_DESC',
  WidgetSettingsByFieldKeyStddevSampleFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyStddevSampleFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyStddevSampleValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_VALUE_ASC',
  WidgetSettingsByFieldKeyStddevSampleValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_VALUE_DESC',
  WidgetSettingsByFieldKeyStddevSampleCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevSampleCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevSampleUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevSampleUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevPopulationIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_ID_ASC',
  WidgetSettingsByFieldKeyStddevPopulationIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_ID_DESC',
  WidgetSettingsByFieldKeyStddevPopulationWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyStddevPopulationWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyStddevPopulationTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_TOPIC_ASC',
  WidgetSettingsByFieldKeyStddevPopulationTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_TOPIC_DESC',
  WidgetSettingsByFieldKeyStddevPopulationFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyStddevPopulationFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyStddevPopulationValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_VALUE_ASC',
  WidgetSettingsByFieldKeyStddevPopulationValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_VALUE_DESC',
  WidgetSettingsByFieldKeyStddevPopulationCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevPopulationCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyStddevPopulationUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyStddevPopulationUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_STDDEV_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyVarianceSampleIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_ID_ASC',
  WidgetSettingsByFieldKeyVarianceSampleIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_ID_DESC',
  WidgetSettingsByFieldKeyVarianceSampleWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyVarianceSampleWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyVarianceSampleTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_TOPIC_ASC',
  WidgetSettingsByFieldKeyVarianceSampleTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_TOPIC_DESC',
  WidgetSettingsByFieldKeyVarianceSampleFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyVarianceSampleFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyVarianceSampleValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_VALUE_ASC',
  WidgetSettingsByFieldKeyVarianceSampleValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_VALUE_DESC',
  WidgetSettingsByFieldKeyVarianceSampleCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyVarianceSampleCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyVarianceSampleUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyVarianceSampleUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsByFieldKeyVariancePopulationIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_ID_ASC',
  WidgetSettingsByFieldKeyVariancePopulationIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_ID_DESC',
  WidgetSettingsByFieldKeyVariancePopulationWidgetIdAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsByFieldKeyVariancePopulationWidgetIdDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsByFieldKeyVariancePopulationTopicAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_TOPIC_ASC',
  WidgetSettingsByFieldKeyVariancePopulationTopicDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_TOPIC_DESC',
  WidgetSettingsByFieldKeyVariancePopulationFieldKeyAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsByFieldKeyVariancePopulationFieldKeyDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsByFieldKeyVariancePopulationValueAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_VALUE_ASC',
  WidgetSettingsByFieldKeyVariancePopulationValueDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_VALUE_DESC',
  WidgetSettingsByFieldKeyVariancePopulationCreatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_CREATED_AT_ASC',
  WidgetSettingsByFieldKeyVariancePopulationCreatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_CREATED_AT_DESC',
  WidgetSettingsByFieldKeyVariancePopulationUpdatedAtAsc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsByFieldKeyVariancePopulationUpdatedAtDesc = 'WIDGET_SETTINGS_BY_FIELD_KEY_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type FieldMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  config?: Maybe<IntFilter>;
};

export type FieldMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Maximum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Maximum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Maximum of config across the matching connection */
  config?: Maybe<Scalars['Int']>;
};

export type FieldMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  companyId?: Maybe<IntFilter>;
  companyProductId?: Maybe<IntFilter>;
  companyChannelId?: Maybe<IntFilter>;
  companyIntegrationId?: Maybe<IntFilter>;
  config?: Maybe<IntFilter>;
};

export type FieldMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['Int']>;
  /** Minimum of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['Int']>;
  /** Minimum of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['Int']>;
  /** Minimum of config across the matching connection */
  config?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FieldNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `field` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FieldNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `field` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyChannel` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyChannelIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyIntegrationIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldCompanyProductIdFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type FieldOnFieldForFieldConfigFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldPkeyUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueChannelUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueIntegrationUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** The fields on `field` to look up the row to update. */
export type FieldOnFieldForFieldConfigFkeyUsingFieldUniqueProductUpdate = {
  /** An object where the defined keys will be set on the `field` being updated. */
  patch: UpdateFieldOnFieldForFieldConfigFkeyPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** Represents an update to a `Field`. Fields that are set will be updated. */
export type FieldPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

export type FieldStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  companyProductId?: Maybe<BigIntFilter>;
  companyChannelId?: Maybe<BigIntFilter>;
  companyIntegrationId?: Maybe<BigIntFilter>;
  config?: Maybe<BigIntFilter>;
};

export type FieldSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of companyProductId across the matching connection */
  companyProductId: Scalars['BigInt'];
  /** Sum of companyChannelId across the matching connection */
  companyChannelId: Scalars['BigInt'];
  /** Sum of companyIntegrationId across the matching connection */
  companyIntegrationId: Scalars['BigInt'];
  /** Sum of config across the matching connection */
  config: Scalars['BigInt'];
};

export enum FieldType {
  Text = 'TEXT',
  Option = 'OPTION'
}

/** A filter to be used against FieldType fields. All fields are combined with a logical ‘and.’ */
export type FieldTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<FieldType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<FieldType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<FieldType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<FieldType>;
  /** Included in the specified list. */
  in?: Maybe<Array<FieldType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<FieldType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<FieldType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<FieldType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<FieldType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<FieldType>;
};

export type FieldVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

export type FieldVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  companyId?: Maybe<BigFloatFilter>;
  companyProductId?: Maybe<BigFloatFilter>;
  companyChannelId?: Maybe<BigFloatFilter>;
  companyIntegrationId?: Maybe<BigFloatFilter>;
  config?: Maybe<BigFloatFilter>;
};

export type FieldVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyProductId across the matching connection */
  companyProductId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyChannelId across the matching connection */
  companyChannelId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of companyIntegrationId across the matching connection */
  companyIntegrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of config across the matching connection */
  config?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Field` values. */
export type FieldsConnection = {
  /** A list of `Field` objects. */
  nodes: Array<Field>;
  /** A list of edges which contains the `Field` and cursor to aid in pagination. */
  edges: Array<FieldsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Field` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldAggregates>>;
};


/** A connection to a list of `Field` values. */
export type FieldsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldGroupBy>;
  having?: Maybe<FieldHavingInput>;
};

/** A `Field` edge in the connection. */
export type FieldsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Field` at the end of the edge. */
  node: Field;
};

/** Methods to use when ordering `Field`. */
export enum FieldsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyProductIdAsc = 'COMPANY_PRODUCT_ID_ASC',
  CompanyProductIdDesc = 'COMPANY_PRODUCT_ID_DESC',
  CompanyChannelIdAsc = 'COMPANY_CHANNEL_ID_ASC',
  CompanyChannelIdDesc = 'COMPANY_CHANNEL_ID_DESC',
  CompanyIntegrationIdAsc = 'COMPANY_INTEGRATION_ID_ASC',
  CompanyIntegrationIdDesc = 'COMPANY_INTEGRATION_ID_DESC',
  ConfigAsc = 'CONFIG_ASC',
  ConfigDesc = 'CONFIG_DESC',
  TopicAsc = 'TOPIC_ASC',
  TopicDesc = 'TOPIC_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FixAssetInput = {
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
};

/** The `companyAnalytic` to be created by this mutation. */
export type FkCompanyAnalyticsCompanyIdCompanyAnalyticsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  type: AnalyticsType;
  analyticsId: Scalars['String'];
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

/** The `company` to be created by this mutation. */
export type FkCompanyAnalyticsCompanyIdCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `CompanyAnalyticInput` mutation. */
export type FkCompanyAnalyticsCompanyIdInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<FkCompanyAnalyticsCompanyIdCompanyCreateInput>;
};

/** Input for the nested mutation of `companyAnalytic` in the `CompanyInput` mutation. */
export type FkCompanyAnalyticsCompanyIdInverseInput = {
  /** Flag indicating whether all other `companyAnalytic` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  connectById?: Maybe<Array<CompanyAnalyticCompanyAnalyticsPkeyConnect>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<CompanyAnalyticNodeIdConnect>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  deleteById?: Maybe<Array<CompanyAnalyticCompanyAnalyticsPkeyDelete>>;
  /** The primary key(s) for `companyAnalytic` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<CompanyAnalyticNodeIdDelete>>;
  /** The primary key(s) and patch data for `companyAnalytic` for the far side of the relationship. */
  updateById?: Maybe<Array<CompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdUsingCompanyAnalyticsPkeyUpdate>>;
  /** The primary key(s) and patch data for `companyAnalytic` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdNodeIdUpdate>>;
  /** A `CompanyAnalyticInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FkCompanyAnalyticsCompanyIdCompanyAnalyticsCreateInput>>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Float']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Float']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Float']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
};


/** A filter to be used against FullText fields. All fields are combined with a logical ‘and.’ */
export type FullTextFilter = {
  /** Performs a full text search on the field. */
  matches?: Maybe<Scalars['String']>;
};

/** The return type of our `getCompanyIdsByCognitoGroups` query. */
export type GetCompanyIdsByCognitoGroupsRecord = {
  administeredCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  normalCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** The return type of our `getCompanyIdsForUser` query. */
export type GetCompanyIdsForUserRecord = {
  administeredCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  normalCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GetCreditAppCreditorsResponse = {
  creditorId?: Maybe<Scalars['String']>;
  creditorName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type GiveUserAccessInput = {
  username: Scalars['String'];
  groupId: Scalars['Int'];
  makeGroupAdmin?: Maybe<Scalars['Boolean']>;
};

export type Group = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type: GroupType;
  distributionType: GroupDistType;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Group`. */
  companyByPrimaryGroup?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets: CompanyWidgetsConnection;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups: CompanyGroupsConnection;
  /** Reads and enables pagination through a set of `CtaAsset`. */
  ctaAssets: CtaAssetsConnection;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
  /** Reads and enables pagination through a set of `DeliveryMethod`. */
  deliveryMethods: DeliveryMethodsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companies: CompaniesConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets: WidgetsConnection;
  /** Reads and enables pagination through a set of `Widget`. */
  widgetsByCompanyWidgetGroupIdAndWidgetId: GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Cta`. */
  ctasByCompanyCtaGroupIdAndCtaId: GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnection;
};


export type GroupCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


export type GroupCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


export type GroupCtaAssetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaAssetsOrderBy>>;
  condition?: Maybe<CtaAssetCondition>;
  filter?: Maybe<CtaAssetFilter>;
};


export type GroupCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


export type GroupDeliveryMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
  condition?: Maybe<DeliveryMethodCondition>;
  filter?: Maybe<DeliveryMethodFilter>;
};


export type GroupCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


export type GroupWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<WidgetFilter>;
};


export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};


export type GroupCtasByCompanyCtaGroupIdAndCtaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};

export type GroupAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<GroupSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GroupDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<GroupMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<GroupMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<GroupAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<GroupStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<GroupStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<GroupVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<GroupVariancePopulationAggregates>;
};

export type GroupAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoName` field. */
  cognitoName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<GroupType>;
  /** Checks for equality with the object’s `distributionType` field. */
  distributionType?: Maybe<GroupDistType>;
  /** Checks for equality with the object’s `legalDesc` field. */
  legalDesc?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoGroup` field. */
  cognitoGroup?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cognitoAdminGroup` field. */
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Cta` values, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta`, info from the `CompanyCta`, and the cursor to aid in pagination. */
  edges: Array<GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas: CompanyCtasConnection;
};


/** A `Cta` edge in the connection, with data from `CompanyCta`. */
export type GroupCtasByCompanyCtaGroupIdAndCtaIdManyToManyEdgeCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};

export type GroupDetailsInput = {
  distributionType: GroupDistType;
  groupType: GroupType;
};

export enum GroupDistType {
  Direct = 'DIRECT',
  Geo = 'GEO',
  User = 'USER'
}

/** A filter to be used against GroupDistType fields. All fields are combined with a logical ‘and.’ */
export type GroupDistTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<GroupDistType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<GroupDistType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<GroupDistType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<GroupDistType>;
  /** Included in the specified list. */
  in?: Maybe<Array<GroupDistType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<GroupDistType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<GroupDistType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<GroupDistType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<GroupDistType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<GroupDistType>;
};

export type GroupDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoName across the matching connection */
  cognitoName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of distributionType across the matching connection */
  distributionType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of legalDesc across the matching connection */
  legalDesc?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoGroup across the matching connection */
  cognitoGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cognitoAdminGroup across the matching connection */
  cognitoAdminGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoName` field. */
  cognitoName?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<GroupTypeFilter>;
  /** Filter by the object’s `distributionType` field. */
  distributionType?: Maybe<GroupDistTypeFilter>;
  /** Filter by the object’s `legalDesc` field. */
  legalDesc?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoGroup` field. */
  cognitoGroup?: Maybe<StringFilter>;
  /** Filter by the object’s `cognitoAdminGroup` field. */
  cognitoAdminGroup?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyByPrimaryGroup` relation. */
  companyByPrimaryGroup?: Maybe<CompanyFilter>;
  /** A related `companyByPrimaryGroup` exists. */
  companyByPrimaryGroupExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyWidgets` relation. */
  companyWidgets?: Maybe<GroupToManyCompanyWidgetFilter>;
  /** Some related `companyWidgets` exist. */
  companyWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyGroups` relation. */
  companyGroups?: Maybe<GroupToManyCompanyGroupFilter>;
  /** Some related `companyGroups` exist. */
  companyGroupsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaAssets` relation. */
  ctaAssets?: Maybe<GroupToManyCtaAssetFilter>;
  /** Some related `ctaAssets` exist. */
  ctaAssetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `companyCtas` relation. */
  companyCtas?: Maybe<GroupToManyCompanyCtaFilter>;
  /** Some related `companyCtas` exist. */
  companyCtasExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `deliveryMethods` relation. */
  deliveryMethods?: Maybe<GroupToManyDeliveryMethodFilter>;
  /** Some related `deliveryMethods` exist. */
  deliveryMethodsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GroupFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GroupFilter>>;
  /** Negates the expression. */
  not?: Maybe<GroupFilter>;
};

/** Grouping methods for `Group` for usage during aggregation. */
export enum GroupGroupBy {
  Name = 'NAME',
  Type = 'TYPE',
  DistributionType = 'DISTRIBUTION_TYPE',
  LegalDesc = 'LEGAL_DESC',
  CognitoGroup = 'COGNITO_GROUP',
  CognitoAdminGroup = 'COGNITO_ADMIN_GROUP',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

/** The fields on `group` to look up the row to connect. */
export type GroupGroupCognitoNameKeyConnect = {
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to connect. */
export type GroupGroupPkeyConnect = {
  id: Scalars['Int'];
};

export type GroupHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Group` aggregates. */
export type GroupHavingInput = {
  AND?: Maybe<Array<GroupHavingInput>>;
  OR?: Maybe<Array<GroupHavingInput>>;
  sum?: Maybe<GroupHavingSumInput>;
  distinctCount?: Maybe<GroupHavingDistinctCountInput>;
  min?: Maybe<GroupHavingMinInput>;
  max?: Maybe<GroupHavingMaxInput>;
  average?: Maybe<GroupHavingAverageInput>;
  stddevSample?: Maybe<GroupHavingStddevSampleInput>;
  stddevPopulation?: Maybe<GroupHavingStddevPopulationInput>;
  varianceSample?: Maybe<GroupHavingVarianceSampleInput>;
  variancePopulation?: Maybe<GroupHavingVariancePopulationInput>;
};

export type GroupHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type GroupHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Group` */
export type GroupInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cognitoName: Scalars['String'];
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

export type GroupMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type GroupMembership = {
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  users: Array<UserDetails>;
};

export type GroupMemberships = {
  groups: Array<GroupMembership>;
};

export type GroupMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyCta` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyCtaForCompanyCtasGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyForCompanyPrimaryGroupFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyGroupForCompanyGroupGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCompanyWidgetForCompanyWidgetsGroupIdFkUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaAsset` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaAsset` being updated. */
  patch: CtaAssetPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnCtaAssetForCtaAssetsGroupIdFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `deliveryMethod` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `deliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupCognitoNameKeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  cognitoName: Scalars['String'];
};

/** The fields on `group` to look up the row to update. */
export type GroupOnDeliveryMethodForDeliveryMethodGroupIdFkUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Group`. Fields that are set will be updated. */
export type GroupPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

export type GroupStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyCta` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyCtaFilter = {
  /** Every related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyCtaFilter>;
  /** Some related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyCtaFilter>;
  /** No related `CompanyCta` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyCtaFilter>;
  /** Aggregates across related `CompanyCta` match the filter criteria. */
  aggregates?: Maybe<CompanyCtaAggregatesFilter>;
};

/** A filter to be used against many `CompanyGroup` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyGroupFilter = {
  /** Every related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyGroupFilter>;
  /** Some related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyGroupFilter>;
  /** No related `CompanyGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyGroupFilter>;
  /** Aggregates across related `CompanyGroup` match the filter criteria. */
  aggregates?: Maybe<CompanyGroupAggregatesFilter>;
};

/** A filter to be used against many `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCompanyWidgetFilter = {
  /** Every related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyWidgetFilter>;
  /** Some related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyWidgetFilter>;
  /** No related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyWidgetFilter>;
  /** Aggregates across related `CompanyWidget` match the filter criteria. */
  aggregates?: Maybe<CompanyWidgetAggregatesFilter>;
};

/** A filter to be used against many `CtaAsset` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyCtaAssetFilter = {
  /** Every related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaAssetFilter>;
  /** Some related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaAssetFilter>;
  /** No related `CtaAsset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaAssetFilter>;
  /** Aggregates across related `CtaAsset` match the filter criteria. */
  aggregates?: Maybe<CtaAssetAggregatesFilter>;
};

/** A filter to be used against many `DeliveryMethod` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyDeliveryMethodFilter = {
  /** Every related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<DeliveryMethodFilter>;
  /** Some related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<DeliveryMethodFilter>;
  /** No related `DeliveryMethod` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<DeliveryMethodFilter>;
  /** Aggregates across related `DeliveryMethod` match the filter criteria. */
  aggregates?: Maybe<DeliveryMethodAggregatesFilter>;
};

export enum GroupType {
  Dealer = 'DEALER',
  Super = 'SUPER'
}

/** A filter to be used against GroupType fields. All fields are combined with a logical ‘and.’ */
export type GroupTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<GroupType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<GroupType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<GroupType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<GroupType>;
  /** Included in the specified list. */
  in?: Maybe<Array<GroupType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<GroupType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<GroupType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<GroupType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<GroupType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<GroupType>;
};

export type GroupVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GroupVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Widget` values, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget`, info from the `CompanyWidget`, and the cursor to aid in pagination. */
  edges: Array<GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection, with data from `CompanyWidget`. */
export type GroupWidgetsByCompanyWidgetGroupIdAndWidgetIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
  specOverride?: Maybe<Scalars['JSON']>;
};

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values. */
export type GroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CognitoNameAsc = 'COGNITO_NAME_ASC',
  CognitoNameDesc = 'COGNITO_NAME_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  DistributionTypeAsc = 'DISTRIBUTION_TYPE_ASC',
  DistributionTypeDesc = 'DISTRIBUTION_TYPE_DESC',
  LegalDescAsc = 'LEGAL_DESC_ASC',
  LegalDescDesc = 'LEGAL_DESC_DESC',
  CognitoGroupAsc = 'COGNITO_GROUP_ASC',
  CognitoGroupDesc = 'COGNITO_GROUP_DESC',
  CognitoAdminGroupAsc = 'COGNITO_ADMIN_GROUP_ASC',
  CognitoAdminGroupDesc = 'COGNITO_ADMIN_GROUP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyWidgetsCountAsc = 'COMPANY_WIDGETS_COUNT_ASC',
  CompanyWidgetsCountDesc = 'COMPANY_WIDGETS_COUNT_DESC',
  CompanyWidgetsSumGroupIdAsc = 'COMPANY_WIDGETS_SUM_GROUP_ID_ASC',
  CompanyWidgetsSumGroupIdDesc = 'COMPANY_WIDGETS_SUM_GROUP_ID_DESC',
  CompanyWidgetsSumWidgetIdAsc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_ASC',
  CompanyWidgetsSumWidgetIdDesc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_DESC',
  CompanyWidgetsSumSpecOverrideAsc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_ASC',
  CompanyWidgetsSumSpecOverrideDesc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_DESC',
  CompanyWidgetsDistinctCountGroupIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyWidgetsDistinctCountGroupIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyWidgetsDistinctCountWidgetIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyWidgetsDistinctCountWidgetIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyWidgetsDistinctCountSpecOverrideAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_ASC',
  CompanyWidgetsDistinctCountSpecOverrideDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMinGroupIdAsc = 'COMPANY_WIDGETS_MIN_GROUP_ID_ASC',
  CompanyWidgetsMinGroupIdDesc = 'COMPANY_WIDGETS_MIN_GROUP_ID_DESC',
  CompanyWidgetsMinWidgetIdAsc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_ASC',
  CompanyWidgetsMinWidgetIdDesc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_DESC',
  CompanyWidgetsMinSpecOverrideAsc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMinSpecOverrideDesc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMaxGroupIdAsc = 'COMPANY_WIDGETS_MAX_GROUP_ID_ASC',
  CompanyWidgetsMaxGroupIdDesc = 'COMPANY_WIDGETS_MAX_GROUP_ID_DESC',
  CompanyWidgetsMaxWidgetIdAsc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_ASC',
  CompanyWidgetsMaxWidgetIdDesc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_DESC',
  CompanyWidgetsMaxSpecOverrideAsc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMaxSpecOverrideDesc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_DESC',
  CompanyWidgetsAverageGroupIdAsc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_ASC',
  CompanyWidgetsAverageGroupIdDesc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_DESC',
  CompanyWidgetsAverageWidgetIdAsc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CompanyWidgetsAverageWidgetIdDesc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CompanyWidgetsAverageSpecOverrideAsc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsAverageSpecOverrideDesc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevSampleGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsStddevSampleGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsStddevSampleWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsStddevSampleWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsStddevSampleSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevSampleSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevPopulationGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsStddevPopulationGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsStddevPopulationWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsStddevPopulationWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsStddevPopulationSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevPopulationSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVarianceSampleGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsVarianceSampleGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsVarianceSampleWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsVarianceSampleWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsVarianceSampleSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVarianceSampleSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVariancePopulationGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsVariancePopulationGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsVariancePopulationWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsVariancePopulationWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsVariancePopulationSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVariancePopulationSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyGroupsCountAsc = 'COMPANY_GROUPS_COUNT_ASC',
  CompanyGroupsCountDesc = 'COMPANY_GROUPS_COUNT_DESC',
  CompanyGroupsSumCompanyIdAsc = 'COMPANY_GROUPS_SUM_COMPANY_ID_ASC',
  CompanyGroupsSumCompanyIdDesc = 'COMPANY_GROUPS_SUM_COMPANY_ID_DESC',
  CompanyGroupsSumGroupIdAsc = 'COMPANY_GROUPS_SUM_GROUP_ID_ASC',
  CompanyGroupsSumGroupIdDesc = 'COMPANY_GROUPS_SUM_GROUP_ID_DESC',
  CompanyGroupsSumCreatedAtAsc = 'COMPANY_GROUPS_SUM_CREATED_AT_ASC',
  CompanyGroupsSumCreatedAtDesc = 'COMPANY_GROUPS_SUM_CREATED_AT_DESC',
  CompanyGroupsSumUpdatedAtAsc = 'COMPANY_GROUPS_SUM_UPDATED_AT_ASC',
  CompanyGroupsSumUpdatedAtDesc = 'COMPANY_GROUPS_SUM_UPDATED_AT_DESC',
  CompanyGroupsDistinctCountCompanyIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyGroupsDistinctCountCompanyIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyGroupsDistinctCountGroupIdAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyGroupsDistinctCountGroupIdDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyGroupsDistinctCountCreatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyGroupsDistinctCountCreatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyGroupsDistinctCountUpdatedAtAsc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyGroupsDistinctCountUpdatedAtDesc = 'COMPANY_GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyGroupsMinCompanyIdAsc = 'COMPANY_GROUPS_MIN_COMPANY_ID_ASC',
  CompanyGroupsMinCompanyIdDesc = 'COMPANY_GROUPS_MIN_COMPANY_ID_DESC',
  CompanyGroupsMinGroupIdAsc = 'COMPANY_GROUPS_MIN_GROUP_ID_ASC',
  CompanyGroupsMinGroupIdDesc = 'COMPANY_GROUPS_MIN_GROUP_ID_DESC',
  CompanyGroupsMinCreatedAtAsc = 'COMPANY_GROUPS_MIN_CREATED_AT_ASC',
  CompanyGroupsMinCreatedAtDesc = 'COMPANY_GROUPS_MIN_CREATED_AT_DESC',
  CompanyGroupsMinUpdatedAtAsc = 'COMPANY_GROUPS_MIN_UPDATED_AT_ASC',
  CompanyGroupsMinUpdatedAtDesc = 'COMPANY_GROUPS_MIN_UPDATED_AT_DESC',
  CompanyGroupsMaxCompanyIdAsc = 'COMPANY_GROUPS_MAX_COMPANY_ID_ASC',
  CompanyGroupsMaxCompanyIdDesc = 'COMPANY_GROUPS_MAX_COMPANY_ID_DESC',
  CompanyGroupsMaxGroupIdAsc = 'COMPANY_GROUPS_MAX_GROUP_ID_ASC',
  CompanyGroupsMaxGroupIdDesc = 'COMPANY_GROUPS_MAX_GROUP_ID_DESC',
  CompanyGroupsMaxCreatedAtAsc = 'COMPANY_GROUPS_MAX_CREATED_AT_ASC',
  CompanyGroupsMaxCreatedAtDesc = 'COMPANY_GROUPS_MAX_CREATED_AT_DESC',
  CompanyGroupsMaxUpdatedAtAsc = 'COMPANY_GROUPS_MAX_UPDATED_AT_ASC',
  CompanyGroupsMaxUpdatedAtDesc = 'COMPANY_GROUPS_MAX_UPDATED_AT_DESC',
  CompanyGroupsAverageCompanyIdAsc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_ASC',
  CompanyGroupsAverageCompanyIdDesc = 'COMPANY_GROUPS_AVERAGE_COMPANY_ID_DESC',
  CompanyGroupsAverageGroupIdAsc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_ASC',
  CompanyGroupsAverageGroupIdDesc = 'COMPANY_GROUPS_AVERAGE_GROUP_ID_DESC',
  CompanyGroupsAverageCreatedAtAsc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_ASC',
  CompanyGroupsAverageCreatedAtDesc = 'COMPANY_GROUPS_AVERAGE_CREATED_AT_DESC',
  CompanyGroupsAverageUpdatedAtAsc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_ASC',
  CompanyGroupsAverageUpdatedAtDesc = 'COMPANY_GROUPS_AVERAGE_UPDATED_AT_DESC',
  CompanyGroupsStddevSampleCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsStddevSampleCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsStddevSampleGroupIdAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsStddevSampleGroupIdDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsStddevSampleCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsStddevSampleCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsStddevSampleUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsStddevSampleUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsStddevPopulationCompanyIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsStddevPopulationCompanyIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsStddevPopulationGroupIdAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyGroupsStddevPopulationGroupIdDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyGroupsStddevPopulationCreatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyGroupsStddevPopulationCreatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyGroupsStddevPopulationUpdatedAtAsc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsStddevPopulationUpdatedAtDesc = 'COMPANY_GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyGroupsVarianceSampleCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyGroupsVarianceSampleCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyGroupsVarianceSampleGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyGroupsVarianceSampleGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyGroupsVarianceSampleCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyGroupsVarianceSampleCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyGroupsVarianceSampleUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyGroupsVarianceSampleUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyGroupsVariancePopulationCompanyIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyGroupsVariancePopulationCompanyIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyGroupsVariancePopulationGroupIdAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyGroupsVariancePopulationGroupIdDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyGroupsVariancePopulationCreatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyGroupsVariancePopulationCreatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyGroupsVariancePopulationUpdatedAtAsc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyGroupsVariancePopulationUpdatedAtDesc = 'COMPANY_GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CtaAssetsCountAsc = 'CTA_ASSETS_COUNT_ASC',
  CtaAssetsCountDesc = 'CTA_ASSETS_COUNT_DESC',
  CtaAssetsSumIdAsc = 'CTA_ASSETS_SUM_ID_ASC',
  CtaAssetsSumIdDesc = 'CTA_ASSETS_SUM_ID_DESC',
  CtaAssetsSumGroupIdAsc = 'CTA_ASSETS_SUM_GROUP_ID_ASC',
  CtaAssetsSumGroupIdDesc = 'CTA_ASSETS_SUM_GROUP_ID_DESC',
  CtaAssetsSumNameAsc = 'CTA_ASSETS_SUM_NAME_ASC',
  CtaAssetsSumNameDesc = 'CTA_ASSETS_SUM_NAME_DESC',
  CtaAssetsSumBucketAsc = 'CTA_ASSETS_SUM_BUCKET_ASC',
  CtaAssetsSumBucketDesc = 'CTA_ASSETS_SUM_BUCKET_DESC',
  CtaAssetsSumKeyAsc = 'CTA_ASSETS_SUM_KEY_ASC',
  CtaAssetsSumKeyDesc = 'CTA_ASSETS_SUM_KEY_DESC',
  CtaAssetsSumDescriptionAsc = 'CTA_ASSETS_SUM_DESCRIPTION_ASC',
  CtaAssetsSumDescriptionDesc = 'CTA_ASSETS_SUM_DESCRIPTION_DESC',
  CtaAssetsSumEmailAsc = 'CTA_ASSETS_SUM_EMAIL_ASC',
  CtaAssetsSumEmailDesc = 'CTA_ASSETS_SUM_EMAIL_DESC',
  CtaAssetsSumIpAddressAsc = 'CTA_ASSETS_SUM_IP_ADDRESS_ASC',
  CtaAssetsSumIpAddressDesc = 'CTA_ASSETS_SUM_IP_ADDRESS_DESC',
  CtaAssetsSumCreatedAtAsc = 'CTA_ASSETS_SUM_CREATED_AT_ASC',
  CtaAssetsSumCreatedAtDesc = 'CTA_ASSETS_SUM_CREATED_AT_DESC',
  CtaAssetsSumUpdatedAtAsc = 'CTA_ASSETS_SUM_UPDATED_AT_ASC',
  CtaAssetsSumUpdatedAtDesc = 'CTA_ASSETS_SUM_UPDATED_AT_DESC',
  CtaAssetsDistinctCountIdAsc = 'CTA_ASSETS_DISTINCT_COUNT_ID_ASC',
  CtaAssetsDistinctCountIdDesc = 'CTA_ASSETS_DISTINCT_COUNT_ID_DESC',
  CtaAssetsDistinctCountGroupIdAsc = 'CTA_ASSETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CtaAssetsDistinctCountGroupIdDesc = 'CTA_ASSETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CtaAssetsDistinctCountNameAsc = 'CTA_ASSETS_DISTINCT_COUNT_NAME_ASC',
  CtaAssetsDistinctCountNameDesc = 'CTA_ASSETS_DISTINCT_COUNT_NAME_DESC',
  CtaAssetsDistinctCountBucketAsc = 'CTA_ASSETS_DISTINCT_COUNT_BUCKET_ASC',
  CtaAssetsDistinctCountBucketDesc = 'CTA_ASSETS_DISTINCT_COUNT_BUCKET_DESC',
  CtaAssetsDistinctCountKeyAsc = 'CTA_ASSETS_DISTINCT_COUNT_KEY_ASC',
  CtaAssetsDistinctCountKeyDesc = 'CTA_ASSETS_DISTINCT_COUNT_KEY_DESC',
  CtaAssetsDistinctCountDescriptionAsc = 'CTA_ASSETS_DISTINCT_COUNT_DESCRIPTION_ASC',
  CtaAssetsDistinctCountDescriptionDesc = 'CTA_ASSETS_DISTINCT_COUNT_DESCRIPTION_DESC',
  CtaAssetsDistinctCountEmailAsc = 'CTA_ASSETS_DISTINCT_COUNT_EMAIL_ASC',
  CtaAssetsDistinctCountEmailDesc = 'CTA_ASSETS_DISTINCT_COUNT_EMAIL_DESC',
  CtaAssetsDistinctCountIpAddressAsc = 'CTA_ASSETS_DISTINCT_COUNT_IP_ADDRESS_ASC',
  CtaAssetsDistinctCountIpAddressDesc = 'CTA_ASSETS_DISTINCT_COUNT_IP_ADDRESS_DESC',
  CtaAssetsDistinctCountCreatedAtAsc = 'CTA_ASSETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaAssetsDistinctCountCreatedAtDesc = 'CTA_ASSETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaAssetsDistinctCountUpdatedAtAsc = 'CTA_ASSETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaAssetsDistinctCountUpdatedAtDesc = 'CTA_ASSETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaAssetsMinIdAsc = 'CTA_ASSETS_MIN_ID_ASC',
  CtaAssetsMinIdDesc = 'CTA_ASSETS_MIN_ID_DESC',
  CtaAssetsMinGroupIdAsc = 'CTA_ASSETS_MIN_GROUP_ID_ASC',
  CtaAssetsMinGroupIdDesc = 'CTA_ASSETS_MIN_GROUP_ID_DESC',
  CtaAssetsMinNameAsc = 'CTA_ASSETS_MIN_NAME_ASC',
  CtaAssetsMinNameDesc = 'CTA_ASSETS_MIN_NAME_DESC',
  CtaAssetsMinBucketAsc = 'CTA_ASSETS_MIN_BUCKET_ASC',
  CtaAssetsMinBucketDesc = 'CTA_ASSETS_MIN_BUCKET_DESC',
  CtaAssetsMinKeyAsc = 'CTA_ASSETS_MIN_KEY_ASC',
  CtaAssetsMinKeyDesc = 'CTA_ASSETS_MIN_KEY_DESC',
  CtaAssetsMinDescriptionAsc = 'CTA_ASSETS_MIN_DESCRIPTION_ASC',
  CtaAssetsMinDescriptionDesc = 'CTA_ASSETS_MIN_DESCRIPTION_DESC',
  CtaAssetsMinEmailAsc = 'CTA_ASSETS_MIN_EMAIL_ASC',
  CtaAssetsMinEmailDesc = 'CTA_ASSETS_MIN_EMAIL_DESC',
  CtaAssetsMinIpAddressAsc = 'CTA_ASSETS_MIN_IP_ADDRESS_ASC',
  CtaAssetsMinIpAddressDesc = 'CTA_ASSETS_MIN_IP_ADDRESS_DESC',
  CtaAssetsMinCreatedAtAsc = 'CTA_ASSETS_MIN_CREATED_AT_ASC',
  CtaAssetsMinCreatedAtDesc = 'CTA_ASSETS_MIN_CREATED_AT_DESC',
  CtaAssetsMinUpdatedAtAsc = 'CTA_ASSETS_MIN_UPDATED_AT_ASC',
  CtaAssetsMinUpdatedAtDesc = 'CTA_ASSETS_MIN_UPDATED_AT_DESC',
  CtaAssetsMaxIdAsc = 'CTA_ASSETS_MAX_ID_ASC',
  CtaAssetsMaxIdDesc = 'CTA_ASSETS_MAX_ID_DESC',
  CtaAssetsMaxGroupIdAsc = 'CTA_ASSETS_MAX_GROUP_ID_ASC',
  CtaAssetsMaxGroupIdDesc = 'CTA_ASSETS_MAX_GROUP_ID_DESC',
  CtaAssetsMaxNameAsc = 'CTA_ASSETS_MAX_NAME_ASC',
  CtaAssetsMaxNameDesc = 'CTA_ASSETS_MAX_NAME_DESC',
  CtaAssetsMaxBucketAsc = 'CTA_ASSETS_MAX_BUCKET_ASC',
  CtaAssetsMaxBucketDesc = 'CTA_ASSETS_MAX_BUCKET_DESC',
  CtaAssetsMaxKeyAsc = 'CTA_ASSETS_MAX_KEY_ASC',
  CtaAssetsMaxKeyDesc = 'CTA_ASSETS_MAX_KEY_DESC',
  CtaAssetsMaxDescriptionAsc = 'CTA_ASSETS_MAX_DESCRIPTION_ASC',
  CtaAssetsMaxDescriptionDesc = 'CTA_ASSETS_MAX_DESCRIPTION_DESC',
  CtaAssetsMaxEmailAsc = 'CTA_ASSETS_MAX_EMAIL_ASC',
  CtaAssetsMaxEmailDesc = 'CTA_ASSETS_MAX_EMAIL_DESC',
  CtaAssetsMaxIpAddressAsc = 'CTA_ASSETS_MAX_IP_ADDRESS_ASC',
  CtaAssetsMaxIpAddressDesc = 'CTA_ASSETS_MAX_IP_ADDRESS_DESC',
  CtaAssetsMaxCreatedAtAsc = 'CTA_ASSETS_MAX_CREATED_AT_ASC',
  CtaAssetsMaxCreatedAtDesc = 'CTA_ASSETS_MAX_CREATED_AT_DESC',
  CtaAssetsMaxUpdatedAtAsc = 'CTA_ASSETS_MAX_UPDATED_AT_ASC',
  CtaAssetsMaxUpdatedAtDesc = 'CTA_ASSETS_MAX_UPDATED_AT_DESC',
  CtaAssetsAverageIdAsc = 'CTA_ASSETS_AVERAGE_ID_ASC',
  CtaAssetsAverageIdDesc = 'CTA_ASSETS_AVERAGE_ID_DESC',
  CtaAssetsAverageGroupIdAsc = 'CTA_ASSETS_AVERAGE_GROUP_ID_ASC',
  CtaAssetsAverageGroupIdDesc = 'CTA_ASSETS_AVERAGE_GROUP_ID_DESC',
  CtaAssetsAverageNameAsc = 'CTA_ASSETS_AVERAGE_NAME_ASC',
  CtaAssetsAverageNameDesc = 'CTA_ASSETS_AVERAGE_NAME_DESC',
  CtaAssetsAverageBucketAsc = 'CTA_ASSETS_AVERAGE_BUCKET_ASC',
  CtaAssetsAverageBucketDesc = 'CTA_ASSETS_AVERAGE_BUCKET_DESC',
  CtaAssetsAverageKeyAsc = 'CTA_ASSETS_AVERAGE_KEY_ASC',
  CtaAssetsAverageKeyDesc = 'CTA_ASSETS_AVERAGE_KEY_DESC',
  CtaAssetsAverageDescriptionAsc = 'CTA_ASSETS_AVERAGE_DESCRIPTION_ASC',
  CtaAssetsAverageDescriptionDesc = 'CTA_ASSETS_AVERAGE_DESCRIPTION_DESC',
  CtaAssetsAverageEmailAsc = 'CTA_ASSETS_AVERAGE_EMAIL_ASC',
  CtaAssetsAverageEmailDesc = 'CTA_ASSETS_AVERAGE_EMAIL_DESC',
  CtaAssetsAverageIpAddressAsc = 'CTA_ASSETS_AVERAGE_IP_ADDRESS_ASC',
  CtaAssetsAverageIpAddressDesc = 'CTA_ASSETS_AVERAGE_IP_ADDRESS_DESC',
  CtaAssetsAverageCreatedAtAsc = 'CTA_ASSETS_AVERAGE_CREATED_AT_ASC',
  CtaAssetsAverageCreatedAtDesc = 'CTA_ASSETS_AVERAGE_CREATED_AT_DESC',
  CtaAssetsAverageUpdatedAtAsc = 'CTA_ASSETS_AVERAGE_UPDATED_AT_ASC',
  CtaAssetsAverageUpdatedAtDesc = 'CTA_ASSETS_AVERAGE_UPDATED_AT_DESC',
  CtaAssetsStddevSampleIdAsc = 'CTA_ASSETS_STDDEV_SAMPLE_ID_ASC',
  CtaAssetsStddevSampleIdDesc = 'CTA_ASSETS_STDDEV_SAMPLE_ID_DESC',
  CtaAssetsStddevSampleGroupIdAsc = 'CTA_ASSETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CtaAssetsStddevSampleGroupIdDesc = 'CTA_ASSETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CtaAssetsStddevSampleNameAsc = 'CTA_ASSETS_STDDEV_SAMPLE_NAME_ASC',
  CtaAssetsStddevSampleNameDesc = 'CTA_ASSETS_STDDEV_SAMPLE_NAME_DESC',
  CtaAssetsStddevSampleBucketAsc = 'CTA_ASSETS_STDDEV_SAMPLE_BUCKET_ASC',
  CtaAssetsStddevSampleBucketDesc = 'CTA_ASSETS_STDDEV_SAMPLE_BUCKET_DESC',
  CtaAssetsStddevSampleKeyAsc = 'CTA_ASSETS_STDDEV_SAMPLE_KEY_ASC',
  CtaAssetsStddevSampleKeyDesc = 'CTA_ASSETS_STDDEV_SAMPLE_KEY_DESC',
  CtaAssetsStddevSampleDescriptionAsc = 'CTA_ASSETS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CtaAssetsStddevSampleDescriptionDesc = 'CTA_ASSETS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CtaAssetsStddevSampleEmailAsc = 'CTA_ASSETS_STDDEV_SAMPLE_EMAIL_ASC',
  CtaAssetsStddevSampleEmailDesc = 'CTA_ASSETS_STDDEV_SAMPLE_EMAIL_DESC',
  CtaAssetsStddevSampleIpAddressAsc = 'CTA_ASSETS_STDDEV_SAMPLE_IP_ADDRESS_ASC',
  CtaAssetsStddevSampleIpAddressDesc = 'CTA_ASSETS_STDDEV_SAMPLE_IP_ADDRESS_DESC',
  CtaAssetsStddevSampleCreatedAtAsc = 'CTA_ASSETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaAssetsStddevSampleCreatedAtDesc = 'CTA_ASSETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaAssetsStddevSampleUpdatedAtAsc = 'CTA_ASSETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaAssetsStddevSampleUpdatedAtDesc = 'CTA_ASSETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaAssetsStddevPopulationIdAsc = 'CTA_ASSETS_STDDEV_POPULATION_ID_ASC',
  CtaAssetsStddevPopulationIdDesc = 'CTA_ASSETS_STDDEV_POPULATION_ID_DESC',
  CtaAssetsStddevPopulationGroupIdAsc = 'CTA_ASSETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CtaAssetsStddevPopulationGroupIdDesc = 'CTA_ASSETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CtaAssetsStddevPopulationNameAsc = 'CTA_ASSETS_STDDEV_POPULATION_NAME_ASC',
  CtaAssetsStddevPopulationNameDesc = 'CTA_ASSETS_STDDEV_POPULATION_NAME_DESC',
  CtaAssetsStddevPopulationBucketAsc = 'CTA_ASSETS_STDDEV_POPULATION_BUCKET_ASC',
  CtaAssetsStddevPopulationBucketDesc = 'CTA_ASSETS_STDDEV_POPULATION_BUCKET_DESC',
  CtaAssetsStddevPopulationKeyAsc = 'CTA_ASSETS_STDDEV_POPULATION_KEY_ASC',
  CtaAssetsStddevPopulationKeyDesc = 'CTA_ASSETS_STDDEV_POPULATION_KEY_DESC',
  CtaAssetsStddevPopulationDescriptionAsc = 'CTA_ASSETS_STDDEV_POPULATION_DESCRIPTION_ASC',
  CtaAssetsStddevPopulationDescriptionDesc = 'CTA_ASSETS_STDDEV_POPULATION_DESCRIPTION_DESC',
  CtaAssetsStddevPopulationEmailAsc = 'CTA_ASSETS_STDDEV_POPULATION_EMAIL_ASC',
  CtaAssetsStddevPopulationEmailDesc = 'CTA_ASSETS_STDDEV_POPULATION_EMAIL_DESC',
  CtaAssetsStddevPopulationIpAddressAsc = 'CTA_ASSETS_STDDEV_POPULATION_IP_ADDRESS_ASC',
  CtaAssetsStddevPopulationIpAddressDesc = 'CTA_ASSETS_STDDEV_POPULATION_IP_ADDRESS_DESC',
  CtaAssetsStddevPopulationCreatedAtAsc = 'CTA_ASSETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaAssetsStddevPopulationCreatedAtDesc = 'CTA_ASSETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaAssetsStddevPopulationUpdatedAtAsc = 'CTA_ASSETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaAssetsStddevPopulationUpdatedAtDesc = 'CTA_ASSETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaAssetsVarianceSampleIdAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_ID_ASC',
  CtaAssetsVarianceSampleIdDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_ID_DESC',
  CtaAssetsVarianceSampleGroupIdAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CtaAssetsVarianceSampleGroupIdDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CtaAssetsVarianceSampleNameAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_NAME_ASC',
  CtaAssetsVarianceSampleNameDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_NAME_DESC',
  CtaAssetsVarianceSampleBucketAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_BUCKET_ASC',
  CtaAssetsVarianceSampleBucketDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_BUCKET_DESC',
  CtaAssetsVarianceSampleKeyAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_KEY_ASC',
  CtaAssetsVarianceSampleKeyDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_KEY_DESC',
  CtaAssetsVarianceSampleDescriptionAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CtaAssetsVarianceSampleDescriptionDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CtaAssetsVarianceSampleEmailAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_EMAIL_ASC',
  CtaAssetsVarianceSampleEmailDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_EMAIL_DESC',
  CtaAssetsVarianceSampleIpAddressAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_IP_ADDRESS_ASC',
  CtaAssetsVarianceSampleIpAddressDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_IP_ADDRESS_DESC',
  CtaAssetsVarianceSampleCreatedAtAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaAssetsVarianceSampleCreatedAtDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaAssetsVarianceSampleUpdatedAtAsc = 'CTA_ASSETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaAssetsVarianceSampleUpdatedAtDesc = 'CTA_ASSETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaAssetsVariancePopulationIdAsc = 'CTA_ASSETS_VARIANCE_POPULATION_ID_ASC',
  CtaAssetsVariancePopulationIdDesc = 'CTA_ASSETS_VARIANCE_POPULATION_ID_DESC',
  CtaAssetsVariancePopulationGroupIdAsc = 'CTA_ASSETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CtaAssetsVariancePopulationGroupIdDesc = 'CTA_ASSETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CtaAssetsVariancePopulationNameAsc = 'CTA_ASSETS_VARIANCE_POPULATION_NAME_ASC',
  CtaAssetsVariancePopulationNameDesc = 'CTA_ASSETS_VARIANCE_POPULATION_NAME_DESC',
  CtaAssetsVariancePopulationBucketAsc = 'CTA_ASSETS_VARIANCE_POPULATION_BUCKET_ASC',
  CtaAssetsVariancePopulationBucketDesc = 'CTA_ASSETS_VARIANCE_POPULATION_BUCKET_DESC',
  CtaAssetsVariancePopulationKeyAsc = 'CTA_ASSETS_VARIANCE_POPULATION_KEY_ASC',
  CtaAssetsVariancePopulationKeyDesc = 'CTA_ASSETS_VARIANCE_POPULATION_KEY_DESC',
  CtaAssetsVariancePopulationDescriptionAsc = 'CTA_ASSETS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CtaAssetsVariancePopulationDescriptionDesc = 'CTA_ASSETS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CtaAssetsVariancePopulationEmailAsc = 'CTA_ASSETS_VARIANCE_POPULATION_EMAIL_ASC',
  CtaAssetsVariancePopulationEmailDesc = 'CTA_ASSETS_VARIANCE_POPULATION_EMAIL_DESC',
  CtaAssetsVariancePopulationIpAddressAsc = 'CTA_ASSETS_VARIANCE_POPULATION_IP_ADDRESS_ASC',
  CtaAssetsVariancePopulationIpAddressDesc = 'CTA_ASSETS_VARIANCE_POPULATION_IP_ADDRESS_DESC',
  CtaAssetsVariancePopulationCreatedAtAsc = 'CTA_ASSETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaAssetsVariancePopulationCreatedAtDesc = 'CTA_ASSETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaAssetsVariancePopulationUpdatedAtAsc = 'CTA_ASSETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaAssetsVariancePopulationUpdatedAtDesc = 'CTA_ASSETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasCountAsc = 'COMPANY_CTAS_COUNT_ASC',
  CompanyCtasCountDesc = 'COMPANY_CTAS_COUNT_DESC',
  CompanyCtasSumIdAsc = 'COMPANY_CTAS_SUM_ID_ASC',
  CompanyCtasSumIdDesc = 'COMPANY_CTAS_SUM_ID_DESC',
  CompanyCtasSumCtaIdAsc = 'COMPANY_CTAS_SUM_CTA_ID_ASC',
  CompanyCtasSumCtaIdDesc = 'COMPANY_CTAS_SUM_CTA_ID_DESC',
  CompanyCtasSumGroupIdAsc = 'COMPANY_CTAS_SUM_GROUP_ID_ASC',
  CompanyCtasSumGroupIdDesc = 'COMPANY_CTAS_SUM_GROUP_ID_DESC',
  CompanyCtasSumThemeAsc = 'COMPANY_CTAS_SUM_THEME_ASC',
  CompanyCtasSumThemeDesc = 'COMPANY_CTAS_SUM_THEME_DESC',
  CompanyCtasSumNameAsc = 'COMPANY_CTAS_SUM_NAME_ASC',
  CompanyCtasSumNameDesc = 'COMPANY_CTAS_SUM_NAME_DESC',
  CompanyCtasSumLockedAsc = 'COMPANY_CTAS_SUM_LOCKED_ASC',
  CompanyCtasSumLockedDesc = 'COMPANY_CTAS_SUM_LOCKED_DESC',
  CompanyCtasSumDefaultAsc = 'COMPANY_CTAS_SUM_DEFAULT_ASC',
  CompanyCtasSumDefaultDesc = 'COMPANY_CTAS_SUM_DEFAULT_DESC',
  CompanyCtasSumTemplateDefAsc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_ASC',
  CompanyCtasSumTemplateDefDesc = 'COMPANY_CTAS_SUM_TEMPLATE_DEF_DESC',
  CompanyCtasSumThemeDefAsc = 'COMPANY_CTAS_SUM_THEME_DEF_ASC',
  CompanyCtasSumThemeDefDesc = 'COMPANY_CTAS_SUM_THEME_DEF_DESC',
  CompanyCtasSumCreatedAtAsc = 'COMPANY_CTAS_SUM_CREATED_AT_ASC',
  CompanyCtasSumCreatedAtDesc = 'COMPANY_CTAS_SUM_CREATED_AT_DESC',
  CompanyCtasSumUpdatedAtAsc = 'COMPANY_CTAS_SUM_UPDATED_AT_ASC',
  CompanyCtasSumUpdatedAtDesc = 'COMPANY_CTAS_SUM_UPDATED_AT_DESC',
  CompanyCtasSumCompatibleMajorAsc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_ASC',
  CompanyCtasSumCompatibleMajorDesc = 'COMPANY_CTAS_SUM_COMPATIBLE_MAJOR_DESC',
  CompanyCtasSumCtaDetailsAsc = 'COMPANY_CTAS_SUM_CTA_DETAILS_ASC',
  CompanyCtasSumCtaDetailsDesc = 'COMPANY_CTAS_SUM_CTA_DETAILS_DESC',
  CompanyCtasDistinctCountIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_ASC',
  CompanyCtasDistinctCountIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_ID_DESC',
  CompanyCtasDistinctCountCtaIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_ASC',
  CompanyCtasDistinctCountCtaIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_ID_DESC',
  CompanyCtasDistinctCountGroupIdAsc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyCtasDistinctCountGroupIdDesc = 'COMPANY_CTAS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyCtasDistinctCountThemeAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_ASC',
  CompanyCtasDistinctCountThemeDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DESC',
  CompanyCtasDistinctCountNameAsc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_ASC',
  CompanyCtasDistinctCountNameDesc = 'COMPANY_CTAS_DISTINCT_COUNT_NAME_DESC',
  CompanyCtasDistinctCountLockedAsc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_ASC',
  CompanyCtasDistinctCountLockedDesc = 'COMPANY_CTAS_DISTINCT_COUNT_LOCKED_DESC',
  CompanyCtasDistinctCountDefaultAsc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_ASC',
  CompanyCtasDistinctCountDefaultDesc = 'COMPANY_CTAS_DISTINCT_COUNT_DEFAULT_DESC',
  CompanyCtasDistinctCountTemplateDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_ASC',
  CompanyCtasDistinctCountTemplateDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_TEMPLATE_DEF_DESC',
  CompanyCtasDistinctCountThemeDefAsc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_ASC',
  CompanyCtasDistinctCountThemeDefDesc = 'COMPANY_CTAS_DISTINCT_COUNT_THEME_DEF_DESC',
  CompanyCtasDistinctCountCreatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyCtasDistinctCountCreatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyCtasDistinctCountUpdatedAtAsc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyCtasDistinctCountUpdatedAtDesc = 'COMPANY_CTAS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyCtasDistinctCountCompatibleMajorAsc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_ASC',
  CompanyCtasDistinctCountCompatibleMajorDesc = 'COMPANY_CTAS_DISTINCT_COUNT_COMPATIBLE_MAJOR_DESC',
  CompanyCtasDistinctCountCtaDetailsAsc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_ASC',
  CompanyCtasDistinctCountCtaDetailsDesc = 'COMPANY_CTAS_DISTINCT_COUNT_CTA_DETAILS_DESC',
  CompanyCtasMinIdAsc = 'COMPANY_CTAS_MIN_ID_ASC',
  CompanyCtasMinIdDesc = 'COMPANY_CTAS_MIN_ID_DESC',
  CompanyCtasMinCtaIdAsc = 'COMPANY_CTAS_MIN_CTA_ID_ASC',
  CompanyCtasMinCtaIdDesc = 'COMPANY_CTAS_MIN_CTA_ID_DESC',
  CompanyCtasMinGroupIdAsc = 'COMPANY_CTAS_MIN_GROUP_ID_ASC',
  CompanyCtasMinGroupIdDesc = 'COMPANY_CTAS_MIN_GROUP_ID_DESC',
  CompanyCtasMinThemeAsc = 'COMPANY_CTAS_MIN_THEME_ASC',
  CompanyCtasMinThemeDesc = 'COMPANY_CTAS_MIN_THEME_DESC',
  CompanyCtasMinNameAsc = 'COMPANY_CTAS_MIN_NAME_ASC',
  CompanyCtasMinNameDesc = 'COMPANY_CTAS_MIN_NAME_DESC',
  CompanyCtasMinLockedAsc = 'COMPANY_CTAS_MIN_LOCKED_ASC',
  CompanyCtasMinLockedDesc = 'COMPANY_CTAS_MIN_LOCKED_DESC',
  CompanyCtasMinDefaultAsc = 'COMPANY_CTAS_MIN_DEFAULT_ASC',
  CompanyCtasMinDefaultDesc = 'COMPANY_CTAS_MIN_DEFAULT_DESC',
  CompanyCtasMinTemplateDefAsc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_ASC',
  CompanyCtasMinTemplateDefDesc = 'COMPANY_CTAS_MIN_TEMPLATE_DEF_DESC',
  CompanyCtasMinThemeDefAsc = 'COMPANY_CTAS_MIN_THEME_DEF_ASC',
  CompanyCtasMinThemeDefDesc = 'COMPANY_CTAS_MIN_THEME_DEF_DESC',
  CompanyCtasMinCreatedAtAsc = 'COMPANY_CTAS_MIN_CREATED_AT_ASC',
  CompanyCtasMinCreatedAtDesc = 'COMPANY_CTAS_MIN_CREATED_AT_DESC',
  CompanyCtasMinUpdatedAtAsc = 'COMPANY_CTAS_MIN_UPDATED_AT_ASC',
  CompanyCtasMinUpdatedAtDesc = 'COMPANY_CTAS_MIN_UPDATED_AT_DESC',
  CompanyCtasMinCompatibleMajorAsc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMinCompatibleMajorDesc = 'COMPANY_CTAS_MIN_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMinCtaDetailsAsc = 'COMPANY_CTAS_MIN_CTA_DETAILS_ASC',
  CompanyCtasMinCtaDetailsDesc = 'COMPANY_CTAS_MIN_CTA_DETAILS_DESC',
  CompanyCtasMaxIdAsc = 'COMPANY_CTAS_MAX_ID_ASC',
  CompanyCtasMaxIdDesc = 'COMPANY_CTAS_MAX_ID_DESC',
  CompanyCtasMaxCtaIdAsc = 'COMPANY_CTAS_MAX_CTA_ID_ASC',
  CompanyCtasMaxCtaIdDesc = 'COMPANY_CTAS_MAX_CTA_ID_DESC',
  CompanyCtasMaxGroupIdAsc = 'COMPANY_CTAS_MAX_GROUP_ID_ASC',
  CompanyCtasMaxGroupIdDesc = 'COMPANY_CTAS_MAX_GROUP_ID_DESC',
  CompanyCtasMaxThemeAsc = 'COMPANY_CTAS_MAX_THEME_ASC',
  CompanyCtasMaxThemeDesc = 'COMPANY_CTAS_MAX_THEME_DESC',
  CompanyCtasMaxNameAsc = 'COMPANY_CTAS_MAX_NAME_ASC',
  CompanyCtasMaxNameDesc = 'COMPANY_CTAS_MAX_NAME_DESC',
  CompanyCtasMaxLockedAsc = 'COMPANY_CTAS_MAX_LOCKED_ASC',
  CompanyCtasMaxLockedDesc = 'COMPANY_CTAS_MAX_LOCKED_DESC',
  CompanyCtasMaxDefaultAsc = 'COMPANY_CTAS_MAX_DEFAULT_ASC',
  CompanyCtasMaxDefaultDesc = 'COMPANY_CTAS_MAX_DEFAULT_DESC',
  CompanyCtasMaxTemplateDefAsc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_ASC',
  CompanyCtasMaxTemplateDefDesc = 'COMPANY_CTAS_MAX_TEMPLATE_DEF_DESC',
  CompanyCtasMaxThemeDefAsc = 'COMPANY_CTAS_MAX_THEME_DEF_ASC',
  CompanyCtasMaxThemeDefDesc = 'COMPANY_CTAS_MAX_THEME_DEF_DESC',
  CompanyCtasMaxCreatedAtAsc = 'COMPANY_CTAS_MAX_CREATED_AT_ASC',
  CompanyCtasMaxCreatedAtDesc = 'COMPANY_CTAS_MAX_CREATED_AT_DESC',
  CompanyCtasMaxUpdatedAtAsc = 'COMPANY_CTAS_MAX_UPDATED_AT_ASC',
  CompanyCtasMaxUpdatedAtDesc = 'COMPANY_CTAS_MAX_UPDATED_AT_DESC',
  CompanyCtasMaxCompatibleMajorAsc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_ASC',
  CompanyCtasMaxCompatibleMajorDesc = 'COMPANY_CTAS_MAX_COMPATIBLE_MAJOR_DESC',
  CompanyCtasMaxCtaDetailsAsc = 'COMPANY_CTAS_MAX_CTA_DETAILS_ASC',
  CompanyCtasMaxCtaDetailsDesc = 'COMPANY_CTAS_MAX_CTA_DETAILS_DESC',
  CompanyCtasAverageIdAsc = 'COMPANY_CTAS_AVERAGE_ID_ASC',
  CompanyCtasAverageIdDesc = 'COMPANY_CTAS_AVERAGE_ID_DESC',
  CompanyCtasAverageCtaIdAsc = 'COMPANY_CTAS_AVERAGE_CTA_ID_ASC',
  CompanyCtasAverageCtaIdDesc = 'COMPANY_CTAS_AVERAGE_CTA_ID_DESC',
  CompanyCtasAverageGroupIdAsc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_ASC',
  CompanyCtasAverageGroupIdDesc = 'COMPANY_CTAS_AVERAGE_GROUP_ID_DESC',
  CompanyCtasAverageThemeAsc = 'COMPANY_CTAS_AVERAGE_THEME_ASC',
  CompanyCtasAverageThemeDesc = 'COMPANY_CTAS_AVERAGE_THEME_DESC',
  CompanyCtasAverageNameAsc = 'COMPANY_CTAS_AVERAGE_NAME_ASC',
  CompanyCtasAverageNameDesc = 'COMPANY_CTAS_AVERAGE_NAME_DESC',
  CompanyCtasAverageLockedAsc = 'COMPANY_CTAS_AVERAGE_LOCKED_ASC',
  CompanyCtasAverageLockedDesc = 'COMPANY_CTAS_AVERAGE_LOCKED_DESC',
  CompanyCtasAverageDefaultAsc = 'COMPANY_CTAS_AVERAGE_DEFAULT_ASC',
  CompanyCtasAverageDefaultDesc = 'COMPANY_CTAS_AVERAGE_DEFAULT_DESC',
  CompanyCtasAverageTemplateDefAsc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_ASC',
  CompanyCtasAverageTemplateDefDesc = 'COMPANY_CTAS_AVERAGE_TEMPLATE_DEF_DESC',
  CompanyCtasAverageThemeDefAsc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_ASC',
  CompanyCtasAverageThemeDefDesc = 'COMPANY_CTAS_AVERAGE_THEME_DEF_DESC',
  CompanyCtasAverageCreatedAtAsc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_ASC',
  CompanyCtasAverageCreatedAtDesc = 'COMPANY_CTAS_AVERAGE_CREATED_AT_DESC',
  CompanyCtasAverageUpdatedAtAsc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_ASC',
  CompanyCtasAverageUpdatedAtDesc = 'COMPANY_CTAS_AVERAGE_UPDATED_AT_DESC',
  CompanyCtasAverageCompatibleMajorAsc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasAverageCompatibleMajorDesc = 'COMPANY_CTAS_AVERAGE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasAverageCtaDetailsAsc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_ASC',
  CompanyCtasAverageCtaDetailsDesc = 'COMPANY_CTAS_AVERAGE_CTA_DETAILS_DESC',
  CompanyCtasStddevSampleIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_ASC',
  CompanyCtasStddevSampleIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_ID_DESC',
  CompanyCtasStddevSampleCtaIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_ASC',
  CompanyCtasStddevSampleCtaIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_ID_DESC',
  CompanyCtasStddevSampleGroupIdAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyCtasStddevSampleGroupIdDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyCtasStddevSampleThemeAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_ASC',
  CompanyCtasStddevSampleThemeDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DESC',
  CompanyCtasStddevSampleNameAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_ASC',
  CompanyCtasStddevSampleNameDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_NAME_DESC',
  CompanyCtasStddevSampleLockedAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_ASC',
  CompanyCtasStddevSampleLockedDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_LOCKED_DESC',
  CompanyCtasStddevSampleDefaultAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_ASC',
  CompanyCtasStddevSampleDefaultDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_DEFAULT_DESC',
  CompanyCtasStddevSampleTemplateDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasStddevSampleTemplateDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasStddevSampleThemeDefAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_ASC',
  CompanyCtasStddevSampleThemeDefDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_THEME_DEF_DESC',
  CompanyCtasStddevSampleCreatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyCtasStddevSampleCreatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyCtasStddevSampleUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasStddevSampleUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasStddevSampleCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevSampleCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevSampleCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasStddevSampleCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasStddevPopulationIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_ASC',
  CompanyCtasStddevPopulationIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_ID_DESC',
  CompanyCtasStddevPopulationCtaIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_ASC',
  CompanyCtasStddevPopulationCtaIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_ID_DESC',
  CompanyCtasStddevPopulationGroupIdAsc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyCtasStddevPopulationGroupIdDesc = 'COMPANY_CTAS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyCtasStddevPopulationThemeAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_ASC',
  CompanyCtasStddevPopulationThemeDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DESC',
  CompanyCtasStddevPopulationNameAsc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_ASC',
  CompanyCtasStddevPopulationNameDesc = 'COMPANY_CTAS_STDDEV_POPULATION_NAME_DESC',
  CompanyCtasStddevPopulationLockedAsc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_ASC',
  CompanyCtasStddevPopulationLockedDesc = 'COMPANY_CTAS_STDDEV_POPULATION_LOCKED_DESC',
  CompanyCtasStddevPopulationDefaultAsc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_ASC',
  CompanyCtasStddevPopulationDefaultDesc = 'COMPANY_CTAS_STDDEV_POPULATION_DEFAULT_DESC',
  CompanyCtasStddevPopulationTemplateDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasStddevPopulationTemplateDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasStddevPopulationThemeDefAsc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_ASC',
  CompanyCtasStddevPopulationThemeDefDesc = 'COMPANY_CTAS_STDDEV_POPULATION_THEME_DEF_DESC',
  CompanyCtasStddevPopulationCreatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyCtasStddevPopulationCreatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyCtasStddevPopulationUpdatedAtAsc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyCtasStddevPopulationUpdatedAtDesc = 'COMPANY_CTAS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyCtasStddevPopulationCompatibleMajorAsc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasStddevPopulationCompatibleMajorDesc = 'COMPANY_CTAS_STDDEV_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasStddevPopulationCtaDetailsAsc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasStddevPopulationCtaDetailsDesc = 'COMPANY_CTAS_STDDEV_POPULATION_CTA_DETAILS_DESC',
  CompanyCtasVarianceSampleIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_ASC',
  CompanyCtasVarianceSampleIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_ID_DESC',
  CompanyCtasVarianceSampleCtaIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CompanyCtasVarianceSampleCtaIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CompanyCtasVarianceSampleGroupIdAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyCtasVarianceSampleGroupIdDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyCtasVarianceSampleThemeAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_ASC',
  CompanyCtasVarianceSampleThemeDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DESC',
  CompanyCtasVarianceSampleNameAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_ASC',
  CompanyCtasVarianceSampleNameDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_NAME_DESC',
  CompanyCtasVarianceSampleLockedAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_ASC',
  CompanyCtasVarianceSampleLockedDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_LOCKED_DESC',
  CompanyCtasVarianceSampleDefaultAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_ASC',
  CompanyCtasVarianceSampleDefaultDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_DEFAULT_DESC',
  CompanyCtasVarianceSampleTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_ASC',
  CompanyCtasVarianceSampleTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_TEMPLATE_DEF_DESC',
  CompanyCtasVarianceSampleThemeDefAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_ASC',
  CompanyCtasVarianceSampleThemeDefDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_THEME_DEF_DESC',
  CompanyCtasVarianceSampleCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyCtasVarianceSampleCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyCtasVarianceSampleUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyCtasVarianceSampleUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyCtasVarianceSampleCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVarianceSampleCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVarianceSampleCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_ASC',
  CompanyCtasVarianceSampleCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_SAMPLE_CTA_DETAILS_DESC',
  CompanyCtasVariancePopulationIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_ASC',
  CompanyCtasVariancePopulationIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_ID_DESC',
  CompanyCtasVariancePopulationCtaIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_ASC',
  CompanyCtasVariancePopulationCtaIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_ID_DESC',
  CompanyCtasVariancePopulationGroupIdAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyCtasVariancePopulationGroupIdDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyCtasVariancePopulationThemeAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_ASC',
  CompanyCtasVariancePopulationThemeDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DESC',
  CompanyCtasVariancePopulationNameAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_ASC',
  CompanyCtasVariancePopulationNameDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_NAME_DESC',
  CompanyCtasVariancePopulationLockedAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_ASC',
  CompanyCtasVariancePopulationLockedDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_LOCKED_DESC',
  CompanyCtasVariancePopulationDefaultAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_ASC',
  CompanyCtasVariancePopulationDefaultDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_DEFAULT_DESC',
  CompanyCtasVariancePopulationTemplateDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_ASC',
  CompanyCtasVariancePopulationTemplateDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_TEMPLATE_DEF_DESC',
  CompanyCtasVariancePopulationThemeDefAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_ASC',
  CompanyCtasVariancePopulationThemeDefDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_THEME_DEF_DESC',
  CompanyCtasVariancePopulationCreatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyCtasVariancePopulationCreatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyCtasVariancePopulationUpdatedAtAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyCtasVariancePopulationUpdatedAtDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyCtasVariancePopulationCompatibleMajorAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_ASC',
  CompanyCtasVariancePopulationCompatibleMajorDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_COMPATIBLE_MAJOR_DESC',
  CompanyCtasVariancePopulationCtaDetailsAsc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_ASC',
  CompanyCtasVariancePopulationCtaDetailsDesc = 'COMPANY_CTAS_VARIANCE_POPULATION_CTA_DETAILS_DESC',
  DeliveryMethodsCountAsc = 'DELIVERY_METHODS_COUNT_ASC',
  DeliveryMethodsCountDesc = 'DELIVERY_METHODS_COUNT_DESC',
  DeliveryMethodsSumGroupIdAsc = 'DELIVERY_METHODS_SUM_GROUP_ID_ASC',
  DeliveryMethodsSumGroupIdDesc = 'DELIVERY_METHODS_SUM_GROUP_ID_DESC',
  DeliveryMethodsSumTypeAsc = 'DELIVERY_METHODS_SUM_TYPE_ASC',
  DeliveryMethodsSumTypeDesc = 'DELIVERY_METHODS_SUM_TYPE_DESC',
  DeliveryMethodsSumCreatedAtAsc = 'DELIVERY_METHODS_SUM_CREATED_AT_ASC',
  DeliveryMethodsSumCreatedAtDesc = 'DELIVERY_METHODS_SUM_CREATED_AT_DESC',
  DeliveryMethodsSumUpdatedAtAsc = 'DELIVERY_METHODS_SUM_UPDATED_AT_ASC',
  DeliveryMethodsSumUpdatedAtDesc = 'DELIVERY_METHODS_SUM_UPDATED_AT_DESC',
  DeliveryMethodsSumConfigAsc = 'DELIVERY_METHODS_SUM_CONFIG_ASC',
  DeliveryMethodsSumConfigDesc = 'DELIVERY_METHODS_SUM_CONFIG_DESC',
  DeliveryMethodsSumValueAsc = 'DELIVERY_METHODS_SUM_VALUE_ASC',
  DeliveryMethodsSumValueDesc = 'DELIVERY_METHODS_SUM_VALUE_DESC',
  DeliveryMethodsSumIdAsc = 'DELIVERY_METHODS_SUM_ID_ASC',
  DeliveryMethodsSumIdDesc = 'DELIVERY_METHODS_SUM_ID_DESC',
  DeliveryMethodsSumRuleAsc = 'DELIVERY_METHODS_SUM_RULE_ASC',
  DeliveryMethodsSumRuleDesc = 'DELIVERY_METHODS_SUM_RULE_DESC',
  DeliveryMethodsDistinctCountGroupIdAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_GROUP_ID_ASC',
  DeliveryMethodsDistinctCountGroupIdDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_GROUP_ID_DESC',
  DeliveryMethodsDistinctCountTypeAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_TYPE_ASC',
  DeliveryMethodsDistinctCountTypeDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_TYPE_DESC',
  DeliveryMethodsDistinctCountCreatedAtAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_CREATED_AT_ASC',
  DeliveryMethodsDistinctCountCreatedAtDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_CREATED_AT_DESC',
  DeliveryMethodsDistinctCountUpdatedAtAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC',
  DeliveryMethodsDistinctCountUpdatedAtDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC',
  DeliveryMethodsDistinctCountConfigAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_CONFIG_ASC',
  DeliveryMethodsDistinctCountConfigDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_CONFIG_DESC',
  DeliveryMethodsDistinctCountValueAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_VALUE_ASC',
  DeliveryMethodsDistinctCountValueDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_VALUE_DESC',
  DeliveryMethodsDistinctCountIdAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_ID_ASC',
  DeliveryMethodsDistinctCountIdDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_ID_DESC',
  DeliveryMethodsDistinctCountRuleAsc = 'DELIVERY_METHODS_DISTINCT_COUNT_RULE_ASC',
  DeliveryMethodsDistinctCountRuleDesc = 'DELIVERY_METHODS_DISTINCT_COUNT_RULE_DESC',
  DeliveryMethodsMinGroupIdAsc = 'DELIVERY_METHODS_MIN_GROUP_ID_ASC',
  DeliveryMethodsMinGroupIdDesc = 'DELIVERY_METHODS_MIN_GROUP_ID_DESC',
  DeliveryMethodsMinTypeAsc = 'DELIVERY_METHODS_MIN_TYPE_ASC',
  DeliveryMethodsMinTypeDesc = 'DELIVERY_METHODS_MIN_TYPE_DESC',
  DeliveryMethodsMinCreatedAtAsc = 'DELIVERY_METHODS_MIN_CREATED_AT_ASC',
  DeliveryMethodsMinCreatedAtDesc = 'DELIVERY_METHODS_MIN_CREATED_AT_DESC',
  DeliveryMethodsMinUpdatedAtAsc = 'DELIVERY_METHODS_MIN_UPDATED_AT_ASC',
  DeliveryMethodsMinUpdatedAtDesc = 'DELIVERY_METHODS_MIN_UPDATED_AT_DESC',
  DeliveryMethodsMinConfigAsc = 'DELIVERY_METHODS_MIN_CONFIG_ASC',
  DeliveryMethodsMinConfigDesc = 'DELIVERY_METHODS_MIN_CONFIG_DESC',
  DeliveryMethodsMinValueAsc = 'DELIVERY_METHODS_MIN_VALUE_ASC',
  DeliveryMethodsMinValueDesc = 'DELIVERY_METHODS_MIN_VALUE_DESC',
  DeliveryMethodsMinIdAsc = 'DELIVERY_METHODS_MIN_ID_ASC',
  DeliveryMethodsMinIdDesc = 'DELIVERY_METHODS_MIN_ID_DESC',
  DeliveryMethodsMinRuleAsc = 'DELIVERY_METHODS_MIN_RULE_ASC',
  DeliveryMethodsMinRuleDesc = 'DELIVERY_METHODS_MIN_RULE_DESC',
  DeliveryMethodsMaxGroupIdAsc = 'DELIVERY_METHODS_MAX_GROUP_ID_ASC',
  DeliveryMethodsMaxGroupIdDesc = 'DELIVERY_METHODS_MAX_GROUP_ID_DESC',
  DeliveryMethodsMaxTypeAsc = 'DELIVERY_METHODS_MAX_TYPE_ASC',
  DeliveryMethodsMaxTypeDesc = 'DELIVERY_METHODS_MAX_TYPE_DESC',
  DeliveryMethodsMaxCreatedAtAsc = 'DELIVERY_METHODS_MAX_CREATED_AT_ASC',
  DeliveryMethodsMaxCreatedAtDesc = 'DELIVERY_METHODS_MAX_CREATED_AT_DESC',
  DeliveryMethodsMaxUpdatedAtAsc = 'DELIVERY_METHODS_MAX_UPDATED_AT_ASC',
  DeliveryMethodsMaxUpdatedAtDesc = 'DELIVERY_METHODS_MAX_UPDATED_AT_DESC',
  DeliveryMethodsMaxConfigAsc = 'DELIVERY_METHODS_MAX_CONFIG_ASC',
  DeliveryMethodsMaxConfigDesc = 'DELIVERY_METHODS_MAX_CONFIG_DESC',
  DeliveryMethodsMaxValueAsc = 'DELIVERY_METHODS_MAX_VALUE_ASC',
  DeliveryMethodsMaxValueDesc = 'DELIVERY_METHODS_MAX_VALUE_DESC',
  DeliveryMethodsMaxIdAsc = 'DELIVERY_METHODS_MAX_ID_ASC',
  DeliveryMethodsMaxIdDesc = 'DELIVERY_METHODS_MAX_ID_DESC',
  DeliveryMethodsMaxRuleAsc = 'DELIVERY_METHODS_MAX_RULE_ASC',
  DeliveryMethodsMaxRuleDesc = 'DELIVERY_METHODS_MAX_RULE_DESC',
  DeliveryMethodsAverageGroupIdAsc = 'DELIVERY_METHODS_AVERAGE_GROUP_ID_ASC',
  DeliveryMethodsAverageGroupIdDesc = 'DELIVERY_METHODS_AVERAGE_GROUP_ID_DESC',
  DeliveryMethodsAverageTypeAsc = 'DELIVERY_METHODS_AVERAGE_TYPE_ASC',
  DeliveryMethodsAverageTypeDesc = 'DELIVERY_METHODS_AVERAGE_TYPE_DESC',
  DeliveryMethodsAverageCreatedAtAsc = 'DELIVERY_METHODS_AVERAGE_CREATED_AT_ASC',
  DeliveryMethodsAverageCreatedAtDesc = 'DELIVERY_METHODS_AVERAGE_CREATED_AT_DESC',
  DeliveryMethodsAverageUpdatedAtAsc = 'DELIVERY_METHODS_AVERAGE_UPDATED_AT_ASC',
  DeliveryMethodsAverageUpdatedAtDesc = 'DELIVERY_METHODS_AVERAGE_UPDATED_AT_DESC',
  DeliveryMethodsAverageConfigAsc = 'DELIVERY_METHODS_AVERAGE_CONFIG_ASC',
  DeliveryMethodsAverageConfigDesc = 'DELIVERY_METHODS_AVERAGE_CONFIG_DESC',
  DeliveryMethodsAverageValueAsc = 'DELIVERY_METHODS_AVERAGE_VALUE_ASC',
  DeliveryMethodsAverageValueDesc = 'DELIVERY_METHODS_AVERAGE_VALUE_DESC',
  DeliveryMethodsAverageIdAsc = 'DELIVERY_METHODS_AVERAGE_ID_ASC',
  DeliveryMethodsAverageIdDesc = 'DELIVERY_METHODS_AVERAGE_ID_DESC',
  DeliveryMethodsAverageRuleAsc = 'DELIVERY_METHODS_AVERAGE_RULE_ASC',
  DeliveryMethodsAverageRuleDesc = 'DELIVERY_METHODS_AVERAGE_RULE_DESC',
  DeliveryMethodsStddevSampleGroupIdAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_GROUP_ID_ASC',
  DeliveryMethodsStddevSampleGroupIdDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_GROUP_ID_DESC',
  DeliveryMethodsStddevSampleTypeAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_TYPE_ASC',
  DeliveryMethodsStddevSampleTypeDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_TYPE_DESC',
  DeliveryMethodsStddevSampleCreatedAtAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsStddevSampleCreatedAtDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsStddevSampleUpdatedAtAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsStddevSampleUpdatedAtDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsStddevSampleConfigAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CONFIG_ASC',
  DeliveryMethodsStddevSampleConfigDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_CONFIG_DESC',
  DeliveryMethodsStddevSampleValueAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_VALUE_ASC',
  DeliveryMethodsStddevSampleValueDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_VALUE_DESC',
  DeliveryMethodsStddevSampleIdAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_ID_ASC',
  DeliveryMethodsStddevSampleIdDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_ID_DESC',
  DeliveryMethodsStddevSampleRuleAsc = 'DELIVERY_METHODS_STDDEV_SAMPLE_RULE_ASC',
  DeliveryMethodsStddevSampleRuleDesc = 'DELIVERY_METHODS_STDDEV_SAMPLE_RULE_DESC',
  DeliveryMethodsStddevPopulationGroupIdAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_GROUP_ID_ASC',
  DeliveryMethodsStddevPopulationGroupIdDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_GROUP_ID_DESC',
  DeliveryMethodsStddevPopulationTypeAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_TYPE_ASC',
  DeliveryMethodsStddevPopulationTypeDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_TYPE_DESC',
  DeliveryMethodsStddevPopulationCreatedAtAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsStddevPopulationCreatedAtDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsStddevPopulationUpdatedAtAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsStddevPopulationUpdatedAtDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsStddevPopulationConfigAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_CONFIG_ASC',
  DeliveryMethodsStddevPopulationConfigDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_CONFIG_DESC',
  DeliveryMethodsStddevPopulationValueAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_VALUE_ASC',
  DeliveryMethodsStddevPopulationValueDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_VALUE_DESC',
  DeliveryMethodsStddevPopulationIdAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_ID_ASC',
  DeliveryMethodsStddevPopulationIdDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_ID_DESC',
  DeliveryMethodsStddevPopulationRuleAsc = 'DELIVERY_METHODS_STDDEV_POPULATION_RULE_ASC',
  DeliveryMethodsStddevPopulationRuleDesc = 'DELIVERY_METHODS_STDDEV_POPULATION_RULE_DESC',
  DeliveryMethodsVarianceSampleGroupIdAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  DeliveryMethodsVarianceSampleGroupIdDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  DeliveryMethodsVarianceSampleTypeAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_TYPE_ASC',
  DeliveryMethodsVarianceSampleTypeDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_TYPE_DESC',
  DeliveryMethodsVarianceSampleCreatedAtAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DeliveryMethodsVarianceSampleCreatedAtDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DeliveryMethodsVarianceSampleUpdatedAtAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DeliveryMethodsVarianceSampleUpdatedAtDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DeliveryMethodsVarianceSampleConfigAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CONFIG_ASC',
  DeliveryMethodsVarianceSampleConfigDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_CONFIG_DESC',
  DeliveryMethodsVarianceSampleValueAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_VALUE_ASC',
  DeliveryMethodsVarianceSampleValueDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_VALUE_DESC',
  DeliveryMethodsVarianceSampleIdAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_ID_ASC',
  DeliveryMethodsVarianceSampleIdDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_ID_DESC',
  DeliveryMethodsVarianceSampleRuleAsc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_RULE_ASC',
  DeliveryMethodsVarianceSampleRuleDesc = 'DELIVERY_METHODS_VARIANCE_SAMPLE_RULE_DESC',
  DeliveryMethodsVariancePopulationGroupIdAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_GROUP_ID_ASC',
  DeliveryMethodsVariancePopulationGroupIdDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_GROUP_ID_DESC',
  DeliveryMethodsVariancePopulationTypeAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_TYPE_ASC',
  DeliveryMethodsVariancePopulationTypeDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_TYPE_DESC',
  DeliveryMethodsVariancePopulationCreatedAtAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC',
  DeliveryMethodsVariancePopulationCreatedAtDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC',
  DeliveryMethodsVariancePopulationUpdatedAtAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DeliveryMethodsVariancePopulationUpdatedAtDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DeliveryMethodsVariancePopulationConfigAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CONFIG_ASC',
  DeliveryMethodsVariancePopulationConfigDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_CONFIG_DESC',
  DeliveryMethodsVariancePopulationValueAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_VALUE_ASC',
  DeliveryMethodsVariancePopulationValueDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_VALUE_DESC',
  DeliveryMethodsVariancePopulationIdAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_ID_ASC',
  DeliveryMethodsVariancePopulationIdDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_ID_DESC',
  DeliveryMethodsVariancePopulationRuleAsc = 'DELIVERY_METHODS_VARIANCE_POPULATION_RULE_ASC',
  DeliveryMethodsVariancePopulationRuleDesc = 'DELIVERY_METHODS_VARIANCE_POPULATION_RULE_DESC'
}

export type HavingBigfloatFilter = {
  equalTo?: Maybe<Scalars['BigFloat']>;
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  greaterThan?: Maybe<Scalars['BigFloat']>;
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  lessThan?: Maybe<Scalars['BigFloat']>;
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};

export type HavingBigintFilter = {
  equalTo?: Maybe<Scalars['BigInt']>;
  notEqualTo?: Maybe<Scalars['BigInt']>;
  greaterThan?: Maybe<Scalars['BigInt']>;
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  lessThan?: Maybe<Scalars['BigInt']>;
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
  equalTo?: Maybe<Scalars['Datetime']>;
  notEqualTo?: Maybe<Scalars['Datetime']>;
  greaterThan?: Maybe<Scalars['Datetime']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  lessThan?: Maybe<Scalars['Datetime']>;
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

export type HavingFloatFilter = {
  equalTo?: Maybe<Scalars['Float']>;
  notEqualTo?: Maybe<Scalars['Float']>;
  greaterThan?: Maybe<Scalars['Float']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
  lessThan?: Maybe<Scalars['Float']>;
  lessThanOrEqualTo?: Maybe<Scalars['Float']>;
};

export type HavingIntFilter = {
  equalTo?: Maybe<Scalars['Int']>;
  notEqualTo?: Maybe<Scalars['Int']>;
  greaterThan?: Maybe<Scalars['Int']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
  lessThan?: Maybe<Scalars['Int']>;
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type IdDuplicate = {
  customerUid?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['Float']>;
};

export type IdFieldSource = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
};

export type IdFieldValues = {
  value?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<IdFieldSource>>>;
};

export type IdFields = {
  givenName?: Maybe<IdFieldValues>;
  familyName?: Maybe<IdFieldValues>;
  middleName?: Maybe<IdFieldValues>;
  dateOfBirth?: Maybe<IdFieldValues>;
  idType?: Maybe<IdFieldValues>;
  idIssuer?: Maybe<IdFieldValues>;
  idExpiryDate?: Maybe<IdFieldValues>;
  addressCity?: Maybe<IdFieldValues>;
  addressCountry?: Maybe<IdFieldValues>;
  addressPostalCode?: Maybe<IdFieldValues>;
  addressStreet?: Maybe<IdFieldValues>;
  addressSubdivision?: Maybe<IdFieldValues>;
};

export type IdImageDetails = {
  fullImage?: Maybe<Scalars['String']>;
  croppedImage?: Maybe<Scalars['String']>;
  faceImage?: Maybe<Scalars['String']>;
};

export enum IdImageFormat {
  Png = 'png',
  Jpeg = 'jpeg'
}

export type IdImageInput = {
  fullImage: Scalars['String'];
  imageFormat?: Maybe<IdImageFormat>;
};

export enum IdImageType {
  IdFront = 'idFront',
  IdBack = 'idBack',
  Selfie = 'selfie'
}

export enum IdProvider {
  Berbix = 'berbix',
  Stripe = 'stripe',
  Autocorp = 'autocorp'
}

export enum IdSupportedCountries {
  Us = 'US',
  Ca = 'CA'
}

export enum IdToolTemplateType {
  FrontBackSelfieLiveness = 'FrontBackSelfieLiveness',
  FrontSelfieLiveness = 'FrontSelfieLiveness',
  FrontBackSelfie = 'FrontBackSelfie',
  FrontSelfie = 'FrontSelfie',
  FrontBack = 'FrontBack',
  Front = 'Front'
}

export type IdVerification = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
};

export type IdVerificationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IdVerificationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IdVerificationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IdVerificationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IdVerificationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IdVerificationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IdVerificationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IdVerificationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IdVerificationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IdVerificationVariancePopulationAggregates>;
};

export type IdVerificationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IdVerification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IdVerificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `verificationId` field. */
  verificationId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationToken` field. */
  verificationToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationUserId` field. */
  verificationUserId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `firstStep` field. */
  firstStep?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transaction` field. */
  transaction?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `nextStep` field. */
  nextStep?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `templateType` field. */
  templateType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationUrl` field. */
  verificationUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
};

export type IdVerificationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationId across the matching connection */
  verificationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationToken across the matching connection */
  verificationToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationUserId across the matching connection */
  verificationUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstStep across the matching connection */
  firstStep?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transaction across the matching connection */
  transaction?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nextStep across the matching connection */
  nextStep?: Maybe<Scalars['BigInt']>;
  /** Distinct count of templateType across the matching connection */
  templateType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verificationUrl across the matching connection */
  verificationUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IdVerification` object types. All fields are combined with a logical ‘and.’ */
export type IdVerificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `verificationId` field. */
  verificationId?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationToken` field. */
  verificationToken?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationUserId` field. */
  verificationUserId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `firstStep` field. */
  firstStep?: Maybe<StringFilter>;
  /** Filter by the object’s `transaction` field. */
  transaction?: Maybe<JsonFilter>;
  /** Filter by the object’s `nextStep` field. */
  nextStep?: Maybe<StringFilter>;
  /** Filter by the object’s `templateType` field. */
  templateType?: Maybe<StringFilter>;
  /** Filter by the object’s `verificationUrl` field. */
  verificationUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IdVerificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IdVerificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IdVerificationFilter>;
};

/** Grouping methods for `IdVerification` for usage during aggregation. */
export enum IdVerificationGroupBy {
  VerificationId = 'VERIFICATION_ID',
  VerificationToken = 'VERIFICATION_TOKEN',
  VerificationUserId = 'VERIFICATION_USER_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  FirstStep = 'FIRST_STEP',
  Transaction = 'TRANSACTION',
  NextStep = 'NEXT_STEP',
  TemplateType = 'TEMPLATE_TYPE',
  VerificationUrl = 'VERIFICATION_URL',
  Provider = 'PROVIDER'
}

export type IdVerificationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IdVerification` aggregates. */
export type IdVerificationHavingInput = {
  AND?: Maybe<Array<IdVerificationHavingInput>>;
  OR?: Maybe<Array<IdVerificationHavingInput>>;
  sum?: Maybe<IdVerificationHavingSumInput>;
  distinctCount?: Maybe<IdVerificationHavingDistinctCountInput>;
  min?: Maybe<IdVerificationHavingMinInput>;
  max?: Maybe<IdVerificationHavingMaxInput>;
  average?: Maybe<IdVerificationHavingAverageInput>;
  stddevSample?: Maybe<IdVerificationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IdVerificationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IdVerificationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IdVerificationHavingVariancePopulationInput>;
};

export type IdVerificationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IdVerificationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `idVerification` to look up the row to connect. */
export type IdVerificationIdVerificationLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to delete. */
export type IdVerificationIdVerificationLeadIdKeyDelete = {
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to connect. */
export type IdVerificationIdVerificationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `idVerification` to look up the row to delete. */
export type IdVerificationIdVerificationPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `IdVerification` */
export type IdVerificationInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** The `idVerification` to be created by this mutation. */
export type IdVerificationLeadIdFkeyIdVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `IdVerificationInput` mutation. */
export type IdVerificationLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnIdVerificationForIdVerificationLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<IdVerificationLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `idVerification` in the `LeadInput` mutation. */
export type IdVerificationLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `idVerification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectById?: Maybe<IdVerificationIdVerificationPkeyConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectByLeadId?: Maybe<IdVerificationIdVerificationLeadIdKeyConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IdVerificationNodeIdConnect>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteById?: Maybe<IdVerificationIdVerificationPkeyDelete>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteByLeadId?: Maybe<IdVerificationIdVerificationLeadIdKeyDelete>;
  /** The primary key(s) for `idVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IdVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateById?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateByLeadId?: Maybe<IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `idVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `IdVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IdVerificationLeadIdFkeyIdVerificationCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type IdVerificationLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type IdVerificationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IdVerificationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IdVerificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `idVerification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IdVerificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `idVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `idVerification` to look up the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `idVerification` to look up the row to update. */
export type IdVerificationOnIdVerificationForIdVerificationLeadIdFkeyUsingIdVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `IdVerification`. Fields that are set will be updated. */
export type IdVerificationPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

export type IdVerificationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type IdVerificationTemplateResponse = {
  success?: Maybe<Scalars['Boolean']>;
  templateType?: Maybe<IdToolTemplateType>;
  alreadyVerified?: Maybe<Scalars['Boolean']>;
  isIdEnabled: Scalars['Boolean'];
  nextStep?: Maybe<Scalars['String']>;
  provider?: Maybe<IdProvider>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type IdVerificationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IdVerificationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IdVerification` values. */
export type IdVerificationsConnection = {
  /** A list of `IdVerification` objects. */
  nodes: Array<IdVerification>;
  /** A list of edges which contains the `IdVerification` and cursor to aid in pagination. */
  edges: Array<IdVerificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IdVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IdVerificationAggregates>>;
};


/** A connection to a list of `IdVerification` values. */
export type IdVerificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IdVerificationGroupBy>;
  having?: Maybe<IdVerificationHavingInput>;
};

/** A `IdVerification` edge in the connection. */
export type IdVerificationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdVerification` at the end of the edge. */
  node: IdVerification;
};

/** Methods to use when ordering `IdVerification`. */
export enum IdVerificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  VerificationIdAsc = 'VERIFICATION_ID_ASC',
  VerificationIdDesc = 'VERIFICATION_ID_DESC',
  VerificationTokenAsc = 'VERIFICATION_TOKEN_ASC',
  VerificationTokenDesc = 'VERIFICATION_TOKEN_DESC',
  VerificationUserIdAsc = 'VERIFICATION_USER_ID_ASC',
  VerificationUserIdDesc = 'VERIFICATION_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  FirstStepAsc = 'FIRST_STEP_ASC',
  FirstStepDesc = 'FIRST_STEP_DESC',
  TransactionAsc = 'TRANSACTION_ASC',
  TransactionDesc = 'TRANSACTION_DESC',
  NextStepAsc = 'NEXT_STEP_ASC',
  NextStepDesc = 'NEXT_STEP_DESC',
  TemplateTypeAsc = 'TEMPLATE_TYPE_ASC',
  TemplateTypeDesc = 'TEMPLATE_TYPE_DESC',
  VerificationUrlAsc = 'VERIFICATION_URL_ASC',
  VerificationUrlDesc = 'VERIFICATION_URL_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Images = {
  front?: Maybe<IdImageDetails>;
  selfie?: Maybe<IdImageDetails>;
  back?: Maybe<IdImageDetails>;
  liveness?: Maybe<Scalars['JSON']>;
};

export type IncomeAccount = {
  id?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  institutionNumber?: Maybe<Scalars['String']>;
  overdraftLimit?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  balance?: Maybe<IncomeAccountBalance>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  holder?: Maybe<IncomeAccountHolder>;
  transactions?: Maybe<Array<IncomeAccountTransaction>>;
};

export type IncomeAccountBalance = {
  available?: Maybe<Scalars['Float']>;
  current?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type IncomeAccountHolder = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<IncomeAccountHolderAddress>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type IncomeAccountHolderAddress = {
  civicAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type IncomeAccountTransaction = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
};

export type IncomeAttributes = {
  estimatedAnnualIncome?: Maybe<Scalars['Float']>;
  verifiedIncome?: Maybe<Scalars['Float']>;
  employerIncomeFrequency?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  loanElements?: Maybe<LoanElements>;
  riskElements?: Maybe<RiskElements>;
};

export type IncomeVerification = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  accounts?: Maybe<Array<IncomeAccount>>;
  attributes?: Maybe<IncomeAttributes>;
};

export type IncomeVerificationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IncomeVerificationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IncomeVerificationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IncomeVerificationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IncomeVerificationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IncomeVerificationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IncomeVerificationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IncomeVerificationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IncomeVerificationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IncomeVerificationVariancePopulationAggregates>;
};

export type IncomeVerificationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IncomeVerification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IncomeVerificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `loginToken` field. */
  loginToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `directDepositAccountId` field. */
  directDepositAccountId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rawAttributes` field. */
  rawAttributes?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rawAccounts` field. */
  rawAccounts?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `report` field. */
  report?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `verifiedAt` field. */
  verifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `accessToken` field. */
  accessToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['String']>;
};

export type IncomeVerificationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of loginToken across the matching connection */
  loginToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of directDepositAccountId across the matching connection */
  directDepositAccountId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawAttributes across the matching connection */
  rawAttributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawAccounts across the matching connection */
  rawAccounts?: Maybe<Scalars['BigInt']>;
  /** Distinct count of report across the matching connection */
  report?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of verifiedAt across the matching connection */
  verifiedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accessToken across the matching connection */
  accessToken?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `IncomeVerification` object types. All fields are combined with a logical ‘and.’ */
export type IncomeVerificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `loginToken` field. */
  loginToken?: Maybe<StringFilter>;
  /** Filter by the object’s `directDepositAccountId` field. */
  directDepositAccountId?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `verifiedAt` field. */
  verifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `accessToken` field. */
  accessToken?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<StringFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IncomeVerificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IncomeVerificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IncomeVerificationFilter>;
};

/** Grouping methods for `IncomeVerification` for usage during aggregation. */
export enum IncomeVerificationGroupBy {
  LoginToken = 'LOGIN_TOKEN',
  DirectDepositAccountId = 'DIRECT_DEPOSIT_ACCOUNT_ID',
  RawAttributes = 'RAW_ATTRIBUTES',
  RawAccounts = 'RAW_ACCOUNTS',
  Report = 'REPORT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  VerifiedAt = 'VERIFIED_AT',
  VerifiedAtTruncatedToHour = 'VERIFIED_AT_TRUNCATED_TO_HOUR',
  VerifiedAtTruncatedToDay = 'VERIFIED_AT_TRUNCATED_TO_DAY',
  Provider = 'PROVIDER',
  AccessToken = 'ACCESS_TOKEN',
  UserId = 'USER_ID'
}

export type IncomeVerificationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `IncomeVerification` aggregates. */
export type IncomeVerificationHavingInput = {
  AND?: Maybe<Array<IncomeVerificationHavingInput>>;
  OR?: Maybe<Array<IncomeVerificationHavingInput>>;
  sum?: Maybe<IncomeVerificationHavingSumInput>;
  distinctCount?: Maybe<IncomeVerificationHavingDistinctCountInput>;
  min?: Maybe<IncomeVerificationHavingMinInput>;
  max?: Maybe<IncomeVerificationHavingMaxInput>;
  average?: Maybe<IncomeVerificationHavingAverageInput>;
  stddevSample?: Maybe<IncomeVerificationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IncomeVerificationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IncomeVerificationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IncomeVerificationHavingVariancePopulationInput>;
};

export type IncomeVerificationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

export type IncomeVerificationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  verifiedAt?: Maybe<HavingDatetimeFilter>;
};

/** The fields on `incomeVerification` to look up the row to connect. */
export type IncomeVerificationIncomeVerificationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to delete. */
export type IncomeVerificationIncomeVerificationPkeyDelete = {
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to connect. */
export type IncomeVerificationIncomeVerificationUniqueToLeadKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `incomeVerification` to look up the row to delete. */
export type IncomeVerificationIncomeVerificationUniqueToLeadKeyDelete = {
  leadId: Scalars['UUID'];
};

/** An input for mutations affecting `IncomeVerification` */
export type IncomeVerificationInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** The `incomeVerification` to be created by this mutation. */
export type IncomeVerificationLeadIdFkeyIncomeVerificationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `IncomeVerificationInput` mutation. */
export type IncomeVerificationLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<IncomeVerificationLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `incomeVerification` in the `LeadInput` mutation. */
export type IncomeVerificationLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `incomeVerification` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectById?: Maybe<IncomeVerificationIncomeVerificationPkeyConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectByLeadId?: Maybe<IncomeVerificationIncomeVerificationUniqueToLeadKeyConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  connectByNodeId?: Maybe<IncomeVerificationNodeIdConnect>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteById?: Maybe<IncomeVerificationIncomeVerificationPkeyDelete>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteByLeadId?: Maybe<IncomeVerificationIncomeVerificationUniqueToLeadKeyDelete>;
  /** The primary key(s) for `incomeVerification` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IncomeVerificationNodeIdDelete>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateById?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationPkeyUpdate>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateByLeadId?: Maybe<IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationUniqueToLeadKeyUpdate>;
  /** The primary key(s) and patch data for `incomeVerification` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate>;
  /** A `IncomeVerificationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<IncomeVerificationLeadIdFkeyIncomeVerificationCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type IncomeVerificationLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type IncomeVerificationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IncomeVerificationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IncomeVerificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IncomeVerificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `incomeVerification` to look up the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationPkeyUpdate = {
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `incomeVerification` to look up the row to update. */
export type IncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingIncomeVerificationUniqueToLeadKeyUpdate = {
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** Represents an update to a `IncomeVerification`. Fields that are set will be updated. */
export type IncomeVerificationPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  loginToken?: Maybe<Scalars['String']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

export type IncomeVerificationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type IncomeVerificationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IncomeVerificationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IncomeVerification` values. */
export type IncomeVerificationsConnection = {
  /** A list of `IncomeVerification` objects. */
  nodes: Array<IncomeVerification>;
  /** A list of edges which contains the `IncomeVerification` and cursor to aid in pagination. */
  edges: Array<IncomeVerificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IncomeVerification` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IncomeVerificationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IncomeVerificationAggregates>>;
};


/** A connection to a list of `IncomeVerification` values. */
export type IncomeVerificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IncomeVerificationGroupBy>;
  having?: Maybe<IncomeVerificationHavingInput>;
};

/** A `IncomeVerification` edge in the connection. */
export type IncomeVerificationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IncomeVerification` at the end of the edge. */
  node: IncomeVerification;
};

/** Methods to use when ordering `IncomeVerification`. */
export enum IncomeVerificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  LoginTokenAsc = 'LOGIN_TOKEN_ASC',
  LoginTokenDesc = 'LOGIN_TOKEN_DESC',
  DirectDepositAccountIdAsc = 'DIRECT_DEPOSIT_ACCOUNT_ID_ASC',
  DirectDepositAccountIdDesc = 'DIRECT_DEPOSIT_ACCOUNT_ID_DESC',
  RawAttributesAsc = 'RAW_ATTRIBUTES_ASC',
  RawAttributesDesc = 'RAW_ATTRIBUTES_DESC',
  RawAccountsAsc = 'RAW_ACCOUNTS_ASC',
  RawAccountsDesc = 'RAW_ACCOUNTS_DESC',
  ReportAsc = 'REPORT_ASC',
  ReportDesc = 'REPORT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VerifiedAtAsc = 'VERIFIED_AT_ASC',
  VerifiedAtDesc = 'VERIFIED_AT_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  AccessTokenAsc = 'ACCESS_TOKEN_ASC',
  AccessTokenDesc = 'ACCESS_TOKEN_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type InitiateIdVerificationInput = {
  leadId: Scalars['UUID'];
  consentToFacialRecognition?: Maybe<Scalars['Boolean']>;
};

export type InitiateIdVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  firstStep?: Maybe<Scalars['String']>;
  clientToken?: Maybe<Scalars['String']>;
  templateType?: Maybe<IdToolTemplateType>;
  nextStep?: Maybe<Scalars['String']>;
};

export type Inquiry = {
  date?: Maybe<Scalars['String']>;
  inquirer?: Maybe<EquifaxEntity>;
  fields: Array<Scalars['String']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type Integration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  displayName: Scalars['String'];
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyIntegrationIntegrationIdAndCompanyId: IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByProductIntegrationIntegrationIdAndProductId: IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Product`. */
  productsByFieldConfigIntegrationIdAndProductId: IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByFieldConfigIntegrationIdAndChannelId: IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyConnection;
};


export type IntegrationCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


export type IntegrationProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


export type IntegrationFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type IntegrationProductsByFieldConfigIntegrationIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};

export type IntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationVariancePopulationAggregates>;
};

export type IntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values, with data from `FieldConfig`. */
export type IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values, with data from `FieldConfig`. */
export type IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection, with data from `FieldConfig`. */
export type IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Channel` edge in the connection, with data from `FieldConfig`. */
export type IntegrationChannelsByFieldConfigIntegrationIdAndChannelIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

/** A connection to a list of `Company` values, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyIntegration`, and the cursor to aid in pagination. */
  edges: Array<IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations: CompanyIntegrationsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyIntegration`. */
export type IntegrationCompaniesByCompanyIntegrationIntegrationIdAndCompanyIdManyToManyEdgeCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};

/**
 * A condition to be used against `Integration` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
};

export type IntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Integration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `companyIntegrations` relation. */
  companyIntegrations?: Maybe<IntegrationToManyCompanyIntegrationFilter>;
  /** Some related `companyIntegrations` exist. */
  companyIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productIntegrations` relation. */
  productIntegrations?: Maybe<IntegrationToManyProductIntegrationFilter>;
  /** Some related `productIntegrations` exist. */
  productIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldConfigs` relation. */
  fieldConfigs?: Maybe<IntegrationToManyFieldConfigFilter>;
  /** Some related `fieldConfigs` exist. */
  fieldConfigsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<IntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<IntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<IntegrationFilter>;
};

/** Grouping methods for `Integration` for usage during aggregation. */
export enum IntegrationGroupBy {
  Name = 'NAME',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  DisplayName = 'DISPLAY_NAME'
}

export type IntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Integration` aggregates. */
export type IntegrationHavingInput = {
  AND?: Maybe<Array<IntegrationHavingInput>>;
  OR?: Maybe<Array<IntegrationHavingInput>>;
  sum?: Maybe<IntegrationHavingSumInput>;
  distinctCount?: Maybe<IntegrationHavingDistinctCountInput>;
  min?: Maybe<IntegrationHavingMinInput>;
  max?: Maybe<IntegrationHavingMaxInput>;
  average?: Maybe<IntegrationHavingAverageInput>;
  stddevSample?: Maybe<IntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<IntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<IntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<IntegrationHavingVariancePopulationInput>;
};

export type IntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type IntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Integration` */
export type IntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** The fields on `integration` to look up the row to connect. */
export type IntegrationIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `integration` to look up the row to delete. */
export type IntegrationIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

export type IntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type IntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type IntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type IntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `integration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** The fields on `integration` to look up the row to update. */
export type IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: UpdateIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Integration`. Fields that are set will be updated. */
export type IntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** A connection to a list of `Product` values, with data from `FieldConfig`. */
export type IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `FieldConfig`. */
export type IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `FieldConfig`. */
export type IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Product` edge in the connection, with data from `FieldConfig`. */
export type IntegrationProductsByFieldConfigIntegrationIdAndProductIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

/** A connection to a list of `Product` values, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `ProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
};


/** A `Product` edge in the connection, with data from `ProductIntegration`. */
export type IntegrationProductsByProductIntegrationIntegrationIdAndProductIdManyToManyEdgeProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};

export type IntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyIntegration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyCompanyIntegrationFilter = {
  /** Every related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyIntegrationFilter>;
  /** Some related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyIntegrationFilter>;
  /** No related `CompanyIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyIntegrationFilter>;
  /** Aggregates across related `CompanyIntegration` match the filter criteria. */
  aggregates?: Maybe<CompanyIntegrationAggregatesFilter>;
};

/** A filter to be used against many `FieldConfig` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyFieldConfigFilter = {
  /** Every related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldConfigFilter>;
  /** Some related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldConfigFilter>;
  /** No related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldConfigFilter>;
  /** Aggregates across related `FieldConfig` match the filter criteria. */
  aggregates?: Maybe<FieldConfigAggregatesFilter>;
};

/** A filter to be used against many `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyProductIntegrationFilter = {
  /** Every related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProductIntegrationFilter>;
  /** Some related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProductIntegrationFilter>;
  /** No related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProductIntegrationFilter>;
  /** Aggregates across related `ProductIntegration` match the filter criteria. */
  aggregates?: Maybe<ProductIntegrationAggregatesFilter>;
};

export type IntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values. */
export type IntegrationsConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration` and cursor to aid in pagination. */
  edges: Array<IntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values. */
export type IntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection. */
export type IntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
};

/** Methods to use when ordering `Integration`. */
export enum IntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyIntegrationsCountAsc = 'COMPANY_INTEGRATIONS_COUNT_ASC',
  CompanyIntegrationsCountDesc = 'COMPANY_INTEGRATIONS_COUNT_DESC',
  CompanyIntegrationsSumIdAsc = 'COMPANY_INTEGRATIONS_SUM_ID_ASC',
  CompanyIntegrationsSumIdDesc = 'COMPANY_INTEGRATIONS_SUM_ID_DESC',
  CompanyIntegrationsSumIntegrationIdAsc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  CompanyIntegrationsSumIntegrationIdDesc = 'COMPANY_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  CompanyIntegrationsSumCompanyIdAsc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_ASC',
  CompanyIntegrationsSumCompanyIdDesc = 'COMPANY_INTEGRATIONS_SUM_COMPANY_ID_DESC',
  CompanyIntegrationsSumEnabledAsc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_ASC',
  CompanyIntegrationsSumEnabledDesc = 'COMPANY_INTEGRATIONS_SUM_ENABLED_DESC',
  CompanyIntegrationsSumCreatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_ASC',
  CompanyIntegrationsSumCreatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_CREATED_AT_DESC',
  CompanyIntegrationsSumUpdatedAtAsc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  CompanyIntegrationsSumUpdatedAtDesc = 'COMPANY_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  CompanyIntegrationsDistinctCountIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  CompanyIntegrationsDistinctCountIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  CompanyIntegrationsDistinctCountIntegrationIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  CompanyIntegrationsDistinctCountIntegrationIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  CompanyIntegrationsDistinctCountCompanyIdAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyIntegrationsDistinctCountCompanyIdDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyIntegrationsDistinctCountEnabledAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyIntegrationsDistinctCountEnabledDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyIntegrationsDistinctCountCreatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyIntegrationsDistinctCountCreatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyIntegrationsDistinctCountUpdatedAtAsc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyIntegrationsDistinctCountUpdatedAtDesc = 'COMPANY_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyIntegrationsMinIdAsc = 'COMPANY_INTEGRATIONS_MIN_ID_ASC',
  CompanyIntegrationsMinIdDesc = 'COMPANY_INTEGRATIONS_MIN_ID_DESC',
  CompanyIntegrationsMinIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  CompanyIntegrationsMinIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  CompanyIntegrationsMinCompanyIdAsc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_ASC',
  CompanyIntegrationsMinCompanyIdDesc = 'COMPANY_INTEGRATIONS_MIN_COMPANY_ID_DESC',
  CompanyIntegrationsMinEnabledAsc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_ASC',
  CompanyIntegrationsMinEnabledDesc = 'COMPANY_INTEGRATIONS_MIN_ENABLED_DESC',
  CompanyIntegrationsMinCreatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_ASC',
  CompanyIntegrationsMinCreatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_CREATED_AT_DESC',
  CompanyIntegrationsMinUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  CompanyIntegrationsMinUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  CompanyIntegrationsMaxIdAsc = 'COMPANY_INTEGRATIONS_MAX_ID_ASC',
  CompanyIntegrationsMaxIdDesc = 'COMPANY_INTEGRATIONS_MAX_ID_DESC',
  CompanyIntegrationsMaxIntegrationIdAsc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  CompanyIntegrationsMaxIntegrationIdDesc = 'COMPANY_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  CompanyIntegrationsMaxCompanyIdAsc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_ASC',
  CompanyIntegrationsMaxCompanyIdDesc = 'COMPANY_INTEGRATIONS_MAX_COMPANY_ID_DESC',
  CompanyIntegrationsMaxEnabledAsc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_ASC',
  CompanyIntegrationsMaxEnabledDesc = 'COMPANY_INTEGRATIONS_MAX_ENABLED_DESC',
  CompanyIntegrationsMaxCreatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_ASC',
  CompanyIntegrationsMaxCreatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_CREATED_AT_DESC',
  CompanyIntegrationsMaxUpdatedAtAsc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  CompanyIntegrationsMaxUpdatedAtDesc = 'COMPANY_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  CompanyIntegrationsAverageIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_ASC',
  CompanyIntegrationsAverageIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ID_DESC',
  CompanyIntegrationsAverageIntegrationIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  CompanyIntegrationsAverageIntegrationIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  CompanyIntegrationsAverageCompanyIdAsc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_ASC',
  CompanyIntegrationsAverageCompanyIdDesc = 'COMPANY_INTEGRATIONS_AVERAGE_COMPANY_ID_DESC',
  CompanyIntegrationsAverageEnabledAsc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  CompanyIntegrationsAverageEnabledDesc = 'COMPANY_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  CompanyIntegrationsAverageCreatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  CompanyIntegrationsAverageCreatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  CompanyIntegrationsAverageUpdatedAtAsc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  CompanyIntegrationsAverageUpdatedAtDesc = 'COMPANY_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevSampleIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  CompanyIntegrationsStddevSampleIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  CompanyIntegrationsStddevSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsStddevSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsStddevSampleEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsStddevSampleEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsStddevSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsStddevSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsStddevSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsStddevSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsStddevPopulationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  CompanyIntegrationsStddevPopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsStddevPopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsStddevPopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsStddevPopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsStddevPopulationEnabledAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyIntegrationsStddevPopulationEnabledDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyIntegrationsStddevPopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsStddevPopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsStddevPopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsStddevPopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyIntegrationsVarianceSampleIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  CompanyIntegrationsVarianceSampleIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  CompanyIntegrationsVarianceSampleIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  CompanyIntegrationsVarianceSampleIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  CompanyIntegrationsVarianceSampleCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyIntegrationsVarianceSampleCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyIntegrationsVarianceSampleEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyIntegrationsVarianceSampleEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyIntegrationsVarianceSampleCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyIntegrationsVarianceSampleCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyIntegrationsVarianceSampleUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyIntegrationsVarianceSampleUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIntegrationsVariancePopulationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  CompanyIntegrationsVariancePopulationIntegrationIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  CompanyIntegrationsVariancePopulationIntegrationIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  CompanyIntegrationsVariancePopulationCompanyIdAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyIntegrationsVariancePopulationCompanyIdDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyIntegrationsVariancePopulationEnabledAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyIntegrationsVariancePopulationEnabledDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyIntegrationsVariancePopulationCreatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyIntegrationsVariancePopulationCreatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyIntegrationsVariancePopulationUpdatedAtAsc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyIntegrationsVariancePopulationUpdatedAtDesc = 'COMPANY_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsCountAsc = 'PRODUCT_INTEGRATIONS_COUNT_ASC',
  ProductIntegrationsCountDesc = 'PRODUCT_INTEGRATIONS_COUNT_DESC',
  ProductIntegrationsSumIdAsc = 'PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  ProductIntegrationsSumIdDesc = 'PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  ProductIntegrationsSumIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  ProductIntegrationsSumIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  ProductIntegrationsSumProductIdAsc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_ASC',
  ProductIntegrationsSumProductIdDesc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_DESC',
  ProductIntegrationsSumEnabledAsc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  ProductIntegrationsSumEnabledDesc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  ProductIntegrationsSumCreatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  ProductIntegrationsSumCreatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  ProductIntegrationsSumUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  ProductIntegrationsSumUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  ProductIntegrationsDistinctCountIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  ProductIntegrationsDistinctCountIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  ProductIntegrationsDistinctCountIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  ProductIntegrationsDistinctCountIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  ProductIntegrationsDistinctCountProductIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  ProductIntegrationsDistinctCountProductIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  ProductIntegrationsDistinctCountEnabledAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  ProductIntegrationsDistinctCountEnabledDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  ProductIntegrationsDistinctCountCreatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  ProductIntegrationsDistinctCountCreatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  ProductIntegrationsDistinctCountUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProductIntegrationsDistinctCountUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProductIntegrationsMinIdAsc = 'PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  ProductIntegrationsMinIdDesc = 'PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  ProductIntegrationsMinIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  ProductIntegrationsMinIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  ProductIntegrationsMinProductIdAsc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_ASC',
  ProductIntegrationsMinProductIdDesc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_DESC',
  ProductIntegrationsMinEnabledAsc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  ProductIntegrationsMinEnabledDesc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  ProductIntegrationsMinCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  ProductIntegrationsMinCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  ProductIntegrationsMinUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  ProductIntegrationsMinUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  ProductIntegrationsMaxIdAsc = 'PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  ProductIntegrationsMaxIdDesc = 'PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  ProductIntegrationsMaxIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  ProductIntegrationsMaxIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  ProductIntegrationsMaxProductIdAsc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_ASC',
  ProductIntegrationsMaxProductIdDesc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_DESC',
  ProductIntegrationsMaxEnabledAsc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  ProductIntegrationsMaxEnabledDesc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  ProductIntegrationsMaxCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  ProductIntegrationsMaxCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  ProductIntegrationsMaxUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  ProductIntegrationsMaxUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  ProductIntegrationsAverageIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  ProductIntegrationsAverageIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  ProductIntegrationsAverageIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  ProductIntegrationsAverageIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  ProductIntegrationsAverageProductIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_ASC',
  ProductIntegrationsAverageProductIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_DESC',
  ProductIntegrationsAverageEnabledAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  ProductIntegrationsAverageEnabledDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  ProductIntegrationsAverageCreatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  ProductIntegrationsAverageCreatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  ProductIntegrationsAverageUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  ProductIntegrationsAverageUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  ProductIntegrationsStddevSampleIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  ProductIntegrationsStddevSampleIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  ProductIntegrationsStddevSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsStddevSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsStddevSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  ProductIntegrationsStddevSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  ProductIntegrationsStddevSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsStddevSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsStddevSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsStddevSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsStddevPopulationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  ProductIntegrationsStddevPopulationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  ProductIntegrationsStddevPopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevPopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevPopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsStddevPopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsStddevPopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  ProductIntegrationsStddevPopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  ProductIntegrationsStddevPopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsStddevPopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsStddevPopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsStddevPopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsVarianceSampleIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  ProductIntegrationsVarianceSampleIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  ProductIntegrationsVarianceSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsVarianceSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsVarianceSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsVarianceSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsVarianceSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  ProductIntegrationsVarianceSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  ProductIntegrationsVarianceSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsVarianceSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsVarianceSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsVarianceSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsVariancePopulationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  ProductIntegrationsVariancePopulationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  ProductIntegrationsVariancePopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsVariancePopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsVariancePopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsVariancePopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsVariancePopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  ProductIntegrationsVariancePopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  ProductIntegrationsVariancePopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsVariancePopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsVariancePopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsVariancePopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldConfigsCountAsc = 'FIELD_CONFIGS_COUNT_ASC',
  FieldConfigsCountDesc = 'FIELD_CONFIGS_COUNT_DESC',
  FieldConfigsSumIdAsc = 'FIELD_CONFIGS_SUM_ID_ASC',
  FieldConfigsSumIdDesc = 'FIELD_CONFIGS_SUM_ID_DESC',
  FieldConfigsSumProductIdAsc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_ASC',
  FieldConfigsSumProductIdDesc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_DESC',
  FieldConfigsSumChannelIdAsc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_ASC',
  FieldConfigsSumChannelIdDesc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_DESC',
  FieldConfigsSumIntegrationIdAsc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_ASC',
  FieldConfigsSumIntegrationIdDesc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_DESC',
  FieldConfigsSumTypeAsc = 'FIELD_CONFIGS_SUM_TYPE_ASC',
  FieldConfigsSumTypeDesc = 'FIELD_CONFIGS_SUM_TYPE_DESC',
  FieldConfigsSumLabelAsc = 'FIELD_CONFIGS_SUM_LABEL_ASC',
  FieldConfigsSumLabelDesc = 'FIELD_CONFIGS_SUM_LABEL_DESC',
  FieldConfigsSumHelperAsc = 'FIELD_CONFIGS_SUM_HELPER_ASC',
  FieldConfigsSumHelperDesc = 'FIELD_CONFIGS_SUM_HELPER_DESC',
  FieldConfigsSumKeyAsc = 'FIELD_CONFIGS_SUM_KEY_ASC',
  FieldConfigsSumKeyDesc = 'FIELD_CONFIGS_SUM_KEY_DESC',
  FieldConfigsSumPublicAsc = 'FIELD_CONFIGS_SUM_PUBLIC_ASC',
  FieldConfigsSumPublicDesc = 'FIELD_CONFIGS_SUM_PUBLIC_DESC',
  FieldConfigsSumCreatedAtAsc = 'FIELD_CONFIGS_SUM_CREATED_AT_ASC',
  FieldConfigsSumCreatedAtDesc = 'FIELD_CONFIGS_SUM_CREATED_AT_DESC',
  FieldConfigsSumUpdatedAtAsc = 'FIELD_CONFIGS_SUM_UPDATED_AT_ASC',
  FieldConfigsSumUpdatedAtDesc = 'FIELD_CONFIGS_SUM_UPDATED_AT_DESC',
  FieldConfigsDistinctCountIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_ASC',
  FieldConfigsDistinctCountIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_DESC',
  FieldConfigsDistinctCountProductIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  FieldConfigsDistinctCountProductIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  FieldConfigsDistinctCountChannelIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  FieldConfigsDistinctCountChannelIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  FieldConfigsDistinctCountIntegrationIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  FieldConfigsDistinctCountIntegrationIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  FieldConfigsDistinctCountTypeAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_ASC',
  FieldConfigsDistinctCountTypeDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_DESC',
  FieldConfigsDistinctCountLabelAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_ASC',
  FieldConfigsDistinctCountLabelDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_DESC',
  FieldConfigsDistinctCountHelperAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_ASC',
  FieldConfigsDistinctCountHelperDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_DESC',
  FieldConfigsDistinctCountKeyAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_ASC',
  FieldConfigsDistinctCountKeyDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_DESC',
  FieldConfigsDistinctCountPublicAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_ASC',
  FieldConfigsDistinctCountPublicDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_DESC',
  FieldConfigsDistinctCountCreatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldConfigsDistinctCountCreatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldConfigsDistinctCountUpdatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldConfigsDistinctCountUpdatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldConfigsMinIdAsc = 'FIELD_CONFIGS_MIN_ID_ASC',
  FieldConfigsMinIdDesc = 'FIELD_CONFIGS_MIN_ID_DESC',
  FieldConfigsMinProductIdAsc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_ASC',
  FieldConfigsMinProductIdDesc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_DESC',
  FieldConfigsMinChannelIdAsc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_ASC',
  FieldConfigsMinChannelIdDesc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_DESC',
  FieldConfigsMinIntegrationIdAsc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_ASC',
  FieldConfigsMinIntegrationIdDesc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_DESC',
  FieldConfigsMinTypeAsc = 'FIELD_CONFIGS_MIN_TYPE_ASC',
  FieldConfigsMinTypeDesc = 'FIELD_CONFIGS_MIN_TYPE_DESC',
  FieldConfigsMinLabelAsc = 'FIELD_CONFIGS_MIN_LABEL_ASC',
  FieldConfigsMinLabelDesc = 'FIELD_CONFIGS_MIN_LABEL_DESC',
  FieldConfigsMinHelperAsc = 'FIELD_CONFIGS_MIN_HELPER_ASC',
  FieldConfigsMinHelperDesc = 'FIELD_CONFIGS_MIN_HELPER_DESC',
  FieldConfigsMinKeyAsc = 'FIELD_CONFIGS_MIN_KEY_ASC',
  FieldConfigsMinKeyDesc = 'FIELD_CONFIGS_MIN_KEY_DESC',
  FieldConfigsMinPublicAsc = 'FIELD_CONFIGS_MIN_PUBLIC_ASC',
  FieldConfigsMinPublicDesc = 'FIELD_CONFIGS_MIN_PUBLIC_DESC',
  FieldConfigsMinCreatedAtAsc = 'FIELD_CONFIGS_MIN_CREATED_AT_ASC',
  FieldConfigsMinCreatedAtDesc = 'FIELD_CONFIGS_MIN_CREATED_AT_DESC',
  FieldConfigsMinUpdatedAtAsc = 'FIELD_CONFIGS_MIN_UPDATED_AT_ASC',
  FieldConfigsMinUpdatedAtDesc = 'FIELD_CONFIGS_MIN_UPDATED_AT_DESC',
  FieldConfigsMaxIdAsc = 'FIELD_CONFIGS_MAX_ID_ASC',
  FieldConfigsMaxIdDesc = 'FIELD_CONFIGS_MAX_ID_DESC',
  FieldConfigsMaxProductIdAsc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_ASC',
  FieldConfigsMaxProductIdDesc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_DESC',
  FieldConfigsMaxChannelIdAsc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_ASC',
  FieldConfigsMaxChannelIdDesc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_DESC',
  FieldConfigsMaxIntegrationIdAsc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_ASC',
  FieldConfigsMaxIntegrationIdDesc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_DESC',
  FieldConfigsMaxTypeAsc = 'FIELD_CONFIGS_MAX_TYPE_ASC',
  FieldConfigsMaxTypeDesc = 'FIELD_CONFIGS_MAX_TYPE_DESC',
  FieldConfigsMaxLabelAsc = 'FIELD_CONFIGS_MAX_LABEL_ASC',
  FieldConfigsMaxLabelDesc = 'FIELD_CONFIGS_MAX_LABEL_DESC',
  FieldConfigsMaxHelperAsc = 'FIELD_CONFIGS_MAX_HELPER_ASC',
  FieldConfigsMaxHelperDesc = 'FIELD_CONFIGS_MAX_HELPER_DESC',
  FieldConfigsMaxKeyAsc = 'FIELD_CONFIGS_MAX_KEY_ASC',
  FieldConfigsMaxKeyDesc = 'FIELD_CONFIGS_MAX_KEY_DESC',
  FieldConfigsMaxPublicAsc = 'FIELD_CONFIGS_MAX_PUBLIC_ASC',
  FieldConfigsMaxPublicDesc = 'FIELD_CONFIGS_MAX_PUBLIC_DESC',
  FieldConfigsMaxCreatedAtAsc = 'FIELD_CONFIGS_MAX_CREATED_AT_ASC',
  FieldConfigsMaxCreatedAtDesc = 'FIELD_CONFIGS_MAX_CREATED_AT_DESC',
  FieldConfigsMaxUpdatedAtAsc = 'FIELD_CONFIGS_MAX_UPDATED_AT_ASC',
  FieldConfigsMaxUpdatedAtDesc = 'FIELD_CONFIGS_MAX_UPDATED_AT_DESC',
  FieldConfigsAverageIdAsc = 'FIELD_CONFIGS_AVERAGE_ID_ASC',
  FieldConfigsAverageIdDesc = 'FIELD_CONFIGS_AVERAGE_ID_DESC',
  FieldConfigsAverageProductIdAsc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_ASC',
  FieldConfigsAverageProductIdDesc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_DESC',
  FieldConfigsAverageChannelIdAsc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_ASC',
  FieldConfigsAverageChannelIdDesc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_DESC',
  FieldConfigsAverageIntegrationIdAsc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_ASC',
  FieldConfigsAverageIntegrationIdDesc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_DESC',
  FieldConfigsAverageTypeAsc = 'FIELD_CONFIGS_AVERAGE_TYPE_ASC',
  FieldConfigsAverageTypeDesc = 'FIELD_CONFIGS_AVERAGE_TYPE_DESC',
  FieldConfigsAverageLabelAsc = 'FIELD_CONFIGS_AVERAGE_LABEL_ASC',
  FieldConfigsAverageLabelDesc = 'FIELD_CONFIGS_AVERAGE_LABEL_DESC',
  FieldConfigsAverageHelperAsc = 'FIELD_CONFIGS_AVERAGE_HELPER_ASC',
  FieldConfigsAverageHelperDesc = 'FIELD_CONFIGS_AVERAGE_HELPER_DESC',
  FieldConfigsAverageKeyAsc = 'FIELD_CONFIGS_AVERAGE_KEY_ASC',
  FieldConfigsAverageKeyDesc = 'FIELD_CONFIGS_AVERAGE_KEY_DESC',
  FieldConfigsAveragePublicAsc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_ASC',
  FieldConfigsAveragePublicDesc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_DESC',
  FieldConfigsAverageCreatedAtAsc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_ASC',
  FieldConfigsAverageCreatedAtDesc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_DESC',
  FieldConfigsAverageUpdatedAtAsc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_ASC',
  FieldConfigsAverageUpdatedAtDesc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_DESC',
  FieldConfigsStddevSampleIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_ASC',
  FieldConfigsStddevSampleIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_DESC',
  FieldConfigsStddevSampleProductIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsStddevSampleProductIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsStddevSampleChannelIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsStddevSampleChannelIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsStddevSampleIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsStddevSampleIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsStddevSampleTypeAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_ASC',
  FieldConfigsStddevSampleTypeDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_DESC',
  FieldConfigsStddevSampleLabelAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_ASC',
  FieldConfigsStddevSampleLabelDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_DESC',
  FieldConfigsStddevSampleHelperAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_ASC',
  FieldConfigsStddevSampleHelperDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_DESC',
  FieldConfigsStddevSampleKeyAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_ASC',
  FieldConfigsStddevSampleKeyDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_DESC',
  FieldConfigsStddevSamplePublicAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_ASC',
  FieldConfigsStddevSamplePublicDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_DESC',
  FieldConfigsStddevSampleCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldConfigsStddevSampleCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldConfigsStddevSampleUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsStddevSampleUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsStddevPopulationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_ASC',
  FieldConfigsStddevPopulationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_DESC',
  FieldConfigsStddevPopulationProductIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsStddevPopulationProductIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsStddevPopulationChannelIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsStddevPopulationChannelIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsStddevPopulationIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsStddevPopulationIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsStddevPopulationTypeAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_ASC',
  FieldConfigsStddevPopulationTypeDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_DESC',
  FieldConfigsStddevPopulationLabelAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_ASC',
  FieldConfigsStddevPopulationLabelDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_DESC',
  FieldConfigsStddevPopulationHelperAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_ASC',
  FieldConfigsStddevPopulationHelperDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_DESC',
  FieldConfigsStddevPopulationKeyAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_ASC',
  FieldConfigsStddevPopulationKeyDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_DESC',
  FieldConfigsStddevPopulationPublicAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_ASC',
  FieldConfigsStddevPopulationPublicDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_DESC',
  FieldConfigsStddevPopulationCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldConfigsStddevPopulationCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldConfigsStddevPopulationUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldConfigsStddevPopulationUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldConfigsVarianceSampleIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_ASC',
  FieldConfigsVarianceSampleIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_DESC',
  FieldConfigsVarianceSampleProductIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsVarianceSampleProductIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsVarianceSampleChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsVarianceSampleChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsVarianceSampleIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsVarianceSampleIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsVarianceSampleTypeAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_ASC',
  FieldConfigsVarianceSampleTypeDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_DESC',
  FieldConfigsVarianceSampleLabelAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_ASC',
  FieldConfigsVarianceSampleLabelDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_DESC',
  FieldConfigsVarianceSampleHelperAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_ASC',
  FieldConfigsVarianceSampleHelperDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_DESC',
  FieldConfigsVarianceSampleKeyAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_ASC',
  FieldConfigsVarianceSampleKeyDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_DESC',
  FieldConfigsVarianceSamplePublicAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_ASC',
  FieldConfigsVarianceSamplePublicDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_DESC',
  FieldConfigsVarianceSampleCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldConfigsVarianceSampleCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldConfigsVarianceSampleUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsVarianceSampleUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsVariancePopulationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_ASC',
  FieldConfigsVariancePopulationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_DESC',
  FieldConfigsVariancePopulationProductIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsVariancePopulationProductIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsVariancePopulationChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsVariancePopulationChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsVariancePopulationIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsVariancePopulationIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsVariancePopulationTypeAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_ASC',
  FieldConfigsVariancePopulationTypeDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_DESC',
  FieldConfigsVariancePopulationLabelAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_ASC',
  FieldConfigsVariancePopulationLabelDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_DESC',
  FieldConfigsVariancePopulationHelperAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_ASC',
  FieldConfigsVariancePopulationHelperDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_DESC',
  FieldConfigsVariancePopulationKeyAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_ASC',
  FieldConfigsVariancePopulationKeyDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_DESC',
  FieldConfigsVariancePopulationPublicAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_ASC',
  FieldConfigsVariancePopulationPublicDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_DESC',
  FieldConfigsVariancePopulationCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldConfigsVariancePopulationCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldConfigsVariancePopulationUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldConfigsVariancePopulationUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type InteractiveQuery = {
  queryId?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<CreditQuestion>>;
};


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};

export enum JourneyStage {
  Partial = 'PARTIAL',
  Credit = 'CREDIT',
  CreditScore = 'CREDIT_SCORE',
  CreditFull = 'CREDIT_FULL',
  Trade = 'TRADE',
  LenderApp = 'LENDER_APP',
  BuyPartial = 'BUY_PARTIAL',
  IdPartial = 'ID_PARTIAL',
  IdComplete = 'ID_COMPLETE',
  IdPartialAdmin = 'ID_PARTIAL_ADMIN',
  ReviewPartial = 'REVIEW_PARTIAL',
  ReviewComplete = 'REVIEW_COMPLETE',
  DrivePartial = 'DRIVE_PARTIAL',
  DriveComplete = 'DRIVE_COMPLETE',
  IncomeSmsSent = 'INCOME_SMS_SENT',
  IncomeLinkOpened = 'INCOME_LINK_OPENED',
  IncomeVoidCheckSelected = 'INCOME_VOID_CHECK_SELECTED',
  IncomeComplete = 'INCOME_COMPLETE',
  IdSmsSent = 'ID_SMS_SENT',
  IdLinkOpened = 'ID_LINK_OPENED',
  IdInitiated = 'ID_INITIATED',
  CreditConsentRequested = 'CREDIT_CONSENT_REQUESTED',
  CreditConsentSmsSent = 'CREDIT_CONSENT_SMS_SENT',
  CreditConsentLinkOpened = 'CREDIT_CONSENT_LINK_OPENED',
  CreditConsentAccepted = 'CREDIT_CONSENT_ACCEPTED',
  CreditConsentDeclined = 'CREDIT_CONSENT_DECLINED'
}

/** A filter to be used against JourneyStage fields. All fields are combined with a logical ‘and.’ */
export type JourneyStageFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<JourneyStage>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<JourneyStage>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<JourneyStage>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<JourneyStage>;
  /** Included in the specified list. */
  in?: Maybe<Array<JourneyStage>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<JourneyStage>>;
  /** Less than the specified value. */
  lessThan?: Maybe<JourneyStage>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<JourneyStage>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<JourneyStage>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<JourneyStage>;
};

export type Lead = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId: Scalars['Int'];
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** Reads a single `AvaApp` that is related to this `Lead`. */
  avaApp?: Maybe<AvaApp>;
  /** Reads a single `CreditApp` that is related to this `Lead`. */
  creditApp?: Maybe<CreditApp>;
  /** Reads a single `LeadCampaign` that is related to this `Lead`. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Reads and enables pagination through a set of `TradeApp`. */
  tradeApps: TradeAppsConnection;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
  /** Reads a single `IdVerification` that is related to this `Lead`. */
  idVerification?: Maybe<IdVerification>;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
  /** Reads a single `IncomeVerification` that is related to this `Lead`. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `Event`. */
  events: EventsConnection;
  journeyStages?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Product`. */
  productsByLeadJourneyLeadIdAndProductId: LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnection;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListingsByLenderAppLeadIdAndVehicleListingId: LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListingsByAppointmentLeadIdAndVehicleListingId: LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnection;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderAppsByLenderQuoteLeadIdAndLenderAppId: LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnection;
  /** Full-text search ranking when filtered by `searchText`. */
  searchTextRank?: Maybe<Scalars['Float']>;
  retrieveIdVerification?: Maybe<TransactionResponse>;
  idVerificationTemplateType?: Maybe<IdToolTemplateType>;
};


export type LeadTradeAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
  condition?: Maybe<TradeAppCondition>;
  filter?: Maybe<TradeAppFilter>;
};


export type LeadLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


export type LeadLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type LeadAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


export type LeadLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type LeadEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


export type LeadProductsByLeadJourneyLeadIdAndProductIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};

export type LeadAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Lead` object types. */
export type LeadAggregatesFilter = {
  /** A filter that must pass for the relevant `Lead` object to be included within the aggregate. */
  filter?: Maybe<LeadFilter>;
  /** Sum aggregate over matching `Lead` objects. */
  sum?: Maybe<LeadSumAggregateFilter>;
  /** Distinct count aggregate over matching `Lead` objects. */
  distinctCount?: Maybe<LeadDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Lead` objects. */
  min?: Maybe<LeadMinAggregateFilter>;
  /** Maximum aggregate over matching `Lead` objects. */
  max?: Maybe<LeadMaxAggregateFilter>;
  /** Mean average aggregate over matching `Lead` objects. */
  average?: Maybe<LeadAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Lead` objects. */
  stddevSample?: Maybe<LeadStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Lead` objects. */
  stddevPopulation?: Maybe<LeadStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Lead` objects. */
  varianceSample?: Maybe<LeadVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Lead` objects. */
  variancePopulation?: Maybe<LeadVariancePopulationAggregateFilter>;
};

export type LeadAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
};

export type LeadAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaign = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
};

export type LeadCampaignAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadCampaignSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadCampaignDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadCampaignMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadCampaignMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadCampaignAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadCampaignStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadCampaignStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadCampaignVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadCampaignVariancePopulationAggregates>;
};

export type LeadCampaignAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LeadCampaign` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LeadCampaignCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `utmCampaign` field. */
  utmCampaign?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmContent` field. */
  utmContent?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmMedium` field. */
  utmMedium?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `utmSource` field. */
  utmSource?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s1` field. */
  s1?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s2` field. */
  s2?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s3` field. */
  s3?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s4` field. */
  s4?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `s5` field. */
  s5?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LeadCampaignDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmCampaign across the matching connection */
  utmCampaign?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmContent across the matching connection */
  utmContent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmMedium across the matching connection */
  utmMedium?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utmSource across the matching connection */
  utmSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s1 across the matching connection */
  s1?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s2 across the matching connection */
  s2?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s3 across the matching connection */
  s3?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s4 across the matching connection */
  s4?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s5 across the matching connection */
  s5?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LeadCampaign` object types. All fields are combined with a logical ‘and.’ */
export type LeadCampaignFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `utmCampaign` field. */
  utmCampaign?: Maybe<StringFilter>;
  /** Filter by the object’s `utmContent` field. */
  utmContent?: Maybe<StringFilter>;
  /** Filter by the object’s `utmMedium` field. */
  utmMedium?: Maybe<StringFilter>;
  /** Filter by the object’s `utmSource` field. */
  utmSource?: Maybe<StringFilter>;
  /** Filter by the object’s `s1` field. */
  s1?: Maybe<StringFilter>;
  /** Filter by the object’s `s2` field. */
  s2?: Maybe<StringFilter>;
  /** Filter by the object’s `s3` field. */
  s3?: Maybe<StringFilter>;
  /** Filter by the object’s `s4` field. */
  s4?: Maybe<StringFilter>;
  /** Filter by the object’s `s5` field. */
  s5?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** A related `lead` exists. */
  leadExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadCampaignFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadCampaignFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadCampaignFilter>;
};

/** An input for mutations affecting `LeadCampaign` */
export type LeadCampaignInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

export type LeadCampaignInputType = {
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
};

/** The fields on `leadCampaign` to look up the row to connect. */
export type LeadCampaignLeadCampaignsLeadIdKeyConnect = {
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to delete. */
export type LeadCampaignLeadCampaignsLeadIdKeyDelete = {
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to connect. */
export type LeadCampaignLeadCampaignsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `leadCampaign` to look up the row to delete. */
export type LeadCampaignLeadCampaignsPkeyDelete = {
  id: Scalars['Int'];
};

export type LeadCampaignMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type LeadCampaignMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LeadCampaignNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LeadCampaignNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `leadCampaign` to look up the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsLeadIdKeyUpdate = {
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  leadId: Scalars['UUID'];
};

/** The fields on `leadCampaign` to look up the row to update. */
export type LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsPkeyUpdate = {
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LeadCampaign`. Fields that are set will be updated. */
export type LeadCampaignPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

export type LeadCampaignStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type LeadCampaignVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type LeadCampaignVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LeadCampaign` values. */
export type LeadCampaignsConnection = {
  /** A list of `LeadCampaign` objects. */
  nodes: Array<LeadCampaign>;
  /** A list of edges which contains the `LeadCampaign` and cursor to aid in pagination. */
  edges: Array<LeadCampaignsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeadCampaign` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadCampaignAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadCampaignAggregates>>;
};


/** A connection to a list of `LeadCampaign` values. */
export type LeadCampaignsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadCampaignsGroupBy>;
  having?: Maybe<LeadCampaignsHavingInput>;
};

/** A `LeadCampaign` edge in the connection. */
export type LeadCampaignsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeadCampaign` at the end of the edge. */
  node: LeadCampaign;
};

/** Grouping methods for `LeadCampaign` for usage during aggregation. */
export enum LeadCampaignsGroupBy {
  UtmCampaign = 'UTM_CAMPAIGN',
  UtmContent = 'UTM_CONTENT',
  UtmMedium = 'UTM_MEDIUM',
  UtmSource = 'UTM_SOURCE',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
  S5 = 'S5',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LeadCampaignsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LeadCampaign` aggregates. */
export type LeadCampaignsHavingInput = {
  AND?: Maybe<Array<LeadCampaignsHavingInput>>;
  OR?: Maybe<Array<LeadCampaignsHavingInput>>;
  sum?: Maybe<LeadCampaignsHavingSumInput>;
  distinctCount?: Maybe<LeadCampaignsHavingDistinctCountInput>;
  min?: Maybe<LeadCampaignsHavingMinInput>;
  max?: Maybe<LeadCampaignsHavingMaxInput>;
  average?: Maybe<LeadCampaignsHavingAverageInput>;
  stddevSample?: Maybe<LeadCampaignsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadCampaignsHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadCampaignsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadCampaignsHavingVariancePopulationInput>;
};

export type LeadCampaignsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadCampaignsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `lead` in the `LeadCampaignInput` mutation. */
export type LeadCampaignsLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LeadCampaignsLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `leadCampaign` in the `LeadInput` mutation. */
export type LeadCampaignsLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `leadCampaign` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectById?: Maybe<LeadCampaignLeadCampaignsPkeyConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectByLeadId?: Maybe<LeadCampaignLeadCampaignsLeadIdKeyConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadCampaignNodeIdConnect>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteById?: Maybe<LeadCampaignLeadCampaignsPkeyDelete>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteByLeadId?: Maybe<LeadCampaignLeadCampaignsLeadIdKeyDelete>;
  /** The primary key(s) for `leadCampaign` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadCampaignNodeIdDelete>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateById?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsPkeyUpdate>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateByLeadId?: Maybe<LeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadCampaignsLeadIdKeyUpdate>;
  /** The primary key(s) and patch data for `leadCampaign` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate>;
  /** A `LeadCampaignInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadCampaignsLeadIdFkeyLeadCampaignsCreateInput>>;
};

/** The `leadCampaign` to be created by this mutation. */
export type LeadCampaignsLeadIdFkeyLeadCampaignsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

/** The `lead` to be created by this mutation. */
export type LeadCampaignsLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** Methods to use when ordering `LeadCampaign`. */
export enum LeadCampaignsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  UtmCampaignAsc = 'UTM_CAMPAIGN_ASC',
  UtmCampaignDesc = 'UTM_CAMPAIGN_DESC',
  UtmContentAsc = 'UTM_CONTENT_ASC',
  UtmContentDesc = 'UTM_CONTENT_DESC',
  UtmMediumAsc = 'UTM_MEDIUM_ASC',
  UtmMediumDesc = 'UTM_MEDIUM_DESC',
  UtmSourceAsc = 'UTM_SOURCE_ASC',
  UtmSourceDesc = 'UTM_SOURCE_DESC',
  S1Asc = 'S1_ASC',
  S1Desc = 'S1_DESC',
  S2Asc = 'S2_ASC',
  S2Desc = 'S2_DESC',
  S3Asc = 'S3_ASC',
  S3Desc = 'S3_DESC',
  S4Asc = 'S4_ASC',
  S4Desc = 'S4_DESC',
  S5Asc = 'S5_ASC',
  S5Desc = 'S5_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `company` to be created by this mutation. */
export type LeadCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `LeadInput` mutation. */
export type LeadCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnLeadForLeadCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadOnLeadForLeadCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<LeadCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `lead` in the `CompanyInput` mutation. */
export type LeadCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `lead` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<Array<LeadLeadPkeyConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LeadNodeIdConnect>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<Array<LeadLeadPkeyDelete>>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LeadNodeIdDelete>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<Array<LeadOnLeadForLeadCompanyIdFkeyUsingLeadPkeyUpdate>>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnLeadForLeadCompanyIdFkeyNodeIdUpdate>>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadCompanyIdFkeyLeadCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadCompanyIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** A condition to be used against `Lead` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LeadCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateOfBirth` field. */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `street` field. */
  street?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `streetNumber` field. */
  streetNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `unit` field. */
  unit?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  country?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `provinceCode` field. */
  provinceCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `emailAddress` field. */
  emailAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionKey` field. */
  transactionKey?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `leadspediaId` field. */
  leadspediaId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText?: Maybe<Scalars['FullText']>;
  /** Checks for equality with the object’s `comment` field. */
  comment?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<LeadState>;
  /** Checks for equality with the object’s `read` field. */
  read?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `externalIds` field. */
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `additionalInformation` field. */
  additionalInformation?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `phoneVerifiedAt` field. */
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `ipAddress` field. */
  ipAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: Maybe<LeadPriority>;
  /** Checks for equality with the object’s `assignee` field. */
  assignee?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortId` field. */
  shortId?: Maybe<Scalars['Int']>;
};

export type LeadDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  companyId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  firstName?: Maybe<BigIntFilter>;
  lastName?: Maybe<BigIntFilter>;
  dateOfBirth?: Maybe<BigIntFilter>;
  street?: Maybe<BigIntFilter>;
  streetNumber?: Maybe<BigIntFilter>;
  unit?: Maybe<BigIntFilter>;
  city?: Maybe<BigIntFilter>;
  country?: Maybe<BigIntFilter>;
  postalCode?: Maybe<BigIntFilter>;
  provinceCode?: Maybe<BigIntFilter>;
  emailAddress?: Maybe<BigIntFilter>;
  phoneNumber?: Maybe<BigIntFilter>;
  sourceUrl?: Maybe<BigIntFilter>;
  transactionKey?: Maybe<BigIntFilter>;
  leadspediaId?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  searchText?: Maybe<BigIntFilter>;
  comment?: Maybe<BigIntFilter>;
  state?: Maybe<BigIntFilter>;
  read?: Maybe<BigIntFilter>;
  externalIds?: Maybe<BigIntFilter>;
  meta?: Maybe<BigIntFilter>;
  additionalInformation?: Maybe<BigIntFilter>;
  phoneVerifiedAt?: Maybe<BigIntFilter>;
  ipAddress?: Maybe<BigIntFilter>;
  clientId?: Maybe<BigIntFilter>;
  deleted?: Maybe<BigIntFilter>;
  priority?: Maybe<BigIntFilter>;
  assignee?: Maybe<BigIntFilter>;
  shortId?: Maybe<BigIntFilter>;
  journeyStages?: Maybe<StringFilter>;
};

export type LeadDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dateOfBirth across the matching connection */
  dateOfBirth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of street across the matching connection */
  street?: Maybe<Scalars['BigInt']>;
  /** Distinct count of streetNumber across the matching connection */
  streetNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unit across the matching connection */
  unit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provinceCode across the matching connection */
  provinceCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailAddress across the matching connection */
  emailAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sourceUrl across the matching connection */
  sourceUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of transactionKey across the matching connection */
  transactionKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadspediaId across the matching connection */
  leadspediaId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of searchText across the matching connection */
  searchText?: Maybe<Scalars['BigInt']>;
  /** Distinct count of comment across the matching connection */
  comment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of read across the matching connection */
  read?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalIds across the matching connection */
  externalIds?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of additionalInformation across the matching connection */
  additionalInformation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneVerifiedAt across the matching connection */
  phoneVerifiedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ipAddress across the matching connection */
  ipAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars['BigInt']>;
  /** Distinct count of assignee across the matching connection */
  assignee?: Maybe<Scalars['BigInt']>;
  /** Distinct count of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  journeyStages?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Lead` object types. All fields are combined with a logical ‘and.’ */
export type LeadFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `dateOfBirth` field. */
  dateOfBirth?: Maybe<DateFilter>;
  /** Filter by the object’s `street` field. */
  street?: Maybe<StringFilter>;
  /** Filter by the object’s `streetNumber` field. */
  streetNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `unit` field. */
  unit?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: Maybe<StringFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: Maybe<StringFilter>;
  /** Filter by the object’s `provinceCode` field. */
  provinceCode?: Maybe<StringFilter>;
  /** Filter by the object’s `emailAddress` field. */
  emailAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `sourceUrl` field. */
  sourceUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `transactionKey` field. */
  transactionKey?: Maybe<StringFilter>;
  /** Filter by the object’s `leadspediaId` field. */
  leadspediaId?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText?: Maybe<FullTextFilter>;
  /** Filter by the object’s `comment` field. */
  comment?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<LeadStateFilter>;
  /** Filter by the object’s `read` field. */
  read?: Maybe<BooleanFilter>;
  /** Filter by the object’s `externalIds` field. */
  externalIds?: Maybe<StringListFilter>;
  /** Filter by the object’s `phoneVerifiedAt` field. */
  phoneVerifiedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `ipAddress` field. */
  ipAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: Maybe<StringFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: Maybe<LeadPriorityFilter>;
  /** Filter by the object’s `assignee` field. */
  assignee?: Maybe<StringFilter>;
  /** Filter by the object’s `shortId` field. */
  shortId?: Maybe<IntFilter>;
  /** Filter by the object’s `journeyStages` field. */
  journeyStages?: Maybe<StringFilter>;
  /** Filter by the object’s `avaApp` relation. */
  avaApp?: Maybe<AvaAppFilter>;
  /** A related `avaApp` exists. */
  avaAppExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `creditApp` relation. */
  creditApp?: Maybe<CreditAppFilter>;
  /** A related `creditApp` exists. */
  creditAppExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadCampaign` relation. */
  leadCampaign?: Maybe<LeadCampaignFilter>;
  /** A related `leadCampaign` exists. */
  leadCampaignExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `tradeApps` relation. */
  tradeApps?: Maybe<LeadToManyTradeAppFilter>;
  /** Some related `tradeApps` exist. */
  tradeAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadJourneys` relation. */
  leadJourneys?: Maybe<LeadToManyLeadJourneyFilter>;
  /** Some related `leadJourneys` exist. */
  leadJourneysExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderApps` relation. */
  lenderApps?: Maybe<LeadToManyLenderAppFilter>;
  /** Some related `lenderApps` exist. */
  lenderAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `idVerification` relation. */
  idVerification?: Maybe<IdVerificationFilter>;
  /** A related `idVerification` exists. */
  idVerificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `appointments` relation. */
  appointments?: Maybe<LeadToManyAppointmentFilter>;
  /** Some related `appointments` exist. */
  appointmentsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `incomeVerification` relation. */
  incomeVerification?: Maybe<IncomeVerificationFilter>;
  /** A related `incomeVerification` exists. */
  incomeVerificationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<LeadToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `events` relation. */
  events?: Maybe<LeadToManyEventFilter>;
  /** Some related `events` exist. */
  eventsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadFilter>;
};

/** Grouping methods for `Lead` for usage during aggregation. */
export enum LeadGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  DateOfBirth = 'DATE_OF_BIRTH',
  Street = 'STREET',
  StreetNumber = 'STREET_NUMBER',
  Unit = 'UNIT',
  City = 'CITY',
  Country = 'COUNTRY',
  PostalCode = 'POSTAL_CODE',
  ProvinceCode = 'PROVINCE_CODE',
  EmailAddress = 'EMAIL_ADDRESS',
  PhoneNumber = 'PHONE_NUMBER',
  SourceUrl = 'SOURCE_URL',
  TransactionKey = 'TRANSACTION_KEY',
  LeadspediaId = 'LEADSPEDIA_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  SearchText = 'SEARCH_TEXT',
  Comment = 'COMMENT',
  State = 'STATE',
  Read = 'READ',
  ExternalIds = 'EXTERNAL_IDS',
  Meta = 'META',
  AdditionalInformation = 'ADDITIONAL_INFORMATION',
  PhoneVerifiedAt = 'PHONE_VERIFIED_AT',
  PhoneVerifiedAtTruncatedToHour = 'PHONE_VERIFIED_AT_TRUNCATED_TO_HOUR',
  PhoneVerifiedAtTruncatedToDay = 'PHONE_VERIFIED_AT_TRUNCATED_TO_DAY',
  IpAddress = 'IP_ADDRESS',
  ClientId = 'CLIENT_ID',
  Deleted = 'DELETED',
  Priority = 'PRIORITY',
  Assignee = 'ASSIGNEE',
  ShortId = 'SHORT_ID'
}

export type LeadHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

/** Conditions for `Lead` aggregates. */
export type LeadHavingInput = {
  AND?: Maybe<Array<LeadHavingInput>>;
  OR?: Maybe<Array<LeadHavingInput>>;
  sum?: Maybe<LeadHavingSumInput>;
  distinctCount?: Maybe<LeadHavingDistinctCountInput>;
  min?: Maybe<LeadHavingMinInput>;
  max?: Maybe<LeadHavingMaxInput>;
  average?: Maybe<LeadHavingAverageInput>;
  stddevSample?: Maybe<LeadHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadHavingVariancePopulationInput>;
};

export type LeadHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  dateOfBirth?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  phoneVerifiedAt?: Maybe<HavingDatetimeFilter>;
  shortId?: Maybe<HavingIntFilter>;
};

export type LeadInfoCompanyType = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LeadInfoCreditAppType = {
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
};

export type LeadInfoResponse = {
  id: Scalars['UUID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  company?: Maybe<LeadInfoCompanyType>;
  creditApp?: Maybe<LeadInfoCreditAppType>;
  additionalInformation?: Maybe<Scalars['JSON']>;
};

/** An input for mutations affecting `Lead` */
export type LeadInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export type LeadJourney = {
  leadId: Scalars['UUID'];
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `LeadJourney`. */
  lead?: Maybe<Lead>;
  /** Reads a single `Product` that is related to this `LeadJourney`. */
  product?: Maybe<Product>;
};

export type LeadJourneyAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LeadJourneySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LeadJourneyDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LeadJourneyMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LeadJourneyMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LeadJourneyAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LeadJourneyStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LeadJourneyStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LeadJourneyVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LeadJourneyVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LeadJourney` object types. */
export type LeadJourneyAggregatesFilter = {
  /** A filter that must pass for the relevant `LeadJourney` object to be included within the aggregate. */
  filter?: Maybe<LeadJourneyFilter>;
  /** Sum aggregate over matching `LeadJourney` objects. */
  sum?: Maybe<LeadJourneySumAggregateFilter>;
  /** Distinct count aggregate over matching `LeadJourney` objects. */
  distinctCount?: Maybe<LeadJourneyDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LeadJourney` objects. */
  min?: Maybe<LeadJourneyMinAggregateFilter>;
  /** Maximum aggregate over matching `LeadJourney` objects. */
  max?: Maybe<LeadJourneyMaxAggregateFilter>;
  /** Mean average aggregate over matching `LeadJourney` objects. */
  average?: Maybe<LeadJourneyAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LeadJourney` objects. */
  stddevSample?: Maybe<LeadJourneyStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LeadJourney` objects. */
  stddevPopulation?: Maybe<LeadJourneyStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LeadJourney` objects. */
  varianceSample?: Maybe<LeadJourneyVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LeadJourney` objects. */
  variancePopulation?: Maybe<LeadJourneyVariancePopulationAggregateFilter>;
};

export type LeadJourneyAverageAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyAverageAggregates = {
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LeadJourney` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LeadJourneyCondition = {
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stage` field. */
  stage?: Maybe<JourneyStage>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LeadJourneyDistinctCountAggregateFilter = {
  leadId?: Maybe<BigIntFilter>;
  stage?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type LeadJourneyDistinctCountAggregates = {
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stage across the matching connection */
  stage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type LeadJourneyFilter = {
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `stage` field. */
  stage?: Maybe<JourneyStageFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** A related `product` exists. */
  productExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LeadJourneyFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LeadJourneyFilter>>;
  /** Negates the expression. */
  not?: Maybe<LeadJourneyFilter>;
};

/** Grouping methods for `LeadJourney` for usage during aggregation. */
export enum LeadJourneyGroupBy {
  LeadId = 'LEAD_ID',
  Stage = 'STAGE',
  ProductId = 'PRODUCT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type LeadJourneyHavingAverageInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingDistinctCountInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LeadJourney` aggregates. */
export type LeadJourneyHavingInput = {
  AND?: Maybe<Array<LeadJourneyHavingInput>>;
  OR?: Maybe<Array<LeadJourneyHavingInput>>;
  sum?: Maybe<LeadJourneyHavingSumInput>;
  distinctCount?: Maybe<LeadJourneyHavingDistinctCountInput>;
  min?: Maybe<LeadJourneyHavingMinInput>;
  max?: Maybe<LeadJourneyHavingMaxInput>;
  average?: Maybe<LeadJourneyHavingAverageInput>;
  stddevSample?: Maybe<LeadJourneyHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LeadJourneyHavingStddevPopulationInput>;
  varianceSample?: Maybe<LeadJourneyHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LeadJourneyHavingVariancePopulationInput>;
};

export type LeadJourneyHavingMaxInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingMinInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingStddevPopulationInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingStddevSampleInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingSumInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingVariancePopulationInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LeadJourneyHavingVarianceSampleInput = {
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LeadJourney` */
export type LeadJourneyInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `LeadJourneyInput` mutation. */
export type LeadJourneyLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLeadJourneyForLeadJourneyLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadJourneyOnLeadJourneyForLeadJourneyLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LeadJourneyLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `leadJourney` in the `LeadInput` mutation. */
export type LeadJourneyLeadIdFkeyInverseInput = {
  /** A `LeadJourneyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadJourneyLeadIdFkeyLeadJourneyCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LeadJourneyLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `leadJourney` to be created by this mutation. */
export type LeadJourneyLeadIdFkeyLeadJourneyCreateInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

export type LeadJourneyMaxAggregateFilter = {
  productId?: Maybe<IntFilter>;
};

export type LeadJourneyMaxAggregates = {
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

export type LeadJourneyMinAggregateFilter = {
  productId?: Maybe<IntFilter>;
};

export type LeadJourneyMinAggregates = {
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to update. */
export type LeadJourneyOnLeadJourneyForLeadJourneyLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type LeadJourneyOnLeadJourneyForLeadJourneyProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** Input for the nested mutation of `product` in the `LeadJourneyInput` mutation. */
export type LeadJourneyProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<LeadJourneyOnLeadJourneyForLeadJourneyProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `leadJourney` in the `ProductInput` mutation. */
export type LeadJourneyProductIdFkeyInverseInput = {
  /** A `LeadJourneyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LeadJourneyProductIdFkeyLeadJourneyCreateInput>>;
};

/** The `leadJourney` to be created by this mutation. */
export type LeadJourneyProductIdFkeyLeadJourneyCreateInput = {
  leadId?: Maybe<Scalars['UUID']>;
  stage: JourneyStage;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadJourneyLeadIdFkeyInput>;
  productToProductId?: Maybe<LeadJourneyProductIdFkeyInput>;
};

export type LeadJourneyStddevPopulationAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyStddevPopulationAggregates = {
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneyStddevSampleAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyStddevSampleAggregates = {
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneySumAggregateFilter = {
  productId?: Maybe<BigIntFilter>;
};

export type LeadJourneySumAggregates = {
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
};

export type LeadJourneyVariancePopulationAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyVariancePopulationAggregates = {
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type LeadJourneyVarianceSampleAggregateFilter = {
  productId?: Maybe<BigFloatFilter>;
};

export type LeadJourneyVarianceSampleAggregates = {
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LeadJourney` values. */
export type LeadJourneysConnection = {
  /** A list of `LeadJourney` objects. */
  nodes: Array<LeadJourney>;
  /** A list of edges which contains the `LeadJourney` and cursor to aid in pagination. */
  edges: Array<LeadJourneysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeadJourney` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadJourneyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadJourneyAggregates>>;
};


/** A connection to a list of `LeadJourney` values. */
export type LeadJourneysConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadJourneyGroupBy>;
  having?: Maybe<LeadJourneyHavingInput>;
};

/** A `LeadJourney` edge in the connection. */
export type LeadJourneysEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LeadJourney` at the end of the edge. */
  node: LeadJourney;
};

/** Methods to use when ordering `LeadJourney`. */
export enum LeadJourneysOrderBy {
  Natural = 'NATURAL',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** The fields on `lead` to look up the row to connect. */
export type LeadLeadPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to delete. */
export type LeadLeadPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `LenderApp` edge in the connection, with data from `LenderQuote`. */
export type LeadLenderAppsByLenderQuoteLeadIdAndLenderAppIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

export type LeadMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  shortId?: Maybe<IntFilter>;
};

export type LeadMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of shortId across the matching connection */
  shortId?: Maybe<Scalars['Int']>;
};

export type LeadMetaResponse = {
  leadId: Scalars['UUID'];
  success?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type LeadMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  shortId?: Maybe<IntFilter>;
};

export type LeadMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of shortId across the matching connection */
  shortId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LeadNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LeadNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lead` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnAppointmentForAppointmentsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: AppointmentPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnAppointmentForAppointmentsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnAppointmentForAppointmentsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnAvaAppForAvaAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `avaApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `avaApp` being updated. */
  patch: AvaAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnAvaAppForAvaAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnAvaAppForAvaAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnCreditAppForCreditAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `creditApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `creditApp` being updated. */
  patch: CreditAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnCreditAppForCreditAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnCreditAppForCreditAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnEventForFakePublicEventsForeignKey0NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `event` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `event` being updated. */
  patch: EventPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnEventForFakePublicEventsForeignKey0UsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnEventForFakePublicEventsForeignKey0Patch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnIdVerificationForIdVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `idVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `idVerification` being updated. */
  patch: IdVerificationPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnIdVerificationForIdVerificationLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnIdVerificationForIdVerificationLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `incomeVerification` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `incomeVerification` being updated. */
  patch: IncomeVerificationPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `leadCampaign` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `leadCampaign` being updated. */
  patch: LeadCampaignPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadCampaignForLeadCampaignsLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLeadForLeadCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadForLeadCompanyIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadForLeadCompanyIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLeadJourneyForLeadJourneyLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLeadJourneyForLeadJourneyLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLenderAppForLenderAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLenderAppForLenderAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnLenderQuoteForLenderQuotesLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LeadOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `tradeApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `tradeApp` being updated. */
  patch: TradeAppPatch;
};

/** The fields on `lead` to look up the row to update. */
export type LeadOnTradeAppForTradeAppLeadIdFkeyUsingLeadPkeyUpdate = {
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: UpdateLeadOnTradeAppForTradeAppLeadIdFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Lead`. Fields that are set will be updated. */
export type LeadPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

export enum LeadPriority {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

/** A filter to be used against LeadPriority fields. All fields are combined with a logical ‘and.’ */
export type LeadPriorityFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LeadPriority>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LeadPriority>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LeadPriority>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LeadPriority>;
  /** Included in the specified list. */
  in?: Maybe<Array<LeadPriority>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LeadPriority>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LeadPriority>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LeadPriority>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LeadPriority>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LeadPriority>;
};

/** A connection to a list of `Product` values, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product`, info from the `LeadJourney`, and the cursor to aid in pagination. */
  edges: Array<LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
};


/** A `Product` edge in the connection, with data from `LeadJourney`. */
export type LeadProductsByLeadJourneyLeadIdAndProductIdManyToManyEdgeLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};

export enum LeadState {
  New = 'NEW',
  Working = 'WORKING',
  Sold = 'SOLD',
  Dead = 'DEAD',
  Contacted = 'CONTACTED',
  AppointmentBooked = 'APPOINTMENT_BOOKED'
}

/** A filter to be used against LeadState fields. All fields are combined with a logical ‘and.’ */
export type LeadStateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LeadState>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LeadState>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LeadState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LeadState>;
  /** Included in the specified list. */
  in?: Maybe<Array<LeadState>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LeadState>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LeadState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LeadState>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LeadState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LeadState>;
};

export type LeadStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
};

export type LeadStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
};

export type LeadStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
};

export type LeadStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
};

export type LeadSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  shortId?: Maybe<BigIntFilter>;
};

export type LeadSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of shortId across the matching connection */
  shortId: Scalars['BigInt'];
};

/** A filter to be used against many `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyAppointmentFilter = {
  /** Every related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AppointmentFilter>;
  /** Some related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AppointmentFilter>;
  /** No related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AppointmentFilter>;
  /** Aggregates across related `Appointment` match the filter criteria. */
  aggregates?: Maybe<AppointmentAggregatesFilter>;
};

/** A filter to be used against many `Event` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyEventFilter = {
  /** Every related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<EventFilter>;
  /** Some related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<EventFilter>;
  /** No related `Event` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<EventFilter>;
  /** Aggregates across related `Event` match the filter criteria. */
  aggregates?: Maybe<EventAggregatesFilter>;
};

/** A filter to be used against many `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLeadJourneyFilter = {
  /** Every related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadJourneyFilter>;
  /** Some related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadJourneyFilter>;
  /** No related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadJourneyFilter>;
  /** Aggregates across related `LeadJourney` match the filter criteria. */
  aggregates?: Maybe<LeadJourneyAggregatesFilter>;
};

/** A filter to be used against many `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLenderAppFilter = {
  /** Every related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderAppFilter>;
  /** Some related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderAppFilter>;
  /** No related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderAppFilter>;
  /** Aggregates across related `LenderApp` match the filter criteria. */
  aggregates?: Maybe<LenderAppAggregatesFilter>;
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

/** A filter to be used against many `TradeApp` object types. All fields are combined with a logical ‘and.’ */
export type LeadToManyTradeAppFilter = {
  /** Every related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TradeAppFilter>;
  /** Some related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TradeAppFilter>;
  /** No related `TradeApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TradeAppFilter>;
  /** Aggregates across related `TradeApp` match the filter criteria. */
  aggregates?: Maybe<TradeAppAggregatesFilter>;
};

export type LeadVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
};

export type LeadVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
};

export type LeadVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  shortId?: Maybe<BigFloatFilter>;
};

export type LeadVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of shortId across the matching connection */
  shortId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `VehicleListing` values, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing`, info from the `Appointment`, and the cursor to aid in pagination. */
  edges: Array<LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
};


/** A `VehicleListing` edge in the connection, with data from `Appointment`. */
export type LeadVehicleListingsByAppointmentLeadIdAndVehicleListingIdManyToManyEdgeAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};

/** A connection to a list of `VehicleListing` values, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing`, info from the `LenderApp`, and the cursor to aid in pagination. */
  edges: Array<LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
};


/** A `VehicleListing` edge in the connection, with data from `LenderApp`. */
export type LeadVehicleListingsByLenderAppLeadIdAndVehicleListingIdManyToManyEdgeLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};

/** A connection to a list of `Lead` values. */
export type LeadsConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead` and cursor to aid in pagination. */
  edges: Array<LeadsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values. */
export type LeadsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection. */
export type LeadsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
};

/** Methods to use when ordering `Lead`. */
export enum LeadsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  DateOfBirthAsc = 'DATE_OF_BIRTH_ASC',
  DateOfBirthDesc = 'DATE_OF_BIRTH_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  StreetNumberAsc = 'STREET_NUMBER_ASC',
  StreetNumberDesc = 'STREET_NUMBER_DESC',
  UnitAsc = 'UNIT_ASC',
  UnitDesc = 'UNIT_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  ProvinceCodeAsc = 'PROVINCE_CODE_ASC',
  ProvinceCodeDesc = 'PROVINCE_CODE_DESC',
  EmailAddressAsc = 'EMAIL_ADDRESS_ASC',
  EmailAddressDesc = 'EMAIL_ADDRESS_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  SourceUrlAsc = 'SOURCE_URL_ASC',
  SourceUrlDesc = 'SOURCE_URL_DESC',
  TransactionKeyAsc = 'TRANSACTION_KEY_ASC',
  TransactionKeyDesc = 'TRANSACTION_KEY_DESC',
  LeadspediaIdAsc = 'LEADSPEDIA_ID_ASC',
  LeadspediaIdDesc = 'LEADSPEDIA_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ReadAsc = 'READ_ASC',
  ReadDesc = 'READ_DESC',
  ExternalIdsAsc = 'EXTERNAL_IDS_ASC',
  ExternalIdsDesc = 'EXTERNAL_IDS_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  AdditionalInformationAsc = 'ADDITIONAL_INFORMATION_ASC',
  AdditionalInformationDesc = 'ADDITIONAL_INFORMATION_DESC',
  PhoneVerifiedAtAsc = 'PHONE_VERIFIED_AT_ASC',
  PhoneVerifiedAtDesc = 'PHONE_VERIFIED_AT_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  ClientIdAsc = 'CLIENT_ID_ASC',
  ClientIdDesc = 'CLIENT_ID_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  AssigneeAsc = 'ASSIGNEE_ASC',
  AssigneeDesc = 'ASSIGNEE_DESC',
  ShortIdAsc = 'SHORT_ID_ASC',
  ShortIdDesc = 'SHORT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchTextRankAsc = 'SEARCH_TEXT_RANK_ASC',
  SearchTextRankDesc = 'SEARCH_TEXT_RANK_DESC',
  TradeAppsCountAsc = 'TRADE_APPS_COUNT_ASC',
  TradeAppsCountDesc = 'TRADE_APPS_COUNT_DESC',
  TradeAppsSumIdAsc = 'TRADE_APPS_SUM_ID_ASC',
  TradeAppsSumIdDesc = 'TRADE_APPS_SUM_ID_DESC',
  TradeAppsSumLeadIdAsc = 'TRADE_APPS_SUM_LEAD_ID_ASC',
  TradeAppsSumLeadIdDesc = 'TRADE_APPS_SUM_LEAD_ID_DESC',
  TradeAppsSumYearAsc = 'TRADE_APPS_SUM_YEAR_ASC',
  TradeAppsSumYearDesc = 'TRADE_APPS_SUM_YEAR_DESC',
  TradeAppsSumMakeAsc = 'TRADE_APPS_SUM_MAKE_ASC',
  TradeAppsSumMakeDesc = 'TRADE_APPS_SUM_MAKE_DESC',
  TradeAppsSumModelAsc = 'TRADE_APPS_SUM_MODEL_ASC',
  TradeAppsSumModelDesc = 'TRADE_APPS_SUM_MODEL_DESC',
  TradeAppsSumTrimAsc = 'TRADE_APPS_SUM_TRIM_ASC',
  TradeAppsSumTrimDesc = 'TRADE_APPS_SUM_TRIM_DESC',
  TradeAppsSumMileageAsc = 'TRADE_APPS_SUM_MILEAGE_ASC',
  TradeAppsSumMileageDesc = 'TRADE_APPS_SUM_MILEAGE_DESC',
  TradeAppsSumConditionAsc = 'TRADE_APPS_SUM_CONDITION_ASC',
  TradeAppsSumConditionDesc = 'TRADE_APPS_SUM_CONDITION_DESC',
  TradeAppsSumTradeInValueAsc = 'TRADE_APPS_SUM_TRADE_IN_VALUE_ASC',
  TradeAppsSumTradeInValueDesc = 'TRADE_APPS_SUM_TRADE_IN_VALUE_DESC',
  TradeAppsSumMaxTradeInValueAsc = 'TRADE_APPS_SUM_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsSumMaxTradeInValueDesc = 'TRADE_APPS_SUM_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsSumMinTradeInValueAsc = 'TRADE_APPS_SUM_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsSumMinTradeInValueDesc = 'TRADE_APPS_SUM_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsSumCreatedAtAsc = 'TRADE_APPS_SUM_CREATED_AT_ASC',
  TradeAppsSumCreatedAtDesc = 'TRADE_APPS_SUM_CREATED_AT_DESC',
  TradeAppsSumUpdatedAtAsc = 'TRADE_APPS_SUM_UPDATED_AT_ASC',
  TradeAppsSumUpdatedAtDesc = 'TRADE_APPS_SUM_UPDATED_AT_DESC',
  TradeAppsDistinctCountIdAsc = 'TRADE_APPS_DISTINCT_COUNT_ID_ASC',
  TradeAppsDistinctCountIdDesc = 'TRADE_APPS_DISTINCT_COUNT_ID_DESC',
  TradeAppsDistinctCountLeadIdAsc = 'TRADE_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  TradeAppsDistinctCountLeadIdDesc = 'TRADE_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  TradeAppsDistinctCountYearAsc = 'TRADE_APPS_DISTINCT_COUNT_YEAR_ASC',
  TradeAppsDistinctCountYearDesc = 'TRADE_APPS_DISTINCT_COUNT_YEAR_DESC',
  TradeAppsDistinctCountMakeAsc = 'TRADE_APPS_DISTINCT_COUNT_MAKE_ASC',
  TradeAppsDistinctCountMakeDesc = 'TRADE_APPS_DISTINCT_COUNT_MAKE_DESC',
  TradeAppsDistinctCountModelAsc = 'TRADE_APPS_DISTINCT_COUNT_MODEL_ASC',
  TradeAppsDistinctCountModelDesc = 'TRADE_APPS_DISTINCT_COUNT_MODEL_DESC',
  TradeAppsDistinctCountTrimAsc = 'TRADE_APPS_DISTINCT_COUNT_TRIM_ASC',
  TradeAppsDistinctCountTrimDesc = 'TRADE_APPS_DISTINCT_COUNT_TRIM_DESC',
  TradeAppsDistinctCountMileageAsc = 'TRADE_APPS_DISTINCT_COUNT_MILEAGE_ASC',
  TradeAppsDistinctCountMileageDesc = 'TRADE_APPS_DISTINCT_COUNT_MILEAGE_DESC',
  TradeAppsDistinctCountConditionAsc = 'TRADE_APPS_DISTINCT_COUNT_CONDITION_ASC',
  TradeAppsDistinctCountConditionDesc = 'TRADE_APPS_DISTINCT_COUNT_CONDITION_DESC',
  TradeAppsDistinctCountTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountMaxTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountMaxTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountMinTradeInValueAsc = 'TRADE_APPS_DISTINCT_COUNT_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsDistinctCountMinTradeInValueDesc = 'TRADE_APPS_DISTINCT_COUNT_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsDistinctCountCreatedAtAsc = 'TRADE_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  TradeAppsDistinctCountCreatedAtDesc = 'TRADE_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  TradeAppsDistinctCountUpdatedAtAsc = 'TRADE_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  TradeAppsDistinctCountUpdatedAtDesc = 'TRADE_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  TradeAppsMinIdAsc = 'TRADE_APPS_MIN_ID_ASC',
  TradeAppsMinIdDesc = 'TRADE_APPS_MIN_ID_DESC',
  TradeAppsMinLeadIdAsc = 'TRADE_APPS_MIN_LEAD_ID_ASC',
  TradeAppsMinLeadIdDesc = 'TRADE_APPS_MIN_LEAD_ID_DESC',
  TradeAppsMinYearAsc = 'TRADE_APPS_MIN_YEAR_ASC',
  TradeAppsMinYearDesc = 'TRADE_APPS_MIN_YEAR_DESC',
  TradeAppsMinMakeAsc = 'TRADE_APPS_MIN_MAKE_ASC',
  TradeAppsMinMakeDesc = 'TRADE_APPS_MIN_MAKE_DESC',
  TradeAppsMinModelAsc = 'TRADE_APPS_MIN_MODEL_ASC',
  TradeAppsMinModelDesc = 'TRADE_APPS_MIN_MODEL_DESC',
  TradeAppsMinTrimAsc = 'TRADE_APPS_MIN_TRIM_ASC',
  TradeAppsMinTrimDesc = 'TRADE_APPS_MIN_TRIM_DESC',
  TradeAppsMinMileageAsc = 'TRADE_APPS_MIN_MILEAGE_ASC',
  TradeAppsMinMileageDesc = 'TRADE_APPS_MIN_MILEAGE_DESC',
  TradeAppsMinConditionAsc = 'TRADE_APPS_MIN_CONDITION_ASC',
  TradeAppsMinConditionDesc = 'TRADE_APPS_MIN_CONDITION_DESC',
  TradeAppsMinTradeInValueAsc = 'TRADE_APPS_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMinTradeInValueDesc = 'TRADE_APPS_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMinMaxTradeInValueAsc = 'TRADE_APPS_MIN_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMinMaxTradeInValueDesc = 'TRADE_APPS_MIN_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMinMinTradeInValueAsc = 'TRADE_APPS_MIN_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMinMinTradeInValueDesc = 'TRADE_APPS_MIN_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMinCreatedAtAsc = 'TRADE_APPS_MIN_CREATED_AT_ASC',
  TradeAppsMinCreatedAtDesc = 'TRADE_APPS_MIN_CREATED_AT_DESC',
  TradeAppsMinUpdatedAtAsc = 'TRADE_APPS_MIN_UPDATED_AT_ASC',
  TradeAppsMinUpdatedAtDesc = 'TRADE_APPS_MIN_UPDATED_AT_DESC',
  TradeAppsMaxIdAsc = 'TRADE_APPS_MAX_ID_ASC',
  TradeAppsMaxIdDesc = 'TRADE_APPS_MAX_ID_DESC',
  TradeAppsMaxLeadIdAsc = 'TRADE_APPS_MAX_LEAD_ID_ASC',
  TradeAppsMaxLeadIdDesc = 'TRADE_APPS_MAX_LEAD_ID_DESC',
  TradeAppsMaxYearAsc = 'TRADE_APPS_MAX_YEAR_ASC',
  TradeAppsMaxYearDesc = 'TRADE_APPS_MAX_YEAR_DESC',
  TradeAppsMaxMakeAsc = 'TRADE_APPS_MAX_MAKE_ASC',
  TradeAppsMaxMakeDesc = 'TRADE_APPS_MAX_MAKE_DESC',
  TradeAppsMaxModelAsc = 'TRADE_APPS_MAX_MODEL_ASC',
  TradeAppsMaxModelDesc = 'TRADE_APPS_MAX_MODEL_DESC',
  TradeAppsMaxTrimAsc = 'TRADE_APPS_MAX_TRIM_ASC',
  TradeAppsMaxTrimDesc = 'TRADE_APPS_MAX_TRIM_DESC',
  TradeAppsMaxMileageAsc = 'TRADE_APPS_MAX_MILEAGE_ASC',
  TradeAppsMaxMileageDesc = 'TRADE_APPS_MAX_MILEAGE_DESC',
  TradeAppsMaxConditionAsc = 'TRADE_APPS_MAX_CONDITION_ASC',
  TradeAppsMaxConditionDesc = 'TRADE_APPS_MAX_CONDITION_DESC',
  TradeAppsMaxTradeInValueAsc = 'TRADE_APPS_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMaxTradeInValueDesc = 'TRADE_APPS_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMaxMaxTradeInValueAsc = 'TRADE_APPS_MAX_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsMaxMaxTradeInValueDesc = 'TRADE_APPS_MAX_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsMaxMinTradeInValueAsc = 'TRADE_APPS_MAX_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsMaxMinTradeInValueDesc = 'TRADE_APPS_MAX_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsMaxCreatedAtAsc = 'TRADE_APPS_MAX_CREATED_AT_ASC',
  TradeAppsMaxCreatedAtDesc = 'TRADE_APPS_MAX_CREATED_AT_DESC',
  TradeAppsMaxUpdatedAtAsc = 'TRADE_APPS_MAX_UPDATED_AT_ASC',
  TradeAppsMaxUpdatedAtDesc = 'TRADE_APPS_MAX_UPDATED_AT_DESC',
  TradeAppsAverageIdAsc = 'TRADE_APPS_AVERAGE_ID_ASC',
  TradeAppsAverageIdDesc = 'TRADE_APPS_AVERAGE_ID_DESC',
  TradeAppsAverageLeadIdAsc = 'TRADE_APPS_AVERAGE_LEAD_ID_ASC',
  TradeAppsAverageLeadIdDesc = 'TRADE_APPS_AVERAGE_LEAD_ID_DESC',
  TradeAppsAverageYearAsc = 'TRADE_APPS_AVERAGE_YEAR_ASC',
  TradeAppsAverageYearDesc = 'TRADE_APPS_AVERAGE_YEAR_DESC',
  TradeAppsAverageMakeAsc = 'TRADE_APPS_AVERAGE_MAKE_ASC',
  TradeAppsAverageMakeDesc = 'TRADE_APPS_AVERAGE_MAKE_DESC',
  TradeAppsAverageModelAsc = 'TRADE_APPS_AVERAGE_MODEL_ASC',
  TradeAppsAverageModelDesc = 'TRADE_APPS_AVERAGE_MODEL_DESC',
  TradeAppsAverageTrimAsc = 'TRADE_APPS_AVERAGE_TRIM_ASC',
  TradeAppsAverageTrimDesc = 'TRADE_APPS_AVERAGE_TRIM_DESC',
  TradeAppsAverageMileageAsc = 'TRADE_APPS_AVERAGE_MILEAGE_ASC',
  TradeAppsAverageMileageDesc = 'TRADE_APPS_AVERAGE_MILEAGE_DESC',
  TradeAppsAverageConditionAsc = 'TRADE_APPS_AVERAGE_CONDITION_ASC',
  TradeAppsAverageConditionDesc = 'TRADE_APPS_AVERAGE_CONDITION_DESC',
  TradeAppsAverageTradeInValueAsc = 'TRADE_APPS_AVERAGE_TRADE_IN_VALUE_ASC',
  TradeAppsAverageTradeInValueDesc = 'TRADE_APPS_AVERAGE_TRADE_IN_VALUE_DESC',
  TradeAppsAverageMaxTradeInValueAsc = 'TRADE_APPS_AVERAGE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsAverageMaxTradeInValueDesc = 'TRADE_APPS_AVERAGE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsAverageMinTradeInValueAsc = 'TRADE_APPS_AVERAGE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsAverageMinTradeInValueDesc = 'TRADE_APPS_AVERAGE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsAverageCreatedAtAsc = 'TRADE_APPS_AVERAGE_CREATED_AT_ASC',
  TradeAppsAverageCreatedAtDesc = 'TRADE_APPS_AVERAGE_CREATED_AT_DESC',
  TradeAppsAverageUpdatedAtAsc = 'TRADE_APPS_AVERAGE_UPDATED_AT_ASC',
  TradeAppsAverageUpdatedAtDesc = 'TRADE_APPS_AVERAGE_UPDATED_AT_DESC',
  TradeAppsStddevSampleIdAsc = 'TRADE_APPS_STDDEV_SAMPLE_ID_ASC',
  TradeAppsStddevSampleIdDesc = 'TRADE_APPS_STDDEV_SAMPLE_ID_DESC',
  TradeAppsStddevSampleLeadIdAsc = 'TRADE_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  TradeAppsStddevSampleLeadIdDesc = 'TRADE_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  TradeAppsStddevSampleYearAsc = 'TRADE_APPS_STDDEV_SAMPLE_YEAR_ASC',
  TradeAppsStddevSampleYearDesc = 'TRADE_APPS_STDDEV_SAMPLE_YEAR_DESC',
  TradeAppsStddevSampleMakeAsc = 'TRADE_APPS_STDDEV_SAMPLE_MAKE_ASC',
  TradeAppsStddevSampleMakeDesc = 'TRADE_APPS_STDDEV_SAMPLE_MAKE_DESC',
  TradeAppsStddevSampleModelAsc = 'TRADE_APPS_STDDEV_SAMPLE_MODEL_ASC',
  TradeAppsStddevSampleModelDesc = 'TRADE_APPS_STDDEV_SAMPLE_MODEL_DESC',
  TradeAppsStddevSampleTrimAsc = 'TRADE_APPS_STDDEV_SAMPLE_TRIM_ASC',
  TradeAppsStddevSampleTrimDesc = 'TRADE_APPS_STDDEV_SAMPLE_TRIM_DESC',
  TradeAppsStddevSampleMileageAsc = 'TRADE_APPS_STDDEV_SAMPLE_MILEAGE_ASC',
  TradeAppsStddevSampleMileageDesc = 'TRADE_APPS_STDDEV_SAMPLE_MILEAGE_DESC',
  TradeAppsStddevSampleConditionAsc = 'TRADE_APPS_STDDEV_SAMPLE_CONDITION_ASC',
  TradeAppsStddevSampleConditionDesc = 'TRADE_APPS_STDDEV_SAMPLE_CONDITION_DESC',
  TradeAppsStddevSampleTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleMaxTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleMaxTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleMinTradeInValueAsc = 'TRADE_APPS_STDDEV_SAMPLE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsStddevSampleMinTradeInValueDesc = 'TRADE_APPS_STDDEV_SAMPLE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsStddevSampleCreatedAtAsc = 'TRADE_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  TradeAppsStddevSampleCreatedAtDesc = 'TRADE_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  TradeAppsStddevSampleUpdatedAtAsc = 'TRADE_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TradeAppsStddevSampleUpdatedAtDesc = 'TRADE_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TradeAppsStddevPopulationIdAsc = 'TRADE_APPS_STDDEV_POPULATION_ID_ASC',
  TradeAppsStddevPopulationIdDesc = 'TRADE_APPS_STDDEV_POPULATION_ID_DESC',
  TradeAppsStddevPopulationLeadIdAsc = 'TRADE_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  TradeAppsStddevPopulationLeadIdDesc = 'TRADE_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  TradeAppsStddevPopulationYearAsc = 'TRADE_APPS_STDDEV_POPULATION_YEAR_ASC',
  TradeAppsStddevPopulationYearDesc = 'TRADE_APPS_STDDEV_POPULATION_YEAR_DESC',
  TradeAppsStddevPopulationMakeAsc = 'TRADE_APPS_STDDEV_POPULATION_MAKE_ASC',
  TradeAppsStddevPopulationMakeDesc = 'TRADE_APPS_STDDEV_POPULATION_MAKE_DESC',
  TradeAppsStddevPopulationModelAsc = 'TRADE_APPS_STDDEV_POPULATION_MODEL_ASC',
  TradeAppsStddevPopulationModelDesc = 'TRADE_APPS_STDDEV_POPULATION_MODEL_DESC',
  TradeAppsStddevPopulationTrimAsc = 'TRADE_APPS_STDDEV_POPULATION_TRIM_ASC',
  TradeAppsStddevPopulationTrimDesc = 'TRADE_APPS_STDDEV_POPULATION_TRIM_DESC',
  TradeAppsStddevPopulationMileageAsc = 'TRADE_APPS_STDDEV_POPULATION_MILEAGE_ASC',
  TradeAppsStddevPopulationMileageDesc = 'TRADE_APPS_STDDEV_POPULATION_MILEAGE_DESC',
  TradeAppsStddevPopulationConditionAsc = 'TRADE_APPS_STDDEV_POPULATION_CONDITION_ASC',
  TradeAppsStddevPopulationConditionDesc = 'TRADE_APPS_STDDEV_POPULATION_CONDITION_DESC',
  TradeAppsStddevPopulationTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationMaxTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationMaxTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationMinTradeInValueAsc = 'TRADE_APPS_STDDEV_POPULATION_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsStddevPopulationMinTradeInValueDesc = 'TRADE_APPS_STDDEV_POPULATION_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsStddevPopulationCreatedAtAsc = 'TRADE_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  TradeAppsStddevPopulationCreatedAtDesc = 'TRADE_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  TradeAppsStddevPopulationUpdatedAtAsc = 'TRADE_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  TradeAppsStddevPopulationUpdatedAtDesc = 'TRADE_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  TradeAppsVarianceSampleIdAsc = 'TRADE_APPS_VARIANCE_SAMPLE_ID_ASC',
  TradeAppsVarianceSampleIdDesc = 'TRADE_APPS_VARIANCE_SAMPLE_ID_DESC',
  TradeAppsVarianceSampleLeadIdAsc = 'TRADE_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  TradeAppsVarianceSampleLeadIdDesc = 'TRADE_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  TradeAppsVarianceSampleYearAsc = 'TRADE_APPS_VARIANCE_SAMPLE_YEAR_ASC',
  TradeAppsVarianceSampleYearDesc = 'TRADE_APPS_VARIANCE_SAMPLE_YEAR_DESC',
  TradeAppsVarianceSampleMakeAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MAKE_ASC',
  TradeAppsVarianceSampleMakeDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MAKE_DESC',
  TradeAppsVarianceSampleModelAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MODEL_ASC',
  TradeAppsVarianceSampleModelDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MODEL_DESC',
  TradeAppsVarianceSampleTrimAsc = 'TRADE_APPS_VARIANCE_SAMPLE_TRIM_ASC',
  TradeAppsVarianceSampleTrimDesc = 'TRADE_APPS_VARIANCE_SAMPLE_TRIM_DESC',
  TradeAppsVarianceSampleMileageAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MILEAGE_ASC',
  TradeAppsVarianceSampleMileageDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MILEAGE_DESC',
  TradeAppsVarianceSampleConditionAsc = 'TRADE_APPS_VARIANCE_SAMPLE_CONDITION_ASC',
  TradeAppsVarianceSampleConditionDesc = 'TRADE_APPS_VARIANCE_SAMPLE_CONDITION_DESC',
  TradeAppsVarianceSampleTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleMaxTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleMaxTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleMinTradeInValueAsc = 'TRADE_APPS_VARIANCE_SAMPLE_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsVarianceSampleMinTradeInValueDesc = 'TRADE_APPS_VARIANCE_SAMPLE_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsVarianceSampleCreatedAtAsc = 'TRADE_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TradeAppsVarianceSampleCreatedAtDesc = 'TRADE_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TradeAppsVarianceSampleUpdatedAtAsc = 'TRADE_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TradeAppsVarianceSampleUpdatedAtDesc = 'TRADE_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TradeAppsVariancePopulationIdAsc = 'TRADE_APPS_VARIANCE_POPULATION_ID_ASC',
  TradeAppsVariancePopulationIdDesc = 'TRADE_APPS_VARIANCE_POPULATION_ID_DESC',
  TradeAppsVariancePopulationLeadIdAsc = 'TRADE_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  TradeAppsVariancePopulationLeadIdDesc = 'TRADE_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  TradeAppsVariancePopulationYearAsc = 'TRADE_APPS_VARIANCE_POPULATION_YEAR_ASC',
  TradeAppsVariancePopulationYearDesc = 'TRADE_APPS_VARIANCE_POPULATION_YEAR_DESC',
  TradeAppsVariancePopulationMakeAsc = 'TRADE_APPS_VARIANCE_POPULATION_MAKE_ASC',
  TradeAppsVariancePopulationMakeDesc = 'TRADE_APPS_VARIANCE_POPULATION_MAKE_DESC',
  TradeAppsVariancePopulationModelAsc = 'TRADE_APPS_VARIANCE_POPULATION_MODEL_ASC',
  TradeAppsVariancePopulationModelDesc = 'TRADE_APPS_VARIANCE_POPULATION_MODEL_DESC',
  TradeAppsVariancePopulationTrimAsc = 'TRADE_APPS_VARIANCE_POPULATION_TRIM_ASC',
  TradeAppsVariancePopulationTrimDesc = 'TRADE_APPS_VARIANCE_POPULATION_TRIM_DESC',
  TradeAppsVariancePopulationMileageAsc = 'TRADE_APPS_VARIANCE_POPULATION_MILEAGE_ASC',
  TradeAppsVariancePopulationMileageDesc = 'TRADE_APPS_VARIANCE_POPULATION_MILEAGE_DESC',
  TradeAppsVariancePopulationConditionAsc = 'TRADE_APPS_VARIANCE_POPULATION_CONDITION_ASC',
  TradeAppsVariancePopulationConditionDesc = 'TRADE_APPS_VARIANCE_POPULATION_CONDITION_DESC',
  TradeAppsVariancePopulationTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationMaxTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_MAX_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationMaxTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_MAX_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationMinTradeInValueAsc = 'TRADE_APPS_VARIANCE_POPULATION_MIN_TRADE_IN_VALUE_ASC',
  TradeAppsVariancePopulationMinTradeInValueDesc = 'TRADE_APPS_VARIANCE_POPULATION_MIN_TRADE_IN_VALUE_DESC',
  TradeAppsVariancePopulationCreatedAtAsc = 'TRADE_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  TradeAppsVariancePopulationCreatedAtDesc = 'TRADE_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  TradeAppsVariancePopulationUpdatedAtAsc = 'TRADE_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TradeAppsVariancePopulationUpdatedAtDesc = 'TRADE_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LeadJourneysCountAsc = 'LEAD_JOURNEYS_COUNT_ASC',
  LeadJourneysCountDesc = 'LEAD_JOURNEYS_COUNT_DESC',
  LeadJourneysSumLeadIdAsc = 'LEAD_JOURNEYS_SUM_LEAD_ID_ASC',
  LeadJourneysSumLeadIdDesc = 'LEAD_JOURNEYS_SUM_LEAD_ID_DESC',
  LeadJourneysSumStageAsc = 'LEAD_JOURNEYS_SUM_STAGE_ASC',
  LeadJourneysSumStageDesc = 'LEAD_JOURNEYS_SUM_STAGE_DESC',
  LeadJourneysSumProductIdAsc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_ASC',
  LeadJourneysSumProductIdDesc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_DESC',
  LeadJourneysSumCreatedAtAsc = 'LEAD_JOURNEYS_SUM_CREATED_AT_ASC',
  LeadJourneysSumCreatedAtDesc = 'LEAD_JOURNEYS_SUM_CREATED_AT_DESC',
  LeadJourneysSumUpdatedAtAsc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_ASC',
  LeadJourneysSumUpdatedAtDesc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_DESC',
  LeadJourneysDistinctCountLeadIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadJourneysDistinctCountLeadIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadJourneysDistinctCountStageAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_ASC',
  LeadJourneysDistinctCountStageDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_DESC',
  LeadJourneysDistinctCountProductIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  LeadJourneysDistinctCountProductIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  LeadJourneysDistinctCountCreatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadJourneysDistinctCountCreatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadJourneysDistinctCountUpdatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadJourneysDistinctCountUpdatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadJourneysMinLeadIdAsc = 'LEAD_JOURNEYS_MIN_LEAD_ID_ASC',
  LeadJourneysMinLeadIdDesc = 'LEAD_JOURNEYS_MIN_LEAD_ID_DESC',
  LeadJourneysMinStageAsc = 'LEAD_JOURNEYS_MIN_STAGE_ASC',
  LeadJourneysMinStageDesc = 'LEAD_JOURNEYS_MIN_STAGE_DESC',
  LeadJourneysMinProductIdAsc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_ASC',
  LeadJourneysMinProductIdDesc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_DESC',
  LeadJourneysMinCreatedAtAsc = 'LEAD_JOURNEYS_MIN_CREATED_AT_ASC',
  LeadJourneysMinCreatedAtDesc = 'LEAD_JOURNEYS_MIN_CREATED_AT_DESC',
  LeadJourneysMinUpdatedAtAsc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_ASC',
  LeadJourneysMinUpdatedAtDesc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_DESC',
  LeadJourneysMaxLeadIdAsc = 'LEAD_JOURNEYS_MAX_LEAD_ID_ASC',
  LeadJourneysMaxLeadIdDesc = 'LEAD_JOURNEYS_MAX_LEAD_ID_DESC',
  LeadJourneysMaxStageAsc = 'LEAD_JOURNEYS_MAX_STAGE_ASC',
  LeadJourneysMaxStageDesc = 'LEAD_JOURNEYS_MAX_STAGE_DESC',
  LeadJourneysMaxProductIdAsc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_ASC',
  LeadJourneysMaxProductIdDesc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_DESC',
  LeadJourneysMaxCreatedAtAsc = 'LEAD_JOURNEYS_MAX_CREATED_AT_ASC',
  LeadJourneysMaxCreatedAtDesc = 'LEAD_JOURNEYS_MAX_CREATED_AT_DESC',
  LeadJourneysMaxUpdatedAtAsc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_ASC',
  LeadJourneysMaxUpdatedAtDesc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_DESC',
  LeadJourneysAverageLeadIdAsc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_ASC',
  LeadJourneysAverageLeadIdDesc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_DESC',
  LeadJourneysAverageStageAsc = 'LEAD_JOURNEYS_AVERAGE_STAGE_ASC',
  LeadJourneysAverageStageDesc = 'LEAD_JOURNEYS_AVERAGE_STAGE_DESC',
  LeadJourneysAverageProductIdAsc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_ASC',
  LeadJourneysAverageProductIdDesc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_DESC',
  LeadJourneysAverageCreatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_ASC',
  LeadJourneysAverageCreatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_DESC',
  LeadJourneysAverageUpdatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_ASC',
  LeadJourneysAverageUpdatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_DESC',
  LeadJourneysStddevSampleLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadJourneysStddevSampleLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadJourneysStddevSampleStageAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_ASC',
  LeadJourneysStddevSampleStageDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_DESC',
  LeadJourneysStddevSampleProductIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysStddevSampleProductIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysStddevSampleCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadJourneysStddevSampleCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadJourneysStddevSampleUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysStddevSampleUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysStddevPopulationLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadJourneysStddevPopulationLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadJourneysStddevPopulationStageAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_ASC',
  LeadJourneysStddevPopulationStageDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_DESC',
  LeadJourneysStddevPopulationProductIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysStddevPopulationProductIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysStddevPopulationCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadJourneysStddevPopulationCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadJourneysStddevPopulationUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadJourneysStddevPopulationUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadJourneysVarianceSampleLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadJourneysVarianceSampleLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadJourneysVarianceSampleStageAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_ASC',
  LeadJourneysVarianceSampleStageDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_DESC',
  LeadJourneysVarianceSampleProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysVarianceSampleProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysVarianceSampleCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadJourneysVarianceSampleCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadJourneysVarianceSampleUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysVarianceSampleUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysVariancePopulationLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadJourneysVariancePopulationLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadJourneysVariancePopulationStageAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_ASC',
  LeadJourneysVariancePopulationStageDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_DESC',
  LeadJourneysVariancePopulationProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysVariancePopulationProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysVariancePopulationCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadJourneysVariancePopulationCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadJourneysVariancePopulationUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadJourneysVariancePopulationUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsCountAsc = 'LENDER_APPS_COUNT_ASC',
  LenderAppsCountDesc = 'LENDER_APPS_COUNT_DESC',
  LenderAppsSumIdAsc = 'LENDER_APPS_SUM_ID_ASC',
  LenderAppsSumIdDesc = 'LENDER_APPS_SUM_ID_DESC',
  LenderAppsSumLeadIdAsc = 'LENDER_APPS_SUM_LEAD_ID_ASC',
  LenderAppsSumLeadIdDesc = 'LENDER_APPS_SUM_LEAD_ID_DESC',
  LenderAppsSumInovatecApplicationNumberAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsSumInovatecApplicationNumberDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsSumInovatecApplicationIdAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsSumInovatecApplicationIdDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsSumYearlyIncomeAsc = 'LENDER_APPS_SUM_YEARLY_INCOME_ASC',
  LenderAppsSumYearlyIncomeDesc = 'LENDER_APPS_SUM_YEARLY_INCOME_DESC',
  LenderAppsSumEmploymentStatusAsc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_ASC',
  LenderAppsSumEmploymentStatusDesc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_DESC',
  LenderAppsSumMonthlyHomePaymentAsc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsSumMonthlyHomePaymentDesc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsSumPreferredOfferIdAsc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_ASC',
  LenderAppsSumPreferredOfferIdDesc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_DESC',
  LenderAppsSumOffersAsc = 'LENDER_APPS_SUM_OFFERS_ASC',
  LenderAppsSumOffersDesc = 'LENDER_APPS_SUM_OFFERS_DESC',
  LenderAppsSumCreatedAtAsc = 'LENDER_APPS_SUM_CREATED_AT_ASC',
  LenderAppsSumCreatedAtDesc = 'LENDER_APPS_SUM_CREATED_AT_DESC',
  LenderAppsSumUpdatedAtAsc = 'LENDER_APPS_SUM_UPDATED_AT_ASC',
  LenderAppsSumUpdatedAtDesc = 'LENDER_APPS_SUM_UPDATED_AT_DESC',
  LenderAppsSumVehicleListingIdAsc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_ASC',
  LenderAppsSumVehicleListingIdDesc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_DESC',
  LenderAppsSumMaritalStatusAsc = 'LENDER_APPS_SUM_MARITAL_STATUS_ASC',
  LenderAppsSumMaritalStatusDesc = 'LENDER_APPS_SUM_MARITAL_STATUS_DESC',
  LenderAppsDistinctCountIdAsc = 'LENDER_APPS_DISTINCT_COUNT_ID_ASC',
  LenderAppsDistinctCountIdDesc = 'LENDER_APPS_DISTINCT_COUNT_ID_DESC',
  LenderAppsDistinctCountLeadIdAsc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderAppsDistinctCountLeadIdDesc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderAppsDistinctCountInovatecApplicationNumberAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsDistinctCountInovatecApplicationNumberDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsDistinctCountInovatecApplicationIdAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsDistinctCountInovatecApplicationIdDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsDistinctCountYearlyIncomeAsc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_ASC',
  LenderAppsDistinctCountYearlyIncomeDesc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_DESC',
  LenderAppsDistinctCountEmploymentStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LenderAppsDistinctCountEmploymentStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LenderAppsDistinctCountMonthlyHomePaymentAsc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsDistinctCountMonthlyHomePaymentDesc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsDistinctCountPreferredOfferIdAsc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_ASC',
  LenderAppsDistinctCountPreferredOfferIdDesc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_DESC',
  LenderAppsDistinctCountOffersAsc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_ASC',
  LenderAppsDistinctCountOffersDesc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_DESC',
  LenderAppsDistinctCountCreatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderAppsDistinctCountCreatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderAppsDistinctCountUpdatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderAppsDistinctCountUpdatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderAppsDistinctCountVehicleListingIdAsc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  LenderAppsDistinctCountVehicleListingIdDesc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  LenderAppsDistinctCountMaritalStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LenderAppsDistinctCountMaritalStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LenderAppsMinIdAsc = 'LENDER_APPS_MIN_ID_ASC',
  LenderAppsMinIdDesc = 'LENDER_APPS_MIN_ID_DESC',
  LenderAppsMinLeadIdAsc = 'LENDER_APPS_MIN_LEAD_ID_ASC',
  LenderAppsMinLeadIdDesc = 'LENDER_APPS_MIN_LEAD_ID_DESC',
  LenderAppsMinInovatecApplicationNumberAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMinInovatecApplicationNumberDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMinInovatecApplicationIdAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMinInovatecApplicationIdDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMinYearlyIncomeAsc = 'LENDER_APPS_MIN_YEARLY_INCOME_ASC',
  LenderAppsMinYearlyIncomeDesc = 'LENDER_APPS_MIN_YEARLY_INCOME_DESC',
  LenderAppsMinEmploymentStatusAsc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_ASC',
  LenderAppsMinEmploymentStatusDesc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_DESC',
  LenderAppsMinMonthlyHomePaymentAsc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMinMonthlyHomePaymentDesc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMinPreferredOfferIdAsc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_ASC',
  LenderAppsMinPreferredOfferIdDesc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_DESC',
  LenderAppsMinOffersAsc = 'LENDER_APPS_MIN_OFFERS_ASC',
  LenderAppsMinOffersDesc = 'LENDER_APPS_MIN_OFFERS_DESC',
  LenderAppsMinCreatedAtAsc = 'LENDER_APPS_MIN_CREATED_AT_ASC',
  LenderAppsMinCreatedAtDesc = 'LENDER_APPS_MIN_CREATED_AT_DESC',
  LenderAppsMinUpdatedAtAsc = 'LENDER_APPS_MIN_UPDATED_AT_ASC',
  LenderAppsMinUpdatedAtDesc = 'LENDER_APPS_MIN_UPDATED_AT_DESC',
  LenderAppsMinVehicleListingIdAsc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_ASC',
  LenderAppsMinVehicleListingIdDesc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_DESC',
  LenderAppsMinMaritalStatusAsc = 'LENDER_APPS_MIN_MARITAL_STATUS_ASC',
  LenderAppsMinMaritalStatusDesc = 'LENDER_APPS_MIN_MARITAL_STATUS_DESC',
  LenderAppsMaxIdAsc = 'LENDER_APPS_MAX_ID_ASC',
  LenderAppsMaxIdDesc = 'LENDER_APPS_MAX_ID_DESC',
  LenderAppsMaxLeadIdAsc = 'LENDER_APPS_MAX_LEAD_ID_ASC',
  LenderAppsMaxLeadIdDesc = 'LENDER_APPS_MAX_LEAD_ID_DESC',
  LenderAppsMaxInovatecApplicationNumberAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMaxInovatecApplicationNumberDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMaxInovatecApplicationIdAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMaxInovatecApplicationIdDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMaxYearlyIncomeAsc = 'LENDER_APPS_MAX_YEARLY_INCOME_ASC',
  LenderAppsMaxYearlyIncomeDesc = 'LENDER_APPS_MAX_YEARLY_INCOME_DESC',
  LenderAppsMaxEmploymentStatusAsc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_ASC',
  LenderAppsMaxEmploymentStatusDesc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_DESC',
  LenderAppsMaxMonthlyHomePaymentAsc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMaxMonthlyHomePaymentDesc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMaxPreferredOfferIdAsc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_ASC',
  LenderAppsMaxPreferredOfferIdDesc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_DESC',
  LenderAppsMaxOffersAsc = 'LENDER_APPS_MAX_OFFERS_ASC',
  LenderAppsMaxOffersDesc = 'LENDER_APPS_MAX_OFFERS_DESC',
  LenderAppsMaxCreatedAtAsc = 'LENDER_APPS_MAX_CREATED_AT_ASC',
  LenderAppsMaxCreatedAtDesc = 'LENDER_APPS_MAX_CREATED_AT_DESC',
  LenderAppsMaxUpdatedAtAsc = 'LENDER_APPS_MAX_UPDATED_AT_ASC',
  LenderAppsMaxUpdatedAtDesc = 'LENDER_APPS_MAX_UPDATED_AT_DESC',
  LenderAppsMaxVehicleListingIdAsc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_ASC',
  LenderAppsMaxVehicleListingIdDesc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_DESC',
  LenderAppsMaxMaritalStatusAsc = 'LENDER_APPS_MAX_MARITAL_STATUS_ASC',
  LenderAppsMaxMaritalStatusDesc = 'LENDER_APPS_MAX_MARITAL_STATUS_DESC',
  LenderAppsAverageIdAsc = 'LENDER_APPS_AVERAGE_ID_ASC',
  LenderAppsAverageIdDesc = 'LENDER_APPS_AVERAGE_ID_DESC',
  LenderAppsAverageLeadIdAsc = 'LENDER_APPS_AVERAGE_LEAD_ID_ASC',
  LenderAppsAverageLeadIdDesc = 'LENDER_APPS_AVERAGE_LEAD_ID_DESC',
  LenderAppsAverageInovatecApplicationNumberAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsAverageInovatecApplicationNumberDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsAverageInovatecApplicationIdAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsAverageInovatecApplicationIdDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsAverageYearlyIncomeAsc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_ASC',
  LenderAppsAverageYearlyIncomeDesc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_DESC',
  LenderAppsAverageEmploymentStatusAsc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LenderAppsAverageEmploymentStatusDesc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LenderAppsAverageMonthlyHomePaymentAsc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsAverageMonthlyHomePaymentDesc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsAveragePreferredOfferIdAsc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_ASC',
  LenderAppsAveragePreferredOfferIdDesc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_DESC',
  LenderAppsAverageOffersAsc = 'LENDER_APPS_AVERAGE_OFFERS_ASC',
  LenderAppsAverageOffersDesc = 'LENDER_APPS_AVERAGE_OFFERS_DESC',
  LenderAppsAverageCreatedAtAsc = 'LENDER_APPS_AVERAGE_CREATED_AT_ASC',
  LenderAppsAverageCreatedAtDesc = 'LENDER_APPS_AVERAGE_CREATED_AT_DESC',
  LenderAppsAverageUpdatedAtAsc = 'LENDER_APPS_AVERAGE_UPDATED_AT_ASC',
  LenderAppsAverageUpdatedAtDesc = 'LENDER_APPS_AVERAGE_UPDATED_AT_DESC',
  LenderAppsAverageVehicleListingIdAsc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  LenderAppsAverageVehicleListingIdDesc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  LenderAppsAverageMaritalStatusAsc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_ASC',
  LenderAppsAverageMaritalStatusDesc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_DESC',
  LenderAppsStddevSampleIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_ID_ASC',
  LenderAppsStddevSampleIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_ID_DESC',
  LenderAppsStddevSampleLeadIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderAppsStddevSampleLeadIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderAppsStddevSampleInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevSampleInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevSampleInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevSampleInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevSampleYearlyIncomeAsc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsStddevSampleYearlyIncomeDesc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsStddevSampleEmploymentStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevSampleEmploymentStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevSampleMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevSampleMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevSamplePreferredOfferIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevSamplePreferredOfferIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevSampleOffersAsc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_ASC',
  LenderAppsStddevSampleOffersDesc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_DESC',
  LenderAppsStddevSampleCreatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderAppsStddevSampleCreatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderAppsStddevSampleUpdatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderAppsStddevSampleUpdatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderAppsStddevSampleVehicleListingIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevSampleVehicleListingIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevSampleMaritalStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsStddevSampleMaritalStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsStddevPopulationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_ID_ASC',
  LenderAppsStddevPopulationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_ID_DESC',
  LenderAppsStddevPopulationLeadIdAsc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderAppsStddevPopulationLeadIdDesc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderAppsStddevPopulationInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevPopulationInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevPopulationInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevPopulationInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevPopulationYearlyIncomeAsc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsStddevPopulationYearlyIncomeDesc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsStddevPopulationEmploymentStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevPopulationEmploymentStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevPopulationMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevPopulationMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevPopulationPreferredOfferIdAsc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevPopulationPreferredOfferIdDesc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevPopulationOffersAsc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_ASC',
  LenderAppsStddevPopulationOffersDesc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_DESC',
  LenderAppsStddevPopulationCreatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderAppsStddevPopulationCreatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderAppsStddevPopulationUpdatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderAppsStddevPopulationUpdatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderAppsStddevPopulationVehicleListingIdAsc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevPopulationVehicleListingIdDesc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevPopulationMaritalStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsStddevPopulationMaritalStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LenderAppsVarianceSampleIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_ASC',
  LenderAppsVarianceSampleIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_DESC',
  LenderAppsVarianceSampleLeadIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderAppsVarianceSampleLeadIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderAppsVarianceSampleInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVarianceSampleInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVarianceSampleInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVarianceSampleInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVarianceSampleYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsVarianceSampleYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsVarianceSampleEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsVarianceSampleEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsVarianceSampleMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVarianceSampleMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVarianceSamplePreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsVarianceSamplePreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsVarianceSampleOffersAsc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_ASC',
  LenderAppsVarianceSampleOffersDesc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_DESC',
  LenderAppsVarianceSampleCreatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderAppsVarianceSampleCreatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderAppsVarianceSampleUpdatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderAppsVarianceSampleUpdatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderAppsVarianceSampleVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsVarianceSampleVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsVarianceSampleMaritalStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsVarianceSampleMaritalStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsVariancePopulationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_ID_ASC',
  LenderAppsVariancePopulationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_ID_DESC',
  LenderAppsVariancePopulationLeadIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderAppsVariancePopulationLeadIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderAppsVariancePopulationInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVariancePopulationInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVariancePopulationInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVariancePopulationInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVariancePopulationYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsVariancePopulationYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsVariancePopulationEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsVariancePopulationEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsVariancePopulationMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVariancePopulationMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVariancePopulationPreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsVariancePopulationPreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsVariancePopulationOffersAsc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_ASC',
  LenderAppsVariancePopulationOffersDesc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_DESC',
  LenderAppsVariancePopulationCreatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderAppsVariancePopulationCreatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderAppsVariancePopulationUpdatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderAppsVariancePopulationUpdatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsVariancePopulationVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsVariancePopulationVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsVariancePopulationMaritalStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsVariancePopulationMaritalStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  AppointmentsCountAsc = 'APPOINTMENTS_COUNT_ASC',
  AppointmentsCountDesc = 'APPOINTMENTS_COUNT_DESC',
  AppointmentsSumIdAsc = 'APPOINTMENTS_SUM_ID_ASC',
  AppointmentsSumIdDesc = 'APPOINTMENTS_SUM_ID_DESC',
  AppointmentsSumLeadIdAsc = 'APPOINTMENTS_SUM_LEAD_ID_ASC',
  AppointmentsSumLeadIdDesc = 'APPOINTMENTS_SUM_LEAD_ID_DESC',
  AppointmentsSumVehicleListingIdAsc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_ASC',
  AppointmentsSumVehicleListingIdDesc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_DESC',
  AppointmentsSumAdditionalInformationAsc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_ASC',
  AppointmentsSumAdditionalInformationDesc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_DESC',
  AppointmentsSumAppointmentAtAsc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_ASC',
  AppointmentsSumAppointmentAtDesc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_DESC',
  AppointmentsSumConfirmedAtAsc = 'APPOINTMENTS_SUM_CONFIRMED_AT_ASC',
  AppointmentsSumConfirmedAtDesc = 'APPOINTMENTS_SUM_CONFIRMED_AT_DESC',
  AppointmentsSumCreatedAtAsc = 'APPOINTMENTS_SUM_CREATED_AT_ASC',
  AppointmentsSumCreatedAtDesc = 'APPOINTMENTS_SUM_CREATED_AT_DESC',
  AppointmentsSumUpdatedAtAsc = 'APPOINTMENTS_SUM_UPDATED_AT_ASC',
  AppointmentsSumUpdatedAtDesc = 'APPOINTMENTS_SUM_UPDATED_AT_DESC',
  AppointmentsDistinctCountIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_ID_ASC',
  AppointmentsDistinctCountIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_ID_DESC',
  AppointmentsDistinctCountLeadIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  AppointmentsDistinctCountLeadIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  AppointmentsDistinctCountVehicleListingIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  AppointmentsDistinctCountVehicleListingIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  AppointmentsDistinctCountAdditionalInformationAsc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  AppointmentsDistinctCountAdditionalInformationDesc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  AppointmentsDistinctCountAppointmentAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_ASC',
  AppointmentsDistinctCountAppointmentAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_DESC',
  AppointmentsDistinctCountConfirmedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_ASC',
  AppointmentsDistinctCountConfirmedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_DESC',
  AppointmentsDistinctCountCreatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  AppointmentsDistinctCountCreatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  AppointmentsDistinctCountUpdatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AppointmentsDistinctCountUpdatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AppointmentsMinIdAsc = 'APPOINTMENTS_MIN_ID_ASC',
  AppointmentsMinIdDesc = 'APPOINTMENTS_MIN_ID_DESC',
  AppointmentsMinLeadIdAsc = 'APPOINTMENTS_MIN_LEAD_ID_ASC',
  AppointmentsMinLeadIdDesc = 'APPOINTMENTS_MIN_LEAD_ID_DESC',
  AppointmentsMinVehicleListingIdAsc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_ASC',
  AppointmentsMinVehicleListingIdDesc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_DESC',
  AppointmentsMinAdditionalInformationAsc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMinAdditionalInformationDesc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMinAppointmentAtAsc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_ASC',
  AppointmentsMinAppointmentAtDesc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_DESC',
  AppointmentsMinConfirmedAtAsc = 'APPOINTMENTS_MIN_CONFIRMED_AT_ASC',
  AppointmentsMinConfirmedAtDesc = 'APPOINTMENTS_MIN_CONFIRMED_AT_DESC',
  AppointmentsMinCreatedAtAsc = 'APPOINTMENTS_MIN_CREATED_AT_ASC',
  AppointmentsMinCreatedAtDesc = 'APPOINTMENTS_MIN_CREATED_AT_DESC',
  AppointmentsMinUpdatedAtAsc = 'APPOINTMENTS_MIN_UPDATED_AT_ASC',
  AppointmentsMinUpdatedAtDesc = 'APPOINTMENTS_MIN_UPDATED_AT_DESC',
  AppointmentsMaxIdAsc = 'APPOINTMENTS_MAX_ID_ASC',
  AppointmentsMaxIdDesc = 'APPOINTMENTS_MAX_ID_DESC',
  AppointmentsMaxLeadIdAsc = 'APPOINTMENTS_MAX_LEAD_ID_ASC',
  AppointmentsMaxLeadIdDesc = 'APPOINTMENTS_MAX_LEAD_ID_DESC',
  AppointmentsMaxVehicleListingIdAsc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_ASC',
  AppointmentsMaxVehicleListingIdDesc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_DESC',
  AppointmentsMaxAdditionalInformationAsc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMaxAdditionalInformationDesc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMaxAppointmentAtAsc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_ASC',
  AppointmentsMaxAppointmentAtDesc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_DESC',
  AppointmentsMaxConfirmedAtAsc = 'APPOINTMENTS_MAX_CONFIRMED_AT_ASC',
  AppointmentsMaxConfirmedAtDesc = 'APPOINTMENTS_MAX_CONFIRMED_AT_DESC',
  AppointmentsMaxCreatedAtAsc = 'APPOINTMENTS_MAX_CREATED_AT_ASC',
  AppointmentsMaxCreatedAtDesc = 'APPOINTMENTS_MAX_CREATED_AT_DESC',
  AppointmentsMaxUpdatedAtAsc = 'APPOINTMENTS_MAX_UPDATED_AT_ASC',
  AppointmentsMaxUpdatedAtDesc = 'APPOINTMENTS_MAX_UPDATED_AT_DESC',
  AppointmentsAverageIdAsc = 'APPOINTMENTS_AVERAGE_ID_ASC',
  AppointmentsAverageIdDesc = 'APPOINTMENTS_AVERAGE_ID_DESC',
  AppointmentsAverageLeadIdAsc = 'APPOINTMENTS_AVERAGE_LEAD_ID_ASC',
  AppointmentsAverageLeadIdDesc = 'APPOINTMENTS_AVERAGE_LEAD_ID_DESC',
  AppointmentsAverageVehicleListingIdAsc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  AppointmentsAverageVehicleListingIdDesc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  AppointmentsAverageAdditionalInformationAsc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsAverageAdditionalInformationDesc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsAverageAppointmentAtAsc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_ASC',
  AppointmentsAverageAppointmentAtDesc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_DESC',
  AppointmentsAverageConfirmedAtAsc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_ASC',
  AppointmentsAverageConfirmedAtDesc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_DESC',
  AppointmentsAverageCreatedAtAsc = 'APPOINTMENTS_AVERAGE_CREATED_AT_ASC',
  AppointmentsAverageCreatedAtDesc = 'APPOINTMENTS_AVERAGE_CREATED_AT_DESC',
  AppointmentsAverageUpdatedAtAsc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_ASC',
  AppointmentsAverageUpdatedAtDesc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_DESC',
  AppointmentsStddevSampleIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_ASC',
  AppointmentsStddevSampleIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_DESC',
  AppointmentsStddevSampleLeadIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AppointmentsStddevSampleLeadIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AppointmentsStddevSampleVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevSampleVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevSampleAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevSampleAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevSampleAppointmentAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsStddevSampleAppointmentAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsStddevSampleConfirmedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsStddevSampleConfirmedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsStddevSampleCreatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AppointmentsStddevSampleCreatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AppointmentsStddevSampleUpdatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AppointmentsStddevSampleUpdatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AppointmentsStddevPopulationIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_ID_ASC',
  AppointmentsStddevPopulationIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_ID_DESC',
  AppointmentsStddevPopulationLeadIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  AppointmentsStddevPopulationLeadIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  AppointmentsStddevPopulationVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevPopulationVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevPopulationAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevPopulationAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevPopulationAppointmentAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsStddevPopulationAppointmentAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsStddevPopulationConfirmedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsStddevPopulationConfirmedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsStddevPopulationCreatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  AppointmentsStddevPopulationCreatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  AppointmentsStddevPopulationUpdatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AppointmentsStddevPopulationUpdatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AppointmentsVarianceSampleIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_ASC',
  AppointmentsVarianceSampleIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_DESC',
  AppointmentsVarianceSampleLeadIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AppointmentsVarianceSampleLeadIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AppointmentsVarianceSampleVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsVarianceSampleVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsVarianceSampleAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVarianceSampleAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVarianceSampleAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsVarianceSampleAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsVarianceSampleConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsVarianceSampleConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsVarianceSampleCreatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AppointmentsVarianceSampleCreatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AppointmentsVarianceSampleUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AppointmentsVarianceSampleUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AppointmentsVariancePopulationIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_ASC',
  AppointmentsVariancePopulationIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_DESC',
  AppointmentsVariancePopulationLeadIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AppointmentsVariancePopulationLeadIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  AppointmentsVariancePopulationVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsVariancePopulationVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsVariancePopulationAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVariancePopulationAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVariancePopulationAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsVariancePopulationAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsVariancePopulationConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsVariancePopulationConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsVariancePopulationCreatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AppointmentsVariancePopulationCreatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AppointmentsVariancePopulationUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AppointmentsVariancePopulationUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC',
  EventsCountAsc = 'EVENTS_COUNT_ASC',
  EventsCountDesc = 'EVENTS_COUNT_DESC',
  EventsSumIdAsc = 'EVENTS_SUM_ID_ASC',
  EventsSumIdDesc = 'EVENTS_SUM_ID_DESC',
  EventsSumLeadIdAsc = 'EVENTS_SUM_LEAD_ID_ASC',
  EventsSumLeadIdDesc = 'EVENTS_SUM_LEAD_ID_DESC',
  EventsSumRequestIdAsc = 'EVENTS_SUM_REQUEST_ID_ASC',
  EventsSumRequestIdDesc = 'EVENTS_SUM_REQUEST_ID_DESC',
  EventsSumMetaAsc = 'EVENTS_SUM_META_ASC',
  EventsSumMetaDesc = 'EVENTS_SUM_META_DESC',
  EventsSumTypeAsc = 'EVENTS_SUM_TYPE_ASC',
  EventsSumTypeDesc = 'EVENTS_SUM_TYPE_DESC',
  EventsSumErrorAsc = 'EVENTS_SUM_ERROR_ASC',
  EventsSumErrorDesc = 'EVENTS_SUM_ERROR_DESC',
  EventsSumCreatedAtAsc = 'EVENTS_SUM_CREATED_AT_ASC',
  EventsSumCreatedAtDesc = 'EVENTS_SUM_CREATED_AT_DESC',
  EventsSumUpdatedAtAsc = 'EVENTS_SUM_UPDATED_AT_ASC',
  EventsSumUpdatedAtDesc = 'EVENTS_SUM_UPDATED_AT_DESC',
  EventsDistinctCountIdAsc = 'EVENTS_DISTINCT_COUNT_ID_ASC',
  EventsDistinctCountIdDesc = 'EVENTS_DISTINCT_COUNT_ID_DESC',
  EventsDistinctCountLeadIdAsc = 'EVENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  EventsDistinctCountLeadIdDesc = 'EVENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  EventsDistinctCountRequestIdAsc = 'EVENTS_DISTINCT_COUNT_REQUEST_ID_ASC',
  EventsDistinctCountRequestIdDesc = 'EVENTS_DISTINCT_COUNT_REQUEST_ID_DESC',
  EventsDistinctCountMetaAsc = 'EVENTS_DISTINCT_COUNT_META_ASC',
  EventsDistinctCountMetaDesc = 'EVENTS_DISTINCT_COUNT_META_DESC',
  EventsDistinctCountTypeAsc = 'EVENTS_DISTINCT_COUNT_TYPE_ASC',
  EventsDistinctCountTypeDesc = 'EVENTS_DISTINCT_COUNT_TYPE_DESC',
  EventsDistinctCountErrorAsc = 'EVENTS_DISTINCT_COUNT_ERROR_ASC',
  EventsDistinctCountErrorDesc = 'EVENTS_DISTINCT_COUNT_ERROR_DESC',
  EventsDistinctCountCreatedAtAsc = 'EVENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  EventsDistinctCountCreatedAtDesc = 'EVENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  EventsDistinctCountUpdatedAtAsc = 'EVENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  EventsDistinctCountUpdatedAtDesc = 'EVENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  EventsMinIdAsc = 'EVENTS_MIN_ID_ASC',
  EventsMinIdDesc = 'EVENTS_MIN_ID_DESC',
  EventsMinLeadIdAsc = 'EVENTS_MIN_LEAD_ID_ASC',
  EventsMinLeadIdDesc = 'EVENTS_MIN_LEAD_ID_DESC',
  EventsMinRequestIdAsc = 'EVENTS_MIN_REQUEST_ID_ASC',
  EventsMinRequestIdDesc = 'EVENTS_MIN_REQUEST_ID_DESC',
  EventsMinMetaAsc = 'EVENTS_MIN_META_ASC',
  EventsMinMetaDesc = 'EVENTS_MIN_META_DESC',
  EventsMinTypeAsc = 'EVENTS_MIN_TYPE_ASC',
  EventsMinTypeDesc = 'EVENTS_MIN_TYPE_DESC',
  EventsMinErrorAsc = 'EVENTS_MIN_ERROR_ASC',
  EventsMinErrorDesc = 'EVENTS_MIN_ERROR_DESC',
  EventsMinCreatedAtAsc = 'EVENTS_MIN_CREATED_AT_ASC',
  EventsMinCreatedAtDesc = 'EVENTS_MIN_CREATED_AT_DESC',
  EventsMinUpdatedAtAsc = 'EVENTS_MIN_UPDATED_AT_ASC',
  EventsMinUpdatedAtDesc = 'EVENTS_MIN_UPDATED_AT_DESC',
  EventsMaxIdAsc = 'EVENTS_MAX_ID_ASC',
  EventsMaxIdDesc = 'EVENTS_MAX_ID_DESC',
  EventsMaxLeadIdAsc = 'EVENTS_MAX_LEAD_ID_ASC',
  EventsMaxLeadIdDesc = 'EVENTS_MAX_LEAD_ID_DESC',
  EventsMaxRequestIdAsc = 'EVENTS_MAX_REQUEST_ID_ASC',
  EventsMaxRequestIdDesc = 'EVENTS_MAX_REQUEST_ID_DESC',
  EventsMaxMetaAsc = 'EVENTS_MAX_META_ASC',
  EventsMaxMetaDesc = 'EVENTS_MAX_META_DESC',
  EventsMaxTypeAsc = 'EVENTS_MAX_TYPE_ASC',
  EventsMaxTypeDesc = 'EVENTS_MAX_TYPE_DESC',
  EventsMaxErrorAsc = 'EVENTS_MAX_ERROR_ASC',
  EventsMaxErrorDesc = 'EVENTS_MAX_ERROR_DESC',
  EventsMaxCreatedAtAsc = 'EVENTS_MAX_CREATED_AT_ASC',
  EventsMaxCreatedAtDesc = 'EVENTS_MAX_CREATED_AT_DESC',
  EventsMaxUpdatedAtAsc = 'EVENTS_MAX_UPDATED_AT_ASC',
  EventsMaxUpdatedAtDesc = 'EVENTS_MAX_UPDATED_AT_DESC',
  EventsAverageIdAsc = 'EVENTS_AVERAGE_ID_ASC',
  EventsAverageIdDesc = 'EVENTS_AVERAGE_ID_DESC',
  EventsAverageLeadIdAsc = 'EVENTS_AVERAGE_LEAD_ID_ASC',
  EventsAverageLeadIdDesc = 'EVENTS_AVERAGE_LEAD_ID_DESC',
  EventsAverageRequestIdAsc = 'EVENTS_AVERAGE_REQUEST_ID_ASC',
  EventsAverageRequestIdDesc = 'EVENTS_AVERAGE_REQUEST_ID_DESC',
  EventsAverageMetaAsc = 'EVENTS_AVERAGE_META_ASC',
  EventsAverageMetaDesc = 'EVENTS_AVERAGE_META_DESC',
  EventsAverageTypeAsc = 'EVENTS_AVERAGE_TYPE_ASC',
  EventsAverageTypeDesc = 'EVENTS_AVERAGE_TYPE_DESC',
  EventsAverageErrorAsc = 'EVENTS_AVERAGE_ERROR_ASC',
  EventsAverageErrorDesc = 'EVENTS_AVERAGE_ERROR_DESC',
  EventsAverageCreatedAtAsc = 'EVENTS_AVERAGE_CREATED_AT_ASC',
  EventsAverageCreatedAtDesc = 'EVENTS_AVERAGE_CREATED_AT_DESC',
  EventsAverageUpdatedAtAsc = 'EVENTS_AVERAGE_UPDATED_AT_ASC',
  EventsAverageUpdatedAtDesc = 'EVENTS_AVERAGE_UPDATED_AT_DESC',
  EventsStddevSampleIdAsc = 'EVENTS_STDDEV_SAMPLE_ID_ASC',
  EventsStddevSampleIdDesc = 'EVENTS_STDDEV_SAMPLE_ID_DESC',
  EventsStddevSampleLeadIdAsc = 'EVENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  EventsStddevSampleLeadIdDesc = 'EVENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  EventsStddevSampleRequestIdAsc = 'EVENTS_STDDEV_SAMPLE_REQUEST_ID_ASC',
  EventsStddevSampleRequestIdDesc = 'EVENTS_STDDEV_SAMPLE_REQUEST_ID_DESC',
  EventsStddevSampleMetaAsc = 'EVENTS_STDDEV_SAMPLE_META_ASC',
  EventsStddevSampleMetaDesc = 'EVENTS_STDDEV_SAMPLE_META_DESC',
  EventsStddevSampleTypeAsc = 'EVENTS_STDDEV_SAMPLE_TYPE_ASC',
  EventsStddevSampleTypeDesc = 'EVENTS_STDDEV_SAMPLE_TYPE_DESC',
  EventsStddevSampleErrorAsc = 'EVENTS_STDDEV_SAMPLE_ERROR_ASC',
  EventsStddevSampleErrorDesc = 'EVENTS_STDDEV_SAMPLE_ERROR_DESC',
  EventsStddevSampleCreatedAtAsc = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  EventsStddevSampleCreatedAtDesc = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  EventsStddevSampleUpdatedAtAsc = 'EVENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EventsStddevSampleUpdatedAtDesc = 'EVENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EventsStddevPopulationIdAsc = 'EVENTS_STDDEV_POPULATION_ID_ASC',
  EventsStddevPopulationIdDesc = 'EVENTS_STDDEV_POPULATION_ID_DESC',
  EventsStddevPopulationLeadIdAsc = 'EVENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  EventsStddevPopulationLeadIdDesc = 'EVENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  EventsStddevPopulationRequestIdAsc = 'EVENTS_STDDEV_POPULATION_REQUEST_ID_ASC',
  EventsStddevPopulationRequestIdDesc = 'EVENTS_STDDEV_POPULATION_REQUEST_ID_DESC',
  EventsStddevPopulationMetaAsc = 'EVENTS_STDDEV_POPULATION_META_ASC',
  EventsStddevPopulationMetaDesc = 'EVENTS_STDDEV_POPULATION_META_DESC',
  EventsStddevPopulationTypeAsc = 'EVENTS_STDDEV_POPULATION_TYPE_ASC',
  EventsStddevPopulationTypeDesc = 'EVENTS_STDDEV_POPULATION_TYPE_DESC',
  EventsStddevPopulationErrorAsc = 'EVENTS_STDDEV_POPULATION_ERROR_ASC',
  EventsStddevPopulationErrorDesc = 'EVENTS_STDDEV_POPULATION_ERROR_DESC',
  EventsStddevPopulationCreatedAtAsc = 'EVENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  EventsStddevPopulationCreatedAtDesc = 'EVENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  EventsStddevPopulationUpdatedAtAsc = 'EVENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  EventsStddevPopulationUpdatedAtDesc = 'EVENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  EventsVarianceSampleIdAsc = 'EVENTS_VARIANCE_SAMPLE_ID_ASC',
  EventsVarianceSampleIdDesc = 'EVENTS_VARIANCE_SAMPLE_ID_DESC',
  EventsVarianceSampleLeadIdAsc = 'EVENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  EventsVarianceSampleLeadIdDesc = 'EVENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  EventsVarianceSampleRequestIdAsc = 'EVENTS_VARIANCE_SAMPLE_REQUEST_ID_ASC',
  EventsVarianceSampleRequestIdDesc = 'EVENTS_VARIANCE_SAMPLE_REQUEST_ID_DESC',
  EventsVarianceSampleMetaAsc = 'EVENTS_VARIANCE_SAMPLE_META_ASC',
  EventsVarianceSampleMetaDesc = 'EVENTS_VARIANCE_SAMPLE_META_DESC',
  EventsVarianceSampleTypeAsc = 'EVENTS_VARIANCE_SAMPLE_TYPE_ASC',
  EventsVarianceSampleTypeDesc = 'EVENTS_VARIANCE_SAMPLE_TYPE_DESC',
  EventsVarianceSampleErrorAsc = 'EVENTS_VARIANCE_SAMPLE_ERROR_ASC',
  EventsVarianceSampleErrorDesc = 'EVENTS_VARIANCE_SAMPLE_ERROR_DESC',
  EventsVarianceSampleCreatedAtAsc = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EventsVarianceSampleCreatedAtDesc = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EventsVarianceSampleUpdatedAtAsc = 'EVENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EventsVarianceSampleUpdatedAtDesc = 'EVENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EventsVariancePopulationIdAsc = 'EVENTS_VARIANCE_POPULATION_ID_ASC',
  EventsVariancePopulationIdDesc = 'EVENTS_VARIANCE_POPULATION_ID_DESC',
  EventsVariancePopulationLeadIdAsc = 'EVENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  EventsVariancePopulationLeadIdDesc = 'EVENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  EventsVariancePopulationRequestIdAsc = 'EVENTS_VARIANCE_POPULATION_REQUEST_ID_ASC',
  EventsVariancePopulationRequestIdDesc = 'EVENTS_VARIANCE_POPULATION_REQUEST_ID_DESC',
  EventsVariancePopulationMetaAsc = 'EVENTS_VARIANCE_POPULATION_META_ASC',
  EventsVariancePopulationMetaDesc = 'EVENTS_VARIANCE_POPULATION_META_DESC',
  EventsVariancePopulationTypeAsc = 'EVENTS_VARIANCE_POPULATION_TYPE_ASC',
  EventsVariancePopulationTypeDesc = 'EVENTS_VARIANCE_POPULATION_TYPE_DESC',
  EventsVariancePopulationErrorAsc = 'EVENTS_VARIANCE_POPULATION_ERROR_ASC',
  EventsVariancePopulationErrorDesc = 'EVENTS_VARIANCE_POPULATION_ERROR_DESC',
  EventsVariancePopulationCreatedAtAsc = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  EventsVariancePopulationCreatedAtDesc = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  EventsVariancePopulationUpdatedAtAsc = 'EVENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EventsVariancePopulationUpdatedAtDesc = 'EVENTS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type LenderApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderQuoteLenderAppIdAndLeadId: LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnection;
};


export type LenderAppLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

export type LenderAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderAppVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderApp` object types. */
export type LenderAppAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderApp` object to be included within the aggregate. */
  filter?: Maybe<LenderAppFilter>;
  /** Sum aggregate over matching `LenderApp` objects. */
  sum?: Maybe<LenderAppSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderApp` objects. */
  distinctCount?: Maybe<LenderAppDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderApp` objects. */
  min?: Maybe<LenderAppMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderApp` objects. */
  max?: Maybe<LenderAppMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderApp` objects. */
  average?: Maybe<LenderAppAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderApp` objects. */
  stddevSample?: Maybe<LenderAppStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderApp` objects. */
  stddevPopulation?: Maybe<LenderAppStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderApp` objects. */
  varianceSample?: Maybe<LenderAppVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderApp` objects. */
  variancePopulation?: Maybe<LenderAppVariancePopulationAggregateFilter>;
};

export type LenderAppAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Mean average of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Mean average of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LenderApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LenderAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `inovatecApplicationNumber` field. */
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `inovatecApplicationId` field. */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `yearlyIncome` field. */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `preferredOfferId` field. */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `offers` field. */
  offers?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<Scalars['String']>;
};

export type LenderAppDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  inovatecApplicationNumber?: Maybe<BigIntFilter>;
  inovatecApplicationId?: Maybe<BigIntFilter>;
  yearlyIncome?: Maybe<BigIntFilter>;
  employmentStatus?: Maybe<BigIntFilter>;
  monthlyHomePayment?: Maybe<BigIntFilter>;
  preferredOfferId?: Maybe<BigIntFilter>;
  offers?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
  maritalStatus?: Maybe<BigIntFilter>;
};

export type LenderAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inovatecApplicationNumber across the matching connection */
  inovatecApplicationNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigInt']>;
  /** Distinct count of employmentStatus across the matching connection */
  employmentStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of offers across the matching connection */
  offers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maritalStatus across the matching connection */
  maritalStatus?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type LenderAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `inovatecApplicationNumber` field. */
  inovatecApplicationNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `inovatecApplicationId` field. */
  inovatecApplicationId?: Maybe<IntFilter>;
  /** Filter by the object’s `yearlyIncome` field. */
  yearlyIncome?: Maybe<IntFilter>;
  /** Filter by the object’s `employmentStatus` field. */
  employmentStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `monthlyHomePayment` field. */
  monthlyHomePayment?: Maybe<IntFilter>;
  /** Filter by the object’s `preferredOfferId` field. */
  preferredOfferId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `vehicleListingId` field. */
  vehicleListingId?: Maybe<IntFilter>;
  /** Filter by the object’s `maritalStatus` field. */
  maritalStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `lenderQuotes` relation. */
  lenderQuotes?: Maybe<LenderAppToManyLenderQuoteFilter>;
  /** Some related `lenderQuotes` exist. */
  lenderQuotesExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `vehicleListing` relation. */
  vehicleListing?: Maybe<VehicleListingFilter>;
  /** A related `vehicleListing` exists. */
  vehicleListingExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderAppFilter>;
};

/** Grouping methods for `LenderApp` for usage during aggregation. */
export enum LenderAppGroupBy {
  LeadId = 'LEAD_ID',
  InovatecApplicationNumber = 'INOVATEC_APPLICATION_NUMBER',
  InovatecApplicationId = 'INOVATEC_APPLICATION_ID',
  YearlyIncome = 'YEARLY_INCOME',
  EmploymentStatus = 'EMPLOYMENT_STATUS',
  MonthlyHomePayment = 'MONTHLY_HOME_PAYMENT',
  PreferredOfferId = 'PREFERRED_OFFER_ID',
  Offers = 'OFFERS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  VehicleListingId = 'VEHICLE_LISTING_ID',
  MaritalStatus = 'MARITAL_STATUS'
}

export type LenderAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

/** Conditions for `LenderApp` aggregates. */
export type LenderAppHavingInput = {
  AND?: Maybe<Array<LenderAppHavingInput>>;
  OR?: Maybe<Array<LenderAppHavingInput>>;
  sum?: Maybe<LenderAppHavingSumInput>;
  distinctCount?: Maybe<LenderAppHavingDistinctCountInput>;
  min?: Maybe<LenderAppHavingMinInput>;
  max?: Maybe<LenderAppHavingMaxInput>;
  average?: Maybe<LenderAppHavingAverageInput>;
  stddevSample?: Maybe<LenderAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderAppHavingVariancePopulationInput>;
};

export type LenderAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

export type LenderAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  inovatecApplicationId?: Maybe<HavingIntFilter>;
  yearlyIncome?: Maybe<HavingIntFilter>;
  monthlyHomePayment?: Maybe<HavingIntFilter>;
  preferredOfferId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
  vehicleListingId?: Maybe<HavingIntFilter>;
};

/** An input for mutations affecting `LenderApp` */
export type LenderAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** Input for the nested mutation of `lead` in the `LenderAppInput` mutation. */
export type LenderAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLenderAppForLenderAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderAppOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LenderAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `lenderApp` in the `LeadInput` mutation. */
export type LenderAppLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderAppLenderAppPkeyConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderAppNodeIdConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderAppLenderAppPkeyDelete>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderAppOnLenderAppForLenderAppLeadIdFkeyUsingLenderAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate>>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderAppLeadIdFkeyLenderAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LenderAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderAppLeadIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderQuote`, and the cursor to aid in pagination. */
  edges: Array<LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes: LenderQuotesConnection;
};


/** A `Lead` edge in the connection, with data from `LenderQuote`. */
export type LenderAppLeadsByLenderQuoteLenderAppIdAndLeadIdManyToManyEdgeLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};

/** The fields on `lenderApp` to look up the row to connect. */
export type LenderAppLenderAppPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderApp` to look up the row to delete. */
export type LenderAppLenderAppPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderAppMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  inovatecApplicationId?: Maybe<IntFilter>;
  yearlyIncome?: Maybe<IntFilter>;
  monthlyHomePayment?: Maybe<IntFilter>;
  preferredOfferId?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type LenderAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Maximum of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Maximum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Maximum of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

export type LenderAppMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  inovatecApplicationId?: Maybe<IntFilter>;
  yearlyIncome?: Maybe<IntFilter>;
  monthlyHomePayment?: Maybe<IntFilter>;
  preferredOfferId?: Maybe<IntFilter>;
  vehicleListingId?: Maybe<IntFilter>;
};

export type LenderAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  /** Minimum of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['Int']>;
  /** Minimum of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  /** Minimum of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderAppNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderAppForLenderAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderAppForLenderAppLeadIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderAppForLenderAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderAppForLenderAppVehicleListingIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** The fields on `lenderApp` to look up the row to update. */
export type LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: UpdateLenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderApp`. Fields that are set will be updated. */
export type LenderAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

export type LenderAppResult = {
  lenderAppId: Scalars['Int'];
  preferredOfferId?: Maybe<Scalars['Int']>;
  vehicleListing?: Maybe<VehicleListing>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
};

export type LenderAppStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  inovatecApplicationId?: Maybe<BigIntFilter>;
  yearlyIncome?: Maybe<BigIntFilter>;
  monthlyHomePayment?: Maybe<BigIntFilter>;
  preferredOfferId?: Maybe<BigIntFilter>;
  vehicleListingId?: Maybe<BigIntFilter>;
};

export type LenderAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of inovatecApplicationId across the matching connection */
  inovatecApplicationId: Scalars['BigInt'];
  /** Sum of yearlyIncome across the matching connection */
  yearlyIncome: Scalars['BigInt'];
  /** Sum of monthlyHomePayment across the matching connection */
  monthlyHomePayment: Scalars['BigInt'];
  /** Sum of preferredOfferId across the matching connection */
  preferredOfferId: Scalars['BigInt'];
  /** Sum of vehicleListingId across the matching connection */
  vehicleListingId: Scalars['BigInt'];
};

/** A filter to be used against many `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LenderAppToManyLenderQuoteFilter = {
  /** Every related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderQuoteFilter>;
  /** Some related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderQuoteFilter>;
  /** No related `LenderQuote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderQuoteFilter>;
  /** Aggregates across related `LenderQuote` match the filter criteria. */
  aggregates?: Maybe<LenderQuoteAggregatesFilter>;
};

export type LenderAppVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Population variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Population variance of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

export type LenderAppVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  inovatecApplicationId?: Maybe<BigFloatFilter>;
  yearlyIncome?: Maybe<BigFloatFilter>;
  monthlyHomePayment?: Maybe<BigFloatFilter>;
  preferredOfferId?: Maybe<BigFloatFilter>;
  vehicleListingId?: Maybe<BigFloatFilter>;
};

export type LenderAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of inovatecApplicationId across the matching connection */
  inovatecApplicationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of yearlyIncome across the matching connection */
  yearlyIncome?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of monthlyHomePayment across the matching connection */
  monthlyHomePayment?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of preferredOfferId across the matching connection */
  preferredOfferId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleListingId across the matching connection */
  vehicleListingId?: Maybe<Scalars['BigFloat']>;
};

/** Input for the nested mutation of `vehicleListing` in the `LenderAppInput` mutation. */
export type LenderAppVehicleListingIdFkeyInput = {
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectById?: Maybe<VehicleListingVehicleListingsPkeyConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  connectByNodeId?: Maybe<VehicleListingNodeIdConnect>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteById?: Maybe<VehicleListingVehicleListingsPkeyDelete>;
  /** The primary key(s) for `vehicleListing` for the far side of the relationship. */
  deleteByNodeId?: Maybe<VehicleListingNodeIdDelete>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateById?: Maybe<VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyUsingVehicleListingsPkeyUpdate>;
  /** The primary key(s) and patch data for `vehicleListing` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate>;
  /** A `VehicleListingInput` object that will be created and connected to this object. */
  create?: Maybe<LenderAppVehicleListingIdFkeyVehicleListingCreateInput>;
};

/** Input for the nested mutation of `lenderApp` in the `VehicleListingInput` mutation. */
export type LenderAppVehicleListingIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderApp` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderAppLenderAppPkeyConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderAppNodeIdConnect>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderAppLenderAppPkeyDelete>>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderAppNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderAppOnLenderAppForLenderAppVehicleListingIdFkeyUsingLenderAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate>>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderAppVehicleListingIdFkeyLenderAppCreateInput>>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderAppVehicleListingIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** The `vehicleListing` to be created by this mutation. */
export type LenderAppVehicleListingIdFkeyVehicleListingCreateInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** A connection to a list of `LenderApp` values. */
export type LenderAppsConnection = {
  /** A list of `LenderApp` objects. */
  nodes: Array<LenderApp>;
  /** A list of edges which contains the `LenderApp` and cursor to aid in pagination. */
  edges: Array<LenderAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderAppAggregates>>;
};


/** A connection to a list of `LenderApp` values. */
export type LenderAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderAppGroupBy>;
  having?: Maybe<LenderAppHavingInput>;
};

/** A `LenderApp` edge in the connection. */
export type LenderAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderApp` at the end of the edge. */
  node: LenderApp;
};

/** Methods to use when ordering `LenderApp`. */
export enum LenderAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  InovatecApplicationNumberAsc = 'INOVATEC_APPLICATION_NUMBER_ASC',
  InovatecApplicationNumberDesc = 'INOVATEC_APPLICATION_NUMBER_DESC',
  InovatecApplicationIdAsc = 'INOVATEC_APPLICATION_ID_ASC',
  InovatecApplicationIdDesc = 'INOVATEC_APPLICATION_ID_DESC',
  YearlyIncomeAsc = 'YEARLY_INCOME_ASC',
  YearlyIncomeDesc = 'YEARLY_INCOME_DESC',
  EmploymentStatusAsc = 'EMPLOYMENT_STATUS_ASC',
  EmploymentStatusDesc = 'EMPLOYMENT_STATUS_DESC',
  MonthlyHomePaymentAsc = 'MONTHLY_HOME_PAYMENT_ASC',
  MonthlyHomePaymentDesc = 'MONTHLY_HOME_PAYMENT_DESC',
  PreferredOfferIdAsc = 'PREFERRED_OFFER_ID_ASC',
  PreferredOfferIdDesc = 'PREFERRED_OFFER_ID_DESC',
  OffersAsc = 'OFFERS_ASC',
  OffersDesc = 'OFFERS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VehicleListingIdAsc = 'VEHICLE_LISTING_ID_ASC',
  VehicleListingIdDesc = 'VEHICLE_LISTING_ID_DESC',
  MaritalStatusAsc = 'MARITAL_STATUS_ASC',
  MaritalStatusDesc = 'MARITAL_STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderQuotesCountAsc = 'LENDER_QUOTES_COUNT_ASC',
  LenderQuotesCountDesc = 'LENDER_QUOTES_COUNT_DESC',
  LenderQuotesSumIdAsc = 'LENDER_QUOTES_SUM_ID_ASC',
  LenderQuotesSumIdDesc = 'LENDER_QUOTES_SUM_ID_DESC',
  LenderQuotesSumLeadIdAsc = 'LENDER_QUOTES_SUM_LEAD_ID_ASC',
  LenderQuotesSumLeadIdDesc = 'LENDER_QUOTES_SUM_LEAD_ID_DESC',
  LenderQuotesSumLenderAppIdAsc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_ASC',
  LenderQuotesSumLenderAppIdDesc = 'LENDER_QUOTES_SUM_LENDER_APP_ID_DESC',
  LenderQuotesSumProviderAsc = 'LENDER_QUOTES_SUM_PROVIDER_ASC',
  LenderQuotesSumProviderDesc = 'LENDER_QUOTES_SUM_PROVIDER_DESC',
  LenderQuotesSumQuoteJsonAsc = 'LENDER_QUOTES_SUM_QUOTE_JSON_ASC',
  LenderQuotesSumQuoteJsonDesc = 'LENDER_QUOTES_SUM_QUOTE_JSON_DESC',
  LenderQuotesSumCreatedAtAsc = 'LENDER_QUOTES_SUM_CREATED_AT_ASC',
  LenderQuotesSumCreatedAtDesc = 'LENDER_QUOTES_SUM_CREATED_AT_DESC',
  LenderQuotesSumUpdatedAtAsc = 'LENDER_QUOTES_SUM_UPDATED_AT_ASC',
  LenderQuotesSumUpdatedAtDesc = 'LENDER_QUOTES_SUM_UPDATED_AT_DESC',
  LenderQuotesSumTypeAsc = 'LENDER_QUOTES_SUM_TYPE_ASC',
  LenderQuotesSumTypeDesc = 'LENDER_QUOTES_SUM_TYPE_DESC',
  LenderQuotesSumExpiredAsc = 'LENDER_QUOTES_SUM_EXPIRED_ASC',
  LenderQuotesSumExpiredDesc = 'LENDER_QUOTES_SUM_EXPIRED_DESC',
  LenderQuotesDistinctCountIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_ASC',
  LenderQuotesDistinctCountIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_ID_DESC',
  LenderQuotesDistinctCountLeadIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderQuotesDistinctCountLeadIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderQuotesDistinctCountLenderAppIdAsc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_ASC',
  LenderQuotesDistinctCountLenderAppIdDesc = 'LENDER_QUOTES_DISTINCT_COUNT_LENDER_APP_ID_DESC',
  LenderQuotesDistinctCountProviderAsc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_ASC',
  LenderQuotesDistinctCountProviderDesc = 'LENDER_QUOTES_DISTINCT_COUNT_PROVIDER_DESC',
  LenderQuotesDistinctCountQuoteJsonAsc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_ASC',
  LenderQuotesDistinctCountQuoteJsonDesc = 'LENDER_QUOTES_DISTINCT_COUNT_QUOTE_JSON_DESC',
  LenderQuotesDistinctCountCreatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderQuotesDistinctCountCreatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderQuotesDistinctCountUpdatedAtAsc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderQuotesDistinctCountUpdatedAtDesc = 'LENDER_QUOTES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderQuotesDistinctCountTypeAsc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_ASC',
  LenderQuotesDistinctCountTypeDesc = 'LENDER_QUOTES_DISTINCT_COUNT_TYPE_DESC',
  LenderQuotesDistinctCountExpiredAsc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_ASC',
  LenderQuotesDistinctCountExpiredDesc = 'LENDER_QUOTES_DISTINCT_COUNT_EXPIRED_DESC',
  LenderQuotesMinIdAsc = 'LENDER_QUOTES_MIN_ID_ASC',
  LenderQuotesMinIdDesc = 'LENDER_QUOTES_MIN_ID_DESC',
  LenderQuotesMinLeadIdAsc = 'LENDER_QUOTES_MIN_LEAD_ID_ASC',
  LenderQuotesMinLeadIdDesc = 'LENDER_QUOTES_MIN_LEAD_ID_DESC',
  LenderQuotesMinLenderAppIdAsc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_ASC',
  LenderQuotesMinLenderAppIdDesc = 'LENDER_QUOTES_MIN_LENDER_APP_ID_DESC',
  LenderQuotesMinProviderAsc = 'LENDER_QUOTES_MIN_PROVIDER_ASC',
  LenderQuotesMinProviderDesc = 'LENDER_QUOTES_MIN_PROVIDER_DESC',
  LenderQuotesMinQuoteJsonAsc = 'LENDER_QUOTES_MIN_QUOTE_JSON_ASC',
  LenderQuotesMinQuoteJsonDesc = 'LENDER_QUOTES_MIN_QUOTE_JSON_DESC',
  LenderQuotesMinCreatedAtAsc = 'LENDER_QUOTES_MIN_CREATED_AT_ASC',
  LenderQuotesMinCreatedAtDesc = 'LENDER_QUOTES_MIN_CREATED_AT_DESC',
  LenderQuotesMinUpdatedAtAsc = 'LENDER_QUOTES_MIN_UPDATED_AT_ASC',
  LenderQuotesMinUpdatedAtDesc = 'LENDER_QUOTES_MIN_UPDATED_AT_DESC',
  LenderQuotesMinTypeAsc = 'LENDER_QUOTES_MIN_TYPE_ASC',
  LenderQuotesMinTypeDesc = 'LENDER_QUOTES_MIN_TYPE_DESC',
  LenderQuotesMinExpiredAsc = 'LENDER_QUOTES_MIN_EXPIRED_ASC',
  LenderQuotesMinExpiredDesc = 'LENDER_QUOTES_MIN_EXPIRED_DESC',
  LenderQuotesMaxIdAsc = 'LENDER_QUOTES_MAX_ID_ASC',
  LenderQuotesMaxIdDesc = 'LENDER_QUOTES_MAX_ID_DESC',
  LenderQuotesMaxLeadIdAsc = 'LENDER_QUOTES_MAX_LEAD_ID_ASC',
  LenderQuotesMaxLeadIdDesc = 'LENDER_QUOTES_MAX_LEAD_ID_DESC',
  LenderQuotesMaxLenderAppIdAsc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_ASC',
  LenderQuotesMaxLenderAppIdDesc = 'LENDER_QUOTES_MAX_LENDER_APP_ID_DESC',
  LenderQuotesMaxProviderAsc = 'LENDER_QUOTES_MAX_PROVIDER_ASC',
  LenderQuotesMaxProviderDesc = 'LENDER_QUOTES_MAX_PROVIDER_DESC',
  LenderQuotesMaxQuoteJsonAsc = 'LENDER_QUOTES_MAX_QUOTE_JSON_ASC',
  LenderQuotesMaxQuoteJsonDesc = 'LENDER_QUOTES_MAX_QUOTE_JSON_DESC',
  LenderQuotesMaxCreatedAtAsc = 'LENDER_QUOTES_MAX_CREATED_AT_ASC',
  LenderQuotesMaxCreatedAtDesc = 'LENDER_QUOTES_MAX_CREATED_AT_DESC',
  LenderQuotesMaxUpdatedAtAsc = 'LENDER_QUOTES_MAX_UPDATED_AT_ASC',
  LenderQuotesMaxUpdatedAtDesc = 'LENDER_QUOTES_MAX_UPDATED_AT_DESC',
  LenderQuotesMaxTypeAsc = 'LENDER_QUOTES_MAX_TYPE_ASC',
  LenderQuotesMaxTypeDesc = 'LENDER_QUOTES_MAX_TYPE_DESC',
  LenderQuotesMaxExpiredAsc = 'LENDER_QUOTES_MAX_EXPIRED_ASC',
  LenderQuotesMaxExpiredDesc = 'LENDER_QUOTES_MAX_EXPIRED_DESC',
  LenderQuotesAverageIdAsc = 'LENDER_QUOTES_AVERAGE_ID_ASC',
  LenderQuotesAverageIdDesc = 'LENDER_QUOTES_AVERAGE_ID_DESC',
  LenderQuotesAverageLeadIdAsc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_ASC',
  LenderQuotesAverageLeadIdDesc = 'LENDER_QUOTES_AVERAGE_LEAD_ID_DESC',
  LenderQuotesAverageLenderAppIdAsc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_ASC',
  LenderQuotesAverageLenderAppIdDesc = 'LENDER_QUOTES_AVERAGE_LENDER_APP_ID_DESC',
  LenderQuotesAverageProviderAsc = 'LENDER_QUOTES_AVERAGE_PROVIDER_ASC',
  LenderQuotesAverageProviderDesc = 'LENDER_QUOTES_AVERAGE_PROVIDER_DESC',
  LenderQuotesAverageQuoteJsonAsc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_ASC',
  LenderQuotesAverageQuoteJsonDesc = 'LENDER_QUOTES_AVERAGE_QUOTE_JSON_DESC',
  LenderQuotesAverageCreatedAtAsc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_ASC',
  LenderQuotesAverageCreatedAtDesc = 'LENDER_QUOTES_AVERAGE_CREATED_AT_DESC',
  LenderQuotesAverageUpdatedAtAsc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_ASC',
  LenderQuotesAverageUpdatedAtDesc = 'LENDER_QUOTES_AVERAGE_UPDATED_AT_DESC',
  LenderQuotesAverageTypeAsc = 'LENDER_QUOTES_AVERAGE_TYPE_ASC',
  LenderQuotesAverageTypeDesc = 'LENDER_QUOTES_AVERAGE_TYPE_DESC',
  LenderQuotesAverageExpiredAsc = 'LENDER_QUOTES_AVERAGE_EXPIRED_ASC',
  LenderQuotesAverageExpiredDesc = 'LENDER_QUOTES_AVERAGE_EXPIRED_DESC',
  LenderQuotesStddevSampleIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_ASC',
  LenderQuotesStddevSampleIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_ID_DESC',
  LenderQuotesStddevSampleLeadIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderQuotesStddevSampleLeadIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderQuotesStddevSampleLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesStddevSampleLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesStddevSampleProviderAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_ASC',
  LenderQuotesStddevSampleProviderDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_PROVIDER_DESC',
  LenderQuotesStddevSampleQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesStddevSampleQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesStddevSampleCreatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderQuotesStddevSampleCreatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderQuotesStddevSampleUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesStddevSampleUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesStddevSampleTypeAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_ASC',
  LenderQuotesStddevSampleTypeDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_TYPE_DESC',
  LenderQuotesStddevSampleExpiredAsc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_ASC',
  LenderQuotesStddevSampleExpiredDesc = 'LENDER_QUOTES_STDDEV_SAMPLE_EXPIRED_DESC',
  LenderQuotesStddevPopulationIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_ASC',
  LenderQuotesStddevPopulationIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_ID_DESC',
  LenderQuotesStddevPopulationLeadIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderQuotesStddevPopulationLeadIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderQuotesStddevPopulationLenderAppIdAsc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesStddevPopulationLenderAppIdDesc = 'LENDER_QUOTES_STDDEV_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesStddevPopulationProviderAsc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_ASC',
  LenderQuotesStddevPopulationProviderDesc = 'LENDER_QUOTES_STDDEV_POPULATION_PROVIDER_DESC',
  LenderQuotesStddevPopulationQuoteJsonAsc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesStddevPopulationQuoteJsonDesc = 'LENDER_QUOTES_STDDEV_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesStddevPopulationCreatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderQuotesStddevPopulationCreatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderQuotesStddevPopulationUpdatedAtAsc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderQuotesStddevPopulationUpdatedAtDesc = 'LENDER_QUOTES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderQuotesStddevPopulationTypeAsc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_ASC',
  LenderQuotesStddevPopulationTypeDesc = 'LENDER_QUOTES_STDDEV_POPULATION_TYPE_DESC',
  LenderQuotesStddevPopulationExpiredAsc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_ASC',
  LenderQuotesStddevPopulationExpiredDesc = 'LENDER_QUOTES_STDDEV_POPULATION_EXPIRED_DESC',
  LenderQuotesVarianceSampleIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_ASC',
  LenderQuotesVarianceSampleIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_ID_DESC',
  LenderQuotesVarianceSampleLeadIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderQuotesVarianceSampleLeadIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderQuotesVarianceSampleLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_ASC',
  LenderQuotesVarianceSampleLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_LENDER_APP_ID_DESC',
  LenderQuotesVarianceSampleProviderAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_ASC',
  LenderQuotesVarianceSampleProviderDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_PROVIDER_DESC',
  LenderQuotesVarianceSampleQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_ASC',
  LenderQuotesVarianceSampleQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_QUOTE_JSON_DESC',
  LenderQuotesVarianceSampleCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderQuotesVarianceSampleCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderQuotesVarianceSampleUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderQuotesVarianceSampleUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderQuotesVarianceSampleTypeAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_ASC',
  LenderQuotesVarianceSampleTypeDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_TYPE_DESC',
  LenderQuotesVarianceSampleExpiredAsc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_ASC',
  LenderQuotesVarianceSampleExpiredDesc = 'LENDER_QUOTES_VARIANCE_SAMPLE_EXPIRED_DESC',
  LenderQuotesVariancePopulationIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_ASC',
  LenderQuotesVariancePopulationIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_ID_DESC',
  LenderQuotesVariancePopulationLeadIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderQuotesVariancePopulationLeadIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderQuotesVariancePopulationLenderAppIdAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_ASC',
  LenderQuotesVariancePopulationLenderAppIdDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_LENDER_APP_ID_DESC',
  LenderQuotesVariancePopulationProviderAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_ASC',
  LenderQuotesVariancePopulationProviderDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_PROVIDER_DESC',
  LenderQuotesVariancePopulationQuoteJsonAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_ASC',
  LenderQuotesVariancePopulationQuoteJsonDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_QUOTE_JSON_DESC',
  LenderQuotesVariancePopulationCreatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderQuotesVariancePopulationCreatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderQuotesVariancePopulationUpdatedAtAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderQuotesVariancePopulationUpdatedAtDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderQuotesVariancePopulationTypeAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_ASC',
  LenderQuotesVariancePopulationTypeDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_TYPE_DESC',
  LenderQuotesVariancePopulationExpiredAsc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_ASC',
  LenderQuotesVariancePopulationExpiredDesc = 'LENDER_QUOTES_VARIANCE_POPULATION_EXPIRED_DESC'
}

export enum LenderIntegrationStatus {
  Pending = 'Pending',
  Ready = 'Ready'
}

export type LenderOffer = {
  offerId: Scalars['Int'];
  maxPti?: Maybe<Scalars['Float']>;
  maxTdsr?: Maybe<Scalars['Float']>;
  maxTerm?: Maybe<Scalars['Int']>;
  maxLtv?: Maybe<Scalars['Float']>;
  maxPayment?: Maybe<Scalars['Float']>;
  maxAmountFinanced?: Maybe<Scalars['Float']>;
  averageRate?: Maybe<Scalars['Float']>;
  estimatedMonthlyPayment?: Maybe<Scalars['Float']>;
  estimatedBiweeklyPayment?: Maybe<Scalars['Float']>;
  estimatedWeeklyPayment?: Maybe<Scalars['Float']>;
  maxPtiPassFail?: Maybe<Scalars['Int']>;
  maxTdsrPassFail?: Maybe<Scalars['Int']>;
  maxLtvPassFail?: Maybe<Scalars['Int']>;
  maxPaymentPassFail?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  lenderId?: Maybe<Scalars['String']>;
};

export type LenderOffers = {
  lenderAppId: Scalars['Int'];
  preferredOfferId?: Maybe<Scalars['Int']>;
  vehicleListing?: Maybe<VehicleListing>;
  offers?: Maybe<Array<Maybe<LenderOffer>>>;
};

export type LenderProviderCreditAppConfig = {
  retailerId: Scalars['String'];
  retailerName?: Maybe<Scalars['String']>;
  status: LenderIntegrationStatus;
};

export type LenderQuote = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  type: LenderQuoteType;
  expired: Scalars['Boolean'];
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
};

export type LenderQuoteAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LenderQuoteSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LenderQuoteDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LenderQuoteMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LenderQuoteMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LenderQuoteAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LenderQuoteStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LenderQuoteStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LenderQuoteVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LenderQuoteVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `LenderQuote` object types. */
export type LenderQuoteAggregatesFilter = {
  /** A filter that must pass for the relevant `LenderQuote` object to be included within the aggregate. */
  filter?: Maybe<LenderQuoteFilter>;
  /** Sum aggregate over matching `LenderQuote` objects. */
  sum?: Maybe<LenderQuoteSumAggregateFilter>;
  /** Distinct count aggregate over matching `LenderQuote` objects. */
  distinctCount?: Maybe<LenderQuoteDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `LenderQuote` objects. */
  min?: Maybe<LenderQuoteMinAggregateFilter>;
  /** Maximum aggregate over matching `LenderQuote` objects. */
  max?: Maybe<LenderQuoteMaxAggregateFilter>;
  /** Mean average aggregate over matching `LenderQuote` objects. */
  average?: Maybe<LenderQuoteAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LenderQuote` objects. */
  stddevSample?: Maybe<LenderQuoteStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `LenderQuote` objects. */
  stddevPopulation?: Maybe<LenderQuoteStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LenderQuote` objects. */
  varianceSample?: Maybe<LenderQuoteVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `LenderQuote` objects. */
  variancePopulation?: Maybe<LenderQuoteVariancePopulationAggregateFilter>;
};

export type LenderQuoteAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LenderQuote` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LenderQuoteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lenderAppId` field. */
  lenderAppId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `quoteJson` field. */
  quoteJson?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<LenderQuoteType>;
  /** Checks for equality with the object’s `expired` field. */
  expired?: Maybe<Scalars['Boolean']>;
};

export type LenderQuoteDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  lenderAppId?: Maybe<BigIntFilter>;
  provider?: Maybe<BigIntFilter>;
  quoteJson?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
  type?: Maybe<BigIntFilter>;
  expired?: Maybe<BigIntFilter>;
};

export type LenderQuoteDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of quoteJson across the matching connection */
  quoteJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expired across the matching connection */
  expired?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LenderQuote` object types. All fields are combined with a logical ‘and.’ */
export type LenderQuoteFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `lenderAppId` field. */
  lenderAppId?: Maybe<IntFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<StringFilter>;
  /** Filter by the object’s `quoteJson` field. */
  quoteJson?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<LenderQuoteTypeFilter>;
  /** Filter by the object’s `expired` field. */
  expired?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Filter by the object’s `lenderApp` relation. */
  lenderApp?: Maybe<LenderAppFilter>;
  /** A related `lenderApp` exists. */
  lenderAppExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LenderQuoteFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LenderQuoteFilter>>;
  /** Negates the expression. */
  not?: Maybe<LenderQuoteFilter>;
};

/** An input for mutations affecting `LenderQuote` */
export type LenderQuoteInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

/** The fields on `lenderQuote` to look up the row to connect. */
export type LenderQuoteLenderQuotesPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `lenderQuote` to look up the row to delete. */
export type LenderQuoteLenderQuotesPkeyDelete = {
  id: Scalars['Int'];
};

export type LenderQuoteMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderAppId?: Maybe<IntFilter>;
};

export type LenderQuoteMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['Int']>;
};

export type LenderQuoteMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  lenderAppId?: Maybe<IntFilter>;
};

export type LenderQuoteMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type LenderQuoteNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type LenderQuoteNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lenderQuote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `lenderQuote` to look up the row to update. */
export type LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderQuotesPkeyUpdate = {
  /** An object where the defined keys will be set on the `lenderQuote` being updated. */
  patch: UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `LenderQuote`. Fields that are set will be updated. */
export type LenderQuotePatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

export type LenderQuoteStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  lenderAppId?: Maybe<BigIntFilter>;
};

export type LenderQuoteSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lenderAppId across the matching connection */
  lenderAppId: Scalars['BigInt'];
};

export enum LenderQuoteType {
  InstantDecision = 'INSTANT_DECISION',
  PassFail = 'PASS_FAIL',
  LenderProgram = 'LENDER_PROGRAM',
  LenderApplication = 'LENDER_APPLICATION'
}

/** A filter to be used against LenderQuoteType fields. All fields are combined with a logical ‘and.’ */
export type LenderQuoteTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LenderQuoteType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LenderQuoteType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LenderQuoteType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LenderQuoteType>;
  /** Included in the specified list. */
  in?: Maybe<Array<LenderQuoteType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LenderQuoteType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LenderQuoteType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LenderQuoteType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LenderQuoteType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LenderQuoteType>;
};

export type LenderQuoteVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
};

export type LenderQuoteVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  lenderAppId?: Maybe<BigFloatFilter>;
};

export type LenderQuoteVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lenderAppId across the matching connection */
  lenderAppId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LenderQuote` values. */
export type LenderQuotesConnection = {
  /** A list of `LenderQuote` objects. */
  nodes: Array<LenderQuote>;
  /** A list of edges which contains the `LenderQuote` and cursor to aid in pagination. */
  edges: Array<LenderQuotesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LenderQuote` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LenderQuoteAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LenderQuoteAggregates>>;
};


/** A connection to a list of `LenderQuote` values. */
export type LenderQuotesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LenderQuotesGroupBy>;
  having?: Maybe<LenderQuotesHavingInput>;
};

/** A `LenderQuote` edge in the connection. */
export type LenderQuotesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LenderQuote` at the end of the edge. */
  node: LenderQuote;
};

/** Grouping methods for `LenderQuote` for usage during aggregation. */
export enum LenderQuotesGroupBy {
  LeadId = 'LEAD_ID',
  LenderAppId = 'LENDER_APP_ID',
  Provider = 'PROVIDER',
  QuoteJson = 'QUOTE_JSON',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  Type = 'TYPE',
  Expired = 'EXPIRED'
}

export type LenderQuotesHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `LenderQuote` aggregates. */
export type LenderQuotesHavingInput = {
  AND?: Maybe<Array<LenderQuotesHavingInput>>;
  OR?: Maybe<Array<LenderQuotesHavingInput>>;
  sum?: Maybe<LenderQuotesHavingSumInput>;
  distinctCount?: Maybe<LenderQuotesHavingDistinctCountInput>;
  min?: Maybe<LenderQuotesHavingMinInput>;
  max?: Maybe<LenderQuotesHavingMaxInput>;
  average?: Maybe<LenderQuotesHavingAverageInput>;
  stddevSample?: Maybe<LenderQuotesHavingStddevSampleInput>;
  stddevPopulation?: Maybe<LenderQuotesHavingStddevPopulationInput>;
  varianceSample?: Maybe<LenderQuotesHavingVarianceSampleInput>;
  variancePopulation?: Maybe<LenderQuotesHavingVariancePopulationInput>;
};

export type LenderQuotesHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LenderQuotesHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  lenderAppId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `lead` in the `LenderQuoteInput` mutation. */
export type LenderQuotesLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `LeadInput` mutation. */
export type LenderQuotesLeadIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnLenderQuoteForLenderQuotesLeadIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesLeadIdFkeyLenderQuotesCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type LenderQuotesLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesLeadIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

/** Input for the nested mutation of `lenderApp` in the `LenderQuoteInput` mutation. */
export type LenderQuotesLenderAppIdFkeyInput = {
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectById?: Maybe<LenderAppLenderAppPkeyConnect>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<LenderAppNodeIdConnect>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteById?: Maybe<LenderAppLenderAppPkeyDelete>;
  /** The primary key(s) for `lenderApp` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LenderAppNodeIdDelete>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateById?: Maybe<LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderAppPkeyUpdate>;
  /** The primary key(s) and patch data for `lenderApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate>;
  /** A `LenderAppInput` object that will be created and connected to this object. */
  create?: Maybe<LenderQuotesLenderAppIdFkeyLenderAppCreateInput>;
};

/** Input for the nested mutation of `lenderQuote` in the `LenderAppInput` mutation. */
export type LenderQuotesLenderAppIdFkeyInverseInput = {
  /** Flag indicating whether all other `lenderQuote` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectById?: Maybe<Array<LenderQuoteLenderQuotesPkeyConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<LenderQuoteNodeIdConnect>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteById?: Maybe<Array<LenderQuoteLenderQuotesPkeyDelete>>;
  /** The primary key(s) for `lenderQuote` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<LenderQuoteNodeIdDelete>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateById?: Maybe<Array<LenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyUsingLenderQuotesPkeyUpdate>>;
  /** The primary key(s) and patch data for `lenderQuote` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyNodeIdUpdate>>;
  /** A `LenderQuoteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<LenderQuotesLenderAppIdFkeyLenderQuotesCreateInput>>;
};

/** The `lenderApp` to be created by this mutation. */
export type LenderQuotesLenderAppIdFkeyLenderAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome: Scalars['Int'];
  employmentStatus: Scalars['String'];
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** The `lenderQuote` to be created by this mutation. */
export type LenderQuotesLenderAppIdFkeyLenderQuotesCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

/** Methods to use when ordering `LenderQuote`. */
export enum LenderQuotesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  LenderAppIdAsc = 'LENDER_APP_ID_ASC',
  LenderAppIdDesc = 'LENDER_APP_ID_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  QuoteJsonAsc = 'QUOTE_JSON_ASC',
  QuoteJsonDesc = 'QUOTE_JSON_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ExpiredAsc = 'EXPIRED_ASC',
  ExpiredDesc = 'EXPIRED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LoanAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  dateOfBirth: Scalars['String'];
  streetNumber: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  sourceUrl?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  lpTest?: Maybe<Scalars['String']>;
  campaign?: Maybe<LeadCampaignInputType>;
};

export type LoanAppResponse = {
  result?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['UUID']>;
  msg?: Maybe<Scalars['String']>;
  consumer: Scalars['ID'];
};

export type LoanElements = {
  overallAmount?: Maybe<Scalars['Float']>;
  automotive?: Maybe<Scalars['Float']>;
  mortgages?: Maybe<Scalars['Float']>;
  microLoans?: Maybe<Scalars['Float']>;
  studentLoans?: Maybe<Scalars['Float']>;
  otherLoans?: Maybe<Scalars['Float']>;
};

export type LoanStatusComponent = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ManageCtaSpecsInput = {
  create?: Maybe<CreateCtaSpecsInput>;
  update?: Maybe<UpdateCtaSpecsInput>;
  delete?: Maybe<DeleteCtaSpecInput>;
};

export type ManageCtaSpecsOverrideResponse = {
  id: Scalars['Int'];
  widgetIds?: Maybe<Array<Scalars['String']>>;
  ctaId: Scalars['String'];
  groupId: Scalars['Int'];
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme: Scalars['String'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  compatibleMajor: Scalars['Int'];
  ctaDetails?: Maybe<Scalars['JSON']>;
};

export type ManageCtaSpecsResponse = {
  id: Scalars['String'];
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
  published: Scalars['Boolean'];
  ctaTree: Scalars['JSON'];
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  themeSpec: Scalars['JSON'];
  templateSpec: Scalars['JSON'];
  themeDef: Scalars['JSON'];
  templateDef: Scalars['JSON'];
  pluginDef: Scalars['JSON'];
  companyOverride?: Maybe<ManageCtaSpecsOverrideResponse>;
  conflict: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  /** Creates a single `Analytic`. */
  createAnalytic?: Maybe<CreateAnalyticPayload>;
  /** Creates a single `AnalyticsCta`. */
  createAnalyticsCta?: Maybe<CreateAnalyticsCtaPayload>;
  /** Creates a single `Appointment`. */
  createAppointment?: Maybe<CreateAppointmentPayload>;
  /** Creates a single `AvaApp`. */
  createAvaApp?: Maybe<CreateAvaAppPayload>;
  /** Creates a single `Channel`. */
  createChannel?: Maybe<CreateChannelPayload>;
  /** Creates a single `Company`. */
  createCompany?: Maybe<CreateCompanyPayload>;
  /** Creates a single `CompanyAnalytic`. */
  createCompanyAnalytic?: Maybe<CreateCompanyAnalyticPayload>;
  /** Creates a single `CompanyChannel`. */
  createCompanyChannel?: Maybe<CreateCompanyChannelPayload>;
  /** Creates a single `CompanyCta`. */
  createCompanyCta?: Maybe<CreateCompanyCtaPayload>;
  /** Creates a single `CompanyDomain`. */
  createCompanyDomain?: Maybe<CreateCompanyDomainPayload>;
  /** Creates a single `CompanyDomainsApproved`. */
  createCompanyDomainsApproved?: Maybe<CreateCompanyDomainsApprovedPayload>;
  /** Creates a single `CompanyDomainsPath`. */
  createCompanyDomainsPath?: Maybe<CreateCompanyDomainsPathPayload>;
  /** Creates a single `CompanyGroup`. */
  createCompanyGroup?: Maybe<CreateCompanyGroupPayload>;
  /** Creates a single `CompanyIntegration`. */
  createCompanyIntegration?: Maybe<CreateCompanyIntegrationPayload>;
  /** Creates a single `CompanyLocation`. */
  createCompanyLocation?: Maybe<CreateCompanyLocationPayload>;
  /** Creates a single `CompanyProduct`. */
  createCompanyProduct?: Maybe<CreateCompanyProductPayload>;
  /** Creates a single `CompanyProductIntegration`. */
  createCompanyProductIntegration?: Maybe<CreateCompanyProductIntegrationPayload>;
  /** Creates a single `CompanyWidget`. */
  createCompanyWidget?: Maybe<CreateCompanyWidgetPayload>;
  /** Creates a single `CreditApp`. */
  createCreditApp?: Maybe<CreateCreditAppPayload>;
  /** Creates a single `CreditBureau`. */
  createCreditBureau?: Maybe<CreateCreditBureauPayload>;
  /** Creates a single `CtaWidget`. */
  createCtaWidget?: Maybe<CreateCtaWidgetPayload>;
  /** Creates a single `Cta`. */
  createCta?: Maybe<CreateCtaPayload>;
  /** Creates a single `DeliveryMethod`. */
  createDeliveryMethod?: Maybe<CreateDeliveryMethodPayload>;
  /** Creates a single `EmailTemplate`. */
  createEmailTemplate?: Maybe<CreateEmailTemplatePayload>;
  /** Creates a single `EquifaxLender`. */
  createEquifaxLender?: Maybe<CreateEquifaxLenderPayload>;
  /** Creates a single `Field`. */
  createField?: Maybe<CreateFieldPayload>;
  /** Creates a single `FieldConfig`. */
  createFieldConfig?: Maybe<CreateFieldConfigPayload>;
  /** Creates a single `FieldKey`. */
  createFieldKey?: Maybe<CreateFieldKeyPayload>;
  /** Creates a single `Group`. */
  createGroup?: Maybe<CreateGroupPayload>;
  /** Creates a single `IdVerification`. */
  createIdVerification?: Maybe<CreateIdVerificationPayload>;
  /** Creates a single `IncomeVerification`. */
  createIncomeVerification?: Maybe<CreateIncomeVerificationPayload>;
  /** Creates a single `Integration`. */
  createIntegration?: Maybe<CreateIntegrationPayload>;
  /** Creates a single `Lead`. */
  createLead?: Maybe<CreateLeadPayload>;
  /** Creates a single `LeadCampaign`. */
  createLeadCampaign?: Maybe<CreateLeadCampaignPayload>;
  /** Creates a single `LeadJourney`. */
  createLeadJourney?: Maybe<CreateLeadJourneyPayload>;
  /** Creates a single `LenderApp`. */
  createLenderApp?: Maybe<CreateLenderAppPayload>;
  /** Creates a single `LenderQuote`. */
  createLenderQuote?: Maybe<CreateLenderQuotePayload>;
  /** Creates a single `Option`. */
  createOption?: Maybe<CreateOptionPayload>;
  /** Creates a single `ProductIntegration`. */
  createProductIntegration?: Maybe<CreateProductIntegrationPayload>;
  /** Creates a single `Review`. */
  createReview?: Maybe<CreateReviewPayload>;
  /** Creates a single `TradeApp`. */
  createTradeApp?: Maybe<CreateTradeAppPayload>;
  /** Creates a single `UserActivity`. */
  createUserActivity?: Maybe<CreateUserActivityPayload>;
  /** Creates a single `VehicleListing`. */
  createVehicleListing?: Maybe<CreateVehicleListingPayload>;
  /** Creates a single `WidgetSetting`. */
  createWidgetSetting?: Maybe<CreateWidgetSettingPayload>;
  /** Creates a single `Widget`. */
  createWidget?: Maybe<CreateWidgetPayload>;
  /** Updates a single `Analytic` using its globally unique id and a patch. */
  updateAnalyticByNodeId?: Maybe<UpdateAnalyticPayload>;
  /** Updates a single `Analytic` using a unique key and a patch. */
  updateAnalytic?: Maybe<UpdateAnalyticPayload>;
  /** Updates a single `AnalyticsCta` using its globally unique id and a patch. */
  updateAnalyticsCtaByNodeId?: Maybe<UpdateAnalyticsCtaPayload>;
  /** Updates a single `AnalyticsCta` using a unique key and a patch. */
  updateAnalyticsCta?: Maybe<UpdateAnalyticsCtaPayload>;
  /** Updates a single `Appointment` using its globally unique id and a patch. */
  updateAppointmentByNodeId?: Maybe<UpdateAppointmentPayload>;
  /** Updates a single `Appointment` using a unique key and a patch. */
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  /** Updates a single `AvaApp` using its globally unique id and a patch. */
  updateAvaAppByNodeId?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `AvaApp` using a unique key and a patch. */
  updateAvaApp?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `AvaApp` using a unique key and a patch. */
  updateAvaAppByLeadId?: Maybe<UpdateAvaAppPayload>;
  /** Updates a single `Channel` using its globally unique id and a patch. */
  updateChannelByNodeId?: Maybe<UpdateChannelPayload>;
  /** Updates a single `Channel` using a unique key and a patch. */
  updateChannel?: Maybe<UpdateChannelPayload>;
  /** Updates a single `Company` using its globally unique id and a patch. */
  updateCompanyByNodeId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompany?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByName?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByExternalId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompanyByPrimaryGroup?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `CompanyAnalytic` using its globally unique id and a patch. */
  updateCompanyAnalyticByNodeId?: Maybe<UpdateCompanyAnalyticPayload>;
  /** Updates a single `CompanyAnalytic` using a unique key and a patch. */
  updateCompanyAnalytic?: Maybe<UpdateCompanyAnalyticPayload>;
  /** Updates a single `CompanyChannel` using its globally unique id and a patch. */
  updateCompanyChannelByNodeId?: Maybe<UpdateCompanyChannelPayload>;
  /** Updates a single `CompanyChannel` using a unique key and a patch. */
  updateCompanyChannel?: Maybe<UpdateCompanyChannelPayload>;
  /** Updates a single `CompanyCta` using its globally unique id and a patch. */
  updateCompanyCtaByNodeId?: Maybe<UpdateCompanyCtaPayload>;
  /** Updates a single `CompanyCta` using a unique key and a patch. */
  updateCompanyCta?: Maybe<UpdateCompanyCtaPayload>;
  /** Updates a single `CompanyDomain` using its globally unique id and a patch. */
  updateCompanyDomainByNodeId?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomain` using a unique key and a patch. */
  updateCompanyDomain?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomain` using a unique key and a patch. */
  updateCompanyDomainByDomain?: Maybe<UpdateCompanyDomainPayload>;
  /** Updates a single `CompanyDomainsApproved` using its globally unique id and a patch. */
  updateCompanyDomainsApprovedByNodeId?: Maybe<UpdateCompanyDomainsApprovedPayload>;
  /** Updates a single `CompanyDomainsApproved` using a unique key and a patch. */
  updateCompanyDomainsApproved?: Maybe<UpdateCompanyDomainsApprovedPayload>;
  /** Updates a single `CompanyDomainsPath` using its globally unique id and a patch. */
  updateCompanyDomainsPathByNodeId?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyDomainsPath` using a unique key and a patch. */
  updateCompanyDomainsPath?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyDomainsPath` using a unique key and a patch. */
  updateCompanyDomainsPathByDomainIdAndPath?: Maybe<UpdateCompanyDomainsPathPayload>;
  /** Updates a single `CompanyGroup` using its globally unique id and a patch. */
  updateCompanyGroupByNodeId?: Maybe<UpdateCompanyGroupPayload>;
  /** Updates a single `CompanyGroup` using a unique key and a patch. */
  updateCompanyGroup?: Maybe<UpdateCompanyGroupPayload>;
  /** Updates a single `CompanyIntegration` using its globally unique id and a patch. */
  updateCompanyIntegrationByNodeId?: Maybe<UpdateCompanyIntegrationPayload>;
  /** Updates a single `CompanyIntegration` using a unique key and a patch. */
  updateCompanyIntegration?: Maybe<UpdateCompanyIntegrationPayload>;
  /** Updates a single `CompanyLocation` using its globally unique id and a patch. */
  updateCompanyLocationByNodeId?: Maybe<UpdateCompanyLocationPayload>;
  /** Updates a single `CompanyLocation` using a unique key and a patch. */
  updateCompanyLocation?: Maybe<UpdateCompanyLocationPayload>;
  /** Updates a single `CompanyProduct` using its globally unique id and a patch. */
  updateCompanyProductByNodeId?: Maybe<UpdateCompanyProductPayload>;
  /** Updates a single `CompanyProduct` using a unique key and a patch. */
  updateCompanyProduct?: Maybe<UpdateCompanyProductPayload>;
  /** Updates a single `CompanyProductIntegration` using its globally unique id and a patch. */
  updateCompanyProductIntegrationByNodeId?: Maybe<UpdateCompanyProductIntegrationPayload>;
  /** Updates a single `CompanyProductIntegration` using a unique key and a patch. */
  updateCompanyProductIntegration?: Maybe<UpdateCompanyProductIntegrationPayload>;
  /** Updates a single `CompanyWidget` using its globally unique id and a patch. */
  updateCompanyWidgetByNodeId?: Maybe<UpdateCompanyWidgetPayload>;
  /** Updates a single `CompanyWidget` using a unique key and a patch. */
  updateCompanyWidget?: Maybe<UpdateCompanyWidgetPayload>;
  /** Updates a single `CreditApp` using its globally unique id and a patch. */
  updateCreditAppByNodeId?: Maybe<UpdateCreditAppPayload>;
  /** Updates a single `CreditApp` using a unique key and a patch. */
  updateCreditApp?: Maybe<UpdateCreditAppPayload>;
  /** Updates a single `CreditApp` using a unique key and a patch. */
  updateCreditAppByLeadId?: Maybe<UpdateCreditAppPayload>;
  /** Updates a single `CreditBureau` using its globally unique id and a patch. */
  updateCreditBureauByNodeId?: Maybe<UpdateCreditBureauPayload>;
  /** Updates a single `CreditBureau` using a unique key and a patch. */
  updateCreditBureau?: Maybe<UpdateCreditBureauPayload>;
  /** Updates a single `CtaWidget` using its globally unique id and a patch. */
  updateCtaWidgetByNodeId?: Maybe<UpdateCtaWidgetPayload>;
  /** Updates a single `CtaWidget` using a unique key and a patch. */
  updateCtaWidget?: Maybe<UpdateCtaWidgetPayload>;
  /** Updates a single `Cta` using its globally unique id and a patch. */
  updateCtaByNodeId?: Maybe<UpdateCtaPayload>;
  /** Updates a single `Cta` using a unique key and a patch. */
  updateCta?: Maybe<UpdateCtaPayload>;
  /** Updates a single `Cta` using a unique key and a patch. */
  updateCtaByProductAndTypeAndVersion?: Maybe<UpdateCtaPayload>;
  /** Updates a single `DeliveryMethod` using its globally unique id and a patch. */
  updateDeliveryMethodByNodeId?: Maybe<UpdateDeliveryMethodPayload>;
  /** Updates a single `DeliveryMethod` using a unique key and a patch. */
  updateDeliveryMethod?: Maybe<UpdateDeliveryMethodPayload>;
  /** Updates a single `EmailTemplate` using its globally unique id and a patch. */
  updateEmailTemplateByNodeId?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates a single `EmailTemplate` using a unique key and a patch. */
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates a single `EquifaxLender` using its globally unique id and a patch. */
  updateEquifaxLenderByNodeId?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `EquifaxLender` using a unique key and a patch. */
  updateEquifaxLender?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `EquifaxLender` using a unique key and a patch. */
  updateEquifaxLenderByEquifaxIdAndName?: Maybe<UpdateEquifaxLenderPayload>;
  /** Updates a single `Field` using its globally unique id and a patch. */
  updateFieldByNodeId?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateField?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyProductIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyChannelIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `Field` using a unique key and a patch. */
  updateFieldByCompanyIntegrationIdAndConfig?: Maybe<UpdateFieldPayload>;
  /** Updates a single `FieldConfig` using its globally unique id and a patch. */
  updateFieldConfigByNodeId?: Maybe<UpdateFieldConfigPayload>;
  /** Updates a single `FieldConfig` using a unique key and a patch. */
  updateFieldConfig?: Maybe<UpdateFieldConfigPayload>;
  /** Updates a single `FieldKey` using its globally unique id and a patch. */
  updateFieldKeyByNodeId?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `FieldKey` using a unique key and a patch. */
  updateFieldKey?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `FieldKey` using a unique key and a patch. */
  updateFieldKeyByKey?: Maybe<UpdateFieldKeyPayload>;
  /** Updates a single `Group` using its globally unique id and a patch. */
  updateGroupByNodeId?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroupByCognitoName?: Maybe<UpdateGroupPayload>;
  /** Updates a single `IdVerification` using its globally unique id and a patch. */
  updateIdVerificationByNodeId?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IdVerification` using a unique key and a patch. */
  updateIdVerification?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IdVerification` using a unique key and a patch. */
  updateIdVerificationByLeadId?: Maybe<UpdateIdVerificationPayload>;
  /** Updates a single `IncomeVerification` using its globally unique id and a patch. */
  updateIncomeVerificationByNodeId?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `IncomeVerification` using a unique key and a patch. */
  updateIncomeVerification?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `IncomeVerification` using a unique key and a patch. */
  updateIncomeVerificationByLeadId?: Maybe<UpdateIncomeVerificationPayload>;
  /** Updates a single `Integration` using its globally unique id and a patch. */
  updateIntegrationByNodeId?: Maybe<UpdateIntegrationPayload>;
  /** Updates a single `Integration` using a unique key and a patch. */
  updateIntegration?: Maybe<UpdateIntegrationPayload>;
  /** Updates a single `Lead` using its globally unique id and a patch. */
  updateLeadByNodeId?: Maybe<UpdateLeadPayload>;
  /** Updates a single `Lead` using a unique key and a patch. */
  updateLead?: Maybe<UpdateLeadPayload>;
  /** Updates a single `LeadCampaign` using its globally unique id and a patch. */
  updateLeadCampaignByNodeId?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LeadCampaign` using a unique key and a patch. */
  updateLeadCampaign?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LeadCampaign` using a unique key and a patch. */
  updateLeadCampaignByLeadId?: Maybe<UpdateLeadCampaignPayload>;
  /** Updates a single `LenderApp` using its globally unique id and a patch. */
  updateLenderAppByNodeId?: Maybe<UpdateLenderAppPayload>;
  /** Updates a single `LenderApp` using a unique key and a patch. */
  updateLenderApp?: Maybe<UpdateLenderAppPayload>;
  /** Updates a single `LenderQuote` using its globally unique id and a patch. */
  updateLenderQuoteByNodeId?: Maybe<UpdateLenderQuotePayload>;
  /** Updates a single `LenderQuote` using a unique key and a patch. */
  updateLenderQuote?: Maybe<UpdateLenderQuotePayload>;
  /** Updates a single `Option` using its globally unique id and a patch. */
  updateOptionByNodeId?: Maybe<UpdateOptionPayload>;
  /** Updates a single `Option` using a unique key and a patch. */
  updateOption?: Maybe<UpdateOptionPayload>;
  /** Updates a single `ProductIntegration` using its globally unique id and a patch. */
  updateProductIntegrationByNodeId?: Maybe<UpdateProductIntegrationPayload>;
  /** Updates a single `ProductIntegration` using a unique key and a patch. */
  updateProductIntegration?: Maybe<UpdateProductIntegrationPayload>;
  /** Updates a single `Review` using its globally unique id and a patch. */
  updateReviewByNodeId?: Maybe<UpdateReviewPayload>;
  /** Updates a single `Review` using a unique key and a patch. */
  updateReview?: Maybe<UpdateReviewPayload>;
  /** Updates a single `TradeApp` using its globally unique id and a patch. */
  updateTradeAppByNodeId?: Maybe<UpdateTradeAppPayload>;
  /** Updates a single `TradeApp` using a unique key and a patch. */
  updateTradeApp?: Maybe<UpdateTradeAppPayload>;
  /** Updates a single `VehicleListing` using its globally unique id and a patch. */
  updateVehicleListingByNodeId?: Maybe<UpdateVehicleListingPayload>;
  /** Updates a single `VehicleListing` using a unique key and a patch. */
  updateVehicleListing?: Maybe<UpdateVehicleListingPayload>;
  /** Updates a single `WidgetSetting` using its globally unique id and a patch. */
  updateWidgetSettingByNodeId?: Maybe<UpdateWidgetSettingPayload>;
  /** Updates a single `WidgetSetting` using a unique key and a patch. */
  updateWidgetSetting?: Maybe<UpdateWidgetSettingPayload>;
  /** Updates a single `Widget` using its globally unique id and a patch. */
  updateWidgetByNodeId?: Maybe<UpdateWidgetPayload>;
  /** Updates a single `Widget` using a unique key and a patch. */
  updateWidget?: Maybe<UpdateWidgetPayload>;
  /** Updates a single `Widget` using a unique key and a patch. */
  updateWidgetByVariant?: Maybe<UpdateWidgetPayload>;
  /** Deletes a single `Analytic` using its globally unique id. */
  deleteAnalyticByNodeId?: Maybe<DeleteAnalyticPayload>;
  /** Deletes a single `Analytic` using a unique key. */
  deleteAnalytic?: Maybe<DeleteAnalyticPayload>;
  /** Deletes a single `AnalyticsCta` using its globally unique id. */
  deleteAnalyticsCtaByNodeId?: Maybe<DeleteAnalyticsCtaPayload>;
  /** Deletes a single `AnalyticsCta` using a unique key. */
  deleteAnalyticsCta?: Maybe<DeleteAnalyticsCtaPayload>;
  /** Deletes a single `Appointment` using its globally unique id. */
  deleteAppointmentByNodeId?: Maybe<DeleteAppointmentPayload>;
  /** Deletes a single `Appointment` using a unique key. */
  deleteAppointment?: Maybe<DeleteAppointmentPayload>;
  /** Deletes a single `Channel` using its globally unique id. */
  deleteChannelByNodeId?: Maybe<DeleteChannelPayload>;
  /** Deletes a single `Channel` using a unique key. */
  deleteChannel?: Maybe<DeleteChannelPayload>;
  /** Deletes a single `CompanyAnalytic` using its globally unique id. */
  deleteCompanyAnalyticByNodeId?: Maybe<DeleteCompanyAnalyticPayload>;
  /** Deletes a single `CompanyAnalytic` using a unique key. */
  deleteCompanyAnalytic?: Maybe<DeleteCompanyAnalyticPayload>;
  /** Deletes a single `CompanyChannel` using its globally unique id. */
  deleteCompanyChannelByNodeId?: Maybe<DeleteCompanyChannelPayload>;
  /** Deletes a single `CompanyChannel` using a unique key. */
  deleteCompanyChannel?: Maybe<DeleteCompanyChannelPayload>;
  /** Deletes a single `CompanyCta` using its globally unique id. */
  deleteCompanyCtaByNodeId?: Maybe<DeleteCompanyCtaPayload>;
  /** Deletes a single `CompanyCta` using a unique key. */
  deleteCompanyCta?: Maybe<DeleteCompanyCtaPayload>;
  /** Deletes a single `CompanyDomain` using its globally unique id. */
  deleteCompanyDomainByNodeId?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomain` using a unique key. */
  deleteCompanyDomain?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomain` using a unique key. */
  deleteCompanyDomainByDomain?: Maybe<DeleteCompanyDomainPayload>;
  /** Deletes a single `CompanyDomainsApproved` using its globally unique id. */
  deleteCompanyDomainsApprovedByNodeId?: Maybe<DeleteCompanyDomainsApprovedPayload>;
  /** Deletes a single `CompanyDomainsApproved` using a unique key. */
  deleteCompanyDomainsApproved?: Maybe<DeleteCompanyDomainsApprovedPayload>;
  /** Deletes a single `CompanyDomainsPath` using its globally unique id. */
  deleteCompanyDomainsPathByNodeId?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyDomainsPath` using a unique key. */
  deleteCompanyDomainsPath?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyDomainsPath` using a unique key. */
  deleteCompanyDomainsPathByDomainIdAndPath?: Maybe<DeleteCompanyDomainsPathPayload>;
  /** Deletes a single `CompanyGroup` using its globally unique id. */
  deleteCompanyGroupByNodeId?: Maybe<DeleteCompanyGroupPayload>;
  /** Deletes a single `CompanyGroup` using a unique key. */
  deleteCompanyGroup?: Maybe<DeleteCompanyGroupPayload>;
  /** Deletes a single `CompanyIntegration` using its globally unique id. */
  deleteCompanyIntegrationByNodeId?: Maybe<DeleteCompanyIntegrationPayload>;
  /** Deletes a single `CompanyIntegration` using a unique key. */
  deleteCompanyIntegration?: Maybe<DeleteCompanyIntegrationPayload>;
  /** Deletes a single `CompanyLocation` using its globally unique id. */
  deleteCompanyLocationByNodeId?: Maybe<DeleteCompanyLocationPayload>;
  /** Deletes a single `CompanyLocation` using a unique key. */
  deleteCompanyLocation?: Maybe<DeleteCompanyLocationPayload>;
  /** Deletes a single `CompanyProduct` using its globally unique id. */
  deleteCompanyProductByNodeId?: Maybe<DeleteCompanyProductPayload>;
  /** Deletes a single `CompanyProduct` using a unique key. */
  deleteCompanyProduct?: Maybe<DeleteCompanyProductPayload>;
  /** Deletes a single `CompanyProductIntegration` using its globally unique id. */
  deleteCompanyProductIntegrationByNodeId?: Maybe<DeleteCompanyProductIntegrationPayload>;
  /** Deletes a single `CompanyProductIntegration` using a unique key. */
  deleteCompanyProductIntegration?: Maybe<DeleteCompanyProductIntegrationPayload>;
  /** Deletes a single `CompanyWidget` using its globally unique id. */
  deleteCompanyWidgetByNodeId?: Maybe<DeleteCompanyWidgetPayload>;
  /** Deletes a single `CompanyWidget` using a unique key. */
  deleteCompanyWidget?: Maybe<DeleteCompanyWidgetPayload>;
  /** Deletes a single `CtaWidget` using its globally unique id. */
  deleteCtaWidgetByNodeId?: Maybe<DeleteCtaWidgetPayload>;
  /** Deletes a single `CtaWidget` using a unique key. */
  deleteCtaWidget?: Maybe<DeleteCtaWidgetPayload>;
  /** Deletes a single `Cta` using its globally unique id. */
  deleteCtaByNodeId?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `Cta` using a unique key. */
  deleteCta?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `Cta` using a unique key. */
  deleteCtaByProductAndTypeAndVersion?: Maybe<DeleteCtaPayload>;
  /** Deletes a single `DeliveryMethod` using its globally unique id. */
  deleteDeliveryMethodByNodeId?: Maybe<DeleteDeliveryMethodPayload>;
  /** Deletes a single `DeliveryMethod` using a unique key. */
  deleteDeliveryMethod?: Maybe<DeleteDeliveryMethodPayload>;
  /** Deletes a single `EmailTemplate` using its globally unique id. */
  deleteEmailTemplateByNodeId?: Maybe<DeleteEmailTemplatePayload>;
  /** Deletes a single `EmailTemplate` using a unique key. */
  deleteEmailTemplate?: Maybe<DeleteEmailTemplatePayload>;
  /** Deletes a single `EquifaxLender` using its globally unique id. */
  deleteEquifaxLenderByNodeId?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `EquifaxLender` using a unique key. */
  deleteEquifaxLender?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `EquifaxLender` using a unique key. */
  deleteEquifaxLenderByEquifaxIdAndName?: Maybe<DeleteEquifaxLenderPayload>;
  /** Deletes a single `Event` using its globally unique id. */
  deleteEventByNodeId?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Event` using a unique key. */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Deletes a single `Field` using its globally unique id. */
  deleteFieldByNodeId?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteField?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyProductIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyChannelIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `Field` using a unique key. */
  deleteFieldByCompanyIntegrationIdAndConfig?: Maybe<DeleteFieldPayload>;
  /** Deletes a single `FieldConfig` using its globally unique id. */
  deleteFieldConfigByNodeId?: Maybe<DeleteFieldConfigPayload>;
  /** Deletes a single `FieldConfig` using a unique key. */
  deleteFieldConfig?: Maybe<DeleteFieldConfigPayload>;
  /** Deletes a single `FieldKey` using its globally unique id. */
  deleteFieldKeyByNodeId?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `FieldKey` using a unique key. */
  deleteFieldKey?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `FieldKey` using a unique key. */
  deleteFieldKeyByKey?: Maybe<DeleteFieldKeyPayload>;
  /** Deletes a single `IdVerification` using its globally unique id. */
  deleteIdVerificationByNodeId?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IdVerification` using a unique key. */
  deleteIdVerification?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IdVerification` using a unique key. */
  deleteIdVerificationByLeadId?: Maybe<DeleteIdVerificationPayload>;
  /** Deletes a single `IncomeVerification` using its globally unique id. */
  deleteIncomeVerificationByNodeId?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `IncomeVerification` using a unique key. */
  deleteIncomeVerification?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `IncomeVerification` using a unique key. */
  deleteIncomeVerificationByLeadId?: Maybe<DeleteIncomeVerificationPayload>;
  /** Deletes a single `Integration` using its globally unique id. */
  deleteIntegrationByNodeId?: Maybe<DeleteIntegrationPayload>;
  /** Deletes a single `Integration` using a unique key. */
  deleteIntegration?: Maybe<DeleteIntegrationPayload>;
  /** Deletes a single `Lead` using its globally unique id. */
  deleteLeadByNodeId?: Maybe<DeleteLeadPayload>;
  /** Deletes a single `Lead` using a unique key. */
  deleteLead?: Maybe<DeleteLeadPayload>;
  /** Deletes a single `LeadCampaign` using its globally unique id. */
  deleteLeadCampaignByNodeId?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LeadCampaign` using a unique key. */
  deleteLeadCampaign?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LeadCampaign` using a unique key. */
  deleteLeadCampaignByLeadId?: Maybe<DeleteLeadCampaignPayload>;
  /** Deletes a single `LenderApp` using its globally unique id. */
  deleteLenderAppByNodeId?: Maybe<DeleteLenderAppPayload>;
  /** Deletes a single `LenderApp` using a unique key. */
  deleteLenderApp?: Maybe<DeleteLenderAppPayload>;
  /** Deletes a single `LenderQuote` using its globally unique id. */
  deleteLenderQuoteByNodeId?: Maybe<DeleteLenderQuotePayload>;
  /** Deletes a single `LenderQuote` using a unique key. */
  deleteLenderQuote?: Maybe<DeleteLenderQuotePayload>;
  /** Deletes a single `Option` using its globally unique id. */
  deleteOptionByNodeId?: Maybe<DeleteOptionPayload>;
  /** Deletes a single `Option` using a unique key. */
  deleteOption?: Maybe<DeleteOptionPayload>;
  /** Deletes a single `ProductIntegration` using its globally unique id. */
  deleteProductIntegrationByNodeId?: Maybe<DeleteProductIntegrationPayload>;
  /** Deletes a single `ProductIntegration` using a unique key. */
  deleteProductIntegration?: Maybe<DeleteProductIntegrationPayload>;
  /** Deletes a single `Review` using its globally unique id. */
  deleteReviewByNodeId?: Maybe<DeleteReviewPayload>;
  /** Deletes a single `Review` using a unique key. */
  deleteReview?: Maybe<DeleteReviewPayload>;
  /** Deletes a single `UserActivity` using its globally unique id. */
  deleteUserActivityByNodeId?: Maybe<DeleteUserActivityPayload>;
  /** Deletes a single `UserActivity` using a unique key. */
  deleteUserActivity?: Maybe<DeleteUserActivityPayload>;
  /** Deletes a single `VehicleListing` using its globally unique id. */
  deleteVehicleListingByNodeId?: Maybe<DeleteVehicleListingPayload>;
  /** Deletes a single `VehicleListing` using a unique key. */
  deleteVehicleListing?: Maybe<DeleteVehicleListingPayload>;
  /** Deletes a single `WidgetSetting` using its globally unique id. */
  deleteWidgetSettingByNodeId?: Maybe<DeleteWidgetSettingPayload>;
  /** Deletes a single `WidgetSetting` using a unique key. */
  deleteWidgetSetting?: Maybe<DeleteWidgetSettingPayload>;
  /** Deletes a single `Widget` using its globally unique id. */
  deleteWidgetByNodeId?: Maybe<DeleteWidgetPayload>;
  /** Deletes a single `Widget` using a unique key. */
  deleteWidget?: Maybe<DeleteWidgetPayload>;
  /** Deletes a single `Widget` using a unique key. */
  deleteWidgetByVariant?: Maybe<DeleteWidgetPayload>;
  setPreferredLenderOffer?: Maybe<LenderOffers>;
  fetchLenderQuotes?: Maybe<FetchLenderQuotesResult>;
  setCreditAppRetailerId: SetCreditAppRetailerIdResponse;
  createCreditAppApplication: CreateCreditAppApplicationResponse;
  submitCreditAppApplication: SubmitCreditAppApplicationResponse;
  creditAuth?: Maybe<CreditQuery>;
  creditQuery?: Maybe<CreditQuery>;
  creditConsent?: Maybe<CreditQuery>;
  loanApp?: Maybe<LoanAppResponse>;
  createUser?: Maybe<CreateUserResponse>;
  removeUser?: Maybe<UserResponse>;
  copyPermissions?: Maybe<CopyPermissionsResponse>;
  giveUserAccess?: Maybe<UserResponse>;
  revokeAccess?: Maybe<UserResponse>;
  resetUserPassword?: Maybe<ChangeUserPasswordResponse>;
  requestAccess: RequestAccessResponse;
  createTradeAppLead?: Maybe<TradeAppCustomType>;
  notifyUserBySms?: Maybe<SmsUserNotificationResponse>;
  archiveCompany: ArchiveCompanyResponse;
  renameCompany: RenameCompanyResponse;
  createCompanyWithUser: CreateCompanyWithUserResponse;
  resendLeadNotification: NotificationResponse;
  triggerLeadNotification: NotificationResponse;
  updateCompanyMeta?: Maybe<CompanyMetaResponse>;
  updateDeliveryMethodPreferredSender: DeliveryMethodResponse;
  createTestLead?: Maybe<SubmitLeadResponse>;
  submitLead?: Maybe<SubmitLeadResponse>;
  adminSubmitLead?: Maybe<SubmitLeadResponse>;
  updateLeadMeta?: Maybe<LeadMetaResponse>;
  requestVerification?: Maybe<VerificationResponse>;
  confirmVerificationCode?: Maybe<VerificationResponse>;
  softDeleteLead?: Maybe<DeleteLeadResponse>;
  notifyUser?: Maybe<UserNotificationResponse>;
  notifyCompany?: Maybe<CompanyNotificationResponse>;
  manageCtaSpecs: ManageCtaSpecsResponse;
  /** @deprecated */
  trackAnalytics: AnalyticsEventResponse;
  trackAnalyticsBatch: AnalyticsResponse;
  zohoSubscriptionsWebhook: ZohoSubscriptionsResponse;
  requestIdVerification?: Maybe<RequestIdVerificationResponse>;
  regenerateIdVerificationToken?: Maybe<RequestIdVerificationResponse>;
  retrieveIdVerification?: Maybe<RetrieveIdVerificationResponse>;
  initiateIdVerification?: Maybe<InitiateIdVerificationResponse>;
  /** @deprecated Use uploadIdVerificationImage from ava-mobile instead to use the Autocorp Id provider */
  setIdVerificationNextStep?: Maybe<SetIdVerificationNextStepResponse>;
  uploadIdVerificationImages?: Maybe<UploadIdVerificationImagesResponse>;
  uploadIdVerificationImage?: Maybe<UploadIdVerificationImagesResponse>;
  requestIncomeVerification?: Maybe<RequestIncomeVerificationResponse>;
  setIncomeLogin?: Maybe<SetIncomeLoginResponse>;
  setDirectDepositAccount?: Maybe<SetDirectDepositAccountResponse>;
  sendFeedback: SendFeedbackResponse;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalyticArgs = {
  input: CreateAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAnalyticsCtaArgs = {
  input: CreateAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAvaAppArgs = {
  input: CreateAvaAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyAnalyticArgs = {
  input: CreateCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyChannelArgs = {
  input: CreateCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyCtaArgs = {
  input: CreateCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainArgs = {
  input: CreateCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainsApprovedArgs = {
  input: CreateCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyDomainsPathArgs = {
  input: CreateCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyGroupArgs = {
  input: CreateCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyIntegrationArgs = {
  input: CreateCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyLocationArgs = {
  input: CreateCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyProductArgs = {
  input: CreateCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyProductIntegrationArgs = {
  input: CreateCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyWidgetArgs = {
  input: CreateCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCreditAppArgs = {
  input: CreateCreditAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCreditBureauArgs = {
  input: CreateCreditBureauInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCtaWidgetArgs = {
  input: CreateCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCtaArgs = {
  input: CreateCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDeliveryMethodArgs = {
  input: CreateDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquifaxLenderArgs = {
  input: CreateEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldConfigArgs = {
  input: CreateFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFieldKeyArgs = {
  input: CreateFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIdVerificationArgs = {
  input: CreateIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIncomeVerificationArgs = {
  input: CreateIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadCampaignArgs = {
  input: CreateLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLeadJourneyArgs = {
  input: CreateLeadJourneyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderAppArgs = {
  input: CreateLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLenderQuoteArgs = {
  input: CreateLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOptionArgs = {
  input: CreateOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProductIntegrationArgs = {
  input: CreateProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTradeAppArgs = {
  input: CreateTradeAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserActivityArgs = {
  input: CreateUserActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVehicleListingArgs = {
  input: CreateVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWidgetSettingArgs = {
  input: CreateWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticByNodeIdArgs = {
  input: UpdateAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticArgs = {
  input: UpdateAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticsCtaByNodeIdArgs = {
  input: UpdateAnalyticsCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAnalyticsCtaArgs = {
  input: UpdateAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppointmentByNodeIdArgs = {
  input: UpdateAppointmentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppByNodeIdArgs = {
  input: UpdateAvaAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppArgs = {
  input: UpdateAvaAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvaAppByLeadIdArgs = {
  input: UpdateAvaAppByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelByNodeIdArgs = {
  input: UpdateChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByNodeIdArgs = {
  input: UpdateCompanyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByNameArgs = {
  input: UpdateCompanyByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByExternalIdArgs = {
  input: UpdateCompanyByExternalIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByPrimaryGroupArgs = {
  input: UpdateCompanyByPrimaryGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyAnalyticByNodeIdArgs = {
  input: UpdateCompanyAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyAnalyticArgs = {
  input: UpdateCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelByNodeIdArgs = {
  input: UpdateCompanyChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyChannelArgs = {
  input: UpdateCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyCtaByNodeIdArgs = {
  input: UpdateCompanyCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyCtaArgs = {
  input: UpdateCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainByNodeIdArgs = {
  input: UpdateCompanyDomainByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainArgs = {
  input: UpdateCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainByDomainArgs = {
  input: UpdateCompanyDomainByDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsApprovedByNodeIdArgs = {
  input: UpdateCompanyDomainsApprovedByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsApprovedArgs = {
  input: UpdateCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathByNodeIdArgs = {
  input: UpdateCompanyDomainsPathByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathArgs = {
  input: UpdateCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyDomainsPathByDomainIdAndPathArgs = {
  input: UpdateCompanyDomainsPathByDomainIdAndPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyGroupByNodeIdArgs = {
  input: UpdateCompanyGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyGroupArgs = {
  input: UpdateCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyIntegrationByNodeIdArgs = {
  input: UpdateCompanyIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyIntegrationArgs = {
  input: UpdateCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyLocationByNodeIdArgs = {
  input: UpdateCompanyLocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyLocationArgs = {
  input: UpdateCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductByNodeIdArgs = {
  input: UpdateCompanyProductByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductArgs = {
  input: UpdateCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductIntegrationByNodeIdArgs = {
  input: UpdateCompanyProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyProductIntegrationArgs = {
  input: UpdateCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyWidgetByNodeIdArgs = {
  input: UpdateCompanyWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyWidgetArgs = {
  input: UpdateCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditAppByNodeIdArgs = {
  input: UpdateCreditAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditAppArgs = {
  input: UpdateCreditAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditAppByLeadIdArgs = {
  input: UpdateCreditAppByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditBureauByNodeIdArgs = {
  input: UpdateCreditBureauByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCreditBureauArgs = {
  input: UpdateCreditBureauInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaWidgetByNodeIdArgs = {
  input: UpdateCtaWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaWidgetArgs = {
  input: UpdateCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaByNodeIdArgs = {
  input: UpdateCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaArgs = {
  input: UpdateCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCtaByProductAndTypeAndVersionArgs = {
  input: UpdateCtaByProductAndTypeAndVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodByNodeIdArgs = {
  input: UpdateDeliveryMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodArgs = {
  input: UpdateDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateByNodeIdArgs = {
  input: UpdateEmailTemplateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderByNodeIdArgs = {
  input: UpdateEquifaxLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderArgs = {
  input: UpdateEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquifaxLenderByEquifaxIdAndNameArgs = {
  input: UpdateEquifaxLenderByEquifaxIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByNodeIdArgs = {
  input: UpdateFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldArgs = {
  input: UpdateFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyProductIdAndConfigArgs = {
  input: UpdateFieldByCompanyProductIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyChannelIdAndConfigArgs = {
  input: UpdateFieldByCompanyChannelIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldByCompanyIntegrationIdAndConfigArgs = {
  input: UpdateFieldByCompanyIntegrationIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldConfigByNodeIdArgs = {
  input: UpdateFieldConfigByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldConfigArgs = {
  input: UpdateFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyByNodeIdArgs = {
  input: UpdateFieldKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyArgs = {
  input: UpdateFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFieldKeyByKeyArgs = {
  input: UpdateFieldKeyByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByNodeIdArgs = {
  input: UpdateGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByCognitoNameArgs = {
  input: UpdateGroupByCognitoNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationByNodeIdArgs = {
  input: UpdateIdVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationArgs = {
  input: UpdateIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIdVerificationByLeadIdArgs = {
  input: UpdateIdVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationByNodeIdArgs = {
  input: UpdateIncomeVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationArgs = {
  input: UpdateIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIncomeVerificationByLeadIdArgs = {
  input: UpdateIncomeVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntegrationByNodeIdArgs = {
  input: UpdateIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntegrationArgs = {
  input: UpdateIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadByNodeIdArgs = {
  input: UpdateLeadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignByNodeIdArgs = {
  input: UpdateLeadCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignArgs = {
  input: UpdateLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadCampaignByLeadIdArgs = {
  input: UpdateLeadCampaignByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderAppByNodeIdArgs = {
  input: UpdateLenderAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderAppArgs = {
  input: UpdateLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderQuoteByNodeIdArgs = {
  input: UpdateLenderQuoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLenderQuoteArgs = {
  input: UpdateLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOptionByNodeIdArgs = {
  input: UpdateOptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOptionArgs = {
  input: UpdateOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductIntegrationByNodeIdArgs = {
  input: UpdateProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProductIntegrationArgs = {
  input: UpdateProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReviewByNodeIdArgs = {
  input: UpdateReviewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeAppByNodeIdArgs = {
  input: UpdateTradeAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTradeAppArgs = {
  input: UpdateTradeAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleListingByNodeIdArgs = {
  input: UpdateVehicleListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVehicleListingArgs = {
  input: UpdateVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetSettingByNodeIdArgs = {
  input: UpdateWidgetSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetSettingArgs = {
  input: UpdateWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetByNodeIdArgs = {
  input: UpdateWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWidgetByVariantArgs = {
  input: UpdateWidgetByVariantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticByNodeIdArgs = {
  input: DeleteAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticArgs = {
  input: DeleteAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticsCtaByNodeIdArgs = {
  input: DeleteAnalyticsCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAnalyticsCtaArgs = {
  input: DeleteAnalyticsCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppointmentByNodeIdArgs = {
  input: DeleteAppointmentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppointmentArgs = {
  input: DeleteAppointmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelByNodeIdArgs = {
  input: DeleteChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelArgs = {
  input: DeleteChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyAnalyticByNodeIdArgs = {
  input: DeleteCompanyAnalyticByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyAnalyticArgs = {
  input: DeleteCompanyAnalyticInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelByNodeIdArgs = {
  input: DeleteCompanyChannelByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyChannelArgs = {
  input: DeleteCompanyChannelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyCtaByNodeIdArgs = {
  input: DeleteCompanyCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyCtaArgs = {
  input: DeleteCompanyCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainByNodeIdArgs = {
  input: DeleteCompanyDomainByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainArgs = {
  input: DeleteCompanyDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainByDomainArgs = {
  input: DeleteCompanyDomainByDomainInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsApprovedByNodeIdArgs = {
  input: DeleteCompanyDomainsApprovedByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsApprovedArgs = {
  input: DeleteCompanyDomainsApprovedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathByNodeIdArgs = {
  input: DeleteCompanyDomainsPathByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathArgs = {
  input: DeleteCompanyDomainsPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyDomainsPathByDomainIdAndPathArgs = {
  input: DeleteCompanyDomainsPathByDomainIdAndPathInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyGroupByNodeIdArgs = {
  input: DeleteCompanyGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyGroupArgs = {
  input: DeleteCompanyGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyIntegrationByNodeIdArgs = {
  input: DeleteCompanyIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyIntegrationArgs = {
  input: DeleteCompanyIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyLocationByNodeIdArgs = {
  input: DeleteCompanyLocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyLocationArgs = {
  input: DeleteCompanyLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductByNodeIdArgs = {
  input: DeleteCompanyProductByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductArgs = {
  input: DeleteCompanyProductInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductIntegrationByNodeIdArgs = {
  input: DeleteCompanyProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyProductIntegrationArgs = {
  input: DeleteCompanyProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyWidgetByNodeIdArgs = {
  input: DeleteCompanyWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyWidgetArgs = {
  input: DeleteCompanyWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaWidgetByNodeIdArgs = {
  input: DeleteCtaWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaWidgetArgs = {
  input: DeleteCtaWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaByNodeIdArgs = {
  input: DeleteCtaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaArgs = {
  input: DeleteCtaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCtaByProductAndTypeAndVersionArgs = {
  input: DeleteCtaByProductAndTypeAndVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDeliveryMethodByNodeIdArgs = {
  input: DeleteDeliveryMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDeliveryMethodArgs = {
  input: DeleteDeliveryMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateByNodeIdArgs = {
  input: DeleteEmailTemplateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateArgs = {
  input: DeleteEmailTemplateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderByNodeIdArgs = {
  input: DeleteEquifaxLenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderArgs = {
  input: DeleteEquifaxLenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquifaxLenderByEquifaxIdAndNameArgs = {
  input: DeleteEquifaxLenderByEquifaxIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventByNodeIdArgs = {
  input: DeleteEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByNodeIdArgs = {
  input: DeleteFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldArgs = {
  input: DeleteFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyProductIdAndConfigArgs = {
  input: DeleteFieldByCompanyProductIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyChannelIdAndConfigArgs = {
  input: DeleteFieldByCompanyChannelIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldByCompanyIntegrationIdAndConfigArgs = {
  input: DeleteFieldByCompanyIntegrationIdAndConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldConfigByNodeIdArgs = {
  input: DeleteFieldConfigByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldConfigArgs = {
  input: DeleteFieldConfigInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyByNodeIdArgs = {
  input: DeleteFieldKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyArgs = {
  input: DeleteFieldKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFieldKeyByKeyArgs = {
  input: DeleteFieldKeyByKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationByNodeIdArgs = {
  input: DeleteIdVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationArgs = {
  input: DeleteIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIdVerificationByLeadIdArgs = {
  input: DeleteIdVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationByNodeIdArgs = {
  input: DeleteIncomeVerificationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationArgs = {
  input: DeleteIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIncomeVerificationByLeadIdArgs = {
  input: DeleteIncomeVerificationByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIntegrationByNodeIdArgs = {
  input: DeleteIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadByNodeIdArgs = {
  input: DeleteLeadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadArgs = {
  input: DeleteLeadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignByNodeIdArgs = {
  input: DeleteLeadCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignArgs = {
  input: DeleteLeadCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLeadCampaignByLeadIdArgs = {
  input: DeleteLeadCampaignByLeadIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderAppByNodeIdArgs = {
  input: DeleteLenderAppByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderAppArgs = {
  input: DeleteLenderAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderQuoteByNodeIdArgs = {
  input: DeleteLenderQuoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLenderQuoteArgs = {
  input: DeleteLenderQuoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOptionByNodeIdArgs = {
  input: DeleteOptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOptionArgs = {
  input: DeleteOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductIntegrationByNodeIdArgs = {
  input: DeleteProductIntegrationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProductIntegrationArgs = {
  input: DeleteProductIntegrationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReviewByNodeIdArgs = {
  input: DeleteReviewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserActivityByNodeIdArgs = {
  input: DeleteUserActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserActivityArgs = {
  input: DeleteUserActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleListingByNodeIdArgs = {
  input: DeleteVehicleListingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVehicleListingArgs = {
  input: DeleteVehicleListingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetSettingByNodeIdArgs = {
  input: DeleteWidgetSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetSettingArgs = {
  input: DeleteWidgetSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetByNodeIdArgs = {
  input: DeleteWidgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWidgetByVariantArgs = {
  input: DeleteWidgetByVariantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetPreferredLenderOfferArgs = {
  input: SetPreferredLenderOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFetchLenderQuotesArgs = {
  input: FetchLenderQuotesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetCreditAppRetailerIdArgs = {
  companyId: Scalars['Int'];
  retailerId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
  creditorIds: Array<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditAuthArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: CreditAuthInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditQueryArgs = {
  input: CreditQueryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreditConsentArgs = {
  accessToken: Scalars['String'];
  dealerCreditConsent: Scalars['Boolean'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoanAppArgs = {
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: LoanAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyPermissionsArgs = {
  input: CopyPermissionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGiveUserAccessArgs = {
  input: GiveUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevokeAccessArgs = {
  input: RevokeUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetUserPasswordArgs = {
  username: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestAccessArgs = {
  companyId: Scalars['Int'];
  topic: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTradeAppLeadArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: TradeAppCustomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyUserBySmsArgs = {
  input: SmsUserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationArchiveCompanyArgs = {
  input: ArchiveCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRenameCompanyArgs = {
  input: RenameCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyWithUserArgs = {
  input: CreateCompanyWithUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendLeadNotificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTriggerLeadNotificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyMetaArgs = {
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDeliveryMethodPreferredSenderArgs = {
  deliveryMethodId: Scalars['Int'];
  preferredSender: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTestLeadArgs = {
  companyId: Scalars['Int'];
  sourceUrl: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitLeadArgs = {
  widgetId: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
  referrer?: Maybe<Scalars['String']>;
  input: LeadInput;
  productType?: Maybe<ProductType>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminSubmitLeadArgs = {
  companyId: Scalars['Int'];
  input: LeadInput;
  productType?: Maybe<ProductType>;
  messageOverride?: Maybe<Scalars['String']>;
  creditConsentLead?: Maybe<Scalars['Boolean']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLeadMetaArgs = {
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestVerificationArgs = {
  leadId: Scalars['UUID'];
  type: VerificationChannel;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmVerificationCodeArgs = {
  leadId: Scalars['UUID'];
  type: VerificationChannel;
  code: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSoftDeleteLeadArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyUserArgs = {
  input: UserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyCompanyArgs = {
  input: CompanyNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationManageCtaSpecsArgs = {
  input: ManageCtaSpecsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackAnalyticsArgs = {
  input: AnalyticsParamsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackAnalyticsBatchArgs = {
  input: AnalyticsParamsInput2;
  events: Array<AnalyticsParamsEvents>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationZohoSubscriptionsWebhookArgs = {
  payload?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestIdVerificationArgs = {
  input: RequestIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegenerateIdVerificationTokenArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetrieveIdVerificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInitiateIdVerificationArgs = {
  input: InitiateIdVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetIdVerificationNextStepArgs = {
  input: SetIdVerificationNextStepInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadIdVerificationImagesArgs = {
  input: UploadIdVerificationImagesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadIdVerificationImageArgs = {
  input: UploadAutocorpIdImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestIncomeVerificationArgs = {
  input: RequestIncomeVerificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetIncomeLoginArgs = {
  leadId: Scalars['UUID'];
  loginId: Scalars['String'];
  directDepositAccountId?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetDirectDepositAccountArgs = {
  leadId: Scalars['UUID'];
  accountId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendFeedbackArgs = {
  leadId: Scalars['UUID'];
  message: Scalars['String'];
  type: FeedbackType;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type NotificationResponse = {
  leadId: Scalars['UUID'];
  success: Scalars['Boolean'];
};

export type Option = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
};

export type OptionAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OptionSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OptionDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OptionMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OptionMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OptionAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OptionStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OptionStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OptionVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OptionVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Option` object types. */
export type OptionAggregatesFilter = {
  /** A filter that must pass for the relevant `Option` object to be included within the aggregate. */
  filter?: Maybe<OptionFilter>;
  /** Sum aggregate over matching `Option` objects. */
  sum?: Maybe<OptionSumAggregateFilter>;
  /** Distinct count aggregate over matching `Option` objects. */
  distinctCount?: Maybe<OptionDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Option` objects. */
  min?: Maybe<OptionMinAggregateFilter>;
  /** Maximum aggregate over matching `Option` objects. */
  max?: Maybe<OptionMaxAggregateFilter>;
  /** Mean average aggregate over matching `Option` objects. */
  average?: Maybe<OptionAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Option` objects. */
  stddevSample?: Maybe<OptionStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Option` objects. */
  stddevPopulation?: Maybe<OptionStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Option` objects. */
  varianceSample?: Maybe<OptionVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Option` objects. */
  variancePopulation?: Maybe<OptionVariancePopulationAggregateFilter>;
};

export type OptionAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Option` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OptionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fieldConfigId` field. */
  fieldConfigId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `display` field. */
  display?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type OptionDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldConfigId?: Maybe<BigIntFilter>;
  display?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type OptionDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of display across the matching connection */
  display?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** The `fieldConfig` to be created by this mutation. */
export type OptionFieldConfigIdFkeyFieldConfigCreateInput = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** Input for the nested mutation of `fieldConfig` in the `OptionInput` mutation. */
export type OptionFieldConfigIdFkeyInput = {
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectById?: Maybe<FieldConfigFieldConfigPkeyConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldConfigNodeIdConnect>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteById?: Maybe<FieldConfigFieldConfigPkeyDelete>;
  /** The primary key(s) for `fieldConfig` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldConfigNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateById?: Maybe<FieldConfigOnOptionForOptionFieldConfigIdFkeyUsingFieldConfigPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldConfig` for the far side of the relationship. */
  updateByNodeId?: Maybe<OptionOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate>;
  /** A `FieldConfigInput` object that will be created and connected to this object. */
  create?: Maybe<OptionFieldConfigIdFkeyFieldConfigCreateInput>;
};

/** Input for the nested mutation of `option` in the `FieldConfigInput` mutation. */
export type OptionFieldConfigIdFkeyInverseInput = {
  /** Flag indicating whether all other `option` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  connectById?: Maybe<Array<OptionOptionPkeyConnect>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<OptionNodeIdConnect>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  deleteById?: Maybe<Array<OptionOptionPkeyDelete>>;
  /** The primary key(s) for `option` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<OptionNodeIdDelete>>;
  /** The primary key(s) and patch data for `option` for the far side of the relationship. */
  updateById?: Maybe<Array<OptionOnOptionForOptionFieldConfigIdFkeyUsingOptionPkeyUpdate>>;
  /** The primary key(s) and patch data for `option` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldConfigOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate>>;
  /** A `OptionInput` object that will be created and connected to this object. */
  create?: Maybe<Array<OptionFieldConfigIdFkeyOptionCreateInput>>;
};

/** The `option` to be created by this mutation. */
export type OptionFieldConfigIdFkeyOptionCreateInput = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

/** A filter to be used against `Option` object types. All fields are combined with a logical ‘and.’ */
export type OptionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `fieldConfigId` field. */
  fieldConfigId?: Maybe<IntFilter>;
  /** Filter by the object’s `display` field. */
  display?: Maybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `fieldConfig` relation. */
  fieldConfig?: Maybe<FieldConfigFilter>;
  /** A related `fieldConfig` exists. */
  fieldConfigExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<OptionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<OptionFilter>>;
  /** Negates the expression. */
  not?: Maybe<OptionFilter>;
};

/** Grouping methods for `Option` for usage during aggregation. */
export enum OptionGroupBy {
  FieldConfigId = 'FIELD_CONFIG_ID',
  Display = 'DISPLAY',
  Value = 'VALUE',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type OptionHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Option` aggregates. */
export type OptionHavingInput = {
  AND?: Maybe<Array<OptionHavingInput>>;
  OR?: Maybe<Array<OptionHavingInput>>;
  sum?: Maybe<OptionHavingSumInput>;
  distinctCount?: Maybe<OptionHavingDistinctCountInput>;
  min?: Maybe<OptionHavingMinInput>;
  max?: Maybe<OptionHavingMaxInput>;
  average?: Maybe<OptionHavingAverageInput>;
  stddevSample?: Maybe<OptionHavingStddevSampleInput>;
  stddevPopulation?: Maybe<OptionHavingStddevPopulationInput>;
  varianceSample?: Maybe<OptionHavingVarianceSampleInput>;
  variancePopulation?: Maybe<OptionHavingVariancePopulationInput>;
};

export type OptionHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type OptionHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldConfigId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Option` */
export type OptionInput = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display: Scalars['String'];
  value: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

export type OptionMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldConfigId?: Maybe<IntFilter>;
};

export type OptionMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['Int']>;
};

export type OptionMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldConfigId?: Maybe<IntFilter>;
};

export type OptionMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OptionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `option` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type OptionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `option` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OptionOnOptionForOptionFieldConfigIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `option` to look up the row to update. */
export type OptionOnOptionForOptionFieldConfigIdFkeyUsingOptionPkeyUpdate = {
  /** An object where the defined keys will be set on the `option` being updated. */
  patch: UpdateOptionOnOptionForOptionFieldConfigIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `option` to look up the row to connect. */
export type OptionOptionPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `option` to look up the row to delete. */
export type OptionOptionPkeyDelete = {
  id: Scalars['Int'];
};

/** Represents an update to a `Option`. Fields that are set will be updated. */
export type OptionPatch = {
  id?: Maybe<Scalars['Int']>;
  fieldConfigId?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

export type OptionStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldConfigId?: Maybe<BigIntFilter>;
};

export type OptionSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of fieldConfigId across the matching connection */
  fieldConfigId: Scalars['BigInt'];
};

export type OptionVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

export type OptionVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldConfigId?: Maybe<BigFloatFilter>;
};

export type OptionVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fieldConfigId across the matching connection */
  fieldConfigId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Option` values. */
export type OptionsConnection = {
  /** A list of `Option` objects. */
  nodes: Array<Option>;
  /** A list of edges which contains the `Option` and cursor to aid in pagination. */
  edges: Array<OptionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Option` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OptionAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OptionAggregates>>;
};


/** A connection to a list of `Option` values. */
export type OptionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OptionGroupBy>;
  having?: Maybe<OptionHavingInput>;
};

/** A `Option` edge in the connection. */
export type OptionsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Option` at the end of the edge. */
  node: Option;
};

/** Methods to use when ordering `Option`. */
export enum OptionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  FieldConfigIdAsc = 'FIELD_CONFIG_ID_ASC',
  FieldConfigIdDesc = 'FIELD_CONFIG_ID_DESC',
  DisplayAsc = 'DISPLAY_ASC',
  DisplayDesc = 'DISPLAY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type PartialTradeApp = {
  condition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  make?: Maybe<Scalars['String']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  mileage?: Maybe<Scalars['Int']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  tradeInValue?: Maybe<Scalars['Float']>;
  trim?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export enum Permissions {
  ManageUsers = 'MANAGE_USERS',
  ViewLeads = 'VIEW_LEADS',
  Admin = 'ADMIN',
  ManageDeliveries = 'MANAGE_DELIVERIES'
}

export type Product = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  displayName: Scalars['String'];
  name: ProductType;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByCompanyProductProductIdAndCompanyId: ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLeadJourneyProductIdAndLeadId: ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByProductIntegrationProductIdAndIntegrationId: ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByFieldConfigProductIdAndChannelId: ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByFieldConfigProductIdAndIntegrationId: ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyConnection;
};


export type ProductCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


export type ProductLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


export type ProductProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


export type ProductFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


export type ProductCompaniesByCompanyProductProductIdAndCompanyIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


export type ProductLeadsByLeadJourneyProductIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


export type ProductChannelsByFieldConfigProductIdAndChannelIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


export type ProductIntegrationsByFieldConfigProductIdAndIntegrationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};

export type ProductAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProductSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProductDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProductMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProductMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProductAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProductStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProductStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProductVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProductVariancePopulationAggregates>;
};

export type ProductAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Channel` values, with data from `FieldConfig`. */
export type ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyConnection = {
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** A list of edges which contains the `Channel`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ChannelAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ChannelAggregates>>;
};


/** A connection to a list of `Channel` values, with data from `FieldConfig`. */
export type ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ChannelGroupBy>;
  having?: Maybe<ChannelHavingInput>;
};

/** A `Channel` edge in the connection, with data from `FieldConfig`. */
export type ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Channel` edge in the connection, with data from `FieldConfig`. */
export type ProductChannelsByFieldConfigProductIdAndChannelIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

/** A connection to a list of `Company` values, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnection = {
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** A list of edges which contains the `Company`, info from the `CompanyProduct`, and the cursor to aid in pagination. */
  edges: Array<ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
};


/** A connection to a list of `Company` values, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: Maybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts: CompanyProductsConnection;
};


/** A `Company` edge in the connection, with data from `CompanyProduct`. */
export type ProductCompaniesByCompanyProductProductIdAndCompanyIdManyToManyEdgeCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};

/** A condition to be used against `Product` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProductCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<ProductType>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ProductDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Product` object types. All fields are combined with a logical ‘and.’ */
export type ProductFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<ProductTypeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `companyProducts` relation. */
  companyProducts?: Maybe<ProductToManyCompanyProductFilter>;
  /** Some related `companyProducts` exist. */
  companyProductsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `leadJourneys` relation. */
  leadJourneys?: Maybe<ProductToManyLeadJourneyFilter>;
  /** Some related `leadJourneys` exist. */
  leadJourneysExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `productIntegrations` relation. */
  productIntegrations?: Maybe<ProductToManyProductIntegrationFilter>;
  /** Some related `productIntegrations` exist. */
  productIntegrationsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldConfigs` relation. */
  fieldConfigs?: Maybe<ProductToManyFieldConfigFilter>;
  /** Some related `fieldConfigs` exist. */
  fieldConfigsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProductFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProductFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProductFilter>;
};

/** Grouping methods for `Product` for usage during aggregation. */
export enum ProductGroupBy {
  DisplayName = 'DISPLAY_NAME',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ProductHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Product` aggregates. */
export type ProductHavingInput = {
  AND?: Maybe<Array<ProductHavingInput>>;
  OR?: Maybe<Array<ProductHavingInput>>;
  sum?: Maybe<ProductHavingSumInput>;
  distinctCount?: Maybe<ProductHavingDistinctCountInput>;
  min?: Maybe<ProductHavingMinInput>;
  max?: Maybe<ProductHavingMaxInput>;
  average?: Maybe<ProductHavingAverageInput>;
  stddevSample?: Maybe<ProductHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ProductHavingStddevPopulationInput>;
  varianceSample?: Maybe<ProductHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ProductHavingVariancePopulationInput>;
};

export type ProductHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegration = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
};

export type ProductIntegrationAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProductIntegrationSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProductIntegrationDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProductIntegrationMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProductIntegrationMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProductIntegrationAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProductIntegrationStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProductIntegrationStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProductIntegrationVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProductIntegrationVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `ProductIntegration` object types. */
export type ProductIntegrationAggregatesFilter = {
  /** A filter that must pass for the relevant `ProductIntegration` object to be included within the aggregate. */
  filter?: Maybe<ProductIntegrationFilter>;
  /** Sum aggregate over matching `ProductIntegration` objects. */
  sum?: Maybe<ProductIntegrationSumAggregateFilter>;
  /** Distinct count aggregate over matching `ProductIntegration` objects. */
  distinctCount?: Maybe<ProductIntegrationDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `ProductIntegration` objects. */
  min?: Maybe<ProductIntegrationMinAggregateFilter>;
  /** Maximum aggregate over matching `ProductIntegration` objects. */
  max?: Maybe<ProductIntegrationMaxAggregateFilter>;
  /** Mean average aggregate over matching `ProductIntegration` objects. */
  average?: Maybe<ProductIntegrationAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProductIntegration` objects. */
  stddevSample?: Maybe<ProductIntegrationStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProductIntegration` objects. */
  stddevPopulation?: Maybe<ProductIntegrationStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProductIntegration` objects. */
  varianceSample?: Maybe<ProductIntegrationVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `ProductIntegration` objects. */
  variancePopulation?: Maybe<ProductIntegrationVariancePopulationAggregateFilter>;
};

export type ProductIntegrationAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ProductIntegration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProductIntegrationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ProductIntegrationDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
  enabled?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type ProductIntegrationDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of productId across the matching connection */
  productId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enabled across the matching connection */
  enabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type ProductIntegrationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId?: Maybe<IntFilter>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<IntFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `integration` relation. */
  integration?: Maybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `product` relation. */
  product?: Maybe<ProductFilter>;
  /** A related `product` exists. */
  productExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ProductIntegrationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ProductIntegrationFilter>>;
  /** Negates the expression. */
  not?: Maybe<ProductIntegrationFilter>;
};

/** Grouping methods for `ProductIntegration` for usage during aggregation. */
export enum ProductIntegrationGroupBy {
  IntegrationId = 'INTEGRATION_ID',
  ProductId = 'PRODUCT_ID',
  Enabled = 'ENABLED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ProductIntegrationHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `ProductIntegration` aggregates. */
export type ProductIntegrationHavingInput = {
  AND?: Maybe<Array<ProductIntegrationHavingInput>>;
  OR?: Maybe<Array<ProductIntegrationHavingInput>>;
  sum?: Maybe<ProductIntegrationHavingSumInput>;
  distinctCount?: Maybe<ProductIntegrationHavingDistinctCountInput>;
  min?: Maybe<ProductIntegrationHavingMinInput>;
  max?: Maybe<ProductIntegrationHavingMaxInput>;
  average?: Maybe<ProductIntegrationHavingAverageInput>;
  stddevSample?: Maybe<ProductIntegrationHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ProductIntegrationHavingStddevPopulationInput>;
  varianceSample?: Maybe<ProductIntegrationHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ProductIntegrationHavingVariancePopulationInput>;
};

export type ProductIntegrationHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ProductIntegrationHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  integrationId?: Maybe<HavingIntFilter>;
  productId?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ProductIntegration` */
export type ProductIntegrationInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** Input for the nested mutation of `integration` in the `ProductIntegrationInput` mutation. */
export type ProductIntegrationIntegrationIdFkeyInput = {
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectById?: Maybe<IntegrationIntegrationPkeyConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  connectByNodeId?: Maybe<IntegrationNodeIdConnect>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteById?: Maybe<IntegrationIntegrationPkeyDelete>;
  /** The primary key(s) for `integration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<IntegrationNodeIdDelete>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateById?: Maybe<IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingIntegrationPkeyUpdate>;
  /** The primary key(s) and patch data for `integration` for the far side of the relationship. */
  updateByNodeId?: Maybe<ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate>;
  /** A `IntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<ProductIntegrationIntegrationIdFkeyIntegrationCreateInput>;
};

/** The `integration` to be created by this mutation. */
export type ProductIntegrationIntegrationIdFkeyIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** Input for the nested mutation of `productIntegration` in the `IntegrationInput` mutation. */
export type ProductIntegrationIntegrationIdFkeyInverseInput = {
  /** Flag indicating whether all other `productIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<IntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate>>;
  /** A `ProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ProductIntegrationIntegrationIdFkeyProductIntegrationCreateInput>>;
};

/** The `productIntegration` to be created by this mutation. */
export type ProductIntegrationIntegrationIdFkeyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

export type ProductIntegrationMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductIntegrationMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Maximum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

export type ProductIntegrationMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  integrationId?: Maybe<IntFilter>;
  productId?: Maybe<IntFilter>;
};

export type ProductIntegrationMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
  /** Minimum of productId across the matching connection */
  productId?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductIntegrationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ProductIntegrationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `integration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `integration` being updated. */
  patch: IntegrationPatch;
};

/** The fields on `productIntegration` to look up the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyUsingProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: UpdateProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: ProductPatch;
};

/** The fields on `productIntegration` to look up the row to update. */
export type ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductIntegrationPkeyUpdate = {
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: UpdateProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `ProductIntegration`. Fields that are set will be updated. */
export type ProductIntegrationPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** Input for the nested mutation of `product` in the `ProductIntegrationInput` mutation. */
export type ProductIntegrationProductIdFkeyInput = {
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectById?: Maybe<ProductProductPkeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByName?: Maybe<ProductProductNameKeyConnect>;
  /** The primary key(s) for `product` for the far side of the relationship. */
  connectByNodeId?: Maybe<ProductNodeIdConnect>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateById?: Maybe<ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductPkeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByName?: Maybe<ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductNameKeyUpdate>;
  /** The primary key(s) and patch data for `product` for the far side of the relationship. */
  updateByNodeId?: Maybe<ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `productIntegration` in the `ProductInput` mutation. */
export type ProductIntegrationProductIdFkeyInverseInput = {
  /** Flag indicating whether all other `productIntegration` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ProductIntegrationNodeIdConnect>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteById?: Maybe<Array<ProductIntegrationProductIntegrationPkeyDelete>>;
  /** The primary key(s) for `productIntegration` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ProductIntegrationNodeIdDelete>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateById?: Maybe<Array<ProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductIntegrationPkeyUpdate>>;
  /** The primary key(s) and patch data for `productIntegration` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<ProductOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate>>;
  /** A `ProductIntegrationInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ProductIntegrationProductIdFkeyProductIntegrationCreateInput>>;
};

/** The `productIntegration` to be created by this mutation. */
export type ProductIntegrationProductIdFkeyProductIntegrationCreateInput = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** The fields on `productIntegration` to look up the row to connect. */
export type ProductIntegrationProductIntegrationPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `productIntegration` to look up the row to delete. */
export type ProductIntegrationProductIntegrationPkeyDelete = {
  id: Scalars['Int'];
};

export type ProductIntegrationStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  integrationId?: Maybe<BigIntFilter>;
  productId?: Maybe<BigIntFilter>;
};

export type ProductIntegrationSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
  /** Sum of productId across the matching connection */
  productId: Scalars['BigInt'];
};

export type ProductIntegrationVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

export type ProductIntegrationVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  integrationId?: Maybe<BigFloatFilter>;
  productId?: Maybe<BigFloatFilter>;
};

export type ProductIntegrationVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of productId across the matching connection */
  productId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values, with data from `FieldConfig`. */
export type ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `FieldConfig`, and the cursor to aid in pagination. */
  edges: Array<ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `FieldConfig`. */
export type ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `FieldConfig`. */
export type ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs: FieldConfigsConnection;
};


/** A `Integration` edge in the connection, with data from `FieldConfig`. */
export type ProductIntegrationsByFieldConfigProductIdAndIntegrationIdManyToManyEdgeFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};

/** A connection to a list of `Integration` values, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnection = {
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** A list of edges which contains the `Integration`, info from the `ProductIntegration`, and the cursor to aid in pagination. */
  edges: Array<ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
};


/** A connection to a list of `Integration` values, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationGroupBy>;
  having?: Maybe<IntegrationHavingInput>;
};

/** A `Integration` edge in the connection, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations: ProductIntegrationsConnection;
};


/** A `Integration` edge in the connection, with data from `ProductIntegration`. */
export type ProductIntegrationsByProductIntegrationProductIdAndIntegrationIdManyToManyEdgeProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};

/** A connection to a list of `ProductIntegration` values. */
export type ProductIntegrationsConnection = {
  /** A list of `ProductIntegration` objects. */
  nodes: Array<ProductIntegration>;
  /** A list of edges which contains the `ProductIntegration` and cursor to aid in pagination. */
  edges: Array<ProductIntegrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProductIntegration` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductIntegrationAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductIntegrationAggregates>>;
};


/** A connection to a list of `ProductIntegration` values. */
export type ProductIntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductIntegrationGroupBy>;
  having?: Maybe<ProductIntegrationHavingInput>;
};

/** A `ProductIntegration` edge in the connection. */
export type ProductIntegrationsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProductIntegration` at the end of the edge. */
  node: ProductIntegration;
};

/** Methods to use when ordering `ProductIntegration`. */
export enum ProductIntegrationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ProductLeadCount = {
  productType: ProductType;
  count: Scalars['Int'];
  productDisplayName: Scalars['String'];
};

/** A connection to a list of `Lead` values, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LeadJourney`, and the cursor to aid in pagination. */
  edges: Array<ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys: LeadJourneysConnection;
};


/** A `Lead` edge in the connection, with data from `LeadJourney`. */
export type ProductLeadsByLeadJourneyProductIdAndLeadIdManyToManyEdgeLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};

export type ProductMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ProductMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProductNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `product` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyProduct` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnCompanyProductForCompanyProductProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnFieldConfigForFieldConfigProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldConfig` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnFieldConfigForFieldConfigProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnFieldConfigForFieldConfigProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnFieldConfigForFieldConfigProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnFieldConfigForFieldConfigProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The fields on `product` to look up the row to update. */
export type ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnLeadJourneyForLeadJourneyProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `productIntegration` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `productIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductNameKeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  name: ProductType;
};

/** The fields on `product` to look up the row to update. */
export type ProductOnProductIntegrationForProductIntegrationProductIdFkeyUsingProductPkeyUpdate = {
  /** An object where the defined keys will be set on the `product` being updated. */
  patch: UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Product`. Fields that are set will be updated. */
export type ProductPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigProductIdFkeyInverseInput>;
};

/** The fields on `product` to look up the row to connect. */
export type ProductProductNameKeyConnect = {
  name: ProductType;
};

/** The fields on `product` to look up the row to connect. */
export type ProductProductPkeyConnect = {
  id: Scalars['Int'];
};

export type ProductStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `CompanyProduct` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyCompanyProductFilter = {
  /** Every related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyProductFilter>;
  /** Some related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyProductFilter>;
  /** No related `CompanyProduct` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyProductFilter>;
  /** Aggregates across related `CompanyProduct` match the filter criteria. */
  aggregates?: Maybe<CompanyProductAggregatesFilter>;
};

/** A filter to be used against many `FieldConfig` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyFieldConfigFilter = {
  /** Every related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<FieldConfigFilter>;
  /** Some related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<FieldConfigFilter>;
  /** No related `FieldConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<FieldConfigFilter>;
  /** Aggregates across related `FieldConfig` match the filter criteria. */
  aggregates?: Maybe<FieldConfigAggregatesFilter>;
};

/** A filter to be used against many `LeadJourney` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyLeadJourneyFilter = {
  /** Every related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LeadJourneyFilter>;
  /** Some related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LeadJourneyFilter>;
  /** No related `LeadJourney` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LeadJourneyFilter>;
  /** Aggregates across related `LeadJourney` match the filter criteria. */
  aggregates?: Maybe<LeadJourneyAggregatesFilter>;
};

/** A filter to be used against many `ProductIntegration` object types. All fields are combined with a logical ‘and.’ */
export type ProductToManyProductIntegrationFilter = {
  /** Every related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ProductIntegrationFilter>;
  /** Some related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ProductIntegrationFilter>;
  /** No related `ProductIntegration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ProductIntegrationFilter>;
  /** Aggregates across related `ProductIntegration` match the filter criteria. */
  aggregates?: Maybe<ProductIntegrationAggregatesFilter>;
};

export enum ProductType {
  CreditTool = 'CREDIT_TOOL',
  TradeTool = 'TRADE_TOOL',
  LenderTool = 'LENDER_TOOL',
  TradePro = 'TRADE_PRO',
  BuyTool = 'BUY_TOOL',
  IdTool = 'ID_TOOL',
  ReviewTool = 'REVIEW_TOOL',
  MsaTool = 'MSA_TOOL',
  DriveTool = 'DRIVE_TOOL',
  IncomeTool = 'INCOME_TOOL'
}

/** A filter to be used against ProductType fields. All fields are combined with a logical ‘and.’ */
export type ProductTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ProductType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ProductType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ProductType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ProductType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ProductType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ProductType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ProductType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ProductType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ProductType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ProductType>;
};

export type ProductVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProductVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Product` values. */
export type ProductsConnection = {
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** A list of edges which contains the `Product` and cursor to aid in pagination. */
  edges: Array<ProductsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProductAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProductAggregates>>;
};


/** A connection to a list of `Product` values. */
export type ProductsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProductGroupBy>;
  having?: Maybe<ProductHavingInput>;
};

/** A `Product` edge in the connection. */
export type ProductsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Product` at the end of the edge. */
  node: Product;
};

/** Methods to use when ordering `Product`. */
export enum ProductsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyProductsCountAsc = 'COMPANY_PRODUCTS_COUNT_ASC',
  CompanyProductsCountDesc = 'COMPANY_PRODUCTS_COUNT_DESC',
  CompanyProductsSumCompanyIdAsc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_ASC',
  CompanyProductsSumCompanyIdDesc = 'COMPANY_PRODUCTS_SUM_COMPANY_ID_DESC',
  CompanyProductsSumProductIdAsc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_ASC',
  CompanyProductsSumProductIdDesc = 'COMPANY_PRODUCTS_SUM_PRODUCT_ID_DESC',
  CompanyProductsSumCreatedAtAsc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_ASC',
  CompanyProductsSumCreatedAtDesc = 'COMPANY_PRODUCTS_SUM_CREATED_AT_DESC',
  CompanyProductsSumUpdatedAtAsc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_ASC',
  CompanyProductsSumUpdatedAtDesc = 'COMPANY_PRODUCTS_SUM_UPDATED_AT_DESC',
  CompanyProductsSumIdAsc = 'COMPANY_PRODUCTS_SUM_ID_ASC',
  CompanyProductsSumIdDesc = 'COMPANY_PRODUCTS_SUM_ID_DESC',
  CompanyProductsSumEnabledAsc = 'COMPANY_PRODUCTS_SUM_ENABLED_ASC',
  CompanyProductsSumEnabledDesc = 'COMPANY_PRODUCTS_SUM_ENABLED_DESC',
  CompanyProductsDistinctCountCompanyIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyProductsDistinctCountCompanyIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyProductsDistinctCountProductIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  CompanyProductsDistinctCountProductIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  CompanyProductsDistinctCountCreatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyProductsDistinctCountCreatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyProductsDistinctCountUpdatedAtAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyProductsDistinctCountUpdatedAtDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyProductsDistinctCountIdAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_ASC',
  CompanyProductsDistinctCountIdDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ID_DESC',
  CompanyProductsDistinctCountEnabledAsc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_ASC',
  CompanyProductsDistinctCountEnabledDesc = 'COMPANY_PRODUCTS_DISTINCT_COUNT_ENABLED_DESC',
  CompanyProductsMinCompanyIdAsc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_ASC',
  CompanyProductsMinCompanyIdDesc = 'COMPANY_PRODUCTS_MIN_COMPANY_ID_DESC',
  CompanyProductsMinProductIdAsc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_ASC',
  CompanyProductsMinProductIdDesc = 'COMPANY_PRODUCTS_MIN_PRODUCT_ID_DESC',
  CompanyProductsMinCreatedAtAsc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_ASC',
  CompanyProductsMinCreatedAtDesc = 'COMPANY_PRODUCTS_MIN_CREATED_AT_DESC',
  CompanyProductsMinUpdatedAtAsc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_ASC',
  CompanyProductsMinUpdatedAtDesc = 'COMPANY_PRODUCTS_MIN_UPDATED_AT_DESC',
  CompanyProductsMinIdAsc = 'COMPANY_PRODUCTS_MIN_ID_ASC',
  CompanyProductsMinIdDesc = 'COMPANY_PRODUCTS_MIN_ID_DESC',
  CompanyProductsMinEnabledAsc = 'COMPANY_PRODUCTS_MIN_ENABLED_ASC',
  CompanyProductsMinEnabledDesc = 'COMPANY_PRODUCTS_MIN_ENABLED_DESC',
  CompanyProductsMaxCompanyIdAsc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_ASC',
  CompanyProductsMaxCompanyIdDesc = 'COMPANY_PRODUCTS_MAX_COMPANY_ID_DESC',
  CompanyProductsMaxProductIdAsc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_ASC',
  CompanyProductsMaxProductIdDesc = 'COMPANY_PRODUCTS_MAX_PRODUCT_ID_DESC',
  CompanyProductsMaxCreatedAtAsc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_ASC',
  CompanyProductsMaxCreatedAtDesc = 'COMPANY_PRODUCTS_MAX_CREATED_AT_DESC',
  CompanyProductsMaxUpdatedAtAsc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_ASC',
  CompanyProductsMaxUpdatedAtDesc = 'COMPANY_PRODUCTS_MAX_UPDATED_AT_DESC',
  CompanyProductsMaxIdAsc = 'COMPANY_PRODUCTS_MAX_ID_ASC',
  CompanyProductsMaxIdDesc = 'COMPANY_PRODUCTS_MAX_ID_DESC',
  CompanyProductsMaxEnabledAsc = 'COMPANY_PRODUCTS_MAX_ENABLED_ASC',
  CompanyProductsMaxEnabledDesc = 'COMPANY_PRODUCTS_MAX_ENABLED_DESC',
  CompanyProductsAverageCompanyIdAsc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_ASC',
  CompanyProductsAverageCompanyIdDesc = 'COMPANY_PRODUCTS_AVERAGE_COMPANY_ID_DESC',
  CompanyProductsAverageProductIdAsc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_ASC',
  CompanyProductsAverageProductIdDesc = 'COMPANY_PRODUCTS_AVERAGE_PRODUCT_ID_DESC',
  CompanyProductsAverageCreatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_ASC',
  CompanyProductsAverageCreatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_CREATED_AT_DESC',
  CompanyProductsAverageUpdatedAtAsc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_ASC',
  CompanyProductsAverageUpdatedAtDesc = 'COMPANY_PRODUCTS_AVERAGE_UPDATED_AT_DESC',
  CompanyProductsAverageIdAsc = 'COMPANY_PRODUCTS_AVERAGE_ID_ASC',
  CompanyProductsAverageIdDesc = 'COMPANY_PRODUCTS_AVERAGE_ID_DESC',
  CompanyProductsAverageEnabledAsc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_ASC',
  CompanyProductsAverageEnabledDesc = 'COMPANY_PRODUCTS_AVERAGE_ENABLED_DESC',
  CompanyProductsStddevSampleCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsStddevSampleCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsStddevSampleProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsStddevSampleProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsStddevSampleCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyProductsStddevSampleCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyProductsStddevSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsStddevSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsStddevSampleIdAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_ASC',
  CompanyProductsStddevSampleIdDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ID_DESC',
  CompanyProductsStddevSampleEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyProductsStddevSampleEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyProductsStddevPopulationCompanyIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyProductsStddevPopulationCompanyIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyProductsStddevPopulationProductIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsStddevPopulationProductIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsStddevPopulationCreatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyProductsStddevPopulationCreatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyProductsStddevPopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyProductsStddevPopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyProductsStddevPopulationIdAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_ASC',
  CompanyProductsStddevPopulationIdDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ID_DESC',
  CompanyProductsStddevPopulationEnabledAsc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_ASC',
  CompanyProductsStddevPopulationEnabledDesc = 'COMPANY_PRODUCTS_STDDEV_POPULATION_ENABLED_DESC',
  CompanyProductsVarianceSampleCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyProductsVarianceSampleCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyProductsVarianceSampleProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  CompanyProductsVarianceSampleProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  CompanyProductsVarianceSampleCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyProductsVarianceSampleCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyProductsVarianceSampleUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyProductsVarianceSampleUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyProductsVarianceSampleIdAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_ASC',
  CompanyProductsVarianceSampleIdDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ID_DESC',
  CompanyProductsVarianceSampleEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyProductsVarianceSampleEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyProductsVariancePopulationCompanyIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyProductsVariancePopulationCompanyIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyProductsVariancePopulationProductIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  CompanyProductsVariancePopulationProductIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  CompanyProductsVariancePopulationCreatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyProductsVariancePopulationCreatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyProductsVariancePopulationUpdatedAtAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyProductsVariancePopulationUpdatedAtDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyProductsVariancePopulationIdAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_ASC',
  CompanyProductsVariancePopulationIdDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ID_DESC',
  CompanyProductsVariancePopulationEnabledAsc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyProductsVariancePopulationEnabledDesc = 'COMPANY_PRODUCTS_VARIANCE_POPULATION_ENABLED_DESC',
  LeadJourneysCountAsc = 'LEAD_JOURNEYS_COUNT_ASC',
  LeadJourneysCountDesc = 'LEAD_JOURNEYS_COUNT_DESC',
  LeadJourneysSumLeadIdAsc = 'LEAD_JOURNEYS_SUM_LEAD_ID_ASC',
  LeadJourneysSumLeadIdDesc = 'LEAD_JOURNEYS_SUM_LEAD_ID_DESC',
  LeadJourneysSumStageAsc = 'LEAD_JOURNEYS_SUM_STAGE_ASC',
  LeadJourneysSumStageDesc = 'LEAD_JOURNEYS_SUM_STAGE_DESC',
  LeadJourneysSumProductIdAsc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_ASC',
  LeadJourneysSumProductIdDesc = 'LEAD_JOURNEYS_SUM_PRODUCT_ID_DESC',
  LeadJourneysSumCreatedAtAsc = 'LEAD_JOURNEYS_SUM_CREATED_AT_ASC',
  LeadJourneysSumCreatedAtDesc = 'LEAD_JOURNEYS_SUM_CREATED_AT_DESC',
  LeadJourneysSumUpdatedAtAsc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_ASC',
  LeadJourneysSumUpdatedAtDesc = 'LEAD_JOURNEYS_SUM_UPDATED_AT_DESC',
  LeadJourneysDistinctCountLeadIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_ASC',
  LeadJourneysDistinctCountLeadIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_LEAD_ID_DESC',
  LeadJourneysDistinctCountStageAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_ASC',
  LeadJourneysDistinctCountStageDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_STAGE_DESC',
  LeadJourneysDistinctCountProductIdAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  LeadJourneysDistinctCountProductIdDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  LeadJourneysDistinctCountCreatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LeadJourneysDistinctCountCreatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LeadJourneysDistinctCountUpdatedAtAsc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LeadJourneysDistinctCountUpdatedAtDesc = 'LEAD_JOURNEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LeadJourneysMinLeadIdAsc = 'LEAD_JOURNEYS_MIN_LEAD_ID_ASC',
  LeadJourneysMinLeadIdDesc = 'LEAD_JOURNEYS_MIN_LEAD_ID_DESC',
  LeadJourneysMinStageAsc = 'LEAD_JOURNEYS_MIN_STAGE_ASC',
  LeadJourneysMinStageDesc = 'LEAD_JOURNEYS_MIN_STAGE_DESC',
  LeadJourneysMinProductIdAsc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_ASC',
  LeadJourneysMinProductIdDesc = 'LEAD_JOURNEYS_MIN_PRODUCT_ID_DESC',
  LeadJourneysMinCreatedAtAsc = 'LEAD_JOURNEYS_MIN_CREATED_AT_ASC',
  LeadJourneysMinCreatedAtDesc = 'LEAD_JOURNEYS_MIN_CREATED_AT_DESC',
  LeadJourneysMinUpdatedAtAsc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_ASC',
  LeadJourneysMinUpdatedAtDesc = 'LEAD_JOURNEYS_MIN_UPDATED_AT_DESC',
  LeadJourneysMaxLeadIdAsc = 'LEAD_JOURNEYS_MAX_LEAD_ID_ASC',
  LeadJourneysMaxLeadIdDesc = 'LEAD_JOURNEYS_MAX_LEAD_ID_DESC',
  LeadJourneysMaxStageAsc = 'LEAD_JOURNEYS_MAX_STAGE_ASC',
  LeadJourneysMaxStageDesc = 'LEAD_JOURNEYS_MAX_STAGE_DESC',
  LeadJourneysMaxProductIdAsc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_ASC',
  LeadJourneysMaxProductIdDesc = 'LEAD_JOURNEYS_MAX_PRODUCT_ID_DESC',
  LeadJourneysMaxCreatedAtAsc = 'LEAD_JOURNEYS_MAX_CREATED_AT_ASC',
  LeadJourneysMaxCreatedAtDesc = 'LEAD_JOURNEYS_MAX_CREATED_AT_DESC',
  LeadJourneysMaxUpdatedAtAsc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_ASC',
  LeadJourneysMaxUpdatedAtDesc = 'LEAD_JOURNEYS_MAX_UPDATED_AT_DESC',
  LeadJourneysAverageLeadIdAsc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_ASC',
  LeadJourneysAverageLeadIdDesc = 'LEAD_JOURNEYS_AVERAGE_LEAD_ID_DESC',
  LeadJourneysAverageStageAsc = 'LEAD_JOURNEYS_AVERAGE_STAGE_ASC',
  LeadJourneysAverageStageDesc = 'LEAD_JOURNEYS_AVERAGE_STAGE_DESC',
  LeadJourneysAverageProductIdAsc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_ASC',
  LeadJourneysAverageProductIdDesc = 'LEAD_JOURNEYS_AVERAGE_PRODUCT_ID_DESC',
  LeadJourneysAverageCreatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_ASC',
  LeadJourneysAverageCreatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_CREATED_AT_DESC',
  LeadJourneysAverageUpdatedAtAsc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_ASC',
  LeadJourneysAverageUpdatedAtDesc = 'LEAD_JOURNEYS_AVERAGE_UPDATED_AT_DESC',
  LeadJourneysStddevSampleLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LeadJourneysStddevSampleLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LeadJourneysStddevSampleStageAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_ASC',
  LeadJourneysStddevSampleStageDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_STAGE_DESC',
  LeadJourneysStddevSampleProductIdAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysStddevSampleProductIdDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysStddevSampleCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LeadJourneysStddevSampleCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LeadJourneysStddevSampleUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysStddevSampleUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysStddevPopulationLeadIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_ASC',
  LeadJourneysStddevPopulationLeadIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_LEAD_ID_DESC',
  LeadJourneysStddevPopulationStageAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_ASC',
  LeadJourneysStddevPopulationStageDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_STAGE_DESC',
  LeadJourneysStddevPopulationProductIdAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysStddevPopulationProductIdDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysStddevPopulationCreatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LeadJourneysStddevPopulationCreatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LeadJourneysStddevPopulationUpdatedAtAsc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LeadJourneysStddevPopulationUpdatedAtDesc = 'LEAD_JOURNEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LeadJourneysVarianceSampleLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LeadJourneysVarianceSampleLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LeadJourneysVarianceSampleStageAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_ASC',
  LeadJourneysVarianceSampleStageDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_STAGE_DESC',
  LeadJourneysVarianceSampleProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  LeadJourneysVarianceSampleProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  LeadJourneysVarianceSampleCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LeadJourneysVarianceSampleCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LeadJourneysVarianceSampleUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LeadJourneysVarianceSampleUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LeadJourneysVariancePopulationLeadIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LeadJourneysVariancePopulationLeadIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LeadJourneysVariancePopulationStageAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_ASC',
  LeadJourneysVariancePopulationStageDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_STAGE_DESC',
  LeadJourneysVariancePopulationProductIdAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  LeadJourneysVariancePopulationProductIdDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  LeadJourneysVariancePopulationCreatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LeadJourneysVariancePopulationCreatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LeadJourneysVariancePopulationUpdatedAtAsc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LeadJourneysVariancePopulationUpdatedAtDesc = 'LEAD_JOURNEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsCountAsc = 'PRODUCT_INTEGRATIONS_COUNT_ASC',
  ProductIntegrationsCountDesc = 'PRODUCT_INTEGRATIONS_COUNT_DESC',
  ProductIntegrationsSumIdAsc = 'PRODUCT_INTEGRATIONS_SUM_ID_ASC',
  ProductIntegrationsSumIdDesc = 'PRODUCT_INTEGRATIONS_SUM_ID_DESC',
  ProductIntegrationsSumIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_ASC',
  ProductIntegrationsSumIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_SUM_INTEGRATION_ID_DESC',
  ProductIntegrationsSumProductIdAsc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_ASC',
  ProductIntegrationsSumProductIdDesc = 'PRODUCT_INTEGRATIONS_SUM_PRODUCT_ID_DESC',
  ProductIntegrationsSumEnabledAsc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_ASC',
  ProductIntegrationsSumEnabledDesc = 'PRODUCT_INTEGRATIONS_SUM_ENABLED_DESC',
  ProductIntegrationsSumCreatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_ASC',
  ProductIntegrationsSumCreatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_CREATED_AT_DESC',
  ProductIntegrationsSumUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_ASC',
  ProductIntegrationsSumUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_SUM_UPDATED_AT_DESC',
  ProductIntegrationsDistinctCountIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_ASC',
  ProductIntegrationsDistinctCountIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ID_DESC',
  ProductIntegrationsDistinctCountIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  ProductIntegrationsDistinctCountIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  ProductIntegrationsDistinctCountProductIdAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  ProductIntegrationsDistinctCountProductIdDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  ProductIntegrationsDistinctCountEnabledAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_ASC',
  ProductIntegrationsDistinctCountEnabledDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_ENABLED_DESC',
  ProductIntegrationsDistinctCountCreatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  ProductIntegrationsDistinctCountCreatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  ProductIntegrationsDistinctCountUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProductIntegrationsDistinctCountUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProductIntegrationsMinIdAsc = 'PRODUCT_INTEGRATIONS_MIN_ID_ASC',
  ProductIntegrationsMinIdDesc = 'PRODUCT_INTEGRATIONS_MIN_ID_DESC',
  ProductIntegrationsMinIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_ASC',
  ProductIntegrationsMinIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MIN_INTEGRATION_ID_DESC',
  ProductIntegrationsMinProductIdAsc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_ASC',
  ProductIntegrationsMinProductIdDesc = 'PRODUCT_INTEGRATIONS_MIN_PRODUCT_ID_DESC',
  ProductIntegrationsMinEnabledAsc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_ASC',
  ProductIntegrationsMinEnabledDesc = 'PRODUCT_INTEGRATIONS_MIN_ENABLED_DESC',
  ProductIntegrationsMinCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_ASC',
  ProductIntegrationsMinCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_CREATED_AT_DESC',
  ProductIntegrationsMinUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_ASC',
  ProductIntegrationsMinUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MIN_UPDATED_AT_DESC',
  ProductIntegrationsMaxIdAsc = 'PRODUCT_INTEGRATIONS_MAX_ID_ASC',
  ProductIntegrationsMaxIdDesc = 'PRODUCT_INTEGRATIONS_MAX_ID_DESC',
  ProductIntegrationsMaxIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_ASC',
  ProductIntegrationsMaxIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_MAX_INTEGRATION_ID_DESC',
  ProductIntegrationsMaxProductIdAsc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_ASC',
  ProductIntegrationsMaxProductIdDesc = 'PRODUCT_INTEGRATIONS_MAX_PRODUCT_ID_DESC',
  ProductIntegrationsMaxEnabledAsc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_ASC',
  ProductIntegrationsMaxEnabledDesc = 'PRODUCT_INTEGRATIONS_MAX_ENABLED_DESC',
  ProductIntegrationsMaxCreatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_ASC',
  ProductIntegrationsMaxCreatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_CREATED_AT_DESC',
  ProductIntegrationsMaxUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_ASC',
  ProductIntegrationsMaxUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_MAX_UPDATED_AT_DESC',
  ProductIntegrationsAverageIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_ASC',
  ProductIntegrationsAverageIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ID_DESC',
  ProductIntegrationsAverageIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_ASC',
  ProductIntegrationsAverageIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_INTEGRATION_ID_DESC',
  ProductIntegrationsAverageProductIdAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_ASC',
  ProductIntegrationsAverageProductIdDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_PRODUCT_ID_DESC',
  ProductIntegrationsAverageEnabledAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_ASC',
  ProductIntegrationsAverageEnabledDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_ENABLED_DESC',
  ProductIntegrationsAverageCreatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_ASC',
  ProductIntegrationsAverageCreatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_CREATED_AT_DESC',
  ProductIntegrationsAverageUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_ASC',
  ProductIntegrationsAverageUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_AVERAGE_UPDATED_AT_DESC',
  ProductIntegrationsStddevSampleIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_ASC',
  ProductIntegrationsStddevSampleIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ID_DESC',
  ProductIntegrationsStddevSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsStddevSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsStddevSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_ASC',
  ProductIntegrationsStddevSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_ENABLED_DESC',
  ProductIntegrationsStddevSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsStddevSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsStddevSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsStddevSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsStddevPopulationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_ASC',
  ProductIntegrationsStddevPopulationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ID_DESC',
  ProductIntegrationsStddevPopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsStddevPopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsStddevPopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsStddevPopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsStddevPopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_ASC',
  ProductIntegrationsStddevPopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_ENABLED_DESC',
  ProductIntegrationsStddevPopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsStddevPopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsStddevPopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsStddevPopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProductIntegrationsVarianceSampleIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_ASC',
  ProductIntegrationsVarianceSampleIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ID_DESC',
  ProductIntegrationsVarianceSampleIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  ProductIntegrationsVarianceSampleIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  ProductIntegrationsVarianceSampleProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  ProductIntegrationsVarianceSampleProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  ProductIntegrationsVarianceSampleEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_ASC',
  ProductIntegrationsVarianceSampleEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_ENABLED_DESC',
  ProductIntegrationsVarianceSampleCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProductIntegrationsVarianceSampleCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProductIntegrationsVarianceSampleUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProductIntegrationsVarianceSampleUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProductIntegrationsVariancePopulationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_ASC',
  ProductIntegrationsVariancePopulationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ID_DESC',
  ProductIntegrationsVariancePopulationIntegrationIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  ProductIntegrationsVariancePopulationIntegrationIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  ProductIntegrationsVariancePopulationProductIdAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  ProductIntegrationsVariancePopulationProductIdDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  ProductIntegrationsVariancePopulationEnabledAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_ASC',
  ProductIntegrationsVariancePopulationEnabledDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_ENABLED_DESC',
  ProductIntegrationsVariancePopulationCreatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProductIntegrationsVariancePopulationCreatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProductIntegrationsVariancePopulationUpdatedAtAsc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProductIntegrationsVariancePopulationUpdatedAtDesc = 'PRODUCT_INTEGRATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FieldConfigsCountAsc = 'FIELD_CONFIGS_COUNT_ASC',
  FieldConfigsCountDesc = 'FIELD_CONFIGS_COUNT_DESC',
  FieldConfigsSumIdAsc = 'FIELD_CONFIGS_SUM_ID_ASC',
  FieldConfigsSumIdDesc = 'FIELD_CONFIGS_SUM_ID_DESC',
  FieldConfigsSumProductIdAsc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_ASC',
  FieldConfigsSumProductIdDesc = 'FIELD_CONFIGS_SUM_PRODUCT_ID_DESC',
  FieldConfigsSumChannelIdAsc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_ASC',
  FieldConfigsSumChannelIdDesc = 'FIELD_CONFIGS_SUM_CHANNEL_ID_DESC',
  FieldConfigsSumIntegrationIdAsc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_ASC',
  FieldConfigsSumIntegrationIdDesc = 'FIELD_CONFIGS_SUM_INTEGRATION_ID_DESC',
  FieldConfigsSumTypeAsc = 'FIELD_CONFIGS_SUM_TYPE_ASC',
  FieldConfigsSumTypeDesc = 'FIELD_CONFIGS_SUM_TYPE_DESC',
  FieldConfigsSumLabelAsc = 'FIELD_CONFIGS_SUM_LABEL_ASC',
  FieldConfigsSumLabelDesc = 'FIELD_CONFIGS_SUM_LABEL_DESC',
  FieldConfigsSumHelperAsc = 'FIELD_CONFIGS_SUM_HELPER_ASC',
  FieldConfigsSumHelperDesc = 'FIELD_CONFIGS_SUM_HELPER_DESC',
  FieldConfigsSumKeyAsc = 'FIELD_CONFIGS_SUM_KEY_ASC',
  FieldConfigsSumKeyDesc = 'FIELD_CONFIGS_SUM_KEY_DESC',
  FieldConfigsSumPublicAsc = 'FIELD_CONFIGS_SUM_PUBLIC_ASC',
  FieldConfigsSumPublicDesc = 'FIELD_CONFIGS_SUM_PUBLIC_DESC',
  FieldConfigsSumCreatedAtAsc = 'FIELD_CONFIGS_SUM_CREATED_AT_ASC',
  FieldConfigsSumCreatedAtDesc = 'FIELD_CONFIGS_SUM_CREATED_AT_DESC',
  FieldConfigsSumUpdatedAtAsc = 'FIELD_CONFIGS_SUM_UPDATED_AT_ASC',
  FieldConfigsSumUpdatedAtDesc = 'FIELD_CONFIGS_SUM_UPDATED_AT_DESC',
  FieldConfigsDistinctCountIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_ASC',
  FieldConfigsDistinctCountIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_ID_DESC',
  FieldConfigsDistinctCountProductIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_ASC',
  FieldConfigsDistinctCountProductIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PRODUCT_ID_DESC',
  FieldConfigsDistinctCountChannelIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_ASC',
  FieldConfigsDistinctCountChannelIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CHANNEL_ID_DESC',
  FieldConfigsDistinctCountIntegrationIdAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  FieldConfigsDistinctCountIntegrationIdDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  FieldConfigsDistinctCountTypeAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_ASC',
  FieldConfigsDistinctCountTypeDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_TYPE_DESC',
  FieldConfigsDistinctCountLabelAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_ASC',
  FieldConfigsDistinctCountLabelDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_LABEL_DESC',
  FieldConfigsDistinctCountHelperAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_ASC',
  FieldConfigsDistinctCountHelperDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_HELPER_DESC',
  FieldConfigsDistinctCountKeyAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_ASC',
  FieldConfigsDistinctCountKeyDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_KEY_DESC',
  FieldConfigsDistinctCountPublicAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_ASC',
  FieldConfigsDistinctCountPublicDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_PUBLIC_DESC',
  FieldConfigsDistinctCountCreatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_ASC',
  FieldConfigsDistinctCountCreatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_CREATED_AT_DESC',
  FieldConfigsDistinctCountUpdatedAtAsc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  FieldConfigsDistinctCountUpdatedAtDesc = 'FIELD_CONFIGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  FieldConfigsMinIdAsc = 'FIELD_CONFIGS_MIN_ID_ASC',
  FieldConfigsMinIdDesc = 'FIELD_CONFIGS_MIN_ID_DESC',
  FieldConfigsMinProductIdAsc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_ASC',
  FieldConfigsMinProductIdDesc = 'FIELD_CONFIGS_MIN_PRODUCT_ID_DESC',
  FieldConfigsMinChannelIdAsc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_ASC',
  FieldConfigsMinChannelIdDesc = 'FIELD_CONFIGS_MIN_CHANNEL_ID_DESC',
  FieldConfigsMinIntegrationIdAsc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_ASC',
  FieldConfigsMinIntegrationIdDesc = 'FIELD_CONFIGS_MIN_INTEGRATION_ID_DESC',
  FieldConfigsMinTypeAsc = 'FIELD_CONFIGS_MIN_TYPE_ASC',
  FieldConfigsMinTypeDesc = 'FIELD_CONFIGS_MIN_TYPE_DESC',
  FieldConfigsMinLabelAsc = 'FIELD_CONFIGS_MIN_LABEL_ASC',
  FieldConfigsMinLabelDesc = 'FIELD_CONFIGS_MIN_LABEL_DESC',
  FieldConfigsMinHelperAsc = 'FIELD_CONFIGS_MIN_HELPER_ASC',
  FieldConfigsMinHelperDesc = 'FIELD_CONFIGS_MIN_HELPER_DESC',
  FieldConfigsMinKeyAsc = 'FIELD_CONFIGS_MIN_KEY_ASC',
  FieldConfigsMinKeyDesc = 'FIELD_CONFIGS_MIN_KEY_DESC',
  FieldConfigsMinPublicAsc = 'FIELD_CONFIGS_MIN_PUBLIC_ASC',
  FieldConfigsMinPublicDesc = 'FIELD_CONFIGS_MIN_PUBLIC_DESC',
  FieldConfigsMinCreatedAtAsc = 'FIELD_CONFIGS_MIN_CREATED_AT_ASC',
  FieldConfigsMinCreatedAtDesc = 'FIELD_CONFIGS_MIN_CREATED_AT_DESC',
  FieldConfigsMinUpdatedAtAsc = 'FIELD_CONFIGS_MIN_UPDATED_AT_ASC',
  FieldConfigsMinUpdatedAtDesc = 'FIELD_CONFIGS_MIN_UPDATED_AT_DESC',
  FieldConfigsMaxIdAsc = 'FIELD_CONFIGS_MAX_ID_ASC',
  FieldConfigsMaxIdDesc = 'FIELD_CONFIGS_MAX_ID_DESC',
  FieldConfigsMaxProductIdAsc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_ASC',
  FieldConfigsMaxProductIdDesc = 'FIELD_CONFIGS_MAX_PRODUCT_ID_DESC',
  FieldConfigsMaxChannelIdAsc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_ASC',
  FieldConfigsMaxChannelIdDesc = 'FIELD_CONFIGS_MAX_CHANNEL_ID_DESC',
  FieldConfigsMaxIntegrationIdAsc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_ASC',
  FieldConfigsMaxIntegrationIdDesc = 'FIELD_CONFIGS_MAX_INTEGRATION_ID_DESC',
  FieldConfigsMaxTypeAsc = 'FIELD_CONFIGS_MAX_TYPE_ASC',
  FieldConfigsMaxTypeDesc = 'FIELD_CONFIGS_MAX_TYPE_DESC',
  FieldConfigsMaxLabelAsc = 'FIELD_CONFIGS_MAX_LABEL_ASC',
  FieldConfigsMaxLabelDesc = 'FIELD_CONFIGS_MAX_LABEL_DESC',
  FieldConfigsMaxHelperAsc = 'FIELD_CONFIGS_MAX_HELPER_ASC',
  FieldConfigsMaxHelperDesc = 'FIELD_CONFIGS_MAX_HELPER_DESC',
  FieldConfigsMaxKeyAsc = 'FIELD_CONFIGS_MAX_KEY_ASC',
  FieldConfigsMaxKeyDesc = 'FIELD_CONFIGS_MAX_KEY_DESC',
  FieldConfigsMaxPublicAsc = 'FIELD_CONFIGS_MAX_PUBLIC_ASC',
  FieldConfigsMaxPublicDesc = 'FIELD_CONFIGS_MAX_PUBLIC_DESC',
  FieldConfigsMaxCreatedAtAsc = 'FIELD_CONFIGS_MAX_CREATED_AT_ASC',
  FieldConfigsMaxCreatedAtDesc = 'FIELD_CONFIGS_MAX_CREATED_AT_DESC',
  FieldConfigsMaxUpdatedAtAsc = 'FIELD_CONFIGS_MAX_UPDATED_AT_ASC',
  FieldConfigsMaxUpdatedAtDesc = 'FIELD_CONFIGS_MAX_UPDATED_AT_DESC',
  FieldConfigsAverageIdAsc = 'FIELD_CONFIGS_AVERAGE_ID_ASC',
  FieldConfigsAverageIdDesc = 'FIELD_CONFIGS_AVERAGE_ID_DESC',
  FieldConfigsAverageProductIdAsc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_ASC',
  FieldConfigsAverageProductIdDesc = 'FIELD_CONFIGS_AVERAGE_PRODUCT_ID_DESC',
  FieldConfigsAverageChannelIdAsc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_ASC',
  FieldConfigsAverageChannelIdDesc = 'FIELD_CONFIGS_AVERAGE_CHANNEL_ID_DESC',
  FieldConfigsAverageIntegrationIdAsc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_ASC',
  FieldConfigsAverageIntegrationIdDesc = 'FIELD_CONFIGS_AVERAGE_INTEGRATION_ID_DESC',
  FieldConfigsAverageTypeAsc = 'FIELD_CONFIGS_AVERAGE_TYPE_ASC',
  FieldConfigsAverageTypeDesc = 'FIELD_CONFIGS_AVERAGE_TYPE_DESC',
  FieldConfigsAverageLabelAsc = 'FIELD_CONFIGS_AVERAGE_LABEL_ASC',
  FieldConfigsAverageLabelDesc = 'FIELD_CONFIGS_AVERAGE_LABEL_DESC',
  FieldConfigsAverageHelperAsc = 'FIELD_CONFIGS_AVERAGE_HELPER_ASC',
  FieldConfigsAverageHelperDesc = 'FIELD_CONFIGS_AVERAGE_HELPER_DESC',
  FieldConfigsAverageKeyAsc = 'FIELD_CONFIGS_AVERAGE_KEY_ASC',
  FieldConfigsAverageKeyDesc = 'FIELD_CONFIGS_AVERAGE_KEY_DESC',
  FieldConfigsAveragePublicAsc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_ASC',
  FieldConfigsAveragePublicDesc = 'FIELD_CONFIGS_AVERAGE_PUBLIC_DESC',
  FieldConfigsAverageCreatedAtAsc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_ASC',
  FieldConfigsAverageCreatedAtDesc = 'FIELD_CONFIGS_AVERAGE_CREATED_AT_DESC',
  FieldConfigsAverageUpdatedAtAsc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_ASC',
  FieldConfigsAverageUpdatedAtDesc = 'FIELD_CONFIGS_AVERAGE_UPDATED_AT_DESC',
  FieldConfigsStddevSampleIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_ASC',
  FieldConfigsStddevSampleIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_ID_DESC',
  FieldConfigsStddevSampleProductIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsStddevSampleProductIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsStddevSampleChannelIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsStddevSampleChannelIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsStddevSampleIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsStddevSampleIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsStddevSampleTypeAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_ASC',
  FieldConfigsStddevSampleTypeDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_TYPE_DESC',
  FieldConfigsStddevSampleLabelAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_ASC',
  FieldConfigsStddevSampleLabelDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_LABEL_DESC',
  FieldConfigsStddevSampleHelperAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_ASC',
  FieldConfigsStddevSampleHelperDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_HELPER_DESC',
  FieldConfigsStddevSampleKeyAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_ASC',
  FieldConfigsStddevSampleKeyDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_KEY_DESC',
  FieldConfigsStddevSamplePublicAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_ASC',
  FieldConfigsStddevSamplePublicDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_PUBLIC_DESC',
  FieldConfigsStddevSampleCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  FieldConfigsStddevSampleCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  FieldConfigsStddevSampleUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsStddevSampleUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsStddevPopulationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_ASC',
  FieldConfigsStddevPopulationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_ID_DESC',
  FieldConfigsStddevPopulationProductIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsStddevPopulationProductIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsStddevPopulationChannelIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsStddevPopulationChannelIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsStddevPopulationIntegrationIdAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsStddevPopulationIntegrationIdDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsStddevPopulationTypeAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_ASC',
  FieldConfigsStddevPopulationTypeDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_TYPE_DESC',
  FieldConfigsStddevPopulationLabelAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_ASC',
  FieldConfigsStddevPopulationLabelDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_LABEL_DESC',
  FieldConfigsStddevPopulationHelperAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_ASC',
  FieldConfigsStddevPopulationHelperDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_HELPER_DESC',
  FieldConfigsStddevPopulationKeyAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_ASC',
  FieldConfigsStddevPopulationKeyDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_KEY_DESC',
  FieldConfigsStddevPopulationPublicAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_ASC',
  FieldConfigsStddevPopulationPublicDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_PUBLIC_DESC',
  FieldConfigsStddevPopulationCreatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_ASC',
  FieldConfigsStddevPopulationCreatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_CREATED_AT_DESC',
  FieldConfigsStddevPopulationUpdatedAtAsc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  FieldConfigsStddevPopulationUpdatedAtDesc = 'FIELD_CONFIGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  FieldConfigsVarianceSampleIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_ASC',
  FieldConfigsVarianceSampleIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_ID_DESC',
  FieldConfigsVarianceSampleProductIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_ASC',
  FieldConfigsVarianceSampleProductIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PRODUCT_ID_DESC',
  FieldConfigsVarianceSampleChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_ASC',
  FieldConfigsVarianceSampleChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CHANNEL_ID_DESC',
  FieldConfigsVarianceSampleIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  FieldConfigsVarianceSampleIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  FieldConfigsVarianceSampleTypeAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_ASC',
  FieldConfigsVarianceSampleTypeDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_TYPE_DESC',
  FieldConfigsVarianceSampleLabelAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_ASC',
  FieldConfigsVarianceSampleLabelDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_LABEL_DESC',
  FieldConfigsVarianceSampleHelperAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_ASC',
  FieldConfigsVarianceSampleHelperDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_HELPER_DESC',
  FieldConfigsVarianceSampleKeyAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_ASC',
  FieldConfigsVarianceSampleKeyDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_KEY_DESC',
  FieldConfigsVarianceSamplePublicAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_ASC',
  FieldConfigsVarianceSamplePublicDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_PUBLIC_DESC',
  FieldConfigsVarianceSampleCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FieldConfigsVarianceSampleCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FieldConfigsVarianceSampleUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FieldConfigsVarianceSampleUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FieldConfigsVariancePopulationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_ASC',
  FieldConfigsVariancePopulationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_ID_DESC',
  FieldConfigsVariancePopulationProductIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_ASC',
  FieldConfigsVariancePopulationProductIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PRODUCT_ID_DESC',
  FieldConfigsVariancePopulationChannelIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_ASC',
  FieldConfigsVariancePopulationChannelIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CHANNEL_ID_DESC',
  FieldConfigsVariancePopulationIntegrationIdAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  FieldConfigsVariancePopulationIntegrationIdDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  FieldConfigsVariancePopulationTypeAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_ASC',
  FieldConfigsVariancePopulationTypeDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_TYPE_DESC',
  FieldConfigsVariancePopulationLabelAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_ASC',
  FieldConfigsVariancePopulationLabelDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_LABEL_DESC',
  FieldConfigsVariancePopulationHelperAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_ASC',
  FieldConfigsVariancePopulationHelperDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_HELPER_DESC',
  FieldConfigsVariancePopulationKeyAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_ASC',
  FieldConfigsVariancePopulationKeyDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_KEY_DESC',
  FieldConfigsVariancePopulationPublicAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_ASC',
  FieldConfigsVariancePopulationPublicDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_PUBLIC_DESC',
  FieldConfigsVariancePopulationCreatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  FieldConfigsVariancePopulationCreatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  FieldConfigsVariancePopulationUpdatedAtAsc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FieldConfigsVariancePopulationUpdatedAtDesc = 'FIELD_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `Analytic`. */
  analytics?: Maybe<AnalyticsConnection>;
  /** Reads and enables pagination through a set of `AnalyticsCta`. */
  analyticsCtas?: Maybe<AnalyticsCtasConnection>;
  /** Reads and enables pagination through a set of `AnalyticsCtaSummary`. */
  analyticsCtaSummaries?: Maybe<AnalyticsCtaSummariesConnection>;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments?: Maybe<AppointmentsConnection>;
  /** Reads and enables pagination through a set of `AvaApp`. */
  avaApps?: Maybe<AvaAppsConnection>;
  /** Reads and enables pagination through a set of `Channel`. */
  channels?: Maybe<ChannelsConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  companies?: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `CompanyAnalytic`. */
  companyAnalytics?: Maybe<CompanyAnalyticsConnection>;
  /** Reads and enables pagination through a set of `CompanyChannel`. */
  companyChannels?: Maybe<CompanyChannelsConnection>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  companyCtas?: Maybe<CompanyCtasConnection>;
  /** Reads and enables pagination through a set of `CompanyDomain`. */
  companyDomains?: Maybe<CompanyDomainsConnection>;
  /** Reads and enables pagination through a set of `CompanyDomainsApproved`. */
  companyDomainsApproveds?: Maybe<CompanyDomainsApprovedsConnection>;
  /** Reads and enables pagination through a set of `CompanyDomainsPath`. */
  companyDomainsPaths?: Maybe<CompanyDomainsPathsConnection>;
  /** Reads and enables pagination through a set of `CompanyGroup`. */
  companyGroups?: Maybe<CompanyGroupsConnection>;
  /** Reads and enables pagination through a set of `CompanyIntegration`. */
  companyIntegrations?: Maybe<CompanyIntegrationsConnection>;
  /** Reads and enables pagination through a set of `CompanyLocation`. */
  companyLocations?: Maybe<CompanyLocationsConnection>;
  /** Reads and enables pagination through a set of `CompanyProduct`. */
  companyProducts?: Maybe<CompanyProductsConnection>;
  /** Reads and enables pagination through a set of `CompanyProductIntegration`. */
  companyProductIntegrations?: Maybe<CompanyProductIntegrationsConnection>;
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets?: Maybe<CompanyWidgetsConnection>;
  /** Reads and enables pagination through a set of `CreditApp`. */
  creditApps?: Maybe<CreditAppsConnection>;
  /** Reads and enables pagination through a set of `CreditBureau`. */
  creditBureaus?: Maybe<CreditBureausConnection>;
  /** Reads and enables pagination through a set of `CtaAsset`. */
  ctaAssets?: Maybe<CtaAssetsConnection>;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets?: Maybe<CtaWidgetsConnection>;
  /** Reads and enables pagination through a set of `Cta`. */
  ctas?: Maybe<CtasConnection>;
  /** Reads and enables pagination through a set of `DeliveryMethod`. */
  deliveryMethods?: Maybe<DeliveryMethodsConnection>;
  /** Reads and enables pagination through a set of `EmailTemplate`. */
  emailTemplates?: Maybe<EmailTemplatesConnection>;
  /** Reads and enables pagination through a set of `EquifaxLender`. */
  equifaxLenders?: Maybe<EquifaxLendersConnection>;
  /** Reads and enables pagination through a set of `Event`. */
  events?: Maybe<EventsConnection>;
  /** Reads and enables pagination through a set of `Field`. */
  fields?: Maybe<FieldsConnection>;
  /** Reads and enables pagination through a set of `FieldConfig`. */
  fieldConfigs?: Maybe<FieldConfigsConnection>;
  /** Reads and enables pagination through a set of `FieldKey`. */
  fieldKeys?: Maybe<FieldKeysConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  groups?: Maybe<GroupsConnection>;
  /** Reads and enables pagination through a set of `IdVerification`. */
  idVerifications?: Maybe<IdVerificationsConnection>;
  /** Reads and enables pagination through a set of `IncomeVerification`. */
  incomeVerifications?: Maybe<IncomeVerificationsConnection>;
  /** Reads and enables pagination through a set of `Integration`. */
  integrations?: Maybe<IntegrationsConnection>;
  /** Reads and enables pagination through a set of `Lead`. */
  leads?: Maybe<LeadsConnection>;
  /** Reads and enables pagination through a set of `LeadCampaign`. */
  leadCampaigns?: Maybe<LeadCampaignsConnection>;
  /** Reads and enables pagination through a set of `LeadJourney`. */
  leadJourneys?: Maybe<LeadJourneysConnection>;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps?: Maybe<LenderAppsConnection>;
  /** Reads and enables pagination through a set of `LenderQuote`. */
  lenderQuotes?: Maybe<LenderQuotesConnection>;
  /** Reads and enables pagination through a set of `Option`. */
  options?: Maybe<OptionsConnection>;
  /** Reads and enables pagination through a set of `Product`. */
  products?: Maybe<ProductsConnection>;
  /** Reads and enables pagination through a set of `ProductIntegration`. */
  productIntegrations?: Maybe<ProductIntegrationsConnection>;
  /** Reads and enables pagination through a set of `Review`. */
  reviews?: Maybe<ReviewsConnection>;
  /** Reads and enables pagination through a set of `TradeApp`. */
  tradeApps?: Maybe<TradeAppsConnection>;
  /** Reads and enables pagination through a set of `UserActivity`. */
  userActivities?: Maybe<UserActivitiesConnection>;
  /** Reads and enables pagination through a set of `VehicleListing`. */
  vehicleListings?: Maybe<VehicleListingsConnection>;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings?: Maybe<WidgetSettingsConnection>;
  /** Reads and enables pagination through a set of `Widget`. */
  widgets?: Maybe<WidgetsConnection>;
  analytic?: Maybe<Analytic>;
  analyticsCta?: Maybe<AnalyticsCta>;
  appointment?: Maybe<Appointment>;
  avaApp?: Maybe<AvaApp>;
  avaAppByLeadId?: Maybe<AvaApp>;
  channel?: Maybe<Channel>;
  company?: Maybe<Company>;
  companyByName?: Maybe<Company>;
  companyByExternalId?: Maybe<Company>;
  companyByPrimaryGroup?: Maybe<Company>;
  companyAnalytic?: Maybe<CompanyAnalytic>;
  companyChannel?: Maybe<CompanyChannel>;
  companyCta?: Maybe<CompanyCta>;
  companyDomain?: Maybe<CompanyDomain>;
  companyDomainByDomain?: Maybe<CompanyDomain>;
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  companyDomainsPathByDomainIdAndPath?: Maybe<CompanyDomainsPath>;
  companyGroup?: Maybe<CompanyGroup>;
  companyIntegration?: Maybe<CompanyIntegration>;
  companyLocation?: Maybe<CompanyLocation>;
  companyProduct?: Maybe<CompanyProduct>;
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  companyWidget?: Maybe<CompanyWidget>;
  creditApp?: Maybe<CreditApp>;
  creditAppByLeadId?: Maybe<CreditApp>;
  creditBureau?: Maybe<CreditBureau>;
  ctaAsset?: Maybe<CtaAsset>;
  ctaAssetByGroupIdAndName?: Maybe<CtaAsset>;
  ctaWidget?: Maybe<CtaWidget>;
  cta?: Maybe<Cta>;
  ctaByProductAndTypeAndVersion?: Maybe<Cta>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  emailTemplate?: Maybe<EmailTemplate>;
  equifaxLender?: Maybe<EquifaxLender>;
  equifaxLenderByEquifaxIdAndName?: Maybe<EquifaxLender>;
  event?: Maybe<Event>;
  field?: Maybe<Field>;
  fieldByCompanyProductIdAndConfig?: Maybe<Field>;
  fieldByCompanyChannelIdAndConfig?: Maybe<Field>;
  fieldByCompanyIntegrationIdAndConfig?: Maybe<Field>;
  fieldConfig?: Maybe<FieldConfig>;
  fieldKey?: Maybe<FieldKey>;
  fieldKeyByKey?: Maybe<FieldKey>;
  group?: Maybe<Group>;
  groupByCognitoName?: Maybe<Group>;
  idVerification?: Maybe<IdVerification>;
  idVerificationByLeadId?: Maybe<IdVerification>;
  incomeVerification?: Maybe<IncomeVerification>;
  incomeVerificationByLeadId?: Maybe<IncomeVerification>;
  integration?: Maybe<Integration>;
  lead?: Maybe<Lead>;
  leadCampaign?: Maybe<LeadCampaign>;
  leadCampaignByLeadId?: Maybe<LeadCampaign>;
  lenderApp?: Maybe<LenderApp>;
  lenderQuote?: Maybe<LenderQuote>;
  option?: Maybe<Option>;
  product?: Maybe<Product>;
  productByName?: Maybe<Product>;
  productIntegration?: Maybe<ProductIntegration>;
  review?: Maybe<Review>;
  tradeApp?: Maybe<TradeApp>;
  userActivity?: Maybe<UserActivity>;
  vehicleListing?: Maybe<VehicleListing>;
  widgetSetting?: Maybe<WidgetSetting>;
  widget?: Maybe<Widget>;
  widgetByVariant?: Maybe<Widget>;
  acComputeGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  acGetAdminCompanyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  acGetAdminGroupIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  acHasAccess2?: Maybe<Scalars['Boolean']>;
  acHasAdminAccess?: Maybe<Scalars['Boolean']>;
  acUtilGetAdminGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  acUtilGetNormalGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  avaAppCreditRange?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Company`. */
  companyDetails?: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  getCompanyByWidget?: Maybe<CompaniesConnection>;
  getCompanyIdsByCognitoGroups?: Maybe<GetCompanyIdsByCognitoGroupsRecord>;
  getCompanyIdsForUser?: Maybe<GetCompanyIdsForUserRecord>;
  /** Reads and enables pagination through a set of `CompanyCta`. */
  getCompatibleCtaOverrides?: Maybe<CompanyCtasConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  getGroupByWidget?: Maybe<GroupsConnection>;
  getKeysOfObject?: Maybe<Array<Maybe<Scalars['String']>>>;
  getMajorVersion?: Maybe<Scalars['Int']>;
  pseudoEncrypt?: Maybe<Scalars['Int']>;
  toKebabCase?: Maybe<Scalars['String']>;
  /** Reads a single `Analytic` using its globally unique `ID`. */
  analyticByNodeId?: Maybe<Analytic>;
  /** Reads a single `AnalyticsCta` using its globally unique `ID`. */
  analyticsCtaByNodeId?: Maybe<AnalyticsCta>;
  /** Reads a single `Appointment` using its globally unique `ID`. */
  appointmentByNodeId?: Maybe<Appointment>;
  /** Reads a single `AvaApp` using its globally unique `ID`. */
  avaAppByNodeId?: Maybe<AvaApp>;
  /** Reads a single `Channel` using its globally unique `ID`. */
  channelByNodeId?: Maybe<Channel>;
  /** Reads a single `Company` using its globally unique `ID`. */
  companyByNodeId?: Maybe<Company>;
  /** Reads a single `CompanyAnalytic` using its globally unique `ID`. */
  companyAnalyticByNodeId?: Maybe<CompanyAnalytic>;
  /** Reads a single `CompanyChannel` using its globally unique `ID`. */
  companyChannelByNodeId?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyCta` using its globally unique `ID`. */
  companyCtaByNodeId?: Maybe<CompanyCta>;
  /** Reads a single `CompanyDomain` using its globally unique `ID`. */
  companyDomainByNodeId?: Maybe<CompanyDomain>;
  /** Reads a single `CompanyDomainsApproved` using its globally unique `ID`. */
  companyDomainsApprovedByNodeId?: Maybe<CompanyDomainsApproved>;
  /** Reads a single `CompanyDomainsPath` using its globally unique `ID`. */
  companyDomainsPathByNodeId?: Maybe<CompanyDomainsPath>;
  /** Reads a single `CompanyGroup` using its globally unique `ID`. */
  companyGroupByNodeId?: Maybe<CompanyGroup>;
  /** Reads a single `CompanyIntegration` using its globally unique `ID`. */
  companyIntegrationByNodeId?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyLocation` using its globally unique `ID`. */
  companyLocationByNodeId?: Maybe<CompanyLocation>;
  /** Reads a single `CompanyProduct` using its globally unique `ID`. */
  companyProductByNodeId?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyProductIntegration` using its globally unique `ID`. */
  companyProductIntegrationByNodeId?: Maybe<CompanyProductIntegration>;
  /** Reads a single `CompanyWidget` using its globally unique `ID`. */
  companyWidgetByNodeId?: Maybe<CompanyWidget>;
  /** Reads a single `CreditApp` using its globally unique `ID`. */
  creditAppByNodeId?: Maybe<CreditApp>;
  /** Reads a single `CreditBureau` using its globally unique `ID`. */
  creditBureauByNodeId?: Maybe<CreditBureau>;
  /** Reads a single `CtaAsset` using its globally unique `ID`. */
  ctaAssetByNodeId?: Maybe<CtaAsset>;
  /** Reads a single `CtaWidget` using its globally unique `ID`. */
  ctaWidgetByNodeId?: Maybe<CtaWidget>;
  /** Reads a single `Cta` using its globally unique `ID`. */
  ctaByNodeId?: Maybe<Cta>;
  /** Reads a single `DeliveryMethod` using its globally unique `ID`. */
  deliveryMethodByNodeId?: Maybe<DeliveryMethod>;
  /** Reads a single `EmailTemplate` using its globally unique `ID`. */
  emailTemplateByNodeId?: Maybe<EmailTemplate>;
  /** Reads a single `EquifaxLender` using its globally unique `ID`. */
  equifaxLenderByNodeId?: Maybe<EquifaxLender>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  /** Reads a single `Field` using its globally unique `ID`. */
  fieldByNodeId?: Maybe<Field>;
  /** Reads a single `FieldConfig` using its globally unique `ID`. */
  fieldConfigByNodeId?: Maybe<FieldConfig>;
  /** Reads a single `FieldKey` using its globally unique `ID`. */
  fieldKeyByNodeId?: Maybe<FieldKey>;
  /** Reads a single `Group` using its globally unique `ID`. */
  groupByNodeId?: Maybe<Group>;
  /** Reads a single `IdVerification` using its globally unique `ID`. */
  idVerificationByNodeId?: Maybe<IdVerification>;
  /** Reads a single `IncomeVerification` using its globally unique `ID`. */
  incomeVerificationByNodeId?: Maybe<IncomeVerification>;
  /** Reads a single `Integration` using its globally unique `ID`. */
  integrationByNodeId?: Maybe<Integration>;
  /** Reads a single `Lead` using its globally unique `ID`. */
  leadByNodeId?: Maybe<Lead>;
  /** Reads a single `LeadCampaign` using its globally unique `ID`. */
  leadCampaignByNodeId?: Maybe<LeadCampaign>;
  /** Reads a single `LenderApp` using its globally unique `ID`. */
  lenderAppByNodeId?: Maybe<LenderApp>;
  /** Reads a single `LenderQuote` using its globally unique `ID`. */
  lenderQuoteByNodeId?: Maybe<LenderQuote>;
  /** Reads a single `Option` using its globally unique `ID`. */
  optionByNodeId?: Maybe<Option>;
  /** Reads a single `Product` using its globally unique `ID`. */
  productByNodeId?: Maybe<Product>;
  /** Reads a single `ProductIntegration` using its globally unique `ID`. */
  productIntegrationByNodeId?: Maybe<ProductIntegration>;
  /** Reads a single `Review` using its globally unique `ID`. */
  reviewByNodeId?: Maybe<Review>;
  /** Reads a single `TradeApp` using its globally unique `ID`. */
  tradeAppByNodeId?: Maybe<TradeApp>;
  /** Reads a single `UserActivity` using its globally unique `ID`. */
  userActivityByNodeId?: Maybe<UserActivity>;
  /** Reads a single `VehicleListing` using its globally unique `ID`. */
  vehicleListingByNodeId?: Maybe<VehicleListing>;
  /** Reads a single `WidgetSetting` using its globally unique `ID`. */
  widgetSettingByNodeId?: Maybe<WidgetSetting>;
  /** Reads a single `Widget` using its globally unique `ID`. */
  widgetByNodeId?: Maybe<Widget>;
  /** Sign in with username and password */
  authSignIn: AuthPayload;
  /** Generate new tokens with refresh token in cookie */
  authRefresh: AuthPayload;
  /** Respond to an auth challenge */
  authChallenge: AuthPayload;
  /** Sign out, clear tokens, cookie */
  authSignOut: AuthSignOutResult;
  validateCreditAppApplication: ValidateCreditAppApplicationResponse;
  getCreditAppCreditors: Array<GetCreditAppCreditorsResponse>;
  getCreditAppApplication?: Maybe<CreditAppApplication>;
  creditConsentStatus: CreditConsentStatus;
  currentUserPermissions?: Maybe<UserPermissions>;
  listUsers?: Maybe<GroupMemberships>;
  /** Search vehicle to trade-in */
  tradeAppSearch?: Maybe<TradeAppSearchResults>;
  /** Get the trade-in value of the specified vehicle. */
  tradeAppGetTradeValue?: Maybe<TradeAppTradeValue>;
  /** Get the trade-in value of the specified vehicle. */
  tradeAppGetPrices: TradeAppPayload;
  /** Get supported model years. */
  tradeAppGetSupportedYears: Array<Scalars['Int']>;
  /** Get all model years. */
  tradeAppGetAllYears?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Get all vehicle makes id supported in a model year. */
  tradeAppGetSupportedMakes: Array<TradeAppYearMakes>;
  /** Get all vehicle makes. */
  tradeAppGetAllMakes?: Maybe<Array<Maybe<TradeAppMake>>>;
  /** Get all vehicle models from the specified make. */
  tradeAppGetAllModels?: Maybe<Array<Maybe<TradeAppModel>>>;
  /** Get all trims or variant of the specified model name and model year. */
  tradeAppGetAllTrims?: Maybe<Array<Maybe<TradeAppTrim>>>;
  /** Get information about the vehicle. */
  tradeAppGetVehicleInfo?: Maybe<TradeAppVehicleInfo>;
  /** Get vehicles by VIN . */
  tradeAppGetVehiclesByVIN: Array<TradeAppVehicleInfo>;
  /** CBB API */
  vehicleLookup: VehicleLookupResponse;
  /** Get a vehicle configuration by chrome style Id. */
  getAutoConfiguration?: Maybe<AutoConfiguration>;
  /** Get a vehicle images by chrome style Id. */
  getAutoImages?: Maybe<AutoImages>;
  getUnreadLeadCountPerProduct: UnreadLeadCountResponse;
  getWidgetDetails: WidgetDetailsResponse;
  getLeadInfo?: Maybe<LeadInfoResponse>;
  getCtaSpecs: CtaSpecsResponse;
  getCtaCatalog: CtaCatalogResults;
  getCompanyCtaAnalytics?: Maybe<CtaAnalyticsResponse>;
  getGlobalCtaAnalytics?: Maybe<CtaAnalyticsResponse>;
  idVerificationTemplate?: Maybe<IdVerificationTemplateResponse>;
  retrieveIncomeVerification?: Maybe<RetrieveIncomeVerificationResponse>;
  getReviewConfigForLead?: Maybe<ReviewConfigForLeadResponse>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
  condition?: Maybe<AnalyticCondition>;
  filter?: Maybe<AnalyticFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
  condition?: Maybe<AnalyticsCtaCondition>;
  filter?: Maybe<AnalyticsCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaSummariesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AnalyticsCtaSummariesOrderBy>>;
  condition?: Maybe<AnalyticsCtaSummaryCondition>;
  filter?: Maybe<AnalyticsCtaSummaryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
  condition?: Maybe<AvaAppCondition>;
  filter?: Maybe<AvaAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
  condition?: Maybe<ChannelCondition>;
  filter?: Maybe<ChannelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
  condition?: Maybe<CompanyCondition>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
  condition?: Maybe<CompanyAnalyticCondition>;
  filter?: Maybe<CompanyAnalyticFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
  condition?: Maybe<CompanyChannelCondition>;
  filter?: Maybe<CompanyChannelFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
  condition?: Maybe<CompanyCtaCondition>;
  filter?: Maybe<CompanyCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
  condition?: Maybe<CompanyDomainCondition>;
  filter?: Maybe<CompanyDomainFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
  condition?: Maybe<CompanyDomainsApprovedCondition>;
  filter?: Maybe<CompanyDomainsApprovedFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
  condition?: Maybe<CompanyDomainsPathCondition>;
  filter?: Maybe<CompanyDomainsPathFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
  condition?: Maybe<CompanyGroupCondition>;
  filter?: Maybe<CompanyGroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
  condition?: Maybe<CompanyIntegrationCondition>;
  filter?: Maybe<CompanyIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
  condition?: Maybe<CompanyLocationCondition>;
  filter?: Maybe<CompanyLocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
  condition?: Maybe<CompanyProductCondition>;
  filter?: Maybe<CompanyProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
  condition?: Maybe<CompanyProductIntegrationCondition>;
  filter?: Maybe<CompanyProductIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditAppsOrderBy>>;
  condition?: Maybe<CreditAppCondition>;
  filter?: Maybe<CreditAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureausArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
  condition?: Maybe<CreditBureauCondition>;
  filter?: Maybe<CreditBureauFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaAssetsOrderBy>>;
  condition?: Maybe<CtaAssetCondition>;
  filter?: Maybe<CtaAssetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCtasArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
  condition?: Maybe<DeliveryMethodCondition>;
  filter?: Maybe<DeliveryMethodFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
  condition?: Maybe<EmailTemplateCondition>;
  filter?: Maybe<EmailTemplateFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLendersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
  condition?: Maybe<EquifaxLenderCondition>;
  filter?: Maybe<EquifaxLenderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EventsOrderBy>>;
  condition?: Maybe<EventCondition>;
  filter?: Maybe<EventFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldsOrderBy>>;
  condition?: Maybe<FieldCondition>;
  filter?: Maybe<FieldFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
  condition?: Maybe<FieldConfigCondition>;
  filter?: Maybe<FieldConfigFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
  condition?: Maybe<FieldKeyCondition>;
  filter?: Maybe<FieldKeyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
  condition?: Maybe<IdVerificationCondition>;
  filter?: Maybe<IdVerificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
  condition?: Maybe<IncomeVerificationCondition>;
  filter?: Maybe<IncomeVerificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
  condition?: Maybe<IntegrationCondition>;
  filter?: Maybe<IntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
  condition?: Maybe<LeadCampaignCondition>;
  filter?: Maybe<LeadCampaignFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadJourneysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadJourneysOrderBy>>;
  condition?: Maybe<LeadJourneyCondition>;
  filter?: Maybe<LeadJourneyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
  condition?: Maybe<LenderQuoteCondition>;
  filter?: Maybe<LenderQuoteFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<OptionsOrderBy>>;
  condition?: Maybe<OptionCondition>;
  filter?: Maybe<OptionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductsOrderBy>>;
  condition?: Maybe<ProductCondition>;
  filter?: Maybe<ProductFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
  condition?: Maybe<ProductIntegrationCondition>;
  filter?: Maybe<ProductIntegrationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
  condition?: Maybe<ReviewCondition>;
  filter?: Maybe<ReviewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
  condition?: Maybe<TradeAppCondition>;
  filter?: Maybe<TradeAppFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserActivitiesOrderBy>>;
  condition?: Maybe<UserActivityCondition>;
  filter?: Maybe<UserActivityFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
  condition?: Maybe<VehicleListingCondition>;
  filter?: Maybe<VehicleListingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
  condition?: Maybe<WidgetCondition>;
  filter?: Maybe<WidgetFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaArgs = {
  eventId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByExternalIdArgs = {
  externalId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByPrimaryGroupArgs = {
  primaryGroup: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainByDomainArgs = {
  domain: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedArgs = {
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathByDomainIdAndPathArgs = {
  domainId: Scalars['Int'];
  path: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupArgs = {
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetArgs = {
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditAppByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureauArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetByGroupIdAndNameArgs = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaByProductAndTypeAndVersionArgs = {
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderByEquifaxIdAndNameArgs = {
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyProductIdAndConfigArgs = {
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyChannelIdAndConfigArgs = {
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByCompanyIntegrationIdAndConfigArgs = {
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyByKeyArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByCognitoNameArgs = {
  cognitoName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignByLeadIdArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuoteArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByNameArgs = {
  name: ProductType;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewArgs = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivityArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetByVariantArgs = {
  variant: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAcComputeGroupsArgs = {
  groupList?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAcHasAccess2Args = {
  recordCompanyId?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAcHasAdminAccessArgs = {
  leadId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppCreditRangeArgs = {
  score?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDetailsArgs = {
  getCompanyId?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyByWidgetArgs = {
  widget?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyIdsByCognitoGroupsArgs = {
  adminGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutocorpAdmin?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyIdsForUserArgs = {
  isAutocorpAdmin?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompatibleCtaOverridesArgs = {
  inputCta: Scalars['String'];
  inputGroup?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<CompanyCtaFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGroupByWidgetArgs = {
  widget?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GroupFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetKeysOfObjectArgs = {
  obj: Scalars['JSON'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetMajorVersionArgs = {
  version?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPseudoEncryptArgs = {
  value: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryToKebabCaseArgs = {
  tValue?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppointmentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvaAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChannelByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyAnalyticByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyChannelByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsApprovedByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyDomainsPathByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyLocationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyProductIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditBureauByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaAssetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCtaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryMethodByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquifaxLenderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldConfigByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFieldKeyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeVerificationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLeadCampaignByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLenderQuoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProductIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReviewByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleListingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetSettingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWidgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthSignInArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthChallengeArgs = {
  session: Scalars['String'];
  challenge: AuthChallengeResponse;
};


/** The root query type which gives access points into the data universe. */
export type QueryValidateCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCreditAppCreditorsArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCreditAppApplicationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCreditConsentStatusArgs = {
  accessToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryListUsersArgs = {
  groupId?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppSearchArgs = {
  phrase: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetTradeValueArgs = {
  vehicleId: Scalars['String'];
  mileage: Scalars['Int'];
  condition: TradeAppVehicleCondition;
  location?: Maybe<TradeAppLocation>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetPricesArgs = {
  vehicleId: Scalars['String'];
  mileage: Scalars['Int'];
  condition: TradeAppVehicleCondition;
  location?: Maybe<TradeAppLocation>;
  provider?: Maybe<TradeProvider>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetSupportedMakesArgs = {
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllMakesArgs = {
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllModelsArgs = {
  year?: Maybe<Scalars['Int']>;
  makeId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetAllTrimsArgs = {
  year: Scalars['Int'];
  modelId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetVehicleInfoArgs = {
  vehicleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTradeAppGetVehiclesByVinArgs = {
  vin: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVehicleLookupArgs = {
  widgetId: Scalars['String'];
  keyword: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAutoConfigurationArgs = {
  styleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAutoImagesArgs = {
  styleId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetUnreadLeadCountPerProductArgs = {
  companyId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetWidgetDetailsArgs = {
  widgetId: Scalars['String'];
  ctaId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLeadInfoArgs = {
  accessToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCtaSpecsArgs = {
  input: CtaSpecsInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCtaCatalogArgs = {
  widgetId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCompanyCtaAnalyticsArgs = {
  filter?: Maybe<CtaAnalyticsFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGlobalCtaAnalyticsArgs = {
  filter?: Maybe<CtaAnalyticsFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIdVerificationTemplateArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRetrieveIncomeVerificationArgs = {
  leadId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetReviewConfigForLeadArgs = {
  leadId: Scalars['UUID'];
};

export type RemoveUserInput = {
  username: Scalars['String'];
};

export type RenameCompanyInput = {
  companyId: Scalars['Int'];
  newName: Scalars['String'];
};

export type RenameCompanyResponse = {
  success: Scalars['Boolean'];
  oldName: Scalars['String'];
  newName: Scalars['String'];
  oldCognitoName: Scalars['String'];
  newCognitoName: Scalars['String'];
  primaryGroupId: Scalars['Int'];
  usersMoved: Array<UserDetails>;
};

export type RequestAccessResponse = {
  success: Scalars['Boolean'];
  topic: Scalars['String'];
  requestedAccess?: Maybe<Array<CompanyRequestedAccess>>;
};

export type RequestIdVerificationInput = {
  leadId: Scalars['UUID'];
  messageOverride?: Maybe<Scalars['String']>;
};

export type RequestIdVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use initiateIdVerification from ava-mobile instead, this will be removed after 9th Feb 2023 */
  firstStep?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use initiateIdVerification from ava-mobile instead, this will be removed after 9th Feb 2023 */
  clientToken?: Maybe<Scalars['String']>;
};

export type RequestIncomeVerificationInput = {
  leadId: Scalars['UUID'];
  messageOverride?: Maybe<Scalars['String']>;
};

export type RequestIncomeVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  verificationUrl?: Maybe<Scalars['String']>;
};

export type RetrieveIdVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  data?: Maybe<TransactionResponse>;
};

export type RetrieveIncomeVerificationResponse = {
  success?: Maybe<Scalars['Boolean']>;
  incomeVerifiedAt?: Maybe<Scalars['Date']>;
};

export type Review = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  companyId: Scalars['Int'];
  provider: ReviewProvider;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
};

export type ReviewAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ReviewSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReviewDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ReviewMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ReviewMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ReviewAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ReviewStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ReviewStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ReviewVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ReviewVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `Review` object types. */
export type ReviewAggregatesFilter = {
  /** A filter that must pass for the relevant `Review` object to be included within the aggregate. */
  filter?: Maybe<ReviewFilter>;
  /** Sum aggregate over matching `Review` objects. */
  sum?: Maybe<ReviewSumAggregateFilter>;
  /** Distinct count aggregate over matching `Review` objects. */
  distinctCount?: Maybe<ReviewDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `Review` objects. */
  min?: Maybe<ReviewMinAggregateFilter>;
  /** Maximum aggregate over matching `Review` objects. */
  max?: Maybe<ReviewMaxAggregateFilter>;
  /** Mean average aggregate over matching `Review` objects. */
  average?: Maybe<ReviewAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Review` objects. */
  stddevSample?: Maybe<ReviewStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `Review` objects. */
  stddevPopulation?: Maybe<ReviewStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Review` objects. */
  varianceSample?: Maybe<ReviewVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `Review` objects. */
  variancePopulation?: Maybe<ReviewVariancePopulationAggregateFilter>;
};

export type ReviewAverageAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Mean average of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Review` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ReviewCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: Maybe<ReviewProvider>;
  /** Checks for equality with the object’s `authorName` field. */
  authorName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `authorPhotoUrl` field. */
  authorPhotoUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `authorUrl` field. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `language` field. */
  language?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rating` field. */
  rating?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `text` field. */
  text?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type ReviewConfigForLeadResponse = {
  companyName: Scalars['String'];
  reviewConfig: CompanyReviewConfig;
};

export type ReviewDistinctCountAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  provider?: Maybe<BigIntFilter>;
  authorName?: Maybe<BigIntFilter>;
  authorPhotoUrl?: Maybe<BigIntFilter>;
  authorUrl?: Maybe<BigIntFilter>;
  language?: Maybe<BigIntFilter>;
  rating?: Maybe<BigIntFilter>;
  text?: Maybe<BigIntFilter>;
  timestamp?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type ReviewDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorName across the matching connection */
  authorName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorPhotoUrl across the matching connection */
  authorPhotoUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of authorUrl across the matching connection */
  authorUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rating across the matching connection */
  rating?: Maybe<Scalars['BigInt']>;
  /** Distinct count of text across the matching connection */
  text?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Review` object types. All fields are combined with a logical ‘and.’ */
export type ReviewFilter = {
  /** Filter by the object’s `companyId` field. */
  companyId?: Maybe<IntFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: Maybe<ReviewProviderFilter>;
  /** Filter by the object’s `authorName` field. */
  authorName?: Maybe<StringFilter>;
  /** Filter by the object’s `authorPhotoUrl` field. */
  authorPhotoUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `authorUrl` field. */
  authorUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `language` field. */
  language?: Maybe<StringFilter>;
  /** Filter by the object’s `rating` field. */
  rating?: Maybe<IntFilter>;
  /** Filter by the object’s `text` field. */
  text?: Maybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: Maybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `company` relation. */
  company?: Maybe<CompanyFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReviewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReviewFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReviewFilter>;
};

/** An input for mutations affecting `Review` */
export type ReviewInput = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

export type ReviewMaxAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  rating?: Maybe<IntFilter>;
  timestamp?: Maybe<BigIntFilter>;
};

export type ReviewMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Maximum of rating across the matching connection */
  rating?: Maybe<Scalars['Int']>;
  /** Maximum of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

export type ReviewMinAggregateFilter = {
  companyId?: Maybe<IntFilter>;
  rating?: Maybe<IntFilter>;
  timestamp?: Maybe<BigIntFilter>;
};

export type ReviewMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId?: Maybe<Scalars['Int']>;
  /** Minimum of rating across the matching connection */
  rating?: Maybe<Scalars['Int']>;
  /** Minimum of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReviewNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `review` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ReviewNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `review` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ReviewOnReviewForReviewsCompanyIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `company` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `company` being updated. */
  patch: CompanyPatch;
};

/** The fields on `review` to look up the row to update. */
export type ReviewOnReviewForReviewsCompanyIdFkeyUsingReviewsPkeyUpdate = {
  /** An object where the defined keys will be set on the `review` being updated. */
  patch: UpdateReviewOnReviewForReviewsCompanyIdFkeyPatch;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** Represents an update to a `Review`. Fields that are set will be updated. */
export type ReviewPatch = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName?: Maybe<Scalars['String']>;
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

export enum ReviewProvider {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK'
}

/** A filter to be used against ReviewProvider fields. All fields are combined with a logical ‘and.’ */
export type ReviewProviderFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ReviewProvider>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ReviewProvider>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ReviewProvider>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ReviewProvider>;
  /** Included in the specified list. */
  in?: Maybe<Array<ReviewProvider>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ReviewProvider>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ReviewProvider>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ReviewProvider>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ReviewProvider>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ReviewProvider>;
};

/** The fields on `review` to look up the row to connect. */
export type ReviewReviewsPkeyConnect = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The fields on `review` to look up the row to delete. */
export type ReviewReviewsPkeyDelete = {
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

export type ReviewStddevPopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewStddevSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewSumAggregateFilter = {
  companyId?: Maybe<BigIntFilter>;
  rating?: Maybe<BigIntFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt'];
  /** Sum of rating across the matching connection */
  rating: Scalars['BigInt'];
  /** Sum of timestamp across the matching connection */
  timestamp: Scalars['BigFloat'];
};

export type ReviewVariancePopulationAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Population variance of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

export type ReviewVarianceSampleAggregateFilter = {
  companyId?: Maybe<BigFloatFilter>;
  rating?: Maybe<BigFloatFilter>;
  timestamp?: Maybe<BigFloatFilter>;
};

export type ReviewVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rating across the matching connection */
  rating?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigFloat']>;
};

/** The `company` to be created by this mutation. */
export type ReviewsCompanyIdFkeyCompanyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** Input for the nested mutation of `company` in the `ReviewInput` mutation. */
export type ReviewsCompanyIdFkeyInput = {
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectById?: Maybe<CompanyCompanyPkeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByName?: Maybe<CompanyCompanyNameKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByExternalId?: Maybe<CompanyCompanyExternalIdKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByPrimaryGroup?: Maybe<CompanyCompanyPrimaryGroupKeyConnect>;
  /** The primary key(s) for `company` for the far side of the relationship. */
  connectByNodeId?: Maybe<CompanyNodeIdConnect>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateById?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPkeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByName?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyNameKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByExternalId?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyExternalIdKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByPrimaryGroup?: Maybe<CompanyOnReviewForReviewsCompanyIdFkeyUsingCompanyPrimaryGroupKeyUpdate>;
  /** The primary key(s) and patch data for `company` for the far side of the relationship. */
  updateByNodeId?: Maybe<ReviewOnReviewForReviewsCompanyIdFkeyNodeIdUpdate>;
  /** A `CompanyInput` object that will be created and connected to this object. */
  create?: Maybe<ReviewsCompanyIdFkeyCompanyCreateInput>;
};

/** Input for the nested mutation of `review` in the `CompanyInput` mutation. */
export type ReviewsCompanyIdFkeyInverseInput = {
  /** Flag indicating whether all other `review` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  connectByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewReviewsPkeyConnect>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<ReviewNodeIdConnect>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  deleteByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewReviewsPkeyDelete>>;
  /** The primary key(s) for `review` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<ReviewNodeIdDelete>>;
  /** The primary key(s) and patch data for `review` for the far side of the relationship. */
  updateByCompanyIdAndAuthorUrl?: Maybe<Array<ReviewOnReviewForReviewsCompanyIdFkeyUsingReviewsPkeyUpdate>>;
  /** The primary key(s) and patch data for `review` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<CompanyOnReviewForReviewsCompanyIdFkeyNodeIdUpdate>>;
  /** A `ReviewInput` object that will be created and connected to this object. */
  create?: Maybe<Array<ReviewsCompanyIdFkeyReviewsCreateInput>>;
};

/** The `review` to be created by this mutation. */
export type ReviewsCompanyIdFkeyReviewsCreateInput = {
  companyId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ReviewProvider>;
  authorName: Scalars['String'];
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl: Scalars['String'];
  language: Scalars['String'];
  rating: Scalars['Int'];
  text: Scalars['String'];
  timestamp: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

/** A connection to a list of `Review` values. */
export type ReviewsConnection = {
  /** A list of `Review` objects. */
  nodes: Array<Review>;
  /** A list of edges which contains the `Review` and cursor to aid in pagination. */
  edges: Array<ReviewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Review` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReviewAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReviewAggregates>>;
};


/** A connection to a list of `Review` values. */
export type ReviewsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReviewsGroupBy>;
  having?: Maybe<ReviewsHavingInput>;
};

/** A `Review` edge in the connection. */
export type ReviewsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Review` at the end of the edge. */
  node: Review;
};

/** Grouping methods for `Review` for usage during aggregation. */
export enum ReviewsGroupBy {
  CompanyId = 'COMPANY_ID',
  Provider = 'PROVIDER',
  AuthorName = 'AUTHOR_NAME',
  AuthorPhotoUrl = 'AUTHOR_PHOTO_URL',
  AuthorUrl = 'AUTHOR_URL',
  Language = 'LANGUAGE',
  Rating = 'RATING',
  Text = 'TEXT',
  Timestamp = 'TIMESTAMP',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type ReviewsHavingAverageInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingDistinctCountInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Review` aggregates. */
export type ReviewsHavingInput = {
  AND?: Maybe<Array<ReviewsHavingInput>>;
  OR?: Maybe<Array<ReviewsHavingInput>>;
  sum?: Maybe<ReviewsHavingSumInput>;
  distinctCount?: Maybe<ReviewsHavingDistinctCountInput>;
  min?: Maybe<ReviewsHavingMinInput>;
  max?: Maybe<ReviewsHavingMaxInput>;
  average?: Maybe<ReviewsHavingAverageInput>;
  stddevSample?: Maybe<ReviewsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<ReviewsHavingStddevPopulationInput>;
  varianceSample?: Maybe<ReviewsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<ReviewsHavingVariancePopulationInput>;
};

export type ReviewsHavingMaxInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingMinInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingStddevPopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingStddevSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingSumInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingVariancePopulationInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ReviewsHavingVarianceSampleInput = {
  companyId?: Maybe<HavingIntFilter>;
  rating?: Maybe<HavingIntFilter>;
  timestamp?: Maybe<HavingBigintFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Review`. */
export enum ReviewsOrderBy {
  Natural = 'NATURAL',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  AuthorNameAsc = 'AUTHOR_NAME_ASC',
  AuthorNameDesc = 'AUTHOR_NAME_DESC',
  AuthorPhotoUrlAsc = 'AUTHOR_PHOTO_URL_ASC',
  AuthorPhotoUrlDesc = 'AUTHOR_PHOTO_URL_DESC',
  AuthorUrlAsc = 'AUTHOR_URL_ASC',
  AuthorUrlDesc = 'AUTHOR_URL_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type RevokeUserAccessInput = {
  username: Scalars['String'];
  groupId: Scalars['Int'];
  retainNonAdminAccess?: Maybe<Scalars['Boolean']>;
};

export type RiskElements = {
  balanceMin?: Maybe<Scalars['Float']>;
  balanceMax?: Maybe<Scalars['Float']>;
  daysWithNegativeBalance90Days?: Maybe<Scalars['Int']>;
  balanceTrend?: Maybe<Scalars['String']>;
};

export type Score = {
  value?: Maybe<Scalars['Int']>;
  reasons: Array<Scalars['String']>;
};

export type SecurityCheck = {
  enabled: Scalars['Boolean'];
  ipWhitelist?: Maybe<Array<Scalars['String']>>;
};

export type SendFeedbackResponse = {
  success: Scalars['Boolean'];
  type: FeedbackType;
  leadId: Scalars['UUID'];
};

export type SetCreditAppRetailerIdResponse = {
  retailerId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetDirectDepositAccountResponse = {
  success?: Maybe<Scalars['Boolean']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
};

export type SetIdVerificationNextStepInput = {
  leadId: Scalars['UUID'];
  nextStep?: Maybe<Scalars['String']>;
};

export type SetIdVerificationNextStepResponse = {
  success?: Maybe<Scalars['Boolean']>;
};

export type SetIncomeLoginResponse = {
  success?: Maybe<Scalars['Boolean']>;
  loginId?: Maybe<Scalars['String']>;
};

export type SetPreferredLenderOfferInput = {
  offerId: Scalars['Int'];
  lenderAppId: Scalars['Int'];
  leadId: Scalars['UUID'];
};

export type SmsUserNotificationInput = {
  leadId: Scalars['UUID'];
  tradeAppId: Scalars['Int'];
  phoneNumber: Scalars['String'];
};

export type SmsUserNotificationResponse = {
  success: Scalars['Boolean'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  smsBody?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum Status {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  NotRun = 'NOT_RUN'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

export type StripeIdentityDocumentOptions = {
  allowed_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  require_live_capture?: Maybe<Scalars['Boolean']>;
  require_matching_selfie?: Maybe<Scalars['Boolean']>;
};

export type SubmitCreditAppApplicationResponse = {
  success: Scalars['Boolean'];
};

export type SubmitLeadResponse = {
  success: Scalars['Boolean'];
  deduplicated: Scalars['Boolean'];
  leadId: Scalars['ID'];
};

export type Trade = {
  date?: Maybe<Scalars['String']>;
  creditor?: Maybe<EquifaxEntity>;
  accountNumber?: Maybe<Scalars['String']>;
  association?: Maybe<Scalars['String']>;
  updateSource?: Maybe<Scalars['String']>;
  monthsReviewed?: Maybe<Scalars['String']>;
  dateOpened?: Maybe<Scalars['String']>;
  dateLastActivityOrPayment?: Maybe<Scalars['String']>;
  portfolioType?: Maybe<LoanStatusComponent>;
  paymentRate?: Maybe<LoanStatusComponent>;
  narratives: Array<Scalars['String']>;
  fields: Array<Scalars['String']>;
  highCreditAmount?: Maybe<Scalars['Float']>;
  paymentTerm?: Maybe<Scalars['String']>;
  paymentTermAmount?: Maybe<Scalars['Float']>;
  balanceAmount?: Maybe<Scalars['Float']>;
  pastDueAmount?: Maybe<Scalars['Float']>;
  derogatoryCounters?: Maybe<DerogatoryCounters>;
  lastSlowPayment?: Maybe<Scalars['String']>;
};

export type TradeApp = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  leadId: Scalars['UUID'];
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
};

export type TradeAppAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TradeAppSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TradeAppDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TradeAppMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TradeAppMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TradeAppAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TradeAppStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TradeAppStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TradeAppVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TradeAppVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `TradeApp` object types. */
export type TradeAppAggregatesFilter = {
  /** A filter that must pass for the relevant `TradeApp` object to be included within the aggregate. */
  filter?: Maybe<TradeAppFilter>;
  /** Sum aggregate over matching `TradeApp` objects. */
  sum?: Maybe<TradeAppSumAggregateFilter>;
  /** Distinct count aggregate over matching `TradeApp` objects. */
  distinctCount?: Maybe<TradeAppDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `TradeApp` objects. */
  min?: Maybe<TradeAppMinAggregateFilter>;
  /** Maximum aggregate over matching `TradeApp` objects. */
  max?: Maybe<TradeAppMaxAggregateFilter>;
  /** Mean average aggregate over matching `TradeApp` objects. */
  average?: Maybe<TradeAppAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TradeApp` objects. */
  stddevSample?: Maybe<TradeAppStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `TradeApp` objects. */
  stddevPopulation?: Maybe<TradeAppStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TradeApp` objects. */
  varianceSample?: Maybe<TradeAppVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `TradeApp` objects. */
  variancePopulation?: Maybe<TradeAppVariancePopulationAggregateFilter>;
};

export type TradeAppAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Mean average of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Mean average of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Mean average of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `TradeApp` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TradeAppCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `leadId` field. */
  leadId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `year` field. */
  year?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `make` field. */
  make?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `model` field. */
  model?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `trim` field. */
  trim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mileage` field. */
  mileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `condition` field. */
  condition?: Maybe<VehicleCondition>;
  /** Checks for equality with the object’s `tradeInValue` field. */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `maxTradeInValue` field. */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `minTradeInValue` field. */
  minTradeInValue?: Maybe<Scalars['Float']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type TradeAppCustomInput = {
  lead: LeadInput;
  tradeApp: TradeAppInput;
};

export type TradeAppCustomType = {
  leadId?: Maybe<Scalars['UUID']>;
  tradeApp?: Maybe<PartialTradeApp>;
  error?: Maybe<TradeAppError>;
};

export type TradeAppDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  leadId?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  make?: Maybe<BigIntFilter>;
  model?: Maybe<BigIntFilter>;
  trim?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  condition?: Maybe<BigIntFilter>;
  tradeInValue?: Maybe<BigIntFilter>;
  maxTradeInValue?: Maybe<BigIntFilter>;
  minTradeInValue?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type TradeAppDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of leadId across the matching connection */
  leadId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of year across the matching connection */
  year?: Maybe<Scalars['BigInt']>;
  /** Distinct count of make across the matching connection */
  make?: Maybe<Scalars['BigInt']>;
  /** Distinct count of model across the matching connection */
  model?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trim across the matching connection */
  trim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of condition across the matching connection */
  condition?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

export type TradeAppError = {
  code?: Maybe<TradeAppErrorCode>;
  message: Scalars['String'];
};

export enum TradeAppErrorCode {
  KbbServerErrorException = 'KbbServerErrorException',
  KbbOutOfRangeException = 'KbbOutOfRangeException',
  KbbDisabledByDealer = 'KbbDisabledByDealer',
  ParameterException = 'ParameterException',
  InternalErrorException = 'InternalErrorException'
}

/** A filter to be used against `TradeApp` object types. All fields are combined with a logical ‘and.’ */
export type TradeAppFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `leadId` field. */
  leadId?: Maybe<UuidFilter>;
  /** Filter by the object’s `year` field. */
  year?: Maybe<IntFilter>;
  /** Filter by the object’s `make` field. */
  make?: Maybe<StringFilter>;
  /** Filter by the object’s `model` field. */
  model?: Maybe<StringFilter>;
  /** Filter by the object’s `trim` field. */
  trim?: Maybe<StringFilter>;
  /** Filter by the object’s `mileage` field. */
  mileage?: Maybe<IntFilter>;
  /** Filter by the object’s `condition` field. */
  condition?: Maybe<VehicleConditionFilter>;
  /** Filter by the object’s `tradeInValue` field. */
  tradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `maxTradeInValue` field. */
  maxTradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `minTradeInValue` field. */
  minTradeInValue?: Maybe<FloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lead` relation. */
  lead?: Maybe<LeadFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TradeAppFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TradeAppFilter>>;
  /** Negates the expression. */
  not?: Maybe<TradeAppFilter>;
};

/** Grouping methods for `TradeApp` for usage during aggregation. */
export enum TradeAppGroupBy {
  LeadId = 'LEAD_ID',
  Year = 'YEAR',
  Make = 'MAKE',
  Model = 'MODEL',
  Trim = 'TRIM',
  Mileage = 'MILEAGE',
  Condition = 'CONDITION',
  TradeInValue = 'TRADE_IN_VALUE',
  MaxTradeInValue = 'MAX_TRADE_IN_VALUE',
  MinTradeInValue = 'MIN_TRADE_IN_VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type TradeAppHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `TradeApp` aggregates. */
export type TradeAppHavingInput = {
  AND?: Maybe<Array<TradeAppHavingInput>>;
  OR?: Maybe<Array<TradeAppHavingInput>>;
  sum?: Maybe<TradeAppHavingSumInput>;
  distinctCount?: Maybe<TradeAppHavingDistinctCountInput>;
  min?: Maybe<TradeAppHavingMinInput>;
  max?: Maybe<TradeAppHavingMaxInput>;
  average?: Maybe<TradeAppHavingAverageInput>;
  stddevSample?: Maybe<TradeAppHavingStddevSampleInput>;
  stddevPopulation?: Maybe<TradeAppHavingStddevPopulationInput>;
  varianceSample?: Maybe<TradeAppHavingVarianceSampleInput>;
  variancePopulation?: Maybe<TradeAppHavingVariancePopulationInput>;
};

export type TradeAppHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type TradeAppHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  year?: Maybe<HavingIntFilter>;
  mileage?: Maybe<HavingIntFilter>;
  tradeInValue?: Maybe<HavingFloatFilter>;
  maxTradeInValue?: Maybe<HavingFloatFilter>;
  minTradeInValue?: Maybe<HavingFloatFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `TradeApp` */
export type TradeAppInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** Input for the nested mutation of `lead` in the `TradeAppInput` mutation. */
export type TradeAppLeadIdFkeyInput = {
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectById?: Maybe<LeadLeadPkeyConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  connectByNodeId?: Maybe<LeadNodeIdConnect>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteById?: Maybe<LeadLeadPkeyDelete>;
  /** The primary key(s) for `lead` for the far side of the relationship. */
  deleteByNodeId?: Maybe<LeadNodeIdDelete>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateById?: Maybe<LeadOnTradeAppForTradeAppLeadIdFkeyUsingLeadPkeyUpdate>;
  /** The primary key(s) and patch data for `lead` for the far side of the relationship. */
  updateByNodeId?: Maybe<TradeAppOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate>;
  /** A `LeadInput` object that will be created and connected to this object. */
  create?: Maybe<TradeAppLeadIdFkeyLeadCreateInput>;
};

/** Input for the nested mutation of `tradeApp` in the `LeadInput` mutation. */
export type TradeAppLeadIdFkeyInverseInput = {
  /** The primary key(s) for `tradeApp` for the far side of the relationship. */
  connectById?: Maybe<Array<TradeAppTradeAppPkeyConnect>>;
  /** The primary key(s) for `tradeApp` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<TradeAppNodeIdConnect>>;
  /** The primary key(s) and patch data for `tradeApp` for the far side of the relationship. */
  updateById?: Maybe<Array<TradeAppOnTradeAppForTradeAppLeadIdFkeyUsingTradeAppPkeyUpdate>>;
  /** The primary key(s) and patch data for `tradeApp` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<LeadOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate>>;
  /** A `TradeAppInput` object that will be created and connected to this object. */
  create?: Maybe<Array<TradeAppLeadIdFkeyTradeAppCreateInput>>;
};

/** The `lead` to be created by this mutation. */
export type TradeAppLeadIdFkeyLeadCreateInput = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** The `tradeApp` to be created by this mutation. */
export type TradeAppLeadIdFkeyTradeAppCreateInput = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year: Scalars['Int'];
  make: Scalars['String'];
  model: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** Location options (province, territories) */
export enum TradeAppLocation {
  /** Newfoundland and Labrador */
  Nl = 'NL',
  /** Nova Scotia */
  Ns = 'NS',
  /** Prince Edward Island */
  Pe = 'PE',
  /** New Brunswick */
  Nb = 'NB',
  /** Quebec */
  Qc = 'QC',
  /** Ontario */
  On = 'ON',
  /** Manitoba */
  Mb = 'MB',
  /** Saskatchewan */
  Sk = 'SK',
  /** Alberta */
  Ab = 'AB',
  /** British Columbia */
  Bc = 'BC',
  /** Northwest Territories */
  Nt = 'NT',
  /** Nunavut */
  Nu = 'NU',
  /** Yukon Territory */
  Yt = 'YT'
}

/** Vehicle make. */
export type TradeAppMake = {
  /** Vehicle make identification. */
  makeId?: Maybe<Scalars['Int']>;
  /** Vehicle make name. */
  makeName?: Maybe<Scalars['String']>;
};

export type TradeAppMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Maximum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Maximum of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Maximum of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Maximum of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  mileage?: Maybe<IntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of year across the matching connection */
  year?: Maybe<Scalars['Int']>;
  /** Minimum of mileage across the matching connection */
  mileage?: Maybe<Scalars['Int']>;
  /** Minimum of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Minimum of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Minimum of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/** Vehicle model. */
export type TradeAppModel = {
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle model name. */
  modelName?: Maybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type TradeAppNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `tradeApp` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type TradeAppOnTradeAppForTradeAppLeadIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lead` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lead` being updated. */
  patch: LeadPatch;
};

/** The fields on `tradeApp` to look up the row to update. */
export type TradeAppOnTradeAppForTradeAppLeadIdFkeyUsingTradeAppPkeyUpdate = {
  /** An object where the defined keys will be set on the `tradeApp` being updated. */
  patch: UpdateTradeAppOnTradeAppForTradeAppLeadIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `TradeApp`. Fields that are set will be updated. */
export type TradeAppPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

export type TradeAppPayload = TradeAppError | TradeAppTradeValue;

/** Search result item. */
export type TradeAppSearchResultItem = {
  /** Vehicle model year. */
  year?: Maybe<Scalars['String']>;
  /** Vehicle make name. */
  make?: Maybe<Scalars['String']>;
  /** Vehicle make identification. */
  makeId?: Maybe<Scalars['Int']>;
  /** Vehicle model name. */
  model?: Maybe<Scalars['String']>;
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle trim or variant name. */
  trim?: Maybe<Scalars['String']>;
  /** Vehicle market class. */
  class?: Maybe<Scalars['String']>;
  /** Vehicle identification. */
  vehicleId?: Maybe<Scalars['String']>;
  /** Vehicle msrp. */
  msrp?: Maybe<Scalars['Int']>;
  /** Vehicle chrome style Id. */
  styleId?: Maybe<Scalars['String']>;
};

/** Search results list. */
export type TradeAppSearchResults = {
  /** Total number of search result of the search query. */
  total?: Maybe<Scalars['Int']>;
  /** List of results. */
  results?: Maybe<Array<Maybe<TradeAppSearchResultItem>>>;
};

export type TradeAppStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  year?: Maybe<BigIntFilter>;
  mileage?: Maybe<BigIntFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of year across the matching connection */
  year: Scalars['BigInt'];
  /** Sum of mileage across the matching connection */
  mileage: Scalars['BigInt'];
  /** Sum of tradeInValue across the matching connection */
  tradeInValue: Scalars['Float'];
  /** Sum of maxTradeInValue across the matching connection */
  maxTradeInValue: Scalars['Float'];
  /** Sum of minTradeInValue across the matching connection */
  minTradeInValue: Scalars['Float'];
};

/** The fields on `tradeApp` to look up the row to connect. */
export type TradeAppTradeAppPkeyConnect = {
  id: Scalars['Int'];
};

/** Vehicle trade-in value according to vehicle condition. */
export type TradeAppTradeValue = {
  /** Vehicle fair purchase price. */
  fairPurchasePrice?: Maybe<Scalars['Float']>;
  /** Vehicle lowest trade-in price within the price range. */
  priceLow?: Maybe<Scalars['Float']>;
  /** Vehicle highest trade-in price within the price range. */
  priceHigh?: Maybe<Scalars['Float']>;
  /** Vehicle bse discounted price. */
  baseDiscountedPrice?: Maybe<Scalars['Float']>;
  /** HTML for rendering price advisor. */
  priceAdvisor?: Maybe<Scalars['String']>;
};

/** Vehicle trim or variant. */
export type TradeAppTrim = {
  /** Vehicle identification. */
  vehicleId?: Maybe<Scalars['String']>;
  /** Vehicle model year. */
  year?: Maybe<Scalars['Int']>;
  /** Vehicle model identification. */
  modelId?: Maybe<Scalars['Int']>;
  /** Vehicle trim name. */
  trimName?: Maybe<Scalars['String']>;
};

export type TradeAppVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Population variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Population variance of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Population variance of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

export type TradeAppVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  year?: Maybe<BigFloatFilter>;
  mileage?: Maybe<BigFloatFilter>;
  tradeInValue?: Maybe<FloatFilter>;
  maxTradeInValue?: Maybe<FloatFilter>;
  minTradeInValue?: Maybe<FloatFilter>;
};

export type TradeAppVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of year across the matching connection */
  year?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of mileage across the matching connection */
  mileage?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tradeInValue across the matching connection */
  tradeInValue?: Maybe<Scalars['Float']>;
  /** Sample variance of maxTradeInValue across the matching connection */
  maxTradeInValue?: Maybe<Scalars['Float']>;
  /** Sample variance of minTradeInValue across the matching connection */
  minTradeInValue?: Maybe<Scalars['Float']>;
};

/** Condition options (defined by KBB as Vehicle Grade) */
export enum TradeAppVehicleCondition {
  Excellent = 'EXCELLENT',
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Fair = 'FAIR'
}

/** Vehicle information. */
export type TradeAppVehicleInfo = {
  /** Vehicle manufacturer. */
  makeName?: Maybe<Scalars['String']>;
  /** Vehicle model name. */
  modelName?: Maybe<Scalars['String']>;
  /** Vehicle variant name. */
  variantName?: Maybe<Scalars['String']>;
  /** Vehicle model year. */
  year?: Maybe<Scalars['Int']>;
  /** Vehicle body type. */
  bodyTypeName?: Maybe<Scalars['String']>;
  /** Number of doors. */
  nrDoors?: Maybe<Scalars['Int']>;
  /** Number of seats. */
  nrSeats?: Maybe<Scalars['Int']>;
  /** Transmission type. */
  transmissionTypeName?: Maybe<Scalars['String']>;
  /** Fuel type. */
  fuelTypeName?: Maybe<Scalars['String']>;
  /** Engine type. */
  engineTypeName?: Maybe<Scalars['String']>;
  /** Drive train type. */
  driveTrainTypeName?: Maybe<Scalars['String']>;
  /** Large image url. */
  mediaUrlLarge?: Maybe<Scalars['String']>;
  /** Medium image url. */
  mediaUrlMedium?: Maybe<Scalars['String']>;
  /** Small image url. */
  mediaUrlSmall?: Maybe<Scalars['String']>;
};

/** Vehicle year makes. */
export type TradeAppYearMakes = {
  /** Vehicle model year. */
  modelYear: Scalars['Int'];
  /** Vehicle make Ids. */
  makeIds: Array<Scalars['Int']>;
};

/** A connection to a list of `TradeApp` values. */
export type TradeAppsConnection = {
  /** A list of `TradeApp` objects. */
  nodes: Array<TradeApp>;
  /** A list of edges which contains the `TradeApp` and cursor to aid in pagination. */
  edges: Array<TradeAppsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TradeApp` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TradeAppAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TradeAppAggregates>>;
};


/** A connection to a list of `TradeApp` values. */
export type TradeAppsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TradeAppGroupBy>;
  having?: Maybe<TradeAppHavingInput>;
};

/** A `TradeApp` edge in the connection. */
export type TradeAppsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TradeApp` at the end of the edge. */
  node: TradeApp;
};

/** Methods to use when ordering `TradeApp`. */
export enum TradeAppsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LeadIdAsc = 'LEAD_ID_ASC',
  LeadIdDesc = 'LEAD_ID_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC',
  MakeAsc = 'MAKE_ASC',
  MakeDesc = 'MAKE_DESC',
  ModelAsc = 'MODEL_ASC',
  ModelDesc = 'MODEL_DESC',
  TrimAsc = 'TRIM_ASC',
  TrimDesc = 'TRIM_DESC',
  MileageAsc = 'MILEAGE_ASC',
  MileageDesc = 'MILEAGE_DESC',
  ConditionAsc = 'CONDITION_ASC',
  ConditionDesc = 'CONDITION_DESC',
  TradeInValueAsc = 'TRADE_IN_VALUE_ASC',
  TradeInValueDesc = 'TRADE_IN_VALUE_DESC',
  MaxTradeInValueAsc = 'MAX_TRADE_IN_VALUE_ASC',
  MaxTradeInValueDesc = 'MAX_TRADE_IN_VALUE_DESC',
  MinTradeInValueAsc = 'MIN_TRADE_IN_VALUE_ASC',
  MinTradeInValueDesc = 'MIN_TRADE_IN_VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TradeLineOverview = {
  totalBalance: Scalars['Float'];
  currentAutoPayment: Scalars['Float'];
  autoTradeLinesQuantity: Scalars['Int'];
  autoTradeLinesBalance: Scalars['Float'];
  autoTradeLinesOver1500Quantity: Scalars['Int'];
  autoTradeLinesOver1500Balance: Scalars['Float'];
  autoTradeLinesUnder1500Quantity: Scalars['Int'];
  autoTradeLinesUnder1500Balance: Scalars['Float'];
  nonAutoTradeLinesQuantity: Scalars['Int'];
  nonAutoTradeLinesBalance: Scalars['Float'];
};

export type TradeLines = {
  overview: TradeLineOverview;
  all: Array<Trade>;
  autoOver1500: Array<Trade>;
  autoUnder1500: Array<Trade>;
  nonAuto: Array<Trade>;
};

export enum TradeProvider {
  Cbb = 'cbb',
  Kbb = 'kbb'
}

export type TransactionResponse = {
  entity?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  flags?: Maybe<Array<Scalars['String']>>;
  fields?: Maybe<IdFields>;
  images?: Maybe<Images>;
  action?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerUid?: Maybe<Scalars['String']>;
  duplicates?: Maybe<Array<Maybe<IdDuplicate>>>;
  dashboardUrl?: Maybe<Scalars['String']>;
  firstStep?: Maybe<Scalars['String']>;
  nextStep?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  shortUrl?: Maybe<Scalars['String']>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

export type UnreadLeadCountResponse = {
  companyId: Scalars['Int'];
  unreadCounts: Array<Maybe<ProductLeadCount>>;
};

/** All input for the `updateAnalyticByNodeId` mutation. */
export type UpdateAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Analytic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Analytic` being updated. */
  patch: AnalyticPatch;
};

/** All input for the `updateAnalytic` mutation. */
export type UpdateAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Analytic` being updated. */
  patch: AnalyticPatch;
  id: Scalars['Int'];
};

/** The output of our update `Analytic` mutation. */
export type UpdateAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Analytic` that was updated by this mutation. */
  analytic?: Maybe<Analytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Analytic`. */
  company?: Maybe<Company>;
  /** An edge for our `Analytic`. May be used by Relay 1. */
  analyticEdge?: Maybe<AnalyticsEdge>;
};


/** The output of our update `Analytic` mutation. */
export type UpdateAnalyticPayloadAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsOrderBy>>;
};

/** All input for the `updateAnalyticsCtaByNodeId` mutation. */
export type UpdateAnalyticsCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AnalyticsCta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AnalyticsCta` being updated. */
  patch: AnalyticsCtaPatch;
};

/** All input for the `updateAnalyticsCta` mutation. */
export type UpdateAnalyticsCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AnalyticsCta` being updated. */
  patch: AnalyticsCtaPatch;
  eventId: Scalars['Int'];
};

/** The output of our update `AnalyticsCta` mutation. */
export type UpdateAnalyticsCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AnalyticsCta` that was updated by this mutation. */
  analyticsCta?: Maybe<AnalyticsCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AnalyticsCta`. May be used by Relay 1. */
  analyticsCtaEdge?: Maybe<AnalyticsCtasEdge>;
};


/** The output of our update `AnalyticsCta` mutation. */
export type UpdateAnalyticsCtaPayloadAnalyticsCtaEdgeArgs = {
  orderBy?: Maybe<Array<AnalyticsCtasOrderBy>>;
};

/** All input for the `updateAppointmentByNodeId` mutation. */
export type UpdateAppointmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Appointment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Appointment` being updated. */
  patch: AppointmentPatch;
};

/** All input for the `updateAppointment` mutation. */
export type UpdateAppointmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Appointment` being updated. */
  patch: AppointmentPatch;
  id: Scalars['Int'];
};

/** The output of our update `Appointment` mutation. */
export type UpdateAppointmentPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Appointment` that was updated by this mutation. */
  appointment?: Maybe<Appointment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `Appointment`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `Appointment`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `Appointment`. May be used by Relay 1. */
  appointmentEdge?: Maybe<AppointmentsEdge>;
};


/** The output of our update `Appointment` mutation. */
export type UpdateAppointmentPayloadAppointmentEdgeArgs = {
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
};

/** All input for the `updateAvaAppByLeadId` mutation. */
export type UpdateAvaAppByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateAvaAppByNodeId` mutation. */
export type UpdateAvaAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AvaApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
};

/** All input for the `updateAvaApp` mutation. */
export type UpdateAvaAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AvaApp` being updated. */
  patch: AvaAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `AvaApp` mutation. */
export type UpdateAvaAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AvaApp` that was updated by this mutation. */
  avaApp?: Maybe<AvaApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `AvaApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `AvaApp`. May be used by Relay 1. */
  avaAppEdge?: Maybe<AvaAppsEdge>;
};


/** The output of our update `AvaApp` mutation. */
export type UpdateAvaAppPayloadAvaAppEdgeArgs = {
  orderBy?: Maybe<Array<AvaAppsOrderBy>>;
};

/** All input for the `updateChannelByNodeId` mutation. */
export type UpdateChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Channel` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Channel` being updated. */
  patch: ChannelPatch;
};

/** All input for the `updateChannel` mutation. */
export type UpdateChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Channel` being updated. */
  patch: ChannelPatch;
  id: Scalars['Int'];
};

/** The output of our update `Channel` mutation. */
export type UpdateChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Channel` that was updated by this mutation. */
  channel?: Maybe<Channel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge?: Maybe<ChannelsEdge>;
};


/** The output of our update `Channel` mutation. */
export type UpdateChannelPayloadChannelEdgeArgs = {
  orderBy?: Maybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateCompanyAnalyticByNodeId` mutation. */
export type UpdateCompanyAnalyticByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyAnalytic` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
};

/** All input for the `updateCompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyAnalytic` being updated. */
  patch: CompanyAnalyticPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyAnalytic` that was updated by this mutation. */
  companyAnalytic?: Maybe<CompanyAnalytic>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyAnalytic`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyAnalytic`. May be used by Relay 1. */
  companyAnalyticEdge?: Maybe<CompanyAnalyticsEdge>;
};


/** The output of our update `CompanyAnalytic` mutation. */
export type UpdateCompanyAnalyticPayloadCompanyAnalyticEdgeArgs = {
  orderBy?: Maybe<Array<CompanyAnalyticsOrderBy>>;
};

/** All input for the `updateCompanyByExternalId` mutation. */
export type UpdateCompanyByExternalIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  externalId: Scalars['String'];
};

/** All input for the `updateCompanyByName` mutation. */
export type UpdateCompanyByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  name: Scalars['String'];
};

/** All input for the `updateCompanyByNodeId` mutation. */
export type UpdateCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Company` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
};

/** All input for the `updateCompanyByPrimaryGroup` mutation. */
export type UpdateCompanyByPrimaryGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  primaryGroup: Scalars['Int'];
};

/** All input for the `updateCompanyChannelByNodeId` mutation. */
export type UpdateCompanyChannelByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyChannel` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyChannel` being updated. */
  patch: CompanyChannelPatch;
};

/** All input for the `updateCompanyChannel` mutation. */
export type UpdateCompanyChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyChannel` being updated. */
  patch: CompanyChannelPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyChannel` mutation. */
export type UpdateCompanyChannelPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyChannel` that was updated by this mutation. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Channel` that is related to this `CompanyChannel`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Company` that is related to this `CompanyChannel`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyChannel`. May be used by Relay 1. */
  companyChannelEdge?: Maybe<CompanyChannelsEdge>;
};


/** The output of our update `CompanyChannel` mutation. */
export type UpdateCompanyChannelPayloadCompanyChannelEdgeArgs = {
  orderBy?: Maybe<Array<CompanyChannelsOrderBy>>;
};

/** All input for the `updateCompanyCtaByNodeId` mutation. */
export type UpdateCompanyCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyCta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyCta` being updated. */
  patch: CompanyCtaPatch;
};

/** All input for the `updateCompanyCta` mutation. */
export type UpdateCompanyCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyCta` being updated. */
  patch: CompanyCtaPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyCta` mutation. */
export type UpdateCompanyCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyCta` that was updated by this mutation. */
  companyCta?: Maybe<CompanyCta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CompanyCta`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Group` that is related to this `CompanyCta`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyCta`. May be used by Relay 1. */
  companyCtaEdge?: Maybe<CompanyCtasEdge>;
};


/** The output of our update `CompanyCta` mutation. */
export type UpdateCompanyCtaPayloadCompanyCtaEdgeArgs = {
  orderBy?: Maybe<Array<CompanyCtasOrderBy>>;
};

/** All input for the `updateCompanyDomainByDomain` mutation. */
export type UpdateCompanyDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
  domain: Scalars['String'];
};

/** All input for the `updateCompanyDomainByNodeId` mutation. */
export type UpdateCompanyDomainByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomain` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
};

/** All input for the `updateCompanyDomain` mutation. */
export type UpdateCompanyDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomain` being updated. */
  patch: CompanyDomainPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyDomain` mutation. */
export type UpdateCompanyDomainPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomain` that was updated by this mutation. */
  companyDomain?: Maybe<CompanyDomain>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomain`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyDomain`. May be used by Relay 1. */
  companyDomainEdge?: Maybe<CompanyDomainsEdge>;
};


/** The output of our update `CompanyDomain` mutation. */
export type UpdateCompanyDomainPayloadCompanyDomainEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsOrderBy>>;
};

/** All input for the `updateCompanyDomainsApprovedByNodeId` mutation. */
export type UpdateCompanyDomainsApprovedByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsApproved` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
};

/** All input for the `updateCompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsApproved` being updated. */
  patch: CompanyDomainsApprovedPatch;
  companyId: Scalars['Int'];
  domainId: Scalars['Int'];
};

/** The output of our update `CompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsApproved` that was updated by this mutation. */
  companyDomainsApproved?: Maybe<CompanyDomainsApproved>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsApproved`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsApproved`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsApproved`. May be used by Relay 1. */
  companyDomainsApprovedEdge?: Maybe<CompanyDomainsApprovedsEdge>;
};


/** The output of our update `CompanyDomainsApproved` mutation. */
export type UpdateCompanyDomainsApprovedPayloadCompanyDomainsApprovedEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsApprovedsOrderBy>>;
};

/** All input for the `updateCompanyDomainsPathByDomainIdAndPath` mutation. */
export type UpdateCompanyDomainsPathByDomainIdAndPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
  domainId: Scalars['Int'];
  path: Scalars['String'];
};

/** All input for the `updateCompanyDomainsPathByNodeId` mutation. */
export type UpdateCompanyDomainsPathByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyDomainsPath` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
};

/** All input for the `updateCompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyDomainsPath` being updated. */
  patch: CompanyDomainsPathPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyDomainsPath` that was updated by this mutation. */
  companyDomainsPath?: Maybe<CompanyDomainsPath>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyDomainsPath`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyDomain` that is related to this `CompanyDomainsPath`. */
  domain?: Maybe<CompanyDomain>;
  /** An edge for our `CompanyDomainsPath`. May be used by Relay 1. */
  companyDomainsPathEdge?: Maybe<CompanyDomainsPathsEdge>;
};


/** The output of our update `CompanyDomainsPath` mutation. */
export type UpdateCompanyDomainsPathPayloadCompanyDomainsPathEdgeArgs = {
  orderBy?: Maybe<Array<CompanyDomainsPathsOrderBy>>;
};

/** All input for the `updateCompanyGroupByNodeId` mutation. */
export type UpdateCompanyGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyGroup` being updated. */
  patch: CompanyGroupPatch;
};

/** All input for the `updateCompanyGroup` mutation. */
export type UpdateCompanyGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyGroup` being updated. */
  patch: CompanyGroupPatch;
  companyId: Scalars['Int'];
  groupId: Scalars['Int'];
};

/** The output of our update `CompanyGroup` mutation. */
export type UpdateCompanyGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyGroup` that was updated by this mutation. */
  companyGroup?: Maybe<CompanyGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `Group` that is related to this `CompanyGroup`. */
  group?: Maybe<Group>;
  /** An edge for our `CompanyGroup`. May be used by Relay 1. */
  companyGroupEdge?: Maybe<CompanyGroupsEdge>;
};


/** The output of our update `CompanyGroup` mutation. */
export type UpdateCompanyGroupPayloadCompanyGroupEdgeArgs = {
  orderBy?: Maybe<Array<CompanyGroupsOrderBy>>;
};

/** All input for the `updateCompany` mutation. */
export type UpdateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
  id: Scalars['Int'];
};

/** All input for the `updateCompanyIntegrationByNodeId` mutation. */
export type UpdateCompanyIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
};

/** All input for the `updateCompanyIntegration` mutation. */
export type UpdateCompanyIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyIntegration` being updated. */
  patch: CompanyIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyIntegration` mutation. */
export type UpdateCompanyIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyIntegration` that was updated by this mutation. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `CompanyIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Company` that is related to this `CompanyIntegration`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyIntegration`. May be used by Relay 1. */
  companyIntegrationEdge?: Maybe<CompanyIntegrationsEdge>;
};


/** The output of our update `CompanyIntegration` mutation. */
export type UpdateCompanyIntegrationPayloadCompanyIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyIntegrationsOrderBy>>;
};

/** All input for the `updateCompanyLocationByNodeId` mutation. */
export type UpdateCompanyLocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyLocation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyLocation` being updated. */
  patch: CompanyLocationPatch;
};

/** All input for the `updateCompanyLocation` mutation. */
export type UpdateCompanyLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyLocation` being updated. */
  patch: CompanyLocationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyLocation` mutation. */
export type UpdateCompanyLocationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyLocation` that was updated by this mutation. */
  companyLocation?: Maybe<CompanyLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyLocation`. */
  company?: Maybe<Company>;
  /** An edge for our `CompanyLocation`. May be used by Relay 1. */
  companyLocationEdge?: Maybe<CompanyLocationsEdge>;
};


/** The output of our update `CompanyLocation` mutation. */
export type UpdateCompanyLocationPayloadCompanyLocationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyLocationsOrderBy>>;
};

export type UpdateCompanyOverride = {
  groupId: Scalars['Int'];
  /** Will be used to ensure that there are no sync conflicts */
  updatedAt: Scalars['Datetime'];
  /** User friendly name of this override. */
  name: Scalars['String'];
  /** Actual theme name that exists on the CTA */
  theme?: Maybe<Scalars['String']>;
  /** Should the override be deleted? */
  delete?: Maybe<Scalars['Boolean']>;
  /**
   * Whether this override should be locked to the specific minor/patch
   * version number.  If true, this override will not be applied on any
   * compatible major version other than this specific CTA.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this
   * override will be used.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
};

/** The output of our update `Company` mutation. */
export type UpdateCompanyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was updated by this mutation. */
  company?: Maybe<Company>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Company`. */
  groupByPrimaryGroup?: Maybe<Group>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
};


/** The output of our update `Company` mutation. */
export type UpdateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: Maybe<Array<CompaniesOrderBy>>;
};

/** All input for the `updateCompanyProductByNodeId` mutation. */
export type UpdateCompanyProductByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProduct` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyProduct` being updated. */
  patch: CompanyProductPatch;
};

/** All input for the `updateCompanyProduct` mutation. */
export type UpdateCompanyProductInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyProduct` being updated. */
  patch: CompanyProductPatch;
  id: Scalars['Int'];
};

/** All input for the `updateCompanyProductIntegrationByNodeId` mutation. */
export type UpdateCompanyProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyProductIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
};

/** All input for the `updateCompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyProductIntegration` being updated. */
  patch: CompanyProductIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `CompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProductIntegration` that was updated by this mutation. */
  companyProductIntegration?: Maybe<CompanyProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `CompanyIntegration` that is related to this `CompanyProductIntegration`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `CompanyProduct` that is related to this `CompanyProductIntegration`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** An edge for our `CompanyProductIntegration`. May be used by Relay 1. */
  companyProductIntegrationEdge?: Maybe<CompanyProductIntegrationsEdge>;
};


/** The output of our update `CompanyProductIntegration` mutation. */
export type UpdateCompanyProductIntegrationPayloadCompanyProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductIntegrationsOrderBy>>;
};

/** The output of our update `CompanyProduct` mutation. */
export type UpdateCompanyProductPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyProduct` that was updated by this mutation. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `CompanyProduct`. */
  company?: Maybe<Company>;
  /** Reads a single `Product` that is related to this `CompanyProduct`. */
  product?: Maybe<Product>;
  /** An edge for our `CompanyProduct`. May be used by Relay 1. */
  companyProductEdge?: Maybe<CompanyProductsEdge>;
};


/** The output of our update `CompanyProduct` mutation. */
export type UpdateCompanyProductPayloadCompanyProductEdgeArgs = {
  orderBy?: Maybe<Array<CompanyProductsOrderBy>>;
};

/** All input for the `updateCompanyWidgetByNodeId` mutation. */
export type UpdateCompanyWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyWidget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** All input for the `updateCompanyWidget` mutation. */
export type UpdateCompanyWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CompanyWidget` being updated. */
  patch: CompanyWidgetPatch;
  groupId: Scalars['Int'];
  widgetId: Scalars['String'];
};

/** The output of our update `CompanyWidget` mutation. */
export type UpdateCompanyWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CompanyWidget` that was updated by this mutation. */
  companyWidget?: Maybe<CompanyWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `CompanyWidget`. */
  group?: Maybe<Group>;
  /** Reads a single `Widget` that is related to this `CompanyWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CompanyWidget`. May be used by Relay 1. */
  companyWidgetEdge?: Maybe<CompanyWidgetsEdge>;
};


/** The output of our update `CompanyWidget` mutation. */
export type UpdateCompanyWidgetPayloadCompanyWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
};

/** All input for the `updateCreditAppByLeadId` mutation. */
export type UpdateCreditAppByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CreditApp` being updated. */
  patch: CreditAppPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateCreditAppByNodeId` mutation. */
export type UpdateCreditAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CreditApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CreditApp` being updated. */
  patch: CreditAppPatch;
};

/** All input for the `updateCreditApp` mutation. */
export type UpdateCreditAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CreditApp` being updated. */
  patch: CreditAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `CreditApp` mutation. */
export type UpdateCreditAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditApp` that was updated by this mutation. */
  creditApp?: Maybe<CreditApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `CreditApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `CreditApp`. May be used by Relay 1. */
  creditAppEdge?: Maybe<CreditAppsEdge>;
};


/** The output of our update `CreditApp` mutation. */
export type UpdateCreditAppPayloadCreditAppEdgeArgs = {
  orderBy?: Maybe<Array<CreditAppsOrderBy>>;
};

/** All input for the `updateCreditBureauByNodeId` mutation. */
export type UpdateCreditBureauByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CreditBureau` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CreditBureau` being updated. */
  patch: CreditBureauPatch;
};

/** All input for the `updateCreditBureau` mutation. */
export type UpdateCreditBureauInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CreditBureau` being updated. */
  patch: CreditBureauPatch;
  id: Scalars['Int'];
};

/** The output of our update `CreditBureau` mutation. */
export type UpdateCreditBureauPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CreditBureau` that was updated by this mutation. */
  creditBureau?: Maybe<CreditBureau>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AvaApp` that is related to this `CreditBureau`. */
  avaApp?: Maybe<AvaApp>;
  /** An edge for our `CreditBureau`. May be used by Relay 1. */
  creditBureauEdge?: Maybe<CreditBureausEdge>;
};


/** The output of our update `CreditBureau` mutation. */
export type UpdateCreditBureauPayloadCreditBureauEdgeArgs = {
  orderBy?: Maybe<Array<CreditBureausOrderBy>>;
};

/** All input for the `updateCtaByNodeId` mutation. */
export type UpdateCtaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
};

/** All input for the `updateCtaByProductAndTypeAndVersion` mutation. */
export type UpdateCtaByProductAndTypeAndVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
  product: ProductType;
  type: CtaType;
  version: Scalars['String'];
};

/** All input for the `updateCta` mutation. */
export type UpdateCtaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Cta` being updated. */
  patch: CtaPatch;
  id: Scalars['String'];
};

/** The output of our update `Cta` mutation. */
export type UpdateCtaPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cta` that was updated by this mutation. */
  cta?: Maybe<Cta>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Cta`. May be used by Relay 1. */
  ctaEdge?: Maybe<CtasEdge>;
};


/** The output of our update `Cta` mutation. */
export type UpdateCtaPayloadCtaEdgeArgs = {
  orderBy?: Maybe<Array<CtasOrderBy>>;
};

export type UpdateCtaSpecsAndDefs = {
  /** What theme on the CTA to update */
  theme: Scalars['String'];
  /** Should the theme be deleted? */
  delete?: Maybe<Scalars['Boolean']>;
  /** Use when replacing the theme specifications */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Use when replacing the template specifications */
  templateSpec?: Maybe<Scalars['JSON']>;
  /**
   * Use when redefining the theme definitions for the requested
   * theme.
   */
  themeDef?: Maybe<Scalars['JSON']>;
  /**
   * Use when redefining template definitions for the requested
   * theme.
   */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Use when redefining the plugin value definitions. */
  pluginDef?: Maybe<Scalars['JSON']>;
};

export type UpdateCtaSpecsInput = {
  id: Scalars['String'];
  /** Will be used to ensure that there are no sync conflicts */
  updatedAt: Scalars['Datetime'];
  published?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  ctaStyles?: Maybe<Scalars['String']>;
  ctaStylesStatic?: Maybe<Scalars['String']>;
  ctaTree?: Maybe<Scalars['JSON']>;
  specsDefs?: Maybe<UpdateCtaSpecsAndDefs>;
  companyOverride?: Maybe<UpdateCompanyOverride>;
};

/** All input for the `updateCtaWidgetByNodeId` mutation. */
export type UpdateCtaWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CtaWidget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CtaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** All input for the `updateCtaWidget` mutation. */
export type UpdateCtaWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CtaWidget` being updated. */
  patch: CtaWidgetPatch;
  id: Scalars['Int'];
};

/** The output of our update `CtaWidget` mutation. */
export type UpdateCtaWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CtaWidget` that was updated by this mutation. */
  ctaWidget?: Maybe<CtaWidget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Cta` that is related to this `CtaWidget`. */
  cta?: Maybe<Cta>;
  /** Reads a single `Widget` that is related to this `CtaWidget`. */
  widget?: Maybe<Widget>;
  /** An edge for our `CtaWidget`. May be used by Relay 1. */
  ctaWidgetEdge?: Maybe<CtaWidgetsEdge>;
};


/** The output of our update `CtaWidget` mutation. */
export type UpdateCtaWidgetPayloadCtaWidgetEdgeArgs = {
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
};

/** All input for the `updateDeliveryMethodByNodeId` mutation. */
export type UpdateDeliveryMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DeliveryMethod` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DeliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
};

/** All input for the `updateDeliveryMethod` mutation. */
export type UpdateDeliveryMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `DeliveryMethod` being updated. */
  patch: DeliveryMethodPatch;
  id: Scalars['Int'];
};

/** The output of our update `DeliveryMethod` mutation. */
export type UpdateDeliveryMethodPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DeliveryMethod` that was updated by this mutation. */
  deliveryMethod?: Maybe<DeliveryMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `DeliveryMethod`. */
  group?: Maybe<Group>;
  /** An edge for our `DeliveryMethod`. May be used by Relay 1. */
  deliveryMethodEdge?: Maybe<DeliveryMethodsEdge>;
};


/** The output of our update `DeliveryMethod` mutation. */
export type UpdateDeliveryMethodPayloadDeliveryMethodEdgeArgs = {
  orderBy?: Maybe<Array<DeliveryMethodsOrderBy>>;
};

/** All input for the `updateEmailTemplateByNodeId` mutation. */
export type UpdateEmailTemplateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmailTemplate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
  patch: EmailTemplatePatch;
};

/** All input for the `updateEmailTemplate` mutation. */
export type UpdateEmailTemplateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
  patch: EmailTemplatePatch;
  name: Scalars['String'];
};

/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmailTemplate` that was updated by this mutation. */
  emailTemplate?: Maybe<EmailTemplate>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EmailTemplate`. May be used by Relay 1. */
  emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};


/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayloadEmailTemplateEdgeArgs = {
  orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `updateEquifaxLenderByEquifaxIdAndName` mutation. */
export type UpdateEquifaxLenderByEquifaxIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
  equifaxId: Scalars['String'];
  name: Scalars['String'];
};

/** All input for the `updateEquifaxLenderByNodeId` mutation. */
export type UpdateEquifaxLenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquifaxLender` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
};

/** All input for the `updateEquifaxLender` mutation. */
export type UpdateEquifaxLenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EquifaxLender` being updated. */
  patch: EquifaxLenderPatch;
  id: Scalars['Int'];
};

/** The output of our update `EquifaxLender` mutation. */
export type UpdateEquifaxLenderPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquifaxLender` that was updated by this mutation. */
  equifaxLender?: Maybe<EquifaxLender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `EquifaxLender`. May be used by Relay 1. */
  equifaxLenderEdge?: Maybe<EquifaxLendersEdge>;
};


/** The output of our update `EquifaxLender` mutation. */
export type UpdateEquifaxLenderPayloadEquifaxLenderEdgeArgs = {
  orderBy?: Maybe<Array<EquifaxLendersOrderBy>>;
};

/** All input for the `updateFieldByCompanyChannelIdAndConfig` mutation. */
export type UpdateFieldByCompanyChannelIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyChannelId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByCompanyIntegrationIdAndConfig` mutation. */
export type UpdateFieldByCompanyIntegrationIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyIntegrationId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByCompanyProductIdAndConfig` mutation. */
export type UpdateFieldByCompanyProductIdAndConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  companyProductId: Scalars['Int'];
  config: Scalars['Int'];
};

/** All input for the `updateFieldByNodeId` mutation. */
export type UpdateFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Field` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
};

/** All input for the `updateFieldConfigByNodeId` mutation. */
export type UpdateFieldConfigByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldConfig` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FieldConfig` being updated. */
  patch: FieldConfigPatch;
};

/** All input for the `updateFieldConfig` mutation. */
export type UpdateFieldConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldConfig` being updated. */
  patch: FieldConfigPatch;
  id: Scalars['Int'];
};

/** The output of our update `FieldConfig` mutation. */
export type UpdateFieldConfigPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldConfig` that was updated by this mutation. */
  fieldConfig?: Maybe<FieldConfig>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Product` that is related to this `FieldConfig`. */
  product?: Maybe<Product>;
  /** Reads a single `Channel` that is related to this `FieldConfig`. */
  channel?: Maybe<Channel>;
  /** Reads a single `Integration` that is related to this `FieldConfig`. */
  integration?: Maybe<Integration>;
  /** An edge for our `FieldConfig`. May be used by Relay 1. */
  fieldConfigEdge?: Maybe<FieldConfigsEdge>;
};


/** The output of our update `FieldConfig` mutation. */
export type UpdateFieldConfigPayloadFieldConfigEdgeArgs = {
  orderBy?: Maybe<Array<FieldConfigsOrderBy>>;
};

/** All input for the `updateField` mutation. */
export type UpdateFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Field` being updated. */
  patch: FieldPatch;
  id: Scalars['Int'];
};

/** All input for the `updateFieldKeyByKey` mutation. */
export type UpdateFieldKeyByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
  key: Scalars['String'];
};

/** All input for the `updateFieldKeyByNodeId` mutation. */
export type UpdateFieldKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FieldKey` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
};

/** All input for the `updateFieldKey` mutation. */
export type UpdateFieldKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FieldKey` being updated. */
  patch: FieldKeyPatch;
  id: Scalars['Int'];
};

/** The output of our update `FieldKey` mutation. */
export type UpdateFieldKeyPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FieldKey` that was updated by this mutation. */
  fieldKey?: Maybe<FieldKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `FieldKey`. May be used by Relay 1. */
  fieldKeyEdge?: Maybe<FieldKeysEdge>;
};


/** The output of our update `FieldKey` mutation. */
export type UpdateFieldKeyPayloadFieldKeyEdgeArgs = {
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
};

/** The output of our update `Field` mutation. */
export type UpdateFieldPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Field` that was updated by this mutation. */
  field?: Maybe<Field>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Field`. */
  company?: Maybe<Company>;
  /** Reads a single `CompanyProduct` that is related to this `Field`. */
  companyProduct?: Maybe<CompanyProduct>;
  /** Reads a single `CompanyChannel` that is related to this `Field`. */
  companyChannel?: Maybe<CompanyChannel>;
  /** Reads a single `CompanyIntegration` that is related to this `Field`. */
  companyIntegration?: Maybe<CompanyIntegration>;
  /** Reads a single `FieldConfig` that is related to this `Field`. */
  fieldConfigByConfig?: Maybe<FieldConfig>;
  /** An edge for our `Field`. May be used by Relay 1. */
  fieldEdge?: Maybe<FieldsEdge>;
};


/** The output of our update `Field` mutation. */
export type UpdateFieldPayloadFieldEdgeArgs = {
  orderBy?: Maybe<Array<FieldsOrderBy>>;
};

/** All input for the `updateGroupByCognitoName` mutation. */
export type UpdateGroupByCognitoNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  cognitoName: Scalars['String'];
};

/** All input for the `updateGroupByNodeId` mutation. */
export type UpdateGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  id: Scalars['Int'];
};

/** The output of our update `Group` mutation. */
export type UpdateGroupPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was updated by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our update `Group` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the `updateIdVerificationByLeadId` mutation. */
export type UpdateIdVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateIdVerificationByNodeId` mutation. */
export type UpdateIdVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IdVerification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
};

/** All input for the `updateIdVerification` mutation. */
export type UpdateIdVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IdVerification` being updated. */
  patch: IdVerificationPatch;
  id: Scalars['Int'];
};

/** The output of our update `IdVerification` mutation. */
export type UpdateIdVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IdVerification` that was updated by this mutation. */
  idVerification?: Maybe<IdVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IdVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IdVerification`. May be used by Relay 1. */
  idVerificationEdge?: Maybe<IdVerificationsEdge>;
};


/** The output of our update `IdVerification` mutation. */
export type UpdateIdVerificationPayloadIdVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IdVerificationsOrderBy>>;
};

/** All input for the `updateIncomeVerificationByLeadId` mutation. */
export type UpdateIncomeVerificationByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateIncomeVerificationByNodeId` mutation. */
export type UpdateIncomeVerificationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `IncomeVerification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
};

/** All input for the `updateIncomeVerification` mutation. */
export type UpdateIncomeVerificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `IncomeVerification` being updated. */
  patch: IncomeVerificationPatch;
  id: Scalars['Int'];
};

/** The output of our update `IncomeVerification` mutation. */
export type UpdateIncomeVerificationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `IncomeVerification` that was updated by this mutation. */
  incomeVerification?: Maybe<IncomeVerification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `IncomeVerification`. */
  lead?: Maybe<Lead>;
  /** An edge for our `IncomeVerification`. May be used by Relay 1. */
  incomeVerificationEdge?: Maybe<IncomeVerificationsEdge>;
};


/** The output of our update `IncomeVerification` mutation. */
export type UpdateIncomeVerificationPayloadIncomeVerificationEdgeArgs = {
  orderBy?: Maybe<Array<IncomeVerificationsOrderBy>>;
};

/** All input for the `updateIntegrationByNodeId` mutation. */
export type UpdateIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Integration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Integration` being updated. */
  patch: IntegrationPatch;
};

/** All input for the `updateIntegration` mutation. */
export type UpdateIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Integration` being updated. */
  patch: IntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `Integration` mutation. */
export type UpdateIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Integration` that was updated by this mutation. */
  integration?: Maybe<Integration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Integration`. May be used by Relay 1. */
  integrationEdge?: Maybe<IntegrationsEdge>;
};


/** The output of our update `Integration` mutation. */
export type UpdateIntegrationPayloadIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<IntegrationsOrderBy>>;
};

/** All input for the `updateLeadByNodeId` mutation. */
export type UpdateLeadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lead` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lead` being updated. */
  patch: LeadPatch;
};

/** All input for the `updateLeadCampaignByLeadId` mutation. */
export type UpdateLeadCampaignByLeadIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
  leadId: Scalars['UUID'];
};

/** All input for the `updateLeadCampaignByNodeId` mutation. */
export type UpdateLeadCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LeadCampaign` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
};

/** All input for the `updateLeadCampaign` mutation. */
export type UpdateLeadCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LeadCampaign` being updated. */
  patch: LeadCampaignPatch;
  id: Scalars['Int'];
};

/** The output of our update `LeadCampaign` mutation. */
export type UpdateLeadCampaignPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LeadCampaign` that was updated by this mutation. */
  leadCampaign?: Maybe<LeadCampaign>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LeadCampaign`. */
  lead?: Maybe<Lead>;
  /** An edge for our `LeadCampaign`. May be used by Relay 1. */
  leadCampaignEdge?: Maybe<LeadCampaignsEdge>;
};


/** The output of our update `LeadCampaign` mutation. */
export type UpdateLeadCampaignPayloadLeadCampaignEdgeArgs = {
  orderBy?: Maybe<Array<LeadCampaignsOrderBy>>;
};

/** All input for the `updateLead` mutation. */
export type UpdateLeadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lead` being updated. */
  patch: LeadPatch;
  id: Scalars['UUID'];
};

/** The output of our update `Lead` mutation. */
export type UpdateLeadPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Lead` that was updated by this mutation. */
  lead?: Maybe<Lead>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Lead`. */
  company?: Maybe<Company>;
  /** An edge for our `Lead`. May be used by Relay 1. */
  leadEdge?: Maybe<LeadsEdge>;
};


/** The output of our update `Lead` mutation. */
export type UpdateLeadPayloadLeadEdgeArgs = {
  orderBy?: Maybe<Array<LeadsOrderBy>>;
};

/** All input for the `updateLenderAppByNodeId` mutation. */
export type UpdateLenderAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderApp` being updated. */
  patch: LenderAppPatch;
};

/** All input for the `updateLenderApp` mutation. */
export type UpdateLenderAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderApp` being updated. */
  patch: LenderAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderApp` mutation. */
export type UpdateLenderAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderApp` that was updated by this mutation. */
  lenderApp?: Maybe<LenderApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderApp`. */
  lead?: Maybe<Lead>;
  /** Reads a single `VehicleListing` that is related to this `LenderApp`. */
  vehicleListing?: Maybe<VehicleListing>;
  /** An edge for our `LenderApp`. May be used by Relay 1. */
  lenderAppEdge?: Maybe<LenderAppsEdge>;
};


/** The output of our update `LenderApp` mutation. */
export type UpdateLenderAppPayloadLenderAppEdgeArgs = {
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
};

/** All input for the `updateLenderQuoteByNodeId` mutation. */
export type UpdateLenderQuoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LenderQuote` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LenderQuote` being updated. */
  patch: LenderQuotePatch;
};

/** All input for the `updateLenderQuote` mutation. */
export type UpdateLenderQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LenderQuote` being updated. */
  patch: LenderQuotePatch;
  id: Scalars['Int'];
};

/** The output of our update `LenderQuote` mutation. */
export type UpdateLenderQuotePayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LenderQuote` that was updated by this mutation. */
  lenderQuote?: Maybe<LenderQuote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `LenderQuote`. */
  lead?: Maybe<Lead>;
  /** Reads a single `LenderApp` that is related to this `LenderQuote`. */
  lenderApp?: Maybe<LenderApp>;
  /** An edge for our `LenderQuote`. May be used by Relay 1. */
  lenderQuoteEdge?: Maybe<LenderQuotesEdge>;
};


/** The output of our update `LenderQuote` mutation. */
export type UpdateLenderQuotePayloadLenderQuoteEdgeArgs = {
  orderBy?: Maybe<Array<LenderQuotesOrderBy>>;
};

/** All input for the `updateOptionByNodeId` mutation. */
export type UpdateOptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Option` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Option` being updated. */
  patch: OptionPatch;
};

/** All input for the `updateOption` mutation. */
export type UpdateOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Option` being updated. */
  patch: OptionPatch;
  id: Scalars['Int'];
};

/** The output of our update `Option` mutation. */
export type UpdateOptionPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Option` that was updated by this mutation. */
  option?: Maybe<Option>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `FieldConfig` that is related to this `Option`. */
  fieldConfig?: Maybe<FieldConfig>;
  /** An edge for our `Option`. May be used by Relay 1. */
  optionEdge?: Maybe<OptionsEdge>;
};


/** The output of our update `Option` mutation. */
export type UpdateOptionPayloadOptionEdgeArgs = {
  orderBy?: Maybe<Array<OptionsOrderBy>>;
};

/** All input for the `updateProductIntegrationByNodeId` mutation. */
export type UpdateProductIntegrationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProductIntegration` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProductIntegration` being updated. */
  patch: ProductIntegrationPatch;
};

/** All input for the `updateProductIntegration` mutation. */
export type UpdateProductIntegrationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProductIntegration` being updated. */
  patch: ProductIntegrationPatch;
  id: Scalars['Int'];
};

/** The output of our update `ProductIntegration` mutation. */
export type UpdateProductIntegrationPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProductIntegration` that was updated by this mutation. */
  productIntegration?: Maybe<ProductIntegration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Integration` that is related to this `ProductIntegration`. */
  integration?: Maybe<Integration>;
  /** Reads a single `Product` that is related to this `ProductIntegration`. */
  product?: Maybe<Product>;
  /** An edge for our `ProductIntegration`. May be used by Relay 1. */
  productIntegrationEdge?: Maybe<ProductIntegrationsEdge>;
};


/** The output of our update `ProductIntegration` mutation. */
export type UpdateProductIntegrationPayloadProductIntegrationEdgeArgs = {
  orderBy?: Maybe<Array<ProductIntegrationsOrderBy>>;
};

/** All input for the `updateReviewByNodeId` mutation. */
export type UpdateReviewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Review` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Review` being updated. */
  patch: ReviewPatch;
};

/** All input for the `updateReview` mutation. */
export type UpdateReviewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Review` being updated. */
  patch: ReviewPatch;
  companyId: Scalars['Int'];
  authorUrl: Scalars['String'];
};

/** The output of our update `Review` mutation. */
export type UpdateReviewPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Review` that was updated by this mutation. */
  review?: Maybe<Review>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Company` that is related to this `Review`. */
  company?: Maybe<Company>;
  /** An edge for our `Review`. May be used by Relay 1. */
  reviewEdge?: Maybe<ReviewsEdge>;
};


/** The output of our update `Review` mutation. */
export type UpdateReviewPayloadReviewEdgeArgs = {
  orderBy?: Maybe<Array<ReviewsOrderBy>>;
};

/** All input for the `updateTradeAppByNodeId` mutation. */
export type UpdateTradeAppByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TradeApp` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TradeApp` being updated. */
  patch: TradeAppPatch;
};

/** All input for the `updateTradeApp` mutation. */
export type UpdateTradeAppInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TradeApp` being updated. */
  patch: TradeAppPatch;
  id: Scalars['Int'];
};

/** The output of our update `TradeApp` mutation. */
export type UpdateTradeAppPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `TradeApp` that was updated by this mutation. */
  tradeApp?: Maybe<TradeApp>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Lead` that is related to this `TradeApp`. */
  lead?: Maybe<Lead>;
  /** An edge for our `TradeApp`. May be used by Relay 1. */
  tradeAppEdge?: Maybe<TradeAppsEdge>;
};


/** The output of our update `TradeApp` mutation. */
export type UpdateTradeAppPayloadTradeAppEdgeArgs = {
  orderBy?: Maybe<Array<TradeAppsOrderBy>>;
};

/** All input for the `updateVehicleListingByNodeId` mutation. */
export type UpdateVehicleListingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VehicleListing` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VehicleListing` being updated. */
  patch: VehicleListingPatch;
};

/** All input for the `updateVehicleListing` mutation. */
export type UpdateVehicleListingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VehicleListing` being updated. */
  patch: VehicleListingPatch;
  id: Scalars['Int'];
};

/** The output of our update `VehicleListing` mutation. */
export type UpdateVehicleListingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `VehicleListing` that was updated by this mutation. */
  vehicleListing?: Maybe<VehicleListing>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `VehicleListing`. May be used by Relay 1. */
  vehicleListingEdge?: Maybe<VehicleListingsEdge>;
};


/** The output of our update `VehicleListing` mutation. */
export type UpdateVehicleListingPayloadVehicleListingEdgeArgs = {
  orderBy?: Maybe<Array<VehicleListingsOrderBy>>;
};

/** All input for the `updateWidgetByNodeId` mutation. */
export type UpdateWidgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Widget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
};

/** All input for the `updateWidgetByVariant` mutation. */
export type UpdateWidgetByVariantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
  variant: Scalars['String'];
};

/** All input for the `updateWidget` mutation. */
export type UpdateWidgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Widget` being updated. */
  patch: WidgetPatch;
  id: Scalars['String'];
};

/** The output of our update `Widget` mutation. */
export type UpdateWidgetPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Widget` that was updated by this mutation. */
  widget?: Maybe<Widget>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Widget`. May be used by Relay 1. */
  widgetEdge?: Maybe<WidgetsEdge>;
};


/** The output of our update `Widget` mutation. */
export type UpdateWidgetPayloadWidgetEdgeArgs = {
  orderBy?: Maybe<Array<WidgetsOrderBy>>;
};

/** All input for the `updateWidgetSettingByNodeId` mutation. */
export type UpdateWidgetSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WidgetSetting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `WidgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** All input for the `updateWidgetSetting` mutation. */
export type UpdateWidgetSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `WidgetSetting` being updated. */
  patch: WidgetSettingPatch;
  id: Scalars['Int'];
};

/** The output of our update `WidgetSetting` mutation. */
export type UpdateWidgetSettingPayload = {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `WidgetSetting` that was updated by this mutation. */
  widgetSetting?: Maybe<WidgetSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
  /** An edge for our `WidgetSetting`. May be used by Relay 1. */
  widgetSettingEdge?: Maybe<WidgetSettingsEdge>;
};


/** The output of our update `WidgetSetting` mutation. */
export type UpdateWidgetSettingPayloadWidgetSettingEdgeArgs = {
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
};

export type UploadAutocorpIdImageInput = {
  leadId: Scalars['UUID'];
  image: IdImageInput;
  imageType: IdImageType;
};

export type UploadIdVerificationImagesInput = {
  leadId: Scalars['UUID'];
  clientToken: Scalars['String'];
  images?: Maybe<Scalars['JSON']>;
};

export type UploadIdVerificationImagesResponse = {
  success?: Maybe<Scalars['Boolean']>;
  issueDetails?: Maybe<Scalars['JSON']>;
  issues?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextStep?: Maybe<Scalars['String']>;
};

/** A connection to a list of `UserActivity` values. */
export type UserActivitiesConnection = {
  /** A list of `UserActivity` objects. */
  nodes: Array<UserActivity>;
  /** A list of edges which contains the `UserActivity` and cursor to aid in pagination. */
  edges: Array<UserActivitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserActivity` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserActivityAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserActivityAggregates>>;
};


/** A connection to a list of `UserActivity` values. */
export type UserActivitiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserActivityGroupBy>;
  having?: Maybe<UserActivityHavingInput>;
};

/** A `UserActivity` edge in the connection. */
export type UserActivitiesEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserActivity` at the end of the edge. */
  node: UserActivity;
};

/** Methods to use when ordering `UserActivity`. */
export enum UserActivitiesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  ClaimsAsc = 'CLAIMS_ASC',
  ClaimsDesc = 'CLAIMS_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SearchTextRankAsc = 'SEARCH_TEXT_RANK_ASC',
  SearchTextRankDesc = 'SEARCH_TEXT_RANK_DESC'
}

export type UserActivity = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  username: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  searchText?: Maybe<Scalars['FullText']>;
  /** Full-text search ranking when filtered by `searchText`. */
  searchTextRank?: Maybe<Scalars['Float']>;
};

export type UserActivityAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UserActivitySumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserActivityDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UserActivityMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UserActivityMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UserActivityAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UserActivityStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UserActivityStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UserActivityVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UserActivityVariancePopulationAggregates>;
};

export type UserActivityAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UserActivity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserActivityCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `claims` field. */
  claims?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText?: Maybe<Scalars['FullText']>;
};

export type UserActivityDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars['BigInt']>;
  /** Distinct count of claims across the matching connection */
  claims?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meta across the matching connection */
  meta?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of searchText across the matching connection */
  searchText?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UserActivity` object types. All fields are combined with a logical ‘and.’ */
export type UserActivityFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `claims` field. */
  claims?: Maybe<StringListFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText?: Maybe<FullTextFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserActivityFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserActivityFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserActivityFilter>;
};

/** Grouping methods for `UserActivity` for usage during aggregation. */
export enum UserActivityGroupBy {
  Username = 'USERNAME',
  Claims = 'CLAIMS',
  Meta = 'META',
  Type = 'TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  SearchText = 'SEARCH_TEXT'
}

export type UserActivityHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `UserActivity` aggregates. */
export type UserActivityHavingInput = {
  AND?: Maybe<Array<UserActivityHavingInput>>;
  OR?: Maybe<Array<UserActivityHavingInput>>;
  sum?: Maybe<UserActivityHavingSumInput>;
  distinctCount?: Maybe<UserActivityHavingDistinctCountInput>;
  min?: Maybe<UserActivityHavingMinInput>;
  max?: Maybe<UserActivityHavingMaxInput>;
  average?: Maybe<UserActivityHavingAverageInput>;
  stddevSample?: Maybe<UserActivityHavingStddevSampleInput>;
  stddevPopulation?: Maybe<UserActivityHavingStddevPopulationInput>;
  varianceSample?: Maybe<UserActivityHavingVarianceSampleInput>;
  variancePopulation?: Maybe<UserActivityHavingVariancePopulationInput>;
};

export type UserActivityHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type UserActivityHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `UserActivity` */
export type UserActivityInput = {
  id?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  claims: Array<Maybe<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
};

export type UserActivityMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type UserActivityMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type UserActivityStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UserActivityStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UserActivitySumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type UserActivityVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UserActivityVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UserDetails = {
  nodeId: Scalars['String'];
  groupId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  email: Scalars['String'];
  role: UserRole;
  status: UserStatus;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
};

export type UserNotificationInput = {
  emailAddress: Scalars['String'];
  companyId: Scalars['Int'];
  type: UserNotificationType;
};

export type UserNotificationResponse = {
  success: Scalars['Boolean'];
};

export enum UserNotificationType {
  OfferAccepted = 'OfferAccepted'
}

export type UserPermissions = {
  autocorp: AutocorpPermissions;
  companies: Array<CompanyPermissions>;
};

export type UserResponse = {
  success?: Maybe<Scalars['Boolean']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export enum UserStatus {
  Disabled = 'DISABLED',
  Pending = 'PENDING',
  Ready = 'READY'
}

export type ValidateCreditAppApplicationResponse = {
  creditorId: Scalars['String'];
  applicationId: Scalars['String'];
  status: Status;
  advancedStatus: AdvancedStatus;
  failureReasons: Array<Scalars['String']>;
  advancedFailureReasons: Array<Scalars['String']>;
};

export enum VehicleCondition {
  Excellent = 'EXCELLENT',
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Fair = 'FAIR'
}

/** A filter to be used against VehicleCondition fields. All fields are combined with a logical ‘and.’ */
export type VehicleConditionFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<VehicleCondition>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<VehicleCondition>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<VehicleCondition>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<VehicleCondition>;
  /** Included in the specified list. */
  in?: Maybe<Array<VehicleCondition>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<VehicleCondition>>;
  /** Less than the specified value. */
  lessThan?: Maybe<VehicleCondition>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<VehicleCondition>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<VehicleCondition>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<VehicleCondition>;
};

export type VehicleListing = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByLenderAppVehicleListingIdAndLeadId: VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Lead`. */
  leadsByAppointmentVehicleListingIdAndLeadId: VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnection;
};


export type VehicleListingLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};


export type VehicleListingAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};


export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};


export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LeadsOrderBy>>;
  condition?: Maybe<LeadCondition>;
  filter?: Maybe<LeadFilter>;
};

export type VehicleListingAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<VehicleListingSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<VehicleListingDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<VehicleListingMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<VehicleListingMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<VehicleListingAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<VehicleListingStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<VehicleListingStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<VehicleListingVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<VehicleListingVariancePopulationAggregates>;
};

export type VehicleListingAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Mean average of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `VehicleListing` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VehicleListingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `listingMsrp` field. */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `listingPrice` field. */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `listingUrl` field. */
  listingUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `listingTitle` field. */
  listingTitle?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleVin` field. */
  vehicleVin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleYear` field. */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vehicleMake` field. */
  vehicleMake?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleModel` field. */
  vehicleModel?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleTrim` field. */
  vehicleTrim?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleMileage` field. */
  vehicleMileage?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `vehicleCondition` field. */
  vehicleCondition?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `listingImageUrl` field. */
  listingImageUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleEngine` field. */
  vehicleEngine?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleTransmission` field. */
  vehicleTransmission?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleExteriorColor` field. */
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleInteriorColor` field. */
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleStatus` field. */
  vehicleStatus?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `vehicleStockNumber` field. */
  vehicleStockNumber?: Maybe<Scalars['String']>;
};

export type VehicleListingDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingUrl across the matching connection */
  listingUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingTitle across the matching connection */
  listingTitle?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleVin across the matching connection */
  vehicleVin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleMake across the matching connection */
  vehicleMake?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleModel across the matching connection */
  vehicleModel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleTrim across the matching connection */
  vehicleTrim?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleCondition across the matching connection */
  vehicleCondition?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of listingImageUrl across the matching connection */
  listingImageUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleEngine across the matching connection */
  vehicleEngine?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleTransmission across the matching connection */
  vehicleTransmission?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleExteriorColor across the matching connection */
  vehicleExteriorColor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleInteriorColor across the matching connection */
  vehicleInteriorColor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleStatus across the matching connection */
  vehicleStatus?: Maybe<Scalars['BigInt']>;
  /** Distinct count of vehicleStockNumber across the matching connection */
  vehicleStockNumber?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `VehicleListing` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `listingMsrp` field. */
  listingMsrp?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `listingPrice` field. */
  listingPrice?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `listingUrl` field. */
  listingUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `listingTitle` field. */
  listingTitle?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleVin` field. */
  vehicleVin?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleYear` field. */
  vehicleYear?: Maybe<IntFilter>;
  /** Filter by the object’s `vehicleMake` field. */
  vehicleMake?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleModel` field. */
  vehicleModel?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleTrim` field. */
  vehicleTrim?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleMileage` field. */
  vehicleMileage?: Maybe<IntFilter>;
  /** Filter by the object’s `vehicleCondition` field. */
  vehicleCondition?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `listingImageUrl` field. */
  listingImageUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleEngine` field. */
  vehicleEngine?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleTransmission` field. */
  vehicleTransmission?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleExteriorColor` field. */
  vehicleExteriorColor?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleInteriorColor` field. */
  vehicleInteriorColor?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleStatus` field. */
  vehicleStatus?: Maybe<StringFilter>;
  /** Filter by the object’s `vehicleStockNumber` field. */
  vehicleStockNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `lenderApps` relation. */
  lenderApps?: Maybe<VehicleListingToManyLenderAppFilter>;
  /** Some related `lenderApps` exist. */
  lenderAppsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `appointments` relation. */
  appointments?: Maybe<VehicleListingToManyAppointmentFilter>;
  /** Some related `appointments` exist. */
  appointmentsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<VehicleListingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<VehicleListingFilter>>;
  /** Negates the expression. */
  not?: Maybe<VehicleListingFilter>;
};

/** Grouping methods for `VehicleListing` for usage during aggregation. */
export enum VehicleListingGroupBy {
  ListingMsrp = 'LISTING_MSRP',
  ListingPrice = 'LISTING_PRICE',
  ListingUrl = 'LISTING_URL',
  ListingTitle = 'LISTING_TITLE',
  VehicleVin = 'VEHICLE_VIN',
  VehicleYear = 'VEHICLE_YEAR',
  VehicleMake = 'VEHICLE_MAKE',
  VehicleModel = 'VEHICLE_MODEL',
  VehicleTrim = 'VEHICLE_TRIM',
  VehicleMileage = 'VEHICLE_MILEAGE',
  VehicleCondition = 'VEHICLE_CONDITION',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  ListingImageUrl = 'LISTING_IMAGE_URL',
  VehicleEngine = 'VEHICLE_ENGINE',
  VehicleTransmission = 'VEHICLE_TRANSMISSION',
  VehicleExteriorColor = 'VEHICLE_EXTERIOR_COLOR',
  VehicleInteriorColor = 'VEHICLE_INTERIOR_COLOR',
  VehicleStatus = 'VEHICLE_STATUS',
  VehicleStockNumber = 'VEHICLE_STOCK_NUMBER'
}

export type VehicleListingHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `VehicleListing` aggregates. */
export type VehicleListingHavingInput = {
  AND?: Maybe<Array<VehicleListingHavingInput>>;
  OR?: Maybe<Array<VehicleListingHavingInput>>;
  sum?: Maybe<VehicleListingHavingSumInput>;
  distinctCount?: Maybe<VehicleListingHavingDistinctCountInput>;
  min?: Maybe<VehicleListingHavingMinInput>;
  max?: Maybe<VehicleListingHavingMaxInput>;
  average?: Maybe<VehicleListingHavingAverageInput>;
  stddevSample?: Maybe<VehicleListingHavingStddevSampleInput>;
  stddevPopulation?: Maybe<VehicleListingHavingStddevPopulationInput>;
  varianceSample?: Maybe<VehicleListingHavingVarianceSampleInput>;
  variancePopulation?: Maybe<VehicleListingHavingVariancePopulationInput>;
};

export type VehicleListingHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type VehicleListingHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  listingMsrp?: Maybe<HavingBigfloatFilter>;
  listingPrice?: Maybe<HavingBigfloatFilter>;
  vehicleYear?: Maybe<HavingIntFilter>;
  vehicleMileage?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `VehicleListing` */
export type VehicleListingInput = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** A connection to a list of `Lead` values, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `Appointment`, and the cursor to aid in pagination. */
  edges: Array<VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `Appointment`. */
  appointments: AppointmentsConnection;
};


/** A `Lead` edge in the connection, with data from `Appointment`. */
export type VehicleListingLeadsByAppointmentVehicleListingIdAndLeadIdManyToManyEdgeAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AppointmentsOrderBy>>;
  condition?: Maybe<AppointmentCondition>;
  filter?: Maybe<AppointmentFilter>;
};

/** A connection to a list of `Lead` values, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnection = {
  /** A list of `Lead` objects. */
  nodes: Array<Lead>;
  /** A list of edges which contains the `Lead`, info from the `LenderApp`, and the cursor to aid in pagination. */
  edges: Array<VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lead` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LeadAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LeadAggregates>>;
};


/** A connection to a list of `Lead` values, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<LeadGroupBy>;
  having?: Maybe<LeadHavingInput>;
};

/** A `Lead` edge in the connection, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Lead` at the end of the edge. */
  node: Lead;
  /** Reads and enables pagination through a set of `LenderApp`. */
  lenderApps: LenderAppsConnection;
};


/** A `Lead` edge in the connection, with data from `LenderApp`. */
export type VehicleListingLeadsByLenderAppVehicleListingIdAndLeadIdManyToManyEdgeLenderAppsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LenderAppsOrderBy>>;
  condition?: Maybe<LenderAppCondition>;
  filter?: Maybe<LenderAppFilter>;
};

export type VehicleListingMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Maximum of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Maximum of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Maximum of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['Int']>;
};

export type VehicleListingMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Minimum of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Minimum of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['Int']>;
  /** Minimum of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VehicleListingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VehicleListingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `vehicleListing` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `appointment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `appointment` being updated. */
  patch: AppointmentPatch;
};

/** The fields on `vehicleListing` to look up the row to update. */
export type VehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyUsingVehicleListingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: UpdateVehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lenderApp` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lenderApp` being updated. */
  patch: LenderAppPatch;
};

/** The fields on `vehicleListing` to look up the row to update. */
export type VehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyUsingVehicleListingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `vehicleListing` being updated. */
  patch: UpdateVehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `VehicleListing`. Fields that are set will be updated. */
export type VehicleListingPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

export type VehicleListingStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of listingMsrp across the matching connection */
  listingMsrp: Scalars['BigFloat'];
  /** Sum of listingPrice across the matching connection */
  listingPrice: Scalars['BigFloat'];
  /** Sum of vehicleYear across the matching connection */
  vehicleYear: Scalars['BigInt'];
  /** Sum of vehicleMileage across the matching connection */
  vehicleMileage: Scalars['BigInt'];
};

/** A filter to be used against many `Appointment` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingToManyAppointmentFilter = {
  /** Every related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AppointmentFilter>;
  /** Some related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AppointmentFilter>;
  /** No related `Appointment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AppointmentFilter>;
  /** Aggregates across related `Appointment` match the filter criteria. */
  aggregates?: Maybe<AppointmentAggregatesFilter>;
};

/** A filter to be used against many `LenderApp` object types. All fields are combined with a logical ‘and.’ */
export type VehicleListingToManyLenderAppFilter = {
  /** Every related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LenderAppFilter>;
  /** Some related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LenderAppFilter>;
  /** No related `LenderApp` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LenderAppFilter>;
  /** Aggregates across related `LenderApp` match the filter criteria. */
  aggregates?: Maybe<LenderAppAggregatesFilter>;
};

export type VehicleListingVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Population variance of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

export type VehicleListingVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of listingMsrp across the matching connection */
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of listingPrice across the matching connection */
  listingPrice?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleYear across the matching connection */
  vehicleYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of vehicleMileage across the matching connection */
  vehicleMileage?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `vehicleListing` to look up the row to connect. */
export type VehicleListingVehicleListingsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `vehicleListing` to look up the row to delete. */
export type VehicleListingVehicleListingsPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `VehicleListing` values. */
export type VehicleListingsConnection = {
  /** A list of `VehicleListing` objects. */
  nodes: Array<VehicleListing>;
  /** A list of edges which contains the `VehicleListing` and cursor to aid in pagination. */
  edges: Array<VehicleListingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VehicleListing` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<VehicleListingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<VehicleListingAggregates>>;
};


/** A connection to a list of `VehicleListing` values. */
export type VehicleListingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<VehicleListingGroupBy>;
  having?: Maybe<VehicleListingHavingInput>;
};

/** A `VehicleListing` edge in the connection. */
export type VehicleListingsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VehicleListing` at the end of the edge. */
  node: VehicleListing;
};

/** Methods to use when ordering `VehicleListing`. */
export enum VehicleListingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ListingMsrpAsc = 'LISTING_MSRP_ASC',
  ListingMsrpDesc = 'LISTING_MSRP_DESC',
  ListingPriceAsc = 'LISTING_PRICE_ASC',
  ListingPriceDesc = 'LISTING_PRICE_DESC',
  ListingUrlAsc = 'LISTING_URL_ASC',
  ListingUrlDesc = 'LISTING_URL_DESC',
  ListingTitleAsc = 'LISTING_TITLE_ASC',
  ListingTitleDesc = 'LISTING_TITLE_DESC',
  VehicleVinAsc = 'VEHICLE_VIN_ASC',
  VehicleVinDesc = 'VEHICLE_VIN_DESC',
  VehicleYearAsc = 'VEHICLE_YEAR_ASC',
  VehicleYearDesc = 'VEHICLE_YEAR_DESC',
  VehicleMakeAsc = 'VEHICLE_MAKE_ASC',
  VehicleMakeDesc = 'VEHICLE_MAKE_DESC',
  VehicleModelAsc = 'VEHICLE_MODEL_ASC',
  VehicleModelDesc = 'VEHICLE_MODEL_DESC',
  VehicleTrimAsc = 'VEHICLE_TRIM_ASC',
  VehicleTrimDesc = 'VEHICLE_TRIM_DESC',
  VehicleMileageAsc = 'VEHICLE_MILEAGE_ASC',
  VehicleMileageDesc = 'VEHICLE_MILEAGE_DESC',
  VehicleConditionAsc = 'VEHICLE_CONDITION_ASC',
  VehicleConditionDesc = 'VEHICLE_CONDITION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ListingImageUrlAsc = 'LISTING_IMAGE_URL_ASC',
  ListingImageUrlDesc = 'LISTING_IMAGE_URL_DESC',
  VehicleEngineAsc = 'VEHICLE_ENGINE_ASC',
  VehicleEngineDesc = 'VEHICLE_ENGINE_DESC',
  VehicleTransmissionAsc = 'VEHICLE_TRANSMISSION_ASC',
  VehicleTransmissionDesc = 'VEHICLE_TRANSMISSION_DESC',
  VehicleExteriorColorAsc = 'VEHICLE_EXTERIOR_COLOR_ASC',
  VehicleExteriorColorDesc = 'VEHICLE_EXTERIOR_COLOR_DESC',
  VehicleInteriorColorAsc = 'VEHICLE_INTERIOR_COLOR_ASC',
  VehicleInteriorColorDesc = 'VEHICLE_INTERIOR_COLOR_DESC',
  VehicleStatusAsc = 'VEHICLE_STATUS_ASC',
  VehicleStatusDesc = 'VEHICLE_STATUS_DESC',
  VehicleStockNumberAsc = 'VEHICLE_STOCK_NUMBER_ASC',
  VehicleStockNumberDesc = 'VEHICLE_STOCK_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  LenderAppsCountAsc = 'LENDER_APPS_COUNT_ASC',
  LenderAppsCountDesc = 'LENDER_APPS_COUNT_DESC',
  LenderAppsSumIdAsc = 'LENDER_APPS_SUM_ID_ASC',
  LenderAppsSumIdDesc = 'LENDER_APPS_SUM_ID_DESC',
  LenderAppsSumLeadIdAsc = 'LENDER_APPS_SUM_LEAD_ID_ASC',
  LenderAppsSumLeadIdDesc = 'LENDER_APPS_SUM_LEAD_ID_DESC',
  LenderAppsSumInovatecApplicationNumberAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsSumInovatecApplicationNumberDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsSumInovatecApplicationIdAsc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsSumInovatecApplicationIdDesc = 'LENDER_APPS_SUM_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsSumYearlyIncomeAsc = 'LENDER_APPS_SUM_YEARLY_INCOME_ASC',
  LenderAppsSumYearlyIncomeDesc = 'LENDER_APPS_SUM_YEARLY_INCOME_DESC',
  LenderAppsSumEmploymentStatusAsc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_ASC',
  LenderAppsSumEmploymentStatusDesc = 'LENDER_APPS_SUM_EMPLOYMENT_STATUS_DESC',
  LenderAppsSumMonthlyHomePaymentAsc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsSumMonthlyHomePaymentDesc = 'LENDER_APPS_SUM_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsSumPreferredOfferIdAsc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_ASC',
  LenderAppsSumPreferredOfferIdDesc = 'LENDER_APPS_SUM_PREFERRED_OFFER_ID_DESC',
  LenderAppsSumOffersAsc = 'LENDER_APPS_SUM_OFFERS_ASC',
  LenderAppsSumOffersDesc = 'LENDER_APPS_SUM_OFFERS_DESC',
  LenderAppsSumCreatedAtAsc = 'LENDER_APPS_SUM_CREATED_AT_ASC',
  LenderAppsSumCreatedAtDesc = 'LENDER_APPS_SUM_CREATED_AT_DESC',
  LenderAppsSumUpdatedAtAsc = 'LENDER_APPS_SUM_UPDATED_AT_ASC',
  LenderAppsSumUpdatedAtDesc = 'LENDER_APPS_SUM_UPDATED_AT_DESC',
  LenderAppsSumVehicleListingIdAsc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_ASC',
  LenderAppsSumVehicleListingIdDesc = 'LENDER_APPS_SUM_VEHICLE_LISTING_ID_DESC',
  LenderAppsSumMaritalStatusAsc = 'LENDER_APPS_SUM_MARITAL_STATUS_ASC',
  LenderAppsSumMaritalStatusDesc = 'LENDER_APPS_SUM_MARITAL_STATUS_DESC',
  LenderAppsDistinctCountIdAsc = 'LENDER_APPS_DISTINCT_COUNT_ID_ASC',
  LenderAppsDistinctCountIdDesc = 'LENDER_APPS_DISTINCT_COUNT_ID_DESC',
  LenderAppsDistinctCountLeadIdAsc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_ASC',
  LenderAppsDistinctCountLeadIdDesc = 'LENDER_APPS_DISTINCT_COUNT_LEAD_ID_DESC',
  LenderAppsDistinctCountInovatecApplicationNumberAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsDistinctCountInovatecApplicationNumberDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsDistinctCountInovatecApplicationIdAsc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsDistinctCountInovatecApplicationIdDesc = 'LENDER_APPS_DISTINCT_COUNT_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsDistinctCountYearlyIncomeAsc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_ASC',
  LenderAppsDistinctCountYearlyIncomeDesc = 'LENDER_APPS_DISTINCT_COUNT_YEARLY_INCOME_DESC',
  LenderAppsDistinctCountEmploymentStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_ASC',
  LenderAppsDistinctCountEmploymentStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_EMPLOYMENT_STATUS_DESC',
  LenderAppsDistinctCountMonthlyHomePaymentAsc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsDistinctCountMonthlyHomePaymentDesc = 'LENDER_APPS_DISTINCT_COUNT_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsDistinctCountPreferredOfferIdAsc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_ASC',
  LenderAppsDistinctCountPreferredOfferIdDesc = 'LENDER_APPS_DISTINCT_COUNT_PREFERRED_OFFER_ID_DESC',
  LenderAppsDistinctCountOffersAsc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_ASC',
  LenderAppsDistinctCountOffersDesc = 'LENDER_APPS_DISTINCT_COUNT_OFFERS_DESC',
  LenderAppsDistinctCountCreatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_ASC',
  LenderAppsDistinctCountCreatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_CREATED_AT_DESC',
  LenderAppsDistinctCountUpdatedAtAsc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LenderAppsDistinctCountUpdatedAtDesc = 'LENDER_APPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LenderAppsDistinctCountVehicleListingIdAsc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  LenderAppsDistinctCountVehicleListingIdDesc = 'LENDER_APPS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  LenderAppsDistinctCountMaritalStatusAsc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_ASC',
  LenderAppsDistinctCountMaritalStatusDesc = 'LENDER_APPS_DISTINCT_COUNT_MARITAL_STATUS_DESC',
  LenderAppsMinIdAsc = 'LENDER_APPS_MIN_ID_ASC',
  LenderAppsMinIdDesc = 'LENDER_APPS_MIN_ID_DESC',
  LenderAppsMinLeadIdAsc = 'LENDER_APPS_MIN_LEAD_ID_ASC',
  LenderAppsMinLeadIdDesc = 'LENDER_APPS_MIN_LEAD_ID_DESC',
  LenderAppsMinInovatecApplicationNumberAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMinInovatecApplicationNumberDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMinInovatecApplicationIdAsc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMinInovatecApplicationIdDesc = 'LENDER_APPS_MIN_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMinYearlyIncomeAsc = 'LENDER_APPS_MIN_YEARLY_INCOME_ASC',
  LenderAppsMinYearlyIncomeDesc = 'LENDER_APPS_MIN_YEARLY_INCOME_DESC',
  LenderAppsMinEmploymentStatusAsc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_ASC',
  LenderAppsMinEmploymentStatusDesc = 'LENDER_APPS_MIN_EMPLOYMENT_STATUS_DESC',
  LenderAppsMinMonthlyHomePaymentAsc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMinMonthlyHomePaymentDesc = 'LENDER_APPS_MIN_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMinPreferredOfferIdAsc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_ASC',
  LenderAppsMinPreferredOfferIdDesc = 'LENDER_APPS_MIN_PREFERRED_OFFER_ID_DESC',
  LenderAppsMinOffersAsc = 'LENDER_APPS_MIN_OFFERS_ASC',
  LenderAppsMinOffersDesc = 'LENDER_APPS_MIN_OFFERS_DESC',
  LenderAppsMinCreatedAtAsc = 'LENDER_APPS_MIN_CREATED_AT_ASC',
  LenderAppsMinCreatedAtDesc = 'LENDER_APPS_MIN_CREATED_AT_DESC',
  LenderAppsMinUpdatedAtAsc = 'LENDER_APPS_MIN_UPDATED_AT_ASC',
  LenderAppsMinUpdatedAtDesc = 'LENDER_APPS_MIN_UPDATED_AT_DESC',
  LenderAppsMinVehicleListingIdAsc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_ASC',
  LenderAppsMinVehicleListingIdDesc = 'LENDER_APPS_MIN_VEHICLE_LISTING_ID_DESC',
  LenderAppsMinMaritalStatusAsc = 'LENDER_APPS_MIN_MARITAL_STATUS_ASC',
  LenderAppsMinMaritalStatusDesc = 'LENDER_APPS_MIN_MARITAL_STATUS_DESC',
  LenderAppsMaxIdAsc = 'LENDER_APPS_MAX_ID_ASC',
  LenderAppsMaxIdDesc = 'LENDER_APPS_MAX_ID_DESC',
  LenderAppsMaxLeadIdAsc = 'LENDER_APPS_MAX_LEAD_ID_ASC',
  LenderAppsMaxLeadIdDesc = 'LENDER_APPS_MAX_LEAD_ID_DESC',
  LenderAppsMaxInovatecApplicationNumberAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsMaxInovatecApplicationNumberDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsMaxInovatecApplicationIdAsc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsMaxInovatecApplicationIdDesc = 'LENDER_APPS_MAX_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsMaxYearlyIncomeAsc = 'LENDER_APPS_MAX_YEARLY_INCOME_ASC',
  LenderAppsMaxYearlyIncomeDesc = 'LENDER_APPS_MAX_YEARLY_INCOME_DESC',
  LenderAppsMaxEmploymentStatusAsc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_ASC',
  LenderAppsMaxEmploymentStatusDesc = 'LENDER_APPS_MAX_EMPLOYMENT_STATUS_DESC',
  LenderAppsMaxMonthlyHomePaymentAsc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsMaxMonthlyHomePaymentDesc = 'LENDER_APPS_MAX_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsMaxPreferredOfferIdAsc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_ASC',
  LenderAppsMaxPreferredOfferIdDesc = 'LENDER_APPS_MAX_PREFERRED_OFFER_ID_DESC',
  LenderAppsMaxOffersAsc = 'LENDER_APPS_MAX_OFFERS_ASC',
  LenderAppsMaxOffersDesc = 'LENDER_APPS_MAX_OFFERS_DESC',
  LenderAppsMaxCreatedAtAsc = 'LENDER_APPS_MAX_CREATED_AT_ASC',
  LenderAppsMaxCreatedAtDesc = 'LENDER_APPS_MAX_CREATED_AT_DESC',
  LenderAppsMaxUpdatedAtAsc = 'LENDER_APPS_MAX_UPDATED_AT_ASC',
  LenderAppsMaxUpdatedAtDesc = 'LENDER_APPS_MAX_UPDATED_AT_DESC',
  LenderAppsMaxVehicleListingIdAsc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_ASC',
  LenderAppsMaxVehicleListingIdDesc = 'LENDER_APPS_MAX_VEHICLE_LISTING_ID_DESC',
  LenderAppsMaxMaritalStatusAsc = 'LENDER_APPS_MAX_MARITAL_STATUS_ASC',
  LenderAppsMaxMaritalStatusDesc = 'LENDER_APPS_MAX_MARITAL_STATUS_DESC',
  LenderAppsAverageIdAsc = 'LENDER_APPS_AVERAGE_ID_ASC',
  LenderAppsAverageIdDesc = 'LENDER_APPS_AVERAGE_ID_DESC',
  LenderAppsAverageLeadIdAsc = 'LENDER_APPS_AVERAGE_LEAD_ID_ASC',
  LenderAppsAverageLeadIdDesc = 'LENDER_APPS_AVERAGE_LEAD_ID_DESC',
  LenderAppsAverageInovatecApplicationNumberAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsAverageInovatecApplicationNumberDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsAverageInovatecApplicationIdAsc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsAverageInovatecApplicationIdDesc = 'LENDER_APPS_AVERAGE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsAverageYearlyIncomeAsc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_ASC',
  LenderAppsAverageYearlyIncomeDesc = 'LENDER_APPS_AVERAGE_YEARLY_INCOME_DESC',
  LenderAppsAverageEmploymentStatusAsc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_ASC',
  LenderAppsAverageEmploymentStatusDesc = 'LENDER_APPS_AVERAGE_EMPLOYMENT_STATUS_DESC',
  LenderAppsAverageMonthlyHomePaymentAsc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsAverageMonthlyHomePaymentDesc = 'LENDER_APPS_AVERAGE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsAveragePreferredOfferIdAsc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_ASC',
  LenderAppsAveragePreferredOfferIdDesc = 'LENDER_APPS_AVERAGE_PREFERRED_OFFER_ID_DESC',
  LenderAppsAverageOffersAsc = 'LENDER_APPS_AVERAGE_OFFERS_ASC',
  LenderAppsAverageOffersDesc = 'LENDER_APPS_AVERAGE_OFFERS_DESC',
  LenderAppsAverageCreatedAtAsc = 'LENDER_APPS_AVERAGE_CREATED_AT_ASC',
  LenderAppsAverageCreatedAtDesc = 'LENDER_APPS_AVERAGE_CREATED_AT_DESC',
  LenderAppsAverageUpdatedAtAsc = 'LENDER_APPS_AVERAGE_UPDATED_AT_ASC',
  LenderAppsAverageUpdatedAtDesc = 'LENDER_APPS_AVERAGE_UPDATED_AT_DESC',
  LenderAppsAverageVehicleListingIdAsc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  LenderAppsAverageVehicleListingIdDesc = 'LENDER_APPS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  LenderAppsAverageMaritalStatusAsc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_ASC',
  LenderAppsAverageMaritalStatusDesc = 'LENDER_APPS_AVERAGE_MARITAL_STATUS_DESC',
  LenderAppsStddevSampleIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_ID_ASC',
  LenderAppsStddevSampleIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_ID_DESC',
  LenderAppsStddevSampleLeadIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_ASC',
  LenderAppsStddevSampleLeadIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_LEAD_ID_DESC',
  LenderAppsStddevSampleInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevSampleInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevSampleInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevSampleInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevSampleYearlyIncomeAsc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsStddevSampleYearlyIncomeDesc = 'LENDER_APPS_STDDEV_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsStddevSampleEmploymentStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevSampleEmploymentStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevSampleMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevSampleMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevSamplePreferredOfferIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevSamplePreferredOfferIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevSampleOffersAsc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_ASC',
  LenderAppsStddevSampleOffersDesc = 'LENDER_APPS_STDDEV_SAMPLE_OFFERS_DESC',
  LenderAppsStddevSampleCreatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LenderAppsStddevSampleCreatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LenderAppsStddevSampleUpdatedAtAsc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LenderAppsStddevSampleUpdatedAtDesc = 'LENDER_APPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LenderAppsStddevSampleVehicleListingIdAsc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevSampleVehicleListingIdDesc = 'LENDER_APPS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevSampleMaritalStatusAsc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsStddevSampleMaritalStatusDesc = 'LENDER_APPS_STDDEV_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsStddevPopulationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_ID_ASC',
  LenderAppsStddevPopulationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_ID_DESC',
  LenderAppsStddevPopulationLeadIdAsc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_ASC',
  LenderAppsStddevPopulationLeadIdDesc = 'LENDER_APPS_STDDEV_POPULATION_LEAD_ID_DESC',
  LenderAppsStddevPopulationInovatecApplicationNumberAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsStddevPopulationInovatecApplicationNumberDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsStddevPopulationInovatecApplicationIdAsc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsStddevPopulationInovatecApplicationIdDesc = 'LENDER_APPS_STDDEV_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsStddevPopulationYearlyIncomeAsc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsStddevPopulationYearlyIncomeDesc = 'LENDER_APPS_STDDEV_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsStddevPopulationEmploymentStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsStddevPopulationEmploymentStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsStddevPopulationMonthlyHomePaymentAsc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsStddevPopulationMonthlyHomePaymentDesc = 'LENDER_APPS_STDDEV_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsStddevPopulationPreferredOfferIdAsc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsStddevPopulationPreferredOfferIdDesc = 'LENDER_APPS_STDDEV_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsStddevPopulationOffersAsc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_ASC',
  LenderAppsStddevPopulationOffersDesc = 'LENDER_APPS_STDDEV_POPULATION_OFFERS_DESC',
  LenderAppsStddevPopulationCreatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_ASC',
  LenderAppsStddevPopulationCreatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_CREATED_AT_DESC',
  LenderAppsStddevPopulationUpdatedAtAsc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LenderAppsStddevPopulationUpdatedAtDesc = 'LENDER_APPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LenderAppsStddevPopulationVehicleListingIdAsc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsStddevPopulationVehicleListingIdDesc = 'LENDER_APPS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsStddevPopulationMaritalStatusAsc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsStddevPopulationMaritalStatusDesc = 'LENDER_APPS_STDDEV_POPULATION_MARITAL_STATUS_DESC',
  LenderAppsVarianceSampleIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_ASC',
  LenderAppsVarianceSampleIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_ID_DESC',
  LenderAppsVarianceSampleLeadIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  LenderAppsVarianceSampleLeadIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  LenderAppsVarianceSampleInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVarianceSampleInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVarianceSampleInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVarianceSampleInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVarianceSampleYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_ASC',
  LenderAppsVarianceSampleYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_SAMPLE_YEARLY_INCOME_DESC',
  LenderAppsVarianceSampleEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_ASC',
  LenderAppsVarianceSampleEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_EMPLOYMENT_STATUS_DESC',
  LenderAppsVarianceSampleMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVarianceSampleMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVarianceSamplePreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_ASC',
  LenderAppsVarianceSamplePreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_PREFERRED_OFFER_ID_DESC',
  LenderAppsVarianceSampleOffersAsc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_ASC',
  LenderAppsVarianceSampleOffersDesc = 'LENDER_APPS_VARIANCE_SAMPLE_OFFERS_DESC',
  LenderAppsVarianceSampleCreatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LenderAppsVarianceSampleCreatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LenderAppsVarianceSampleUpdatedAtAsc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LenderAppsVarianceSampleUpdatedAtDesc = 'LENDER_APPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LenderAppsVarianceSampleVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  LenderAppsVarianceSampleVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  LenderAppsVarianceSampleMaritalStatusAsc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_ASC',
  LenderAppsVarianceSampleMaritalStatusDesc = 'LENDER_APPS_VARIANCE_SAMPLE_MARITAL_STATUS_DESC',
  LenderAppsVariancePopulationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_ID_ASC',
  LenderAppsVariancePopulationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_ID_DESC',
  LenderAppsVariancePopulationLeadIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_ASC',
  LenderAppsVariancePopulationLeadIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_LEAD_ID_DESC',
  LenderAppsVariancePopulationInovatecApplicationNumberAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_ASC',
  LenderAppsVariancePopulationInovatecApplicationNumberDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_NUMBER_DESC',
  LenderAppsVariancePopulationInovatecApplicationIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_ASC',
  LenderAppsVariancePopulationInovatecApplicationIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_INOVATEC_APPLICATION_ID_DESC',
  LenderAppsVariancePopulationYearlyIncomeAsc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_ASC',
  LenderAppsVariancePopulationYearlyIncomeDesc = 'LENDER_APPS_VARIANCE_POPULATION_YEARLY_INCOME_DESC',
  LenderAppsVariancePopulationEmploymentStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_ASC',
  LenderAppsVariancePopulationEmploymentStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_EMPLOYMENT_STATUS_DESC',
  LenderAppsVariancePopulationMonthlyHomePaymentAsc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_ASC',
  LenderAppsVariancePopulationMonthlyHomePaymentDesc = 'LENDER_APPS_VARIANCE_POPULATION_MONTHLY_HOME_PAYMENT_DESC',
  LenderAppsVariancePopulationPreferredOfferIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_ASC',
  LenderAppsVariancePopulationPreferredOfferIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_PREFERRED_OFFER_ID_DESC',
  LenderAppsVariancePopulationOffersAsc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_ASC',
  LenderAppsVariancePopulationOffersDesc = 'LENDER_APPS_VARIANCE_POPULATION_OFFERS_DESC',
  LenderAppsVariancePopulationCreatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LenderAppsVariancePopulationCreatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LenderAppsVariancePopulationUpdatedAtAsc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LenderAppsVariancePopulationUpdatedAtDesc = 'LENDER_APPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LenderAppsVariancePopulationVehicleListingIdAsc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  LenderAppsVariancePopulationVehicleListingIdDesc = 'LENDER_APPS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  LenderAppsVariancePopulationMaritalStatusAsc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_ASC',
  LenderAppsVariancePopulationMaritalStatusDesc = 'LENDER_APPS_VARIANCE_POPULATION_MARITAL_STATUS_DESC',
  AppointmentsCountAsc = 'APPOINTMENTS_COUNT_ASC',
  AppointmentsCountDesc = 'APPOINTMENTS_COUNT_DESC',
  AppointmentsSumIdAsc = 'APPOINTMENTS_SUM_ID_ASC',
  AppointmentsSumIdDesc = 'APPOINTMENTS_SUM_ID_DESC',
  AppointmentsSumLeadIdAsc = 'APPOINTMENTS_SUM_LEAD_ID_ASC',
  AppointmentsSumLeadIdDesc = 'APPOINTMENTS_SUM_LEAD_ID_DESC',
  AppointmentsSumVehicleListingIdAsc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_ASC',
  AppointmentsSumVehicleListingIdDesc = 'APPOINTMENTS_SUM_VEHICLE_LISTING_ID_DESC',
  AppointmentsSumAdditionalInformationAsc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_ASC',
  AppointmentsSumAdditionalInformationDesc = 'APPOINTMENTS_SUM_ADDITIONAL_INFORMATION_DESC',
  AppointmentsSumAppointmentAtAsc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_ASC',
  AppointmentsSumAppointmentAtDesc = 'APPOINTMENTS_SUM_APPOINTMENT_AT_DESC',
  AppointmentsSumConfirmedAtAsc = 'APPOINTMENTS_SUM_CONFIRMED_AT_ASC',
  AppointmentsSumConfirmedAtDesc = 'APPOINTMENTS_SUM_CONFIRMED_AT_DESC',
  AppointmentsSumCreatedAtAsc = 'APPOINTMENTS_SUM_CREATED_AT_ASC',
  AppointmentsSumCreatedAtDesc = 'APPOINTMENTS_SUM_CREATED_AT_DESC',
  AppointmentsSumUpdatedAtAsc = 'APPOINTMENTS_SUM_UPDATED_AT_ASC',
  AppointmentsSumUpdatedAtDesc = 'APPOINTMENTS_SUM_UPDATED_AT_DESC',
  AppointmentsDistinctCountIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_ID_ASC',
  AppointmentsDistinctCountIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_ID_DESC',
  AppointmentsDistinctCountLeadIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_ASC',
  AppointmentsDistinctCountLeadIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_LEAD_ID_DESC',
  AppointmentsDistinctCountVehicleListingIdAsc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_ASC',
  AppointmentsDistinctCountVehicleListingIdDesc = 'APPOINTMENTS_DISTINCT_COUNT_VEHICLE_LISTING_ID_DESC',
  AppointmentsDistinctCountAdditionalInformationAsc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_ASC',
  AppointmentsDistinctCountAdditionalInformationDesc = 'APPOINTMENTS_DISTINCT_COUNT_ADDITIONAL_INFORMATION_DESC',
  AppointmentsDistinctCountAppointmentAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_ASC',
  AppointmentsDistinctCountAppointmentAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_APPOINTMENT_AT_DESC',
  AppointmentsDistinctCountConfirmedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_ASC',
  AppointmentsDistinctCountConfirmedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CONFIRMED_AT_DESC',
  AppointmentsDistinctCountCreatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  AppointmentsDistinctCountCreatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  AppointmentsDistinctCountUpdatedAtAsc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AppointmentsDistinctCountUpdatedAtDesc = 'APPOINTMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AppointmentsMinIdAsc = 'APPOINTMENTS_MIN_ID_ASC',
  AppointmentsMinIdDesc = 'APPOINTMENTS_MIN_ID_DESC',
  AppointmentsMinLeadIdAsc = 'APPOINTMENTS_MIN_LEAD_ID_ASC',
  AppointmentsMinLeadIdDesc = 'APPOINTMENTS_MIN_LEAD_ID_DESC',
  AppointmentsMinVehicleListingIdAsc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_ASC',
  AppointmentsMinVehicleListingIdDesc = 'APPOINTMENTS_MIN_VEHICLE_LISTING_ID_DESC',
  AppointmentsMinAdditionalInformationAsc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMinAdditionalInformationDesc = 'APPOINTMENTS_MIN_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMinAppointmentAtAsc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_ASC',
  AppointmentsMinAppointmentAtDesc = 'APPOINTMENTS_MIN_APPOINTMENT_AT_DESC',
  AppointmentsMinConfirmedAtAsc = 'APPOINTMENTS_MIN_CONFIRMED_AT_ASC',
  AppointmentsMinConfirmedAtDesc = 'APPOINTMENTS_MIN_CONFIRMED_AT_DESC',
  AppointmentsMinCreatedAtAsc = 'APPOINTMENTS_MIN_CREATED_AT_ASC',
  AppointmentsMinCreatedAtDesc = 'APPOINTMENTS_MIN_CREATED_AT_DESC',
  AppointmentsMinUpdatedAtAsc = 'APPOINTMENTS_MIN_UPDATED_AT_ASC',
  AppointmentsMinUpdatedAtDesc = 'APPOINTMENTS_MIN_UPDATED_AT_DESC',
  AppointmentsMaxIdAsc = 'APPOINTMENTS_MAX_ID_ASC',
  AppointmentsMaxIdDesc = 'APPOINTMENTS_MAX_ID_DESC',
  AppointmentsMaxLeadIdAsc = 'APPOINTMENTS_MAX_LEAD_ID_ASC',
  AppointmentsMaxLeadIdDesc = 'APPOINTMENTS_MAX_LEAD_ID_DESC',
  AppointmentsMaxVehicleListingIdAsc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_ASC',
  AppointmentsMaxVehicleListingIdDesc = 'APPOINTMENTS_MAX_VEHICLE_LISTING_ID_DESC',
  AppointmentsMaxAdditionalInformationAsc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_ASC',
  AppointmentsMaxAdditionalInformationDesc = 'APPOINTMENTS_MAX_ADDITIONAL_INFORMATION_DESC',
  AppointmentsMaxAppointmentAtAsc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_ASC',
  AppointmentsMaxAppointmentAtDesc = 'APPOINTMENTS_MAX_APPOINTMENT_AT_DESC',
  AppointmentsMaxConfirmedAtAsc = 'APPOINTMENTS_MAX_CONFIRMED_AT_ASC',
  AppointmentsMaxConfirmedAtDesc = 'APPOINTMENTS_MAX_CONFIRMED_AT_DESC',
  AppointmentsMaxCreatedAtAsc = 'APPOINTMENTS_MAX_CREATED_AT_ASC',
  AppointmentsMaxCreatedAtDesc = 'APPOINTMENTS_MAX_CREATED_AT_DESC',
  AppointmentsMaxUpdatedAtAsc = 'APPOINTMENTS_MAX_UPDATED_AT_ASC',
  AppointmentsMaxUpdatedAtDesc = 'APPOINTMENTS_MAX_UPDATED_AT_DESC',
  AppointmentsAverageIdAsc = 'APPOINTMENTS_AVERAGE_ID_ASC',
  AppointmentsAverageIdDesc = 'APPOINTMENTS_AVERAGE_ID_DESC',
  AppointmentsAverageLeadIdAsc = 'APPOINTMENTS_AVERAGE_LEAD_ID_ASC',
  AppointmentsAverageLeadIdDesc = 'APPOINTMENTS_AVERAGE_LEAD_ID_DESC',
  AppointmentsAverageVehicleListingIdAsc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_ASC',
  AppointmentsAverageVehicleListingIdDesc = 'APPOINTMENTS_AVERAGE_VEHICLE_LISTING_ID_DESC',
  AppointmentsAverageAdditionalInformationAsc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsAverageAdditionalInformationDesc = 'APPOINTMENTS_AVERAGE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsAverageAppointmentAtAsc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_ASC',
  AppointmentsAverageAppointmentAtDesc = 'APPOINTMENTS_AVERAGE_APPOINTMENT_AT_DESC',
  AppointmentsAverageConfirmedAtAsc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_ASC',
  AppointmentsAverageConfirmedAtDesc = 'APPOINTMENTS_AVERAGE_CONFIRMED_AT_DESC',
  AppointmentsAverageCreatedAtAsc = 'APPOINTMENTS_AVERAGE_CREATED_AT_ASC',
  AppointmentsAverageCreatedAtDesc = 'APPOINTMENTS_AVERAGE_CREATED_AT_DESC',
  AppointmentsAverageUpdatedAtAsc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_ASC',
  AppointmentsAverageUpdatedAtDesc = 'APPOINTMENTS_AVERAGE_UPDATED_AT_DESC',
  AppointmentsStddevSampleIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_ASC',
  AppointmentsStddevSampleIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ID_DESC',
  AppointmentsStddevSampleLeadIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_ASC',
  AppointmentsStddevSampleLeadIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_LEAD_ID_DESC',
  AppointmentsStddevSampleVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevSampleVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevSampleAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevSampleAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevSampleAppointmentAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsStddevSampleAppointmentAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsStddevSampleConfirmedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsStddevSampleConfirmedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsStddevSampleCreatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AppointmentsStddevSampleCreatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AppointmentsStddevSampleUpdatedAtAsc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AppointmentsStddevSampleUpdatedAtDesc = 'APPOINTMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AppointmentsStddevPopulationIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_ID_ASC',
  AppointmentsStddevPopulationIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_ID_DESC',
  AppointmentsStddevPopulationLeadIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_ASC',
  AppointmentsStddevPopulationLeadIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_LEAD_ID_DESC',
  AppointmentsStddevPopulationVehicleListingIdAsc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsStddevPopulationVehicleListingIdDesc = 'APPOINTMENTS_STDDEV_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsStddevPopulationAdditionalInformationAsc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsStddevPopulationAdditionalInformationDesc = 'APPOINTMENTS_STDDEV_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsStddevPopulationAppointmentAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsStddevPopulationAppointmentAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsStddevPopulationConfirmedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsStddevPopulationConfirmedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsStddevPopulationCreatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  AppointmentsStddevPopulationCreatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  AppointmentsStddevPopulationUpdatedAtAsc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AppointmentsStddevPopulationUpdatedAtDesc = 'APPOINTMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AppointmentsVarianceSampleIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_ASC',
  AppointmentsVarianceSampleIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ID_DESC',
  AppointmentsVarianceSampleLeadIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_ASC',
  AppointmentsVarianceSampleLeadIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_LEAD_ID_DESC',
  AppointmentsVarianceSampleVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_ASC',
  AppointmentsVarianceSampleVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_VEHICLE_LISTING_ID_DESC',
  AppointmentsVarianceSampleAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVarianceSampleAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVarianceSampleAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_ASC',
  AppointmentsVarianceSampleAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_APPOINTMENT_AT_DESC',
  AppointmentsVarianceSampleConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_ASC',
  AppointmentsVarianceSampleConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CONFIRMED_AT_DESC',
  AppointmentsVarianceSampleCreatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AppointmentsVarianceSampleCreatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AppointmentsVarianceSampleUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AppointmentsVarianceSampleUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AppointmentsVariancePopulationIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_ASC',
  AppointmentsVariancePopulationIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ID_DESC',
  AppointmentsVariancePopulationLeadIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_ASC',
  AppointmentsVariancePopulationLeadIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_LEAD_ID_DESC',
  AppointmentsVariancePopulationVehicleListingIdAsc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_ASC',
  AppointmentsVariancePopulationVehicleListingIdDesc = 'APPOINTMENTS_VARIANCE_POPULATION_VEHICLE_LISTING_ID_DESC',
  AppointmentsVariancePopulationAdditionalInformationAsc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_ASC',
  AppointmentsVariancePopulationAdditionalInformationDesc = 'APPOINTMENTS_VARIANCE_POPULATION_ADDITIONAL_INFORMATION_DESC',
  AppointmentsVariancePopulationAppointmentAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_ASC',
  AppointmentsVariancePopulationAppointmentAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_APPOINTMENT_AT_DESC',
  AppointmentsVariancePopulationConfirmedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_ASC',
  AppointmentsVariancePopulationConfirmedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CONFIRMED_AT_DESC',
  AppointmentsVariancePopulationCreatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AppointmentsVariancePopulationCreatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AppointmentsVariancePopulationUpdatedAtAsc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AppointmentsVariancePopulationUpdatedAtDesc = 'APPOINTMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type VehicleLookupEntry = {
  make?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  matchCode?: Maybe<Scalars['String']>;
  matchDescription?: Maybe<Scalars['String']>;
};

export type VehicleLookupResponse = {
  results?: Maybe<Array<Maybe<VehicleLookupEntry>>>;
};

export enum VerificationChannel {
  Phone = 'Phone'
}

export type VerificationResponse = {
  success: Scalars['Boolean'];
  leadId: Scalars['ID'];
  phoneVerifiedAt?: Maybe<Scalars['String']>;
};

export type Widget = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['String'];
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `CompanyWidget`. */
  companyWidgets: CompanyWidgetsConnection;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettings: WidgetSettingsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCompanyWidgetWidgetIdAndGroupId: WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Cta`. */
  ctasByCtaWidgetWidgetIdAndCtaId: WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FieldKey`. */
  fieldKeysByWidgetSettingWidgetIdAndFieldKey: WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnection;
  config?: Maybe<WidgetConfig>;
};


export type WidgetCompanyWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CompanyWidgetsOrderBy>>;
  condition?: Maybe<CompanyWidgetCondition>;
  filter?: Maybe<CompanyWidgetFilter>;
};


export type WidgetCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};


export type WidgetWidgetSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};


export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
  filter?: Maybe<GroupFilter>;
};


export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtasOrderBy>>;
  condition?: Maybe<CtaCondition>;
  filter?: Maybe<CtaFilter>;
};


export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FieldKeysOrderBy>>;
  condition?: Maybe<FieldKeyCondition>;
  filter?: Maybe<FieldKeyFilter>;
};

export type WidgetAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WidgetDistinctCountAggregates>;
};

export type WidgetCompanyDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  widgetId: Scalars['String'];
  archived: Scalars['Boolean'];
  products: Array<ProductType>;
  meta?: Maybe<WidgetCompanyMeta>;
};

export type WidgetCompanyMeta = {
  tradeTool?: Maybe<WidgetCompanyTradeToolMeta>;
};

export type WidgetCompanyTradeToolMeta = {
  provider?: Maybe<Scalars['String']>;
};

/** A condition to be used against `Widget` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WidgetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `widgetSpec` field. */
  widgetSpec?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `ctaTheme` field. */
  ctaTheme?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `variant` field. */
  variant?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rawConfig` field. */
  rawConfig?: Maybe<Scalars['JSON']>;
};

export type WidgetConfig = {
  language?: Maybe<WidgetLanguage>;
  inventoryVertical?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  buttonBg?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Cta` values, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnection = {
  /** A list of `Cta` objects. */
  nodes: Array<Cta>;
  /** A list of edges which contains the `Cta`, info from the `CtaWidget`, and the cursor to aid in pagination. */
  edges: Array<WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cta` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CtaAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CtaAggregates>>;
};


/** A connection to a list of `Cta` values, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CtasGroupBy>;
  having?: Maybe<CtasHavingInput>;
};

/** A `Cta` edge in the connection, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cta` at the end of the edge. */
  node: Cta;
  /** Reads and enables pagination through a set of `CtaWidget`. */
  ctaWidgets: CtaWidgetsConnection;
};


/** A `Cta` edge in the connection, with data from `CtaWidget`. */
export type WidgetCtasByCtaWidgetWidgetIdAndCtaIdManyToManyEdgeCtaWidgetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CtaWidgetsOrderBy>>;
  condition?: Maybe<CtaWidgetCondition>;
  filter?: Maybe<CtaWidgetFilter>;
};

export type WidgetDetailsResponse = {
  company: WidgetCompanyDetails;
  group: WidgetGroupDetails;
  memberCompanies: Array<WidgetCompanyDetails>;
  config?: Maybe<WidgetConfig>;
  /** Content overrides defined for the input cta and/or the selected company */
  widgetOverrides?: Maybe<Scalars['JSON']>;
  settings: Array<WidgetSettingType>;
  fields: Array<WidgetField>;
  productIntegrations: Array<WidgetProductIntegration>;
};

export type WidgetDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetSpec across the matching connection */
  widgetSpec?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ctaTheme across the matching connection */
  ctaTheme?: Maybe<Scalars['BigInt']>;
  /** Distinct count of variant across the matching connection */
  variant?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rawConfig across the matching connection */
  rawConfig?: Maybe<Scalars['BigInt']>;
};

export type WidgetField = {
  product?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** A connection to a list of `FieldKey` values, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnection = {
  /** A list of `FieldKey` objects. */
  nodes: Array<FieldKey>;
  /** A list of edges which contains the `FieldKey`, info from the `WidgetSetting`, and the cursor to aid in pagination. */
  edges: Array<WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FieldKey` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FieldKeyAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FieldKeyAggregates>>;
};


/** A connection to a list of `FieldKey` values, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FieldKeyGroupBy>;
  having?: Maybe<FieldKeyHavingInput>;
};

/** A `FieldKey` edge in the connection, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FieldKey` at the end of the edge. */
  node: FieldKey;
  /** Reads and enables pagination through a set of `WidgetSetting`. */
  widgetSettingsByFieldKey: WidgetSettingsConnection;
};


/** A `FieldKey` edge in the connection, with data from `WidgetSetting`. */
export type WidgetFieldKeysByWidgetSettingWidgetIdAndFieldKeyManyToManyEdgeWidgetSettingsByFieldKeyArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WidgetSettingsOrderBy>>;
  condition?: Maybe<WidgetSettingCondition>;
  filter?: Maybe<WidgetSettingFilter>;
};

/** A filter to be used against `Widget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<StringFilter>;
  /** Filter by the object’s `ctaTheme` field. */
  ctaTheme?: Maybe<StringFilter>;
  /** Filter by the object’s `variant` field. */
  variant?: Maybe<StringFilter>;
  /** Filter by the object’s `rawConfig` field. */
  rawConfig?: Maybe<JsonFilter>;
  /** Filter by the object’s `companyWidgets` relation. */
  companyWidgets?: Maybe<WidgetToManyCompanyWidgetFilter>;
  /** Some related `companyWidgets` exist. */
  companyWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `ctaWidgets` relation. */
  ctaWidgets?: Maybe<WidgetToManyCtaWidgetFilter>;
  /** Some related `ctaWidgets` exist. */
  ctaWidgetsExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `widgetSettings` relation. */
  widgetSettings?: Maybe<WidgetToManyWidgetSettingFilter>;
  /** Some related `widgetSettings` exist. */
  widgetSettingsExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WidgetFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WidgetFilter>>;
  /** Negates the expression. */
  not?: Maybe<WidgetFilter>;
};

export type WidgetGroupDetails = {
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  legalDesc?: Maybe<Scalars['String']>;
  distributionType: GroupDistType;
};

/** A connection to a list of `Group` values, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnection = {
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** A list of edges which contains the `Group`, info from the `CompanyWidget`, and the cursor to aid in pagination. */
  edges: Array<WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
};


/** A connection to a list of `Group` values, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupGroupBy>;
  having?: Maybe<GroupHavingInput>;
};

/** A `Group` edge in the connection, with data from `CompanyWidget`. */
export type WidgetGroupsByCompanyWidgetWidgetIdAndGroupIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
  specOverride?: Maybe<Scalars['JSON']>;
};

/** An input for mutations affecting `Widget` */
export type WidgetInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export enum WidgetLanguage {
  En = 'En',
  Es = 'Es',
  Fr = 'Fr'
}

/** The globally unique `ID` look up for the row to connect. */
export type WidgetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WidgetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `widget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `companyWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `companyWidget` being updated. */
  patch: CompanyWidgetPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch;
  variant: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `ctaWidget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ctaWidget` being updated. */
  patch: CtaWidgetPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnCtaWidgetForCtaWidgetsWidgetIdFkUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch;
  variant: Scalars['String'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: WidgetSettingPatch;
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to update. */
export type WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsUqVariantUpdate = {
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  variant: Scalars['String'];
};

/** Represents an update to a `Widget`. Fields that are set will be updated. */
export type WidgetPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

export type WidgetProductIntegration = {
  product?: Maybe<Scalars['String']>;
  integrations?: Maybe<Array<Scalars['String']>>;
};

export type WidgetSetting = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Widget` that is related to this `WidgetSetting`. */
  widget?: Maybe<Widget>;
  /** Reads a single `FieldKey` that is related to this `WidgetSetting`. */
  fieldKeyByFieldKey?: Maybe<FieldKey>;
};

export type WidgetSettingAggregates = {
  keys?: Maybe<Array<Scalars['String']>>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<WidgetSettingSumAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WidgetSettingDistinctCountAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<WidgetSettingMinAggregates>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<WidgetSettingMaxAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<WidgetSettingAverageAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<WidgetSettingStddevSampleAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<WidgetSettingStddevPopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<WidgetSettingVarianceSampleAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<WidgetSettingVariancePopulationAggregates>;
};

/** A filter to be used against aggregates of `WidgetSetting` object types. */
export type WidgetSettingAggregatesFilter = {
  /** A filter that must pass for the relevant `WidgetSetting` object to be included within the aggregate. */
  filter?: Maybe<WidgetSettingFilter>;
  /** Sum aggregate over matching `WidgetSetting` objects. */
  sum?: Maybe<WidgetSettingSumAggregateFilter>;
  /** Distinct count aggregate over matching `WidgetSetting` objects. */
  distinctCount?: Maybe<WidgetSettingDistinctCountAggregateFilter>;
  /** Minimum aggregate over matching `WidgetSetting` objects. */
  min?: Maybe<WidgetSettingMinAggregateFilter>;
  /** Maximum aggregate over matching `WidgetSetting` objects. */
  max?: Maybe<WidgetSettingMaxAggregateFilter>;
  /** Mean average aggregate over matching `WidgetSetting` objects. */
  average?: Maybe<WidgetSettingAverageAggregateFilter>;
  /** Sample standard deviation aggregate over matching `WidgetSetting` objects. */
  stddevSample?: Maybe<WidgetSettingStddevSampleAggregateFilter>;
  /** Population standard deviation aggregate over matching `WidgetSetting` objects. */
  stddevPopulation?: Maybe<WidgetSettingStddevPopulationAggregateFilter>;
  /** Sample variance aggregate over matching `WidgetSetting` objects. */
  varianceSample?: Maybe<WidgetSettingVarianceSampleAggregateFilter>;
  /** Population variance aggregate over matching `WidgetSetting` objects. */
  variancePopulation?: Maybe<WidgetSettingVariancePopulationAggregateFilter>;
};

export type WidgetSettingAverageAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingAverageAggregates = {
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `WidgetSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WidgetSettingCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `widgetId` field. */
  widgetId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `topic` field. */
  topic?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fieldKey` field. */
  fieldKey?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type WidgetSettingDistinctCountAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  widgetId?: Maybe<BigIntFilter>;
  topic?: Maybe<BigIntFilter>;
  fieldKey?: Maybe<BigIntFilter>;
  value?: Maybe<BigIntFilter>;
  createdAt?: Maybe<BigIntFilter>;
  updatedAt?: Maybe<BigIntFilter>;
};

export type WidgetSettingDistinctCountAggregates = {
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of widgetId across the matching connection */
  widgetId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of topic across the matching connection */
  topic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type WidgetSettingFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `widgetId` field. */
  widgetId?: Maybe<StringFilter>;
  /** Filter by the object’s `topic` field. */
  topic?: Maybe<StringFilter>;
  /** Filter by the object’s `fieldKey` field. */
  fieldKey?: Maybe<IntFilter>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `widget` relation. */
  widget?: Maybe<WidgetFilter>;
  /** A related `widget` exists. */
  widgetExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `fieldKeyByFieldKey` relation. */
  fieldKeyByFieldKey?: Maybe<FieldKeyFilter>;
  /** A related `fieldKeyByFieldKey` exists. */
  fieldKeyByFieldKeyExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<WidgetSettingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<WidgetSettingFilter>>;
  /** Negates the expression. */
  not?: Maybe<WidgetSettingFilter>;
};

/** An input for mutations affecting `WidgetSetting` */
export type WidgetSettingInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type WidgetSettingMaxAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldKey?: Maybe<IntFilter>;
};

export type WidgetSettingMaxAggregates = {
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['Int']>;
};

export type WidgetSettingMinAggregateFilter = {
  id?: Maybe<IntFilter>;
  fieldKey?: Maybe<IntFilter>;
};

export type WidgetSettingMinAggregates = {
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WidgetSettingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WidgetSettingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `widgetSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `fieldKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `fieldKey` being updated. */
  patch: FieldKeyPatch;
};

/** The fields on `widgetSetting` to look up the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingWidgetSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: UpdateWidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch;
  id: Scalars['Int'];
};

/** The globally unique `ID` look up for the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `widget` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `widget` being updated. */
  patch: WidgetPatch;
};

/** The fields on `widgetSetting` to look up the row to update. */
export type WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `widgetSetting` being updated. */
  patch: UpdateWidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `WidgetSetting`. Fields that are set will be updated. */
export type WidgetSettingPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type WidgetSettingStddevPopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingStddevSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingSumAggregateFilter = {
  id?: Maybe<BigIntFilter>;
  fieldKey?: Maybe<BigIntFilter>;
};

export type WidgetSettingSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of fieldKey across the matching connection */
  fieldKey: Scalars['BigInt'];
};

export type WidgetSettingType = {
  id: Scalars['Int'];
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  field?: Maybe<FieldKeyType>;
  value: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type WidgetSettingVariancePopulationAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

export type WidgetSettingVarianceSampleAggregateFilter = {
  id?: Maybe<BigFloatFilter>;
  fieldKey?: Maybe<BigFloatFilter>;
};

export type WidgetSettingVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fieldKey across the matching connection */
  fieldKey?: Maybe<Scalars['BigFloat']>;
};

/** The fields on `widgetSetting` to look up the row to connect. */
export type WidgetSettingWidgetSettingsPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `widgetSetting` to look up the row to delete. */
export type WidgetSettingWidgetSettingsPkeyDelete = {
  id: Scalars['Int'];
};

/** A connection to a list of `WidgetSetting` values. */
export type WidgetSettingsConnection = {
  /** A list of `WidgetSetting` objects. */
  nodes: Array<WidgetSetting>;
  /** A list of edges which contains the `WidgetSetting` and cursor to aid in pagination. */
  edges: Array<WidgetSettingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WidgetSetting` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetSettingAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetSettingAggregates>>;
};


/** A connection to a list of `WidgetSetting` values. */
export type WidgetSettingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetSettingsGroupBy>;
  having?: Maybe<WidgetSettingsHavingInput>;
};

/** A `WidgetSetting` edge in the connection. */
export type WidgetSettingsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `WidgetSetting` at the end of the edge. */
  node: WidgetSetting;
};

/** The `fieldKey` to be created by this mutation. */
export type WidgetSettingsFieldKeyFkeyFieldKeyCreateInput = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type: FieldType;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key: Scalars['String'];
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

/** Input for the nested mutation of `fieldKey` in the `WidgetSettingInput` mutation. */
export type WidgetSettingsFieldKeyFkeyInput = {
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectById?: Maybe<FieldKeyFieldKeyPkeyConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectByKey?: Maybe<FieldKeyFieldKeyKeyKeyConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<FieldKeyNodeIdConnect>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteById?: Maybe<FieldKeyFieldKeyPkeyDelete>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteByKey?: Maybe<FieldKeyFieldKeyKeyKeyDelete>;
  /** The primary key(s) for `fieldKey` for the far side of the relationship. */
  deleteByNodeId?: Maybe<FieldKeyNodeIdDelete>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateById?: Maybe<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyPkeyUpdate>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateByKey?: Maybe<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingFieldKeyKeyKeyUpdate>;
  /** The primary key(s) and patch data for `fieldKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate>;
  /** A `FieldKeyInput` object that will be created and connected to this object. */
  create?: Maybe<WidgetSettingsFieldKeyFkeyFieldKeyCreateInput>;
};

/** Input for the nested mutation of `widgetSetting` in the `FieldKeyInput` mutation. */
export type WidgetSettingsFieldKeyFkeyInverseInput = {
  /** Flag indicating whether all other `widgetSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<WidgetSettingNodeIdConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyDelete>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<WidgetSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateById?: Maybe<Array<WidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyUsingWidgetSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<FieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyNodeIdUpdate>>;
  /** A `WidgetSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WidgetSettingsFieldKeyFkeyWidgetSettingsCreateInput>>;
};

/** The `widgetSetting` to be created by this mutation. */
export type WidgetSettingsFieldKeyFkeyWidgetSettingsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** Grouping methods for `WidgetSetting` for usage during aggregation. */
export enum WidgetSettingsGroupBy {
  WidgetId = 'WIDGET_ID',
  Topic = 'TOPIC',
  FieldKey = 'FIELD_KEY',
  Value = 'VALUE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY'
}

export type WidgetSettingsHavingAverageInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingDistinctCountInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `WidgetSetting` aggregates. */
export type WidgetSettingsHavingInput = {
  AND?: Maybe<Array<WidgetSettingsHavingInput>>;
  OR?: Maybe<Array<WidgetSettingsHavingInput>>;
  sum?: Maybe<WidgetSettingsHavingSumInput>;
  distinctCount?: Maybe<WidgetSettingsHavingDistinctCountInput>;
  min?: Maybe<WidgetSettingsHavingMinInput>;
  max?: Maybe<WidgetSettingsHavingMaxInput>;
  average?: Maybe<WidgetSettingsHavingAverageInput>;
  stddevSample?: Maybe<WidgetSettingsHavingStddevSampleInput>;
  stddevPopulation?: Maybe<WidgetSettingsHavingStddevPopulationInput>;
  varianceSample?: Maybe<WidgetSettingsHavingVarianceSampleInput>;
  variancePopulation?: Maybe<WidgetSettingsHavingVariancePopulationInput>;
};

export type WidgetSettingsHavingMaxInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingMinInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingStddevPopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingStddevSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingSumInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingVariancePopulationInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type WidgetSettingsHavingVarianceSampleInput = {
  id?: Maybe<HavingIntFilter>;
  fieldKey?: Maybe<HavingIntFilter>;
  createdAt?: Maybe<HavingDatetimeFilter>;
  updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `WidgetSetting`. */
export enum WidgetSettingsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetIdAsc = 'WIDGET_ID_ASC',
  WidgetIdDesc = 'WIDGET_ID_DESC',
  TopicAsc = 'TOPIC_ASC',
  TopicDesc = 'TOPIC_DESC',
  FieldKeyAsc = 'FIELD_KEY_ASC',
  FieldKeyDesc = 'FIELD_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Input for the nested mutation of `widget` in the `WidgetSettingInput` mutation. */
export type WidgetSettingsWidgetIdFkeyInput = {
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectById?: Maybe<WidgetWidgetsPkeyConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByVariant?: Maybe<WidgetWidgetsUqVariantConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  connectByNodeId?: Maybe<WidgetNodeIdConnect>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteById?: Maybe<WidgetWidgetsPkeyDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByVariant?: Maybe<WidgetWidgetsUqVariantDelete>;
  /** The primary key(s) for `widget` for the far side of the relationship. */
  deleteByNodeId?: Maybe<WidgetNodeIdDelete>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateById?: Maybe<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsPkeyUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByVariant?: Maybe<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetsUqVariantUpdate>;
  /** The primary key(s) and patch data for `widget` for the far side of the relationship. */
  updateByNodeId?: Maybe<WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate>;
  /** A `WidgetInput` object that will be created and connected to this object. */
  create?: Maybe<WidgetSettingsWidgetIdFkeyWidgetsCreateInput>;
};

/** Input for the nested mutation of `widgetSetting` in the `WidgetInput` mutation. */
export type WidgetSettingsWidgetIdFkeyInverseInput = {
  /** Flag indicating whether all other `widgetSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<WidgetSettingNodeIdConnect>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteById?: Maybe<Array<WidgetSettingWidgetSettingsPkeyDelete>>;
  /** The primary key(s) for `widgetSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<WidgetSettingNodeIdDelete>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateById?: Maybe<Array<WidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyUsingWidgetSettingsPkeyUpdate>>;
  /** The primary key(s) and patch data for `widgetSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<WidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyNodeIdUpdate>>;
  /** A `WidgetSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<WidgetSettingsWidgetIdFkeyWidgetSettingsCreateInput>>;
};

/** The `widgetSetting` to be created by this mutation. */
export type WidgetSettingsWidgetIdFkeyWidgetSettingsCreateInput = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** The `widget` to be created by this mutation. */
export type WidgetSettingsWidgetIdFkeyWidgetsCreateInput = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** A filter to be used against many `CompanyWidget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyCompanyWidgetFilter = {
  /** Every related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CompanyWidgetFilter>;
  /** Some related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CompanyWidgetFilter>;
  /** No related `CompanyWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CompanyWidgetFilter>;
  /** Aggregates across related `CompanyWidget` match the filter criteria. */
  aggregates?: Maybe<CompanyWidgetAggregatesFilter>;
};

/** A filter to be used against many `CtaWidget` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyCtaWidgetFilter = {
  /** Every related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CtaWidgetFilter>;
  /** Some related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CtaWidgetFilter>;
  /** No related `CtaWidget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CtaWidgetFilter>;
  /** Aggregates across related `CtaWidget` match the filter criteria. */
  aggregates?: Maybe<CtaWidgetAggregatesFilter>;
};

/** A filter to be used against many `WidgetSetting` object types. All fields are combined with a logical ‘and.’ */
export type WidgetToManyWidgetSettingFilter = {
  /** Every related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<WidgetSettingFilter>;
  /** Some related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<WidgetSettingFilter>;
  /** No related `WidgetSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<WidgetSettingFilter>;
  /** Aggregates across related `WidgetSetting` match the filter criteria. */
  aggregates?: Maybe<WidgetSettingAggregatesFilter>;
};

/** The fields on `widget` to look up the row to connect. */
export type WidgetWidgetsPkeyConnect = {
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to delete. */
export type WidgetWidgetsPkeyDelete = {
  id: Scalars['String'];
};

/** The fields on `widget` to look up the row to connect. */
export type WidgetWidgetsUqVariantConnect = {
  variant: Scalars['String'];
};

/** The fields on `widget` to look up the row to delete. */
export type WidgetWidgetsUqVariantDelete = {
  variant: Scalars['String'];
};

/** A connection to a list of `Widget` values. */
export type WidgetsConnection = {
  /** A list of `Widget` objects. */
  nodes: Array<Widget>;
  /** A list of edges which contains the `Widget` and cursor to aid in pagination. */
  edges: Array<WidgetsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Widget` you could get from the connection. */
  totalCount: Scalars['Int'];
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WidgetAggregates>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WidgetAggregates>>;
};


/** A connection to a list of `Widget` values. */
export type WidgetsConnectionGroupedAggregatesArgs = {
  groupBy: Array<WidgetsGroupBy>;
  having?: Maybe<WidgetsHavingInput>;
};

/** A `Widget` edge in the connection. */
export type WidgetsEdge = {
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Widget` at the end of the edge. */
  node: Widget;
};

/** Grouping methods for `Widget` for usage during aggregation. */
export enum WidgetsGroupBy {
  WidgetSpec = 'WIDGET_SPEC',
  CtaTheme = 'CTA_THEME',
  RawConfig = 'RAW_CONFIG'
}

/** Conditions for `Widget` aggregates. */
export type WidgetsHavingInput = {
  AND?: Maybe<Array<WidgetsHavingInput>>;
  OR?: Maybe<Array<WidgetsHavingInput>>;
};

/** Methods to use when ordering `Widget`. */
export enum WidgetsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  WidgetSpecAsc = 'WIDGET_SPEC_ASC',
  WidgetSpecDesc = 'WIDGET_SPEC_DESC',
  CtaThemeAsc = 'CTA_THEME_ASC',
  CtaThemeDesc = 'CTA_THEME_DESC',
  VariantAsc = 'VARIANT_ASC',
  VariantDesc = 'VARIANT_DESC',
  RawConfigAsc = 'RAW_CONFIG_ASC',
  RawConfigDesc = 'RAW_CONFIG_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  CompanyWidgetsCountAsc = 'COMPANY_WIDGETS_COUNT_ASC',
  CompanyWidgetsCountDesc = 'COMPANY_WIDGETS_COUNT_DESC',
  CompanyWidgetsSumGroupIdAsc = 'COMPANY_WIDGETS_SUM_GROUP_ID_ASC',
  CompanyWidgetsSumGroupIdDesc = 'COMPANY_WIDGETS_SUM_GROUP_ID_DESC',
  CompanyWidgetsSumWidgetIdAsc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_ASC',
  CompanyWidgetsSumWidgetIdDesc = 'COMPANY_WIDGETS_SUM_WIDGET_ID_DESC',
  CompanyWidgetsSumSpecOverrideAsc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_ASC',
  CompanyWidgetsSumSpecOverrideDesc = 'COMPANY_WIDGETS_SUM_SPEC_OVERRIDE_DESC',
  CompanyWidgetsDistinctCountGroupIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_ASC',
  CompanyWidgetsDistinctCountGroupIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_GROUP_ID_DESC',
  CompanyWidgetsDistinctCountWidgetIdAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CompanyWidgetsDistinctCountWidgetIdDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CompanyWidgetsDistinctCountSpecOverrideAsc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_ASC',
  CompanyWidgetsDistinctCountSpecOverrideDesc = 'COMPANY_WIDGETS_DISTINCT_COUNT_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMinGroupIdAsc = 'COMPANY_WIDGETS_MIN_GROUP_ID_ASC',
  CompanyWidgetsMinGroupIdDesc = 'COMPANY_WIDGETS_MIN_GROUP_ID_DESC',
  CompanyWidgetsMinWidgetIdAsc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_ASC',
  CompanyWidgetsMinWidgetIdDesc = 'COMPANY_WIDGETS_MIN_WIDGET_ID_DESC',
  CompanyWidgetsMinSpecOverrideAsc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMinSpecOverrideDesc = 'COMPANY_WIDGETS_MIN_SPEC_OVERRIDE_DESC',
  CompanyWidgetsMaxGroupIdAsc = 'COMPANY_WIDGETS_MAX_GROUP_ID_ASC',
  CompanyWidgetsMaxGroupIdDesc = 'COMPANY_WIDGETS_MAX_GROUP_ID_DESC',
  CompanyWidgetsMaxWidgetIdAsc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_ASC',
  CompanyWidgetsMaxWidgetIdDesc = 'COMPANY_WIDGETS_MAX_WIDGET_ID_DESC',
  CompanyWidgetsMaxSpecOverrideAsc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_ASC',
  CompanyWidgetsMaxSpecOverrideDesc = 'COMPANY_WIDGETS_MAX_SPEC_OVERRIDE_DESC',
  CompanyWidgetsAverageGroupIdAsc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_ASC',
  CompanyWidgetsAverageGroupIdDesc = 'COMPANY_WIDGETS_AVERAGE_GROUP_ID_DESC',
  CompanyWidgetsAverageWidgetIdAsc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CompanyWidgetsAverageWidgetIdDesc = 'COMPANY_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CompanyWidgetsAverageSpecOverrideAsc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsAverageSpecOverrideDesc = 'COMPANY_WIDGETS_AVERAGE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevSampleGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsStddevSampleGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsStddevSampleWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsStddevSampleWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsStddevSampleSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevSampleSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsStddevPopulationGroupIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsStddevPopulationGroupIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsStddevPopulationWidgetIdAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsStddevPopulationWidgetIdDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsStddevPopulationSpecOverrideAsc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsStddevPopulationSpecOverrideDesc = 'COMPANY_WIDGETS_STDDEV_POPULATION_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVarianceSampleGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  CompanyWidgetsVarianceSampleGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  CompanyWidgetsVarianceSampleWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CompanyWidgetsVarianceSampleWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CompanyWidgetsVarianceSampleSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVarianceSampleSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_SAMPLE_SPEC_OVERRIDE_DESC',
  CompanyWidgetsVariancePopulationGroupIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_ASC',
  CompanyWidgetsVariancePopulationGroupIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_GROUP_ID_DESC',
  CompanyWidgetsVariancePopulationWidgetIdAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CompanyWidgetsVariancePopulationWidgetIdDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CompanyWidgetsVariancePopulationSpecOverrideAsc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_ASC',
  CompanyWidgetsVariancePopulationSpecOverrideDesc = 'COMPANY_WIDGETS_VARIANCE_POPULATION_SPEC_OVERRIDE_DESC',
  CtaWidgetsCountAsc = 'CTA_WIDGETS_COUNT_ASC',
  CtaWidgetsCountDesc = 'CTA_WIDGETS_COUNT_DESC',
  CtaWidgetsSumIdAsc = 'CTA_WIDGETS_SUM_ID_ASC',
  CtaWidgetsSumIdDesc = 'CTA_WIDGETS_SUM_ID_DESC',
  CtaWidgetsSumCtaIdAsc = 'CTA_WIDGETS_SUM_CTA_ID_ASC',
  CtaWidgetsSumCtaIdDesc = 'CTA_WIDGETS_SUM_CTA_ID_DESC',
  CtaWidgetsSumStaticIdAsc = 'CTA_WIDGETS_SUM_STATIC_ID_ASC',
  CtaWidgetsSumStaticIdDesc = 'CTA_WIDGETS_SUM_STATIC_ID_DESC',
  CtaWidgetsSumWidgetIdAsc = 'CTA_WIDGETS_SUM_WIDGET_ID_ASC',
  CtaWidgetsSumWidgetIdDesc = 'CTA_WIDGETS_SUM_WIDGET_ID_DESC',
  CtaWidgetsSumCreatedAtAsc = 'CTA_WIDGETS_SUM_CREATED_AT_ASC',
  CtaWidgetsSumCreatedAtDesc = 'CTA_WIDGETS_SUM_CREATED_AT_DESC',
  CtaWidgetsSumUpdatedAtAsc = 'CTA_WIDGETS_SUM_UPDATED_AT_ASC',
  CtaWidgetsSumUpdatedAtDesc = 'CTA_WIDGETS_SUM_UPDATED_AT_DESC',
  CtaWidgetsDistinctCountIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_ASC',
  CtaWidgetsDistinctCountIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_ID_DESC',
  CtaWidgetsDistinctCountCtaIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_ASC',
  CtaWidgetsDistinctCountCtaIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CTA_ID_DESC',
  CtaWidgetsDistinctCountStaticIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_ASC',
  CtaWidgetsDistinctCountStaticIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_STATIC_ID_DESC',
  CtaWidgetsDistinctCountWidgetIdAsc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_ASC',
  CtaWidgetsDistinctCountWidgetIdDesc = 'CTA_WIDGETS_DISTINCT_COUNT_WIDGET_ID_DESC',
  CtaWidgetsDistinctCountCreatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_ASC',
  CtaWidgetsDistinctCountCreatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_CREATED_AT_DESC',
  CtaWidgetsDistinctCountUpdatedAtAsc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CtaWidgetsDistinctCountUpdatedAtDesc = 'CTA_WIDGETS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CtaWidgetsMinIdAsc = 'CTA_WIDGETS_MIN_ID_ASC',
  CtaWidgetsMinIdDesc = 'CTA_WIDGETS_MIN_ID_DESC',
  CtaWidgetsMinCtaIdAsc = 'CTA_WIDGETS_MIN_CTA_ID_ASC',
  CtaWidgetsMinCtaIdDesc = 'CTA_WIDGETS_MIN_CTA_ID_DESC',
  CtaWidgetsMinStaticIdAsc = 'CTA_WIDGETS_MIN_STATIC_ID_ASC',
  CtaWidgetsMinStaticIdDesc = 'CTA_WIDGETS_MIN_STATIC_ID_DESC',
  CtaWidgetsMinWidgetIdAsc = 'CTA_WIDGETS_MIN_WIDGET_ID_ASC',
  CtaWidgetsMinWidgetIdDesc = 'CTA_WIDGETS_MIN_WIDGET_ID_DESC',
  CtaWidgetsMinCreatedAtAsc = 'CTA_WIDGETS_MIN_CREATED_AT_ASC',
  CtaWidgetsMinCreatedAtDesc = 'CTA_WIDGETS_MIN_CREATED_AT_DESC',
  CtaWidgetsMinUpdatedAtAsc = 'CTA_WIDGETS_MIN_UPDATED_AT_ASC',
  CtaWidgetsMinUpdatedAtDesc = 'CTA_WIDGETS_MIN_UPDATED_AT_DESC',
  CtaWidgetsMaxIdAsc = 'CTA_WIDGETS_MAX_ID_ASC',
  CtaWidgetsMaxIdDesc = 'CTA_WIDGETS_MAX_ID_DESC',
  CtaWidgetsMaxCtaIdAsc = 'CTA_WIDGETS_MAX_CTA_ID_ASC',
  CtaWidgetsMaxCtaIdDesc = 'CTA_WIDGETS_MAX_CTA_ID_DESC',
  CtaWidgetsMaxStaticIdAsc = 'CTA_WIDGETS_MAX_STATIC_ID_ASC',
  CtaWidgetsMaxStaticIdDesc = 'CTA_WIDGETS_MAX_STATIC_ID_DESC',
  CtaWidgetsMaxWidgetIdAsc = 'CTA_WIDGETS_MAX_WIDGET_ID_ASC',
  CtaWidgetsMaxWidgetIdDesc = 'CTA_WIDGETS_MAX_WIDGET_ID_DESC',
  CtaWidgetsMaxCreatedAtAsc = 'CTA_WIDGETS_MAX_CREATED_AT_ASC',
  CtaWidgetsMaxCreatedAtDesc = 'CTA_WIDGETS_MAX_CREATED_AT_DESC',
  CtaWidgetsMaxUpdatedAtAsc = 'CTA_WIDGETS_MAX_UPDATED_AT_ASC',
  CtaWidgetsMaxUpdatedAtDesc = 'CTA_WIDGETS_MAX_UPDATED_AT_DESC',
  CtaWidgetsAverageIdAsc = 'CTA_WIDGETS_AVERAGE_ID_ASC',
  CtaWidgetsAverageIdDesc = 'CTA_WIDGETS_AVERAGE_ID_DESC',
  CtaWidgetsAverageCtaIdAsc = 'CTA_WIDGETS_AVERAGE_CTA_ID_ASC',
  CtaWidgetsAverageCtaIdDesc = 'CTA_WIDGETS_AVERAGE_CTA_ID_DESC',
  CtaWidgetsAverageStaticIdAsc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_ASC',
  CtaWidgetsAverageStaticIdDesc = 'CTA_WIDGETS_AVERAGE_STATIC_ID_DESC',
  CtaWidgetsAverageWidgetIdAsc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_ASC',
  CtaWidgetsAverageWidgetIdDesc = 'CTA_WIDGETS_AVERAGE_WIDGET_ID_DESC',
  CtaWidgetsAverageCreatedAtAsc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_ASC',
  CtaWidgetsAverageCreatedAtDesc = 'CTA_WIDGETS_AVERAGE_CREATED_AT_DESC',
  CtaWidgetsAverageUpdatedAtAsc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_ASC',
  CtaWidgetsAverageUpdatedAtDesc = 'CTA_WIDGETS_AVERAGE_UPDATED_AT_DESC',
  CtaWidgetsStddevSampleIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_ASC',
  CtaWidgetsStddevSampleIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_ID_DESC',
  CtaWidgetsStddevSampleCtaIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_ASC',
  CtaWidgetsStddevSampleCtaIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CTA_ID_DESC',
  CtaWidgetsStddevSampleStaticIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsStddevSampleStaticIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsStddevSampleWidgetIdAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsStddevSampleWidgetIdDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsStddevSampleCreatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsStddevSampleCreatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsStddevSampleUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsStddevSampleUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsStddevPopulationIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_ASC',
  CtaWidgetsStddevPopulationIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_ID_DESC',
  CtaWidgetsStddevPopulationCtaIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_ASC',
  CtaWidgetsStddevPopulationCtaIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CTA_ID_DESC',
  CtaWidgetsStddevPopulationStaticIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_ASC',
  CtaWidgetsStddevPopulationStaticIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_STATIC_ID_DESC',
  CtaWidgetsStddevPopulationWidgetIdAsc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsStddevPopulationWidgetIdDesc = 'CTA_WIDGETS_STDDEV_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsStddevPopulationCreatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_ASC',
  CtaWidgetsStddevPopulationCreatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_CREATED_AT_DESC',
  CtaWidgetsStddevPopulationUpdatedAtAsc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsStddevPopulationUpdatedAtDesc = 'CTA_WIDGETS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CtaWidgetsVarianceSampleIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_ASC',
  CtaWidgetsVarianceSampleIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_ID_DESC',
  CtaWidgetsVarianceSampleCtaIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_ASC',
  CtaWidgetsVarianceSampleCtaIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CTA_ID_DESC',
  CtaWidgetsVarianceSampleStaticIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_ASC',
  CtaWidgetsVarianceSampleStaticIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_STATIC_ID_DESC',
  CtaWidgetsVarianceSampleWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  CtaWidgetsVarianceSampleWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  CtaWidgetsVarianceSampleCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CtaWidgetsVarianceSampleCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CtaWidgetsVarianceSampleUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CtaWidgetsVarianceSampleUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CtaWidgetsVariancePopulationIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_ASC',
  CtaWidgetsVariancePopulationIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_ID_DESC',
  CtaWidgetsVariancePopulationCtaIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_ASC',
  CtaWidgetsVariancePopulationCtaIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CTA_ID_DESC',
  CtaWidgetsVariancePopulationStaticIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_ASC',
  CtaWidgetsVariancePopulationStaticIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_STATIC_ID_DESC',
  CtaWidgetsVariancePopulationWidgetIdAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  CtaWidgetsVariancePopulationWidgetIdDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  CtaWidgetsVariancePopulationCreatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CtaWidgetsVariancePopulationCreatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CtaWidgetsVariancePopulationUpdatedAtAsc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CtaWidgetsVariancePopulationUpdatedAtDesc = 'CTA_WIDGETS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsCountAsc = 'WIDGET_SETTINGS_COUNT_ASC',
  WidgetSettingsCountDesc = 'WIDGET_SETTINGS_COUNT_DESC',
  WidgetSettingsSumIdAsc = 'WIDGET_SETTINGS_SUM_ID_ASC',
  WidgetSettingsSumIdDesc = 'WIDGET_SETTINGS_SUM_ID_DESC',
  WidgetSettingsSumWidgetIdAsc = 'WIDGET_SETTINGS_SUM_WIDGET_ID_ASC',
  WidgetSettingsSumWidgetIdDesc = 'WIDGET_SETTINGS_SUM_WIDGET_ID_DESC',
  WidgetSettingsSumTopicAsc = 'WIDGET_SETTINGS_SUM_TOPIC_ASC',
  WidgetSettingsSumTopicDesc = 'WIDGET_SETTINGS_SUM_TOPIC_DESC',
  WidgetSettingsSumFieldKeyAsc = 'WIDGET_SETTINGS_SUM_FIELD_KEY_ASC',
  WidgetSettingsSumFieldKeyDesc = 'WIDGET_SETTINGS_SUM_FIELD_KEY_DESC',
  WidgetSettingsSumValueAsc = 'WIDGET_SETTINGS_SUM_VALUE_ASC',
  WidgetSettingsSumValueDesc = 'WIDGET_SETTINGS_SUM_VALUE_DESC',
  WidgetSettingsSumCreatedAtAsc = 'WIDGET_SETTINGS_SUM_CREATED_AT_ASC',
  WidgetSettingsSumCreatedAtDesc = 'WIDGET_SETTINGS_SUM_CREATED_AT_DESC',
  WidgetSettingsSumUpdatedAtAsc = 'WIDGET_SETTINGS_SUM_UPDATED_AT_ASC',
  WidgetSettingsSumUpdatedAtDesc = 'WIDGET_SETTINGS_SUM_UPDATED_AT_DESC',
  WidgetSettingsDistinctCountIdAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_ID_ASC',
  WidgetSettingsDistinctCountIdDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_ID_DESC',
  WidgetSettingsDistinctCountWidgetIdAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_WIDGET_ID_ASC',
  WidgetSettingsDistinctCountWidgetIdDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_WIDGET_ID_DESC',
  WidgetSettingsDistinctCountTopicAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_TOPIC_ASC',
  WidgetSettingsDistinctCountTopicDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_TOPIC_DESC',
  WidgetSettingsDistinctCountFieldKeyAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_FIELD_KEY_ASC',
  WidgetSettingsDistinctCountFieldKeyDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_FIELD_KEY_DESC',
  WidgetSettingsDistinctCountValueAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_VALUE_ASC',
  WidgetSettingsDistinctCountValueDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_VALUE_DESC',
  WidgetSettingsDistinctCountCreatedAtAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_CREATED_AT_ASC',
  WidgetSettingsDistinctCountCreatedAtDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_CREATED_AT_DESC',
  WidgetSettingsDistinctCountUpdatedAtAsc = 'WIDGET_SETTINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  WidgetSettingsDistinctCountUpdatedAtDesc = 'WIDGET_SETTINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  WidgetSettingsMinIdAsc = 'WIDGET_SETTINGS_MIN_ID_ASC',
  WidgetSettingsMinIdDesc = 'WIDGET_SETTINGS_MIN_ID_DESC',
  WidgetSettingsMinWidgetIdAsc = 'WIDGET_SETTINGS_MIN_WIDGET_ID_ASC',
  WidgetSettingsMinWidgetIdDesc = 'WIDGET_SETTINGS_MIN_WIDGET_ID_DESC',
  WidgetSettingsMinTopicAsc = 'WIDGET_SETTINGS_MIN_TOPIC_ASC',
  WidgetSettingsMinTopicDesc = 'WIDGET_SETTINGS_MIN_TOPIC_DESC',
  WidgetSettingsMinFieldKeyAsc = 'WIDGET_SETTINGS_MIN_FIELD_KEY_ASC',
  WidgetSettingsMinFieldKeyDesc = 'WIDGET_SETTINGS_MIN_FIELD_KEY_DESC',
  WidgetSettingsMinValueAsc = 'WIDGET_SETTINGS_MIN_VALUE_ASC',
  WidgetSettingsMinValueDesc = 'WIDGET_SETTINGS_MIN_VALUE_DESC',
  WidgetSettingsMinCreatedAtAsc = 'WIDGET_SETTINGS_MIN_CREATED_AT_ASC',
  WidgetSettingsMinCreatedAtDesc = 'WIDGET_SETTINGS_MIN_CREATED_AT_DESC',
  WidgetSettingsMinUpdatedAtAsc = 'WIDGET_SETTINGS_MIN_UPDATED_AT_ASC',
  WidgetSettingsMinUpdatedAtDesc = 'WIDGET_SETTINGS_MIN_UPDATED_AT_DESC',
  WidgetSettingsMaxIdAsc = 'WIDGET_SETTINGS_MAX_ID_ASC',
  WidgetSettingsMaxIdDesc = 'WIDGET_SETTINGS_MAX_ID_DESC',
  WidgetSettingsMaxWidgetIdAsc = 'WIDGET_SETTINGS_MAX_WIDGET_ID_ASC',
  WidgetSettingsMaxWidgetIdDesc = 'WIDGET_SETTINGS_MAX_WIDGET_ID_DESC',
  WidgetSettingsMaxTopicAsc = 'WIDGET_SETTINGS_MAX_TOPIC_ASC',
  WidgetSettingsMaxTopicDesc = 'WIDGET_SETTINGS_MAX_TOPIC_DESC',
  WidgetSettingsMaxFieldKeyAsc = 'WIDGET_SETTINGS_MAX_FIELD_KEY_ASC',
  WidgetSettingsMaxFieldKeyDesc = 'WIDGET_SETTINGS_MAX_FIELD_KEY_DESC',
  WidgetSettingsMaxValueAsc = 'WIDGET_SETTINGS_MAX_VALUE_ASC',
  WidgetSettingsMaxValueDesc = 'WIDGET_SETTINGS_MAX_VALUE_DESC',
  WidgetSettingsMaxCreatedAtAsc = 'WIDGET_SETTINGS_MAX_CREATED_AT_ASC',
  WidgetSettingsMaxCreatedAtDesc = 'WIDGET_SETTINGS_MAX_CREATED_AT_DESC',
  WidgetSettingsMaxUpdatedAtAsc = 'WIDGET_SETTINGS_MAX_UPDATED_AT_ASC',
  WidgetSettingsMaxUpdatedAtDesc = 'WIDGET_SETTINGS_MAX_UPDATED_AT_DESC',
  WidgetSettingsAverageIdAsc = 'WIDGET_SETTINGS_AVERAGE_ID_ASC',
  WidgetSettingsAverageIdDesc = 'WIDGET_SETTINGS_AVERAGE_ID_DESC',
  WidgetSettingsAverageWidgetIdAsc = 'WIDGET_SETTINGS_AVERAGE_WIDGET_ID_ASC',
  WidgetSettingsAverageWidgetIdDesc = 'WIDGET_SETTINGS_AVERAGE_WIDGET_ID_DESC',
  WidgetSettingsAverageTopicAsc = 'WIDGET_SETTINGS_AVERAGE_TOPIC_ASC',
  WidgetSettingsAverageTopicDesc = 'WIDGET_SETTINGS_AVERAGE_TOPIC_DESC',
  WidgetSettingsAverageFieldKeyAsc = 'WIDGET_SETTINGS_AVERAGE_FIELD_KEY_ASC',
  WidgetSettingsAverageFieldKeyDesc = 'WIDGET_SETTINGS_AVERAGE_FIELD_KEY_DESC',
  WidgetSettingsAverageValueAsc = 'WIDGET_SETTINGS_AVERAGE_VALUE_ASC',
  WidgetSettingsAverageValueDesc = 'WIDGET_SETTINGS_AVERAGE_VALUE_DESC',
  WidgetSettingsAverageCreatedAtAsc = 'WIDGET_SETTINGS_AVERAGE_CREATED_AT_ASC',
  WidgetSettingsAverageCreatedAtDesc = 'WIDGET_SETTINGS_AVERAGE_CREATED_AT_DESC',
  WidgetSettingsAverageUpdatedAtAsc = 'WIDGET_SETTINGS_AVERAGE_UPDATED_AT_ASC',
  WidgetSettingsAverageUpdatedAtDesc = 'WIDGET_SETTINGS_AVERAGE_UPDATED_AT_DESC',
  WidgetSettingsStddevSampleIdAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_ID_ASC',
  WidgetSettingsStddevSampleIdDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_ID_DESC',
  WidgetSettingsStddevSampleWidgetIdAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsStddevSampleWidgetIdDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsStddevSampleTopicAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_TOPIC_ASC',
  WidgetSettingsStddevSampleTopicDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_TOPIC_DESC',
  WidgetSettingsStddevSampleFieldKeyAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsStddevSampleFieldKeyDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsStddevSampleValueAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_VALUE_ASC',
  WidgetSettingsStddevSampleValueDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_VALUE_DESC',
  WidgetSettingsStddevSampleCreatedAtAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsStddevSampleCreatedAtDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsStddevSampleUpdatedAtAsc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsStddevSampleUpdatedAtDesc = 'WIDGET_SETTINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsStddevPopulationIdAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_ID_ASC',
  WidgetSettingsStddevPopulationIdDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_ID_DESC',
  WidgetSettingsStddevPopulationWidgetIdAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsStddevPopulationWidgetIdDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsStddevPopulationTopicAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_TOPIC_ASC',
  WidgetSettingsStddevPopulationTopicDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_TOPIC_DESC',
  WidgetSettingsStddevPopulationFieldKeyAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsStddevPopulationFieldKeyDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsStddevPopulationValueAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_VALUE_ASC',
  WidgetSettingsStddevPopulationValueDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_VALUE_DESC',
  WidgetSettingsStddevPopulationCreatedAtAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_CREATED_AT_ASC',
  WidgetSettingsStddevPopulationCreatedAtDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_CREATED_AT_DESC',
  WidgetSettingsStddevPopulationUpdatedAtAsc = 'WIDGET_SETTINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsStddevPopulationUpdatedAtDesc = 'WIDGET_SETTINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  WidgetSettingsVarianceSampleIdAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_ID_ASC',
  WidgetSettingsVarianceSampleIdDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_ID_DESC',
  WidgetSettingsVarianceSampleWidgetIdAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_WIDGET_ID_ASC',
  WidgetSettingsVarianceSampleWidgetIdDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_WIDGET_ID_DESC',
  WidgetSettingsVarianceSampleTopicAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_TOPIC_ASC',
  WidgetSettingsVarianceSampleTopicDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_TOPIC_DESC',
  WidgetSettingsVarianceSampleFieldKeyAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_FIELD_KEY_ASC',
  WidgetSettingsVarianceSampleFieldKeyDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_FIELD_KEY_DESC',
  WidgetSettingsVarianceSampleValueAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_VALUE_ASC',
  WidgetSettingsVarianceSampleValueDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_VALUE_DESC',
  WidgetSettingsVarianceSampleCreatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  WidgetSettingsVarianceSampleCreatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  WidgetSettingsVarianceSampleUpdatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  WidgetSettingsVarianceSampleUpdatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  WidgetSettingsVariancePopulationIdAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_ID_ASC',
  WidgetSettingsVariancePopulationIdDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_ID_DESC',
  WidgetSettingsVariancePopulationWidgetIdAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_WIDGET_ID_ASC',
  WidgetSettingsVariancePopulationWidgetIdDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_WIDGET_ID_DESC',
  WidgetSettingsVariancePopulationTopicAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_TOPIC_ASC',
  WidgetSettingsVariancePopulationTopicDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_TOPIC_DESC',
  WidgetSettingsVariancePopulationFieldKeyAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_FIELD_KEY_ASC',
  WidgetSettingsVariancePopulationFieldKeyDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_FIELD_KEY_DESC',
  WidgetSettingsVariancePopulationValueAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_VALUE_ASC',
  WidgetSettingsVariancePopulationValueDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_VALUE_DESC',
  WidgetSettingsVariancePopulationCreatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  WidgetSettingsVariancePopulationCreatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  WidgetSettingsVariancePopulationUpdatedAtAsc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  WidgetSettingsVariancePopulationUpdatedAtDesc = 'WIDGET_SETTINGS_VARIANCE_POPULATION_UPDATED_AT_DESC'
}

export type ZohoSubscriptionsResponse = {
  code: Scalars['Int'];
  message: Scalars['String'];
  response: Scalars['String'];
};

/** An object where the defined keys will be set on the `analytic` being updated. */
export type UpdateAnalyticOnAnalyticForAnalyticsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  referrerUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<AnalyticsCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `appointment` being updated. */
export type UpdateAppointmentOnAppointmentForAppointmentsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** An object where the defined keys will be set on the `appointment` being updated. */
export type UpdateAppointmentOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  additionalInformation?: Maybe<Scalars['String']>;
  appointmentAt?: Maybe<Scalars['Datetime']>;
  confirmedAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<AppointmentsLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<AppointmentsVehicleListingsIdFkeyInput>;
};

/** An object where the defined keys will be set on the `avaApp` being updated. */
export type UpdateAvaAppOnAvaAppForAvaAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `avaApp` being updated. */
export type UpdateAvaAppOnCreditBureauForCreditBureauAvaAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  creditVerified?: Maybe<Scalars['Boolean']>;
  dealerCreditConsent?: Maybe<Scalars['Boolean']>;
  creditScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  creditRange?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<AvaAppLeadIdFkeyInput>;
  creditBureausUsingId?: Maybe<CreditBureauAvaAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `channel` being updated. */
export type UpdateChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `channel` being updated. */
export type UpdateChannelOnFieldConfigForFieldConfigChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyChannelsUsingId?: Maybe<CompanyChannelChannelIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyAnalytic` being updated. */
export type UpdateCompanyAnalyticOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AnalyticsType>;
  analyticsId?: Maybe<Scalars['String']>;
  analyticsSecret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FkCompanyAnalyticsCompanyIdInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnCompanyChannelForCompanyChannelChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyChannel` being updated. */
export type UpdateCompanyChannelOnFieldForFieldCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  channelToChannelId?: Maybe<CompanyChannelChannelIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyChannelCompanyIdFkeyInput>;
  fieldsUsingId?: Maybe<FieldCompanyChannelIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyCta` being updated. */
export type UpdateCompanyCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyCta` being updated. */
export type UpdateCompanyCtaOnCompanyCtaForCompanyCtasGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** Correlates directly to the `theme` name that exists on the CTA record */
  theme?: Maybe<Scalars['String']>;
  /** User friendly name of this override. */
  name?: Maybe<Scalars['String']>;
  /**
   * Whether this override should be locked to the specific minor/patch version number.
   *
   * If true, this override will not be applied on any compatible major version update.
   */
  locked?: Maybe<Scalars['Boolean']>;
  /**
   * When no specific theme is requested, the theme & values from this override will be used.
   *
   * Only one `default` record should exist at a time.
   */
  default?: Maybe<Scalars['Boolean']>;
  templateDef?: Maybe<Scalars['JSON']>;
  themeDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  compatibleMajor?: Maybe<Scalars['Int']>;
  ctaDetails?: Maybe<Scalars['JSON']>;
  ctaToCtaId?: Maybe<CompanyCtasCtaIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyCtasGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomain` being updated. */
export type UpdateCompanyDomainOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsCompanyIdFkeyInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathDomainIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
export type UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch = {
  domainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsApproved` being updated. */
export type UpdateCompanyDomainsApprovedOnCompanyDomainsApprovedForCompanyDomainsApprovedDomainIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsApprovedDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
export type UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyDomainsPath` being updated. */
export type UpdateCompanyDomainsPathOnCompanyDomainsPathForCompanyDomainsPathDomainIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyDomainsPathCompanyIdFkeyInput>;
  companyDomainToDomainId?: Maybe<CompanyDomainsPathDomainIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyGroup` being updated. */
export type UpdateCompanyGroupOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch = {
  groupId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyGroup` being updated. */
export type UpdateCompanyGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyGroupCompanyIdFkeyInput>;
  groupToGroupId?: Maybe<CompanyGroupGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyIntegration` being updated. */
export type UpdateCompanyIntegrationOnFieldForFieldCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<CompanyIntegrationIntegrationIdFkeyInput>;
  companyToCompanyId?: Maybe<CompanyIntegrationCompanyIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyLocation` being updated. */
export type UpdateCompanyLocationOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<CompanyLocationCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnAnalyticForAnalyticsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyAnalyticForFkCompanyAnalyticsCompanyIdPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyChannelForCompanyChannelCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainForCompanyDomainsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainsApprovedForCompanyDomainsApprovedCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyDomainsPathForCompanyDomainsPathCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyForCompanyPrimaryGroupFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyGroupForCompanyGroupCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyIntegrationForCompanyIntegrationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyLocationForCompanyLocationCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnCompanyProductForCompanyProductCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnFieldForFieldCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnLeadForLeadCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `company` being updated. */
export type UpdateCompanyOnReviewForReviewsCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  equifaxCustomerNumber?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  archived?: Maybe<Scalars['Boolean']>;
  zohoId?: Maybe<Scalars['String']>;
  deliverTo?: Maybe<Scalars['Boolean']>;
  primaryGroup?: Maybe<Scalars['Int']>;
  rawMeta?: Maybe<Scalars['JSON']>;
  billableCompanyId?: Maybe<Scalars['Int']>;
  rawRequestedAccess?: Maybe<Scalars['JSON']>;
  lenderDealerCode?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  legalConsent?: Maybe<Scalars['Boolean']>;
  groupToPrimaryGroup?: Maybe<CompanyPrimaryGroupFkeyInput>;
  leadsUsingId?: Maybe<LeadCompanyIdFkeyInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupCompanyIdFkeyInverseInput>;
  companyProductsUsingId?: Maybe<CompanyProductCompanyIdFkeyInverseInput>;
  companyLocationsUsingId?: Maybe<CompanyLocationCompanyIdFkeyInverseInput>;
  companyAnalyticsUsingId?: Maybe<FkCompanyAnalyticsCompanyIdInverseInput>;
  companyDomainsUsingId?: Maybe<CompanyDomainsCompanyIdFkeyInverseInput>;
  companyDomainsApprovedsUsingId?: Maybe<CompanyDomainsApprovedCompanyIdFkeyInverseInput>;
  companyDomainsPathsUsingId?: Maybe<CompanyDomainsPathCompanyIdFkeyInverseInput>;
  reviewsUsingId?: Maybe<ReviewsCompanyIdFkeyInverseInput>;
  analyticsUsingId?: Maybe<AnalyticsCompanyIdFkeyInverseInput>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationCompanyIdFkeyInverseInput>;
  companyChannelsUsingId?: Maybe<CompanyChannelCompanyIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
export type UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyProductIntegration` being updated. */
export type UpdateCompanyProductIntegrationOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyIntegrationToCompanyIntegrationId?: Maybe<CompanyProductIntegrationCompanyIntegrationIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductForCompanyProductCompanyIdFkeyPatch = {
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductForCompanyProductProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnCompanyProductIntegrationForCompanyProductIntegrationCompanyProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyProduct` being updated. */
export type UpdateCompanyProductOnFieldForFieldCompanyProductIdFkeyPatch = {
  companyId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  companyToCompanyId?: Maybe<CompanyProductCompanyIdFkeyInput>;
  productToProductId?: Maybe<CompanyProductProductIdFkeyInput>;
  companyProductIntegrationsUsingId?: Maybe<CompanyProductIntegrationCompanyProductIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldCompanyProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `companyWidget` being updated. */
export type UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch = {
  widgetId?: Maybe<Scalars['String']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `companyWidget` being updated. */
export type UpdateCompanyWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch = {
  groupId?: Maybe<Scalars['Int']>;
  specOverride?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<CompanyWidgetsGroupIdFkInput>;
  widgetToWidgetId?: Maybe<CompanyWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `creditApp` being updated. */
export type UpdateCreditAppOnCreditAppForCreditAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  desiredVehicle?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  preferredContactMethod?: Maybe<Scalars['String']>;
  salaryHourly?: Maybe<Scalars['String']>;
  incomeSource?: Maybe<Scalars['String']>;
  incomeTime?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  creditConcern?: Maybe<Scalars['String']>;
  residenceTime?: Maybe<Scalars['String']>;
  rentOrOwn?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Float']>;
  employmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<CreditAppLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `creditBureau` being updated. */
export type UpdateCreditBureauOnCreditBureauForCreditBureauAvaAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  /** company | lead */
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  creditScore?: Maybe<Scalars['Int']>;
  rawBureau?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  rawExpired?: Maybe<Scalars['Boolean']>;
  xml?: Maybe<Scalars['String']>;
  avaAppToAvaAppId?: Maybe<CreditBureauAvaAppIdFkeyInput>;
};

/** An object where the defined keys will be set on the `ctaAsset` being updated. */
export type UpdateCtaAssetOnCtaAssetForCtaAssetsGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<CtaAssetsGroupIdFkeyInput>;
};

/** An object where the defined keys will be set on the `cta` being updated. */
export type UpdateCtaOnCompanyCtaForCompanyCtasCtaIdFkeyPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `cta` being updated. */
export type UpdateCtaOnCtaWidgetForCtaWidgetsCtaIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  type?: Maybe<CtaType>;
  version?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  /** The CSS stylesheet for this CTA */
  ctaStyles?: Maybe<Scalars['String']>;
  /** Stores the layout thrashing mitigation CSS.  Named "static" because it is added as a static value to the host page. */
  ctaStylesStatic?: Maybe<Scalars['String']>;
  /** The parsed CTA structure */
  ctaTree?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `templateDef`, and their types */
  templateSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the template.  These will be inserted into the `cta_tree` structure, and can also be used in `plugin_def` */
  templateDef?: Maybe<Scalars['JSON']>;
  /** Specification defining what fields are available for the `themeDef`, and their types */
  themeSpec?: Maybe<Scalars['JSON']>;
  /** Actual definitions for the theme.  These values will be inserted as SCSS variables into the CTA style sheets */
  themeDef?: Maybe<Scalars['JSON']>;
  /** Values that will be added to plugins, if not defined in the plugin's props in `cta_tree`. */
  pluginDef?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Automatically generated from "version"; do not set */
  compatibleMajor?: Maybe<Scalars['Int']>;
  invalidate?: Maybe<Array<Maybe<Scalars['String']>>>;
  themes?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCtasUsingId?: Maybe<CompanyCtasCtaIdFkeyInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsCtaIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `ctaWidget` being updated. */
export type UpdateCtaWidgetOnCtaWidgetForCtaWidgetsCtaIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  widgetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `ctaWidget` being updated. */
export type UpdateCtaWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  ctaId?: Maybe<Scalars['String']>;
  /** The ID of a static CTA (not in the ctas table) */
  staticId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  ctaToCtaId?: Maybe<CtaWidgetsCtaIdFkInput>;
  widgetToWidgetId?: Maybe<CtaWidgetsWidgetIdFkInput>;
};

/** An object where the defined keys will be set on the `deliveryMethod` being updated. */
export type UpdateDeliveryMethodOnDeliveryMethodForDeliveryMethodGroupIdFkPatch = {
  type?: Maybe<DeliveryMethodType>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  config?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['JSON']>;
  groupToGroupId?: Maybe<DeliveryMethodGroupIdFkInput>;
};

/** An object where the defined keys will be set on the `event` being updated. */
export type UpdateEventOnEventForFakePublicEventsForeignKey0Patch = {
  id?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<FakePublicEventsForeignKey0Input>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnFieldConfigForFieldConfigChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnFieldConfigForFieldConfigIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnFieldConfigForFieldConfigProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnFieldForFieldConfigFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldConfig` being updated. */
export type UpdateFieldConfigOnOptionForOptionFieldConfigIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  type?: Maybe<FieldType>;
  label?: Maybe<Scalars['String']>;
  helper?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  productToProductId?: Maybe<FieldConfigProductIdFkeyInput>;
  channelToChannelId?: Maybe<FieldConfigChannelIdFkeyInput>;
  integrationToIntegrationId?: Maybe<FieldConfigIntegrationIdFkeyInput>;
  optionsUsingId?: Maybe<OptionFieldConfigIdFkeyInverseInput>;
  fieldsUsingId?: Maybe<FieldConfigFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `fieldKey` being updated. */
export type UpdateFieldKeyOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  type?: Maybe<FieldType>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  key?: Maybe<Scalars['String']>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsFieldKeyFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyChannelIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldCompanyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  config?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `field` being updated. */
export type UpdateFieldOnFieldForFieldConfigFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  companyProductId?: Maybe<Scalars['Int']>;
  companyChannelId?: Maybe<Scalars['Int']>;
  companyIntegrationId?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<FieldCompanyIdFkeyInput>;
  companyProductToCompanyProductId?: Maybe<FieldCompanyProductIdFkeyInput>;
  companyChannelToCompanyChannelId?: Maybe<FieldCompanyChannelIdFkeyInput>;
  companyIntegrationToCompanyIntegrationId?: Maybe<FieldCompanyIntegrationIdFkeyInput>;
  fieldConfigToConfig?: Maybe<FieldConfigFkeyInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyCtaForCompanyCtasGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyForCompanyPrimaryGroupFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyGroupForCompanyGroupGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCompanyWidgetForCompanyWidgetsGroupIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnCtaAssetForCtaAssetsGroupIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnDeliveryMethodForDeliveryMethodGroupIdFkPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  cognitoName?: Maybe<Scalars['String']>;
  type?: Maybe<GroupType>;
  distributionType?: Maybe<GroupDistType>;
  legalDesc?: Maybe<Scalars['String']>;
  cognitoGroup?: Maybe<Scalars['String']>;
  cognitoAdminGroup?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyUsingId?: Maybe<CompanyPrimaryGroupFkeyInverseInput>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsGroupIdFkInverseInput>;
  companyGroupsUsingId?: Maybe<CompanyGroupGroupIdFkeyInverseInput>;
  ctaAssetsUsingId?: Maybe<CtaAssetsGroupIdFkeyInverseInput>;
  companyCtasUsingId?: Maybe<CompanyCtasGroupIdFkeyInverseInput>;
  deliveryMethodsUsingId?: Maybe<DeliveryMethodGroupIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `idVerification` being updated. */
export type UpdateIdVerificationOnIdVerificationForIdVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  verificationId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
  verificationUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  firstStep?: Maybe<Scalars['String']>;
  transaction?: Maybe<Scalars['JSON']>;
  nextStep?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  verificationUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IdVerificationLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `incomeVerification` being updated. */
export type UpdateIncomeVerificationOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  loginToken?: Maybe<Scalars['String']>;
  directDepositAccountId?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  rawAccounts?: Maybe<Scalars['JSON']>;
  report?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  verifiedAt?: Maybe<Scalars['Datetime']>;
  provider?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<IncomeVerificationLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnCompanyIntegrationForCompanyIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnFieldConfigForFieldConfigIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `integration` being updated. */
export type UpdateIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  companyIntegrationsUsingId?: Maybe<CompanyIntegrationIntegrationIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationIntegrationIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigIntegrationIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `leadCampaign` being updated. */
export type UpdateLeadCampaignOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  s1?: Maybe<Scalars['String']>;
  s2?: Maybe<Scalars['String']>;
  s3?: Maybe<Scalars['String']>;
  s4?: Maybe<Scalars['String']>;
  s5?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<LeadCampaignsLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnAppointmentForAppointmentsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnAvaAppForAvaAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnCreditAppForCreditAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnEventForFakePublicEventsForeignKey0Patch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnIdVerificationForIdVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnIncomeVerificationForIncomeVerificationLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadCampaignForLeadCampaignsLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadForLeadCompanyIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLeadJourneyForLeadJourneyLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLenderAppForLenderAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnLenderQuoteForLenderQuotesLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lead` being updated. */
export type UpdateLeadOnTradeAppForTradeAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  transactionKey?: Maybe<Scalars['String']>;
  leadspediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  searchText?: Maybe<Scalars['FullText']>;
  comment?: Maybe<Scalars['String']>;
  state?: Maybe<LeadState>;
  read?: Maybe<Scalars['Boolean']>;
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  additionalInformation?: Maybe<Scalars['JSON']>;
  phoneVerifiedAt?: Maybe<Scalars['Datetime']>;
  ipAddress?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<LeadPriority>;
  assignee?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  companyToCompanyId?: Maybe<LeadCompanyIdFkeyInput>;
  avaAppUsingId?: Maybe<AvaAppLeadIdFkeyInverseInput>;
  creditAppUsingId?: Maybe<CreditAppLeadIdFkeyInverseInput>;
  leadCampaignUsingId?: Maybe<LeadCampaignsLeadIdFkeyInverseInput>;
  tradeAppsUsingId?: Maybe<TradeAppLeadIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyLeadIdFkeyInverseInput>;
  lenderAppsUsingId?: Maybe<LenderAppLeadIdFkeyInverseInput>;
  idVerificationUsingId?: Maybe<IdVerificationLeadIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsLeadIdFkeyInverseInput>;
  incomeVerificationUsingId?: Maybe<IncomeVerificationLeadIdFkeyInverseInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLeadIdFkeyInverseInput>;
  eventsUsingId?: Maybe<FakePublicEventsForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderAppForLenderAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderAppForLenderAppVehicleListingIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderApp` being updated. */
export type UpdateLenderAppOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  inovatecApplicationNumber?: Maybe<Scalars['String']>;
  inovatecApplicationId?: Maybe<Scalars['Int']>;
  yearlyIncome?: Maybe<Scalars['Int']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyHomePayment?: Maybe<Scalars['Int']>;
  preferredOfferId?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  vehicleListingId?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  leadToLeadId?: Maybe<LenderAppLeadIdFkeyInput>;
  vehicleListingToVehicleListingId?: Maybe<LenderAppVehicleListingIdFkeyInput>;
  lenderQuotesUsingId?: Maybe<LenderQuotesLenderAppIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  lenderAppId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lenderQuote` being updated. */
export type UpdateLenderQuoteOnLenderQuoteForLenderQuotesLenderAppIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['UUID']>;
  provider?: Maybe<Scalars['String']>;
  quoteJson?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<LenderQuoteType>;
  expired?: Maybe<Scalars['Boolean']>;
  leadToLeadId?: Maybe<LenderQuotesLeadIdFkeyInput>;
  lenderAppToLenderAppId?: Maybe<LenderQuotesLenderAppIdFkeyInput>;
};

/** An object where the defined keys will be set on the `option` being updated. */
export type UpdateOptionOnOptionForOptionFieldConfigIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  fieldConfigToFieldConfigId?: Maybe<OptionFieldConfigIdFkeyInput>;
};

/** An object where the defined keys will be set on the `productIntegration` being updated. */
export type UpdateProductIntegrationOnProductIntegrationForProductIntegrationIntegrationIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `productIntegration` being updated. */
export type UpdateProductIntegrationOnProductIntegrationForProductIntegrationProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  integrationToIntegrationId?: Maybe<ProductIntegrationIntegrationIdFkeyInput>;
  productToProductId?: Maybe<ProductIntegrationProductIdFkeyInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnCompanyProductForCompanyProductProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnFieldConfigForFieldConfigProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnLeadJourneyForLeadJourneyProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `product` being updated. */
export type UpdateProductOnProductIntegrationForProductIntegrationProductIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<ProductType>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyProductsUsingId?: Maybe<CompanyProductProductIdFkeyInverseInput>;
  leadJourneysUsingId?: Maybe<LeadJourneyProductIdFkeyInverseInput>;
  productIntegrationsUsingId?: Maybe<ProductIntegrationProductIdFkeyInverseInput>;
  fieldConfigsUsingId?: Maybe<FieldConfigProductIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `review` being updated. */
export type UpdateReviewOnReviewForReviewsCompanyIdFkeyPatch = {
  provider?: Maybe<ReviewProvider>;
  authorName?: Maybe<Scalars['String']>;
  authorPhotoUrl?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  companyToCompanyId?: Maybe<ReviewsCompanyIdFkeyInput>;
};

/** An object where the defined keys will be set on the `tradeApp` being updated. */
export type UpdateTradeAppOnTradeAppForTradeAppLeadIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  trim?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  condition?: Maybe<VehicleCondition>;
  tradeInValue?: Maybe<Scalars['Float']>;
  maxTradeInValue?: Maybe<Scalars['Float']>;
  minTradeInValue?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  leadToLeadId?: Maybe<TradeAppLeadIdFkeyInput>;
};

/** An object where the defined keys will be set on the `vehicleListing` being updated. */
export type UpdateVehicleListingOnAppointmentForAppointmentsVehicleListingsIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `vehicleListing` being updated. */
export type UpdateVehicleListingOnLenderAppForLenderAppVehicleListingIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  listingMsrp?: Maybe<Scalars['BigFloat']>;
  listingPrice?: Maybe<Scalars['BigFloat']>;
  listingUrl?: Maybe<Scalars['String']>;
  listingTitle?: Maybe<Scalars['String']>;
  vehicleVin?: Maybe<Scalars['String']>;
  vehicleYear?: Maybe<Scalars['Int']>;
  vehicleMake?: Maybe<Scalars['String']>;
  vehicleModel?: Maybe<Scalars['String']>;
  vehicleTrim?: Maybe<Scalars['String']>;
  vehicleMileage?: Maybe<Scalars['Int']>;
  vehicleCondition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  listingImageUrl?: Maybe<Scalars['String']>;
  vehicleEngine?: Maybe<Scalars['String']>;
  vehicleTransmission?: Maybe<Scalars['String']>;
  vehicleExteriorColor?: Maybe<Scalars['String']>;
  vehicleInteriorColor?: Maybe<Scalars['String']>;
  vehicleStatus?: Maybe<Scalars['String']>;
  vehicleStockNumber?: Maybe<Scalars['String']>;
  lenderAppsUsingId?: Maybe<LenderAppVehicleListingIdFkeyInverseInput>;
  appointmentsUsingId?: Maybe<AppointmentsVehicleListingsIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnCompanyWidgetForCompanyWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnCtaWidgetForCtaWidgetsWidgetIdFkPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widget` being updated. */
export type UpdateWidgetOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch = {
  id?: Maybe<Scalars['String']>;
  widgetSpec?: Maybe<Scalars['JSON']>;
  ctaTheme?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  rawConfig?: Maybe<Scalars['JSON']>;
  companyWidgetsUsingId?: Maybe<CompanyWidgetsWidgetIdFkInverseInput>;
  ctaWidgetsUsingId?: Maybe<CtaWidgetsWidgetIdFkInverseInput>;
  widgetSettingsUsingId?: Maybe<WidgetSettingsWidgetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `widgetSetting` being updated. */
export type UpdateWidgetSettingOnWidgetSettingForWidgetSettingsFieldKeyFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  widgetId?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

/** An object where the defined keys will be set on the `widgetSetting` being updated. */
export type UpdateWidgetSettingOnWidgetSettingForWidgetSettingsWidgetIdFkeyPatch = {
  id?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  fieldKey?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  widgetToWidgetId?: Maybe<WidgetSettingsWidgetIdFkeyInput>;
  fieldKeyToFieldKey?: Maybe<WidgetSettingsFieldKeyFkeyInput>;
};

export type SendNotificationMutationVariables = Exact<{
  leadId: Scalars['UUID'];
}>;


export type SendNotificationMutation = { triggerLeadNotification: { leadId: any, success: boolean } };

export type RequestVerificationMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  type: VerificationChannel;
}>;


export type RequestVerificationMutation = { requestVerification?: Maybe<{ success: boolean, leadId: string, phoneVerifiedAt?: Maybe<string> }> };

export type ConfirmVerificationCodeMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  type: VerificationChannel;
  code: Scalars['String'];
}>;


export type ConfirmVerificationCodeMutation = { confirmVerificationCode?: Maybe<{ success: boolean, leadId: string, phoneVerifiedAt?: Maybe<string> }> };

export type UpdateLeadMetaMutationVariables = Exact<{
  leadId: Scalars['UUID'];
  companyId: Scalars['Int'];
  meta: Scalars['JSON'];
}>;


export type UpdateLeadMetaMutation = { updateLeadMeta?: Maybe<{ leadId: any, meta?: Maybe<any> }> };

export type GetLeadInfoQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type GetLeadInfoQuery = { getLeadInfo?: Maybe<{ id: any, firstName?: Maybe<string>, lastName?: Maybe<string>, dateOfBirth?: Maybe<string>, phoneNumber?: Maybe<string>, emailAddress?: Maybe<string>, postalCode?: Maybe<string>, provinceCode?: Maybe<string>, streetNumber?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, additionalInformation?: Maybe<any>, company?: Maybe<{ id?: Maybe<number>, name?: Maybe<string> }>, creditApp?: Maybe<{ id: number, employmentStatus?: Maybe<string>, monthlyIncome?: Maybe<number> }> }> };

export type GetWidgetDetailsQueryVariables = Exact<{
  widget: Scalars['String'];
  ctaId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
}>;


export type GetWidgetDetailsQuery = { getWidgetDetails: { company: { id: number, name: string, widgetId: string, archived: boolean, products: Array<ProductType> }, group: { id: number, name: string, legalDesc?: Maybe<string>, distributionType: GroupDistType }, memberCompanies: Array<{ id: number, name: string, widgetId: string, archived: boolean, products: Array<ProductType> }>, fields: Array<{ product?: Maybe<string>, integration?: Maybe<string>, channel?: Maybe<string>, topic?: Maybe<string>, key?: Maybe<string>, value?: Maybe<string> }>, productIntegrations: Array<{ product?: Maybe<string>, integrations?: Maybe<Array<string>> }> } };

export type AnalyticsTrackingMutationVariables = Exact<{
  input: AnalyticsParamsInput;
}>;


export type AnalyticsTrackingMutation = { trackAnalytics: { success: boolean, eventType?: Maybe<string>, analyticsPayload?: Maybe<Array<any>> } };

export type AnswerMutationVariables = Exact<{
  input: CreditQueryInput;
}>;


export type AnswerMutation = { creditQuery?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }>, report?: Maybe<{ score?: Maybe<{ value?: Maybe<number> }>, trades: Array<{ dateOpened?: Maybe<string>, balanceAmount?: Maybe<number>, paymentTermAmount?: Maybe<number>, association?: Maybe<string>, highCreditAmount?: Maybe<number>, dateLastActivityOrPayment?: Maybe<string>, monthsReviewed?: Maybe<string>, paymentTerm?: Maybe<string>, lastSlowPayment?: Maybe<string>, creditor?: Maybe<{ name?: Maybe<string> }>, portfolioType?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, paymentRate?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, derogatoryCounters?: Maybe<{ past30: number, past60: number, past90: number }> }>, inquiries: Array<{ date?: Maybe<string>, inquirer?: Maybe<{ id?: Maybe<string>, name?: Maybe<string>, phone?: Maybe<string> }> }> }> }> };

export type AuthMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: CreditAuthInput;
}>;


export type AuthMutation = { creditAuth?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, consumer: string, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }> }> };

export type SubmitLoanAppMutationVariables = Exact<{
  input: LoanAppInput;
}>;


export type SubmitLoanAppMutation = { loanApp?: Maybe<{ leadId?: Maybe<any>, msg?: Maybe<string>, result?: Maybe<string> }> };

export type SubmitPartialLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitPartialLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type SubmitDriveLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitDriveLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type GetLenderAnswerMutationVariables = Exact<{
  input: CreditQueryInput;
}>;


export type GetLenderAnswerMutation = { creditQuery?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }>, report?: Maybe<{ score?: Maybe<{ value?: Maybe<number> }>, trades: Array<{ dateOpened?: Maybe<string>, balanceAmount?: Maybe<number>, paymentTermAmount?: Maybe<number>, association?: Maybe<string>, highCreditAmount?: Maybe<number>, dateLastActivityOrPayment?: Maybe<string>, monthsReviewed?: Maybe<string>, paymentTerm?: Maybe<string>, lastSlowPayment?: Maybe<string>, creditor?: Maybe<{ name?: Maybe<string> }>, portfolioType?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, paymentRate?: Maybe<{ code?: Maybe<string>, description?: Maybe<string> }>, derogatoryCounters?: Maybe<{ past30: number, past60: number, past90: number }> }>, inquiries: Array<{ date?: Maybe<string>, inquirer?: Maybe<{ id?: Maybe<string>, name?: Maybe<string>, phone?: Maybe<string> }> }> }> }> };

export type FetchLenderQuotesMutationVariables = Exact<{
  input: FetchLenderQuotesInput;
}>;


export type FetchLenderQuotesMutation = { fetchLenderQuotes?: Maybe<{ success: boolean, quotes?: Maybe<Array<Maybe<{ id: number, provider?: Maybe<string>, quoteJson?: Maybe<any>, createdAt: any, updatedAt: any, lenderApp?: Maybe<{ id: number, yearlyIncome: number, employmentStatus: string, monthlyHomePayment?: Maybe<number>, createdAt: any, updatedAt: any, vehicleListing?: Maybe<{ id: number, listingMsrp?: Maybe<any>, listingPrice?: Maybe<any>, listingUrl?: Maybe<string>, listingImageUrl?: Maybe<string>, listingTitle?: Maybe<string>, vehicleVin?: Maybe<string>, vehicleYear?: Maybe<number>, vehicleMake?: Maybe<string>, vehicleModel?: Maybe<string>, vehicleTrim?: Maybe<string>, vehicleMileage?: Maybe<number>, vehicleCondition?: Maybe<string>, createdAt: any, updatedAt: any, vehicleEngine?: Maybe<string>, vehicleTransmission?: Maybe<string>, vehicleExteriorColor?: Maybe<string>, vehicleInteriorColor?: Maybe<string>, vehicleStatus?: Maybe<string>, vehicleStockNumber?: Maybe<string> }> }> }>>> }> };

export type LenderAuthMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: CreditAuthInput;
}>;


export type LenderAuthMutation = { creditAuth?: Maybe<{ transactionKey?: Maybe<string>, equifaxServerError?: Maybe<boolean>, equifaxErrorMessages?: Maybe<Array<Maybe<string>>>, fraudCheckFailed?: Maybe<boolean>, consumer: string, interactiveQuery?: Maybe<{ queryId?: Maybe<string>, questions?: Maybe<Array<{ questionId: string, questionText: string, answers?: Maybe<Array<{ answerId: string, value: string }>> }>> }> }> };

export type SetPreferredLenderOfferMutationVariables = Exact<{
  input: SetPreferredLenderOfferInput;
}>;


export type SetPreferredLenderOfferMutation = { setPreferredLenderOffer?: Maybe<{ preferredOfferId?: Maybe<number>, offers?: Maybe<Array<Maybe<{ offerId: number, averageRate?: Maybe<number>, estimatedBiweeklyPayment?: Maybe<number>, estimatedMonthlyPayment?: Maybe<number>, estimatedWeeklyPayment?: Maybe<number>, maxAmountFinanced?: Maybe<number>, maxLtvPassFail?: Maybe<number>, maxLtv?: Maybe<number>, maxPayment?: Maybe<number>, maxPti?: Maybe<number>, maxPaymentPassFail?: Maybe<number>, maxPtiPassFail?: Maybe<number>, maxTdsr?: Maybe<number>, maxTerm?: Maybe<number>, maxTdsrPassFail?: Maybe<number>, lenderId?: Maybe<string>, name?: Maybe<string> }>>> }> };

export type SubmitLenderPartialMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitLenderPartialMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type SubmitMsaLeadMutationVariables = Exact<{
  widgetId: Scalars['String'];
  input: LeadInput;
  product: ProductType;
}>;


export type SubmitMsaLeadMutation = { submitLead?: Maybe<{ leadId: string, success: boolean }> };

export type NotifyUserBySmsMutationVariables = Exact<{
  input: SmsUserNotificationInput;
}>;


export type NotifyUserBySmsMutation = { notifyUserBySms?: Maybe<{ success: boolean, from?: Maybe<string>, to?: Maybe<string>, smsBody?: Maybe<string>, error?: Maybe<string> }> };

export type SubmitTradeAppLeadMutationVariables = Exact<{
  input: TradeAppCustomInput;
  widgetId: Scalars['String'];
}>;


export type SubmitTradeAppLeadMutation = { createTradeAppLead?: Maybe<{ leadId?: Maybe<any>, tradeApp?: Maybe<{ id?: Maybe<number> }>, error?: Maybe<{ code?: Maybe<TradeAppErrorCode>, message: string }> }> };

export type GetVehiclePricesQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  condition: TradeAppVehicleCondition;
  mileage: Scalars['Int'];
  provider?: Maybe<TradeProvider>;
}>;


export type GetVehiclePricesQuery = { tradeAppGetPrices: { __typename: 'TradeAppError', code?: Maybe<TradeAppErrorCode>, message: string } | { __typename: 'TradeAppTradeValue', fairPurchasePrice?: Maybe<number>, priceLow?: Maybe<number>, baseDiscountedPrice?: Maybe<number>, priceAdvisor?: Maybe<string>, priceHigh?: Maybe<number> } };

export type GetTradeInValueQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  condition: TradeAppVehicleCondition;
  mileage: Scalars['Int'];
  location?: Maybe<TradeAppLocation>;
}>;


export type GetTradeInValueQuery = { tradeAppGetTradeValue?: Maybe<{ priceLow?: Maybe<number>, priceHigh?: Maybe<number>, fairPurchasePrice?: Maybe<number>, baseDiscountedPrice?: Maybe<number>, priceAdvisor?: Maybe<string> }> };

export type VehicleLookupQueryVariables = Exact<{
  widgetId: Scalars['String'];
  keyword: Scalars['String'];
}>;


export type VehicleLookupQuery = { vehicleLookup: { results?: Maybe<Array<Maybe<{ description?: Maybe<string>, make?: Maybe<string>, model?: Maybe<string>, series?: Maybe<string>, style?: Maybe<string>, vehicleId?: Maybe<string>, year?: Maybe<string> }>>> } };

export type GetAllModelsQueryVariables = Exact<{
  makeId: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
}>;


export type GetAllModelsQuery = { tradeAppGetAllModels?: Maybe<Array<Maybe<{ modelId?: Maybe<number>, modelName?: Maybe<string> }>>> };

export type GetAllTrimsQueryVariables = Exact<{
  modelId: Scalars['String'];
  year: Scalars['Int'];
}>;


export type GetAllTrimsQuery = { tradeAppGetAllTrims?: Maybe<Array<Maybe<{ vehicleId?: Maybe<string>, trimName?: Maybe<string> }>>> };

export type GetAllYearsMakesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllYearsMakesQuery = { tradeAppGetSupportedMakes: Array<{ makeIds: Array<number>, modelYear: number }> };

export type SearchModelsTrimsQueryVariables = Exact<{
  yearMake: Scalars['String'];
}>;


export type SearchModelsTrimsQuery = { tradeAppSearch?: Maybe<{ total?: Maybe<number>, results?: Maybe<Array<Maybe<{ trim?: Maybe<string>, vehicleId?: Maybe<string>, modelId?: Maybe<number>, model?: Maybe<string>, makeId?: Maybe<number>, msrp?: Maybe<number> }>>> }> };
