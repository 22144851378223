// import { ProductType as PT } from "@graphql/schema";
// export * from "@shared/products";

// declare module "@graphql/schema" {
export enum ProductType {
    CreditTool = "CREDIT_TOOL",
    MSATool = "MSA_TOOL",
    TradeTool = "TRADE_TOOL",
    LenderTool = "LENDER_TOOL",
    TradePro = "TRADE_PRO",
    DriveTool = "DRIVE_TOOL"
}
// }

export type AllProducts = CamelCase<keyof typeof ProductType>;
