import { transformFromPhone } from "@util/phone";
import {
    rawPostalCode,
    dateToRaw,
    toFloat,
} from "@util/transforms";

import type { ICombinedState } from "@msa-tool/state";
import type { LeadInput } from "@graphql/schema";
import { isNil, omitBy } from "lodash";

export const mapStateToPartialLead = (state: ICombinedState): LeadInput => {
    const partialLead: LeadInput = {
        id: state.leadId || undefined,
        sourceUrl: state.sourceUrl,
        firstName: state.firstName,
        lastName: state.lastName,
        dateOfBirth: dateToRaw(state.dateOfBirth),
        phoneNumber: transformFromPhone(state.phoneNumber),
        emailAddress: state.emailAddress,
        postalCode: rawPostalCode(state.postalCode),
        streetNumber: state.streetNumber,
        street: state.street,
        unit: state.unit,
        city: state.city,
        provinceCode: state.province,
        country: state.country || "CA",
        creditAppUsingId: {
            create: [{
                leadId: state.leadId || undefined,
                desiredVehicle: state.vehicle,
                companyName: state.employer,
                jobTitle: state.position,
                monthlyIncome: toFloat(state.monthlyIncome),
                employmentStatus: state.employmentStatus,
                incomeTime: state.employmentDuration,
                residenceTime: state.addressDuration,
            }],
        },
        meta: {
            productVariant: "msa",
        },
        phoneVerifiedAt: state.phoneVerifiedAt,
        additionalInformation: {
            ...state.additionalInformation,
            interestRate: state.interestRate,
            monthlyBudget: state.monthlyBudget,
        },
    };

    return omitBy(partialLead, isNil) as any as LeadInput;
};

export type MappedPartialPayload = ReturnType<typeof mapStateToPartialLead>;