import { h, FunctionalComponent, VNode, RenderableProps, ComponentProps } from "preact";
import clsx from "clsx";

import { Section } from "../Section";
import { ActionsContainer } from "./container/ActionContainer";

import { Text, ErrorText } from "@components/Text";
import { Button, IButtonProps } from "@components/Button";

import styles from "./Actions.module.scss";

type ComponentPropsOrSymbol<T extends FunctionalComponent<any> | undefined> = (
    T extends FunctionalComponent<any>
        ? Exclude<keyof ComponentProps<T>, keyof IButtonProps> extends never
            ? symbol
            : {
                componentProps: Omit<
                    ComponentProps<T>,
                    keyof IButtonProps
                >
            }
        : symbol
)

type AttachIf<T, O> = O extends symbol
    ? T
    : T & O;

export type ActionsProps<
    TComponent extends undefined | FunctionalComponent<any> = undefined,
> = AttachIf<{
    onClick: () => void | Promise<void>;
    label: string | VNode;
    footnote?: boolean;
    errors?: string[];
    containerClass?: string;
    buttonProps?: IButtonProps;
    disabled?: boolean;
    component?: TComponent;
    suffixIcon?: VNode;
    theme?: string;
}, ComponentPropsOrSymbol<TComponent>>

export const Actions = <TComponent extends undefined | FunctionalComponent<any>>(
    options: RenderableProps<ActionsProps<TComponent>>,
): VNode => {
    const {
        onClick,
        label,
        errors = [],
        footnote = false,
        disabled = false,
        containerClass,
        component: Component,
        componentProps,
        buttonProps = {} as IButtonProps,
        suffixIcon,
        theme = "default",
        children,
    } = options as unknown as RenderableProps<ActionsProps<FunctionalComponent<any>>>;

    const ButtonComponent = Component || Button;
    const buttonClassName = theme === "msa" ? (styles as any).msaButton : undefined;
    return (
        <ActionsContainer className={clsx((styles as any).container, containerClass)}>
            <ButtonComponent
                {...buttonProps}
                {...componentProps || {}}
                onClick={onClick}
                className={buttonProps.className || buttonClassName}
                disabled={disabled}
                suffixIcon={suffixIcon}
            >
                {label}
            </ButtonComponent>
            {children}
            {errors.length > 0 && (
                <Section top={12}>
                    {errors.map((err) => (
                        <ErrorText block>{err}</ErrorText>
                    ))}
                </Section>
            )}
            {footnote && (
                <Section top={12}>
                    <Text block>*This will NOT impact your credit score</Text>
                </Section>
            )}
        </ActionsContainer>
    );
};
