import { frameHandler } from "@host";
import { isCLC } from "./customers";

const REFINANCE_DOMAIN = "https://refi.carloanscanada.com";

export const initial = frameHandler.domain === REFINANCE_DOMAIN ? [
    "vehicle-trade-in",
    "interest-rate",
] : ["vehicle"];

// HELPER TO GENERATE MANY STEPS
export const order = [
    ...initial,
    "date-of-birth",
    "employment",
    "income",
    "employment-duration",
    "budget",
    "employer",
    "address",
    "address-duration",
    "name",
    "email",
    "phone",
    isCLC ? "phone-verify" : "",
    "eid",
].filter(Boolean);

export const MAX_QUESTIONS = order.length;

export const getStepNumber = (search: string) => {
    return order.findIndex((v) => search === v) + 1;
};

export const steps = order.reduce((agg, step, i) => ({
    ...agg,
    [step]: { next: order[i + 1], back: !!i },
}), {});