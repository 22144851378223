import { h, FunctionalComponent, VNode } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";

import clsx from "clsx";
import styles from "./Expandable.module.scss";
import Caret from "@assets/caret.svg";

interface IExpandableProps {
    title: string | VNode;
    content: string | VNode;
    className?: string;
}

export const Expandable: FunctionalComponent<IExpandableProps> = ({
    title,
    content,
    className,
}) => {
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState<string | number>(0);
    const [height, setHeight] = useState<string | number>(0);
    const titleRef = useRef<HTMLDivElement>(null);
    const contentContainer = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const handleTransition = (val: boolean) => {
        if (val) {
            setWidth("100%");
            setOpen(true);
        } else {
            contentContainer.current?.style.setProperty(
                "height",
                `${contentRef.current?.offsetHeight || 0}px`,
            );
            setOpen(false);
        }
    };

    useEffect(() => {
        if (width === "100%") {
            setHeight(contentRef.current?.offsetHeight || 0);
        }
    }, [width]);

    useEffect(() => {
        if (!open) {
            setHeight(0);
        }
    }, [open]);

    const handleTransitionEnd = () => {
        if (open) {
            setHeight("100%");
        } else {
            setWidth(0);
        }
    };

    return (
        <div
            className={clsx(styles.container, className)}
            onClick={() => {
                handleTransition(!open);
            }}
        >
            <div ref={titleRef} className={clsx(styles.title)}>
                <div>{title}</div>
                <Caret  className={styles.caret} />
            </div>
            <div
                ref={contentContainer}
                className={clsx(styles.content)}
                style={{
                    height,
                    width,
                    ...height === "100%" && {
                        transition: "none",
                    },
                }}
                onTransitionEnd={handleTransitionEnd}
            >
                <div ref={contentRef} style={{ paddingTop: 6 }}>
                    {content}
                </div>
            </div>
        </div>
    );
};